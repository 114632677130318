export const MAX_MOOD_LENGTH = 6;

export const MOOD_PLACEHOLDER = {
  placeholderValue: 5,
};

export const DASHBOARD_DEFAULT_PARAMS = {
  page: 1,
  page_size: 10,
};

export const MY_COURSES_ORDERING = { ordering: 'course__title' };

export const TO_DO_ENTITIES = {
  PLAN: 'PLAN',
  ONE_ON_ONE: '1:1',
  SURVEY: 'SURVEY',
};
