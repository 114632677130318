export const CONFIG = {
  header: true,
  comments: true,
  transformHeader: header => {
    const [partOne, partTwo] = header.split(' ');
    return `${partOne.toLowerCase()}${
      partTwo ? `${partTwo.charAt(0).toUpperCase()}${partTwo.slice(1)}` : ''
    }`;
  },
};
