import { useMemo, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup } from 'react-transition-group';
import { Typography, Fade, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import DotsMenu from '../../dotsMenu';
import RichTextPreview from '../../richTextPreview';
import Tag from '../../tag';
import Tooltip from '../../tooltip';
import TasksList from '../../tasksList';
import UserLink from '../../userLink';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit-dark.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete-outlined.svg';
import { ReactComponent as AnonymousIcon } from '../../../../assets/icons/anonymous-user.svg';
import { isArrayEmpty } from '../../../../utility/helpers';
import { formatDate } from '../../../../utility/dateUtils';

const DAY_MONTH_FORMAT = 'MMM d';
const YEAR_FORMAT = 'yyyy';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    borderRadius: 4,
    backgroundColor: primary.bluish9,
    display: 'grid',
    gridTemplateColumns: '250px 1fr',
    position: 'relative',
  },
  info: {
    boxSizing: 'border-box',
    padding: spacing(4, 4, 4, 6),
  },
  content: {
    boxSizing: 'border-box',
    borderLeft: `1px dashed ${primary.bluish7}`,
    padding: spacing(4, 6, 4, 4),
  },
  menu: {
    position: 'absolute',
    top: 16,
    right: 24,
  },
  rowSpacing: {
    marginBottom: spacing(2),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  rowSpacingLarge: {
    marginBottom: spacing(4),
  },
  spacing: {
    marginBottom: spacing(1),
  },
  noteLabelWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  labelWrapper: {
    marginBottom: spacing(1.5),
  },
  label: {
    color: primary.bluish3,
  },
  description: {
    maxWidth: 'calc(100% - 24px)',
  },
  userLabel: {
    fontFamily: 'ProximaNova-Bold',
    fontSize: 16,
    lineHeight: '20px',
  },
  privateNoteTooltip: {
    width: 14,
    height: 12,
    marginLeft: spacing(1),
  },
  tagsWrapper: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    minHeight: 28,
    width: '100%',
  },
  tag: {
    minWidth: 0,
    margin: spacing(0, 2, 2, 0),
  },
  noTags: {
    marginBottom: spacing(2),
  },
  createdByHighlightedLabel: {
    fontFamily: 'ProximaNova-Bold',
  },
}));

const Note = ({
  translations,
  note,
  isCreatedByMe,
  isPrivateOnly,
  canAccessUserProfile,
  checkCanManageTask,
  checkIsDisabledEdit,
  checkCanManageNote,
  onTaskStatusChange,
  onEdit,
  onDelete,
}) => {
  const classes = useStyles();
  const {
    creator,
    user,
    created_at,
    text,
    is_private,
    tags,
    tasks,
    one_on_one_exist,
  } = note;
  const isUserAccessible = canAccessUserProfile(user.id);
  const isCreatorAccessible = !!creator && canAccessUserProfile(creator.id);
  const isEditDisabled = checkIsDisabledEdit(note.user);
  const canManageNote = checkCanManageNote(note);

  const handleTaskStatusChange = useCallback(
    task => {
      return onTaskStatusChange(note, task);
    },
    [note, onTaskStatusChange]
  );

  const menuItems = useMemo(() => {
    return [
      {
        type: 'edit',
        label: translations.edit,
        shouldItemRender: () => !isEditDisabled,
        action: () => onEdit(note),
        icon: EditIcon,
      },
      {
        type: 'delete',
        label: translations.delete,
        shouldItemRender: () => true,
        action: () => onDelete(note),
        icon: DeleteIcon,
      },
    ];
  }, [translations, isEditDisabled, note, onEdit, onDelete]);

  return (
    <div className={classes.root}>
      <div className={classes.info}>
        <div className={classes.rowSpacingLarge}>
          <Typography variant="body2" className={classes.labelWrapper}>
            {translations.createdOn}
          </Typography>
          <Typography variant="subtitle1" className={classes.spacing}>
            {formatDate(created_at, DAY_MONTH_FORMAT)}
          </Typography>
          <Typography variant="overline">
            {formatDate(created_at, YEAR_FORMAT)}
          </Typography>
        </div>
        <div>
          <Typography variant="body2" className={classes.labelWrapper}>
            {translations.tags}
          </Typography>
          {!isArrayEmpty(tags) ? (
            <TransitionGroup className={classes.tagsWrapper}>
              {tags.map(tag => (
                <Fade key={`tag_item_${tag.id}`} in>
                  <div className={classes.tag}>
                    <Tag tag={tag} color={tag.category.color} isSelected />
                  </div>
                </Fade>
              ))}
            </TransitionGroup>
          ) : (
            <Typography className={classes.noTags} variant="body2">
              -
            </Typography>
          )}
        </div>
        {isPrivateOnly && one_on_one_exist && (
          <div className={classes.rowSpacing}>
            <Typography variant="body2" className={classes.labelWrapper}>
              {translations.basedOn}
            </Typography>
            <Typography variant="subtitle1">
              {translations.basedOnOneOnOne}
            </Typography>
          </div>
        )}
        {isPrivateOnly && (
          <UserLink
            label={translations.about}
            user={user}
            isUserAccessible={isUserAccessible}
          />
        )}
        {!isPrivateOnly && (
          <UserLink
            className={classes.rowSpacing}
            label={translations.createdBy}
            labelClass={classNames({
              [classes.createdByHighlightedLabel]: isCreatedByMe,
            })}
            meLabel={translations.createdByMe}
            user={creator}
            isUserAccessible={isCreatorAccessible}
            isCurrentUser={isCreatedByMe}
          />
        )}
      </div>
      <div className={classes.content}>
        <div className={classes.rowSpacingLarge}>
          <div
            className={classNames(
              classes.noteLabelWrapper,
              classes.labelWrapper
            )}
          >
            <Typography variant="subtitle1" className={classes.label}>
              {is_private ? translations.privateNote : translations.note}
            </Typography>
            {is_private && (
              <Tooltip
                customIconClass={classes.privateNoteTooltip}
                text={translations.privateNoteTooltip}
                icon={AnonymousIcon}
              />
            )}
          </div>
          <RichTextPreview
            className={classNames(classes.description)}
            text={text}
          />
        </div>
        {!is_private && (
          <div className={classes.rowSpacing}>
            <Typography
              variant="subtitle1"
              className={classNames(classes.labelWrapper, classes.label)}
            >
              {translations.tasks}
            </Typography>
            {!isArrayEmpty(tasks) ? (
              <TasksList
                translations={translations.tasksList}
                tasks={tasks}
                canAccessUserProfile={canAccessUserProfile}
                checkCanManageTask={checkCanManageTask}
                onStatusChange={handleTaskStatusChange}
              />
            ) : (
              <Typography variant="body2">-</Typography>
            )}
          </div>
        )}
        {canManageNote && (
          <DotsMenu className={classes.menu} menuItems={menuItems} />
        )}
      </div>
    </div>
  );
};

Note.defaultProps = {
  note: {},
  isPrivateOnly: false,
  isCreatedByMe: false,
  checkCanManageNote: () => true,
  canAccessUserProfile: () => false,
  checkCanManageTask: () => false,
  checkIsDisabledEdit: () => false,
  onEdit: () => {},
  onDelete: () => {},
  onTaskStatusChange: () => {},
};

Note.propTypes = {
  translations: PropTypes.object.isRequired,
  note: PropTypes.object,
  isPrivateOnly: PropTypes.bool,
  isCreatedByMe: PropTypes.bool,
  checkCanManageTask: PropTypes.func,
  checkIsDisabledEdit: PropTypes.func,
  canAccessUserProfile: PropTypes.func,
  checkCanManageNote: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onTaskStatusChange: PropTypes.func,
};

export default memo(Note);
