import { RECURRING_TYPES_MAP } from 'constants/oneOnOne';
import {
  addHours,
  addMonths,
  addWeeks,
  differenceInMonths,
  differenceInWeeks,
  parseJSON,
} from 'date-fns';
import { ReactComponent as EditIcon } from '../assets/icons/edit-dark.svg';
import { ReactComponent as DeleteIcon } from '../assets/icons/delete-outlined.svg';
import {
  getWeekNumberInYear,
  getNumberOfWeeksInYear,
  getNumberOfWeeksInRange,
  getStartOfWeek,
  getEndOfWeek,
  formatDate,
  isFirstWeekOfTheMonth,
  getTimeRangeFromToday,
  getStartEndOfDayUtc,
} from './dateUtils';
import { checkUserRole, canSeeEmployeeProfile, isObjectEmpty } from './helpers';
import { isEmpty, forbiddenCharacters, validateLength } from './validation';
import { getPersonFullName } from './uiUtils';
import { FILTER_DATE_FORMAT } from '../constants/oneOnOne';
import { FIELD_TYPES } from '../constants/fieldTypes';
import { PARAMS } from '../constants/pages';
import { ROLES } from '../constants/rolesAndPermissionList';

export const getManageOneOnOneSuccessMessages = (
  successMessages,
  currentRecordId,
  isOneOnOneRequest = false,
  isReschedule = false
) => {
  if (isOneOnOneRequest) {
    return successMessages.general.request;
  }

  if (isReschedule) {
    return successMessages.general.reschedule;
  }

  return currentRecordId
    ? successMessages.general.edit
    : successMessages.general.create;
};

export const getDotsMenuItems = (
  labels,
  isDisabled,
  isCurrentUserSubject,
  canManageUserTopic,
  onEdit,
  onDelete,
  onManageTasks,
  onEditEmployeeTopic
) => [
  {
    type: 'edit',
    label: labels.edit,
    shouldItemRender: () => !isDisabled && !isCurrentUserSubject,
    action: onEdit,
    icon: EditIcon,
  },
  {
    type: 'manageEmployeeTopic',
    label: labels.manageEmployeeTopic,
    shouldItemRender: () => !isDisabled && canManageUserTopic,
    action: onEditEmployeeTopic,
    icon: EditIcon,
  },
  {
    type: 'manageTasks',
    label: labels.manageTasks,
    shouldItemRender: () => !isDisabled,
    action: onManageTasks,
    icon: EditIcon,
  },
  {
    type: 'delete',
    label: labels.delete,
    shouldItemRender: () => !isCurrentUserSubject,
    action: onDelete,
    icon: DeleteIcon,
  },
];

export const getModeratorAgenda = record =>
  record.agenda.filter(
    agenda => agenda.creator === record.moderator?.id || agenda.creator === null
  );

export const getEmployeeAgenda = record =>
  record.agenda.filter(agenda => agenda.creator === record.user?.id);

export const getOneOnOneReportWeeks = () => {
  const { MEETING_TIME_START, MEETING_TIME_END } = PARAMS;
  const period = getTimeRangeFromToday();
  const startWeekNumber = getWeekNumberInYear(period.start);
  const startWeekFirstDay = getStartOfWeek(period.start);
  const weeksInYear = getNumberOfWeeksInYear(period.start);
  const totalWeeks = getNumberOfWeeksInRange(period.start, period.end) + 1;
  const weeksArray = Array.from({ length: totalWeeks });
  const weeksList = weeksArray.map((_, index) => {
    const weekNumber =
      startWeekNumber + index > weeksInYear
        ? startWeekNumber + index - weeksInYear
        : startWeekNumber + index;
    const weekStart = formatDate(
      addWeeks(new Date(index === 0 ? period.start : startWeekFirstDay), index),
      FILTER_DATE_FORMAT
    );
    const weekEnd = formatDate(
      index === totalWeeks - 1 ? period.end : getEndOfWeek(new Date(weekStart)),
      FILTER_DATE_FORMAT
    );
    const isFirstMondayInMonth = isFirstWeekOfTheMonth(weekStart);

    return {
      week: weekNumber,
      weekPeriod: {
        [MEETING_TIME_START]: getStartEndOfDayUtc(weekStart),
        [MEETING_TIME_END]: getStartEndOfDayUtc(weekEnd, true),
      },
      isFirstMondayInMonth,
    };
  });

  return {
    startDate: period.start,
    weeksList,
  };
};

export const prepareOneOnOneReportData = (reportResults = []) => {
  const range = getOneOnOneReportWeeks();

  return reportResults.reduce((acc, report) => {
    const weeks = range.weeksList.map(currentWeek => {
      const weekReport = report.weeks.find(
        weekResult => weekResult.week === currentWeek.week
      );

      return {
        ...currentWeek,
        impression: weekReport ? Math.round(weekReport.impression) : null,
      };
    });

    return [...acc, { ...report, weeks }];
  }, []);
};

export const isMeetingAccessible = meeting => meeting.is_accessible;

export const getNextRecurring = (meetingDateTime, recurringType) => {
  const formattedMeetingDateTime = parseJSON(meetingDateTime);
  const weeksGap = differenceInWeeks(new Date(), formattedMeetingDateTime);
  const monthsGap = differenceInMonths(new Date(), formattedMeetingDateTime);

  switch (recurringType) {
    case RECURRING_TYPES_MAP.WEEKLY.id: {
      return addWeeks(formattedMeetingDateTime, weeksGap + 1);
    }
    case RECURRING_TYPES_MAP.BIWEEKLY.id: {
      return addWeeks(formattedMeetingDateTime, weeksGap + 2);
    }
    case RECURRING_TYPES_MAP.MONTHLY.id: {
      return addMonths(formattedMeetingDateTime, monthsGap + 1);
    }
    default: {
      return null;
    }
  }
};

export const getOneOnOneCalendarData = (labels, record) => {
  const { id, meeting_time, targetFullName } = record;
  const startDate = formatDate(meeting_time, 'yyyy-MM-dd');
  const startTime = formatDate(meeting_time, 'HH:mm');
  const endTime = formatDate(addHours(new Date(meeting_time), 1), 'HH:mm');
  const formatedAgenda = [
    ...getModeratorAgenda(record),
    ...getEmployeeAgenda(record),
  ]
    .map(a => `[br]- ${a.name}`)
    .join('');

  return {
    name: `${labels.name} ${targetFullName}`,
    description: `${labels.greetingMessage} ${targetFullName},[br][br]${labels.description} [br]${formatedAgenda}`,
    startDate,
    startTime,
    endTime,
    uid: id,
    iCalFileName: `${labels.name} ${targetFullName} ${startDate}`,
  };
};

export const prepareOneOnOneRecordsData = (
  records,
  currentUser,
  user = null
) => {
  const isAdmin = checkUserRole(currentUser.role, ROLES.ADMIN);

  return records.map(record => {
    const isCreatedByMe = currentUser.id === record.moderator?.id;
    const isCreatedForMe =
      currentUser.id === user?.id || currentUser.id === record.user?.id;
    const canAccessOneOnOne = isAdmin || isCreatedByMe || isCreatedForMe;
    const canAccessModeratorProfile =
      !!record.moderator &&
      canSeeEmployeeProfile(currentUser, record.moderator?.id);

    return {
      ...record,
      meeting_time: record.meeting_time,
      ...(record?.recurring
        ? {
            nextRecurring: getNextRecurring(
              record?.meeting_time,
              record?.recurring
            ),
          }
        : {}),
      targetFullName: getPersonFullName(user || record.moderator),
      note: record.note,
      isCreatedByMe,
      isCreatedForMe,
      canAccessOneOnOne,
      canAccessModeratorProfile,
    };
  });
};

export const getOneOnOneTasksAvailableUsers = (oneOnOneRecord = {}) => {
  const { user, moderator } = oneOnOneRecord;

  return moderator ? Array.of(moderator, user) : Array.of(user);
};

export const checkIsManagerChanged = (oldManager, newManager) =>
  oldManager?.id !== newManager?.id;

export const getRequestCardLabels = (labels, oldManager, newManager) => {
  const isExpired = checkIsManagerChanged(oldManager, newManager);

  if (isObjectEmpty(newManager)) {
    return { ...labels, userRequest: labels.expiredWithNoModerator };
  }

  if (isExpired) {
    return { ...labels, userRequest: labels.expired };
  }

  return labels;
};

export const prepareEmployeeTopicData = (record = {}, currentUserId = null) => {
  const employeeTopic = record?.agenda?.find(
    u => !u.is_recommended && u.creator === currentUserId
  );
  return {
    id: employeeTopic?.id || null,
    meetingId: record?.id || null,
    moderator: getPersonFullName(record?.moderator),
    name: employeeTopic?.name || '',
  };
};

export const getEmployeeTopicSidebarTranslations = (
  labels,
  isEdit = false,
  isRequest = false
) => {
  if (isRequest) {
    return {
      ...labels,
      add: labels.requestTitle,
      edit: labels.requestTitle,
      save: isEdit ? labels.save : labels.request,
    };
  }

  return {
    ...labels,
    add: labels.employeeTopicTitle,
    edit: labels.employeeTopicTitle,
  };
};

export const getEmployeeTopicFields = () => {
  return [
    {
      name: 'moderator',
      type: FIELD_TYPES.TEXT,
      isDisabled: true,
    },
    {
      name: 'name',
      type: FIELD_TYPES.TEXT,
      required: true,
      validators: [
        {
          validator: isEmpty,
          type: 'required',
        },
        {
          validator: forbiddenCharacters,
          type: 'forbiddenCharacters',
        },
        {
          validator: fieldValue => validateLength(fieldValue, 2, 50),
          type: 'topicMaxLength',
        },
      ],
    },
  ];
};
