import { createAction, handleActions } from 'redux-actions';
import http from '../../utility/http';
import { parseDuplicateParameters } from '../../utility/uiUtils';
import {
  transformSurveyData,
  prepareReviewParticipants,
  transformReviewsData,
  transformReviewData,
} from '../../utility/reportUtils';
import { getItemIndex, replaceObjectInList } from '../../utility/helpers';
import {
  api_review_feedback,
  api_survey,
  api_survey_reviewers,
  api_review_results,
  api_text_reply_review_results,
  api_review_participants_info,
  api_survey_attributes,
  api_attribute,
} from '../../constants/apiRoutes';
import { DEFAULT_PARAMS } from '../../components/pages/surveySettingsPage/config';
// ------------------------------------
// Constants
// ------------------------------------
const SET_SURVEY = 'SET_SURVEY';
const SET_REVIEWS = 'SET_REVIEWS';
const SET_REVIEW = 'SET_REVIEW';
const SET_REVIEWERS = 'SET_REVIEWERS';

const initialState = {
  survey: {},
  reviews: [],
  reviewers: {}, // settings page
};

// ------------------------------------
// Actions
// ------------------------------------

const setSurvey = createAction(SET_SURVEY);
const setReviews = createAction(SET_REVIEWS);
const setReview = createAction(SET_REVIEW);
const setReviewers = createAction(SET_REVIEWERS);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_SURVEY]: (state, { payload }) => {
    return { ...state, survey: payload };
  },
  [SET_REVIEWERS]: (state, { payload }) => {
    if (payload.isLoadMore) {
      return {
        ...state,
        reviewers: {
          ...payload.data,
          results: [...state.reviewers.results, ...payload.data.results],
        },
      };
    }
    return { ...state, reviewers: payload.data };
  },
  [SET_REVIEWS]: (state, { payload }) => {
    return { ...state, reviews: payload };
  },
  [SET_REVIEW]: (state, { payload }) => {
    const { reviewId, review, answers, participants, comments } = payload;
    const reviewIndex = getItemIndex(state.reviews, reviewId);

    const reviewData = {
      ...review,
      ...state.reviews[reviewIndex],
      answers,
      participants: prepareReviewParticipants(state.survey.type, participants),
      comments,
    };

    return {
      ...state,
      reviews: replaceObjectInList(state.reviews, reviewIndex, reviewData),
    };
  },
};

// ------------------------------------
// API calls
// ------------------------------------

export const getSurvey = (dispatch, surveyId) => {
  return http.get(api_survey(surveyId)).then(({ data }) => {
    const updatedData = transformSurveyData(data);
    dispatch(setSurvey(updatedData));
    return updatedData;
  });
};

export const editSurvey = (dispatch, surveyId, updatedSurvey) => {
  return http.patch(api_survey(surveyId), updatedSurvey).then(({ data }) => {
    const updatedData = transformSurveyData(data);
    dispatch(setSurvey(updatedData));
  });
};

export const getReviews = (dispatch, surveyId) => {
  return http.get(api_survey_attributes(surveyId)).then(({ data }) => {
    const reviews = transformReviewsData(data);
    dispatch(setReviews(reviews));

    return reviews;
  });
};

export const getReview = async (dispatch, surveyId, reviewId) => {
  const { data } = await http.get(api_attribute(reviewId));
  const review = transformReviewData(data);

  const [resultsData, participantsData, feedbackData] = await Promise.all([
    review.isTextReply
      ? http.get(api_text_reply_review_results(surveyId, reviewId))
      : http.get(api_review_results(surveyId, reviewId)),
    http.get(api_review_participants_info(surveyId, reviewId)),
    ...[
      review.hasAdditionalFeedback
        ? http.get(api_review_feedback(surveyId, reviewId))
        : [],
    ],
  ]);

  dispatch(
    setReview({
      reviewId,
      review,
      answers: resultsData.data,
      participants: participantsData.data,
      comments: feedbackData?.data || [],
    })
  );
};

export const getReviewers = (
  dispatch,
  surveyId,
  isLoadMore = false,
  params = {}
) => {
  return http
    .get(api_survey_reviewers(surveyId), {
      params: { ...DEFAULT_PARAMS, ...params },
      paramsSerializer: data => parseDuplicateParameters(data),
    })
    .then(({ data }) => {
      dispatch(setReviewers({ data, isLoadMore }));
    });
};

export default handleActions(reducers, initialState);

// ------------------------------------
// "plain" action functions
// ------------------------------------

export const clearSurvey = () => setSurvey(initialState.survey);
export const clearReviews = () => setReviews(initialState.reviews);
export const clearReviewers = () =>
  setReviewers({ data: initialState.reviewers });
