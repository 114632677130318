import { PureComponent, createRef } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { primaryPalette } from 'styles/theme';
import { formatDate, getQuarterFromDate } from '../../../utility/dateUtils';
import { isObjectEmpty } from '../../../utility/helpers';

const DATE = 'd MMM';
const YEAR = 'yyyy';
const MONTH = 'MMM';
const BAR_HEIGHT = 18;
const DEFAULT_CHART_HEIGHT = 180;
const CATEGORY_SPACING = 80;
const BARS_SPACING = 10;
const TICKS = [0, 2.5, 5, 7.5, 10];

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

class ReportsBarChart extends PureComponent {
  zeroWeightLabels = {
    id: 'zeroWeightLabels',
    afterDatasetsDraw(chart) {
      const { ctx, data } = chart;
      ctx.save();

      data.datasets.forEach((val, dataIndex) => {
        chart.getDatasetMeta(dataIndex).data.forEach((dataPoint, index) => {
          const weight = data.datasets[dataIndex].data[index];
          if (weight === 0) {
            ctx.font = '12px ProximaNova-Bold';
            ctx.fillStyle = primaryPalette.bluish2;
            ctx.textBaseline = 'middle';
            ctx.fillText(
              `${data.datasets[dataIndex].name}`,
              dataPoint.x + 5,
              dataPoint.y
            );
            ctx.textBaseline = 'top';
            ctx.fillText(
              `Average: ${weight}`,
              dataPoint.x + 5,
              dataPoint.y + 4
            );
          }
        });
      });
    },
  };

  constructor(props) {
    super(props);
    this.chartWrapper = createRef();
  }

  componentDidMount() {
    this.setChartHeight();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.barCountMap !== this.props.barCountMap) {
      this.setChartHeight();
    }
  }

  setChartHeight = () => {
    const { barCountMap, chartData } = this.props;
    if (!isObjectEmpty(barCountMap)) {
      const totalNumberOfBars = Object.keys(barCountMap).reduce(
        (acc, current) => acc + barCountMap[current],
        0
      );
      const biggestNumberOfBars = Object.values(barCountMap).sort(
        (a, b) => b - a
      )[0];
      const numberOfLabels = chartData.labels?.length;
      const calculatedChartHeight =
        numberOfLabels *
        (CATEGORY_SPACING + biggestNumberOfBars * (BAR_HEIGHT + BARS_SPACING));
      const chartWrapperNode = this.chartWrapper.current;

      if (numberOfLabels === 1) {
        if (totalNumberOfBars < 4) {
          chartWrapperNode.style.height = `${DEFAULT_CHART_HEIGHT}px`;
        } else {
          chartWrapperNode.style.height = `${
            calculatedChartHeight + CATEGORY_SPACING
          }px`;
        }
      } else {
        chartWrapperNode.style.height = `${calculatedChartHeight}px`;
      }
    }
  };

  getHalf = rawLabel => {
    return `H ${rawLabel.slice(0, 3)}`;
  };

  getQuarter = rawLabel => {
    const [startDate] = rawLabel.split(' - ');
    return `Q ${getQuarterFromDate(startDate)}/4`;
  };

  renderYTicks = rawLabel => {
    const { groupingPeriod } = this.props;
    const label = rawLabel.length > 10 ? rawLabel.slice(0, 7) : rawLabel;

    switch (groupingPeriod) {
      case 'surveys':
        return [formatDate(label, DATE), formatDate(label, YEAR)];
      case 'month':
        return [formatDate(label, MONTH), formatDate(label, YEAR)];
      case 'quarter':
        return [this.getQuarter(rawLabel), label.slice(0, 4)];
      case 'half':
        return [this.getHalf(label), formatDate(label, YEAR)];
      case 'year':
        return [formatDate(label, YEAR)];

      default:
        return null;
    }
  };

  getOptions = chartData => ({
    indexAxis: 'y',
    skipNull: true,
    responsive: true,
    animation: {
      delay: 500,
    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: item => {
            return `Average: ${item.raw}`;
          },
          title: item => chartData.datasets[item[0].datasetIndex].name,
        },
        backgroundColor: primaryPalette.white,
        titleColor: primaryPalette.bluish2,
        bodyColor: primaryPalette.bluish2,
        padding: 10,
        cornerRadius: 8,
        borderWidth: 1,
        borderColor: 'rgba(0, 0, 0, 0.08)',
      },
    },
    scales: {
      y: {
        grid: {
          drawTicks: false,
          borderColor: primaryPalette.bluish3,
          borderDash: [2, 2],
          color: ctx => {
            if (ctx.type === 'scale') {
              return primaryPalette.bluish3;
            } else if (ctx.index === 0) {
              return primaryPalette.white;
            }
            return primaryPalette.bluish6;
          },
          z: 10,
        },
        ticks: {
          crossAlign: 'far',
          font: {
            size: 14,
            family: 'ProximaNova-Bold',
          },
          padding: 10,
          color: primaryPalette.bluish2,
          callback: (value, index) => {
            return this.renderYTicks(chartData.labels[index]);
          },
        },
      },
      x: {
        max: 10,
        min: 0,
        grid: {
          borderDash: [2, 2],
          drawTicks: false,
          borderColor: primaryPalette.bluish3,
          color: primaryPalette.bluish6,
        },
        ticks: {
          padding: 15,
          color: primaryPalette.bluish2,
          font: {
            size: 14,
            family: 'ProximaNova-Bold',
          },
          stepSize: 2.5,
          callback: (value, index) => {
            return TICKS[index];
          },
        },
      },
    },
  });

  render() {
    const { chartData } = this.props;
    return (
      <div ref={this.chartWrapper}>
        <Bar
          data={chartData}
          options={this.getOptions(chartData)}
          plugins={[this.zeroWeightLabels]}
        />
      </div>
    );
  }
}

export default ReportsBarChart;
