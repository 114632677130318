import { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import { alpha, Fade } from '@material-ui/core';
import NotificationCard from '../../notificationCard';
import { isArrayEmpty } from '../../../../utility/helpers';
import {
  getOptions,
  getChartHeight,
  getXAxisLabels,
  prepareChartResults,
} from './config';

ChartJS.register(CategoryScale, LinearScale, PointElement, Filler, LineElement);

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    width: '100%',
    position: 'relative',
    height: getChartHeight,
    marginTop: spacing(6),
  },
  noResults: {
    boxSizing: 'border-box',
    backgroundColor: primary.white,
    position: 'absolute',
    top: 'calc(50% - 20px)',
    left: 'calc(50% - 16px)',
    width: 'fit-content',
    transform: 'translate(-50%, -50%)',
    padding: spacing(3, 4),
    filter: `drop-shadow(0px 4px 10px ${alpha(primary.black, 0.2)})`,
  },
}));

const ReportChart = ({
  translations,
  noResultsMessage,
  xMax,
  yMax,
  hasNeutralAnswer,
  results,
  isAverageView,
}) => {
  const classes = useStyles({ yMax });
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    setChartData(prepareChartResults(results, isAverageView));
  }, [results, isAverageView]);

  return (
    <div className={classes.root}>
      <Line
        options={getOptions(
          translations,
          yMax,
          hasNeutralAnswer,
          isAverageView
        )}
        data={{
          labels: getXAxisLabels(translations, xMax),
          datasets: chartData,
        }}
      />
      <Fade in={isArrayEmpty(results)} unmountOnExit>
        <NotificationCard
          className={classes.noResults}
          title={noResultsMessage}
          titleVariant="subtitle1"
        />
      </Fade>
    </div>
  );
};

ReportChart.defaultProps = {
  results: [],
};

ReportChart.propTypes = {
  translations: PropTypes.object.isRequired,
  noResultsMessage: PropTypes.string.isRequired,
  xMax: PropTypes.number.isRequired,
  yMax: PropTypes.number.isRequired,
  hasNeutralAnswer: PropTypes.bool.isRequired,
  isAverageView: PropTypes.bool.isRequired,
  results: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
};

export default memo(ReportChart);
