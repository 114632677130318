import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import ResetPasswordFormLayout from './layout';
import { validateFields } from '../../../utility/validation';
import fields from './fields';
import { isObjectEmpty } from '../../../utility/helpers';

const ResetPasswordForm = withFormik({
  mapPropsToValues: () => ({ password: '', repeatPassword: '' }),
  validate: async values => {
    const errors = await validateFields(fields, values);

    if (
      isObjectEmpty(errors) &&
      values.password &&
      values.repeatPassword &&
      values.password !== values.repeatPassword
    ) {
      errors.password = 'passwordMismatch';
      errors.repeatPassword = 'passwordMismatch';
    }

    throw errors;
  },
  validateOnBlur: false,
  handleSubmit: (values, { props, setSubmitting }) => {
    props.handleSubmitHandler(values, props, setSubmitting);
  },
})(ResetPasswordFormLayout);

ResetPasswordForm.defaultProps = {
  withoutBackLink: false,
};
ResetPasswordForm.propTypes = {
  translations: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  handleSubmitHandler: PropTypes.func.isRequired,
  withoutBackLink: PropTypes.bool,
};

export default ResetPasswordForm;
