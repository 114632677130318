import { createAction, handleActions } from 'redux-actions';
import http from '../../utility/http';
import { API_COURSES } from '../../constants/apiRoutes';
import { parseDuplicateParameters } from '../../utility/uiUtils';
import { DEFAULT_ORDERING } from '../../constants/courses';
import { COURSES_DEFAULT_PARAMS } from '../../constants/pages';
// ------------------------------------
// Constants
// ------------------------------------

const SET_COURSES = 'SET_COURSES';

const initialState = {
  courses: {},
};

// ------------------------------------
// Actions
// ------------------------------------

export const setCourses = createAction(SET_COURSES);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_COURSES]: (state, { payload }) => {
    return { ...state, courses: payload };
  },
};

// ------------------------------------
// API calls
// ------------------------------------

export const getCourses = (dispatch, params) => {
  return http
    .get(API_COURSES, {
      params: { ...COURSES_DEFAULT_PARAMS, ...DEFAULT_ORDERING, ...params },
      paramsSerializer: d => parseDuplicateParameters(d),
    })
    .then(({ data }) => {
      dispatch(setCourses(data));
    });
};

export default handleActions(reducers, initialState);

// ------------------------------------
// "plain" action functions
// ------------------------------------

export const clearCourses = () => setCourses(initialState.courses);
