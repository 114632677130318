import { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TransitionGroup } from 'react-transition-group';
import { Typography, Fade, makeStyles } from '@material-ui/core';
import SectionTitle from '../sectionTitle';
import DotsMenu from '../dotsMenu';
import StatusIndicator from '../statusIndicator';
import TextBoxWithTooltip from '../textBoxWithTooltip';
import Tag from '../tag';
import UserLink from '../userLink';
import TasksList from '../tasksList';
import TextLink from '../textLink';
import RequestedIconTooltip from '../requestedIconTooltip';
import ProposedStatus from '../proposedStatus';
import { formatDate } from '../../../utility/dateUtils';
import { isArrayEmpty, canManagePerson } from '../../../utility/helpers';
import { getDotsMenuItems } from '../../../utility/actionPlans';
import { ACTION_PLAN_FIELDS } from '../../../constants/actionPlan';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  heading: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: spacing(4),
  },
  root: {
    backgroundColor: primary.bluish9,
    display: 'grid',
    gridTemplateColumns: `minmax(0, 1fr) 230px`,
    width: '100%',
    borderRadius: 4,
    marginBottom: spacing(7),
  },
  content: {
    boxSizing: 'border-box',
    padding: spacing(6),
    width: '100%',
  },
  info: {
    boxSizing: 'border-box',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(6, 6, 6, 4),
    borderLeft: `1px dashed ${primary.bluish7}`,
  },
  sectionWrapper: {
    marginBottom: spacing(4),
  },
  label: {
    color: primary.bluish3,
  },
  labelSpacing: {
    marginBottom: spacing(1.5),
  },
  about: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    maxWidth: '100%',
  },
  name: {
    width: 'auto',
    maxWidth: '100%',
    lineHeight: '20px',
  },
  clickable: {
    cursor: 'pointer',
    color: primary.blue1,
  },
  day: {
    marginBottom: spacing(1),
  },
  dotsMenu: {
    position: 'absolute',
    top: 24,
    right: 24,
    height: 20,
    width: 'auto',
  },
  tagsSection: {
    marginBottom: spacing(2),
  },
  tagsWrapper: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
  },
  tagsWrapperWithoutTags: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 28,
  },
  tagWrapper: {
    margin: spacing(0, 2, 2, 0),
  },
  coursesWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  courseWrapper: {
    display: 'flex',
    marginBottom: spacing(2),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  createdByHighlightedLabel: {
    fontFamily: 'ProximaNova-Bold',
  },
  autoSpacing: {
    marginTop: 'auto',
  },
  statusWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const DAY_MONTH_FORMAT = 'MMM d';
const YEAR_FORMAT = 'yyyy';

const ActionPlanDetailsCard = ({
  translations,
  actionPlan,
  statuses,
  currentUser,
  isDisabled,
  canManage,
  hasActions,
  onManageTasks,
  onDelete,
  onEdit,
  onDismissProposal,
  onTaskStatusChange,
  checkCanManageTaskCompletion,
  checkCanAccessUserProfile,
}) => {
  const classes = useStyles();

  const {
    title,
    creator,
    due_date,
    user,
    created_on,
    tags,
    progress,
    tasks,
    courses,
    is_proposed,
    is_pending_proposal,
  } = actionPlan;
  const isCreatedForAccessible = checkCanAccessUserProfile(user.id);
  const isCreatedByMe = currentUser.id === creator?.id;
  const isCreatorAccessible =
    !!creator?.id && checkCanAccessUserProfile(creator.id);
  const hasCourses = !isArrayEmpty(courses);
  const isPendingProposal = is_proposed && is_pending_proposal;
  const isCreatedForCurrentUser = user.id === currentUser.id;
  const canManageProposal =
    isPendingProposal && canManagePerson(currentUser, user.id, true);

  const handleTaskStatusChange = useCallback(
    task => {
      onTaskStatusChange(task, hasActions);
    },
    [hasActions, onTaskStatusChange]
  );

  const renderStatusLabel = () => {
    const currentStatus = statuses.find(
      status => status.value === actionPlan[ACTION_PLAN_FIELDS.STATUS]
    );

    return (
      <div className={classes.statusWrapper}>
        <StatusIndicator status={currentStatus} />
        {is_proposed && (
          <RequestedIconTooltip
            labels={translations.proposedTooltip}
            isRequestedByCurrentUser={isCreatedForCurrentUser}
          />
        )}
      </div>
    );
  };

  return (
    <>
      <div className={classes.heading}>
        <SectionTitle title={translations.details} />
      </div>
      <div className={classes.root}>
        <div className={classes.content}>
          <UserLink
            className={classes.sectionWrapper}
            labelClass={classes.label}
            label={translations.createdFor}
            user={user}
            isUserAccessible={isCreatedForAccessible}
          />
          <div className={classes.sectionWrapper}>
            <Typography
              className={classNames(classes.label, classes.labelSpacing)}
              variant="body2"
            >
              {translations.title}
            </Typography>
            <TextBoxWithTooltip variant="subtitle2" text={title} />
          </div>
          <div
            className={classNames(classes.sectionWrapper, classes.tagsSection)}
          >
            <Typography
              className={classNames(classes.label, classes.labelSpacing)}
              variant="body2"
            >
              {translations.tags}
            </Typography>
            <TransitionGroup className={classes.tagsWrapper}>
              {tags.map(tag => (
                <Fade key={`tag_item_${tag.id}`} in unmountOnExit>
                  <div className={classes.tagWrapper}>
                    <Tag tag={tag} color={tag.category.color} isSelected />
                  </div>
                </Fade>
              ))}
            </TransitionGroup>
            <Fade in={isArrayEmpty(tags)} unmountOnExit>
              <Typography className={classes.tagsWrapperWithoutTags}>
                -
              </Typography>
            </Fade>
          </div>
          <div className={classes.sectionWrapper}>
            <Typography
              className={classNames(classes.label, classes.labelSpacing)}
              variant="body2"
            >
              {translations.tasks}
            </Typography>
            <TasksList
              translations={translations.tasksList}
              tasks={tasks}
              checkCanManageTask={checkCanManageTaskCompletion}
              canAccessUserProfile={checkCanAccessUserProfile}
              onStatusChange={handleTaskStatusChange}
            />
            <Fade in={isArrayEmpty(tasks)} unmountOnExit>
              <Typography variant="body2">-</Typography>
            </Fade>
          </div>
          <div>
            <Typography
              className={classNames(classes.label, classes.labelSpacing)}
              variant="body2"
            >
              {translations.courses}
            </Typography>
            <Fade in={!hasCourses} unmountOnExit>
              <Typography variant="body2">-</Typography>
            </Fade>
            <TransitionGroup className={classes.coursesWrapper}>
              {courses.map(course => (
                <Fade
                  key={`course_item_${course.id}`}
                  className={classes.courseWrapper}
                  in
                  unmountOnExit
                >
                  <div>
                    <TextLink
                      to={`/learning/${course.id}`}
                      variant="subtitle1"
                      text={course.title}
                    />
                  </div>
                </Fade>
              ))}
            </TransitionGroup>
          </div>
        </div>
        <div className={classes.info}>
          <div className={classes.sectionWrapper}>
            {isPendingProposal ? (
              <ProposedStatus proposedLabel={translations.proposed} />
            ) : (
              renderStatusLabel()
            )}
          </div>
          {!isPendingProposal && (
            <div className={classes.sectionWrapper}>
              <Typography className={classes.labelSpacing} variant="body2">
                {translations.completeness}
              </Typography>
              <Typography variant="subtitle1">{`${progress}%`}</Typography>
            </div>
          )}
          <div className={classes.sectionWrapper}>
            <Typography className={classes.labelSpacing} variant="body2">
              {translations.dueDate}
            </Typography>
            <Typography variant="subtitle1" className={classes.day}>
              {formatDate(due_date, DAY_MONTH_FORMAT)}
            </Typography>
            <Typography variant="overline">
              {formatDate(due_date, YEAR_FORMAT)}
            </Typography>
          </div>
          <div className={classes.sectionWrapper}>
            <Typography className={classes.labelSpacing} variant="body2">
              {isPendingProposal
                ? translations.proposedOn
                : translations.createdOn}
            </Typography>
            <Typography variant="subtitle1" className={classes.day}>
              {formatDate(created_on, DAY_MONTH_FORMAT)}
            </Typography>
            <Typography variant="overline">
              {formatDate(created_on, YEAR_FORMAT)}
            </Typography>
          </div>
          {!isPendingProposal && (
            <UserLink
              className={classes.autoSpacing}
              label={translations.createdBy}
              labelClass={classNames({
                [classes.createdByHighlightedLabel]: isCreatedByMe,
              })}
              meLabel={translations.createdByMe}
              user={creator}
              isUserAccessible={isCreatorAccessible}
              isCurrentUser={isCreatedByMe}
            />
          )}
          {(hasActions || canManageProposal) && (
            <DotsMenu
              className={classes.dotsMenu}
              menuItems={getDotsMenuItems(
                translations.menuItemLabels,
                actionPlan,
                canManage,
                canManageProposal,
                isDisabled,
                hasActions,
                is_pending_proposal,
                isCreatedForCurrentUser,
                onEdit,
                onDelete,
                onManageTasks,
                onDismissProposal
              )}
            />
          )}
        </div>
      </div>
    </>
  );
};

ActionPlanDetailsCard.defaultProps = {
  hasActions: false,
  canManage: false,
  isDisabled: false,
  onManageTasks: () => {},
  onDelete: () => {},
  onEdit: () => {},
  onTaskStatusChange: () => {},
};

ActionPlanDetailsCard.propTypes = {
  translations: PropTypes.object.isRequired,
  actionPlan: PropTypes.shape({}).isRequired,
  currentUser: PropTypes.object.isRequired,
  statuses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isDisabled: PropTypes.bool,
  canManage: PropTypes.bool,
  hasActions: PropTypes.bool,
  checkCanAccessUserProfile: PropTypes.func.isRequired,
  checkCanManageTaskCompletion: PropTypes.func.isRequired,
  onManageTasks: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onDismissProposal: PropTypes.func.isRequired,
  onTaskStatusChange: PropTypes.func,
};

export default ActionPlanDetailsCard;
