import { connect } from 'react-redux';

import { PLACEMENTS } from 'constants/tags';
import OneOnOnePage from '../../components/pages/oneOnOnePage';
import PageContainer from '../../components/shared/pageContainer';
import { useTranslations } from '../../utility/useTranslations';
import { oneOnOneSelector } from '../../store/selectors/oneOnOne';
import { oneOnOneTopicsSelector } from '../../store/selectors/oneOnOneTopicsSelector';
import { tagsSelector } from '../../store/selectors/tagsSelector';
import {
  getOneOnOneRecords,
  clearOneOnOneRecords,
} from '../../store/modules/oneOnOne';
import {
  getOneOnOneTopics,
  clearOneOnOneTopics,
} from '../../store/modules/oneOnOneTopics';
import {
  getPageQuickFilters,
  clearPageQuickFilters,
  getTagsCategories,
  clearTagsCategories,
  addMultipleTags,
} from '../../store/modules/tags';
import { APP_PAGES } from '../../constants/pages';

const mapStateToProps = state => ({
  ...oneOnOneSelector(state),
  ...oneOnOneTopicsSelector(state),
  ...tagsSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getOneOnOneRecords: (isLoadMore, data) =>
    getOneOnOneRecords(dispatch, isLoadMore, data),
  getOneOnOneTopics: () => getOneOnOneTopics(dispatch),
  clearOneOnOneRecords: () => dispatch(clearOneOnOneRecords()),
  clearOneOnOneTopics: () => clearOneOnOneTopics(dispatch),
  getTagsCategories: () => getTagsCategories(dispatch),
  getPageQuickFilters: () =>
    getPageQuickFilters(dispatch, PLACEMENTS.ONE_ON_ONE.id),
  clearTagsCategories: () => dispatch(clearTagsCategories()),
  addMultipleTags: data => dispatch(addMultipleTags(data)),
  clearPageQuickFilters: () => dispatch(clearPageQuickFilters()),
});

const OneOnOnePageView = props => {
  const translations = useTranslations(APP_PAGES.ONE_ON_ONE);

  return (
    <PageContainer {...props} translations={translations} isFullWidthContent>
      <OneOnOnePage />
    </PageContainer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OneOnOnePageView);
