import { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import AttributesList from '../../attributesList';

const useStyles = makeStyles(({ spacing }) => ({
  label: {
    marginBottom: spacing(2),
  },
  scroll: {
    top: 0,
    right: -21,
    height: '100%',
  },
}));

const Attributes = ({ label, attributes }) => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="subtitle2" className={classes.label}>
        {label}
      </Typography>
      <AttributesList
        scrollClass={classes.scroll}
        attributes={attributes}
        isScrollable
        isActive
        isHorizontalList
      />
    </div>
  );
};

Attributes.propTypes = {
  label: PropTypes.string.isRequired,
  attributes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default memo(Attributes);
