import { getUserProfileLink } from '../../../utility/uiUtils';
import {
  canManagePerson,
  canSeeEmployeeProfile,
  isArrayEmpty,
} from '../../../utility/helpers';
import { formatDate } from '../../../utility/dateUtils';
import { ACTION_PLAN_FIELDS } from '../../../constants/actionPlan';

export const INITIAL_SORT = {
  column: 'modifiedOn',
  asc: false,
  sortKey: '-modified_on',
};

export const getHeaderActions = labels => [
  {
    id: 'actions-column',
    title: labels.actions,
    rowKey: 'actions',
    align: 'right',
    rowCellClass: 'actions-cell',
    isHeaderAction: true,
    minWidth: 60,
    maxWidth: 80,
  },
];

export const getTableHeaders = labels => [
  {
    id: 1,
    title: labels.name,
    rowKey: 'name',
    sortAs: 'user__first_name,user__last_name',
    rowCellClass: 'created-for-cell',
    minWidth: '160px',
    maxWidth: '1fr',
  },
  {
    id: 2,
    title: labels.title,
    rowKey: 'title',
    sortAs: 'title',
    rowCellClass: 'title-cell',
    minWidth: 145,
    maxWidth: '1fr',
    hasConditionalTooltip: true,
  },
  {
    id: 3,
    title: labels.dueDate,
    rowKey: 'dueDate',
    rowCellClass: 'due-date-cell',
    sortAs: 'due_date',
    maxWidth: '1fr',
  },
  {
    id: 4,
    title: labels.modifiedOn,
    rowKey: 'modifiedOn',
    rowCellClass: 'modified-on-cell',
    sortAs: 'modified_on',
    maxWidth: '1fr',
  },
  {
    id: 5,
    title: labels.status,
    rowKey: 'status',
    sortAs: 'is_finished',
    maxWidth: '1fr',
  },
];

export const prepareTableData = (
  actionPlans,
  statuses,
  renderUser,
  renderStatus,
  renderActions,
  isAdmin,
  currentUser,
  canSeeHimself
) => {
  const {
    TITLE,
    USER,
    STATUS,
    DUE_DATE,
    MODIFIED_ON,
    TASKS,
    PROGRESS,
    COURSES,
    IS_PROPOSED,
    IS_PENDING_PROPOSAL,
  } = ACTION_PLAN_FIELDS;

  return actionPlans.map(actionPlan => {
    const detailsUrl = `${actionPlan.id}/details/`;
    const canAccessAssigneeProfile = canSeeEmployeeProfile(
      currentUser,
      actionPlan[USER].id,
      canSeeHimself
    );
    const status = statuses.find(s => {
      return s.value === actionPlan[STATUS];
    });
    const hasTasks = !isArrayEmpty(actionPlan[TASKS]);
    const isProposed = actionPlan[IS_PROPOSED];
    const isPendingProposal = isProposed && actionPlan[IS_PENDING_PROPOSAL];
    const isCreatedForCurrentUser = actionPlan[USER].id === currentUser.id;
    const canManage =
      isAdmin ||
      currentUser.id === actionPlan?.user?.id ||
      currentUser.id === actionPlan?.creator?.id;
    const canManageProposal =
      isPendingProposal &&
      canManagePerson(currentUser, actionPlan[USER].id, true);

    return {
      ...(canManage || canManageProposal
        ? {
            actions: renderActions(
              actionPlan,
              isAdmin,
              canManage,
              canManageProposal,
              !!isPendingProposal,
              isCreatedForCurrentUser
            ),
          }
        : {}),
      id: actionPlan.id,
      name: renderUser(actionPlan[USER], canAccessAssigneeProfile),
      title: actionPlan[TITLE],
      dueDate: formatDate(actionPlan[DUE_DATE]),
      modifiedOn: formatDate(actionPlan[MODIFIED_ON]),
      status: renderStatus(
        status,
        actionPlan[PROGRESS],
        hasTasks,
        actionPlan[COURSES],
        isPendingProposal,
        isProposed,
        isCreatedForCurrentUser
      ),
      cellLinks: {
        name: {
          to: getUserProfileLink(actionPlan[USER].id, canAccessAssigneeProfile),
        },
        title: {
          to: detailsUrl,
        },
        dueDate: {
          to: detailsUrl,
        },
        status: {
          to: detailsUrl,
        },
        modifiedOn: {
          to: detailsUrl,
        },
      },
    };
  });
};
