import { connect } from 'react-redux';
import { useParams } from 'react-router';
import PeoplePageContainer from '../../components/shared/peoplePageContainer';
import PeopleInsightsPage from '../../components/pages/peopleInsightsPage';
import {
  getReviewedAttributes,
  getBarChartData,
  clearReviewedAttributes,
  clearReportComments,
  clearUserReports,
  getReportComments,
} from '../../store/modules/personReports';
import { personReportsSelector } from '../../store/selectors/personReportsSelector';
import { loadingSelector } from '../../store/selectors/loadingSelector';
import { timelineSelector } from '../../store/selectors/timelineSelector';
import {
  getSurveysTimeline,
  clearSurveysTimeline,
} from '../../store/modules/timeline';
import { useTranslations } from '../../utility/useTranslations';
import { APP_PAGES } from '../../constants/pages';

const mapStateToProps = state => ({
  ...personReportsSelector(state),
  ...loadingSelector(state),
  ...timelineSelector(state),
});
const mapDispatchToProps = dispatch => ({
  getReviewedAttributes: (id, params) =>
    getReviewedAttributes(dispatch, id, params),
  getReportComments: (id, data) => getReportComments(dispatch, id, data),
  getBarChartData: (id, groupBy, data) =>
    getBarChartData(dispatch, id, groupBy, data),
  getSurveysTimeline: (id, data) => getSurveysTimeline(dispatch, id, data),
  clearReviewedAttributes: () => dispatch(clearReviewedAttributes()),
  clearReportComments: () => dispatch(clearReportComments()),
  clearUserReports: () => dispatch(clearUserReports()),
  clearSurveysTimeline: () => dispatch(clearSurveysTimeline()),
});

const PeopleInsightsPageView = props => {
  const { id } = useParams();
  const userId = (!Number.isNaN(Number(id)) && +id) || -1;
  const translations = useTranslations(APP_PAGES.PEOPLE_INSIGHTS);

  return (
    <PeoplePageContainer {...props} userId={userId} translations={translations}>
      <PeopleInsightsPage />
    </PeoplePageContainer>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PeopleInsightsPageView);
