import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import SurveyType from '../../surveyType';
import TextBoxWithTooltip from '../../textBoxWithTooltip';
import CustomButton from '../../customButton';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  title: {
    color: primary.bluish4,
    marginBottom: spacing(2),
  },
  type: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(3),
    height: 22,
  },
  surveyTitle: {
    marginBottom: spacing(3),
  },
  expiryDate: {
    color: primary.bluish4,
    marginBottom: spacing(2),
  },
  fillSurveyButton: {
    marginLeft: spacing(-2),
  },
  fillSurveyButtonLabel: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  buttonIcon: {
    marginRight: 0,
    marginLeft: spacing(2),
  },
}));

const FillSurveyCard = ({ translations, survey, onFillSurvey }) => {
  const classes = useStyles();
  const { id, title, type, expiryDate } = survey;

  const handleFillSurvey = useCallback(() => {
    onFillSurvey(id);
  }, [id, onFillSurvey]);

  return (
    <div>
      <Typography className={classes.title} variant="caption">
        {translations.title.toUpperCase()}
      </Typography>
      <SurveyType className={classes.type} type={type} />
      <TextBoxWithTooltip
        className={classes.surveyTitle}
        variant="h5"
        text={title}
      />
      <Typography className={classes.expiryDate} variant="caption">
        {`${translations.expiryDate}: ${expiryDate}`}
      </Typography>
      <CustomButton
        className={classes.fillSurveyButton}
        classes={{ label: classes.fillSurveyButtonLabel }}
        customIconWrapper={classes.buttonIcon}
        type="fillSurveySmall"
        onClick={handleFillSurvey}
      >
        {translations.fillSurvey}
      </CustomButton>
    </div>
  );
};

FillSurveyCard.defaultProps = {
  survey: {},
};

FillSurveyCard.propTypes = {
  survey: PropTypes.object,
  onFillSurvey: PropTypes.func.isRequired,
};

export default FillSurveyCard;
