import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = ({ palette: { primary } }) => ({
  main: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  root: {
    position: 'relative',
  },
  progressColor: { color: primary.blue2 },
});

const SectionLoader = ({ classes, isLoading, relativePosition }) => {
  if (isLoading) {
    return (
      <div className={relativePosition ? classes.root : classes.main}>
        <CircularProgress
          classes={{ colorPrimary: classes.progressColor }}
          size={50}
        />
      </div>
    );
  }

  return null;
};

SectionLoader.defaultProps = {
  relativePosition: false,
};

SectionLoader.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  relativePosition: PropTypes.bool,
};

export default withStyles(styles)(SectionLoader);
