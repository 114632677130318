import { useState, useCallback, useEffect, useRef } from 'react';
import { isArrayEmpty, isObjectEmpty } from './helpers';
import { parseDuplicateParameters } from './uiUtils';
import http from './http';
import { PARAMS } from '../constants/pages';
import { PEOPLE_DEFAULT_ORDERING } from '../constants/people';
import { ROLES } from '../constants/rolesAndPermissionList';
import { UserStatuses } from '../constants/statuses';
import { API_USERS_BASIC } from '../constants/apiRoutes';

export const usePreviousValue = value => {
  const currentValueRef = useRef(value);
  const previousValueRef = useRef();

  if (currentValueRef.current !== value) {
    previousValueRef.current = currentValueRef.current;
    currentValueRef.current = value;
  }

  return previousValueRef.current;
};

export const useCustomEffect = (
  callback,
  dependencies = [],
  shouldRunOnMount = true
) => {
  const isMountedRef = useRef(shouldRunOnMount);

  useEffect(() => {
    if (isMountedRef.current) return callback();

    isMountedRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};

export const useAvailableUsers = (params = {}, organizationUser = {}) => {
  const [availableUsers, setAvailableMUsers] = useState([]);

  const getAvailableUsers = useCallback(async () => {
    const { EXCLUDE_ROLE, EXCLUDE_STATUS } = PARAMS;
    const [, , , SUSPENDED] = UserStatuses;

    const { data } = await http.get(API_USERS_BASIC, {
      params: {
        ...PEOPLE_DEFAULT_ORDERING,
        [EXCLUDE_ROLE]: ROLES.ASSOCIATE,
        [EXCLUDE_STATUS]: SUSPENDED.id,
        ...params,
      },
      paramsSerializer: d => parseDuplicateParameters(d),
    });

    return setAvailableMUsers(data);
  }, [params]);

  const cleanup = useCallback(() => setAvailableMUsers([]), []);

  useEffect(() => {
    if (!isObjectEmpty(params)) {
      getAvailableUsers();
    }

    return cleanup;
  }, [params, cleanup, getAvailableUsers]);

  return isArrayEmpty(availableUsers)
    ? availableUsers
    : [
        ...(!isObjectEmpty(organizationUser) ? [organizationUser] : []),
        ...availableUsers,
      ];
};
