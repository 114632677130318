import { memo, useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import PageContainer from '../../shared/pageContainer';
import SectionTitle from '../../shared/sectionTitle';
import SectionLoader from '../../shared/sectionLoader';
import CustomDonutChart from '../../shared/customDonutChart';
import OverviewTable from '../../shared/overviewTable';
import UserAvatar from '../../shared/userAvatar';
import UserLabel from '../../shared/userLabel';
import NotificationCard from '../../shared/notificationCard';
import ProgressBarWithLabel from '../../shared/progressBarWithLabel';
import Tooltip from '../../shared/tooltip';
import AsyncListTooltip from '../../shared/asyncListTooltip';
import SelectField from '../../shared/selectField';
import { ReactComponent as NotificationIcon } from '../../../assets/icons/people_alt-icon.svg';
import { ReactComponent as TooltipIcon } from '../../../assets/icons/tooltip.svg';
import { useTranslations } from '../../../utility/useTranslations';
import { loadingSelector } from '../../../store/selectors/loadingSelector';
import { companyOverviewSelector } from '../../../store/selectors/companyOverviewSelector';
import { setSectionLoader } from '../../../store/modules/loading';
import {
  getActionPlansOverview,
  getCoursesOverview,
  getOneOnOneOverview,
  getModeratorsWithTeams,
  getTeamsOverview,
  getTeamsMetrics,
  clearActionPlansOverview,
  clearCoursesOverview,
  clearOneOnOneOverview,
  clearTeamsOverview,
  clearTeamsMetrics,
} from '../../../store/modules/companyOverview';
import {
  getAllManagersUnder,
  isArray,
  isArrayEmpty,
  prepareDonutChartData,
} from '../../../utility/helpers';
import {
  COMPANY_OVERVIEW_STATS_TYPES,
  TEAMS_OVERVIEW__DEFAULT_PARAMS,
  COMPANY_OVERVIEW_DEFAULT_PERIOD_FILTER,
} from '../../../constants/companyOverview';
import http from '../../../utility/http';
import { getDasboardPeriodOptions } from '../../../utility/dashboardUtils';
import {
  api_company_no_one_on_one,
  api_company_low_impressed,
} from '../../../constants/apiRoutes';
import { SECTION_LOADERS } from '../../../constants/sectionLoaders';
import { APP_PAGES, PARAMS } from '../../../constants/pages';
import { PERIOD_FILTER } from '../../../constants/filters';
import {
  prepareTeamsOverviewHeader,
  prepareTeamsOverviewResults,
} from './config';

const useStyles = makeStyles(
  ({ breakpoints, palette: { primary }, spacing }) => ({
    pageTitleWrapper: {
      display: 'flex',
      alignItems: 'center',
      '& > div': {
        marginRight: spacing(4),
        width: 250,
      },
    },
    pageTitle: {
      marginRight: spacing(4),
    },
    sectionTitleContainer: {
      borderBottom: `1px solid ${primary.bluish7}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingBottom: spacing(6),
    },
    sectionTitle: {
      display: 'flex',
      alignItems: 'center',
    },
    sectionDescription: {
      marginBottom: spacing(4),
      marginTop: spacing(1),
    },
    statsTooltipIcon: {
      marginLeft: spacing(3),
    },
    stats: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
      gridColumnGap: 8,
      marginTop: spacing(8),
      [breakpoints.up('xLg')]: {
        gridColumnGap: 16,
      },
    },
    statsOverviewChart: {
      position: 'relative',
      margin: '0 auto',
      width: '100%',
      maxWidth: 440,
      marginTop: 0,
      marginBottom: 0,
      minHeight: 200,

      [breakpoints.up('xLg')]: {
        marginTop: spacing(7),
        marginBottom: spacing(14),
      },
    },
    overviewTableContainer: {
      position: 'relative',
      minHeight: 200,
    },
    metricLabel: {
      marginLeft: spacing(1),
      [breakpoints.up('xLg')]: {
        marginLeft: spacing(2),
      },
      [breakpoints.up('xl')]: {
        marginLeft: spacing(4),
      },
    },
    teamOverviewTable: {
      '& .managerCell': {
        paddingLeft: 0,
        overflow: 'hidden',
        width: '100%',
      },
    },
    fullName: {
      fontFamily: 'ProximaNova-Bold',
    },
  })
);

const CompanyOverviewPage = ({ navigate, ...rest }) => {
  const classes = useStyles();
  const translations = useTranslations(APP_PAGES.COMPANY_OVERVIEW);

  const [currentPage, setCurrentPage] = useState(
    TEAMS_OVERVIEW__DEFAULT_PARAMS.page
  );
  const [period, setPeriod] = useState(COMPANY_OVERVIEW_DEFAULT_PERIOD_FILTER);
  const [selectedUser, setSelectedUser] = useState({
    id: -1,
    shouldRemoveUserAvatar: true,
  });

  const {
    actionPlansOverview,
    coursesOverview,
    oneOnOneOverview,
    teamsOverview,
    teamsMetrics,
    moderatorsWithTeams,
    formattedModeratorsWithTeams,
  } = useSelector(companyOverviewSelector);
  const { sectionLoaders } = useSelector(loadingSelector);
  const dispatch = useDispatch();

  const { sectionTitles, teamOverviewTable, tooltipLabels } = translations;
  const periodOptions = getDasboardPeriodOptions(translations.periodOptions);
  const periodParams = useMemo(() => {
    const selectedPeriodData = PERIOD_FILTER.find(
      data => data.value === period
    )?.filter;

    return selectedPeriodData
      ? {
          [PARAMS.START_AT]: selectedPeriodData.start,
        }
      : {};
  }, [period]);

  const usersParams = useMemo(() => {
    return !selectedUser.shouldRemoveUserAvatar
      ? {
          users: getAllManagersUnder(
            selectedUser.id,
            formattedModeratorsWithTeams
          ),
        }
      : {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser]);

  const getOverviewData = useCallback(async () => {
    const params = {
      ...periodParams,
      ...usersParams,
    };

    dispatch(setSectionLoader(SECTION_LOADERS.TEAMS_OVERVIEW, true));
    getActionPlansOverview(dispatch, params);
    getCoursesOverview(dispatch, params);
    getOneOnOneOverview(dispatch, params);
    await Promise.all([
      getTeamsOverview(dispatch, {
        ...TEAMS_OVERVIEW__DEFAULT_PARAMS,
        ...params,
      }),
      getTeamsMetrics(dispatch, {
        ...TEAMS_OVERVIEW__DEFAULT_PARAMS,
        ...params,
      }),
    ]);
    dispatch(setSectionLoader(SECTION_LOADERS.TEAMS_OVERVIEW));
    setCurrentPage(TEAMS_OVERVIEW__DEFAULT_PARAMS.page);
  }, [dispatch, periodParams, usersParams]);

  const cleanup = useCallback(() => {
    dispatch(clearActionPlansOverview());
    dispatch(clearCoursesOverview());
    dispatch(clearOneOnOneOverview());
    dispatch(clearTeamsOverview());
    dispatch(clearTeamsMetrics());
  }, [dispatch]);

  const onLoadMore = async () => {
    const updatedParams = {
      ...TEAMS_OVERVIEW__DEFAULT_PARAMS,
      page: currentPage + 1,
      ...periodParams,
      ...usersParams,
    };

    await Promise.all([
      getTeamsOverview(dispatch, updatedParams, true),
      getTeamsMetrics(dispatch, updatedParams, true),
    ]);
    setCurrentPage(prevPage => prevPage + 1);
  };

  useEffect(() => {
    getOverviewData();
  }, [getOverviewData]);

  useEffect(() => {
    getModeratorsWithTeams(dispatch);
  }, [dispatch]);

  useEffect(() => {
    return cleanup;
  }, [cleanup]);

  const goToProfilePage = userId => navigate(`/people/${userId}`);

  const goToOneOnOnePage = userId => navigate(`/people/${userId}/one-on-one/`);

  const handleActiveCellClick = (data, type) => {
    const { ACTION_PLANS_OVERVIEW } = COMPANY_OVERVIEW_STATS_TYPES;

    if (type === ACTION_PLANS_OVERVIEW) {
      return navigate('/action-plans', { state: { filters: data.filters } });
    }

    return navigate('/1-1', {
      state: {
        filters: data.filters,
        period: data.period,
      },
    });
  };

  const getDetailsData =
    (managerId, isLowImpressed = false) =>
    () =>
      isLowImpressed
        ? http.get(api_company_low_impressed(managerId), {
            params: periodParams,
          })
        : http.get(api_company_no_one_on_one(managerId), {
            params: periodParams,
          });

  const handleChangePeriod = value => {
    if (value !== period) return setPeriod(value);
  };

  const renderOverviewTitle = () => (
    <div className={classes.pageTitleWrapper}>
      <Typography variant="h1" className={classes.pageTitle}>
        {translations.pageTitle}
      </Typography>
      <SelectField
        value={period}
        options={periodOptions}
        onChange={handleChangePeriod}
        isValueHighlighted
        shouldRemoveLabel
        isSearchDisabled
      />
      <SelectField
        parser={{
          value: 'id',
          label: 'first_name',
        }}
        value={selectedUser}
        options={moderatorsWithTeams}
        onChange={user => setSelectedUser(user)}
        shouldReturnOption
        isUser
        shouldRemoveLabel
        isValueHighlighted
      />
    </div>
  );

  const renderSectionTitle = ({
    title,
    description,
    isStatsTitle = false,
    tooltipText,
  }) => (
    <div
      className={classNames({
        [classes.sectionTitleContainer]: isStatsTitle,
      })}
    >
      <div className={classes.sectionTitle}>
        <SectionTitle title={title} />
        {tooltipText && (
          <Tooltip
            customIconClass={classes.statsTooltipIcon}
            text={tooltipText}
            icon={TooltipIcon}
          />
        )}
      </div>
      {description && (
        <Typography className={classes.sectionDescription} variant="body2">
          {description}
        </Typography>
      )}
    </div>
  );

  const renderStatsOverviewSection = (stats, placeholderKey, loaderKey) => {
    const { ACTION_PLANS_OVERVIEW, COURSES_OVERVIEW } =
      COMPANY_OVERVIEW_STATS_TYPES;
    const data = prepareDonutChartData(
      translations.overviewStatusLabels,
      stats,
      placeholderKey === ACTION_PLANS_OVERVIEW
    );
    return (
      <div className={classes.statsOverviewChart}>
        <SectionLoader isLoading={!!sectionLoaders[loaderKey]} />
        {!sectionLoaders[loaderKey] && data && (
          <CustomDonutChart
            data={data}
            placeholderText={translations.overviewPlaceholders[placeholderKey]}
            onRedirect={cellData =>
              handleActiveCellClick(cellData, placeholderKey)
            }
            isInteractive={placeholderKey !== COURSES_OVERVIEW}
            hasNotStartedStatus={placeholderKey !== ACTION_PLANS_OVERVIEW}
          />
        )}
      </div>
    );
  };

  const renderManager = manager => (
    <UserAvatar
      labelClass={classes.fullName}
      user={manager}
      captionDescription={
        manager.position ? manager.position.name : translations.noJobTitle
      }
      onClickHandler={() => goToProfilePage(manager.id)}
      clickableCaption
      caption
      withDescription
      medium
    />
  );

  const renderMetric = (value, barColor) => (
    <ProgressBarWithLabel
      customLabelClass={classes.metricLabel}
      value={value}
      barColorType={barColor}
    />
  );

  const renderUserLabel = user => <UserLabel user={user} />;

  const renderDetailsTooltip = (managerId, value, isLowImpressed = false) => (
    <AsyncListTooltip
      position="top"
      getLabelData={getDetailsData(managerId, isLowImpressed)}
      renderLabelItem={renderUserLabel}
      onItemClickHandler={goToOneOnOnePage}
      title={
        isLowImpressed
          ? translations.teamOverviewTable.headerLabels.oneOnOne.subLabels
              .negativeImpressions
          : translations.teamOverviewTable.headerLabels.oneOnOne.subLabels
              .withoutOneOnOne
      }
    >
      {isLowImpressed ? `${value}%` : value}
    </AsyncListTooltip>
  );

  return (
    <PageContainer
      {...rest}
      translations={translations}
      headerProps={{
        renderTitle: renderOverviewTitle,
      }}
      navigate={navigate}
      shouldPassProps={false}
      isFullWidthContent
    >
      <div>
        <div className={classes.stats}>
          <div>
            {renderSectionTitle({
              title: sectionTitles.oneOnOne,
              isStatsTitle: true,
              tooltipText: selectedUser.shouldRemoveUserAvatar
                ? tooltipLabels.companyOneOnOne
                : tooltipLabels.oneOnOne,
            })}
            {renderStatsOverviewSection(
              oneOnOneOverview,
              COMPANY_OVERVIEW_STATS_TYPES.ONE_ON_ONE_OVERVIEW,
              SECTION_LOADERS.ONE_ON_ONE_OVERVIEW
            )}
          </div>
          <div>
            {renderSectionTitle({
              title: sectionTitles.actionPlans,
              isStatsTitle: true,
              tooltipText: selectedUser.shouldRemoveUserAvatar
                ? tooltipLabels.companyActionPlans
                : tooltipLabels.actionPlans,
            })}
            {renderStatsOverviewSection(
              actionPlansOverview,
              COMPANY_OVERVIEW_STATS_TYPES.ACTION_PLANS_OVERVIEW,
              SECTION_LOADERS.ACTION_PLANS_OVERVIEW
            )}
          </div>
          <div>
            {renderSectionTitle({
              title: sectionTitles.courses,
              isStatsTitle: true,
              tooltipText: selectedUser.shouldRemoveUserAvatar
                ? tooltipLabels.companyCourses
                : tooltipLabels.courses,
            })}
            {renderStatsOverviewSection(
              coursesOverview,
              COMPANY_OVERVIEW_STATS_TYPES.COURSES_OVERVIEW,
              SECTION_LOADERS.COURSES_OVERVIEW
            )}
          </div>
        </div>
        <div>
          {renderSectionTitle({
            title: sectionTitles.overview.title,
            description: sectionTitles.overview.description,
          })}
          <div className={classes.overviewTableContainer}>
            <SectionLoader
              isLoading={!!sectionLoaders[SECTION_LOADERS.TEAMS_OVERVIEW]}
            />
            {!sectionLoaders[SECTION_LOADERS.TEAMS_OVERVIEW] ? (
              <>
                {isArray(teamsOverview.results) &&
                isArray(teamsMetrics.results) &&
                !isArrayEmpty(teamsOverview.results) &&
                !isArrayEmpty(teamsMetrics.results) ? (
                  <OverviewTable
                    className={classes.teamOverviewTable}
                    headers={prepareTeamsOverviewHeader(
                      teamOverviewTable.headerLabels
                    )}
                    rows={prepareTeamsOverviewResults(
                      teamsOverview.results,
                      teamsMetrics.results,
                      renderManager,
                      renderMetric,
                      renderDetailsTooltip
                    )}
                    showLoadMore={!!teamsOverview.next}
                    buttonLabel={teamOverviewTable.loadButtonLabel}
                    onLoadMore={onLoadMore}
                    hasSubHeaders
                  />
                ) : (
                  <NotificationCard
                    customIcon={NotificationIcon}
                    title={teamOverviewTable.noResults.title}
                    content={teamOverviewTable.noResults.content}
                    hasIcon
                  />
                )}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

CompanyOverviewPage.propTypes = {
  navigate: PropTypes.func.isRequired,
};

export default memo(CompanyOverviewPage);
