import { createAction, handleActions } from 'redux-actions';
import { getNextRecurring } from 'utility/oneOnOne';
import http from '../../utility/http';
import { isObjectEmpty, replaceObjectInList } from '../../utility/helpers';
import { parseDuplicateParameters } from '../../utility/uiUtils';
import {
  API_ONE_ON_ONE,
  api_one_on_one_comments,
  api_one_on_one_record,
  api_one_on_one_tasks_status,
} from '../../constants/apiRoutes';
import { ONE_ON_ONE_DEFAULT_PARAMS } from '../../constants/pages';

// ------------------------------------
// Constants
// ------------------------------------

const SET_ONE_ON_ONE_RECORDS = 'SET_ONE_ON_ONE_RECORDS';
const SET_ONE_ON_ONE_RECORD = 'SET_ONE_ON_ONE_RECORD';
const LOAD_MORE_ONE_ON_ONE_RECORDS = 'LOAD_MORE_ONE_ON_ONE_RECORDS';
const SET_ONE_ON_ONE_ACTIVITIES = 'SET_ONE_ON_ONE_ACTIVITIES';
const SET_ONE_ON_ONE_TASK_STATUS = 'SET_ONE_ON_ONE_TASK_STATUS';

const initialState = {
  oneOnOne: {},
  oneOnOneRecord: {},
  oneOnOneComments: {},
};

// ------------------------------------
// Actions
// ------------------------------------

const setOneOnOneRecords = createAction(SET_ONE_ON_ONE_RECORDS);
const setOneOnOneRecord = createAction(SET_ONE_ON_ONE_RECORD);
const setOneOnOneTaskStatus = createAction(SET_ONE_ON_ONE_TASK_STATUS);
const loadMoreOneOnOneRecords = createAction(LOAD_MORE_ONE_ON_ONE_RECORDS);
const setOneOnOneComments = createAction(SET_ONE_ON_ONE_ACTIVITIES);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_ONE_ON_ONE_RECORDS]: (state, { payload }) => {
    return { ...state, oneOnOne: payload };
  },
  [SET_ONE_ON_ONE_RECORD]: (state, { payload }) => {
    return {
      ...state,
      oneOnOneRecord: {
        ...payload,
        nextRecurring: getNextRecurring(
          payload?.meeting_time,
          payload?.recurring
        ),
      },
    };
  },
  [SET_ONE_ON_ONE_TASK_STATUS]: (state, { payload }) => {
    const taskIndex = state.oneOnOneRecord.tasks.findIndex(
      currentTask => currentTask.id === payload.id
    );

    return {
      ...state,
      oneOnOneRecord: {
        ...state.oneOnOneRecord,
        tasks: replaceObjectInList(
          state.oneOnOneRecord.tasks,
          taskIndex,
          payload
        ),
      },
    };
  },
  [LOAD_MORE_ONE_ON_ONE_RECORDS]: (state, { payload }) => {
    return isObjectEmpty(payload)
      ? { ...state, oneOnOne: payload }
      : {
          ...state,
          oneOnOne: {
            ...payload,
            results: state.oneOnOne.results
              ? [...state.oneOnOne.results, ...payload.results]
              : [...payload.results],
          },
        };
  },
  [SET_ONE_ON_ONE_ACTIVITIES]: (state, { payload }) => {
    const { data, page = 1 } = payload;
    if (page === 1) {
      return {
        ...state,
        oneOnOneComments: data,
      };
    }

    return {
      ...state,
      oneOnOneComments: {
        ...data,
        results: [...state.oneOnOneComments.results, ...data.results],
      },
    };
  },
};

// ------------------------------------
// API calls
// ------------------------------------

export const getOneOnOneRecords = (dispatch, isLoadMore, params = {}) => {
  return http
    .get(API_ONE_ON_ONE, {
      params: {
        ...ONE_ON_ONE_DEFAULT_PARAMS,
        ...params,
      },
      paramsSerializer: data => parseDuplicateParameters(data),
    })
    .then(({ data }) =>
      dispatch(
        isLoadMore ? loadMoreOneOnOneRecords(data) : setOneOnOneRecords(data)
      )
    );
};

export const getOneOnOneRecord = (dispatch, recordId) => {
  return http
    .get(api_one_on_one_record(recordId))
    .then(({ data }) => dispatch(setOneOnOneRecord(data)));
};

export const getOneOnOneComments = (dispatch, oneOnOneId, params) => {
  return http
    .get(api_one_on_one_comments(oneOnOneId), { params })
    .then(({ data }) => {
      dispatch(setOneOnOneComments({ data, page: params.page }));
    });
};

export const changeOneOnOneTaskStatus = (dispatch, recordId, taskId) => {
  return http
    .patch(api_one_on_one_tasks_status(recordId, taskId))
    .then(({ data }) => dispatch(setOneOnOneTaskStatus(data)));
};

export default handleActions(reducers, initialState);

// ------------------------------------
// "plain" action functions
// ------------------------------------

export const clearOneOnOneRecords = () =>
  setOneOnOneRecords(initialState.oneOnOne);
export const clearOneOnOneRecord = () =>
  setOneOnOneRecord(initialState.oneOnOneRecord);
export const clearOneOnOneComments = () =>
  setOneOnOneComments(initialState.oneOnOneComments);
