import { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import TagHash from '../tagHash';
import TextBoxWithTooltip from '../textBoxWithTooltip';
import ActionButton from '../actionButton';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  main: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    minWidth: 0,
  },
  notSelectable: {
    cursor: 'default',
  },
  label: {
    transition: 'color .25s ease',
    '&:hover': {
      color: ({ color }) => color || primary.bluish1,
    },
  },
  selected: {
    fontFamily: 'ProximaNova-Bold',
  },
  disabledLabel: {
    color: primary.bluish5,
    cursor: 'default',
    transition: 'color .25s ease',
  },
  button: {
    flexShrink: 0,
    width: 24,
    height: 24,
    marginLeft: spacing(1),
  },
  icon: {
    width: 16,
    height: 16,
  },
}));

const Tag = ({
  className,
  tag,
  color,
  hasRemove,
  isSelected,
  isDisabled,
  isSelectable,
  isSmall,
  onTagSelect,
}) => {
  const classes = useStyles({ color });

  const handleTagSelect = useCallback(
    () => onTagSelect(tag),
    [tag, onTagSelect]
  );

  return (
    <Typography
      variant="body2"
      component="div"
      className={classNames(
        classes.main,
        {
          [classes.notSelectable]: !isSelectable || isDisabled || hasRemove,
        },
        className
      )}
      onClick={
        isSelectable && !hasRemove && !isDisabled ? handleTagSelect : () => {}
      }
    >
      <TagHash
        className={classNames({ [classes.selected]: isSelected })}
        bgColor={color}
        isSmall={isSmall}
      />
      <TextBoxWithTooltip
        className={classNames({
          [classes.label]: isSelectable && !isSelected && !isDisabled,
          [classes.selected]: isSelected,
          [classes.disabledLabel]: isDisabled,
        })}
        variant={isSmall ? 'body2' : undefined}
        text={tag.name}
      />
      {hasRemove && (
        <ActionButton
          className={classes.button}
          iconClass={classes.icon}
          type={ACTION_BUTTON_TYPES.CLOSE}
          size="small"
          onClickHandler={handleTagSelect}
        />
      )}
    </Typography>
  );
};

Tag.defaultProps = {
  className: null,
  color: '',
  isSelected: false,
  isSelectable: false,
  isDisabled: false,
  isSmall: false,
  hasRemove: false,
  onTagSelect: () => {},
};

Tag.propTypes = {
  className: PropTypes.string,
  tag: PropTypes.object.isRequired,
  color: PropTypes.string,
  isSelected: PropTypes.bool,
  isSelectable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSmall: PropTypes.bool,
  hasRemove: PropTypes.bool,
  onTagSelect: PropTypes.func,
};

export default memo(Tag);
