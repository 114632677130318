import { connect } from 'react-redux';
import { PLACEMENTS } from 'constants/tags';
import AttributesPage from '../../components/pages/attributesPage';
import PageContainer from '../../components/shared/pageContainer';
import { attributesSelector } from '../../store/selectors/attributesSelector';
import { attributeSelector } from '../../store/selectors/attributeSelector';
import { dialogSelector } from '../../store/selectors/dialogSelector';
import { tagsSelector } from '../../store/selectors/tagsSelector';
import {
  getAttributes,
  clearAttributes,
  setAttributes,
} from '../../store/modules/attributes';
import {
  getAttribute,
  clearAttribute,
  setAttribute,
  updateAttribute,
  setAttributeQuestions,
  updateAttributeQuestions,
  updateAttributeQuestion,
  createQuestion,
  reorderQuestions,
  deleteAttributeQuestion,
  deleteAllAttributeQuestions,
} from '../../store/modules/attribute';
import {
  getTagsCategories,
  clearTagsCategories,
  addMultipleTags,
  getPageQuickFilters,
  clearPageQuickFilters,
} from '../../store/modules/tags';
import { useTranslations } from '../../utility/useTranslations';
import { APP_PAGES } from '../../constants/pages';

const mapStateToProps = state => ({
  ...attributesSelector(state),
  ...attributeSelector(state),
  ...dialogSelector(state),
  ...tagsSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getAttributes: (isLoadMore, data) =>
    getAttributes(dispatch, isLoadMore, data),
  clearAttributes: () => dispatch(clearAttributes()),
  getAttribute: id => getAttribute(dispatch, id),
  clearAttribute: () => dispatch(clearAttribute()),
  updateAttribute: (id, data) => updateAttribute(dispatch, id, data),
  setAttributeQuestions: (id, data) =>
    setAttributeQuestions(dispatch, id, data),
  updateAttributeQuestions: (id, data) =>
    updateAttributeQuestions(dispatch, id, data),
  updateAttributeQuestion: (id, data) =>
    updateAttributeQuestion(dispatch, id, data),
  setAttribute: data => dispatch(setAttribute(data)),
  createQuestion: data => createQuestion(dispatch, data),
  reorderQuestions: data => reorderQuestions(dispatch, data),
  deleteAttributeQuestion: questionId =>
    deleteAttributeQuestion(dispatch, questionId),
  deleteAllAttributeQuestions: id => deleteAllAttributeQuestions(dispatch, id),
  addMultipleTags: data => dispatch(addMultipleTags(data)),
  getTagsCategories: () => getTagsCategories(dispatch),
  getPageQuickFilters: () =>
    getPageQuickFilters(dispatch, PLACEMENTS.ATTRIBUTE.id),
  clearPageQuickFilters: () => dispatch(clearPageQuickFilters()),
  clearTagsCategories: () => dispatch(clearTagsCategories()),
  setAttributes: data => dispatch(setAttributes(data)),
});

const AttributesPageView = props => {
  const translations = useTranslations(APP_PAGES.ATTRIBUTES);

  return (
    <PageContainer {...props} translations={translations} isFullWidthContent>
      <AttributesPage />
    </PageContainer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AttributesPageView);
