import { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TransitionGroup } from 'react-transition-group';
import { Fade, makeStyles } from '@material-ui/core';
import ActionButton from '../../actionButton';
import CustomScrollBar from '../../customScrollBar';
import Tag from '../../tag';
import AttributeChip from '../../attributeChip';
import UsersList from '../../usersList';
import ReorderItems from '../../reorderItems';
import TextBoxWithTooltip from '../../textBoxWithTooltip';
import { reorder } from '../../../../utility/helpers';
import { ACTION_BUTTON_TYPES } from '../../actionButton/config';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    marginTop: spacing(2),
  },
  rootVertical: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: spacing(2),
  },
  rootDraggable: {
    flexWrap: 'nowrap',
    flexDirection: 'column',
  },
  attribute: {
    minWidth: 0,
    margin: spacing(0, 1, 1, 0),
  },
  attributeDraggableItem: {
    display: 'flex',
    minWidth: 0,
  },
  tagWrapper: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
    padding: spacing(0, 1, 1, 0),
  },
  itemWrapper: {
    boxSizing: 'border-box',
    display: 'flex',
    maxWidth: '100%',
    marginBottom: spacing(2),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  usersWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: spacing(4),
    width: '100%',
    minWidth: 0,
  },
  usersWrapperScrollable: {
    maxHeight: 188,
  },
  button: {
    flexShrink: 0,
    width: 24,
    height: 24,
    marginLeft: spacing(1),
  },
  icon: {
    width: 16,
    height: 16,
  },
  scrollY: {
    top: 0,
    right: -21,
    height: '100%',
  },
}));

const SelectedItems = ({
  items,
  isTag,
  isAttribute,
  isUser,
  isScrollableResult,
  isAttributeDraggable,
  onReorder,
  onDeselectItem,
}) => {
  const classes = useStyles();

  const handleReorderAttributes = useCallback(
    result => {
      if (
        !result.destination ||
        result.source.index === result.destination.index
      )
        return;

      const updatedOrder = reorder(
        items,
        result.source.index,
        result.destination.index
      );

      return onReorder(updatedOrder);
    },
    [onReorder, items]
  );

  const handleRemoveItem = useCallback(
    item => () => {
      onDeselectItem(item);
    },
    [onDeselectItem]
  );

  if (isUser) {
    return (
      <div
        className={classNames(classes.usersWrapper, {
          [classes.usersWrapperScrollable]: isScrollableResult,
        })}
      >
        <CustomScrollBar
          customScrollBarYClass={classes.scrollY}
          verticalScroll
          removeScrollX
          passContentHeight
          passContentWidth
        >
          <UsersList
            users={items}
            onRemoveUser={onDeselectItem}
            isVerticalList
            isUserRemovable
          />
        </CustomScrollBar>
      </div>
    );
  }

  if (isAttribute) {
    return (
      <ReorderItems
        className={classNames(classes.root, {
          [classes.rootDraggable]: isAttributeDraggable,
        })}
        itemClass={classes.attributeDraggableItem}
        isDragDisabled={!isAttributeDraggable}
        onSave={handleReorderAttributes}
      >
        {items.map(item => (
          <AttributeChip
            key={`value${item.id}`}
            id={item.id}
            customClass={classes.attribute}
            attribute={item}
            name={item.name}
            color={item?.color}
            isAttributeDraggable={isAttributeDraggable}
            onRemove={onDeselectItem}
            isSelected
            hasRemove
          />
        ))}
      </ReorderItems>
    );
  }

  if (isTag) {
    return (
      <TransitionGroup className={classes.root}>
        {items.map(item => (
          <Fade key={`value${item.id}`} in>
            <div className={classes.tagWrapper}>
              <Tag
                tag={item}
                color={item?.color}
                onTagSelect={onDeselectItem}
                isSelectable
                isSelected
                hasRemove
              />
            </div>
          </Fade>
        ))}
      </TransitionGroup>
    );
  }

  return (
    <TransitionGroup className={classes.rootVertical}>
      {items.map(item => (
        <Fade key={`value${item.id}`} in>
          <div className={classes.itemWrapper}>
            <TextBoxWithTooltip
              variant="subtitle2"
              text={item.title || item.name}
            />
            <ActionButton
              className={classes.button}
              iconClass={classes.icon}
              type={ACTION_BUTTON_TYPES.CLOSE}
              size="small"
              onClickHandler={handleRemoveItem(item)}
            />
          </div>
        </Fade>
      ))}
    </TransitionGroup>
  );
};

SelectedItems.defaultProps = {
  isTag: false,
  isAttribute: false,
  isUser: false,
  isScrollableResult: false,
  isAttributeDraggable: false,
  onReorder: () => {},
};

SelectedItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  isUser: PropTypes.bool,
  isScrollableResult: PropTypes.bool,
  isTag: PropTypes.bool,
  isAttribute: PropTypes.bool,
  isAttributeDraggable: PropTypes.bool,
  onDeselectItem: PropTypes.func.isRequired,
  onReorder: PropTypes.func,
};

export default memo(SelectedItems);
