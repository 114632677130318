import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, withStyles, alpha } from '@material-ui/core';
import FillReviewFlow from '../fillReviewFlow';
import CustomButton from '../customButton';
import ConditionalTooltip from '../conditionalTooltip';
import ActionButton from '../actionButton';
import HeaderImage from '../../../assets/images/kadar_header.png';
import { ReactComponent as PointIcon } from '../../../assets/icons/finger_point.svg';
import { ReactComponent as SmileIcon } from '../../../assets/icons/smile.svg';
import {
  getUnionOfTwoArrays,
  isArrayEmpty,
  areArrayItemsReordered,
} from '../../../utility/helpers';
import { prepareSurveyData } from '../../../utility/fillSurveyUtils';
import { CREATE_SURVEY_FIELD_NAMES } from '../../../constants/survey';
import { ATTRIBUTES } from '../../../constants/attributes';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';
import { getSurveySteps } from '../../pages/fillSurveyPage/config';
import {
  isPreviewAvailable,
  prepareAttributeData,
  getOrganizationSubject,
} from './config';

const styles = ({ breakpoints, palette: { primary }, spacing }) => ({
  main: {
    position: 'relative',
    width: '100%',
    height: '100%',
    maxHeight: 'calc(100vh - 224px)',
    [breakpoints.up('xLg')]: {
      maxHeight: 'calc(100vh - 236px)',
    },
  },
  fillReviewFlow: {
    backgroundColor: primary.white,
    boxSizing: 'border-box',
    boxShadow: `0px 2px 8px ${alpha(primary.black, 0.07)}`,
    padding: spacing(5, 5, 4, 5),
    height: '100%',
    [breakpoints.up('xLg')]: {
      padding: spacing(8, 8, 4, 8),
    },
  },
  header: {
    background: `${primary.white} url(${HeaderImage})  center / cover no-repeat`,
    width: '100%',
    height: 110,
  },
  emailMessageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '100%',
    height: '100%',
  },
  emailContent: {
    width: 600,
    minHeight: 200,
  },
  emailUser: {
    margin: spacing(3, 0),
  },
  emailMessage: {
    maxHeight: 200,
    wordBreak: 'break-word',
    overflowY: 'hidden',
  },
  actionWrapper: {
    display: 'flex',
    justifyContent: 'center',
    borderBottom: `1px solid ${primary.bluish6}`,
    paddingBottom: spacing(5),
  },
  fillSurveyButton: {
    backgroundColor: primary.bluish1,
    border: 'none',
    borderRadius: 3,
    color: primary.white,
    fontFamily: 'ProximaNova-Bold',
    padding: spacing(2.5, 6),
    maxHeight: 36,
    marginTop: spacing(5),
    '&:hover': {
      backgroundColor: primary.bluish1,
      color: primary.white,
    },
    '&:disabled': {
      backgroundColor: primary.bluish6,
      border: `1px solid ${primary.bluish6}`,
    },
  },
  buttonIconWrapper: {
    marginLeft: spacing(1),
    marginRight: 0,
  },
  buttonIcon: {
    height: 16,
    width: 16,
  },
  buttonIconDisabled: {
    '& g path': {
      fill: `${primary.bluish4} !important`,
    },
  },
  emailFooter: {
    textAlign: 'center',
    marginTop: spacing(4),
  },
  smileIcon: {
    display: 'block',
    margin: spacing(5, 'auto', 0, 'auto'),
  },
  previewRestartButton: {
    position: 'absolute',
    right: 0,
    top: -45,
  },
});

const {
  ATTRIBUTES: ATTRIBUTES_FIELD,
  CREATED_FOR,
  IS_ANONYMOUS,
  EMAIL_MESSAGE,
} = CREATE_SURVEY_FIELD_NAMES;

class SurveyPreview extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isPreviewActive: false,
      hasPreview: false,
      survey: {},
      review: {},
      surveyStep: 0,
      reviewStep: 0,
    };
  }

  componentDidMount() {
    this.setSurveyPreviewData();
  }

  componentDidUpdate(prevProps) {
    const { values } = this.props;
    const { isPreviewActive } = this.state;

    if (
      prevProps.values[IS_ANONYMOUS] !== values[IS_ANONYMOUS] ||
      prevProps.values[ATTRIBUTES_FIELD].length !==
        values[ATTRIBUTES_FIELD].length ||
      prevProps.values[CREATED_FOR].length !== values[CREATED_FOR].length ||
      getUnionOfTwoArrays(
        prevProps.values[ATTRIBUTES_FIELD] || [],
        values[ATTRIBUTES_FIELD]
      ).length !== values[ATTRIBUTES_FIELD].length ||
      getUnionOfTwoArrays(
        prevProps.values[CREATED_FOR] || [],
        values[CREATED_FOR]
      ).length !== values[CREATED_FOR].length ||
      areArrayItemsReordered(
        prevProps.values[ATTRIBUTES_FIELD] || [],
        values[ATTRIBUTES_FIELD] || []
      )
    ) {
      this.setSurveyPreviewData();
    }

    if (
      prevProps.values[EMAIL_MESSAGE] !== values[EMAIL_MESSAGE] &&
      isPreviewActive
    ) {
      this.handleRestart();
    }
  }

  setSurveyPreviewData = () => {
    const { values, onClearReview } = this.props;
    const hasPreview = isPreviewAvailable(values);
    const survey = prepareSurveyData({
      ...values,
      subjects: !isArrayEmpty(values[CREATED_FOR])
        ? values[CREATED_FOR]
        : getOrganizationSubject(values.company),
    });

    this.setState({
      isPreviewActive: false,
      hasPreview,
      survey: hasPreview ? survey : {},
      review: {},
      surveyStep: 0,
      reviewStep: 0,
    });
    onClearReview();
  };

  handleGetStarted = attributeId => () => {
    const { values, onGetReview } = this.props;
    const { survey } = this.state;

    return onGetReview(attributeId).then(() =>
      this.setState({
        review: prepareAttributeData(
          this.props.attribute,
          survey.subjects,
          values
        ),
      })
    );
  };

  handleStepChange = step => {
    const { values, onGetReview } = this.props;
    const { survey, surveyStep } = this.state;

    if (step === -1) {
      return onGetReview(survey[ATTRIBUTES_FIELD][surveyStep - 1].id).then(
        () => {
          const { attribute } = this.props;
          const hasAdditionalFeedback =
            attribute[ATTRIBUTES.fields.withAdditionalFeedback];
          const questionsCount = attribute.questions.length;

          return this.setState(prevState => ({
            review: prepareAttributeData(attribute, survey.subjects, values),
            surveyStep: prevState.surveyStep - 1,
            reviewStep: hasAdditionalFeedback
              ? questionsCount
              : questionsCount - 1,
          }));
        }
      );
    }

    return this.setState({ reviewStep: step });
  };

  handleSubmit = () => {
    const { values, onGetReview } = this.props;
    const { survey, surveyStep } = this.state;

    if (surveyStep + 1 < survey.totalReviews) {
      return onGetReview(survey[ATTRIBUTES_FIELD][surveyStep + 1].id).then(
        () => {
          this.setState({
            reviewStep: 0,
            surveyStep: surveyStep + 1,
            review: prepareAttributeData(
              this.props.attribute,
              survey.subjects,
              values
            ),
          });
        }
      );
    }
  };

  handleOpenSurvey = () => this.setState({ isPreviewActive: true });

  handleRestart = () => {
    const { onClearReview } = this.props;

    return this.setState(
      {
        isPreviewActive: false,
        surveyStep: 0,
        reviewStep: 0,
        review: {},
      },
      onClearReview
    );
  };

  renderEmailMessage = () => {
    const { classes, translations, values } = this.props;
    const { hasPreview } = this.state;

    return (
      <div className={classes.emailMessageWrapper}>
        <div className={classes.emailContent}>
          <div className={classes.header} />
          <Typography className={classes.emailUser} variant="h4">
            {translations.user}
          </Typography>
          <Typography className={classes.emailMessage}>
            {values.message}
          </Typography>
          <div className={classes.actionWrapper}>
            <ConditionalTooltip
              message={translations.disabledButtonTooltip}
              addTooltip={!hasPreview}
            >
              <CustomButton
                className={classes.fillSurveyButton}
                classes={{ startIcon: classes.buttonIconWrapper }}
                type="addWithTextRounded"
                endIcon={
                  <PointIcon
                    className={classNames({
                      [classes.buttonIconDisabled]: !hasPreview,
                    })}
                  />
                }
                disabled={!hasPreview}
                onClick={this.handleOpenSurvey}
              >
                {translations.fillSurveyButton}
              </CustomButton>
            </ConditionalTooltip>
          </div>
          <Typography className={classes.emailFooter} variant="subtitle2">
            {translations.emailFooter}
          </Typography>
          <SmileIcon className={classes.smileIcon} />
        </div>
      </div>
    );
  };

  renderPreview = () => {
    const { classes, translations } = this.props;
    const { survey, surveyStep, reviewStep, review } = this.state;

    return (
      <div className={classes.fillReviewFlow}>
        <FillReviewFlow
          translations={translations.fillSurvey}
          surveyStep={surveyStep}
          survey={survey}
          review={review}
          steps={getSurveySteps(review)}
          activeStep={reviewStep}
          onStepChange={this.handleStepChange}
          onSubmit={this.handleSubmit}
          onGetStarted={this.handleGetStarted(survey[ATTRIBUTES_FIELD][0].id)}
          isPreview
        />
      </div>
    );
  };

  render() {
    const { classes, className, translations } = this.props;
    const { isPreviewActive, hasPreview } = this.state;

    return (
      <div className={classNames(classes.main, className)}>
        {isPreviewActive && (
          <ActionButton
            className={classes.previewRestartButton}
            type={ACTION_BUTTON_TYPES.RELOAD}
            tooltipText={translations.restart}
            onClickHandler={this.handleRestart}
          />
        )}
        {hasPreview && isPreviewActive
          ? this.renderPreview()
          : this.renderEmailMessage()}
      </div>
    );
  }
}

SurveyPreview.defaultProps = {
  className: null,
};

SurveyPreview.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  values: PropTypes.shape({}).isRequired,
  onGetReview: PropTypes.func.isRequired,
  onClearReview: PropTypes.func.isRequired,
};

export default withStyles(styles)(SurveyPreview);
