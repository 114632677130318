import { ReactComponent as AnonymousIcon } from '../../../assets/icons/anonymous-user.svg';
import {
  isEmpty,
  validateLength,
  forbiddenCharacters,
  validateReviews,
} from '../../../utility/validation';
import { FIELD_TYPES } from '../../../constants/fieldTypes';

export const SUMMARY_FORM_INITIAL_DATA = {
  title: '',
  description: '',
  report_data: [],
  share_to: [],
  anon_reviewers: false,
};

export const getSurveySummaryFields = (classes, isSelfAssesment) => {
  return [
    {
      name: 'title',
      type: FIELD_TYPES.TEXT,
      required: true,
      fieldWrapperClass: classes.field,
      validators: [
        { type: 'required', validator: isEmpty },
        { type: 'forbiddenCharacters', validator: forbiddenCharacters },
        {
          type: 'titleMaxLength',
          validator: value => validateLength(value, 0, 150),
        },
      ],
    },
    {
      name: 'description',
      type: FIELD_TYPES.RICH_TEXT_EDITOR,
      fieldWrapperClass: classes.summary,
      validators: [
        {
          type: 'summaryMaxLength',
          validator: fieldValue => validateLength(fieldValue, 0, 20000),
        },
      ],
    },
    ...(!isSelfAssesment
      ? [
          {
            name: 'anon_reviewers',
            type: FIELD_TYPES.CHECKBOX,
            endLabelIcon: AnonymousIcon,
            translationKey: 'isAnonymous',
            isControlled: true,
            smallText: true,
          },
        ]
      : []),
    {
      name: 'reviewsSubtitle',
      type: FIELD_TYPES.SUBTITLE,
      fieldWrapperClass: classes.subtitle,
    },
    {
      name: 'report_data',
      translationKey: 'reviews',
      type: FIELD_TYPES.REVIEWS,
      optionsKey: 'reviews',
      validators: [
        {
          type: 'reviews',
          isMultiple: true,
          validator: validateReviews,
        },
      ],
    },
    {
      name: 'share_to',
      type: FIELD_TYPES.SELECT,
      translationKey: 'shareWith',
      optionsKey: 'allUsers',
      fieldWrapperClass: classes.field,
      isUser: true,
      isMulti: true,
      parser: {
        value: 'id',
        label: 'first_name',
      },
    },
  ];
};
