import { useState, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Tag from '../tag';
import UserCountBadge from '../userCountBadge';
import UsersList from '../usersList';
import CustomButton from '../customButton';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    boxSizing: 'border-box',
    borderRadius: 4,
    border: ({ color }) => `1px solid ${color}`,
    padding: spacing(6),
    width: '100%',
    minHeight: 278,
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: spacing(5),
  },
  userCount: {
    flexShrink: 0,
    marginLeft: spacing(2),
  },
  button: {
    border: 'none',
    backgroundColor: primary.bluish8,
    borderRadius: 40,
    color: primary.bluish1,
    display: 'block',
    fontFamily: 'ProximaNova-Bold',
    lineHeight: '18px',
    maxHeight: 27,
    margin: spacing(3, 'auto', 0, 'auto'),
    '&:hover': {
      backgroundColor: primary.bluish8,
    },
  },
}));

const MAX_VISIBLE_USERS = 4;

const UsersTagCard = ({
  translations,
  tag,
  color,
  isUserAccesible,
  onGoToUserProfile,
}) => {
  const classes = useStyles({ color });

  const [visibleUsersCount, setVisibleUsersCount] = useState(MAX_VISIBLE_USERS);

  const totalUsers = tag.users.length;
  const visibleUsers = useMemo(
    () => (totalUsers ? tag.users.slice(0, visibleUsersCount) : []),
    [totalUsers, visibleUsersCount, tag]
  );

  const onToggleViewAll = () =>
    setVisibleUsersCount(prevCount =>
      prevCount === totalUsers ? MAX_VISIBLE_USERS : totalUsers
    );

  return (
    <div className={classes.root}>
      <div className={classes.titleWrapper}>
        <Tag tag={tag} color={color} isSelected isSmall />
        <UserCountBadge className={classes.userCount} userCount={totalUsers} />
      </div>
      {!!totalUsers && (
        <UsersList
          users={visibleUsers}
          isUserAccesible={isUserAccesible}
          onUserClickHandler={onGoToUserProfile}
          isVerticalList
        />
      )}
      {totalUsers > MAX_VISIBLE_USERS && (
        <CustomButton
          className={classes.button}
          type="rounded"
          onClick={onToggleViewAll}
        >
          {totalUsers === visibleUsersCount
            ? translations.viewLess
            : translations.viewAll}
        </CustomButton>
      )}
    </div>
  );
};

UsersTagCard.propTypes = {
  translations: PropTypes.object.isRequired,
  tag: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  isUserAccesible: PropTypes.func.isRequired,
  onGoToUserProfile: PropTypes.func.isRequired,
};

export default memo(UsersTagCard);
