import { connect } from 'react-redux';
import { useParams } from 'react-router';
import OneOnOneDetailsPage from '../../components/pages/oneOnOneDetailsPage';
import PageContainer from '../../components/shared/pageContainer';
import { useTranslations } from '../../utility/useTranslations';
import { oneOnOneSelector } from '../../store/selectors/oneOnOne';
import { oneOnOneTopicsSelector } from '../../store/selectors/oneOnOneTopicsSelector';
import { tagsSelector } from '../../store/selectors/tagsSelector';
import {
  getOneOnOneRecord,
  clearOneOnOneRecord,
  getOneOnOneComments,
  clearOneOnOneComments,
  changeOneOnOneTaskStatus,
} from '../../store/modules/oneOnOne';
import {
  getTagsCategories,
  clearTagsCategories,
  addMultipleTags,
} from '../../store/modules/tags';
import {
  getOneOnOneTopics,
  clearOneOnOneTopics,
} from '../../store/modules/oneOnOneTopics';
import { APP_PAGES } from '../../constants/pages';

const mapStateToProps = state => ({
  ...oneOnOneSelector(state),
  ...oneOnOneTopicsSelector(state),
  ...tagsSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getOneOnOneRecord: recordId => getOneOnOneRecord(dispatch, recordId),
  getOneOnOneTopics: () => getOneOnOneTopics(dispatch),
  changeOneOnOneTaskStatus: (recordId, taskId) =>
    changeOneOnOneTaskStatus(dispatch, recordId, taskId),
  getOneOnOneComments: (oneOnOneId, params) =>
    getOneOnOneComments(dispatch, oneOnOneId, params),
  clearOneOnOneComments: () => clearOneOnOneComments(dispatch),
  clearOneOnOneRecord: () => clearOneOnOneRecord(dispatch),
  clearOneOnOneTopics: () => clearOneOnOneTopics(dispatch),
  addMultipleTags: data => dispatch(addMultipleTags(data)),
  getTagsCategories: () => getTagsCategories(dispatch),
  clearTagsCategories: () => dispatch(clearTagsCategories()),
});

const OneOnOneDetailsPageView = props => {
  const translations = useTranslations(APP_PAGES.ONE_ON_ONE_DETAILS);
  const { oneOnOneId } = useParams();

  return (
    <PageContainer {...props} translations={translations}>
      <OneOnOneDetailsPage matchedOneOnOneId={oneOnOneId} />
    </PageContainer>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OneOnOneDetailsPageView);
