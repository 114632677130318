import {
  ACTIVE_DEFAULT_NAME,
  DEACTIVATED_DEFAULT_NAME,
  EXTERNAL_DEFAULT_NAME,
} from '../constants/people';
import { getLast12Months } from '../components/shared/customDateRange/config';
import { PARAMS } from '../constants/pages';
import { replaceObjectInList } from './helpers';
import { getStartEndOfDayUtc } from './dateUtils';
import { GROUPED_SURVEY_REPORTING_TYPES } from '../constants/survey';

export const getPersonMeasuredAttributesParams = (isSelf = false) => {
  const [PeerTeamAndDirect, Self] = GROUPED_SURVEY_REPORTING_TYPES;
  const { SURVEY_TYPE, START_AT, END_AT, START, END } = PARAMS;
  const range = getLast12Months();

  return {
    [SURVEY_TYPE]: isSelf ? Self.values : PeerTeamAndDirect.values,
    [START_AT]: getStartEndOfDayUtc(range[START]),
    [END_AT]: getStartEndOfDayUtc(range[END], true),
  };
};

export const prepareMeasuredAttributes = (
  measuredAttributes,
  coreAttributes = []
) => {
  return measuredAttributes.reduce(
    (acc, curentAttribute) => {
      const coreAttributeIndex = acc.core.findIndex(
        att => att.id === curentAttribute.id
      );

      if (coreAttributeIndex !== -1) {
        return {
          ...acc,
          core: replaceObjectInList(
            acc.core,
            coreAttributeIndex,
            curentAttribute
          ),
        };
      }

      return { ...acc, niche: [...acc.niche, curentAttribute] };
    },
    { core: [...coreAttributes], niche: [] }
  );
};

export const isActiveUserView = selectedView =>
  selectedView === ACTIVE_DEFAULT_NAME;

export const isDeactivatedUserView = selectedView =>
  selectedView === DEACTIVATED_DEFAULT_NAME;

export const isExternalUserView = selectedView =>
  selectedView === EXTERNAL_DEFAULT_NAME;
