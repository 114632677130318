import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, Fade, makeStyles } from '@material-ui/core';
import UserAvatar from '../userAvatar';
import TextBoxWithTooltip from '../textBoxWithTooltip';
import NotificationCard from '../notificationCard';
import { ReactComponent as NoItemsIcon } from '../../../assets/icons/no-items.svg';
import { objectHasProperty, isArrayEmpty } from '../../../utility/helpers';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  title: {
    paddingBottom: spacing(4),
    marginBottom: spacing(12),
    borderBottom: `1px solid ${primary.bluish7}`,
  },
  user: {
    boxSizing: 'border-box',
    backgroundColor: primary.bluish9,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    padding: spacing(2, 4),
    marginBottom: spacing(3),
    width: '100%',
    '&:last-of-type': {
      marginBottom: spacing(0),
    },
  },
  userAvatar: {
    width: '100%',
  },
  userCardMini: {
    width: 300,
  },
  userFullName: {
    fontFamily: 'ProximaNova-Bold',
    lineHeight: '21px',
  },
  shareType: {
    marginLeft: 'auto',
    marginTop: spacing(3),
    padding: spacing(1, 2),
    border: `1px solid ${primary.bluish6}`,
    color: primary.bluish6,
    width: 'auto',
    height: 16,
    textAlign: 'center',
  },
}));

const MyPeopleList = ({
  className,
  title,
  translations,
  users,
  onEmployeeClick,
}) => {
  const classes = useStyles();

  const renderPeopleList = () => (
    <Fade in>
      <div>
        {users.map(user => {
          const hasSharedFlag = objectHasProperty(user, 'read_only');
          return (
            <div className={classes.user} key={user.id}>
              <UserAvatar
                className={classes.userAvatar}
                customWrapperClass={classNames({
                  [classes.userCardMini]: hasSharedFlag,
                })}
                labelClass={classes.userFullName}
                user={user}
                captionDescription={
                  user.position ? user.position?.name : translations.noJobTitle
                }
                onClickHandler={() => onEmployeeClick(user.id)}
                clickableCaption={user.hasAccess}
                caption
                withDescription
                medium
              />
              {hasSharedFlag ? (
                <Typography className={classes.shareType} variant="caption">
                  {user.read_only
                    ? translations.viewer
                    : translations.collaborator}
                </Typography>
              ) : null}
            </div>
          );
        })}
      </div>
    </Fade>
  );

  return (
    <div className={className}>
      <TextBoxWithTooltip className={classes.title} variant="h5" text={title} />
      {!isArrayEmpty(users) ? (
        renderPeopleList()
      ) : (
        <NotificationCard
          title={translations.noDataTitle}
          content={translations.noDataDescription}
          customIcon={NoItemsIcon}
          hasIcon
        />
      )}
    </div>
  );
};

MyPeopleList.defaultProps = {
  className: '',
  users: [],
  onEmployeeClick: () => {},
};

MyPeopleList.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(PropTypes.object),
  onEmployeeClick: PropTypes.func,
};

export default memo(MyPeopleList);
