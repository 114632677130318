import { memo } from 'react';
import classNames from 'classnames';
import { alpha, makeStyles } from '@material-ui/core';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import ActionButton from '../actionButton';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  toast: {
    top: 130,
    right: 24,
    padding: 0,
    width: 'max-content',
  },
  content: {
    boxSizing: 'border-box',
    borderRadius: 8,
    boxShadow: `2px 2px 0px 0px ${alpha(primary.black, 0.17)}`,
    fontSize: 16,
    fontFamily: 'ProximaNova-Regular',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 56,
    maxWidth: 'max-content',
    padding: spacing(2),
    marginBottom: spacing(4),
    marginLeft: 'auto',
  },
  success: {
    boxShadow: `4px 4px 14px 0px ${alpha(primary.black, 0.14)}`,
    backgroundColor: primary.green3,
  },
  error: {
    boxShadow: `2px 2px 0px 0px ${alpha(primary.black, 0.17)}`,
    backgroundColor: primary.red5,
  },
  closeButton: {
    height: 24,
    width: 24,
    margin: spacing(0, 2),
  },
  closeIcon: {
    height: 16,
    width: 16,
  },
}));

const CLOSE_DELAY = 2500;

const CloseButton = ({ closeToast }) => {
  const classes = useStyles();
  return (
    <ActionButton
      className={classes.closeButton}
      iconClass={classes.closeIcon}
      type={ACTION_BUTTON_TYPES.CLOSE}
      onClickHandler={closeToast}
    />
  );
};

const AlertComponent = () => {
  const classes = useStyles();

  return (
    <div>
      <ToastContainer
        className={classes.toast}
        toastClassName={({ type }) =>
          classNames(classes.content, classes?.[type])
        }
        autoClose={CLOSE_DELAY}
        transition={Zoom}
        closeButton={CloseButton}
        icon={false}
        hideProgressBar
      />
    </div>
  );
};

export default memo(AlertComponent);
