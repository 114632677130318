import { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import CustomModal from '../customModal';
import CustomScrollBar from '../customScrollBar';
import CustomCheckbox from '../customCheckbox';
import UserAvatar from '../userAvatar';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  usersWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 80,
    maxHeight: 160,
  },
  description: {
    fontSize: 14,
    lineHeight: '18px',
  },
  confirmButton: {
    backgroundColor: primary.red1,
    '&:hover': {
      backgroundColor: primary.red1,
    },
    '&:disabled': {
      backgroundColor: primary.bluish5,
    },
  },
  checkbox: {
    marginRight: spacing(2),
  },
  userItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(5),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  avatar: {
    maxWidth: 'calc(100% - 40px)',
  },
  user: {
    justifyContent: 'flex-start',
    width: '100%',
  },
  userLabelContainer: {
    maxWidth: 'calc(100% - 32px)',
  },
  userLabel: {
    color: primary.bluish1,
    fontFamily: 'ProximaNova-Bold',
    fontSize: 16,
    lineHeight: '20px',
  },
  scrollY: {
    backgroundColor: primary.bluish9,
    top: 0,
    right: -20,
    height: '100%',
    width: 8,
  },
  scroll: {
    backgroundColor: primary.bluish7,
  },
}));

const GroupUserActionDialog = ({
  translations,
  isOpened,
  users,
  onClose,
  onConfirm,
}) => {
  const classes = useStyles();
  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleClose = () => {
    setSelectedUsers([]);
    onClose();
  };

  const handleSave = async () => {
    await onConfirm(selectedUsers);
    handleClose();
  };

  const onChange = userId => () => {
    const isChecked = selectedUsers.includes(userId);

    setSelectedUsers([
      ...(isChecked
        ? selectedUsers.filter(uId => uId !== userId)
        : [...selectedUsers, userId]),
    ]);
  };

  return (
    <CustomModal
      descriptionClass={classes.description}
      confirmButtonClass={classes.confirmButton}
      confirmButtonLabel={translations.save}
      closeButtonLabel={translations.cancel}
      title={translations.title}
      description={translations.description}
      isConfirmDisabled={false}
      isOpened={isOpened}
      onClose={handleClose}
      onConfirm={handleSave}
      isMedium
    >
      <div className={classes.usersWrapper}>
        <CustomScrollBar
          customScrollBarYClass={classes.scrollY}
          customScrollClass={classes.scroll}
          verticalScroll
          removeScrollX
          passContentHeight
        >
          <>
            {users.map(user => {
              return (
                <div
                  key={`user_deactivated_${user.id}`}
                  className={classes.userItem}
                >
                  <CustomCheckbox
                    customRootClass={classes.checkbox}
                    isChecked={selectedUsers.includes(user.id)}
                    onChange={onChange(user.id)}
                  />

                  <UserAvatar
                    className={classes.avatar}
                    labelClass={classes.userLabel}
                    user={user}
                    onClickHandler={onChange(user.id)}
                    clickableCaption
                    caption
                    small
                  />
                </div>
              );
            })}
          </>
        </CustomScrollBar>
      </div>
    </CustomModal>
  );
};

GroupUserActionDialog.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  translations: PropTypes.object.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default memo(GroupUserActionDialog);
