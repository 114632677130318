import { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import TextBoxWithTooltip from '../textBoxWithTooltip';
import StatusIndicator from '../statusIndicator';
import ProposedStatus from '../proposedStatus';
import RequestedIconTooltip from '../requestedIconTooltip';
import TasksIconTooltip from '../tasksIconTooltip';
import CoursesIconTooltip from '../coursesIconTooltip';
import Tag from '../tag';
import AsyncListBadge from '../asyncListBadge';
import { ReactComponent as TagsIcon } from '../../../assets/icons/tag-dark.svg';
import { formatDate } from '../../../utility/dateUtils';
import { isArrayEmpty } from '../../../utility/helpers';
import { DUE_DATE_TIME_FORMAT } from '../../../constants/actionPlan';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    backgroundColor: primary.white,
    boxSizing: 'border-box',
    borderRadius: 16,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(4),
    width: 270,
    minHeight: 170,
    userSelect: 'none',
  },
  verticalEllipsis: {
    cursor: 'pointer',
    marginBottom: spacing(2),
  },
  date: {
    color: primary.bluish4,
    margin: spacing(2, 0),
  },
  statusWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  progressWrapper: {
    boxSizing: 'border-box',
    backgroundColor: primary.black,
    padding: spacing(1, 2),
    height: 20,
  },
  progressValue: {
    color: primary.white,
    fontSize: 10,
    lineHeight: '12px',
    textTransform: 'uppercase',
    userSelect: 'none',
  },
  footer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(0,max-content))',
    gridColumnGap: 8,
    alignItems: 'center',
    marginTop: 'auto',
  },
  badgeIcon: {
    marginTop: spacing(-0.5),
  },
}));

const PlanMiniCard = ({ className, translations, plan, onCardClick }) => {
  const classes = useStyles();
  const {
    is_proposed,
    title,
    due_date,
    created_on,
    status,
    progress,
    isCreatedForCurrentUser,
    isPendingProposal,
    tags,
    hasTasks,
    hasCourses,
  } = plan;
  const hasTags = !isArrayEmpty(tags);

  const handleCardClick = useCallback(() => {
    onCardClick(plan.id);
  }, [plan, onCardClick]);

  const renderTag = tag => (
    <Tag tag={tag} color={tag?.category?.color} isSelected />
  );

  return (
    <div
      className={classNames(classes.root, className)}
      onClick={handleCardClick}
    >
      <TextBoxWithTooltip
        className={classes.verticalEllipsis}
        text={title}
        variant="h5"
      />
      <Typography className={classes.date} variant="caption">{`${
        isPendingProposal ? translations.proposedOn : translations.dueDate
      }: ${formatDate(
        isPendingProposal ? created_on : due_date,
        DUE_DATE_TIME_FORMAT
      )}`}</Typography>
      <div className={classes.statusWrapper}>
        {isPendingProposal ? (
          <ProposedStatus proposedLabel={translations.proposed} />
        ) : (
          <StatusIndicator status={status} />
        )}
        {!isPendingProposal && (
          <div className={classes.progressWrapper}>
            <Typography className={classes.progressValue} variant="overline">
              {`${progress}%`}
            </Typography>
          </div>
        )}
      </div>
      <div className={classes.footer}>
        {is_proposed && !isPendingProposal && (
          <RequestedIconTooltip
            labels={translations.requested}
            isRequestedByCurrentUser={isCreatedForCurrentUser}
          />
        )}
        <TasksIconTooltip
          text={translations.tasksIconTooltip}
          isVisible={hasTasks}
        />
        <CoursesIconTooltip
          text={translations.coursesIconTooltip}
          isVisible={hasCourses}
        />
        {hasTags && (
          <AsyncListBadge
            badgeIconClass={classes.badgeIcon}
            tooltipTitle={translations.tags}
            count={tags?.length}
            items={tags}
            icon={TagsIcon}
            renderItem={renderTag}
          />
        )}
      </div>
    </div>
  );
};

PlanMiniCard.defaultProps = {
  className: undefined,
  plan: {},
};

PlanMiniCard.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  plan: PropTypes.object,
  onCardClick: PropTypes.func.isRequired,
};

export default PlanMiniCard;
