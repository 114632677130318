import { connect } from 'react-redux';

import LevelingPage from '../../components/pages/levelingPage';
import PageContainer from '../../components/shared/pageContainer';
import { levelsAndTracksSelector } from '../../store/selectors/levelsAndTracksSelector';
import {
  getLevelsAndTracks,
  addLevels,
  addTracks,
  clearLevelsAndTracks,
} from '../../store/modules/levelsAndTracks';
import { useTranslations } from '../../utility/useTranslations';
import { APP_PAGES } from '../../constants/pages';

const mapStateToProps = state => ({
  ...levelsAndTracksSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getLevelsAndTracks: () => getLevelsAndTracks(dispatch),
  addLevels: data => addLevels(dispatch, data),
  addTracks: data => addTracks(dispatch, data),
  clearLevelsAndTracks: () => dispatch(clearLevelsAndTracks()),
});

const LevelingPageView = props => {
  const translations = useTranslations(APP_PAGES.LEVELING);

  return (
    <PageContainer {...props} translations={translations} isFullWidthContent>
      <LevelingPage />
    </PageContainer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LevelingPageView);
