export const tagManagerDataLayer = (action, name, value = null) => {
  if (window && window._paq && window._paq.push) {
    window._paq.push([
      'trackEvent',
      window.location.hostname,
      action,
      name,
      value,
    ]);
  }
};

export const trackPageView = pathname => {
  if (window && window._paq && window._paq.push) {
    window._paq.push([
      'setDocumentTitle',
      `${window.document.domain}/${pathname}`,
    ]);
    window._paq.push(['trackPageView']);
  }
};

// _paq.push(['trackEvent', category, action, name, value, {dimension1: 'DimensionValue'}]);

export const EVENT_ACTION_TYPES = {
  Login: { action: 'LOGIN_USER', name: 'Somebody logged in the app.' },
  AddUser: { action: 'ADD_USER', name: 'New user is added.' },
  AddActionPlan: {
    action: 'ADD_ACTION_PLAN',
    name: 'New action plan created.',
  },
  AddOneOnOne: {
    action: 'ADD_ONE_ON_ONE',
    name: 'New 1 - 1 meeting scheduled.',
  },
  AddNewCourseInLibrary: {
    action: 'ADD_NEW_COURSE',
    name: 'New course added in library.',
  },
  AddJobTitle: {
    action: 'ADD_JOB_TITLE',
    name: 'New job title added',
  },
  EnrollEmployeeOnCourse: {
    action: 'ENROLL_EMPLOYEE_TO_COURSE',
    name: 'Employee enrolled to the course.',
  },
  AddNewAttribute: {
    action: 'ADD_NEW_ATTRIBUTE',
    name: 'New attribute created.',
  },
  ComposeNewSurvey: {
    action: 'COMPOSE_NEW_SURVEY',
    name: 'New survey has been sent.',
  },
  VisitAPDP: {
    action: 'VISIT_ACTION_DETAILS_PAGE',
    name: 'Somebody visited Action Point Detail Page.',
  },
  VisitSDP: {
    action: 'VISIT_SURVEY_DETAILS_PAGE',
    name: 'Somebody visited Survey Details Page',
  },
  VisitEMP: {
    action: 'VISIT_EMPLOYEE_PROFILE',
    name: 'Somebody visited employee profile',
  },
  VisitCDP: {
    action: 'VISIT_COURSE_DETAILS_PAGE',
    name: 'Somebody visited Course Details Page',
  },
  VisitFSP: {
    action: 'VISIT_FILL_SURVEY_PAGE',
    name: 'Somebody opened fill survey page',
  },
};
