import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import classNames from 'classnames';
import InputField from '../inputField';
import { ReactComponent as SendArrow } from '../../../assets/icons/send-arrow.svg';
import ActionButton from '../actionButton';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';

const styles = ({ palette: { primary } }) => ({
  icon: {
    position: 'absolute',
    bottom: 4,
    right: 50,
    cursor: 'pointer',
  },
  cancelIcon: {
    position: 'absolute',
    bottom: 4,
    right: 12,
    cursor: 'pointer',
  },
  disabled: {
    pointerEvents: 'none',
    cursor: 'default',
    '& g path': {
      '&:nth-of-type(2)': {
        fill: primary.bluish5,
      },
    },
  },
});

class SubmittableInput extends PureComponent {
  renderSubmit = () => {
    const { classes, onSubmit, onCancel, isEdit, isSendDisabled } = this.props;
    return (
      <>
        <SendArrow
          className={classNames({
            [classes.icon]: isEdit,
            [classes.cancelIcon]: !isEdit,
            [classes.disabled]: isSendDisabled,
          })}
          onClick={onSubmit}
        />
        {isEdit && (
          <ActionButton
            className={classes.cancelIcon}
            type={ACTION_BUTTON_TYPES.CLOSE}
            onClickHandler={onCancel}
          />
        )}
      </>
    );
  };

  render() {
    const {
      className,
      label,
      placeholder,
      hasError,
      errorMessage,
      errorClass,
      value,
      onChange,
    } = this.props;

    return (
      <InputField
        className={className}
        customErrorClass={errorClass}
        label={label}
        placeholder={placeholder}
        error={hasError}
        errorMessage={errorMessage}
        value={value}
        onChange={onChange}
        endAdornment={this.renderSubmit()}
        fullWidth
        multiline
      />
    );
  }
}

SubmittableInput.defaultProps = {
  className: '',
  errorClass: '',
  label: '',
  placeholder: '',
  hasError: false,
  errorMessage: '',
  isEdit: false,
  onCancel: () => {},
  isSendDisabled: false,
};

SubmittableInput.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape({}).isRequired,
  errorClass: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  isEdit: PropTypes.bool,
  isSendDisabled: PropTypes.bool,
};

export default withStyles(styles)(SubmittableInput);
