import axios from 'axios';

import StorageService from './storageService';
import LoaderService from './LoaderService';
import { showErrorMessage, getSubdomain } from './uiUtils';
import { setAuthInfo } from '../store/modules/auth';
import { setDialogVisibility } from '../store/modules/dialogs';
import { setLoading, constants } from '../store/modules/loading';
import { store, history } from '../index';
import {
  API_REGISTRATION_CONFIRM,
  API_FILL_SURVEYS,
} from '../constants/apiRoutes';

export const AUTH = 'auth';
export const ACCESS_TOKEN = 'access';
export const TOKEN_TYPE = 'Bearer';
export const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
export const API_PROTOCOL = process.env.REACT_APP_API_PROTOCOL;
const HTTP_STATUS = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  PAYMENT_REQUIRED: 402,
  UPGRADE_REQUIRED: 426,
  NOT_FOUND: 404,
  I_AM_TEAPOT: 418,
  PAGE_EXPIRED: 419,
};
const BLACKLISTED_URLS_FOR_LOADING = Array.of(API_REGISTRATION_CONFIRM);

const DEFAULT_HEADERS = {
  tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
};

const resolveApiUrl = () => {
  const subdomain = getSubdomain(window.location.hostname);

  if (subdomain && subdomain.length > 0) {
    return `${API_PROTOCOL}://${subdomain}.api.${API_DOMAIN}/api/`;
  }
  return `${API_PROTOCOL}://api.${API_DOMAIN}/api/`;
};

export const getHeaders = storage => {
  const headers = {
    ...DEFAULT_HEADERS,
  };

  if (storage.has(AUTH)) {
    const auth = JSON.parse(storage.get(AUTH));
    return { ...headers, Authorization: `${TOKEN_TYPE} ${auth[ACCESS_TOKEN]}` };
  }

  return headers;
};

export const createCustomHttpClient = (
  baseURL = `${API_PROTOCOL}://api.${API_DOMAIN}/api/`,
  headers = {}
) => {
  const customHttp = axios.create({
    headers: {
      ...DEFAULT_HEADERS,
      ...headers,
    },
    baseURL,
  });

  customHttp.interceptors.request.use(
    config => {
      if (!BLACKLISTED_URLS_FOR_LOADING.includes(config.url)) {
        LoaderService.start(() =>
          store.dispatch(setLoading(constants.INCREMENT))
        );
      }

      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  customHttp.interceptors.response.use(
    response => {
      if (!BLACKLISTED_URLS_FOR_LOADING.includes(response.config.url)) {
        LoaderService.end(() =>
          store.dispatch(setLoading(constants.DECREMENT))
        );
      }
      return response;
    },
    error => {
      if (!BLACKLISTED_URLS_FOR_LOADING.includes(error.config.url)) {
        LoaderService.end(() =>
          store.dispatch(setLoading(constants.DECREMENT))
        );
      }

      return Promise.reject(error);
    }
  );

  return customHttp;
};

// Default http client
const http = axios.create({
  headers: getHeaders(StorageService),
  baseURL: resolveApiUrl(),
});

http.interceptors.request.use(
  config => {
    LoaderService.start(() => store.dispatch(setLoading(constants.INCREMENT)));

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  response => {
    LoaderService.end(() => store.dispatch(setLoading(constants.DECREMENT)));

    return response;
  },
  error => {
    LoaderService.end(() => store.dispatch(setLoading(constants.DECREMENT)));
    if (error && error.response) {
      if (
        error.response.status === HTTP_STATUS.UNAUTHORIZED ||
        error.response.status === HTTP_STATUS.I_AM_TEAPOT
      ) {
        StorageService.clear();
        store.dispatch(setAuthInfo({}));
        http.defaults.headers = getHeaders(StorageService);
        history.push('/login');
      } else if (error.response.status === HTTP_STATUS.NOT_FOUND) {
        if (!error.response.config.url.includes(API_FILL_SURVEYS)) {
          history.replace('/not-found');
        }
      } else if (error.response.status === HTTP_STATUS.FORBIDDEN) {
        history.replace('/unauthorized-access');
      } else if (error.response.status === HTTP_STATUS.PAYMENT_REQUIRED) {
        store.dispatch(
          setDialogVisibility({
            dialogName: 'upgradeRequiredDialog',
            opened: true,
          })
        );
      } else if (error.response.status === HTTP_STATUS.UPGRADE_REQUIRED) {
        store.dispatch(
          setDialogVisibility({
            dialogName: 'isLimitReachedDialog',
            opened: true,
          })
        );
      } else if (
        error.response.status === HTTP_STATUS.PAGE_EXPIRED &&
        error.response.config.url.includes(API_FILL_SURVEYS)
      ) {
        return Promise.reject(error);
      } else if (error.response.data) {
        if (
          error.response.data.non_field_errors &&
          Array.isArray(error.response.data.non_field_errors)
        ) {
          showErrorMessage(error.response.data.non_field_errors.join(','));
        } else {
          showErrorMessage(error.response.data.detail);
        }
      }
    } else {
      showErrorMessage('Oops! Something went wrong.');
    }

    return Promise.reject(error);
  }
);

export default http;
