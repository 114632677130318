import { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import CustomModal from '../customModal';
import CustomCheckbox from '../customCheckbox';
import Users from './users';
import Attributes from './attributes';
import { ReactComponent as InfoIcon } from '../../../assets/icons/info.svg';
import {
  CREATE_SURVEY_FIELD_NAMES,
  SURVEY_TYPES,
} from '../../../constants/survey';
import { isPlural, hasInlineSurvey, getDescription } from './config';

const useStyles = makeStyles(({ spacing }) => ({
  paper: {
    width: 533,
    padding: spacing(8),
  },
  description: {
    marginBottom: spacing(2),
  },
  fillInline: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 'auto',
  },
  tooltipIcon: {
    marginTop: spacing(0.5),
    marginLeft: spacing(2),
  },
}));

const SendSurveyNotificationDialog = ({
  translations,
  isOpened,
  surveyData,
  currentUser,
  onFillInlineChange,
  onConfirm,
  onClose,
}) => {
  const classes = useStyles();
  const {
    ATTRIBUTES,
    FILL_INLINE,
    SURVEY_TYPE,
    CREATED_FOR,
    REVIEWERS,
    IS_SCHEDULE,
  } = CREATE_SURVEY_FIELD_NAMES;
  const isSelfAssessment = surveyData[SURVEY_TYPE] === SURVEY_TYPES.SELF;
  const isSchedule = surveyData[IS_SCHEDULE];
  const reviewerLabel = isPlural(surveyData)
    ? translations.reviewers
    : translations.reviewer;
  const surveyReviewers = isSelfAssessment
    ? surveyData[CREATED_FOR]
    : surveyData[REVIEWERS];
  const description = useMemo(
    () => getDescription(translations, surveyData, isSchedule),
    [translations, surveyData, isSchedule]
  );

  const handleSaveDraft = e => onConfirm(e, true);

  const renderFillInlineField = () => {
    return hasInlineSurvey(surveyData, currentUser) ? (
      <div className={classes.fillInline}>
        <CustomCheckbox
          customRootClass={classes.checkbox}
          isChecked={surveyData[FILL_INLINE]}
          labelText={translations.fillInline}
          endLabelIcon={InfoIcon}
          endLabelIconHelp={translations.fillInlineTooltip}
          onChange={onFillInlineChange}
          smallText
          isControlled
        />
      </div>
    ) : null;
  };

  return (
    <CustomModal
      isOpened={isOpened}
      title={isSchedule ? translations.titleSchedule : translations.title}
      confirmButtonLabel={
        !isSchedule && !surveyData.isDraftActivation
          ? translations.saveDraft
          : ''
      }
      saveAndCreateButtonLabel={
        isSchedule ? translations.schedule : translations.send
      }
      closeButtonLabel={translations.cancel}
      renderCustomAction={renderFillInlineField}
      onClose={onClose}
      onConfirm={handleSaveDraft}
      onSaveAndCreate={onConfirm}
      isMedium
    >
      <div>
        <Typography className={classes.description}>{description}</Typography>
        <Users title={reviewerLabel} users={surveyReviewers} />
        {!isSelfAssessment && (
          <Users
            title={translations.createdFor}
            users={surveyData[CREATED_FOR]}
            survey={surveyData}
          />
        )}
        <Attributes
          label={translations.attributes}
          attributes={surveyData[ATTRIBUTES]}
        />
      </div>
    </CustomModal>
  );
};

SendSurveyNotificationDialog.propTypes = {
  translations: PropTypes.object.isRequired,
  isOpened: PropTypes.bool.isRequired,
  surveyData: PropTypes.shape({}).isRequired,
  currentUser: PropTypes.shape({}).isRequired,
  onFillInlineChange: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default memo(SendSurveyNotificationDialog);
