const BLACKLISTED_ROUTES = [
  '/sign-up',
  '/login',
  '/privacy-policy',
  '/terms-of-service',
  '/reset-link-sent',
  '/forgotten-password',
  '/set-password',
  '/fill-survey',
  '/thank-you',
];

export const getActiveSectionId = (sections, pathname) => {
  const [dashboardSection] = sections;

  return sections.find(section => {
    if (!section.items)
      return section.url === pathname ? section.id : undefined;

    if (pathname.length === 1) {
      return dashboardSection;
    }

    return section.items.some(sItem => {
      if (sItem.url.length === 1) return false;

      return pathname.includes(sItem.url);
    });
  })?.id;
};

export const shouldSidebarRender = pathname =>
  BLACKLISTED_ROUTES.every(route => !pathname.includes(route));
