import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Fade, makeStyles } from '@material-ui/core';
import UserAvatar from '../../userAvatar';
import SelectField from '../../selectField';
import CustomDateRange from '../../customDateRange';
import MultipartPeoplePicker from '../../multipartPeoplePicker';
import ActionButton from '../../actionButton';
import { isArrayEmpty } from '../../../../utility/helpers';
import { PARAMS } from '../../../../constants/pages';
import { ACTION_BUTTON_TYPES } from '../../actionButton/config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  divider: {
    '&::before': {
      content: '""',
      position: 'absolute',
      width: 1,
      height: 'calc(100% + 16px)',
      top: -16,
      right: -16,
      borderRight: `1px dotted ${primary.bluish7}`,
    },
  },
  avatarWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
    maxWidth: 'calc(100% - 38px)',
    marginBottom: spacing(6),
  },
  avatarWraperCompare: {
    maxWidth: 'calc(100% - 76px)',
  },
  userLabel: {
    fontFamily: 'ProximaNova-Bold',
    fontSize: 16,
    lineHeight: '20px',
  },
  field: {
    marginBottom: spacing(6),
    width: '100%',
    maxWidth: 300,
  },
  peoplePicker: {
    width: '100%',
    maxWidth: '100%',
  },
  peoplePickerWithReviewers: {
    marginBottom: spacing(4),
  },
  selectedReviewerWrapper: {
    maxWidth: 380,
  },
  actionButton: {
    position: 'absolute',
    right: 0,
    top: 9,
  },
  actionButtonSync: {
    right: 38,
  },
  compareWithButton: {
    display: 'flex',
    marginBottom: spacing(6),
  },
  compareWithTooltip: {
    cursor: 'not-allowed',
  },
  tooltipLabel: {
    fontFamily: 'ProximaNova-Bold',
    textAlign: 'center',
  },
  tooltipRoot: {
    maxWidth: 220,
  },
}));

const CompareFilters = ({
  translations,
  user,
  reviewersFilters,
  surveyTypes,
  surveyType,
  period,
  reviewers,
  allUsers,
  isSelfSurvey,
  hasCompareWith,
  compareWithUser,
  onSyncFilters,
  onRemoveUser,
  onOpenAddCompare,
  onChangeReviewers,
  onSurveyTypeChange,
  onPeriodChange,
  onGoToProfilePage,
  onGetReviewers,
}) => {
  const classes = useStyles();

  const { START, END } = PARAMS;
  const hasReviewers = !isArrayEmpty(reviewers);

  return (
    <div
      className={classNames(classes.root, {
        [classes.divider]: !!compareWithUser,
      })}
    >
      <Fade key={`user_${user?.id}`} in={!!user} unmountOnExit>
        <div
          className={classNames(classes.avatarWrapper, {
            [classes.avatarWraperCompare]: !hasCompareWith,
          })}
        >
          {user && (
            <UserAvatar
              labelClass={classes.userLabel}
              customCaptionContainerClass={classes.userCaption}
              user={user}
              onClickHandler={onGoToProfilePage(user.id)}
              clickableCaption
              caption
              medium
            />
          )}
        </div>
      </Fade>
      <Fade in>
        <div>
          <SelectField
            className={classes.field}
            label={translations.surveyType.label}
            value={surveyType}
            options={surveyTypes}
            parser={{ value: 'key', label: 'name' }}
            onChange={onSurveyTypeChange}
            shouldReturnOption
            isSearchDisabled
          />
          <CustomDateRange
            className={classes.field}
            label={translations.period}
            initialRange={{
              startDate: period[START],
              endDate: period[END],
            }}
            startAtKey={START}
            endAtKey={END}
            onChange={onPeriodChange}
          />
        </div>
      </Fade>
      <Fade in={!isSelfSurvey} unmountOnExit>
        <div
          className={classNames(classes.peoplePicker, {
            [classes.peoplePickerWithReviewers]: hasReviewers,
          })}
        >
          <MultipartPeoplePicker
            selectedUserWrapperClass={classes.selectedReviewerWrapper}
            translations={translations.reviewersPicker}
            filters={reviewersFilters}
            allUsers={allUsers}
            selectedUsers={reviewers}
            onGetUsers={onGetReviewers}
            onSave={onChangeReviewers}
          />
        </div>
      </Fade>
      <Fade in={!hasCompareWith} unmountOnExit>
        <div
          className={classNames(classes.actionButton, classes.actionButtonSync)}
        >
          <ActionButton
            type={ACTION_BUTTON_TYPES.SYNC}
            tooltipText={translations.syncFiltersButton}
            onClickHandler={onSyncFilters}
            hasBorder
          />
        </div>
      </Fade>
      <Fade in={!hasCompareWith} unmountOnExit>
        <div className={classes.actionButton}>
          <ActionButton
            type={ACTION_BUTTON_TYPES.CLOSE}
            tooltipText={translations.removeCompareWith}
            onClickHandler={onRemoveUser}
            hasBorder
          />
        </div>
      </Fade>
      <Fade in={hasCompareWith} unmountOnExit>
        <div className={classes.actionButton}>
          <ActionButton
            type={ACTION_BUTTON_TYPES.PEOPLE}
            tooltipText={translations.compareWithButton}
            onClickHandler={onOpenAddCompare}
            hasBorder
          />
        </div>
      </Fade>
    </div>
  );
};

CompareFilters.defaultProps = {
  user: null,
  hasCompareWith: false,
  compareWithUser: null,
  onOpenAddCompare: () => {},
  onSyncFilters: () => {},
  onRemoveUser: () => {},
};

CompareFilters.propTypes = {
  translations: PropTypes.object.isRequired,
  user: PropTypes.object,
  reviewersFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
  surveyTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  allUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
  surveyType: PropTypes.object.isRequired,
  period: PropTypes.object.isRequired,
  reviewers: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasCompareWith: PropTypes.bool,
  isSelfSurvey: PropTypes.bool.isRequired,
  compareWithUser: PropTypes.object,
  onOpenAddCompare: PropTypes.func,
  onSyncFilters: PropTypes.func,
  onRemoveUser: PropTypes.func,
  onChangeReviewers: PropTypes.func.isRequired,
  onSurveyTypeChange: PropTypes.func.isRequired,
  onPeriodChange: PropTypes.func.isRequired,
  onGoToProfilePage: PropTypes.func.isRequired,
  onGetReviewers: PropTypes.func.isRequired,
};

export default memo(CompareFilters);
