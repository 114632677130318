import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Fade, makeStyles } from '@material-ui/core';
import NotificationCard from '../../notificationCard';
import AttributesList from '../../attributesList';
import ActionButton from '../../actionButton';
import { isArrayEmpty } from '../../../../utility/helpers';
import { ACTION_BUTTON_TYPES } from '../../actionButton/config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    borderTop: `1px solid ${primary.bluish7}`,
    position: 'relative',
    paddingTop: spacing(6),
  },
  divider: {
    '&::before': {
      content: '""',
      position: 'absolute',
      width: 1,
      height: 'calc(100% + 16px)',
      top: 0,
      right: -16,
      borderRight: `1px dotted ${primary.bluish7}`,
    },
  },
  restoreButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: spacing(4),
  },
}));

const CompareScores = ({
  translations,
  attributes,
  isRemovable,
  hasDivider,
  hasRestore,
  isDraggable,
  onReorder,
  onRestore,
  onRemoveAttribute,
}) => {
  const classes = useStyles();

  const hasAttributes = !isArrayEmpty(attributes);
  const restoreButtonProps = {
    type: ACTION_BUTTON_TYPES.SYNC,
    tooltipText: translations.restoreAttributes,
    hasBorder: true,
    onClickHandler: onRestore,
  };

  return (
    <div
      className={classNames(classes.root, { [classes.divider]: hasDivider })}
    >
      <NotificationCard
        shouldFade={!hasAttributes}
        content={translations.noAttributeResults}
        iconButtonProps={{
          ...(hasRestore ? { ...restoreButtonProps } : {}),
        }}
      />
      <AttributesList
        translations={translations.attribute}
        attributes={attributes}
        isRemovable={isRemovable}
        isDraggable={isDraggable}
        onRemove={onRemoveAttribute}
        onReorder={onReorder}
        hasScore
      />
      <Fade in={hasAttributes && hasRestore} unmountOnExit>
        <div className={classes.restoreButtonWrapper}>
          <ActionButton {...restoreButtonProps} />
        </div>
      </Fade>
    </div>
  );
};

CompareScores.defaultProps = {
  isRemovable: false,
  hasDivider: false,
  attributes: [],
  hasRestore: false,
  isDraggable: false,
  onRestore: () => {},
  onRemoveAttribute: () => {},
};

CompareScores.propTypes = {
  translations: PropTypes.object.isRequired,
  isRemovable: PropTypes.bool,
  hasDivider: PropTypes.bool,
  hasRestore: PropTypes.bool,
  isDraggable: PropTypes.bool,
  attributes: PropTypes.arrayOf(PropTypes.object),
  onRestore: PropTypes.func,
  onRemoveAttribute: PropTypes.func,
};

export default memo(CompareScores);
