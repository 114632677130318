import { useState, useEffect, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { Typography, Fade, makeStyles } from '@material-ui/core';
import { TransitionGroup } from 'react-transition-group';
import CustomModal from '../customModal';
import CustomScrollBar from '../customScrollBar';
import TagsCategory from '../tagsCategory';
import Tag from '../tag';
import Search from '../search/index';
import NoDataText from '../noDataText';
import { isArrayEmpty, getObjectToNumberArray } from '../../../utility/helpers';
import { sortAlphabetically } from '../../../utility/uiUtils';
import { filterTags } from '../../../utility/tagUtils';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  description: {
    paddingBottom: spacing(4),
  },
  selectedTagsLabel: {
    fontFamily: 'ProximaNova-Bold',
    lineHeight: '20px',
    marginTop: spacing(4),
  },
  selectedTagsWrapper: {
    borderBottom: `1px solid ${primary.bluish7}`,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    marginTop: spacing(4),
    paddingBottom: spacing(1),
    marginBottom: spacing(4),
    minHeight: 20,
    maxHeight: 112,
  },
  tagsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    width: '100%',
  },
  tag: {
    margin: spacing(0, 2, 2, 0),
  },
  tagsScrollWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: 200,
  },
  category: {
    marginBottom: spacing(2),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  search: {
    width: '100%',
    maxWidth: '100%',
    paddingBottom: spacing(4),
    borderBottom: `1px solid ${primary.bluish8}`,
  },
  scrollY: {
    backgroundColor: primary.bluish9,
    top: 0,
    right: -20,
    height: '100%',
    width: 8,
  },
  scroll: {
    backgroundColor: primary.bluish7,
  },
}));

const ManageUserTagsDialog = ({
  translations,
  isOpened,
  categories,
  selectedTags,
  onCancel,
  onSave,
  userAvatars,
}) => {
  const classes = useStyles();
  const [tags, setTags] = useState(selectedTags);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (isOpened) {
      setTags(selectedTags);
    }
  }, [isOpened, selectedTags]);

  const filteredCategories = useMemo(
    () => filterTags(categories, searchValue, tags),
    [categories, searchValue, tags]
  );

  const handleReset = () => {
    setSearchValue('');
  };

  const handleClose = () => {
    onCancel();
    handleReset();
  };

  const handleSave = () => {
    onSave({ tags: getObjectToNumberArray(tags) });
    handleReset();
  };

  const onSelectTag = (tag, category) =>
    setTags(prevTags =>
      sortAlphabetically([...prevTags, { ...tag, color: category.color }])
    );

  const onDeselectTag = tag =>
    setTags(prevTags =>
      prevTags.filter(previousTag => previousTag.id !== tag.id)
    );

  const handleSearch = value => setSearchValue(value);

  return (
    <CustomModal
      title={translations.title}
      confirmButtonLabel={translations.save}
      closeButtonLabel={translations.cancel}
      isOpened={isOpened}
      onClose={handleClose}
      onConfirm={handleSave}
      isMedium
    >
      {userAvatars || (
        <Typography className={classes.description}>
          {translations.description}
        </Typography>
      )}
      <Search
        className={classes.search}
        label={translations.search.label}
        placeholder={translations.search.placeholder}
        value={searchValue}
        onChange={handleSearch}
      />
      <Typography className={classes.selectedTagsLabel}>
        {translations.selectedTags}
      </Typography>
      <div className={classes.selectedTagsWrapper}>
        <CustomScrollBar
          customScrollBarYClass={classes.scrollY}
          customScrollClass={classes.scroll}
          verticalScroll
          removeScrollX
          passContentHeight
          passContentWidth
        >
          <>
            {!isArrayEmpty(tags) ? (
              <TransitionGroup className={classes.tagsWrapper}>
                {tags.map(tag => (
                  <Fade key={`tag_item_${tag.id}`} in>
                    <div>
                      <Tag
                        className={classes.tag}
                        tag={tag}
                        color={tag?.color || tag?.category?.color}
                        onTagSelect={onDeselectTag}
                        hasRemove
                        isSelected
                        isSelectable
                      />
                    </div>
                  </Fade>
                ))}
              </TransitionGroup>
            ) : (
              <NoDataText text={translations.noSelectedTags} />
            )}
          </>
        </CustomScrollBar>
      </div>
      <div className={classes.tagsScrollWrapper}>
        <CustomScrollBar
          customScrollBarYClass={classes.scrollY}
          customScrollClass={classes.scroll}
          verticalScroll
          removeScrollX
          passContentHeight
          passContentWidth
        >
          <>
            {!isArrayEmpty(filteredCategories) ? (
              <TransitionGroup>
                {filteredCategories.map(category => (
                  <Fade
                    key={`category_${category.id}`}
                    className={classes.category}
                    in
                    appear
                  >
                    <div>
                      <TagsCategory
                        translations={translations}
                        category={category}
                        onSelectTag={onSelectTag}
                        isTagSelectable
                        isSmallCategoryBox
                      />
                    </div>
                  </Fade>
                ))}
              </TransitionGroup>
            ) : (
              <NoDataText text={translations.noResults} />
            )}
          </>
        </CustomScrollBar>
      </div>
    </CustomModal>
  );
};

ManageUserTagsDialog.defaultProps = {
  userAvatars: undefined,
  selectedTags: [],
};

ManageUserTagsDialog.propTypes = {
  translations: PropTypes.object.isRequired,
  isOpened: PropTypes.bool.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  selectedTags: PropTypes.array,
  userAvatars: PropTypes.object,
};

export default memo(ManageUserTagsDialog);
