import { isArray, isArrayEmpty } from './helpers';

export const getSelectFieldOptions = (
  field,
  values,
  options = [],
  parentOptions = []
) => {
  if (field.optionsKey) {
    if (field.circularDependant) {
      if (
        isArray(values[field.circularDependant]) &&
        !isArrayEmpty(values[field.circularDependant])
      ) {
        return options.filter(
          option =>
            !values[field.circularDependant].some(
              circularOption =>
                circularOption === option || circularOption?.id === option?.id
            )
        );
      }
    }
    if (field.parent) {
      const parentValue = values[field.parent.name];

      if (!parentValue) {
        return [];
      }
      const parent = parentOptions.find(elem => elem.id === parentValue);

      return (parent && parent[field.optionsKey]) || [];
    }
    return options;
  }
  return [];
};
