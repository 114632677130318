import { memo } from 'react';
import classNames from 'classnames';
import { components } from 'react-select';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    maxHeight: '220px !important',
    padding: `${spacing(0, 2)} !important`,
    '&::-webkit-scrollbar': {
      borderRadius: 4,
      background: 'rgb(201,212,218, .44)',
      height: 150,
      width: 4,
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: 4,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 4,
      background: primary.blue2,
    },
  },
}));

const MenuList = props => {
  const classes = useStyles();

  return (
    <components.MenuList
      innerRef={props.innerRef}
      {...props}
      className={classNames(classes.root, props.selectProps?.menuListClass)}
    >
      {props.children}
    </components.MenuList>
  );
};

export default memo(MenuList);
