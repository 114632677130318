import { createAction, handleActions } from 'redux-actions';
import {
  api_user_organization,
  API_ORG_CHART,
} from '../../constants/apiRoutes';
import http from '../../utility/http';
import { formatUserForOrgChart } from '../../utility/uiUtils';
import {
  canSeeEmployeeProfile,
  updatePersonRespondents,
} from '../../utility/helpers';

// ------------------------------------
// Constants
// ------------------------------------

const SET_EMPLOYEES_HIERARCHY = 'SET_EMPLOYEES_HIERARCHY';
const SET_EMPLOYEE_RESPONDENTS = 'SET_EMPLOYEE_RESPONDENTS';

const initialState = {
  employeesHierarchy: {},
};

// ------------------------------------
// Actions
// ------------------------------------
const setEmployeesHierarchy = createAction(SET_EMPLOYEES_HIERARCHY);
export const setEmployeeRespondents = createAction(SET_EMPLOYEE_RESPONDENTS);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_EMPLOYEES_HIERARCHY]: (state, { payload }) => {
    return { ...state, employeesHierarchy: payload };
  },
  [SET_EMPLOYEE_RESPONDENTS]: (state, { payload }) => {
    const children = updatePersonRespondents(
      state.employeesHierarchy.children,
      payload.id,
      payload.data
    );

    return {
      ...state,
      employeesHierarchy: {
        ...state.employeesHierarchy,
        children,
      },
    };
  },
};

export const clearEmployeesHierarchy = dispatch =>
  dispatch(setEmployeesHierarchy(initialState.employeesHierarchy));

export const getEmployeesHierarchy = (dispatch, auth) => {
  return http.get(API_ORG_CHART).then(({ data }) => {
    const user = data;
    return http.get(api_user_organization(user.id)).then(res => {
      const { global_see_himself } = auth;
      const ceoUser = {
        ...formatUserForOrgChart(
          { ...user, users: res.data },
          canSeeEmployeeProfile(auth, user.id)
        ),
        children: res.data.map(u =>
          formatUserForOrgChart(
            u,
            canSeeEmployeeProfile(auth, u.id, global_see_himself)
          )
        ),
      };
      dispatch(setEmployeesHierarchy(ceoUser));
    });
  });
};

export default handleActions(reducers, initialState);
