import PropTypes from 'prop-types';
import { Fade, makeStyles } from '@material-ui/core';
import { TransitionGroup } from 'react-transition-group';
import ReactMarkdown from 'react-markdown';
import CustomModal from '../customModal';
import CustomScrollBar from '../customScrollBar';
import SectionLoader from '../sectionLoader';
import { isArrayEmpty } from '../../../utility/helpers';

const useStyles = makeStyles(({ palette: { primary } }) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: 350,
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: 350,
    alignItems: 'center',
  },
  suggestionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  suggestionItems: {
    '& ol': {
      marginTop: 0,
      marginBottom: 0,
      '& li': {
        '&::marker': {
          fontFamily: 'ProximaNova-Bold',
          fontSize: 14,
          lineHeight: '18px',
        },
        '& p': {
          fontFamily: 'ProximaNova-Regular',
          fontSize: 16,
          lineHeight: '20px',
          marginTop: 0,
        },
      },
    },
  },
  scrollY: {
    backgroundColor: primary.bluish9,
    top: 0,
    right: -20,
    height: '100%',
    width: 8,
  },
  scroll: {
    backgroundColor: primary.bluish7,
  },
}));

const AiSuggestionsDialog = ({
  translations,
  isOpened,
  isLoading,
  suggestions,
  onClose,
}) => {
  const classes = useStyles();
  const hasSuggestions = !isArrayEmpty(suggestions);

  return (
    <CustomModal
      title={translations.title}
      closeButtonLabel={translations.close}
      isOpened={isOpened}
      isCloseDisabled={isLoading}
      onClose={onClose}
      shouldRemoveCloseIconButton
      isMedium
    >
      <div className={classes.content}>
        <CustomScrollBar
          customScrollBarYClass={classes.scrollY}
          customScrollClass={classes.scroll}
          verticalScroll
          removeScrollX
          passContentHeight
          passContentWidth
        >
          <>
            {isLoading && !hasSuggestions && (
              <div className={classes.loader}>
                <SectionLoader isLoading={isLoading} relativePosition />
              </div>
            )}
            <TransitionGroup className={classes.suggestionsWrapper}>
              {suggestions.map((suggestion, index) => (
                <Fade key={`suggestion_item_${index}`} in unmountOnExit>
                  <div className={classes.suggestionItems}>
                    <ReactMarkdown>{suggestion.text}</ReactMarkdown>
                  </div>
                </Fade>
              ))}
            </TransitionGroup>
          </>
        </CustomScrollBar>
      </div>
    </CustomModal>
  );
};

AiSuggestionsDialog.defaultProps = {
  suggestions: [],
};

AiSuggestionsDialog.propTypes = {
  translations: PropTypes.object.isRequired,
  isOpened: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.object),
  onClose: PropTypes.func.isRequired,
};

export default AiSuggestionsDialog;
