import { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import UserLink from '../../userLink';
import CustomCheckbox from '../../customCheckbox';

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gridTemplateColumns: ({ shouldHideAssignedUser }) =>
      shouldHideAssignedUser ? '1fr' : '1fr 200px',
    gridColumnGap: 16,
  },
  userWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
}));

const Task = ({
  className,
  task,
  shouldHideAssignedUser,
  checkCanManageTask,
  canAccessUserProfile,
  onChange,
}) => {
  const classes = useStyles({ shouldHideAssignedUser });
  const { text, user, is_finished } = task;
  const isUserAccessible = canAccessUserProfile(user.id);
  const canManageTask = checkCanManageTask(task);

  const handleChange = useCallback(() => {
    return onChange(task);
  }, [task, onChange]);

  return (
    <div className={classNames(classes.root, className)}>
      <CustomCheckbox
        isChecked={is_finished}
        labelText={text}
        disabled={!canManageTask}
        onChange={handleChange}
        shouldTopAlign
        isControlled
        smallText
      />
      {!shouldHideAssignedUser && (
        <div className={classes.userWrapper}>
          <UserLink user={user} isUserAccessible={isUserAccessible} />
        </div>
      )}
    </div>
  );
};

Task.defaultProps = {
  className: undefined,
  shouldHideAssignedUser: false,
  canAccessUserProfile: () => false,
  checkCanManageTask: () => false,
};

Task.propTypes = {
  className: PropTypes.string,
  task: PropTypes.object.isRequired,
  shouldHideAssignedUser: PropTypes.bool,
  canAccessUserProfile: PropTypes.func,
  checkCanManageTask: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

export default memo(Task);
