import { memo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import 'react-date-range/dist/styles.css'; // main style file
import '../../../styles/customDateRangeTheme.css';
import { DateRangePicker } from 'react-date-range';
import { InputAdornment, ClickAwayListener } from '@material-ui/core';
import InputField from '../inputField';
import { ReactComponent as ClearIcon } from '../../../assets/icons/close-gray.svg';
import { customStaticRanges, formatDateRange, formatDate } from './config';

const KEY = 'selection';
const INITIAL_VALUES = {
  color: 'rgb(30, 135, 240)',
  key: KEY,
  startDate: null,
  endDate: null,
};
const DATE_FORMAT = 'yyyy-LL-dd';
const PLACEHOLDER_DATE = 'dd MMM yyyy';

const useStyles = makeStyles(() => ({
  calendarContainer: {
    position: 'absolute',
    zIndex: 9999,
  },
  clearRange: {
    cursor: 'pointer',
  },
}));

const MIN_DATE = new Date(2020, 0, 1);
const MAX_DATE = new Date();

const CustomDateRange = ({
  className,
  label,
  placeholder,
  initialRange,
  startAtKey,
  endAtKey,
  outsideTrigger,
  isClearable,
  onChange,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [range, setRange] = useState(INITIAL_VALUES);

  useEffect(() => {
    setRange(newRange => ({
      ...newRange,
      startDate: initialRange.startDate
        ? new Date(initialRange.startDate)
        : null,
      endDate: initialRange.endDate ? new Date(initialRange.endDate) : null,
    }));
  }, [initialRange]);

  const handleSelect = useCallback(
    newRange => {
      if (onChange) {
        onChange(
          {
            [startAtKey]: formatDate(newRange[KEY].startDate, DATE_FORMAT),
            [endAtKey]: formatDate(newRange[KEY].endDate, DATE_FORMAT),
          },
          outsideTrigger
        );
      }
    },
    [onChange, startAtKey, endAtKey, outsideTrigger]
  );

  const handleClearRange = e => {
    e.stopPropagation();
    onChange(null, outsideTrigger);
  };

  const toggleCalendar = useCallback(() => setOpen(!open), [open]);

  const renderClear = () => {
    return isClearable && range.startDate && range.endDate ? (
      <InputAdornment
        className={classes.clearRange}
        position="end"
        onClick={handleClearRange}
      >
        <ClearIcon />
      </InputAdornment>
    ) : undefined;
  };

  return (
    <div className={className}>
      <InputField
        label={label}
        placeholder={placeholder}
        value={formatDateRange(range, PLACEHOLDER_DATE)}
        endAdornment={renderClear()}
        onClick={toggleCalendar}
        fullWidth
        readOnly
      />
      {open ? (
        <ClickAwayListener onClickAway={toggleCalendar}>
          <div className={classes.calendarContainer}>
            <DateRangePicker
              showMonthArrow={false}
              staticRanges={customStaticRanges}
              ranges={[range]}
              onChange={handleSelect}
              minDate={MIN_DATE}
              maxDate={MAX_DATE}
              showDateDisplay
              inputRanges={[]}
            />
          </div>
        </ClickAwayListener>
      ) : null}
    </div>
  );
};

CustomDateRange.defaultProps = {
  className: '',
  initialRange: {},
  label: '',
  placeholder: '',
  outsideTrigger: false,
  isClearable: false,
};

CustomDateRange.propTypes = {
  className: PropTypes.string,
  initialRange: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
  label: PropTypes.string,
  outsideTrigger: PropTypes.bool,
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
  startAtKey: PropTypes.string.isRequired,
  endAtKey: PropTypes.string.isRequired,
};

export default memo(CustomDateRange);
