export const SECTION_LOADERS = {
  ONE_ON_ONE_STATS: 'ONE_ON_ONE_STATS',
  ACTION_PLANS_STATS: 'ACTION_PLANS_STATS',
  COURSES_STATS: 'COURSES_STATS',
  TEAM_INFO: 'TEAM_INFO',
  TEAM_ACTIONS: 'TEAM_ACTIONS',
  TEAM_SURVEYS: 'TEAM_SURVEYS',
  ACTION_PLANS_OVERVIEW: 'ACTION_PLANS_OVERVIEW',
  COURSES_OVERVIEW: 'COURSES_OVERVIEW',
  ONE_ON_ONE_OVERVIEW: 'ONE_ON_ONE_OVERVIEW',
  TEAMS_OVERVIEW: 'TEAMS_OVERVIEW',
  ONE_ON_ONE_REQUESTS: 'ONE_ON_ONE_REQUESTS',
};
