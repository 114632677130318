import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import UserAvatar from '../userAvatar';
import StatusIndicator from '../statusIndicator';
import ActionButton from '../actionButton';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';

const styles = ({ palette: { primary }, spacing }) => ({
  main: {
    backgroundColor: primary.bluish9,
    borderRadius: 8,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    padding: spacing(2, 4),
    width: '100%',
    height: 64,
  },
  employee: {
    flexGrow: 1,
    marginRight: 'auto',
    maxWidth: 500,
  },
  fullName: {
    fontFamily: 'ProximaNova-Bold',
  },
  disenrollButton: {
    marginLeft: spacing(3),
  },
});

class EnrolledEmployeeCard extends PureComponent {
  handleDisenroll = e => {
    const { onDisenroll } = this.props;

    e.stopPropagation();
    onDisenroll();
  };

  renderEmployee = () => {
    const { classes, translations, user, canOpenPersonProfile, onGoToCourses } =
      this.props;

    return (
      <UserAvatar
        className={classes.employee}
        labelClass={classes.fullName}
        user={user}
        captionDescription={
          user.position ? user.position.name : translations.noJobTitle
        }
        onClickHandler={canOpenPersonProfile ? onGoToCourses : () => {}}
        disabled={!canOpenPersonProfile}
        clickableCaption={canOpenPersonProfile}
        caption
        withDescription
        medium
      />
    );
  };

  renderDisenrollButton = () => {
    const { classes } = this.props;

    return (
      <ActionButton
        className={classes.disenrollButton}
        type={ACTION_BUTTON_TYPES.DELETE}
        onClickHandler={this.handleDisenroll}
      />
    );
  };

  render() {
    const {
      className,
      classes,
      status,
      statuses,
      isReadOnly,
      isStatusChangeDisabled,
      onChangeEnrollmentStatus,
    } = this.props;

    return (
      <div className={classNames(classes.main, className)}>
        {this.renderEmployee()}
        <StatusIndicator
          status={status}
          statuses={statuses}
          onSelect={onChangeEnrollmentStatus}
          isSelectable={!isReadOnly && !isStatusChangeDisabled}
        />
        {!isReadOnly && this.renderDisenrollButton()}
      </div>
    );
  }
}

EnrolledEmployeeCard.defaultProps = {
  className: null,
  isStatusChangeDisabled: false,
};

EnrolledEmployeeCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  user: PropTypes.shape({}).isRequired,
  status: PropTypes.shape({}).isRequired,
  statuses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  isStatusChangeDisabled: PropTypes.bool,
  onChangeEnrollmentStatus: PropTypes.func.isRequired,
  onGoToCourses: PropTypes.func.isRequired,
  onDisenroll: PropTypes.func.isRequired,
};

export default withStyles(styles)(EnrolledEmployeeCard);
