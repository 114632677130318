import { memo } from 'react';
import BackgroundContainer from 'components/shared/backgroundContainer';
import ForgottenPasswordForm from '../../shared/forgottenPasswordForm';
import { useTranslations } from '../../../utility/useTranslations';
import { APP_PAGES } from '../../../constants/pages';

const ForgottenPasswordPage = props => {
  const translations = useTranslations(APP_PAGES.FORGOTTEN_PASSWORD);

  return (
    <BackgroundContainer translations={translations.authContainer}>
      <ForgottenPasswordForm {...props} translations={translations} />
    </BackgroundContainer>
  );
};

export default memo(ForgottenPasswordPage);
