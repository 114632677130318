import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import PresetDropdown from 'components/shared/presetDropdown';
import {
  getActivePresetId,
  getActivePresetName,
  getFilteredPresets,
  getPlaceholderName,
  handleHasMaxPresets,
  handlePresetName,
} from 'utility/presets';
import { PRESETS } from 'constants/presets';
import { showSuccessMessage } from 'utility/uiUtils';
import AlertDialog from 'components/shared/alertDialog';
import AttributesList from '../../shared/attributesList';
import NotificationCard from '../../shared/notificationCard';
import CustomDateRange from '../../shared/customDateRange';
import CompetenceMapChart from '../../shared/competenceMapChart';
import UserCompetenceScores from '../../shared/userCompetenceScores';
import { sticky } from '../../../constants/helperCssRules';
import {
  isArrayEmpty,
  isUserProfileAccessible,
  asyncDebounce,
  isItemInList,
  areArraysContentsEqual,
  getItemById,
  getObjectToNumberArray,
  isValueInList,
} from '../../../utility/helpers';
import { PARAMS } from '../../../constants/pages';
import { COMPETENCE_MAP_ATTRIBUTE_CONFIG } from '../../../constants/attributes';
import { getLast12Months } from '../../shared/customDateRange/config';
import {
  NOTIFICATION_DELAY,
  NOTIFICATION_CLEAR_DELAY,
  MAX_SELECTED_SCORES,
  getInitiallySelectedAttributes,
  getChartData,
  updateSelectedScores,
  handlePeriod,
} from './config';

const styles = ({ palette: { primary }, spacing }) => ({
  description: {
    padding: spacing(8, 0, 6, 0),
    ...sticky(primary.white, 105),
  },
  attributesWrapper: {
    position: 'relative',
    marginBottom: spacing(6),
  },
  attributesLabel: {
    marginBottom: spacing(2),
  },
  notification: {
    color: primary.red1,
    position: 'absolute',
    left: 0,
    bottom: -16,
  },
  filter: {
    marginBottom: spacing(6),
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0, 350px))',
    gridColumnGap: 16,
  },
  chart: {
    marginTop: spacing(6),
  },
  scoresTitle: {
    margin: spacing(6, 0),
  },
  score: {
    marginBottom: spacing(4),
    'last-of-type': {
      marginBottom: spacing(0),
    },
  },
});

const COMPETENCE_MAP_PRESET_ID = PRESETS.COMPETENCE_MAP;

class CompetenceMapPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isScoreLoading: false,
      selectedAttributes: [],
      selectedScores: [],
      period: getLast12Months(),
      hasNotification: false,
      notificationMessage: '',
      alreadySavedMessage: '',
      isDeletePresetDialogOpened: false,
      selectedPreset: undefined,
      defaultPlaceholder: false,
      presetPlaceholderName: '',
    };
    this.handleShowMinMaxNotification = asyncDebounce(
      this.handleShowMinMaxNotification,
      NOTIFICATION_DELAY,
      true
    );
  }

  componentDidMount() {
    const { presets } = this.props;
    const competenceMapPagePresets = presets[COMPETENCE_MAP_PRESET_ID];
    const existsPresetsArray =
      competenceMapPagePresets && !isArrayEmpty(competenceMapPagePresets);
    const initialPreset = existsPresetsArray
      ? getItemById(
          competenceMapPagePresets,
          presets.active[COMPETENCE_MAP_PRESET_ID]
        )
      : undefined;

    this.onGetMeasuredAttributes(initialPreset);
  }

  componentWillUnmount() {
    const {
      clearMeasuredAttributes,
      clearAttributesScoreDistribution,
      clearAttributesUsersScores,
    } = this.props;

    clearMeasuredAttributes();
    clearAttributesScoreDistribution();
    clearAttributesUsersScores();
  }

  onGoToProfilePage = userId => () => {
    const { history } = this.props;
    history.push(`/people/${userId}`);
  };

  checkValueInPresets = (scores, period, selectableAttributes) => {
    const { presets, editPresetActivity, translations } = this.props;
    const competenceMapPresets = presets[COMPETENCE_MAP_PRESET_ID];

    if (competenceMapPresets && !isArrayEmpty(competenceMapPresets)) {
      const activePreset = competenceMapPresets.find(preset => {
        const presetData = preset?.data;
        const tempSelectedAttributes = getObjectToNumberArray(
          presetData.attributes
        );
        const { end, start } = presetData.period;

        return (
          period.end === end &&
          period.start === start &&
          areArraysContentsEqual(
            tempSelectedAttributes,
            selectableAttributes
          ) &&
          areArraysContentsEqual(presetData.scores, scores)
        );
      });
      if (activePreset) {
        editPresetActivity(activePreset.id, COMPETENCE_MAP_PRESET_ID, true);
        this.setState({
          selectedPreset: activePreset,
          alreadySavedMessage: translations.presets.used,
        });
      } else {
        this.handleResetPreset();
      }
    }
  };

  handleResetPreset = () => {
    const { editPresetActivity } = this.props;

    editPresetActivity(-1, COMPETENCE_MAP_PRESET_ID, false);
    this.setState({
      selectedPreset: undefined,
      alreadySavedMessage: '',
    });
  };

  getFilteredAttributes = (results, initialPreset) => {
    const { selectedAttributes } = this.state;
    const tempAttributes = initialPreset
      ? initialPreset?.data?.attributes
      : selectedAttributes;
    const tempSelectedAttributes = getObjectToNumberArray(tempAttributes);

    return results.filter(attribute =>
      isValueInList(tempSelectedAttributes, attribute.id)
    );
  };

  onGetMeasuredAttributes = (initialPreset = null) => {
    const {
      getMeasuredAttributes,
      clearAttributesScoreDistribution,
      scoreDistribution,
      clearAttributesUsersScores,
    } = this.props;
    const { period, selectedScores } = this.state;
    const tempPeriod = handlePeriod(initialPreset, period);
    const params = {
      [PARAMS.USED_AFTER]: tempPeriod.start,
      [PARAMS.USED_BEFORE]: tempPeriod.end,
    };

    return getMeasuredAttributes(params).then(results => {
      const selectedAttributes = initialPreset
        ? this.getFilteredAttributes(results, initialPreset)
        : getInitiallySelectedAttributes(results);
      const scores = initialPreset
        ? initialPreset?.data?.scores
        : selectedScores;

      if (!isArrayEmpty(selectedAttributes)) {
        return this.onGetAttributesScoreDistribution(
          selectedAttributes,
          initialPreset
        ).then(() =>
          this.setState(
            {
              isLoading: false,
              selectedAttributes,
              selectedScores: updateSelectedScores(
                scores,
                this.props.scoreDistribution
              ),
              ...(initialPreset ? { period: initialPreset?.data?.period } : {}),
            },
            this.onGetAttributesUsersScores
          )
        );
      }

      if (!isArrayEmpty(scoreDistribution)) {
        clearAttributesScoreDistribution();
      }

      clearAttributesUsersScores();
      this.setState(
        {
          isLoading: false,
          selectedAttributes,
          selectedScores: [],
        },
        () =>
          this.checkValueInPresets(
            [],
            getObjectToNumberArray(selectedAttributes),
            getLast12Months()
          )
      );
    });
  };

  onGetAttributesScoreDistribution = (attributes, initialPreset = null) => {
    const { getAttributesScoreDistribution } = this.props;
    const { period } = this.state;
    const tempPeriod = handlePeriod(initialPreset, period);

    const params = {
      attribute: getObjectToNumberArray(attributes),
      [PARAMS.USED_AFTER]: tempPeriod.start,
      [PARAMS.USED_BEFORE]: tempPeriod.end,
    };

    return getAttributesScoreDistribution(params);
  };

  onGetAttributesUsersScores = () => {
    const { getAttributesUsersScores, clearAttributesUsersScores } = this.props;
    const { period, selectedScores, selectedAttributes } = this.state;
    const hasSelectedScores = !isArrayEmpty(selectedScores);

    const params = {
      attribute: getObjectToNumberArray(selectedAttributes),
      score: selectedScores,
      [PARAMS.USED_AFTER]: period.start,
      [PARAMS.USED_BEFORE]: period.end,
    };

    if (hasSelectedScores) {
      return getAttributesUsersScores(params).then(() =>
        this.setState({ isScoreLoading: false }, () =>
          this.checkValueInPresets(selectedScores, period, params.attribute)
        )
      );
    }

    this.setState({ isScoreLoading: false }, () => {
      clearAttributesUsersScores();
      this.checkValueInPresets(selectedScores, period, params.attribute);
    });
  };

  handleAttributeSelect = attribute => {
    const { selectedAttributes, selectedScores } = this.state;
    const { translations } = this.props;
    const { MIN, MAX } = COMPETENCE_MAP_ATTRIBUTE_CONFIG;
    const isSelected = isItemInList(selectedAttributes, attribute);
    const attributesCount = selectedAttributes.length;
    let updatedAttributes = [];

    this.handleResetPreset();

    if (isSelected) {
      if (attributesCount > MIN) {
        updatedAttributes = selectedAttributes.filter(
          selectedAttribute => selectedAttribute.id !== attribute.id
        );
        this.setState(
          {
            selectedAttributes: updatedAttributes,
            defaultPlaceholder: true,
          },
          () =>
            this.onGetAttributesScoreDistribution(updatedAttributes).then(() =>
              this.setState(
                {
                  selectedScores: updateSelectedScores(
                    selectedScores,
                    this.props.scoreDistribution
                  ),
                },
                this.onGetAttributesUsersScores
              )
            )
        );
      } else {
        this.handleShowMinMaxNotification(
          `${translations.minAttribute} ${MIN}`
        );
      }
    } else if (attributesCount < MAX) {
      updatedAttributes = [...selectedAttributes, attribute];
      this.setState({ selectedAttributes: updatedAttributes }, () =>
        this.onGetAttributesScoreDistribution(updatedAttributes).then(() =>
          this.setState(
            {
              defaultPlaceholder: true,
              selectedScores: updateSelectedScores(
                selectedScores,
                this.props.scoreDistribution
              ),
            },
            this.onGetAttributesUsersScores
          )
        )
      );
    } else {
      this.handleShowMinMaxNotification(`${translations.maxAttribute} ${MAX}`);
    }
  };

  handlePeriodChange = newRange =>
    this.setState({ period: newRange, defaultPlaceholder: true }, () =>
      this.onGetMeasuredAttributes()
    );

  handleScoreSelect = scoreValue => {
    const { translations } = this.props;
    const { selectedScores } = this.state;
    const isSelected = selectedScores.includes(scoreValue);
    const selectedCount = selectedScores.length;

    if (!isSelected && selectedCount === MAX_SELECTED_SCORES) {
      return this.handleShowMinMaxNotification(
        `${translations.maxSelectedScores} ${MAX_SELECTED_SCORES}`
      );
    }

    this.setState(
      {
        isScoreLoading: true,
        defaultPlaceholder: true,
        selectedScores: isSelected
          ? selectedScores.filter(score => score !== scoreValue)
          : [...selectedScores, scoreValue],
      },
      this.onGetAttributesUsersScores
    );
  };

  handleShowMinMaxNotification = notificationMessage =>
    this.setState(
      { hasNotification: true, notificationMessage },
      asyncDebounce(
        () =>
          this.setState({ hasNotification: false, notificationMessage: '' }),
        NOTIFICATION_CLEAR_DELAY
      )
    );

  handlePlaceholder = name =>
    this.setState({
      defaultPlaceholder: false,
      presetPlaceholderName: name,
    });

  handleDeletePresetDialog = preset => {
    this.setState({ selectedPreset: preset, isDeletePresetDialogOpened: true });
  };

  handleDeletePreset = () => {
    const { deleteSelectedPreset, translations, presets } = this.props;
    const { selectedPreset } = this.state;

    deleteSelectedPreset(selectedPreset.id, COMPETENCE_MAP_PRESET_ID).then(
      () => {
        this.setState({
          isDeletePresetDialogOpened: false,
          ...(presets.active[COMPETENCE_MAP_PRESET_ID] === selectedPreset.id
            ? { selectedPreset: undefined, alreadySavedMessage: '' }
            : {}),
        });
        showSuccessMessage(translations.presets.successMessages.deletedPreset);
      }
    );
  };

  handleSavePreset = presetName => {
    const { translations, addNewPreset } = this.props;
    const { period, selectedAttributes, selectedScores } = this.state;

    const data = {
      period,
      attributes: selectedAttributes,
      scores: selectedScores,
    };
    addNewPreset({
      name: presetName,
      location: COMPETENCE_MAP_PRESET_ID,
      data,
    }).then(() => {
      this.setState({
        alreadySavedMessage: translations.presets.used,
      });
      showSuccessMessage(translations.presets.successMessages.createdPreset);
    });
  };

  handleApplyPreset = preset => {
    const { editPresetActivity } = this.props;
    const presetData = preset?.data;

    editPresetActivity(preset.id, COMPETENCE_MAP_PRESET_ID, true);
    this.setState(
      {
        period: presetData?.period,
        selectedAttributes: presetData?.attributes,
        selectedScores: presetData?.scores,
        selectedPreset: preset,
      },
      () => this.onGetMeasuredAttributes(preset)
    );
  };

  render() {
    const {
      classes,
      translations,
      organizationSettings,
      attributes,
      scoreDistribution,
      usersScores,
      auth,
      presets,
    } = this.props;
    const {
      isLoading,
      isScoreLoading,
      selectedAttributes,
      period,
      selectedScores,
      hasNotification,
      notificationMessage,
      alreadySavedMessage,
      isDeletePresetDialogOpened,
    } = this.state;
    const activePresetId = getActivePresetId(presets, COMPETENCE_MAP_PRESET_ID);
    const filteredPresets = getFilteredPresets(
      presets,
      COMPETENCE_MAP_PRESET_ID
    );
    const presetName = getActivePresetName(filteredPresets, activePresetId);
    const handledPresetName = handlePresetName(
      filteredPresets,
      activePresetId,
      presetName,
      this.state,
      translations.presets.startSearch
    );
    const placeholderName = getPlaceholderName(
      handledPresetName,
      presetName,
      translations.presets.startSearch
    );
    const hasAttributes = !isArrayEmpty(attributes);
    const hasScore = !isArrayEmpty(scoreDistribution);
    const hasSelectedScores = !isArrayEmpty(selectedScores);
    const hasUsersScores = !isArrayEmpty(usersScores);

    return (
      <div>
        <div className={classes.description}>
          <Typography variant="body2">{translations.description}</Typography>
        </div>
        <div className={classes.filter}>
          <CustomDateRange
            label={translations.range}
            initialRange={{
              startDate: period[PARAMS.START],
              endDate: period[PARAMS.END],
            }}
            startAtKey={PARAMS.START}
            endAtKey={PARAMS.END}
            onChange={this.handlePeriodChange}
          />
          <PresetDropdown
            translations={translations.presets}
            options={filteredPresets}
            activePreset={activePresetId}
            handleApply={this.handleApplyPreset}
            handleDelete={this.handleDeletePresetDialog}
            handleSavePreset={this.handleSavePreset}
            disabledPreset={handleHasMaxPresets(
              filteredPresets,
              translations.presets
            )}
            alreadySavedMessage={alreadySavedMessage}
            presetPlaceholderName={placeholderName}
            handlePlaceholder={this.handlePlaceholder}
          />
        </div>
        {!isLoading && (
          <>
            <div className={classes.attributesWrapper}>
              <Typography className={classes.attributesLabel} variant="h5">
                {translations.attributes}
              </Typography>
              {hasAttributes ? (
                <AttributesList
                  attributes={attributes}
                  selectedAttributes={selectedAttributes}
                  onSelect={this.handleAttributeSelect}
                  isHorizontalList
                  isScrollable
                />
              ) : (
                <NotificationCard content={translations.noRecords} />
              )}
              {hasNotification && (
                <Typography className={classes.notification} variant="caption">
                  {notificationMessage}
                </Typography>
              )}
            </div>
            {hasAttributes && hasScore && (
              <CompetenceMapChart
                className={classes.chart}
                yAxisLabel={translations.yAxisLabel}
                data={getChartData(scoreDistribution)}
                selectedScores={selectedScores}
                onScoreSelect={this.handleScoreSelect}
              />
            )}
            <Typography className={classes.scoresTitle} variant="h4">
              {`${translations.selectedScore} ${
                hasSelectedScores ? `(${selectedScores.join(', ')})` : ''
              }`}
            </Typography>
            {!isScoreLoading && !hasSelectedScores && !hasUsersScores && (
              <NotificationCard content={translations.noScores} />
            )}
            {hasSelectedScores && hasUsersScores && (
              <div className={classes.scores}>
                {usersScores.map(
                  score =>
                    !isArrayEmpty(score.users) && (
                      <UserCompetenceScores
                        key={`attributes_score_${score.id}`}
                        className={classes.score}
                        attribute={score}
                        isUserProfileAccessible={isUserProfileAccessible(
                          auth,
                          organizationSettings.global_see_himself
                        )}
                        onGoToProfilePage={this.onGoToProfilePage}
                      />
                    )
                )}
              </div>
            )}
          </>
        )}
        <AlertDialog
          translations={translations.presets.presetDeleteDialog}
          isOpened={isDeletePresetDialogOpened}
          onClose={() => this.setState({ isDeletePresetDialogOpened: false })}
          onConfirm={this.handleDeletePreset}
          isWarning
        />
      </div>
    );
  }
}

CompetenceMapPage.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  organizationSettings: PropTypes.object.isRequired,
  attributes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  scoreDistribution: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  usersScores: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getMeasuredAttributes: PropTypes.func.isRequired,
  getAttributesScoreDistribution: PropTypes.func.isRequired,
  getAttributesUsersScores: PropTypes.func.isRequired,
  clearMeasuredAttributes: PropTypes.func.isRequired,
  clearAttributesScoreDistribution: PropTypes.func.isRequired,
  clearAttributesUsersScores: PropTypes.func.isRequired,
};

export default withStyles(styles)(CompetenceMapPage);
