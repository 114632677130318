import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    boxSizing: 'border-box',
    backgroundColor: primary.blue8,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    transition: 'background-color .25s ease-in',
    padding: spacing(1.5, 2),
    marginTop: spacing(1),
    width: '100%',
    '&:hover': {
      backgroundColor: primary.blue7,
    },
  },
  active: {
    '&.active': {
      backgroundColor: primary.blue7,
      '& $label': {
        color: primary.bluish1,
      },
      '& svg path': {
        fill: primary.bluish1,
      },
      '& > div': {
        backgroundColor: `${primary.bluish1} !important`,
      },
    },
  },
  notActiveIcon: {
    '& > div': {
      backgroundColor: `${primary.bluish6} !important`,
    },
  },
  sectionRoot: {
    marginTop: 0,
    padding: spacing(1, 2),
  },
  icon: {
    marginRight: spacing(2),
    width: 16,
    height: 16,
  },
  sectionIcon: {
    width: 24,
    height: 24,
  },
  label: {
    color: primary.bluish3,
  },
}));

const NavigationLink = ({ linkData, isSectionLink }) => {
  const classes = useStyles();
  const location = useLocation();
  const {
    title,
    url,
    icon: Icon,
    childrenSection,
    hasParent,
    hasChildren,
  } = linkData;
  const pathname = location?.pathname;
  const isMenuSetup = childrenSection?.some(elem => pathname.includes(elem));
  const isActiveIcon =
    (!hasParent && !hasChildren) ||
    (hasChildren && !isMenuSetup) ||
    (isMenuSetup && hasParent && pathname.includes(url));

  return (
    <NavLink
      className={classNames(classes.root, {
        [classes.sectionRoot]: isSectionLink,
        [classes.active]: isActiveIcon,
        [classes.notActiveIcon]: !isActiveIcon,
      })}
      to={url}
    >
      <Icon
        className={classNames(classes.icon, {
          [classes.sectionIcon]: isSectionLink,
        })}
      />
      <Typography
        className={classNames(classes.label)}
        component="span"
        variant={isSectionLink ? 'subtitle1' : 'subtitle2'}
      >
        {title}
      </Typography>
    </NavLink>
  );
};

NavigationLink.defaultProps = {
  isSectionLink: false,
};

NavigationLink.propTypes = {
  linkData: PropTypes.object.isRequired,
  isSectionLink: PropTypes.bool,
};

export default memo(NavigationLink);
