import {
  isEmpty,
  validateEmail,
  validateLength,
  validateLowercase,
  validateName,
  validateOrganizationUrl,
  validateTypeOfCharacters,
  validateUppercase,
  validateWhitespace,
} from '../../../utility/validation';
import { FIELD_TYPES } from '../../../constants/fieldTypes';

export const SIGN_UP_FORM_FIELD_NAMES = {
  EMAIL: 'email',
  VERIFICATION: 'email_verification_code',
  FULL_NAME: 'full_name',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  DOMAIN_NAME: 'domain_name',
  PASSWORD: 'password',
  JOB_TITLE: 'job_title',
  INCENTIVE: 'incentive',
  COMPANY_SIZE: 'company_size',
  PREFERENCE: 'preference',
};

export const SIGN_UP_FORM_FIELDS = {
  EMAIL: { label: 'email', step: 0 },
  FIRST_NAME: { label: 'first_name', step: 2 },
  LAST_NAME: { label: 'last_name', step: 2 },
  DOMAIN_NAME: { label: 'domain_name', step: 3 },
  JOB_TITLE: { label: 'job_title', step: 4 },
  INCENTIVE: { label: 'incentive', step: 5 },
  COMPANY_SIZE: { label: 'company_size', step: 6 },
  PREFERENCE: { label: 'preference', step: 7 },
};

export const KADAR_APPLICANT = 'X-Authorization-Applicant';
export const APPLICANT = 'applicant';
export const APPLICANT_TOKEN = 'applicant_token';
export const DEMO_INIT = 'demo_init';

const JOB_TITLES = ['CEO/COO/C-level', 'HR', 'Manager', 'Other'];

export const INCENTIVE = [
  'Develop transparent leveling frameworks so everyone on the team can see their career paths.',
  'Set goals, align expectations, provide feedback, and maximize team performance.',
  `Understand the team's well-being and uncover things to address before they become a problem.`,
  'Curate learning materials and build personalized learning hubs on an individual level.',
];

export const INCENTIVE_MAP = {
  LEVEL_AND_JOB: 0,
  GOALS_AND_EXPECTATIONS: 1,
  TEAMS_WELL_BEING: 2,
  LEARNING: 3,
};

const COMPANY_SIZE = ['0-20', '21-100', '101-250', '251-500', '500+'];

export const COMPANY_SIZE_MAP = {
  '0-20': 'XS',
  '21-100': 'S',
  '101-250': 'M',
  '251-500': 'L',
  '500+': 'XL',
};

const PREFERENCE = [
  { label: 'Discover Kadar on my own', value: true },
  { label: 'Book a 1:1 call with a Kadar Expert', value: false },
];

export const STEPS_MAP = {
  EMAIL: 0,
  EMAIL_VERIFICATION_CODE: 1,
  DOMAIN_NAME: 3,
  PREFERENCE: 7,
  PASSWORD: 8,
};

export const getFields = translations => {
  return {
    0: {
      id: SIGN_UP_FORM_FIELD_NAMES.EMAIL,
      name: SIGN_UP_FORM_FIELD_NAMES.EMAIL,
      fieldType: FIELD_TYPES.TEXT,
      label: translations.formLabels.email.label,
      title: translations.email.emailPageTitle,
      subtitle: translations.email.emailPageSubtitle,
      required: true,
      validators: [
        {
          type: 'required',
          validator: isEmpty,
        },
        {
          type: 'exceededLengthEmail',
          validator: validateLength,
        },
        {
          type: 'invalidEmail',
          validator: validateEmail,
        },
      ],
    },
    1: {
      id: SIGN_UP_FORM_FIELD_NAMES.VERIFICATION,
      name: SIGN_UP_FORM_FIELD_NAMES.VERIFICATION,
      fieldType: 'number',
      inputLabel: translations.formLabels.verification.label,
      title: translations.verification.title,
      label: translations.verification.label,
    },
    2: {
      id: SIGN_UP_FORM_FIELD_NAMES.FULL_NAME,
      title: translations.fullName.title,
      fieldType: FIELD_TYPES.TEXT,
      fields: [
        {
          name: SIGN_UP_FORM_FIELD_NAMES.FIRST_NAME,
          placeholder: translations.formLabels.fullName.placeholder.firstName,
        },
        {
          name: SIGN_UP_FORM_FIELD_NAMES.LAST_NAME,
          placeholder: translations.formLabels.fullName.placeholder.lastName,
        },
      ],
      required: true,
      validators: [
        {
          validator: isEmpty,
          type: 'required',
        },
        {
          validator: validateName,
          type: 'invalidName',
        },
        {
          validator: value => validateLength(value, 2, undefined, true),
          type: 'minLength',
        },
        {
          validator: value => validateLength(value, 0, 50),
          type: 'maxLength',
        },
      ],
    },
    3: {
      id: SIGN_UP_FORM_FIELD_NAMES.DOMAIN_NAME,
      name: SIGN_UP_FORM_FIELD_NAMES.DOMAIN_NAME,
      fieldType: FIELD_TYPES.TEXT,
      placeholder: translations.formLabels.domainName.placeholder,
      title: translations.domainName.title,
      subtitle: translations.domainName.subtitle,
      domainNameLabel: translations.domainName.domainNameLabel,
      required: true,
      validators: [
        {
          type: 'required',
          validator: isEmpty,
        },
        {
          validator: validateOrganizationUrl,
          type: 'invalidWorkspaceUrl',
        },
        {
          validator: value => validateLength(value, 0, 50),
          type: 'maxLength',
        },
      ],
    },
    4: {
      id: SIGN_UP_FORM_FIELD_NAMES.JOB_TITLE,
      name: SIGN_UP_FORM_FIELD_NAMES.JOB_TITLE,
      title: translations.jobTitle.title,
      domainNameSubtitle: '.kadar.app',
      buttonLabels: JOB_TITLES,
      label: translations.jobTitle.label,
    },
    5: {
      id: SIGN_UP_FORM_FIELD_NAMES.INCENTIVE,
      name: SIGN_UP_FORM_FIELD_NAMES.INCENTIVE,
      title: translations.incentive.title,
      buttonLabels: INCENTIVE,
      label: translations.incentive.label,
    },
    6: {
      id: SIGN_UP_FORM_FIELD_NAMES.COMPANY_SIZE,
      name: SIGN_UP_FORM_FIELD_NAMES.COMPANY_SIZE,
      title: translations.companySize.title,
      buttonLabels: COMPANY_SIZE,
    },
    7: {
      id: SIGN_UP_FORM_FIELD_NAMES.PREFERENCE,
      name: SIGN_UP_FORM_FIELD_NAMES.PREFERENCE,
      title: translations.preference.title,
      buttonLabels: PREFERENCE,
    },
    8: {
      id: SIGN_UP_FORM_FIELD_NAMES.PASSWORD,
      name: SIGN_UP_FORM_FIELD_NAMES.PASSWORD,
      fieldType: FIELD_TYPES.PASSWORD,
      placeholder: translations.formLabels.password.placeholder,
      title: translations.password.title,
      passwordValidationLabels: translations.password.validationLabels,
      required: true,
      forbidTrim: true,
      validators: [
        {
          type: 'requiredLength',
          validator: value => validateLength(value, 8),
        },
        {
          type: 'lowercase',
          validator: validateLowercase,
        },
        {
          type: 'uppercase',
          validator: validateUppercase,
        },
        {
          type: 'typeOfCharacters',
          validator: validateTypeOfCharacters,
        },
        {
          type: 'whitespace',
          validator: validateWhitespace,
        },
      ],
    },
  };
};
