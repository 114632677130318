import { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Typography,
  Popper,
  ClickAwayListener,
  Fade,
} from '@material-ui/core';
import { TransitionGroup } from 'react-transition-group';
import classNames from 'classnames';
import { forbiddenCharacters, validateLength } from 'utility/validation';
import { ellipsis } from '../../../constants/helperCssRules';
import InputField from '../inputField';
import CustomButton from '../customButton';
import CustomScrollBar from '../customScrollBar';
import ActionButton from '../actionButton';
import {
  isArrayEmpty,
  trimString,
  isValueInList,
} from '../../../utility/helpers';
import { customSearch } from '../../../utility/uiUtils';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';
import ArrowTooltip from '../arrowTooltip';
import ConditionalTooltip from '../conditionalTooltip';
import TextBoxWithTooltip from '../textBoxWithTooltip';
import { setPopperWidth } from '../peoplePicker/config';

const styles = ({ palette: { primary }, spacing }) => ({
  picker: {
    display: 'flex',
    position: 'relative',
    '& > div': {
      width: '100%',
    },
  },
  placeholder: {
    '& .MuiFormControl-root .MuiInputBase-root': {
      '& > input': {
        ...ellipsis(),
      },
      '& > input::placeholder': {
        color: primary.black,
      },
    },
  },
  popperRoot: {
    boxShadow: 'none',
    borderRadius: 4,
    zIndex: 1,
  },
  popperMain: {
    boxSizing: 'border-box',
    backgroundColor: primary.white,
    border: `1px solid ${primary.bluish6}`,
    height: 330,
    borderRadius: 4,
    padding: spacing(4),
    marginTop: spacing(1),
  },
  popperContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  popoverFooter: {
    display: 'flex',
    marginTop: spacing(4),
    paddingTop: 24,
    borderTop: `1px solid ${primary.bluish7}`,
    justifyContent: 'space-between',
    '& .MuiFormControl-root': {
      width: 235,
    },
  },
  item: {
    borderRadius: 4,
  },
  presetItem: {
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    padding: spacing(2),
    justifyContent: 'space-between',
    '& > p': {
      ...ellipsis(),
    },
  },
  buttonContainer: {
    display: 'flex',
    flexShrink: 0,
    width: 24,
    height: 24,
    alignItems: 'center',
  },
  noResults: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: spacing(25),
  },
  noResultsWithSelectAll: {
    marginTop: spacing(12.5),
  },
  scrollY: {
    top: 11,
    right: -10,
    height: 'calc(100% - 22px)',
    width: 4,
  },
  hover: {
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: primary.bluish8,
    },
  },
  selectButton: {
    marginLeft: 'auto',
  },
  selectedPreset: {
    backgroundColor: primary.bluish9,
    '& > div > p': {
      fontFamily: 'ProximaNova-Bold',
    },
    '& > p': {
      fontFamily: 'ProximaNova-Bold',
    },
  },
});

const SCROLLBAR_HEIGHT = 200;

class PresetDropdown extends PureComponent {
  constructor(props) {
    super(props);

    this.inputRef = createRef();
    this.state = {
      isOpen: false,
      anchorEl: null,
      search: '',
      presets: [],
      isFocused: false,
      presetName: '',
      hasError: false,
      errorMessage: '',
    };
  }

  componentDidMount() {
    this.setAnchorEl();
  }

  componentDidUpdate(prevProps, prevState) {
    const { isFocused } = this.state;

    if (prevState.isFocused !== isFocused && isFocused) {
      this.getPreset();
    }
  }

  handleClose = () => {
    this.setState({
      isOpen: false,
      search: '',
      presets: [],
      hasError: false,
      errorMessage: '',
      presetName: '',
      isFocused: false,
    });
  };

  setAnchorEl = () => {
    this.setState({ anchorEl: this.inputRef?.current });
  };

  handleOpen = () => {
    const { isOpen } = this.state;

    if (!isOpen) {
      this.setState({ isOpen: true });
    }
  };

  getPreset = (term = '') => {
    const { options } = this.props;
    const searchTerm = trimString(term);
    const presets = customSearch(options, searchTerm);

    return this.setState({
      presets,
    });
  };

  handleChange = event => {
    const { value } = event.target;

    this.setState(
      {
        search: value,
      },
      () => {
        this.handleOpen();
        return this.getPreset(value);
      }
    );
  };

  handleInputFocus = () => {
    this.setState({ isFocused: true, isOpen: true });
  };

  handleValidation = name => {
    let message = '';
    const { hasError } = this.state;
    const { translations, options } = this.props;

    message = validateLength(name, 0, 100)
      ? message
      : translations.limitErrorMessage;
    message = forbiddenCharacters(name)
      ? message
      : translations.forbiddenCharacters;
    message = trimString(name) ? message : translations.emptyErrorMessage;
    const alreadyUsed = isValueInList(
      options.map(preset => preset.name),
      trimString(name)
    );

    if (message || alreadyUsed) {
      if (alreadyUsed) {
        this.setState({
          hasError: true,
          errorMessage: translations.availableName,
        });
      } else {
        this.setState({
          hasError: true,
          errorMessage: message,
        });
      }
      return false;
    }
    if (!message && !alreadyUsed && hasError) {
      this.setState({ hasError: false });
      return true;
    }
    return true;
  };

  handlePresetName = e => {
    const name = e.target.value;
    this.handleValidation(name);

    this.setState({ presetName: name });
  };

  handleSave = () => {
    const { presetName } = this.state;
    const { handleSavePreset, handlePlaceholder } = this.props;

    const isValid = this.handleValidation(presetName);
    if (isValid) {
      this.handleClose();
      handlePlaceholder(presetName);
      handleSavePreset(trimString(presetName));
    }
  };

  applyPreset = preset => {
    const { handleApply, handlePlaceholder } = this.props;

    handlePlaceholder(preset.name);
    handleApply(preset);
  };

  renderPresets = () => {
    const {
      classes,
      translations,
      handleDelete,
      activePreset,
      handlePlaceholder,
    } = this.props;
    const { presets, isOpen } = this.state;

    if (!isOpen) return null;

    if (!isArrayEmpty(presets)) {
      return (
        <TransitionGroup>
          {presets.map(preset => {
            const isPresetSelected = preset?.id === activePreset;
            return (
              <Fade key={preset.id} unmountOnExit appear addEndListener={null}>
                <div className={classes.item}>
                  <div
                    className={classNames(classes.presetItem, classes.hover, {
                      [classes.selectedPreset]: isPresetSelected,
                    })}
                    onClick={() =>
                      isPresetSelected ? null : this.applyPreset(preset)
                    }
                  >
                    <TextBoxWithTooltip text={preset.name}>
                      <Typography variant="body1">{preset.name}</Typography>
                    </TextBoxWithTooltip>
                    <div className={classes.buttonContainer}>
                      <ArrowTooltip
                        position="top"
                        tooltipLabel={translations.remove}
                      >
                        <ActionButton
                          type={ACTION_BUTTON_TYPES.DELETE_FILL}
                          onClickHandler={e => {
                            e.stopPropagation();
                            if (isPresetSelected) {
                              handlePlaceholder(translations.startSearch);
                            }
                            handleDelete(preset);
                          }}
                        />
                      </ArrowTooltip>
                    </div>
                  </div>
                </div>
              </Fade>
            );
          })}
        </TransitionGroup>
      );
    }
    return (
      <Typography className={classNames(classes.noResults)}>
        {translations.noPresetResults}
      </Typography>
    );
  };

  render() {
    const {
      dropdownWidth,
      inputWidth,
      className,
      classes,
      translations,
      isDisabled,
      disabledPreset,
      alreadySavedMessage,
      presetPlaceholderName,
    } = this.props;
    const { isOpen, anchorEl, search, presetName, hasError, errorMessage } =
      this.state;
    const savePresetTooltip = disabledPreset || alreadySavedMessage;
    const shouldFlip = inputWidth ? anchorEl?.clientWidth < inputWidth : false;

    return (
      <div className={className}>
        <ClickAwayListener
          onClickAway={this.handleClose}
          mouseEvent="onMouseDown"
        >
          <div className={classes.picker}>
            <div
              className={classNames({
                [classes.placeholder]:
                  presetPlaceholderName !== translations.startSearch,
              })}
              ref={this.inputRef}
            >
              <InputField
                label={translations.label}
                placeholder={presetPlaceholderName}
                value={search}
                disabled={isDisabled}
                onChange={this.handleChange}
                onInputFocus={this.handleInputFocus}
                autoComplete="off"
                fullWidth
              />
            </div>
            <Popper
              className={classes.popperRoot}
              style={{
                width: anchorEl ? anchorEl.clientWidth : null,
              }}
              anchorEl={anchorEl}
              open={isOpen}
              modifiers={{
                flip: {
                  enabled: true,
                  options: {
                    altBoundary: true,
                    rootBoundary: 'viewport',
                    allowedAutoPlacements: ['bottom-end'],
                    fallbackPlacements: ['bottom-end'],
                  },
                  preventOverflow: {
                    enabled: true,
                    altBoundary: true,
                    escapeWithReference: true,
                    boundariesElement: 'viewport',
                  },
                },
              }}
              popperOptions={{
                onCreate: ({ instance: { reference, popper } }) =>
                  setPopperWidth(reference, popper, dropdownWidth),
                onUpdate: ({ instance: { reference, popper } }) =>
                  setPopperWidth(reference, popper, dropdownWidth),
              }}
              placement="bottom-start"
              disablePortal
              keepMounted={!!shouldFlip}
            >
              <div className={classes.popperMain}>
                <div className={classes.popperContent}>
                  {isOpen ? (
                    <CustomScrollBar
                      customScrollBarYClass={classes.scrollY}
                      scrollBarHeight={SCROLLBAR_HEIGHT}
                      verticalScroll
                      removeScrollX
                    >
                      {this.renderPresets()}
                    </CustomScrollBar>
                  ) : null}
                </div>
                <div className={classes.popoverFooter}>
                  <InputField
                    name="name"
                    onChange={this.handlePresetName}
                    value={presetName}
                    placeholder={translations.inputPlaceholder}
                    error={hasError}
                    errorMessage={errorMessage}
                  />
                  <ConditionalTooltip
                    message={savePresetTooltip}
                    addTooltip={
                      Boolean(disabledPreset) || Boolean(alreadySavedMessage)
                    }
                  >
                    <CustomButton
                      className={classes.selectButton}
                      type="withTextLightRounded"
                      disabled={
                        Boolean(disabledPreset) || Boolean(alreadySavedMessage)
                      }
                      onClick={this.handleSave}
                    >
                      {translations.saveButtonLabel}
                    </CustomButton>
                  </ConditionalTooltip>
                </div>
              </div>
            </Popper>
          </div>
        </ClickAwayListener>
      </div>
    );
  }
}

PresetDropdown.defaultProps = {
  className: undefined,
  disabledPreset: '',
  options: [],
  activePreset: -1,
  alreadySavedMessage: '',
  dropdownWidth: null,
  inputWidth: 0,
  handlePlaceholder: () => {},
};

PresetDropdown.propTypes = {
  className: PropTypes.string,
  activePreset: PropTypes.number,
  dropdownWidth: PropTypes.string,
  inputWidth: PropTypes.number,
  handleApply: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleSavePreset: PropTypes.func.isRequired,
  alreadySavedMessage: PropTypes.string,
  translations: PropTypes.object.isRequired,
  disabledPreset: PropTypes.string,
  handlePlaceholder: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({})),
};

export default withStyles(styles)(PresetDropdown);
