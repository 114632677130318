import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { ONE_ON_ONE_COMMENT_FIELDS } from 'constants/oneOnOne';
import classNames from 'classnames';
import CommentItem from './commentItem';
import AddComment from '../addComment';
import AlertDialog from '../alertDialog';

class UsersComments extends PureComponent {
  state = {
    isDeleteCommentOpened: false,
    commentId: null,
  };

  handleDeleteComment = commentId => {
    this.setState({ isDeleteCommentOpened: true, commentId });
  };

  onCancelDelete = () => {
    this.setState({ isDeleteCommentOpened: false, commentId: null });
  };

  onDelete = () => {
    const { onDeleteComment } = this.props;
    const { commentId } = this.state;

    onDeleteComment(commentId);
    this.onCancelDelete();
  };

  canEditComment = comment => {
    const { canAddComment, currentUser } = this.props;
    const { CREATOR } = ONE_ON_ONE_COMMENT_FIELDS;

    return canAddComment && comment[CREATOR]?.id === currentUser.id;
  };

  render() {
    const {
      className,
      translations,
      currentUser,
      comments,
      onSendComment,
      canAddComment,
      commentId,
      openEditComment,
      closeEditComment,
    } = this.props;
    const { isDeleteCommentOpened } = this.state;

    return (
      <div className={classNames({ [className]: !canAddComment })}>
        <>
          {canAddComment ? (
            <AddComment
              translations={translations.addComment}
              currentUser={currentUser}
              onSendComment={onSendComment}
              closeEditComment={closeEditComment}
            />
          ) : null}
          {comments.map(comment => (
            <CommentItem
              key={`comment_item_${comment.id}`}
              translations={translations}
              comment={comment}
              canEditComment={this.canEditComment(comment)}
              currentUser={currentUser}
              onSendComment={onSendComment}
              isEdit={commentId === comment.id}
              onCommentDelete={this.handleDeleteComment}
              openEditComment={openEditComment}
              closeEditComment={closeEditComment}
            />
          ))}
        </>
        <AlertDialog
          isOpened={isDeleteCommentOpened}
          translations={translations.deleteCommentDialog}
          onClose={this.onCancelDelete}
          onConfirm={this.onDelete}
          isWarning
        />
      </div>
    );
  }
}

UsersComments.defaultProps = {
  className: undefined,
  closeEditComment: () => {},
};

UsersComments.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.shape({}).isRequired,
  currentUser: PropTypes.shape({}).isRequired,
  canAddComment: PropTypes.bool.isRequired,
  comments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSendComment: PropTypes.func.isRequired,
  onDeleteComment: PropTypes.func.isRequired,
  commentId: PropTypes.number.isRequired,
  openEditComment: PropTypes.func.isRequired,
  closeEditComment: PropTypes.func,
};

export default UsersComments;
