import { createAction, handleActions } from 'redux-actions';
import { parseDuplicateParameters } from 'utility/uiUtils';
import http from '../../utility/http';
import { isArray } from '../../utility/helpers';
import { API_POSITIONS } from '../../constants/apiRoutes';
import { JOB_TITLES_DEFAULT_PARAMS } from '../../constants/pages';
// ------------------------------------
// Constants
// ------------------------------------

const SET_JOB_TITLES = 'SET_JOB_TITLES';

const initialState = {
  jobTitles: [],
};

// ------------------------------------
// Actions
// ------------------------------------

export const setJobTitles = createAction(SET_JOB_TITLES);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_JOB_TITLES]: (state, { payload }) => {
    return {
      ...state,
      jobTitles: isArray(payload.results) ? payload.results : [],
    };
  },
};

// ------------------------------------
// API calls
// ------------------------------------

export const getJobTitles = (dispatch, params = {}) => {
  return http
    .get(API_POSITIONS, {
      params: { ...JOB_TITLES_DEFAULT_PARAMS, ...params },
      paramsSerializer: data => parseDuplicateParameters(data),
    })
    .then(({ data }) => {
      dispatch(setJobTitles(data));
    });
};

export default handleActions(reducers, initialState);

// ------------------------------------
// "plain" action functions
// ------------------------------------

export const clearJobTitles = () => setJobTitles(initialState.jobTitles);
