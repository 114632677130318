import { createAction, handleActions } from 'redux-actions';
import http from '../../utility/http';
import { api_user, api_upload_photo } from '../../constants/apiRoutes';

// ------------------------------------
// Constants
// ------------------------------------

const SET_PERSON = 'SET_PERSON';
const SET_PERSON_ROLE_VISIBILITY = 'SET_PERSON_ROLE_VISIBILITY';
const UPDATE_PERSON = 'UPDATE_PERSON';

const initialState = {};

// ------------------------------------
// Actions
// ------------------------------------

const setPerson = createAction(SET_PERSON);
const setPersonRoleVisibility = createAction(SET_PERSON_ROLE_VISIBILITY);
const updatePersonAction = createAction(UPDATE_PERSON);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_PERSON]: (state, { payload }) => {
    return payload;
  },
  [UPDATE_PERSON]: (state, { payload }) => {
    return { ...state, ...payload };
  },
  [SET_PERSON_ROLE_VISIBILITY]: (state, { payload }) => {
    const { role, with_access } = payload;
    return {
      ...state,
      role,
      with_access,
    };
  },
};

// ------------------------------------
// API calls
// ------------------------------------

export const getPerson = (dispatch, id) => {
  return http.get(api_user(id)).then(response => {
    dispatch(setPerson(response.data));

    return response;
  });
};

export const updateEmploymentInfo = (dispatch, id, data) => {
  return http.patch(api_user(id), data).then(response => {
    dispatch(updatePersonAction(response.data));

    return response;
  });
};

export const updatePersonPhoto = (dispatch, id, dataUpload) => {
  return http.put(api_upload_photo(id), dataUpload).then(({ data }) => {
    dispatch(updatePersonAction(data));
  });
};

export const updatePersonRole = (dispatch, id, userData) => {
  return http.patch(api_user(id), userData).then(({ data }) => {
    dispatch(setPersonRoleVisibility(data));
  });
};

export default handleActions(reducers, initialState);

// ------------------------------------
// "plain" action functions
// ------------------------------------

export const clearPerson = () => setPerson(initialState);
