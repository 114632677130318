import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import CustomButton from '../customButton';
import Tooltip from '../tooltip';

const useStyles = makeStyles(
  ({ palette: { primary }, spacing, breakpoints }) => ({
    root: {
      backgroundColor: primary.yellow4,
      borderRadius: 24,
      display: 'flex',
      justifyContent: 'center',
      padding: spacing(8, 5),
      [breakpoints.up('xLg')]: {
        padding: spacing(10, 8),
      },
      [breakpoints.up('xl')]: {
        padding: spacing(11, 9),
      },
    },
    content: {
      maxWidth: 337,
    },
    name: {
      color: primary.black,
      lineHeight: 1.4,
      marginBottom: spacing(2),
    },
    description: {
      color: primary.bluish3,
      fontFamily: 'ProximaNova-Regular',
      lineHeight: '27px',
      marginBottom: spacing(4),
    },
    priceWrapper: {
      display: 'flex',
      alignItems: 'baseline',
      marginBottom: spacing(8),
    },
    price: {
      color: primary.black,
      fontSize: 54,
      lineHeight: 1.2,
      marginRight: spacing(1),
    },
    priceDescription: {
      color: primary.bluish3,
      lineHeight: 1.3,
    },
    subscribeButton: {
      fontFamily: 'ProximaNova-Bold',
      fontSize: 18,
      padding: spacing(3.5, 12),
      maxHeight: 60,
      marginBottom: spacing(11),
    },
    featureTitle: {
      marginBottom: spacing(3),
    },
    featureWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: spacing(3),
      width: '100%',
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
    featureItem: {
      fontFamily: 'ProximaNova-Regular',
    },
  })
);

const PricingPlan = ({
  translations,
  subscribeButtonLabel,
  price,
  isLink,
  isDisabled,
  onSubscribe,
  ...linkProps
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography variant="h3" className={classes.name}>
          {translations.name}
        </Typography>
        <Typography className={classes.description} variant="h5">
          {translations.description}
        </Typography>
        <div className={classes.priceWrapper}>
          <Typography className={classes.price} variant="h1">
            {price}
          </Typography>
          {translations.priceDescription && (
            <Typography className={classes.priceDescription} variant="h3">
              {translations.priceDescription}
            </Typography>
          )}
        </div>
        <CustomButton
          className={classes.subscribeButton}
          disabled={isDisabled}
          type="withTextDarkRounded"
          onClick={onSubscribe}
          {...(isLink ? { ...linkProps } : {})}
        >
          {subscribeButtonLabel}
        </CustomButton>
        {translations.featureTitle && (
          <Typography className={classes.featureTitle} variant="h5">
            {translations.featureTitle}
          </Typography>
        )}
        {translations.features.map((feature, i) => (
          <div key={`feature_item_${i}`} className={classes.featureWrapper}>
            <Typography className={classes.featureItem} variant="h5">
              {feature.text}
            </Typography>
            {feature.tooltip && <Tooltip text={feature.tooltip} />}
          </div>
        ))}
      </div>
    </div>
  );
};

PricingPlan.defaultProps = {
  isDisabled: false,
  onSubscribe: () => {},
};

PricingPlan.propTypes = {
  translations: PropTypes.object.isRequired,
  subscribeButtonLabel: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  onSubscribe: PropTypes.func,
};

export default PricingPlan;
