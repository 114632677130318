import { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import ReorderItems from '../reorderItems';
import AttributeChip from '../attributeChip';
import AttributeChipWithScore from '../attributeChipWithScore';
import CustomScrollBar from '../customScrollBar';
import { reorder, isItemInList } from '../../../utility/helpers';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    width: '100%',
  },
  rootScrollable: {
    maxWidth: 'calc(100% - 12px)',
  },
  horizontalList: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  attribute: {
    minWidth: 0,
    margin: spacing(0, 1, 1, 0),
  },
  draggableItem: {
    display: 'flex',
    minWidth: 0,
  },
  scrollable: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 128,
  },
  scrollY: {
    top: 0,
    right: 0,
    height: '100%',
  },
}));

const AttributesList = ({
  className,
  scrollClass,
  translations,
  attributes,
  selectedAttributes,
  isHorizontalList,
  isActive,
  checkIsSelectable,
  hasScore,
  isDraggable,
  isRemovable,
  isScrollable,
  onSelect,
  onReorder,
  onRemove,
}) => {
  const classes = useStyles();

  const handleReorderAttributes = useCallback(
    result => {
      if (
        !result.destination ||
        result.source.index === result.destination.index
      )
        return;

      const updatedOrder = reorder(
        attributes,
        result.source.index,
        result.destination.index
      );

      return onReorder(updatedOrder);
    },
    [attributes, onReorder]
  );

  return (
    <div className={classNames({ [classes.scrollable]: isScrollable })}>
      <CustomScrollBar
        customScrollBarYClass={classNames(classes.scrollY, scrollClass)}
        verticalScroll={isScrollable}
        removeScrollX
        passContentHeight
        passContentWidth
      >
        <ReorderItems
          className={classNames(
            classes.root,
            {
              [classes.horizontalList]: isHorizontalList,
              [classes.rootScrollable]: isScrollable,
            },
            className
          )}
          itemClass={classes.draggableItem}
          isDragDisabled={!isDraggable || isHorizontalList}
          onSave={handleReorderAttributes}
        >
          {attributes.map(attribute => {
            const isSelectable = checkIsSelectable(attribute);
            const isSelected =
              isSelectable && isItemInList(selectedAttributes, attribute);

            return hasScore ? (
              <AttributeChipWithScore
                key={`value${attribute.id}`}
                id={attribute.id}
                translations={translations}
                attribute={attribute}
                hasRemove={isRemovable}
                isDraggable={isDraggable}
                onRemove={onRemove}
              />
            ) : (
              <AttributeChip
                key={`value${attribute.id}`}
                id={attribute.id}
                customClass={classes.attribute}
                attribute={attribute}
                name={attribute.name}
                color={isSelected || isActive ? attribute?.color : ''}
                hasRemove={isRemovable}
                isAttributeDraggable={isDraggable}
                isSelectable={isSelectable && !isActive}
                isSelected={isActive || isSelected}
                onSelect={onSelect}
                onRemove={onRemove}
              />
            );
          })}
        </ReorderItems>
      </CustomScrollBar>
    </div>
  );
};

AttributesList.defaultProps = {
  translations: {},
  className: undefined,
  scrollClass: null,
  isHorizontalList: false,
  isDraggable: false,
  isRemovable: false,
  hasScore: false,
  isActive: false,
  isScrollable: false,
  selectedAttributes: [],
  checkIsSelectable: () => true,
  onSelect: () => {},
  onReorder: () => {},
  onRemove: () => {},
};

AttributesList.propTypes = {
  className: PropTypes.string,
  scrollClass: PropTypes.string,
  translations: PropTypes.object,
  attributes: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedAttributes: PropTypes.arrayOf(PropTypes.object),
  isHorizontalList: PropTypes.bool,
  hasScore: PropTypes.bool,
  isDraggable: PropTypes.bool,
  isRemovable: PropTypes.bool,
  isActive: PropTypes.bool,
  isScrollable: PropTypes.bool,
  checkIsSelectable: PropTypes.func,
  onSelect: PropTypes.func,
  onReorder: PropTypes.func,
  onRemove: PropTypes.func,
};

export default memo(AttributesList);
