import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import MyPeopleList from '../../shared/myPeopleList';
import { tagManagerDataLayer } from '../../../utility/tagManager';
import http from '../../../utility/http';
import { canSeeEmployeeProfile } from '../../../utility/helpers';
import {
  API_USERS,
  api_user_has_shared_access,
} from '../../../constants/apiRoutes';
import {
  PEOPLE_DEFAULT_ORDERING,
  PEOPLE_MAX_PAGE_SIZE,
} from '../../../constants/people';
import { PARAMS } from '../../../constants/pages';
import { sticky } from '../../../constants/helperCssRules';

const styles = ({ palette: { primary }, spacing }) => ({
  description: {
    paddingBottom: spacing(4),
    ...sticky(primary.white, 169),
  },
  noResultsWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    display: 'grid',
    gridGap: spacing(8),
    gridTemplateColumns: 'repeat(2, minmax(0,1fr))',
  },
});

class PeopleTeamPage extends PureComponent {
  state = {
    isLoading: true,
    directReports: [],
    sharedAccess: [],
  };

  componentDidMount() {
    const { user } = this.props;

    Promise.all([
      http.get(API_USERS, {
        params: {
          ...PEOPLE_MAX_PAGE_SIZE,
          ...PEOPLE_DEFAULT_ORDERING,
          [PARAMS.REPORT_TO]: user.id,
        },
      }),
      http.get(api_user_has_shared_access(user.id)),
    ])
      .then(responses => {
        const [directReportsResponse, sharedAccessResponse] = responses;
        this.setState({
          directReports: this.applyAccessFlag(
            directReportsResponse?.data?.results
          ),
          sharedAccess: this.applyAccessFlag(sharedAccessResponse.data),
        });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });

    tagManagerDataLayer('User Profile Team', 'Open Page', 'upTeamOpenPage');
  }

  handleEmployeeClick = userId => {
    const { history } = this.props;
    history.push(`/people/${userId}/insights`);
  };

  applyAccessFlag = users => {
    const { auth } = this.props;
    return users.map(u => {
      return { ...u, hasAccess: canSeeEmployeeProfile(auth, u.id) };
    });
  };

  render() {
    const { classes, translations, user, auth } = this.props;
    const { isLoading, directReports, sharedAccess } = this.state;
    const personName = user.first_name;

    return (
      !isLoading && (
        <div>
          <Typography variant="body2" className={classes.description}>
            {auth.id === user.id
              ? translations.descriptionOwnProfile
              : translations.description}
          </Typography>
          <div className={classes.content}>
            <MyPeopleList
              translations={translations.myDirectList}
              title={`${personName}'s ${translations.directReports}`}
              users={directReports}
              onEmployeeClick={this.handleEmployeeClick}
            />
            <MyPeopleList
              translations={translations.mySharedList}
              title={`${translations.sharedWith} ${personName}`}
              users={sharedAccess}
              onEmployeeClick={this.handleEmployeeClick}
            />
          </div>
        </div>
      )
    );
  }
}

PeopleTeamPage.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default withStyles(styles)(PeopleTeamPage);
