import { connect } from 'react-redux';
import {
  addNewPreset,
  deleteSelectedPreset,
  editPresetActivity,
} from 'store/modules/organizationSettings';
import PageContainer from '../../components/shared/pageContainer';
import SkillsMatrixPage from '../../components/pages/skillsMatrixPage';
import { surveysSelector } from '../../store/selectors/surveysSelector';
import { useTranslations } from '../../utility/useTranslations';
import { getAllUsers, clearAllUsers } from '../../store/modules/surveys';
import { APP_PAGES } from '../../constants/pages';

const mapStateToProps = state => ({
  allUsers: [...surveysSelector(state).allUsers],
  formattedUsers: { ...surveysSelector(state).formattedUsers },
});

const mapDispatchToProps = dispatch => ({
  getAllUsers: data => getAllUsers(dispatch, data),
  addNewPreset: data => addNewPreset(dispatch, data),
  deleteSelectedPreset: (id, locationId) =>
    deleteSelectedPreset(dispatch, id, locationId),
  editPresetActivity: (id, locationId, isActive) =>
    editPresetActivity(dispatch, id, locationId, isActive),
  clearAllUsers: () => dispatch(clearAllUsers()),
});

const SkillsMatrixPageView = props => {
  const translations = useTranslations(APP_PAGES.SKILLS_MATRIX);

  return (
    <PageContainer {...props} isFullWidthContent translations={translations}>
      <SkillsMatrixPage />
    </PageContainer>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SkillsMatrixPageView);
