import { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Switch, FormControl, FormLabel, makeStyles } from '@material-ui/core';
import ConditionalTooltip from '../conditionalTooltip';
import Tooltip from '../tooltip';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    flexShrink: 0,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 18,
    lineHeight: '20px',
    marginBottom: 0,
  },
  highlightedLabel: {
    fontFamily: 'ProximaNova-Bold',
  },
  tooltipIcon: {
    marginLeft: spacing(1),
    width: 14,
    height: 14,
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
}));

const CustomSwitch = ({
  className,
  labelClass,
  label,
  tooltipInfo,
  tooltipText,
  isHighlightedLabel,
  isChecked,
  isDisabled,
  onChange,
}) => {
  const classes = useStyles();

  const handleChange = useCallback(
    event => {
      onChange(event.target.checked);
    },
    [onChange]
  );

  return (
    <FormControl className={classNames(classNames.root, className)} fullWidth>
      <div className={classes.content}>
        {label && (
          <FormLabel
            classes={{
              root: classNames(
                classes.label,
                {
                  [classes.highlightedLabel]: isHighlightedLabel,
                },
                labelClass
              ),
            }}
          >
            <span>{label}</span>
            {tooltipInfo && (
              <Tooltip
                customIconClass={classes.tooltipIcon}
                text={tooltipInfo}
              />
            )}
          </FormLabel>
        )}
        <ConditionalTooltip message={tooltipText} addTooltip={!!tooltipText}>
          <Switch
            color="default"
            checked={isChecked}
            disabled={isDisabled}
            onChange={handleChange}
          />
        </ConditionalTooltip>
      </div>
    </FormControl>
  );
};

CustomSwitch.defaultProps = {
  className: undefined,
  labelClass: null,
  isHighlightedLabel: false,
  isDisabled: false,
  tooltipInfo: '',
  tooltipText: '',
};

CustomSwitch.propTypes = {
  className: PropTypes.string,
  labelClass: PropTypes.string,
  tooltipText: PropTypes.string,
  isChecked: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  isHighlightedLabel: PropTypes.bool,
  tooltipInfo: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default memo(CustomSwitch);
