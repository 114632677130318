import { memo, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { Paper, alpha, makeStyles } from '@material-ui/core';
import { onScrollIntoView } from '../../../../utility/uiUtils';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    boxSizing: 'border-box',
    border: `1px solid ${primary.bluish6}`,
    boxShadow: 'none',
    borderRadius: 4,
    filter: `drop-shadow(0px 4px 10px ${alpha(primary.black, 0.2)})`,
    position: 'absolute',
    zIndex: 100,
    maxHeight: 252,
    left: 0,
    right: 0,
    padding: spacing(4, 2),
    minWidth: 0,
  },
}));

const Menu = props => {
  const classes = useStyles();
  const paperRef = useRef(null);

  useEffect(() => {
    if (props.selectProps?.menuIsOpen && paperRef?.current) {
      onScrollIntoView(paperRef);
    }
  }, [props.selectProps?.menuIsOpen]);

  return (
    <Paper
      ref={paperRef}
      square
      className={classNames(classes.root, props.selectProps?.menuClass)}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
};

export default memo(Menu);
