import { createAction, handleActions } from 'redux-actions';
import http from '../../utility/http';
import { isObjectEmpty } from '../../utility/helpers';
import { parseDuplicateParameters } from '../../utility/uiUtils';
import { prepareOneOnOneReportData } from '../../utility/oneOnOne';
import { API_ONE_ON_ONE_REPORT } from '../../constants/apiRoutes';
import { DEFAULT_PARAMS } from '../../constants/oneOnOne';

// ------------------------------------
// Constants
// ------------------------------------

const SET_ONE_ON_ONE_REPORT = 'SET_ONE_ON_ONE_REPORT';
const LOAD_MORE_ONE_ON_ONE_REPORT = 'LOAD_MORE_ONE_ON_ONE_REPORT';

const initialState = {
  oneOnOneReport: {},
};

// ------------------------------------
// Actions
// ------------------------------------

const setOneOnOneReport = createAction(SET_ONE_ON_ONE_REPORT);
const loadMoreOneOnOneReport = createAction(LOAD_MORE_ONE_ON_ONE_REPORT);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_ONE_ON_ONE_REPORT]: (state, { payload }) => {
    return {
      ...state,
      oneOnOneReport: {
        ...payload,
        results: prepareOneOnOneReportData(payload.results),
      },
    };
  },
  [LOAD_MORE_ONE_ON_ONE_REPORT]: (state, { payload }) => {
    const results = prepareOneOnOneReportData(payload?.results);

    return isObjectEmpty(payload)
      ? { ...state, oneOnOneReport: payload }
      : {
          ...state,
          oneOnOneReport: {
            ...payload,
            results: state.oneOnOneReport.results
              ? [...state.oneOnOneReport.results, ...results]
              : [...results],
          },
        };
  },
};

// ------------------------------------
// API calls
// ------------------------------------

export const getOneOnOneReport = (dispatch, isLoadMore, params = {}) => {
  return http
    .get(API_ONE_ON_ONE_REPORT, {
      params: {
        ...DEFAULT_PARAMS,
        ...params,
      },
      paramsSerializer: data => parseDuplicateParameters(data),
    })
    .then(({ data }) =>
      dispatch(
        isLoadMore ? loadMoreOneOnOneReport(data) : setOneOnOneReport(data)
      )
    );
};

export default handleActions(reducers, initialState);

// ------------------------------------
// "plain" action functions
// ------------------------------------

export const clearOneOnOneReport = () =>
  setOneOnOneReport(initialState.oneOnOneReport);
