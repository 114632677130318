import { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import SectionTitle from '../sectionTitle';
import CustomButton from '../customButton';
import CustomCheckbox from '../customCheckbox';
import Filter from '../filter';
import Search from '../search';
import SelectField from '../selectField';
import NotificationCard from '../notificationCard';
import Reviewer from './reviewer';
import {
  getItemById,
  isArrayEmpty,
  isItemInList,
  isArraySubset,
} from '../../../utility/helpers';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: spacing(5),
  },
  button: {
    backgroundColor: primary.white,
    border: `1px solid ${primary.bluish1}`,
    color: primary.bluish1,
    borderRadius: 44,
    fontFamily: 'ProximaNova-Bold',
    fontSize: 14,
    lineHeight: '24px',
    padding: spacing(1, 4),
    maxHeight: 32,
  },
  loadMoreButton: {
    display: 'flex',
    alignSelf: 'center',
    marginTop: spacing(3),
    width: 'fit-content',
  },
  action: {
    width: 118,
    minWidth: 118,
  },
  filters: {
    borderBottom: `1px dashed ${primary.bluish7}`,
    display: 'flex',
    alignItems: 'center',
    paddingBottom: spacing(3),
  },
  spacing: {
    marginLeft: spacing(2),
  },
  search: {
    marginLeft: 'auto',
  },
  selectPaper: {
    minWidth: 200,
  },
  reviewers: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const SurveyReviewers = ({
  translations,
  actions,
  reviewers,
  completionStatuses,
  hasLoadMore,
  isExpired,
  isScheduled,
  isDisabled,
  canAddReviewer,
  completionStatus,
  selectedUsers,
  isUserClickable,
  onAddReviewers,
  onGoToPersonProfile,
  onStatusChange,
  onSearch,
  onLoadMore,
  onActionSelect,
  onSelectAll,
  onUserSelect,
}) => {
  const classes = useStyles();
  const hasReviewers = !isArrayEmpty(reviewers);

  return (
    <div>
      <div className={classes.header}>
        <SectionTitle variant="h5" title={translations.title} />
        {canAddReviewer && !isDisabled && (
          <CustomButton
            className={classes.button}
            type="addWithTextRounded"
            onClick={onAddReviewers}
          >
            {isScheduled
              ? translations.addScheduleButton
              : translations.addButton}
          </CustomButton>
        )}
      </div>
      {hasReviewers ? (
        <>
          <div className={classes.filters}>
            <CustomCheckbox
              isChecked={
                !isArrayEmpty(selectedUsers) &&
                !isArrayEmpty(reviewers) &&
                isArraySubset(selectedUsers, reviewers)
              }
              onChange={onSelectAll}
              isControlled
              disabled={isScheduled || isDisabled}
            />
            <SelectField
              className={classNames(classes.action, classes.spacing)}
              menuClass={classes.selectPaper}
              placeholder={translations.action}
              options={actions}
              parser={{ label: 'name', value: 'id' }}
              isDisabled={isScheduled || isDisabled}
              onChange={onActionSelect}
              isSearchDisabled
              shouldRemoveLabel
            />
            <Filter
              className={classes.spacing}
              name={translations.status}
              options={completionStatuses}
              value={completionStatus}
              isDisabled={isScheduled}
              onChange={onStatusChange}
            />
            <Search
              className={classes.search}
              placeholder={translations.search}
              onChange={onSearch}
            />
          </div>
          <div className={classes.reviewers}>
            {reviewers.map(reviewer => (
              <Reviewer
                key={`reviewer_${reviewer.id}`}
                reviewer={reviewer}
                isSelected={isItemInList(selectedUsers, reviewer)}
                isClickable={isUserClickable(reviewer)}
                status={getItemById(
                  completionStatuses,
                  reviewer.completion_status
                )}
                isDisabled={isScheduled || isExpired || isDisabled}
                onGoToPersonProfile={onGoToPersonProfile}
                onChange={onUserSelect(reviewer)}
              />
            ))}
            {hasLoadMore && (
              <CustomButton
                className={classNames(classes.button, classes.loadMoreButton)}
                type="addWithTextRounded"
                onClick={onLoadMore}
              >
                {translations.loadMore}
              </CustomButton>
            )}
          </div>
        </>
      ) : (
        <NotificationCard
          shouldFade={!hasReviewers}
          title={translations.noReviewers}
        />
      )}
    </div>
  );
};

SurveyReviewers.defaultProps = {
  reviewers: [],
};

SurveyReviewers.propTypes = {
  translations: PropTypes.object.isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  reviewers: PropTypes.arrayOf(PropTypes.shape({})),
  completionStatuses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  completionStatus: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  hasLoadMore: PropTypes.bool.isRequired,
  isScheduled: PropTypes.bool.isRequired,
  isExpired: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  canAddReviewer: PropTypes.bool.isRequired,
  selectedUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isUserClickable: PropTypes.func.isRequired,
  onAddReviewers: PropTypes.func.isRequired,
  onActionSelect: PropTypes.func.isRequired,
  onGoToPersonProfile: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  onUserSelect: PropTypes.func.isRequired,
};

export default memo(SurveyReviewers);
