import { authSelector } from './authSelector';

export const grantedPermissionsSelector = state => {
  const { auth } = authSelector(state);
  const grantedPermissions = [];
  if (auth && auth.permissions) {
    Object.keys(auth.permissions).forEach(sectionKey => {
      if (Array.isArray(auth.permissions[sectionKey])) {
        auth.permissions[sectionKey].forEach(permission => {
          if (permission.granted) {
            grantedPermissions.push(permission.key);
          }
        });
      }
    });
  }
  return { grantedPermissions };
};
