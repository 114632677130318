import PropTypes from 'prop-types';
import ModeratorCard from './moderatorCard';
import UserCard from './userCard';

const OneOnOneRequestCard = ({
  className,
  translations,
  request,
  isExpired,
  isMiniCard,
  isUserCard,
  onChangeRequest,
  onDeleteRequest,
  onAcceptRequest,
  onDismissRequest,
}) => {
  return isUserCard ? (
    <UserCard
      className={className}
      translations={translations}
      request={request}
      isExpired={isExpired}
      isMiniCard={isMiniCard}
      onChangeRequest={onChangeRequest}
      onDeleteRequest={onDeleteRequest}
    />
  ) : (
    <ModeratorCard
      className={className}
      translations={translations}
      request={request}
      onAcceptRequest={onAcceptRequest}
      onDismissRequest={onDismissRequest}
    />
  );
};

OneOnOneRequestCard.defaultProps = {
  className: undefined,
  isUserCard: false,
  isExpired: false,
  isMiniCard: false,
  onChangeRequest: () => {},
  onDeleteRequest: () => {},
  onAcceptRequest: () => {},
  onDismissRequest: () => {},
};

OneOnOneRequestCard.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.shape({}).isRequired,
  request: PropTypes.shape({}).isRequired,
  isExpired: PropTypes.bool,
  isUserCard: PropTypes.bool,
  isMiniCard: PropTypes.bool,
  onChangeRequest: PropTypes.func,
  onDeleteRequest: PropTypes.func,
  onAcceptRequest: PropTypes.func,
  onDismissRequest: PropTypes.func,
};

export default OneOnOneRequestCard;
