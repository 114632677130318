import { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TransitionGroup } from 'react-transition-group';
import { Typography, Fade, makeStyles } from '@material-ui/core';
import OneOnOneTimeIndicator from '../oneOnOneTimeIndicator';
import Impression from '../impressions/impression';
import DotsMenu from '../dotsMenu';
import AgendaList from '../agendaList';
import Tag from '../tag';
import OneOnOneCalendar from '../oneOnOneCalendar';
import RecurringIconTooltip from '../recuringIconTooltip';
import TasksList from '../tasksList';
import RequestedIconTooltip from '../requestedIconTooltip';
import UserLink from '../userLink';
import TextLink from '../textLink';
import TextBoxWithTooltip from '../textBoxWithTooltip';
import { getPersonFullName } from '../../../utility/uiUtils';
import { isArrayEmpty } from '../../../utility/helpers';
import { formatDate, isDateInFuture } from '../../../utility/dateUtils';
import {
  getDotsMenuItems,
  getEmployeeAgenda,
  getModeratorAgenda,
} from '../../../utility/oneOnOne';
import {
  DATE_FORMAT,
  RECURRING_TYPES_MAP,
  TIME_FORMAT,
} from '../../../constants/oneOnOne';
import ActionButton from '../actionButton';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    boxSizing: 'border-box',
    backgroundColor: primary.bluish9,
    borderRadius: 4,
    display: 'grid',
    gridTemplateColumns: '1fr 225px',
    minHeight: 150,
  },
  content: {
    borderRight: `1px dashed ${primary.bluish7}`,
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    gridGap: 16,
    padding: spacing(6, 4, 6, 6),
  },
  info: {
    position: 'relative',
    padding: spacing(6, 6, 6, 4),
  },
  label: {
    color: primary.bluish3,
    fontSize: 14,
    fontFamily: 'ProximaNova-Regular',
    marginBottom: spacing(1),
  },
  date: {
    marginBottom: spacing(1),
  },
  impressionWrapper: {
    marginTop: spacing(4),
  },
  impressionLabel: {
    fontFamily: 'ProximaNova-Bold',
    fontSize: 12,
    lineHeight: '14px',
    textTransform: 'uppercase',
    letterSpacing: 1,
    marginBottom: spacing(2),
  },
  impressionValue: {
    cursor: 'default',
  },
  menu: {
    position: 'absolute',
    right: 20,
    top: 24,
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(2),
  },
  actionsWrapper: {
    display: 'flex',
  },
  rescheduleWrapper: {
    position: 'absolute',
    top: 22,
    right: 50,
  },
  fullWidth: {
    gridColumn: 'span 2',
  },
  tagsWrapper: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
  },
  tagsWrapperWithoutTags: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 28,
  },
  tag: {
    margin: spacing(0, 2, 2, 0),
  },
  plansWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  planWrapper: {
    display: 'flex',
    marginBottom: spacing(2),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  calendar: {
    narginBottom: spacing(4),
  },
}));

const OneOnOneRecordDetails = ({
  translations,
  record,
  canManageUser,
  isUserActive,
  isCurrentUserSubject,
  isCreatedByCurrentUser,
  isPendingRequest,
  checkCanAccessUserProfile,
  onAddImpression,
  onReschedule,
  onManageTasks,
  onEditRecord,
  onEditEmployeeTopic,
  onDeleteRecord,
  onTaskStatusChange,
  checkCanManageTask,
}) => {
  const classes = useStyles();
  const {
    user,
    moderator,
    meeting_time,
    meeting_impression,
    has_impression,
    requested,
    tags,
    recurring,
    tasks,
    plans,
  } = record;
  const { RESOLVE, RESCHEDULE } = ACTION_BUTTON_TYPES;
  const isUserAccessible = checkCanAccessUserProfile(user.id);
  const isModeratorAccessible =
    !!moderator && checkCanAccessUserProfile(moderator?.id);
  const hasActions =
    meeting_impression === null && isCreatedByCurrentUser && isUserActive;
  const isFutureOneOnOne = isDateInFuture(meeting_time);
  const isRecurring = recurring !== RECURRING_TYPES_MAP.DOES_NOT_REPEAT.id;
  const hasAddToCalendarButton =
    !isPendingRequest &&
    isFutureOneOnOne &&
    (isCreatedByCurrentUser || isCurrentUserSubject);
  const calendarTarget = getPersonFullName(
    isCreatedByCurrentUser ? user : moderator
  );
  const hasPlans = !isArrayEmpty(plans);
  const canManageUserTopic = isFutureOneOnOne && isCurrentUserSubject;

  const handleTaskStatusChange = useCallback(
    task => {
      return onTaskStatusChange(record, task);
    },
    [record, onTaskStatusChange]
  );

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div>
          <UserLink
            labelClass={classes.label}
            label={translations.with}
            user={user}
            isUserAccessible={isUserAccessible}
          />
        </div>
        <div>
          <UserLink
            labelClass={classes.label}
            label={translations.moderator}
            user={moderator}
            isUserAccessible={isModeratorAccessible}
          />
        </div>
        <div>
          <AgendaList
            titleClass={classes.label}
            title={translations.managerAgenda}
            items={getModeratorAgenda(record)}
          />
        </div>
        <div>
          <AgendaList
            titleClass={classes.label}
            title={translations.employeeAgenda}
            items={getEmployeeAgenda(record)}
          />
        </div>
        <div className={classes.fullWidth}>
          <Typography className={classes.label} variant="body2">
            {translations.tags}
          </Typography>
          <TransitionGroup className={classes.tagsWrapper}>
            {tags.map(tag => (
              <Fade key={`tag_item_${tag.id}`} in>
                <div className={classes.tag}>
                  <Tag tag={tag} color={tag.category.color} isSelected />
                </div>
              </Fade>
            ))}
          </TransitionGroup>
          <Fade in={isArrayEmpty(tags)} unmountOnExit>
            <Typography className={classes.tagsWrapperWithoutTags}>
              -
            </Typography>
          </Fade>
        </div>
        <div className={classes.fullWidth}>
          <Typography className={classes.label} variant="body2">
            {translations.tasks}
          </Typography>
          <TasksList
            translations={translations.tasksList}
            tasks={tasks}
            canAccessUserProfile={checkCanAccessUserProfile}
            checkCanManageTask={checkCanManageTask}
            onStatusChange={handleTaskStatusChange}
          />
          <Fade in={isArrayEmpty(tasks)} unmountOnExit>
            <Typography variant="body2">-</Typography>
          </Fade>
        </div>
        <div className={classes.fullWidth}>
          <Typography
            className={classNames(classes.label, classes.labelSpacing)}
            variant="body2"
          >
            {translations.associatedPlans}
          </Typography>
          <Fade in={!hasPlans} unmountOnExit>
            <Typography variant="body2">-</Typography>
          </Fade>
          <TransitionGroup className={classes.plansWrapper}>
            {plans.map(plan => (
              <Fade
                key={`plan_item_${plan.id}`}
                className={classes.planWrapper}
                in
                unmountOnExit
              >
                <div>
                  {isUserAccessible ? (
                    <TextLink
                      to={`/action-plans/${plan.id}/details`}
                      variant="subtitle1"
                      text={plan.title}
                    />
                  ) : (
                    <TextBoxWithTooltip variant="subtitle1" text={plan.title} />
                  )}
                </div>
              </Fade>
            ))}
          </TransitionGroup>
        </div>
      </div>
      <div className={classes.info}>
        <div className={classes.status}>
          <OneOnOneTimeIndicator
            labels={translations.statuses}
            meetingTime={meeting_time}
            hasImpression={has_impression}
          />
          <RecurringIconTooltip
            isVisible={isRecurring}
            text={translations.recurring}
          />
          {requested && (
            <RequestedIconTooltip
              labels={translations.requested}
              isRequestedByCurrentUser={isCurrentUserSubject}
            />
          )}
          {!isPendingRequest &&
            !isFutureOneOnOne &&
            hasActions &&
            canManageUser && (
              <div
                className={classNames(
                  classes.actionsWrapper,
                  classes.rescheduleWrapper
                )}
              >
                <ActionButton
                  type={RESCHEDULE}
                  tooltipText={
                    translations.actionButtons[RESCHEDULE.toLowerCase()]
                  }
                  onClickHandler={onReschedule}
                  isSquared
                />
              </div>
            )}
        </div>
        <Typography variant="subtitle1" className={classes.date}>
          {formatDate(meeting_time, DATE_FORMAT)}
        </Typography>
        <Typography variant="caption">
          {formatDate(meeting_time, TIME_FORMAT)}
        </Typography>
        {hasAddToCalendarButton ? (
          <OneOnOneCalendar
            className={classes.calendar}
            translations={translations.calendar}
            record={{ ...record, targetFullName: calendarTarget }}
          />
        ) : null}
        {!isPendingRequest && !isFutureOneOnOne && (
          <div className={classes.impressionWrapper}>
            {(hasActions || !!meeting_impression) && (
              <Typography
                variant="overline"
                className={classNames(classes.label, classes.impressionLabel)}
              >
                {translations.impression}
              </Typography>
            )}
            {hasActions && (
              <div className={classes.actionsWrapper}>
                <ActionButton
                  type={RESOLVE}
                  tooltipText={
                    translations.actionButtons[RESOLVE.toLowerCase()]
                  }
                  onClickHandler={onAddImpression}
                  isSquared
                />
              </div>
            )}
            {!!meeting_impression && (
              <Impression
                className={classes.impressionValue}
                value={meeting_impression}
                isSmall
              />
            )}
          </div>
        )}
        {!isPendingRequest &&
          (isCreatedByCurrentUser || isCurrentUserSubject) && (
            <DotsMenu
              className={classes.menu}
              menuItems={getDotsMenuItems(
                translations,
                !isUserActive,
                isCurrentUserSubject,
                canManageUserTopic,
                onEditRecord,
                onDeleteRecord,
                onManageTasks,
                onEditEmployeeTopic
              )}
            />
          )}
      </div>
    </div>
  );
};

OneOnOneRecordDetails.propTypes = {
  translations: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired,
  canManageUser: PropTypes.bool.isRequired,
  isUserActive: PropTypes.bool.isRequired,
  isCurrentUserSubject: PropTypes.bool.isRequired,
  isCreatedByCurrentUser: PropTypes.bool.isRequired,
  isPendingRequest: PropTypes.bool.isRequired,
  checkCanAccessUserProfile: PropTypes.func.isRequired,
  onDeleteRecord: PropTypes.func.isRequired,
  onManageTasks: PropTypes.func.isRequired,
  onAddImpression: PropTypes.func.isRequired,
  onReschedule: PropTypes.func.isRequired,
  checkCanManageTask: PropTypes.func.isRequired,
  onTaskStatusChange: PropTypes.func.isRequired,
  onEditEmployeeTopic: PropTypes.func.isRequired,
};

export default memo(OneOnOneRecordDetails);
