import { memo } from 'react';
import { useLocation } from 'react-router';
import { useCustomEffect, usePreviousValue } from '../../../utility/hooks';

const SCROLL_RESTORATION = 'scrollRestoration';
const MANUAL = 'manual';

const ScrollToTop = ({ children }) => {
  const location = useLocation();
  const previousLocation = usePreviousValue(location.pathname);

  useCustomEffect(() => {
    if (SCROLL_RESTORATION in window.history) {
      window.history.scrollRestoration = MANUAL;
    }
  });

  useCustomEffect(
    () => {
      if (previousLocation && location.pathname !== previousLocation) {
        window.scrollTo(0, 0);
      }
    },
    [location],
    false
  );

  return children;
};

export default memo(ScrollToTop);
