export const DEFAULT_ORDERING = {
  ordering: 'title',
};

export const DEFAULT_SEARCH_VALUE = 'e learning';

export const ALL = {
  id: null,
  key: 'all',
  name: 'All',
};

export const COURSE_LEVELS_LIST = [
  {
    id: 'BEGINNER',
    key: 'beginner',
    name: 'Beginner',
  },
  {
    id: 'INTERMEDIATE',
    key: 'intermediate',
    name: 'Intermediate',
  },
  {
    id: 'EXPERT',
    key: 'expert',
    name: 'Expert',
  },
];

export const COURSE_SOURCES = {
  UDEMY: 'UDEMY',
  CUSTOM: 'CUSTOM',
};
