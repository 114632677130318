import { createAction, handleActions } from 'redux-actions';
import http from '../../utility/http';
import { isArrayEmpty } from '../../utility/helpers';
import { parseDuplicateParameters } from '../../utility/uiUtils';
import {
  api_user_one_on_one,
  API_ONE_ON_ONE_MODERATOR_REQUEST,
} from '../../constants/apiRoutes';
import { ONE_ON_ONE_DEFAULT_PARAMS } from '../../constants/pages';

// ------------------------------------
// Constants
// ------------------------------------

const SET_PERSON_ONE_ON_ONE_RECORDS = 'SET_PERSON_ONE_ON_ONE_RECORDS';
const APPEND_PERSON_ONE_ON_ONE_RECORDS = 'APPEND_PERSON_ONE_ON_ONE_RECORDS';
const DELETE_PERSON_ONE_ON_ONE_RECORD = 'DELETE_PERSON_ONE_ON_ONE_RECORD';
const SET_PERSON_ONE_ON_ONE_REQUEST = 'SET_PERSON_ONE_ON_ONE_REQUEST';

const initialState = {
  personOneOnOne: {
    count: 0,
    results: [],
    previous: null,
    next: null,
  },
  personOneOnOneRequest: null,
};

// ------------------------------------
// Actions
// ------------------------------------

const setPersonOneOnOneRecords = createAction(SET_PERSON_ONE_ON_ONE_RECORDS);
const appendPersonOneOnOneRecords = createAction(
  APPEND_PERSON_ONE_ON_ONE_RECORDS
);
const setPersonOneOnOneRequest = createAction(SET_PERSON_ONE_ON_ONE_REQUEST);
export const deletePersonOneOnOneRecord = createAction(
  DELETE_PERSON_ONE_ON_ONE_RECORD
);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_PERSON_ONE_ON_ONE_RECORDS]: (state, { payload }) => {
    return { ...state, personOneOnOne: payload };
  },
  [APPEND_PERSON_ONE_ON_ONE_RECORDS]: (state, { payload }) => {
    return {
      ...state,
      personOneOnOne: {
        ...payload,
        results: [...state.personOneOnOne.results, ...payload.results],
      },
    };
  },
  [DELETE_PERSON_ONE_ON_ONE_RECORD]: (state, { payload }) => {
    return {
      ...state,
      personOneOnOne: {
        ...state.personOneOnOne,
        count:
          state.personOneOnOne?.count > 0 ? state.personOneOnOne.count - 1 : 0,
        results: state.personOneOnOne.results.filter(rec => rec.id !== payload),
      },
    };
  },
  [SET_PERSON_ONE_ON_ONE_REQUEST]: (state, { payload }) => {
    return {
      ...state,
      personOneOnOneRequest:
        payload?.results && !isArrayEmpty(payload?.results)
          ? payload?.results?.[0]
          : null,
    };
  },
};

// ------------------------------------
// API calls
// ------------------------------------

export const getPersonOneOnOneRecords = (
  dispatch,
  isLoadMore,
  userId,
  params
) => {
  return http
    .get(api_user_one_on_one(userId), {
      params: {
        ...ONE_ON_ONE_DEFAULT_PARAMS,
        ...params,
      },
      paramsSerializer: data => parseDuplicateParameters(data),
    })
    .then(({ data }) => {
      return dispatch(
        isLoadMore
          ? appendPersonOneOnOneRecords(data)
          : setPersonOneOnOneRecords(data)
      );
    });
};

export const getPersonOneOnOneRequest = (dispatch, userId) => {
  return http
    .get(API_ONE_ON_ONE_MODERATOR_REQUEST, {
      params: {
        user: userId,
      },
    })
    .then(({ data }) => dispatch(setPersonOneOnOneRequest(data)));
};

export default handleActions(reducers, initialState);

// ------------------------------------
// "plain" action functions
// ------------------------------------

export const clearPersonOneOnOneRecords = () =>
  setPersonOneOnOneRecords(initialState.personOneOnOne);
export const clearPersonOneOnOneRequest = () =>
  setPersonOneOnOneRequest(initialState.personOneOnOneRequest);
