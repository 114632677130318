import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import { RECURRING_TYPES } from 'constants/oneOnOne';
import CustomButton from '../../shared/customButton';
import OneOnOneRecords from '../../shared/oneOnOneRecords';
import AlertDialog from '../../shared/alertDialog';
import NotificationCard from '../../shared/notificationCard';
import Filters from '../../shared/filters';
import OneOnOneRequestCard from '../../shared/oneOnOneRequestCard';
import ManageOneOnOne from '../../shared/manageOneOnOne';
import ManageTasks from '../../shared/manageTasks';
import AddImpressionDialog from '../../shared/addImpressionDialog';
import http from '../../../utility/http';
import {
  isArray,
  isObjectEmpty,
  canManagePerson,
  checkUserRole,
  getObjectToNumberArray,
  isArrayEmpty,
  isUserSuspended,
} from '../../../utility/helpers';
import { prepareTasksForSave } from '../../../utility/tasks';
import {
  hasNextPage,
  parseDuplicateParameters,
  parseQueryParams,
  showSuccessMessage,
} from '../../../utility/uiUtils';
import { validateFreemiumAction } from '../../../utility/subscriptionHelper';
import {
  getManageOneOnOneSuccessMessages,
  getOneOnOneTasksAvailableUsers,
  prepareOneOnOneRecordsData,
} from '../../../utility/oneOnOne';
import {
  EVENT_ACTION_TYPES,
  tagManagerDataLayer,
} from '../../../utility/tagManager';
import { onSaveCreatableTag } from '../../../utility/tagUtils';
import {
  API_ONE_ON_ONE_TOPICS,
  api_one_on_one_record,
  api_one_on_one_topic,
  api_user_one_on_one,
} from '../../../constants/apiRoutes';
import { GENERAL_CATEGORY_ID } from '../../../constants/tags';
import { ROLES } from '../../../constants/rolesAndPermissionList';
import { sticky } from '../../../constants/helperCssRules';
import {
  PARAMS,
  ONE_ON_ONE_DEFAULT_PARAMS,
  PAGE_WHITELISTED_PARAMS,
} from '../../../constants/pages';
import { FREEMIUM_LIMIT_TYPES } from '../../../constants/appConfig';
import { hasSelectedFilters } from '../../shared/filters/config';
import { getPageFilters } from './config';
import { AUTOMATION_ID } from '../../../constants/automationId';

const styles = ({ palette: { primary }, spacing }) => ({
  header: {
    ...sticky(primary.white, 169),
  },
  description: {
    marginBottom: spacing(4),
  },
  block: {
    display: 'flex',
    alignItems: 'center',
  },
  addButton: {
    minWidth: 115,
    marginLeft: spacing(2),
    width: 'max-content',
  },
  loadMoreWrapper: {
    paddingTop: spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadMoreButton: {
    border: `1px solid ${primary.bluish3}`,
    borderRadius: 44,
    color: primary.bluish4,
    lineHeight: '24px',
    justifySelf: 'center',
    padding: spacing(1, 4),
    maxHeight: 32,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: spacing(4),
  },
  requestCard: {
    marginBottom: spacing(4),
  },
  editorLabelWrapper: {
    justifyContent: 'space-between',
  },
  editorLabel: {
    fontFamily: 'ProximaNova-Bold',
    fontSize: 20,
    lineHeight: '24px',
  },
});

const { PAGE, PAGE_SIZE, COMPLETION_STATUS, TAGS } = PARAMS;

class PeopleOneOnOnePage extends PureComponent {
  state = {
    isInitialLoad: true,
    isLoading: false,
    [PAGE]: 1,
    currentRecord: null,
    isOneOnOneDialogOpened: false,
    isReschedule: false,
    newRecurring: false,
    dismissRequestOpened: false,
    isOneOnOneRequest: false,
    isAddImpressionOpened: false,
    isManageTasksOpened: false,
    filters: {
      [COMPLETION_STATUS]: [],
      [TAGS]: [],
    },
  };

  componentDidMount() {
    this.getInitialData();
  }

  componentWillUnmount() {
    const {
      clearPersonOneOnOneRecords,
      clearOneOnOneTopics,
      clearPersonOneOnOneRequest,
      clearTagsCategories,
      clearPageQuickFilters,
    } = this.props;

    clearPersonOneOnOneRecords();
    clearOneOnOneTopics();
    clearPersonOneOnOneRequest();
    clearTagsCategories();
    clearPageQuickFilters();
  }

  getInitialData = () => {
    const {
      location,
      auth,
      user,
      getPageQuickFilters,
      getTagsCategories,
      getOneOnOneTopics,
      getPersonOneOnOneRequest,
    } = this.props;
    const { search, state } = location;
    const params = parseQueryParams(
      search,
      PAGE_WHITELISTED_PARAMS.PEOPLE_ONE_ON_ONE
    );

    if (!isObjectEmpty(params)) {
      return this.setState(
        {
          filters: {
            [COMPLETION_STATUS]: params[COMPLETION_STATUS]
              ? [params[COMPLETION_STATUS]]
              : [],
            [TAGS]: params[TAGS]
              ? [...(isArray(params[TAGS]) ? params[TAGS] : [params[TAGS]])]
              : [],
          },
        },
        () => {
          return Promise.all([
            this.handlePageChange(
              false,
              ONE_ON_ONE_DEFAULT_PARAMS[PAGE_SIZE] * params[PAGE]
            ),
            ...(auth.id === user.report_to
              ? [getPersonOneOnOneRequest(user.id)]
              : []),
            getOneOnOneTopics(),
            getTagsCategories(),
            getPageQuickFilters(),
          ]).then(() => {
            this.setState({ isInitialLoad: false });
          });
        }
      );
    }

    return Promise.all([
      this.handlePageChange(),
      ...(auth.id === user.report_to
        ? [getPersonOneOnOneRequest(user.id)]
        : []),
      getOneOnOneTopics(),
      getTagsCategories(),
      getPageQuickFilters(),
    ]).then(() => {
      this.setState({ isInitialLoad: false }, () => {
        if (state?.shouldOpenDialog) {
          this.handleOpenOneOnOneDialog();
        }
      });
    });
  };

  handlePageChange = (isLoadMore = false, pageSize = 0) => {
    const { history, location, user, getPersonOneOnOneRecords } = this.props;
    const { page, filters } = this.state;
    const params = {
      page,
      ...filters,
    };

    const query = parseDuplicateParameters(params);

    if (location.search !== `?${query}`) {
      history.replace(`/people/${user.id}/one-on-one/?${query}`);
    }

    return getPersonOneOnOneRecords(user.id, isLoadMore, {
      ...(pageSize ? { ...params, [PAGE_SIZE]: pageSize } : params),
    });
  };

  handleOpenOneOnOneDialog = () => {
    this.setState({ isOneOnOneDialogOpened: true });
  };

  onCloseDrawer = () => {
    const { newRecurring } = this.state;

    this.setState(
      {
        isOneOnOneDialogOpened: false,
        currentRecord: null,
        isReschedule: false,
        newRecurring: false,
        isOneOnOneRequest: false,
        ...(newRecurring ? { [PAGE]: ONE_ON_ONE_DEFAULT_PARAMS[PAGE] } : {}),
      },
      newRecurring ? this.handlePageChange : () => {}
    );
  };

  onSuccessAction = () => {
    const { clearPersonOneOnOneRequest } = this.props;
    const { isOneOnOneRequest } = this.state;

    if (isOneOnOneRequest) {
      clearPersonOneOnOneRequest();
    }

    this.onCloseDrawer();

    return Promise.resolve();
  };

  handleSaveRecord = async values => {
    const { translations, deletePersonOneOnOneRecord } = this.props;
    const { currentRecord, isReschedule, isOneOnOneRequest, newRecurring } =
      this.state;
    const { user, hasCustomTopic, customTopic, ...rest } = values;
    const payload = { ...rest };

    if (isReschedule && currentRecord && !newRecurring) {
      await http.delete(api_one_on_one_record(currentRecord?.id));
      deletePersonOneOnOneRecord(currentRecord?.id);
    }

    if (hasCustomTopic) {
      const { data } = customTopic?.id
        ? await http.patch(api_one_on_one_topic(customTopic?.id), {
            name: customTopic?.name,
          })
        : await http.post(API_ONE_ON_ONE_TOPICS, {
            name: customTopic?.name,
          });

      if (data?.id) {
        payload.agenda = [...payload.agenda, data.id];
      }
    }

    if (currentRecord && !isReschedule && !newRecurring) {
      await http.patch(api_one_on_one_record(currentRecord?.id), {
        ...payload,
        user: user.id,
      });
    } else {
      await http.post(api_user_one_on_one(user.id), {
        ...payload,
      });
      const { AddOneOnOne } = EVENT_ACTION_TYPES;
      tagManagerDataLayer(AddOneOnOne.action, AddOneOnOne.name);
    }

    return this.onSuccessAction().then(() => {
      showSuccessMessage(
        getManageOneOnOneSuccessMessages(
          translations.successMessages,
          currentRecord?.id,
          isOneOnOneRequest,
          isReschedule
        )
      );
      return this.setState(
        { [PAGE]: ONE_ON_ONE_DEFAULT_PARAMS[PAGE] },
        this.handlePageChange
      );
    });
  };

  handleAcceptOneOnOneRequest = () => {
    const { personOneOnOneRequest } = this.props;

    return this.setState(
      {
        currentRecord: personOneOnOneRequest,
        isOneOnOneRequest: true,
      },
      () => this.handleOpenOneOnOneDialog()
    );
  };

  handleEditRecord = record => () => {
    this.setState({ currentRecord: record }, () =>
      this.handleOpenOneOnOneDialog()
    );
  };

  handleReschedule = record => () => {
    this.setState({ currentRecord: record, isReschedule: true }, () =>
      this.handleOpenOneOnOneDialog()
    );
  };

  toggleAddImpression =
    (record = null, updatedState = {}, cb = () => {}) =>
    () => {
      this.setState(
        prevState => ({
          isAddImpressionOpened: !prevState.isAddImpressionOpened,
          currentRecord: record,
          ...updatedState,
        }),
        cb
      );
    };

  onSaveImpression = async data => {
    const {
      translations,
      user,
      organizationSettings,
      setDialogVisibility,
      getOrganizationSettings,
    } = this.props;
    const { currentRecord } = this.state;
    const { impression, nextRecurring, stoppedRecurring, notify } = data;
    const { recurring, tags, agenda, shared_note, note, tasks, plans } =
      currentRecord;
    const noteText = note ? note.text : '';
    const { AddOneOnOne } = EVENT_ACTION_TYPES;

    return http
      .patch(api_one_on_one_record(currentRecord?.id), {
        meeting_impression: impression,
        recurring: stoppedRecurring ? RECURRING_TYPES[0].id : recurring,
        tasks: prepareTasksForSave(tasks),
        user: user.id,
      })
      .then(async () => {
        showSuccessMessage(translations.addImpressionDialog.success);
        if (!stoppedRecurring) {
          const params = {
            meeting_time: nextRecurring,
            recurring: stoppedRecurring ? RECURRING_TYPES[0].id : recurring,
            tags: getObjectToNumberArray(tags),
            plans: getObjectToNumberArray(plans),
            agenda: getObjectToNumberArray(agenda),
            shared_note: shared_note || '',
            note: noteText,
            user: user.id,
            notify,
          };
          const isValid = await validateFreemiumAction(
            async () => {
              try {
                await http.post(api_user_one_on_one(user.id), {
                  ...params,
                });
                showSuccessMessage(translations.successMessages.general.create);
                tagManagerDataLayer(AddOneOnOne.action, AddOneOnOne.name);
              } finally {
                this.toggleAddImpression(
                  null,
                  { [PAGE]: ONE_ON_ONE_DEFAULT_PARAMS[PAGE] },
                  this.handlePageChange
                )();
              }
            },
            FREEMIUM_LIMIT_TYPES.ONE_ON_ONE,
            organizationSettings,
            setDialogVisibility,
            getOrganizationSettings
          )();

          if (!isValid) {
            this.toggleAddImpression(
              null,
              { [PAGE]: ONE_ON_ONE_DEFAULT_PARAMS[PAGE] },
              this.handlePageChange
            )();
          }
        } else {
          this.toggleAddImpression(
            null,
            { [PAGE]: ONE_ON_ONE_DEFAULT_PARAMS[PAGE] },
            this.handlePageChange
          )();
        }
      }, this.toggleAddImpression());
  };

  onSaveAndCreate = async (impression, tasks) => {
    const {
      translations,
      organizationSettings,
      setDialogVisibility,
      getOrganizationSettings,
    } = this.props;
    const { currentRecord } = this.state;

    await http
      .patch(api_one_on_one_record(currentRecord.id), {
        meeting_impression: impression,
        tasks: prepareTasksForSave(tasks),
        user: currentRecord.user.id,
      })
      .then(async () => {
        showSuccessMessage(translations.addImpressionDialog.success);
        const isValid = await validateFreemiumAction(
          () =>
            this.setState({
              isAddImpressionOpened: false,
              isOneOnOneDialogOpened: true,
              newRecurring: true,
            }),
          FREEMIUM_LIMIT_TYPES.ONE_ON_ONE,
          organizationSettings,
          setDialogVisibility,
          getOrganizationSettings
        )();

        if (!isValid) {
          this.setState(
            {
              [PAGE]: ONE_ON_ONE_DEFAULT_PARAMS[PAGE],
              isAddImpressionOpened: false,
            },
            this.handlePageChange
          );
        }
      }, this.toggleAddImpression());
  };

  handleDeleteRecord = record => () => {
    const { setDialogVisibility } = this.props;

    this.setState({ currentRecord: record }, () => {
      setDialogVisibility({
        dialogName: 'deleteOneOnOneRecord',
        opened: true,
      });
    });
  };

  onDeleteRecord = () => {
    const { translations, deletePersonOneOnOneRecord } = this.props;
    const { currentRecord } = this.state;

    return http.delete(api_one_on_one_record(currentRecord?.id)).then(
      () => {
        deletePersonOneOnOneRecord(currentRecord?.id);
        this.onCancelDeleteRecord();
        this.onSuccessAction();
        showSuccessMessage(
          translations.deleteOneOnOneRecordDialog.deleteSuccess
        );

        this.setState(
          { [PAGE]: ONE_ON_ONE_DEFAULT_PARAMS[PAGE] },
          this.handlePageChange
        );
      },
      () => this.onCancelDeleteRecord()
    );
  };

  onCancelDeleteRecord = () => {
    const { setDialogVisibility } = this.props;
    setDialogVisibility({
      dialogName: 'deleteOneOnOneRecord',
      opened: false,
    });
  };

  toggleDismissOneOnOneRequest = (record = null) =>
    this.setState(prevState => ({
      dismissRequestOpened: !prevState.dismissRequestOpened,
      currentRecord: record,
    }));

  onDismissOneOnOneRequest = () => {
    const { translations, clearPersonOneOnOneRequest } = this.props;
    const { currentRecord } = this.state;

    return http.delete(api_one_on_one_record(currentRecord?.id)).then(() => {
      clearPersonOneOnOneRequest();
      this.toggleDismissOneOnOneRequest();
      showSuccessMessage(
        translations.dismissOneOnOneRequestDialog.dismissSuccess
      );
    });
  };

  onLoadMore = () => {
    return this.setState(
      prevState => ({ ...prevState, [PAGE]: prevState[PAGE] + 1 }),
      () => this.handlePageChange(true)
    );
  };

  handleApplyFilters = filters => {
    this.setState(
      { [PAGE]: ONE_ON_ONE_DEFAULT_PARAMS[PAGE], filters, isLoading: true },
      () =>
        this.handlePageChange().then(() => this.setState({ isLoading: false }))
    );
  };

  onCreateTag = async tagName => {
    const { addMultipleTags } = this.props;
    const tags = await onSaveCreatableTag(tagName);
    const [tag] = tags.create;

    addMultipleTags({ categoryId: GENERAL_CATEGORY_ID, tags });

    return tag;
  };

  handleGoToDetailsPage = ({ id }) => {
    const { history } = this.props;

    history.push(`/1-1/${id}/details`);
  };

  handleOpenManageTasks = record => () => {
    this.setState({ currentRecord: record, isManageTasksOpened: true });
  };

  handleCloseManageTasks = () => {
    this.setState({ currentRecord: null, isManageTasksOpened: false });
  };

  onSaveTasks = async values => {
    const { translations } = this.props;
    const { currentRecord } = this.state;

    await http.patch(api_one_on_one_record(currentRecord.id), {
      tasks: prepareTasksForSave(values.tasks),
      user: currentRecord.user.id,
    });
    showSuccessMessage(translations.manageTasksDialog.successMessage);

    return this.setState(
      { [PAGE]: ONE_ON_ONE_DEFAULT_PARAMS[PAGE] },
      this.handlePageChange
    );
  };

  render() {
    const {
      classes,
      translations,
      auth,
      dialogs,
      organizationSettings,
      personOneOnOne,
      topics,
      user,
      categories,
      pageQuickFilters,
      personOneOnOneRequest,
      setDialogVisibility,
      getOrganizationSettings,
    } = this.props;
    const {
      currentRecord,
      isInitialLoad,
      isLoading,
      isReschedule,
      newRecurring,
      dismissRequestOpened,
      isOneOnOneRequest,
      isOneOnOneDialogOpened,
      isAddImpressionOpened,
      filters,
      isManageTasksOpened,
    } = this.state;
    const {
      description,
      addButtonLabel,
      deleteOneOnOneRecordDialog,
      dismissOneOnOneRequestDialog,
      noRecords,
      requestCard,
    } = translations;
    const { deleteOneOnOneRecordOpened } = dialogs;
    const isUser = checkUserRole(auth.role, ROLES.USER);
    const isUserDeactivated = isUserSuspended(user);
    const canAddOneOnOne = canManagePerson(auth, user.id);
    const hasLoadMore = hasNextPage(personOneOnOne);
    const isFilterActive = hasSelectedFilters(filters);

    return (
      <div>
        {!isInitialLoad && (
          <div className={classes.header}>
            <Typography variant="body2" className={classes.description}>
              {description}
            </Typography>
            <div className={classes.actions}>
              <Filters
                className={classes.filters}
                translations={translations.filters}
                selectedFilters={filters}
                filters={getPageFilters(
                  translations,
                  categories,
                  pageQuickFilters
                )}
                onApplyFilters={this.handleApplyFilters}
              />
              {canAddOneOnOne ? (
                <div className={classes.block}>
                  <CustomButton
                    className={classes.addButton}
                    disabled={isUserDeactivated}
                    onClick={validateFreemiumAction(
                      this.handleOpenOneOnOneDialog,
                      FREEMIUM_LIMIT_TYPES.ONE_ON_ONE,
                      organizationSettings,
                      setDialogVisibility,
                      getOrganizationSettings
                    )}
                    type="addRoundedNew"
                  >
                    {addButtonLabel}
                  </CustomButton>
                </div>
              ) : null}
            </div>
          </div>
        )}
        <div>
          {!isUserDeactivated && personOneOnOneRequest && (
            <OneOnOneRequestCard
              className={classes.requestCard}
              translations={requestCard}
              request={personOneOnOneRequest}
              onAcceptRequest={this.handleAcceptOneOnOneRequest}
              onDismissRequest={this.toggleDismissOneOnOneRequest}
            />
          )}
          <OneOnOneRecords
            translations={translations.oneOnOneRecords}
            records={prepareOneOnOneRecordsData(
              personOneOnOne.results,
              auth,
              user
            )}
            currentUser={auth}
            isDisabled={isUserDeactivated}
            canManageUser={canAddOneOnOne}
            onCardClickHandler={this.handleGoToDetailsPage}
            onDeleteRecord={this.handleDeleteRecord}
            onEditRecord={this.handleEditRecord}
            onRescheduleRecord={this.handleReschedule}
            onAddImpression={this.toggleAddImpression}
            onManageTasks={this.handleOpenManageTasks}
          />
          {personOneOnOne?.results &&
            !isArrayEmpty(personOneOnOne.results) &&
            hasLoadMore && (
              <div className={classes.loadMoreWrapper}>
                <CustomButton
                  id={AUTOMATION_ID.LOAD_MORE_BUTTON}
                  type="addWithTextRounded"
                  className={classes.loadMoreButton}
                  onClick={this.onLoadMore}
                >
                  {translations.loadMore}
                </CustomButton>
              </div>
            )}
          <NotificationCard
            shouldFade={
              !isInitialLoad &&
              !isLoading &&
              personOneOnOne?.results &&
              isArrayEmpty(personOneOnOne.results)
            }
            title={isFilterActive ? '' : noRecords.title}
            content={
              isFilterActive
                ? noRecords.noSearchResultsMessage
                : !isUser && noRecords.message
            }
          />
        </div>
        <AlertDialog
          translations={deleteOneOnOneRecordDialog}
          isOpened={deleteOneOnOneRecordOpened}
          onClose={this.onCancelDeleteRecord}
          onConfirm={this.onDeleteRecord}
          isWarning
        />
        <AlertDialog
          translations={dismissOneOnOneRequestDialog}
          isOpened={dismissRequestOpened}
          onClose={this.toggleDismissOneOnOneRequest}
          onConfirm={this.onDismissOneOnOneRequest}
          isWarning
        />
        <ManageOneOnOne
          translations={translations.oneOnOneRecordDialog}
          isOpened={isOneOnOneDialogOpened}
          topics={topics}
          record={currentRecord}
          categories={categories}
          createdFor={user}
          currentUser={auth}
          isOneOnOneRequest={isOneOnOneRequest}
          isReschedule={isReschedule}
          newRecurring={newRecurring}
          onCreateTag={this.onCreateTag}
          onDelete={this.handleDeleteRecord(currentRecord)}
          onClose={this.onCloseDrawer}
          onSave={this.handleSaveRecord}
        />
        {currentRecord && (
          <ManageTasks
            translations={translations.manageTasksDialog}
            isOpened={isManageTasksOpened}
            record={currentRecord}
            availableUsers={getOneOnOneTasksAvailableUsers(currentRecord)}
            onClose={this.handleCloseManageTasks}
            onSave={this.onSaveTasks}
          />
        )}
        <AddImpressionDialog
          translations={translations.addImpressionDialog}
          isOpened={isAddImpressionOpened}
          recordId={currentRecord?.id}
          currentRecord={isAddImpressionOpened ? currentRecord : null}
          onCancel={this.toggleAddImpression()}
          onConfirm={this.onSaveImpression}
          onSaveAndCreate={this.onSaveAndCreate}
        />
      </div>
    );
  }
}

PeopleOneOnOnePage.defaultProps = {
  personOneOnOneRequest: null,
};

PeopleOneOnOnePage.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  organizationSettings: PropTypes.object.isRequired,
  dialogs: PropTypes.shape({}).isRequired,
  auth: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({}).isRequired,
  personOneOnOne: PropTypes.shape({}).isRequired,
  personOneOnOneRequest: PropTypes.shape({}),
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pageQuickFilters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  topics: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getPersonOneOnOneRecords: PropTypes.func.isRequired,
  getOneOnOneTopics: PropTypes.func.isRequired,
  setDialogVisibility: PropTypes.func.isRequired,
  getPersonOneOnOneRequest: PropTypes.func.isRequired,
  addMultipleTags: PropTypes.func.isRequired,
  clearOneOnOneTopics: PropTypes.func.isRequired,
  clearPersonOneOnOneRequest: PropTypes.func.isRequired,
  clearPersonOneOnOneRecords: PropTypes.func.isRequired,
  deletePersonOneOnOneRecord: PropTypes.func.isRequired,
  getTagsCategories: PropTypes.func.isRequired,
  clearTagsCategories: PropTypes.func.isRequired,
  getPageQuickFilters: PropTypes.func.isRequired,
  getOrganizationSettings: PropTypes.func.isRequired,
};

export default withStyles(styles)(PeopleOneOnOnePage);
