import ExpiredStorage from 'expired-storage';

const DEFAULT_EXPIRATION_TIME = 60 * 60 * 24 * 7;

class StorageService {
  constructor() {
    this.storage = new ExpiredStorage();
  }

  get(key) {
    return this.storage.getItem(key);
  }

  set(key, value, expire = DEFAULT_EXPIRATION_TIME) {
    return this.storage.setItem(key, value, expire);
  }

  remove(key) {
    this.storage.removeItem(key);
  }

  clear() {
    this.storage.clear();
  }

  has(key) {
    const value = this.storage.getItem(key);
    return typeof value !== 'undefined' && value !== null;
  }
}

export default new StorageService();
