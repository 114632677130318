import { checkUserRole, isItemInList } from '../../../utility/helpers';
import { isEmployeeInReporters } from '../../../utility/uiUtils';
import {
  isDateInPast,
  addNumberOfDays,
  getStartOfDay,
  isDateInFuture,
} from '../../../utility/dateUtils';
import {
  EMPLOYEE_ROLES_LIST,
  ROLES,
} from '../../../constants/rolesAndPermissionList';
import { PEOPLE_PARAM_SCOPE_VALUES } from '../../../constants/people';
import {
  SURVEY_COMPLETION_STATUS_TYPES,
  SURVEY_COMPLETION_STATUSES,
  SURVEY_STATUS_TYPES,
} from '../../../constants/statuses';
import { SURVEY_TYPES } from '../../../constants/survey';

export const DEFAULT_PARAMS = {
  page: 1,
  page_size: 5,
};

export const STATUS = 'completion_status';

export const REVIEWER_ACTION_TYPES = {
  SEND_REMINDER: 'remind',
  REOPEN: 'open',
  CLOSE_SURVEY: 'close',
  APPEND: 'append',
};

const REVIEWERS_ACTIONS = [
  {
    id: REVIEWER_ACTION_TYPES.SEND_REMINDER,
    name: 'Send reminder',
    key: 'sendReminder',
    keyAll: 'sendReminderAll',
  },
  {
    id: REVIEWER_ACTION_TYPES.REOPEN,
    name: 'Reopen',
    key: 'reopen',
    keyAll: 'reopenAll',
  },
  {
    id: REVIEWER_ACTION_TYPES.CLOSE_SURVEY,
    name: 'Close survey',
    key: 'closeSurvey',
  },
];

export const hasAddReviewerButton = survey =>
  survey.type !== SURVEY_TYPES.DIRECT && survey.type !== SURVEY_TYPES.TEAM;

export const getSurveyUserCompletionStatuses = (labels, hasAll = false) => {
  const statuses = SURVEY_COMPLETION_STATUSES.map(status => ({
    ...status,
    name: labels[status.key],
  }));

  if (hasAll) {
    return [{ id: 0, name: labels.all }, ...statuses];
  }

  return statuses;
};

export const getReviewersActions = (
  labels,
  currentUser,
  survey,
  hasSelectedUsers
) => {
  const isAdmin = checkUserRole(currentUser.role, ROLES.ADMIN);

  return REVIEWERS_ACTIONS.reduce((acc, action) => {
    if (isAdmin || survey.creator.id === currentUser.id) {
      if (
        action.id === REVIEWER_ACTION_TYPES.SEND_REMINDER &&
        survey.status === SURVEY_STATUS_TYPES.ACTIVE
      ) {
        return [
          ...acc,
          {
            ...action,
            name: labels[hasSelectedUsers ? action.key : action.keyAll],
          },
        ];
      }
      if (action.id === REVIEWER_ACTION_TYPES.REOPEN) {
        if (
          survey.status === SURVEY_STATUS_TYPES.COMPLETED &&
          !hasSelectedUsers
        ) {
          return [
            ...acc,
            {
              ...action,
              name: labels[action.keyAll],
            },
          ];
        }

        if (hasSelectedUsers) {
          return [
            ...acc,
            {
              ...action,
              name: labels[action.key],
            },
          ];
        }
      }
      if (
        action.id === REVIEWER_ACTION_TYPES.CLOSE_SURVEY &&
        survey.status !== SURVEY_STATUS_TYPES.SCHEDULED &&
        survey.status !== SURVEY_STATUS_TYPES.COMPLETED &&
        isDateInPast(addNumberOfDays(getStartOfDay(survey.sentAt), 2)) &&
        isDateInFuture(survey.expireAt)
      ) {
        return [
          ...acc,
          {
            ...action,
            name: labels[action.keyAll || action.key],
          },
        ];
      }

      return acc;
    }

    return acc;
  }, []);
};

export const prepareUsersToNotify = (users, surveyStatus, isReopen = false) => {
  const usersToNotify = [];
  const usersWithoutNotification = [];
  const isForceCompleted =
    surveyStatus === SURVEY_STATUS_TYPES.COMPLETED &&
    users.some(
      user =>
        user.completion_status !== SURVEY_COMPLETION_STATUS_TYPES.COMPLETED
    );

  if (isReopen && isForceCompleted) {
    return {
      usersToNotify: users,
      usersWithoutNotification,
    };
  }

  users.forEach(user => {
    if (user.completion_status === SURVEY_COMPLETION_STATUS_TYPES.COMPLETED) {
      if (isReopen) {
        return usersToNotify.push(user);
      }
      return usersWithoutNotification.push(user);
    }

    return isReopen
      ? usersWithoutNotification.push(user)
      : usersToNotify.push(user);
  });

  return {
    usersToNotify,
    usersWithoutNotification,
  };
};

export const prepareUsersForAction = (users, action, surveyStatus) =>
  users.reduce((acc, user) => {
    if (action === REVIEWER_ACTION_TYPES.SEND_REMINDER) {
      if (user.submitted_reviews !== user.total_reviews) {
        return [...acc, { user: user.id, action }];
      }

      return acc;
    }

    if (
      action === REVIEWER_ACTION_TYPES.REOPEN &&
      surveyStatus !== SURVEY_STATUS_TYPES.COMPLETED
    ) {
      if (user.submitted_reviews === user.total_reviews) {
        return [...acc, { user: user.id, action }];
      }

      return acc;
    }

    return [...acc, { user: user.id, action }];
  }, []);

export const prepareAllUsers = (
  users,
  currentUser,
  reviewers,
  usersToReview,
  isPeerReview,
  isSelfReview
) => {
  const isAdmin = checkUserRole(currentUser.role, ROLES.ADMIN);

  if (isAdmin) {
    return users.filter(user => {
      const isAssociate = checkUserRole(user.role, ROLES.ASSOCIATE);

      if (isSelfReview) {
        return !isItemInList(reviewers, user) && !isAssociate;
      }

      if (isPeerReview) {
        return (
          !isItemInList(reviewers, user) && !isItemInList(usersToReview, user)
        );
      }

      return !isItemInList(reviewers, user);
    });
  }

  return users.filter(user => {
    const isAssociate = checkUserRole(user.role, ROLES.ASSOCIATE);

    if (isSelfReview) {
      return (
        !isItemInList(reviewers, user) &&
        (user.id === currentUser.id ||
          isEmployeeInReporters(user.id, currentUser.reporters))
      );
    }

    if (isPeerReview) {
      return (
        !isItemInList(reviewers, user) &&
        !isItemInList(usersToReview, user) &&
        (user.id === currentUser.id ||
          isAssociate ||
          isEmployeeInReporters(user.id, currentUser.reporters))
      );
    }

    return (
      !isItemInList(reviewers, user) &&
      (user.id === currentUser.id ||
        isAssociate ||
        isEmployeeInReporters(user.id, currentUser.reporters))
    );
  });
};

export const getPeoplePickerParams = (isAdmin, isSelfReview) => {
  const { ME, ALL_REPORTS, ASSOCIATES } = PEOPLE_PARAM_SCOPE_VALUES;

  if (isAdmin) {
    if (isSelfReview) {
      return { role: EMPLOYEE_ROLES_LIST };
    }

    return { role: ROLES };
  }

  if (isSelfReview) {
    return { scope: [ME, ALL_REPORTS] };
  }

  return { scope: [ME, ALL_REPORTS, ASSOCIATES] };
};
