import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import Tooltip from '../tooltip';
import { ReactComponent as DeletedUserIcon } from '../../../assets/icons/person_remove.svg';

const useStyles = makeStyles(() => ({
  icon: {
    width: 32,
    height: 32,
  },
  large: {
    width: 64,
    height: 64,
  },
  medium: {
    width: 48,
    height: 48,
  },
  small: {
    width: 24,
    height: 24,
  },
  extraSmall: {
    width: 20,
    height: 20,
  },
}));

const DeletedUserAvatar = ({
  tooltipText,
  isLarge,
  isMedium,
  isSmall,
  isExtraSmall,
}) => {
  const classes = useStyles();

  return (
    <Tooltip
      customIconClass={classNames(classes.icon, {
        [classes.large]: isLarge,
        [classes.medium]: isMedium,
        [classes.small]: isSmall,
        [classes.extraSmall]: isExtraSmall,
      })}
      text={tooltipText}
      icon={DeletedUserIcon}
    />
  );
};

DeletedUserAvatar.defaultProps = {
  isLarge: false,
  isMedium: false,
  isSmall: false,
  isExtraSmall: false,
};

DeletedUserAvatar.propTypes = {
  tooltipText: PropTypes.string.isRequired,
  isLarge: PropTypes.bool,
  isMedium: PropTypes.bool,
  isSmall: PropTypes.bool,
  isExtraSmall: PropTypes.bool,
};

export default DeletedUserAvatar;
