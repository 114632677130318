import { useState, useMemo, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import CustomModal from '../customModal';
import SelectField from '../selectField';
import CustomScrollBar from '../customScrollBar';
import UsersList from '../usersList';
import { useAvailableUsers } from '../../../utility/hooks';
import { isArrayEmpty, getObjectToNumberArray } from '../../../utility/helpers';
import { getShareTypeUserLabel } from '../../../utility/uiUtils';
import { PARAMS } from '../../../constants/pages';
import { ROLES } from '../../../constants/rolesAndPermissionList';

const SHARE_TYPES = [
  {
    translationKey: 'viewer',
    value: true,
  },
  {
    translationKey: 'collaborator',
    value: false,
  },
];

const useStyles = makeStyles(({ spacing }) => ({
  main: {
    minHeight: 326,
  },
  inputWrapper: {
    display: 'grid',
    gridTemplateColumns: '147px 315px',
    gridColumnGap: 8,
    marginBottom: spacing(2),
    marginTop: 20,
  },
  inputWrapperFullWidth: {
    gridTemplateColumns: '1fr',
  },
  usersSelectWrapper: {
    width: 315,
  },
  usersSelectWrapperReport: {
    width: '100%',
  },
  shareType: {
    minWidth: 147,
  },
  heading: {
    fontSize: 14,
    margin: spacing(4, 0),
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 188,
  },
  scrollY: {
    top: 0,
    right: -21,
    height: '100%',
  },
}));

const EmployeeShareDialog = ({
  translations,
  isOpened,
  currentUser,
  sharedWith,
  isReportShare,
  onClose,
  onSave,
}) => {
  const classes = useStyles();
  const shareTypes = useMemo(
    () =>
      SHARE_TYPES.map(item => {
        return { ...item, name: translations.sharedItem[item.translationKey] };
      }),
    [translations]
  );
  const [sharedList, setSharedList] = useState([]);
  const [isReadOnly, setIsReadOnly] = useState(true);

  const params = useMemo(() => {
    if (!isOpened) return {};

    return {
      [PARAMS.EXCLUDE]: [
        ...getObjectToNumberArray(sharedWith),
        ...(isReportShare ? [currentUser.id] : []),
      ],
      ...(isReportShare
        ? { [PARAMS.ROLE]: ROLES.MODERATOR }
        : { [PARAMS.AVAILABLE_MANAGERS]: currentUser.id }),
    };
  }, [isOpened, isReportShare, sharedWith, currentUser]);

  const availableUsers = useAvailableUsers(params);

  const getUserLabel = useCallback(
    user => getShareTypeUserLabel(translations.sharedItem, user),
    [translations]
  );

  const handleClose = () => {
    onClose();
    setSharedList([]);
    setIsReadOnly(true);
  };

  const handleChangeShareType = newType => setIsReadOnly(newType);

  const handleRemoveUser = user =>
    setSharedList(prevShared => prevShared.filter(u => u.id !== user.id));

  const handleSelect = updatedSharedList => {
    const selectedLength = updatedSharedList.length;
    setSharedList(prevSharedList => [
      ...prevSharedList,
      {
        ...updatedSharedList[selectedLength - 1],
        read_only: isReadOnly,
      },
    ]);
  };

  const handleSave = () => {
    const sharedListData = [...sharedWith, ...sharedList].map(u => {
      return {
        user: u.id,
        ...(!isReportShare ? { read_only: u.read_only } : {}),
      };
    });

    handleClose();
    onSave(sharedListData);
  };

  return (
    <CustomModal
      paperClass={classes.paper}
      confirmButtonLabel={translations.save}
      closeButtonLabel={translations.cancel}
      title={translations.title}
      isConfirmDisabled={isArrayEmpty(sharedList)}
      isOpened={isOpened}
      onClose={handleClose}
      onConfirm={handleSave}
      isMedium
    >
      <div className={classes.main}>
        <div
          className={classNames(classes.inputWrapper, {
            [classes.inputWrapperFullWidth]: isReportShare,
          })}
        >
          {!isReportShare && (
            <SelectField
              className={classes.shareType}
              value={isReadOnly}
              label={translations.accessType}
              options={shareTypes}
              parser={{ value: 'value', label: 'name' }}
              onChange={handleChangeShareType}
              isSearchDisabled
            />
          )}
          <SelectField
            className={classNames(classes.usersSelectWrapper, {
              [classes.usersSelectWrapperReport]: isReportShare,
            })}
            value={sharedList}
            label={translations.selectUserLabel}
            placeholder={translations.placeholder}
            options={availableUsers}
            parser={{ value: 'id', label: 'first_name' }}
            onChange={handleSelect}
            shouldReturnOption
            shouldHideMultipleResults
            isMulti
            isUser
          />
        </div>
        <Typography variant="h5" className={classes.heading}>
          {!isArrayEmpty(sharedList) ? `${translations.grantAccessTo}:` : ''}
        </Typography>
        <div className={classes.list}>
          <CustomScrollBar
            customScrollBarYClass={classes.scrollY}
            verticalScroll
            removeScrollX
            passContentHeight
            passContentWidth
          >
            <UsersList
              users={sharedList}
              getUserLabel={getUserLabel}
              onRemoveUser={handleRemoveUser}
              hasUserLabel={!isReportShare}
              isVerticalList
              isUserRemovable
              isSmallUserLabel
            />
          </CustomScrollBar>
        </div>
      </div>
    </CustomModal>
  );
};

EmployeeShareDialog.defaultProps = {
  isReportShare: false,
  currentUser: {},
};

EmployeeShareDialog.propTypes = {
  translations: PropTypes.object.isRequired,
  isOpened: PropTypes.bool.isRequired,
  currentUser: PropTypes.object,
  isReportShare: PropTypes.bool,
  sharedWith: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default memo(EmployeeShareDialog);
