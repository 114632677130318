import { validateTasks } from '../../../utility/tasks';
import { FIELD_TYPES } from '../../../constants/fieldTypes';

export const getFields = checkCanManageTask => [
  {
    name: 'tasks',
    translationKey: 'tasks',
    type: FIELD_TYPES.TASKS,
    optionsKey: 'availableUsers',
    checkCanManageTask,
    shouldRender: () => true,
    validators: [
      {
        validator: (tasks, values) => validateTasks(tasks, values),
        isMultiple: true,
      },
    ],
  },
];
