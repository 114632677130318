import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, withStyles } from '@material-ui/core';
import { isArrayEmpty } from '../../../utility/helpers';

const styles = ({ palette: { primary }, spacing }) => ({
  list: {
    padding: 0,
    margin: 0,
    listStyleType: 'none',
  },
  title: {
    color: primary.bluish3,
    marginBottom: spacing(1),
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    wordBreak: 'break-word',
    '&::before': {
      backgroundColor: primary.bluish1,
      borderRadius: '50%',
      content: '""',
      height: 4,
      width: 4,
      marginLeft: spacing(0.25),
      marginRight: spacing(2),
    },
  },
});

class AgendaList extends PureComponent {
  render() {
    const { classes, titleClass, title, items } = this.props;
    return (
      <div>
        <Typography
          className={classNames(classes.title, titleClass)}
          variant="subtitle2"
        >
          {title}
        </Typography>
        <ul className={classes.list}>
          {items.map(agenda => (
            <li key={agenda.id} className={classes.item}>
              <Typography variant="body2" component="span">
                {agenda.name}
              </Typography>
            </li>
          ))}
        </ul>
        {isArrayEmpty(items) && <Typography variant="body2">-</Typography>}
      </div>
    );
  }
}

AgendaList.defaultProps = {
  titleClass: undefined,
};

AgendaList.propTypes = {
  titleClass: PropTypes.string,
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withStyles(styles)(AgendaList);
