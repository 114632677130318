import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, Fade, makeStyles } from '@material-ui/core';
import Tooltip from '../tooltip';
import { ReactComponent as CoursesLinkIcon } from '../../../assets/icons/link-dark.svg';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: spacing(1.5),
  },
  courses: {
    marginBottom: spacing(-0.5),
  },
}));

const CoursesIconTooltip = ({
  className,
  text,
  numberOfCourses,
  isVisible,
}) => {
  const classes = useStyles();
  const hasCourses = numberOfCourses !== 0;

  return (
    <Fade
      className={classNames(classes.root, className)}
      in={isVisible}
      unmountOnExit
    >
      <div className={classes.root}>
        <Tooltip
          customIconClass={classNames({
            [classes.icon]: hasCourses,
          })}
          text={text}
          icon={CoursesLinkIcon}
        />
        {hasCourses && (
          <Typography variant="subtitle2" className={classes.courses}>
            {numberOfCourses}
          </Typography>
        )}
      </div>
    </Fade>
  );
};

CoursesIconTooltip.defaultProps = {
  className: undefined,
  numberOfCourses: 0,
  isVisible: false,
};

CoursesIconTooltip.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  numberOfCourses: PropTypes.number,
  isVisible: PropTypes.bool,
};

export default CoursesIconTooltip;
