import {
  isEmpty,
  validateLowercase,
  validateUppercase,
  validateTypeOfCharacters,
  validateLength,
  validateWhitespace,
} from '../../../utility/validation';

const fields = [
  {
    name: 'password',
    type: 'password',
    label: 'password',
    required: true,
    forbidTrim: true,
    hasVisibilityToggle: true,
    validators: [
      {
        type: 'passwordRequirements',
        validator: isEmpty,
      },
      {
        type: 'passwordRequirements',
        validator: value => validateLength(value, 8),
      },
      {
        type: 'passwordRequirements',
        validator: validateLowercase,
      },
      {
        type: 'passwordRequirements',
        validator: validateUppercase,
      },
      {
        type: 'passwordRequirements',
        validator: validateTypeOfCharacters,
      },
      {
        type: 'passwordRequirements',
        validator: validateWhitespace,
      },
    ],
  },
  {
    name: 'repeatPassword',
    type: 'password',
    label: 'repeatPassword',
    required: true,
    shouldShowError: true,
    forbidTrim: true,
    hasVisibilityToggle: true,
    validators: [
      {
        type: 'passwordRequirements',
        validator: isEmpty,
      },
      {
        type: 'passwordRequirements',
        validator: value => validateLength(value, 8),
      },
      {
        type: 'passwordRequirements',
        validator: validateLowercase,
      },
      {
        type: 'passwordRequirements',
        validator: validateUppercase,
      },
      {
        type: 'passwordRequirements',
        validator: validateTypeOfCharacters,
      },
      {
        type: 'passwordRequirements',
        validator: validateWhitespace,
      },
    ],
  },
];

export default fields;
