import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import Header from './header';
import Body from './body';
import { formatDate } from '../../../utility/dateUtils';
import { getOneOnOneReportWeeks } from '../../../utility/oneOnOne';
import { PERIOD_DATE_FORMAT } from '../../../constants/oneOnOne';

const OneOnOneReport = ({
  translations,
  reports,
  canSeeUserProfile,
  onGoToMeetingDetails,
  onGoToUserProfile,
  onGetWeekReport,
}) => {
  const header = getOneOnOneReportWeeks();
  const startDate = useMemo(
    () => formatDate(header.startDate, PERIOD_DATE_FORMAT),
    [header]
  );

  return (
    <div>
      <Header
        labels={translations}
        header={header.weeksList}
        periodStart={startDate}
      />
      <Body
        translations={translations}
        reports={reports}
        canSeeUserProfile={canSeeUserProfile}
        onGoToMeetingDetails={onGoToMeetingDetails}
        onGoToUserProfile={onGoToUserProfile}
        onGetWeekReport={onGetWeekReport}
      />
    </div>
  );
};

OneOnOneReport.propTypes = {
  translations: PropTypes.object.isRequired,
  reports: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onGoToUserProfile: PropTypes.func.isRequired,
  canSeeUserProfile: PropTypes.func.isRequired,
  onGetWeekReport: PropTypes.func.isRequired,
  onGoToMeetingDetails: PropTypes.func.isRequired,
};

export default memo(OneOnOneReport);
