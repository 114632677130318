import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import ActionButton from '../../actionButton';
import { getUserRequestDateLabel } from '../../../../utility/uiUtils';
import { ACTION_BUTTON_TYPES } from '../../actionButton/config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    boxSizing: 'border-box',
    borderRadius: 4,
    border: `1px solid ${primary.blue5}`,
    padding: spacing(4),
    transition: 'border .2s ease',
    '&:hover': {
      border: `1px solid ${primary.blue1}`,
    },
  },
  request: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: spacing(2),
  },
  requestMini: {
    paddingLeft: 0,
  },
  iconButton: {
    borderRadius: '50%',
    border: `1px solid ${primary.bluish4}`,
    marginLeft: spacing(4),
    width: 34,
    height: 34,
  },
  editIcon: {
    '& path': {
      fill: primary.bluish3,
    },
  },
  dateWrapper: {
    marginTop: spacing(2),
    paddingLeft: spacing(2),
  },
  dateWrapperMini: {
    marginTop: 'auto',
    paddingLeft: 0,
  },
  date: {
    color: primary.bluish3,
    lineHeight: '20px',
  },
}));

const UserCard = ({
  className,
  translations,
  request,
  isExpired,
  isMiniCard,
  onDeleteRequest,
  onChangeRequest,
}) => {
  const classes = useStyles();
  const { EDIT, DELETE_FILL } = ACTION_BUTTON_TYPES;
  const cardInfo = getUserRequestDateLabel(
    translations.requested,
    request.created_at
  );

  return (
    <div className={classNames(classes.root, className)}>
      <div
        className={classNames(classes.request, {
          [classes.requestMini]: isMiniCard,
        })}
      >
        <Typography variant="body2">{translations.userRequest}</Typography>
        <ActionButton
          className={classes.iconButton}
          iconClass={classNames({ [classes.editIcon]: !isExpired })}
          type={isExpired ? DELETE_FILL : EDIT}
          onClickHandler={isExpired ? onDeleteRequest : onChangeRequest}
        />
      </div>
      <div
        className={classNames(classes.dateWrapper, {
          [classes.dateWrapperMini]: isMiniCard,
        })}
      >
        <Typography className={classes.date} variant="caption">
          {cardInfo}
        </Typography>
      </div>
    </div>
  );
};

UserCard.defaultProps = {
  className: undefined,
  isMiniCard: false,
  isExpired: false,
};

UserCard.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.shape({}).isRequired,
  request: PropTypes.shape({}).isRequired,
  isMiniCard: PropTypes.bool,
  isExpired: PropTypes.bool,
  onChangeRequest: PropTypes.func.isRequired,
  onDeleteRequest: PropTypes.func.isRequired,
};

export default UserCard;
