import { memo } from 'react';
import PropTypes from 'prop-types';
import UserLabel from '../userLabel';
import AsyncListTooltip from '../asyncListTooltip';
import UserCountBadge from '../userCountBadge';

const UserCount = ({
  className,
  tooltipTitle,
  addButtonLabel,
  userCount,
  canSeeUserProfile,
  shouldDisablePortal,
  isReadOnly,
  hasAddButton,
  isTooltipDisabled,
  onAdd,
  goToUserProfile,
  getUsers,
}) => {
  const renderUser = (user, isClickable) => (
    <UserLabel user={user} isDisabled={isReadOnly || !isClickable} />
  );

  return (
    <div className={className}>
      <AsyncListTooltip
        title={tooltipTitle}
        addButtonLabel={addButtonLabel}
        hasAddButton={hasAddButton}
        getLabelData={getUsers}
        renderLabelItem={renderUser}
        shouldDisablePortal={shouldDisablePortal}
        isTooltipDisabled={isTooltipDisabled}
        isItemClickable={canSeeUserProfile}
        onItemClickHandler={goToUserProfile}
        onAdd={onAdd}
      >
        <UserCountBadge userCount={userCount || 0} />
      </AsyncListTooltip>
    </div>
  );
};

UserCount.defaultProps = {
  className: null,
  userCount: 0,
  tooltipTitle: '',
  addButtonLabel: '',
  isReadOnly: false,
  shouldDisablePortal: true,
  hasAddButton: false,
  isTooltipDisabled: false,
  canSeeUserProfile: () => true,
  goToUserProfile: () => {},
  onAdd: () => {},
};

UserCount.propTypes = {
  className: PropTypes.string,
  addButtonLabel: PropTypes.string,
  userCount: PropTypes.number,
  isReadOnly: PropTypes.bool,
  canSeeUserProfile: PropTypes.func,
  tooltipTitle: PropTypes.string,
  isTooltipDisabled: PropTypes.bool,
  shouldDisablePortal: PropTypes.bool,
  hasAddButton: PropTypes.bool,
  getUsers: PropTypes.func.isRequired,
  goToUserProfile: PropTypes.func,
  onAdd: PropTypes.func,
};

export default memo(UserCount);
