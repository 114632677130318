import { connect } from 'react-redux';
import download from 'downloadjs';
import { Typography, makeStyles } from '@material-ui/core';
import ActionPlansPage from '../../components/pages/actionPlansPage';
import PageContainer from '../../components/shared/pageContainer';
import ActionButton from '../../components/shared/actionButton';
import { actionPlansSelector } from '../../store/selectors/actionPlansSelector';
import { tagsSelector } from '../../store/selectors/tagsSelector';
import { peopleSelector } from '../../store/selectors/peopleSelector';
import { coursesSelector } from '../../store/selectors/coursesSelector';
import {
  getAllActionPlans,
  clearAllActionPlans,
} from '../../store/modules/actionPlans';
import { getAllUsers, clearAllUsers } from '../../store/modules/people';
import {
  getCourseEnrolledUsers,
  clearCourseEnrolledUsers,
} from '../../store/modules/courseDetails';
import { getCourses, clearCourses } from '../../store/modules/courses';
import {
  getPageQuickFilters,
  clearPageQuickFilters,
  getTagsCategories,
  clearTagsCategories,
  addMultipleTags,
} from '../../store/modules/tags';
import http from '../../utility/http';
import { useTranslations } from '../../utility/useTranslations';
import { formatDate } from '../../utility/dateUtils';
import { API_ACTION_PLANS_EXPORT } from '../../constants/apiRoutes';
import { checkUserRole } from '../../utility/helpers';
import { ROLES } from '../../constants/rolesAndPermissionList';
import { ACTION_BUTTON_TYPES } from '../../components/shared/actionButton/config';
import { APP_PAGES } from '../../constants/pages';
import { PLACEMENTS } from '../../constants/tags';

const mapStateToProps = state => ({
  ...actionPlansSelector(state),
  ...tagsSelector(state),
  ...coursesSelector(state),
  allUsers: [...peopleSelector(state).allUsers],
});

const mapDispatchToProps = dispatch => ({
  getAllActionPlans: (isLoadMore, data) =>
    getAllActionPlans(dispatch, isLoadMore, data),
  clearAllActionPlans: () => clearAllActionPlans(dispatch),
  addMultipleTags: data => dispatch(addMultipleTags(data)),
  getTagsCategories: () => getTagsCategories(dispatch),
  getPageQuickFilters: () =>
    getPageQuickFilters(dispatch, PLACEMENTS.ACTION_POINTS.id),
  clearTagsCategories: () => dispatch(clearTagsCategories()),
  clearPageQuickFilters: () => dispatch(clearPageQuickFilters()),
  getAllUsers: () => getAllUsers(dispatch),
  clearAllUsers: () => dispatch(clearAllUsers()),
  getCourses: params => getCourses(dispatch, params),
  clearCourses: () => dispatch(clearCourses()),
  getCourseEnrolledUsers: (courseId, params) =>
    getCourseEnrolledUsers(dispatch, courseId, params),
  clearCourseEnrolledUsers: () => dispatch(clearCourseEnrolledUsers()),
});

const useStyles = makeStyles(({ spacing }) => ({
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  downloadButton: {
    marginLeft: spacing(2),
  },
}));

const DATE_FORMAT = 'dd_MMM_y_kk_mm_ss';

const ActionPlansPageView = props => {
  const classes = useStyles();
  const translations = useTranslations(APP_PAGES.ACTION_PLANS);
  const isAdmin = checkUserRole(props.auth.role, ROLES.ADMIN);

  const handleExport = () => {
    http.get(API_ACTION_PLANS_EXPORT).then(({ data }) => {
      download(
        new Blob([data], {
          type: 'text/csv',
        }),
        `action_plans_${formatDate(new Date(), DATE_FORMAT)}.csv`
      );
    });
  };

  const renderHeader = () => (
    <div className={classes.headerWrapper}>
      <Typography variant="h1">{translations.pageTitle}</Typography>
      {isAdmin && (
        <ActionButton
          className={classes.downloadButton}
          tooltipText={translations.exportTooltip}
          type={ACTION_BUTTON_TYPES.DOWNLOAD}
          onClickHandler={handleExport}
        />
      )}
    </div>
  );

  return (
    <PageContainer
      {...props}
      headerProps={{
        renderTitle: renderHeader,
      }}
      translations={translations}
      isFullWidthContent
    >
      <ActionPlansPage />
    </PageContainer>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActionPlansPageView);
