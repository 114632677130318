import { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import ColorBox from '../../../../colorBox';
import SubMenuItem from '../subMenuItem';
import { ellipsis } from '../../../../../../constants/helperCssRules';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  titleWrapper: {
    boxSizing: 'border-box',
    borderBottom: `1px solid ${primary.bluish5}`,
    display: 'flex',
    alignItems: 'center',
    padding: spacing(2, 1, 2, 2),
    margin: spacing(0, 2, 1, 2),
  },
  title: {
    flexGrow: 1,
    ...ellipsis(),
  },
  adornment: {
    flexShrink: 0,
    marginRight: spacing(2),
  },
}));

const Category = ({
  category,
  selectedFilters,
  categoryKey,
  hasCategoryColorBox,
  hasHash,
  hasColorBox,
  isSingleSelect,
  onSelect,
}) => {
  const classes = useStyles();
  const { name, color } = category;

  return (
    <div className={classes.root}>
      <div className={classes.titleWrapper}>
        {hasCategoryColorBox && (
          <ColorBox className={classes.adornment} bgColor={color} isSmall />
        )}
        <Typography
          variant="subtitle1"
          className={classes.title}
          component="span"
        >
          {name}
        </Typography>
      </div>
      {category[categoryKey].map(categoryItem => {
        const isSelected = selectedFilters?.includes(categoryItem.id);

        return (
          <SubMenuItem
            key={categoryItem.id}
            id={`sub-menu-item-${categoryItem.id}`}
            item={{ ...categoryItem, color }}
            selectedFilters={selectedFilters}
            isSelected={isSelected}
            hasHash={hasHash}
            hasColorBox={hasColorBox}
            isSingleSelect={isSingleSelect}
            onSelect={onSelect}
          />
        );
      })}
    </div>
  );
};

Category.defaultProps = {
  selectedFilters: [],
  hasHash: false,
  hasColorBox: false,
  isSingleSelect: false,
  hasCategoryColorBox: false,
};

Category.propTypes = {
  category: PropTypes.object.isRequired,
  selectedFilters: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
  ),
  categoryKey: PropTypes.string.isRequired,
  hasHash: PropTypes.bool,
  hasColorBox: PropTypes.bool,
  isSingleSelect: PropTypes.bool,
  hasCategoryColorBox: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
};

export default memo(Category);
