import { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import PageContainer from '../../shared/pageContainer';
import PricingPlan from '../../shared/pricingPlan';
import http from '../../../utility/http';
import { useTranslations } from '../../../utility/useTranslations';
import { API_SUBSCRIPTION } from '../../../constants/apiRoutes';
import {
  BOOK_A_DEMO_LINK,
  STRIPE_PRO_PRICE_ID,
} from '../../../constants/appConfig';
import { APP_PAGES } from '../../../constants/pages';
import { sticky } from '../../../constants/helperCssRules';

const useStyles = makeStyles(
  ({ palette: { primary }, spacing, breakpoints }) => ({
    divider: {
      paddingTop: spacing(8),
      ...sticky(primary.white, 105),
    },
    plans: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
      gridColumnGap: 24,
      [breakpoints.up('xLg')]: {
        gridColumnGap: 36,
      },
    },
    plan: {
      backgroundColor: primary.yellow4,
      borderRadius: 24,
      display: 'flex',
      justifyContent: 'center',
      padding: spacing(8, 5),
      [breakpoints.up('xLg')]: {
        padding: spacing(10, 8),
      },
      [breakpoints.up('xl')]: {
        padding: spacing(11, 9),
      },
    },
    planContent: {
      maxWidth: 337,
    },
    name: {
      color: primary.black,
      lineHeight: 1.4,
      marginBottom: spacing(2),
    },
    description: {
      color: primary.bluish3,
      fontFamily: 'ProximaNova-Regular',
      lineHeight: '27px',
      marginBottom: spacing(4),
    },
    planPriceWrapper: {
      display: 'flex',
      alignItems: 'baseline',
      marginBottom: spacing(8),
    },
    price: {
      color: primary.black,
      fontSize: 54,
      lineHeight: 1.2,
      marginRight: spacing(1),
    },
    priceDescription: {
      color: primary.bluish3,
      lineHeight: 1.3,
    },
    actionButton: {
      fontFamily: 'ProximaNova-Bold',
      fontSize: 18,
      padding: spacing(3.5, 12),
      maxHeight: 60,
      marginBottom: spacing(11),
    },
  })
);

const PricingPlansPage = ({
  navigate,
  organizationSettings,
  isPremiumUser,
  isSubscriptionExpired,
  ...rest
}) => {
  const translations = useTranslations(APP_PAGES.PRICING);
  const classes = useStyles();

  const onSubscribe = () =>
    http
      .post(API_SUBSCRIPTION, {
        price_id: STRIPE_PRO_PRICE_ID,
        success_url: `${window.location.origin}/`,
        cancel_url: window.location.origin,
      })
      .then(({ data }) => {
        window.location.href = data.subscription_session_url;
      });

  return (
    <PageContainer
      {...rest}
      translations={translations}
      navigate={navigate}
      organizationSettings={organizationSettings}
      isPremiumUser={isPremiumUser}
      shouldPassProps={false}
      isFullWidthContent
    >
      <div>
        <div className={classes.divider} />
        <div className={classes.plans}>
          <PricingPlan
            translations={translations.plans.free}
            subscribeButtonLabel={
              isPremiumUser
                ? translations.plans.free.subscribeButton.default
                : translations.plans.free.subscribeButton.current
            }
            price={translations.plans.free.price}
            isDisabled
          />
          <PricingPlan
            translations={translations.plans.pro}
            subscribeButtonLabel={
              isPremiumUser && !isSubscriptionExpired
                ? translations.plans.pro.subscribeButton.current
                : translations.plans.pro.subscribeButton.default
            }
            isDisabled={isPremiumUser && !isSubscriptionExpired}
            price={translations.plans.pro.price}
            onSubscribe={onSubscribe}
          />
          <PricingPlan
            translations={translations.plans.business}
            subscribeButtonLabel={
              translations.plans.business.subscribeButton.default
            }
            price={translations.plans.business.price}
            href={BOOK_A_DEMO_LINK}
            target="_blank"
            rel="noopener noreferrer"
            isLink
          />
        </div>
      </div>
    </PageContainer>
  );
};

PricingPlansPage.propTypes = {
  navigate: PropTypes.func.isRequired,
  organizationSettings: PropTypes.object.isRequired,
  isPremiumUser: PropTypes.bool.isRequired,
  isSubscriptionExpired: PropTypes.bool.isRequired,
};

export default memo(PricingPlansPage);
