import { NOTE_FIELD_NAMES } from './notes';
import {
  isEmpty,
  forbiddenCharacters,
  validateLength,
} from '../utility/validation';

export const NEW_TASK = {
  text: '',
  user: null,
};

export const TASK_FIELDS = [
  {
    name: NOTE_FIELD_NAMES.TEXT,
    required: true,
    translationKey: 'description',
    validators: [
      { type: 'required', validator: isEmpty },
      {
        type: 'maxTaskTextLength',
        validator: value => validateLength(value, 0, 250),
      },
      {
        type: 'forbiddenCharacters',
        validator: forbiddenCharacters,
      },
    ],
  },
  {
    name: NOTE_FIELD_NAMES.USER,
    translationKey: 'assignTo',
    required: true,
    parser: {
      value: 'id',
      label: 'first_name',
    },
    validators: [
      {
        validator: isEmpty,
        type: 'required',
      },
    ],
  },
];
