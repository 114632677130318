import ColorBox from '../components/shared/colorBox';
import { ReactComponent as StatusIcon } from '../assets/icons/status-icon.svg';
import { ReactComponent as TagsIcon } from '../assets/icons/tags-icon.svg';
import { ReactComponent as EditIcon } from '../assets/icons/edit-dark.svg';
import { ReactComponent as DeleteIcon } from '../assets/icons/delete-outlined.svg';
import { ReactComponent as SubjectIcon } from '../assets/icons/subject-icon.svg';
import { ReactComponent as DueDateIcon } from '../assets/icons/event_note_gray.svg';
import { filterTags } from './tagUtils';
import { isArrayEmpty } from './helpers';
import { getFilterableUsers } from './uiUtils';
import { PARAMS } from '../constants/pages';
import { ACTION_PLAN_STATUSES_LIST } from '../constants/statuses';
import {
  DUE_DATE_FILTERS,
  ACTION_PLAN_STATUS_FILTERS,
} from '../constants/filters';

export const getActionPlanStatuses = labels =>
  ACTION_PLAN_STATUSES_LIST.map(asu => ({
    ...asu,
    name: labels[asu.key],
    label: labels[asu.key],
  }));

export const getActionPlanFilterStatuses = labels =>
  ACTION_PLAN_STATUS_FILTERS.map(asu => ({
    ...asu,
    name: labels[asu.translationKey],
    label: labels[asu.translationKey],
  }));

export const getDueDateFilters = labels =>
  DUE_DATE_FILTERS.map(filter => ({
    ...filter,
    name: labels[filter.translationKey],
  }));

export const getActionPlansPageFilters = (
  translations,
  statuses,
  tagCategories,
  quickFilters = [],
  allUsers = [],
  isCumulativePage = false
) => {
  const { COMPLETION_STATUS, STATUS, TAGS, USER, DUE_DATE } = PARAMS;
  const defaultOption = { id: null, name: translations.all };
  const dueDateFilters = getDueDateFilters(translations.statuses);

  return [
    {
      id: COMPLETION_STATUS,
      name: translations.filters.labels[STATUS],
      icon: StatusIcon,
      isSingleSelect: true,
      items: [defaultOption, ...statuses],
    },
    ...(isCumulativePage
      ? [
          {
            id: USER,
            name: translations.filters.labels.for,
            icon: SubjectIcon,
            items: getFilterableUsers(allUsers),
          },
        ]
      : []),
    {
      id: DUE_DATE,
      name: translations.filters.labels.dueDate,
      icon: DueDateIcon,
      isSingleSelect: true,
      items: [defaultOption, ...dueDateFilters],
    },
    {
      id: TAGS,
      name: translations.filters.labels[TAGS],
      icon: TagsIcon,
      hasChildren: true,
      categoryItemsKey: TAGS,
      hasCategoryColorBox: true,
      hasSubmenuHash: true,
      isCategorized: true,
      items: tagCategories,
      categorySearch: filterTags,
    },
    ...(!isArrayEmpty(quickFilters)
      ? quickFilters?.map(qFilter => {
          return {
            id: qFilter.name,
            name: qFilter.name,
            icon: () => {
              return <ColorBox bgColor={qFilter.color} isSmall />;
            },
            parent: {
              id: TAGS,
            },
            items: qFilter?.tags,
          };
        }) || {}
      : []),
  ];
};

export const getDotsMenuItems = (
  labels,
  actionPlan,
  canManage,
  canManageProposal,
  isDisabled,
  canManageTasks,
  isPendingProposal,
  isCreatedForCurrentUser,
  onEdit,
  onDelete,
  onManageTasks,
  onDismissProposal
) => [
  {
    type: 'edit',
    label:
      isPendingProposal && !isCreatedForCurrentUser
        ? labels.accept
        : labels.edit,
    shouldItemRender: () =>
      isPendingProposal
        ? (canManageProposal || isCreatedForCurrentUser) && !isDisabled
        : !isDisabled && canManage,
    action: () => onEdit(actionPlan),
    icon: EditIcon,
  },
  {
    type: 'manageTasks',
    label: labels.manageTasks,
    shouldItemRender: () => !isPendingProposal && !isDisabled && canManageTasks,
    action: () => onManageTasks(actionPlan),
    icon: EditIcon,
  },
  {
    type: 'delete',
    label:
      isPendingProposal && !isCreatedForCurrentUser
        ? labels.dismiss
        : labels.delete,
    action: () =>
      isPendingProposal && !isCreatedForCurrentUser
        ? onDismissProposal(actionPlan)
        : onDelete(actionPlan),
    shouldItemRender: () =>
      isPendingProposal
        ? isCreatedForCurrentUser || canManageProposal
        : canManage,
    icon: DeleteIcon,
  },
];

export const preparePlansData = (plans, auth, statuses) => {
  return plans.map(plan => {
    const {
      user,
      is_proposed,
      is_pending_proposal,
      is_finished,
      courses,
      tasks,
    } = plan;
    const currentStatus = statuses.find(status => status.value === is_finished);
    const hasCourses = !isArrayEmpty(courses);
    const hasTasks = !isArrayEmpty(tasks);
    const isPendingProposal = is_proposed && is_pending_proposal;
    const isCreatedForCurrentUser = user.id === auth.id;

    return {
      ...plan,
      status: currentStatus,
      isPendingProposal,
      isCreatedForCurrentUser,
      hasCourses,
      hasTasks,
    };
  });
};
