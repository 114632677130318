import { useEffect, useCallback, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation, useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { grantedPermissionsSelector } from '../../../store/selectors/grantedPermissionsSelector';
import { dialogSelector } from '../../../store/selectors/dialogSelector';
import { setDialogVisibility as setGlobalDialog } from '../../../store/modules/dialogs';
import {
  checkUserRole,
  replacePropsInObject,
  objectHasProperty,
} from '../../../utility/helpers';
import { getOrganizationSettings as getGlobalSettings } from '../../../store/modules/organizationSettings';
import { ROLES } from '../../../constants/rolesAndPermissionList';
import {
  isPremiumUserCheck,
  isSubscriptionExpiredCheck,
} from '../../../utility/subscriptionHelper';

const LOGIN = '/login';
const UNAUTHORIZED = '/unauthorized-access';

const ProtectedRoute = ({
  auth,
  organizationSettings,
  children,
  requiredRoles,
  isPersonInfoRoute,
  ...rest
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { grantedPermissions } = useSelector(grantedPermissionsSelector);
  const { dialogs } = useSelector(dialogSelector);
  const isUserAuthenticated = auth && auth.access;
  const allowUserToAccessRoute =
    isUserAuthenticated && requiredRoles.includes(auth.role);
  const isAdmin = isUserAuthenticated && checkUserRole(auth.role, ROLES.ADMIN);
  const isPremiumUser = isPremiumUserCheck(organizationSettings);
  const isSubscriptionExpired =
    isSubscriptionExpiredCheck(organizationSettings);
  const setDialogVisibility = useCallback(
    data => dispatch(setGlobalDialog(data)),
    [dispatch]
  );
  const getOrganizationSettings = useCallback(
    () => getGlobalSettings(dispatch),
    [dispatch]
  );

  useEffect(() => {
    if (isUserAuthenticated && isPremiumUser && isSubscriptionExpired) {
      getOrganizationSettings(dispatch);
    }
  }, [
    dispatch,
    isPremiumUser,
    isUserAuthenticated,
    isSubscriptionExpired,
    location,
    getOrganizationSettings,
  ]);

  const forbidAccessToPersonInfoRoutes = () => {
    const whitelistEmployees = replacePropsInObject(
      auth.accessibleProfiles,
      organizationSettings.global_see_himself
        ? {
            [auth.id]: true,
          }
        : {}
    );

    return !objectHasProperty(whitelistEmployees, id);
  };

  const handleRedirect = path => {
    return (
      <Navigate
        to={{
          pathname: path,
          state: { from: location },
        }}
      />
    );
  };

  if (isUserAuthenticated) {
    if (!allowUserToAccessRoute) {
      return handleRedirect(UNAUTHORIZED);
    }

    if (
      allowUserToAccessRoute &&
      !isAdmin &&
      isPersonInfoRoute &&
      forbidAccessToPersonInfoRoutes()
    ) {
      return handleRedirect(UNAUTHORIZED);
    }

    return cloneElement(children, {
      location,
      navigate,
      dispatch,
      auth,
      organizationSettings,
      grantedPermissions,
      dialogs,
      isPremiumUser,
      isSubscriptionExpired,
      setDialogVisibility,
      getOrganizationSettings,
      ...rest,
    });
  }

  return handleRedirect(LOGIN);
};

ProtectedRoute.defaultProps = {
  requiredRoles: [],
  isPersonInfoRoute: false,
};

ProtectedRoute.propTypes = {
  auth: PropTypes.object.isRequired,
  organizationSettings: PropTypes.object.isRequired,
  requiredRoles: PropTypes.arrayOf(PropTypes.string),
  isPersonInfoRoute: PropTypes.bool,
};

export default ProtectedRoute;
