import { ReactComponent as Active } from '../assets/icons/status-active.svg';
import { ReactComponent as SuspendedIcon } from '../assets/icons/status-suspended.svg';
import { ReactComponent as NotInvited } from '../assets/icons/status-notInvited.svg';
import { ReactComponent as Invited } from '../assets/icons/status-invited.svg';
import { ReactComponent as CompletionSubmittedIcon } from '../assets/icons/check-circle-outline-green.svg';
import { ReactComponent as CompletionNotStartedIcon } from '../assets/icons/highlight_off.svg';
import { ReactComponent as CompletionStartedIcon } from '../assets/icons/access_time-green.svg';
import { primaryPalette } from '../styles/theme';

export const SURVEY_STATUS_TYPES = {
  ACTIVE: 'ACTIVE',
  COMPLETED: 'COMPLETED',
  SCHEDULED: 'SCHEDULED',
  DRAFT: 'DRAFT',
};

export const SURVEY_COMPLETION_STATUS_TYPES = {
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  NOT_STARTED: 'NOT_STARTED',
};

const ONE_ONE_ONE_COMPLETION_STATUS_TYPES = {
  REQUESTED: 'requested',
  UPCOMING: 'upcoming',
  PENDING: 'pending',
  PAST: 'past',
};

export const SURVEY_STATUSES = [
  {
    id: SURVEY_STATUS_TYPES.ACTIVE,
    name: 'In Progress',
    key: 'inProgress',
  },
  {
    id: SURVEY_STATUS_TYPES.COMPLETED,
    name: 'Done',
    key: 'done',
  },
  {
    id: SURVEY_STATUS_TYPES.SCHEDULED,
    name: 'Scheduled',
    key: 'scheduled',
  },
  {
    id: SURVEY_STATUS_TYPES.DRAFT,
    name: 'Draft',
    key: 'draft',
  },
];

export const SURVEY_COMPLETION_STATUSES = [
  {
    id: SURVEY_COMPLETION_STATUS_TYPES.NOT_STARTED,
    name: 'Not started',
    key: 'notStarted',
    icon: CompletionNotStartedIcon,
  },
  {
    id: SURVEY_COMPLETION_STATUS_TYPES.IN_PROGRESS,
    name: 'Started',
    key: 'started',
    icon: CompletionStartedIcon,
  },
  {
    id: SURVEY_COMPLETION_STATUS_TYPES.COMPLETED,
    name: 'Submitted',
    key: 'submitted',
    icon: CompletionSubmittedIcon,
  },
];

export const UserStatuses = [
  {
    id: 'IDLE',
    name: 'Not yet invited',
    key: 'status',
    color: primaryPalette.bluish5,
    backgroundColor: primaryPalette.white,
    borderColor: primaryPalette.bluish5,
    icon: NotInvited,
  },
  {
    id: 'INVITED',
    name: 'Invited',
    key: 'status',
    color: primaryPalette.green1,
    backgroundColor: '#FFFFFF',
    borderColor: primaryPalette.green1,
    icon: Invited,
  },
  {
    id: 'WITH_ACCESS',
    name: 'Activated',
    key: 'status',
    color: '#FFFFFF',
    backgroundColor: primaryPalette.green1,
    borderColor: primaryPalette.green1,
    icon: Active,
  },
  {
    id: 'SUSPENDED',
    name: 'Deactivated',
    key: 'status',
    color: '#FFFFFF',
    backgroundColor: primaryPalette.bluish5,
    borderColor: primaryPalette.bluish5,
    icon: SuspendedIcon,
  },
];

export const ACTION_PLAN_STATUSES = {
  IN_PROGRESS: false,
  DONE: true,
};

export const ACTION_PLAN_STATUSES_LIST = [
  {
    id: ACTION_PLAN_STATUSES.IN_PROGRESS,
    label: 'In progress',
    key: 'inProgress',
    value: ACTION_PLAN_STATUSES.IN_PROGRESS,
    color: primaryPalette.green1,
  },
  {
    id: ACTION_PLAN_STATUSES.DONE,
    label: 'Done',
    key: 'done',
    value: ACTION_PLAN_STATUSES.DONE,
    color: primaryPalette.white,
    borderColor: primaryPalette.green1,
    backgroundColor: primaryPalette.green1,
  },
];

export const COURSE_ENROLLMENT_STATUSES = [
  {
    name: 'To do',
    key: 'toDo',
    value: 1,
    id: 1,
  },
  {
    name: 'In progress',
    key: 'inProgress',
    value: 2,
    id: 2,
  },
  {
    name: 'Completed',
    key: 'completed',
    value: 3,
    id: 3,
  },
];

export const ONE_ON_ONE_COMPLETION_STATUSES = [
  {
    id: ONE_ONE_ONE_COMPLETION_STATUS_TYPES.REQUESTED,
    name: 'Requested',
  },
  {
    id: ONE_ONE_ONE_COMPLETION_STATUS_TYPES.UPCOMING,
    name: 'Upcoming',
  },
  {
    id: ONE_ONE_ONE_COMPLETION_STATUS_TYPES.PENDING,
    name: 'Pending',
  },
  {
    id: ONE_ONE_ONE_COMPLETION_STATUS_TYPES.PAST,
    name: 'Past',
  },
];
