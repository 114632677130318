import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import SectionTitle from '../sectionTitle';
import NotificationCard from '../notificationCard';
import UserAvatar from '../userAvatar';
import { isArrayEmpty, isObject } from '../../../utility/helpers';

const useStyles = makeStyles(({ spacing }) => ({
  title: {
    marginBottom: spacing(12),
  },
  feedbackWrapper: {
    display: 'grid',
    gridGap: 24,
    gridTemplateColumns: 'repeat(5, minmax(0, 1fr))',
  },
  feedbackItem: {
    height: '100%',
  },
  userAvatar: {
    marginBottom: spacing(2.5),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 18,
    height: 'calc(100% - 30px)',
  },
  feedbackText: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
}));

const Feedback = ({
  className,
  translations,
  feedback,
  isReadOnly,
  isUserClickable,
  onGoToPersonProfile,
}) => {
  const classes = useStyles();

  const renderFeedbackItem = (feedbackItem, order) => {
    const { user, comment, submitted_at } = feedbackItem;
    const isAnonymous = !isObject(user);
    const isClickable = !isReadOnly && !isAnonymous && isUserClickable(user);

    return (
      <div
        key={`feedback_item_${order}_${submitted_at}`}
        className={classes.feedbackItem}
      >
        <UserAvatar
          className={classes.userAvatar}
          user={isAnonymous ? translations.anonymousUser : user}
          anonymousUser={isAnonymous}
          variant="subtitle1"
          onClickHandler={() => onGoToPersonProfile(user.id)}
          clickableCaption={isClickable}
          caption
          extraSmall
        />
        <div className={classes.content}>
          <Typography className={classes.feedbackText} variant="subtitle2">
            {comment}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <div className={className}>
      <SectionTitle
        className={classes.title}
        variant="h5"
        title={translations.title}
      />
      {isArrayEmpty(feedback) ? (
        <NotificationCard title={translations.noResults} />
      ) : (
        <div className={classes.feedbackWrapper}>
          {feedback.map((feedbackItem, index) =>
            renderFeedbackItem(feedbackItem, index)
          )}
        </div>
      )}
    </div>
  );
};

Feedback.defaultProps = {
  className: '',
  isReadOnly: false,
  isUserClickable: () => true,
  onGoToPersonProfile: () => {},
};

Feedback.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  feedback: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isReadOnly: PropTypes.bool,
  isUserClickable: PropTypes.func,
  onGoToPersonProfile: PropTypes.func,
};

export default Feedback;
