import { memo } from 'react';
import PropTypes from 'prop-types';
import CustomFormDrawer from '../customFormDrawer';
import { getFields } from './config';

const ManageTasks = ({
  translations,
  isOpened,
  record,
  availableUsers,
  checkCanManageTask,
  onClose,
  onSave,
}) => {
  const handleSave = values => onSave(values);

  return (
    <CustomFormDrawer
      translations={translations}
      isOpened={isOpened}
      titleText={translations.title}
      initialData={{ tasks: record.tasks || [] }}
      fields={getFields(checkCanManageTask)}
      availableUsers={availableUsers}
      onClose={onClose}
      onSave={handleSave}
      hasCancelButton
      isMiddleWidth
      isInitialValid
      hideDelete
    />
  );
};

ManageTasks.defaultProps = {
  checkCanManageTask: () => true,
  availableUsers: [],
};

ManageTasks.propTypes = {
  translations: PropTypes.object.isRequired,
  isOpened: PropTypes.bool.isRequired,
  record: PropTypes.shape({}).isRequired,
  availableUsers: PropTypes.arrayOf(PropTypes.object),
  checkCanManageTask: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default memo(ManageTasks);
