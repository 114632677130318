import { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import ConditionalTooltip from '../conditionalTooltip';
import UserCount from '../userCount';
import ListWithTooltip from '../listWithTooltip';
import {
  isEllipsisActive,
  isArrayEmpty,
  isArraySubset,
} from '../../../utility/helpers';
import { ellipsis } from '../../../constants/helperCssRules';
import ActionButton from '../actionButton';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';
import RichTextPreview from '../richTextPreview';

const VISIBLE_ITEMS = 3;

const styles = ({ palette: { primary }, spacing }) => ({
  main: {
    cursor: 'pointer',
    boxSizing: 'border-box',
    border: `1px solid ${primary.bluish7}`,
    borderRadius: 4,
    width: '100%',
    minWidth: 280,
    minHeight: 284,
    padding: spacing(6),
    transition: 'border .2s ease',
    '&:hover': {
      border: `1px solid ${primary.blue1}`,
    },
  },
  readOnly: {
    cursor: 'auto',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    maxWidth: 158,
    ...ellipsis(),
  },
  userCount: {
    marginLeft: spacing(1),
  },
  labelWrapper: {
    lineHeight: '20px',
    marginBottom: spacing(1),
  },
  label: {
    color: primary.bluish4,
  },
  descriptionWrapper: {
    margin: spacing(2, 0),
    height: 95,
  },
  description: {
    color: primary.bluish3,
    '-webkit-line-clamp': 3,
    wordBreak: 'break-word',
  },
  tags: {
    marginTop: spacing(2),
  },
  tagsList: {
    gridTemplateColumns: `repeat(${VISIBLE_ITEMS}, minmax(0,min-content)) minmax(auto, min-content)`,
  },
});

class JobTitleItem extends PureComponent {
  constructor(props) {
    super(props);

    this.titleRef = createRef();

    this.state = {
      hasTitleTooltip: false,
    };
  }

  componentDidMount() {
    this.shouldShowTooltip(this.titleRef.current);
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;

    if (
      prevProps.data.name !== data.name ||
      prevProps.data.attributes.length !== data.attributes.length ||
      (!isArrayEmpty(prevProps.attributes) &&
        !isArrayEmpty(data.attributes) &&
        !isArraySubset(prevProps.data.attributes, data.attributes))
    ) {
      this.shouldShowTooltip(this.titleRef.current);
    }
  }

  shouldShowTooltip = titleNode =>
    this.setState({
      hasTitleTooltip: isEllipsisActive(titleNode),
    });

  renderTitle = () => {
    const {
      classes,
      data,
      labels,
      getUsers,
      goToUserProfilePage,
      canSeeUserProfile,
    } = this.props;
    const { hasTitleTooltip } = this.state;

    return (
      <div className={classes.titleWrapper}>
        <ConditionalTooltip addTooltip={hasTitleTooltip} message={data.name}>
          <Typography
            ref={this.titleRef}
            variant="subtitle2"
            className={classes.title}
          >
            {data.name}
          </Typography>
        </ConditionalTooltip>
        <UserCount
          className={classes.userCount}
          tooltipTitle={labels.users}
          userCount={data.people_count}
          isTooltipDisabled={data.people_count === 0}
          canSeeUserProfile={canSeeUserProfile}
          getUsers={getUsers}
          goToUserProfile={goToUserProfilePage}
        />
      </div>
    );
  };

  renderDescription = () => {
    const { classes, data, labels, handlePreviewClick } = this.props;

    return (
      <div className={classes.descriptionWrapper}>
        <div className={classNames(classes.labelWrapper, classes.titleWrapper)}>
          <Typography variant="body2" className={classes.label}>
            {labels.description}
          </Typography>
          {data.description ? (
            <ActionButton
              type={ACTION_BUTTON_TYPES.PREVIEW}
              tooltipText={labels.preview}
              onClickHandler={handlePreviewClick}
            />
          ) : null}
        </div>
        {data.description ? (
          <RichTextPreview
            className={classes.description}
            text={data.description}
            isEllipsis
          />
        ) : (
          <Typography variant="subtitle2" className={classes.description}>
            -
          </Typography>
        )}
      </div>
    );
  };

  renderAttributes = () => {
    const { data, labels } = this.props;

    return (
      <ListWithTooltip
        label={labels.attributes}
        items={data.attributes}
        asyncListProps={{
          isDisabled: true,
        }}
        visibleItemsCount={VISIBLE_ITEMS}
      />
    );
  };

  renderTags = () => {
    const { classes, data, labels } = this.props;

    return (
      <ListWithTooltip
        className={classes.tags}
        listClass={classes.tagsList}
        label={labels.tags}
        items={data.tags}
        asyncListProps={{
          isDisabled: true,
        }}
        visibleItemsCount={VISIBLE_ITEMS}
        isTagsList
      />
    );
  };

  render() {
    const { classes, isReadOnly, onJobTitleClick } = this.props;

    return (
      <div
        className={classNames(classes.main, { [classes.readOnly]: isReadOnly })}
        onClick={isReadOnly ? () => {} : onJobTitleClick}
      >
        {this.renderTitle()}
        {this.renderDescription()}
        {this.renderAttributes()}
        {this.renderTags()}
      </div>
    );
  }
}

JobTitleItem.propTypes = {
  classes: PropTypes.object.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  canSeeUserProfile: PropTypes.func.isRequired,
  onJobTitleClick: PropTypes.func.isRequired,
  goToUserProfilePage: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
};

export default withStyles(styles)(JobTitleItem);
