import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import CustomCheckbox from '../../customCheckbox';
import UserAvatar from '../../userAvatar';

const styles = ({ palette: { primary }, spacing }) => ({
  main: {
    borderBottom: `1px solid ${primary.bluish7}`,
    display: 'flex',
    alignItems: 'center',
    padding: spacing(4, 0),
    width: '100%',
  },
  userAvatar: {
    marginLeft: spacing(2),
    width: 'fit-content',
    maxWidth: 'calc(100% - 142px)',
  },
  status: {
    display: 'flex',
    marginLeft: 'auto',
    width: 94,
  },
  icon: {
    marginRight: spacing(2),
  },
});

class Reviewer extends PureComponent {
  render() {
    const {
      classes,
      reviewer,
      isSelected,
      isClickable,
      status,
      isDisabled,
      onGoToPersonProfile,
      onChange,
    } = this.props;
    const { icon: Icon, name } = status;

    return (
      <div className={classes.main}>
        <CustomCheckbox
          isChecked={isSelected}
          onChange={onChange}
          disabled={isDisabled}
          isControlled
        />
        <UserAvatar
          className={classes.userAvatar}
          variant="subtitle2"
          user={reviewer}
          clickableCaption={isClickable}
          onClickHandler={() => onGoToPersonProfile(reviewer.id)}
          small
          caption
        />
        <div className={classes.status}>
          <Icon className={classes.icon} />
          <Typography variant="body2">{name}</Typography>
        </div>
      </div>
    );
  }
}

Reviewer.propTypes = {
  classes: PropTypes.object.isRequired,
  reviewer: PropTypes.shape({}).isRequired,
  isClickable: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  status: PropTypes.shape({}).isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onGoToPersonProfile: PropTypes.func.isRequired,
};
export default withStyles(styles)(Reviewer);
