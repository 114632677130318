import { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TransitionGroup } from 'react-transition-group';
import { Typography, Fade, makeStyles } from '@material-ui/core';
import ActionButton from '../../actionButton';
import RichTextPreview from '../../richTextPreview';
import StatusIndicator from '../../statusIndicator';
import DotsMenu from '../../dotsMenu';
import ListWithTooltip from '../../listWithTooltip';
import Tag from '../../tag';
import TextBoxWithTooltip from '../../textBoxWithTooltip';
import UserLink from '../../userLink';
import TasksIconTooltip from '../../tasksIconTooltip';
import CoursesIconTooltip from '../../coursesIconTooltip';
import RequestedIconTooltip from '../../requestedIconTooltip';
import ProposedStatus from '../../proposedStatus';
import { ellipsis } from '../../../../constants/helperCssRules';
import { isArrayEmpty } from '../../../../utility/helpers';
import { getContentLength } from '../../../../utility/uiUtils';
import { getDotsMenuItems } from '../../../../utility/actionPlans';
import { formatDate } from '../../../../utility/dateUtils';
import { ACTION_BUTTON_TYPES } from '../../actionButton/config';
import { VISIBLE_TAGS, DAY_MONTH_FORMAT, YEAR_FORMAT } from '../config';

const useStyles = makeStyles(
  ({ palette: { primary }, spacing, breakpoints }) => ({
    root: {
      backgroundColor: primary.bluish8,
      boxSizing: 'border-box',
      border: '1px solid transparent',
      borderRadius: 4,
      display: 'grid',
      gridTemplateColumns: `minmax(0, 1fr) 230px`,
      width: '100%',
      transition: 'border .2s ease',
    },
    rootMin: {
      gridTemplateColumns: `minmax(195px, 1fr) 140px`,
      [breakpoints.up('xLg')]: {
        gridTemplateColumns: `minmax(210px, 1fr) minmax(115px, 160px)`,
      },
      [breakpoints.up('xl')]: {
        gridTemplateColumns: `minmax(210px, 1fr) minmax(115px, 180px)`,
      },
    },
    clickableCard: {
      cursor: 'pointer',
      '&:hover': {
        border: `1px solid ${primary.blue1}`,
      },
    },
    content: {
      boxSizing: 'border-box',
      padding: spacing(3),
      width: '100%',
      [breakpoints.up('xLg')]: {
        padding: spacing(6),
      },
    },
    descriptionPreviewButton: {
      marginLeft: spacing(1.5),
    },
    aboutSectionReverse: {
      gridColumn: 'span 2',
    },
    info: {
      backgroundColor: primary.bluish9,
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      padding: spacing(6, 5),
    },
    infoMini: {
      padding: spacing(3),
      [breakpoints.up('xLg')]: {
        padding: spacing(6, 5),
      },
    },
    statusWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    sectionWrapper: {
      marginBottom: spacing(4),
    },
    labelSpacing: {
      marginBottom: spacing(1.5),
    },
    label: {
      color: primary.bluish3,
    },
    day: {
      marginBottom: spacing(1),
    },
    dotsMenu: {
      position: 'absolute',
      right: 16,
      top: 16,
      height: 20,
      width: 'auto',
      [breakpoints.up('xLg')]: {
        right: 24,
        top: 24,
      },
    },
    courseButtonWrapper: {
      display: 'flex',
      marginBottom: spacing(4),
    },
    descriptionLabelWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: spacing(1),
      lineHeight: '18px',
    },
    descriptionText: {
      ...ellipsis(),
      display: '-webkit-box',
      '-webkit-line-clamp': 3,
      '-webkit-box-orient': 'vertical',
      whiteSpace: 'normal',
    },
    tagsWrapper: {
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      minHeight: 28,
      width: '100%',
    },
    tagsWrapperAll: {
      marginBottom: spacing(2),
    },
    tagWrapper: {
      margin: spacing(0, 2, 2, 0),
    },
    tagsWrapperMini: {
      display: 'flex',
      alignItems: 'center',
      minHeight: 28,
    },
    tagsSectionSpacing: {
      marginBottom: spacing(4),
    },
    tagsList: {
      gridTemplateColumns: `repeat(${VISIBLE_TAGS}, minmax(0,min-content)) minmax(auto, min-content)`,
      gridColumnGap: spacing(2),
    },
    moreTags: {
      backgroundColor: primary.bluish7,
    },
    autoSpacing: {
      marginTop: 'auto',
    },
    createdByHighlightedLabel: {
      fontFamily: 'ProximaNova-Bold',
    },
    iconTooltipWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    iconSpacing: {
      marginLeft: spacing(2),
    },
    pendingProposalWrapper: {
      border: `1px solid ${primary.bluish5}`,
      borderRadius: 8,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: spacing(2, 3),
    },
    actionButtons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    actionButton: {
      marginLeft: spacing(1.5),
    },
  })
);

const ActionPlanCard = ({
  className,
  translations,
  actionPlan,
  statuses,
  isMiniCard,
  isCreatedByMe,
  isCreatorAccessible,
  isDisabled,
  canAcceptProposal,
  canManage,
  onCardClick,
  onDelete,
  onEdit,
  onManageTasks,
  onPreviewDescription,
  onDismissProposal,
}) => {
  const classes = useStyles();
  const {
    title,
    due_date,
    description,
    tags,
    created_on,
    creator,
    tasks,
    progress,
    is_proposed,
    is_pending_proposal,
    is_finished,
    courses,
  } = actionPlan;
  const { ACCEPT, CLOSE, PREVIEW } = ACTION_BUTTON_TYPES;

  const hasTasks = !isArrayEmpty(tasks);
  const hasCourses = !isArrayEmpty(courses);
  const hasDescription = description && getContentLength(description) > 0;
  const isPendingProposal = is_proposed && is_pending_proposal;

  const handleCardClick = useCallback(() => {
    onCardClick(actionPlan);
  }, [actionPlan, onCardClick]);

  const handleEdit = useCallback(
    () => onEdit(actionPlan),
    [actionPlan, onEdit]
  );

  const handleAcceptProposal = useCallback(
    e => {
      e.stopPropagation(e);
      handleEdit();
    },
    [handleEdit]
  );

  const handleDelete = useCallback(() => {
    onDelete(actionPlan);
  }, [actionPlan, onDelete]);

  const handleDismissProposal = useCallback(
    e => {
      e.stopPropagation();
      onDismissProposal(actionPlan);
    },
    [actionPlan, onDismissProposal]
  );

  const handlePreviewDescription = useCallback(
    e => {
      e.stopPropagation();

      onPreviewDescription(actionPlan);
    },
    [actionPlan, onPreviewDescription]
  );

  const renderStatusLabel = () => {
    const currentStatus = statuses.find(status => status.value === is_finished);

    return (
      <div className={classes.statusWrapper}>
        <StatusIndicator status={currentStatus} />
        {is_proposed && (
          <RequestedIconTooltip
            labels={translations.requested}
            isRequestedByCurrentUser={isMiniCard}
          />
        )}
      </div>
    );
  };

  const renderTagsList = () => {
    return (
      <div className={classes.tagsWrapperMini}>
        <ListWithTooltip
          listClass={classes.tagsList}
          moreItemsClass={classes.moreTags}
          items={tags}
          asyncListProps={{
            isDisabled: true,
          }}
          visibleItemsCount={VISIBLE_TAGS}
          isTagsList
        />
      </div>
    );
  };

  const renderAllTags = () => {
    return !isArrayEmpty(tags) ? (
      <TransitionGroup
        className={classNames(classes.tagsWrapper, classes.tagsWrapperAll)}
      >
        {tags.map(tag => (
          <Fade key={`tag_item_${tag.id}`} in>
            <div className={classes.tagWrapper}>
              <Tag tag={tag} color={tag.category.color} isSelected />
            </div>
          </Fade>
        ))}
      </TransitionGroup>
    ) : (
      <Typography
        className={classNames(classes.tagsWrapperMini, classes.tagsWrapperAll)}
      >
        -
      </Typography>
    );
  };

  return (
    <div
      className={classNames(
        classes.root,
        classes.clickableCard,
        {
          [classes.rootMin]: isMiniCard,
        },
        className
      )}
      onClick={handleCardClick}
    >
      <div className={classes.content}>
        {!isMiniCard &&
          !isDisabled &&
          isPendingProposal &&
          canAcceptProposal && (
            <div className={classes.sectionWrapper}>
              <div className={classes.pendingProposalWrapper}>
                <Typography variant="subtitle2">
                  {translations.proposeAccept}
                </Typography>
                <div className={classes.actionButtons}>
                  <ActionButton
                    className={classes.actionButton}
                    type={ACCEPT}
                    tooltipText={
                      translations.actionButtons[ACCEPT.toLowerCase()]
                    }
                    onClickHandler={handleAcceptProposal}
                    isSquared
                  />
                  <ActionButton
                    className={classes.actionButton}
                    type={CLOSE}
                    tooltipText={translations.actionButtons.dismiss}
                    onClickHandler={handleDismissProposal}
                    isSquared
                  />
                </div>
              </div>
            </div>
          )}
        <div className={classes.sectionWrapper}>
          <Typography
            className={classNames(classes.label, classes.labelSpacing)}
            variant="body2"
          >
            {translations.title}
          </Typography>
          <TextBoxWithTooltip variant="subtitle2" text={title} />
        </div>
        <div className={classes.sectionWrapper}>
          <div className={classes.descriptionLabelWrapper}>
            <Typography className={classes.label} variant="body2">
              {translations.description}
            </Typography>
            {hasDescription && (
              <ActionButton
                className={classes.descriptionPreviewButton}
                type={PREVIEW}
                tooltipText={translations.preview}
                onClickHandler={handlePreviewDescription}
              />
            )}
          </div>
          {hasDescription ? (
            <RichTextPreview
              className={classes.descriptionText}
              text={description}
              isEllipsis
            />
          ) : (
            <Typography variant="body2">-</Typography>
          )}
        </div>
        {isMiniCard && (
          <div>
            <Typography
              className={classNames(classes.label, classes.labelSpacing)}
              variant="body2"
            >
              {translations.tags}
            </Typography>
            {renderTagsList()}
          </div>
        )}
        {!isMiniCard && (
          <div>
            <Typography
              className={classNames(classes.label, classes.labelSpacing)}
              variant="body2"
            >
              {translations.tags}
            </Typography>
            {renderAllTags()}
          </div>
        )}
      </div>
      <div
        className={classNames(classes.info, {
          [classes.infoMini]: isMiniCard,
        })}
      >
        <div className={classes.sectionWrapper}>
          {isPendingProposal ? (
            <ProposedStatus proposedLabel={translations.proposed} />
          ) : (
            renderStatusLabel()
          )}
        </div>
        {!isPendingProposal && (
          <div className={classes.sectionWrapper}>
            <Typography className={classes.labelSpacing} variant="body2">
              {translations.completeness}
            </Typography>
            <Typography variant="subtitle1">{`${progress}%`}</Typography>
          </div>
        )}
        <div className={classes.sectionWrapper}>
          <Typography className={classes.labelSpacing} variant="body2">
            {translations.dueDate}
          </Typography>
          <Typography variant="subtitle1" className={classes.day}>
            {formatDate(due_date, DAY_MONTH_FORMAT)}
          </Typography>
          <Typography variant="overline">
            {formatDate(due_date, YEAR_FORMAT)}
          </Typography>
        </div>
        <div className={classes.sectionWrapper}>
          <Typography className={classes.labelSpacing} variant="body2">
            {isPendingProposal
              ? translations.proposedOn
              : translations.createdOn}
          </Typography>
          <Typography variant="subtitle1" className={classes.day}>
            {formatDate(created_on, DAY_MONTH_FORMAT)}
          </Typography>
          <Typography variant="overline">
            {formatDate(created_on, YEAR_FORMAT)}
          </Typography>
        </div>
        <div className={classes.iconTooltipWrapper}>
          <CoursesIconTooltip
            className={classes.sectionWrapper}
            text={translations.coursesIconTooltip}
            isVisible={hasCourses}
            numberOfCourses={courses?.length}
          />
          <TasksIconTooltip
            className={classNames(classes.sectionWrapper, {
              [classes.iconSpacing]: hasCourses,
            })}
            text={translations.tasksIconTooltip}
            isVisible={hasTasks}
            numberOfTasks={tasks?.length}
          />
        </div>
        {!isPendingProposal && (
          <UserLink
            className={classes.autoSpacing}
            label={translations.createdBy}
            labelClass={classNames({
              [classes.createdByHighlightedLabel]: isCreatedByMe,
            })}
            meLabel={translations.createdByMe}
            user={creator}
            isUserAccessible={isCreatorAccessible}
            isCurrentUser={isCreatedByMe}
          />
        )}
        {!isPendingProposal && !isMiniCard && canManage && (
          <DotsMenu
            className={classes.dotsMenu}
            menuItems={getDotsMenuItems(
              translations.menuItemLabels,
              actionPlan,
              canManage,
              false,
              isDisabled,
              canManage,
              false,
              false,
              handleEdit,
              handleDelete,
              onManageTasks
            )}
          />
        )}
      </div>
    </div>
  );
};

ActionPlanCard.defaultProps = {
  className: null,
  isMiniCard: false,
  canManage: false,
  isCreatorAccessible: false,
  isCreatedByMe: false,
  isDisabled: false,
  canAcceptProposal: false,
  onManageTasks: () => {},
  onCardClick: () => {},
  onDelete: () => {},
  onEdit: () => {},
  onDismissProposal: () => {},
  onPreviewDescription: () => {},
};

ActionPlanCard.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  actionPlan: PropTypes.shape({}).isRequired,
  statuses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isMiniCard: PropTypes.bool,
  isCreatedByMe: PropTypes.bool,
  isCreatorAccessible: PropTypes.bool,
  canAcceptProposal: PropTypes.bool,
  isDisabled: PropTypes.bool,
  canManage: PropTypes.bool,
  onCardClick: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onManageTasks: PropTypes.func,
  onPreviewDescription: PropTypes.func,
  onDismissProposal: PropTypes.func,
};

export default ActionPlanCard;
