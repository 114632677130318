import { createAction, handleActions } from 'redux-actions';
import http from '../../utility/http';
import { replaceObjectInList } from '../../utility/helpers';
import {
  api_action_plan,
  api_action_plan_comment,
  api_action_plan_tasks_status,
  api_task_completion,
} from '../../constants/apiRoutes';
import { ACTION_PLAN_COMMENTS_DEFAULT_PARAMS } from '../../constants/actionPlan';

// ------------------------------------
// Constants
// ------------------------------------

const SET_ACTION_PLAN = 'SET_ACTION_PLAN';
const SET_ACTION_PLAN_COMMENTS = 'SET_ACTION_PLAN_COMMENTS';
const SET_ACTION_PLAN_TASK_STATUS = 'SET_ACTION_PLAN_TASK_STATUS';

const initialState = {
  actionPlan: {},
  actionPlanComments: {},
};

// ------------------------------------
// Actions
// ------------------------------------

const setActionPlan = createAction(SET_ACTION_PLAN);
const setActionPlanTaskStatus = createAction(SET_ACTION_PLAN_TASK_STATUS);
const setActionPlanComments = createAction(SET_ACTION_PLAN_COMMENTS);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_ACTION_PLAN]: (state, { payload }) => {
    return {
      ...state,
      actionPlan: payload,
    };
  },
  [SET_ACTION_PLAN_COMMENTS]: (state, { payload }) => {
    const { data, page = 1 } = payload;
    if (page === 1) {
      return {
        ...state,
        actionPlanComments: data,
      };
    }

    return {
      ...state,
      actionPlanComments: {
        ...data,
        results: [...state.actionPlanComments.results, ...data.results],
      },
    };
  },
  [SET_ACTION_PLAN_TASK_STATUS]: (state, { payload }) => {
    const taskIndex = state.actionPlan.tasks.findIndex(
      currentTask => currentTask.id === payload.id
    );

    return {
      ...state,
      actionPlan: {
        ...state.actionPlan,
        tasks: replaceObjectInList(state.actionPlan.tasks, taskIndex, payload),
      },
    };
  },
};

// ------------------------------------
// API calls
// ------------------------------------

export const getActionPlan = (dispatch, actionPlanId) => {
  return http.get(api_action_plan(actionPlanId)).then(({ data }) => {
    dispatch(setActionPlan(data));

    return data;
  });
};

export const changeActionPlanTaskStatus = (dispatch, actionPlanId, taskId) => {
  return http
    .patch(api_action_plan_tasks_status(actionPlanId, taskId))
    .then(({ data }) => dispatch(setActionPlanTaskStatus(data)));
};

export const changeTaskStatus = (dispatch, taskId) => {
  return http
    .patch(api_task_completion(taskId))
    .then(({ data }) => dispatch(setActionPlanTaskStatus(data)));
};

export const getActionPlanComments = (dispatch, actionPlanId, params) => {
  const parameters = { ...ACTION_PLAN_COMMENTS_DEFAULT_PARAMS, ...params };

  return http
    .get(api_action_plan_comment(actionPlanId), { params: parameters })
    .then(({ data }) => {
      dispatch(setActionPlanComments({ data, page: parameters.page }));
    });
};

export default handleActions(reducers, initialState);

// ------------------------------------
// "plain" action functions
// ------------------------------------

export const clearActionPlan = () => setActionPlan(initialState.actionPlan);

export const clearActionPlanComments = () =>
  setActionPlanComments(initialState.actionPlanComments);
