import { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Typography,
  Popper,
  ClickAwayListener,
  Fade,
} from '@material-ui/core';
import { TransitionGroup } from 'react-transition-group';
import classNames from 'classnames';
import InputField from '../inputField';
import CustomButton from '../customButton';
import UserAvatar from '../userAvatar';
import CustomCheckbox from '../customCheckbox';
import CustomScrollBar from '../customScrollBar';
import ActionButton from '../actionButton';
import { ReactComponent as DirectReportArrow } from '../../../assets/icons/direct-reports-arrow.svg';
import {
  isArrayEmpty,
  isItemInList,
  getUnionOfTwoArrays,
  isArraySubset,
  trimString,
  getAssociates,
  getEmployees,
} from '../../../utility/helpers';
import { customSearch } from '../../../utility/uiUtils';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';
import { isDirty, setPopperWidth, getScrollbarHeight } from './config';

const styles = ({ palette: { primary }, spacing }) => ({
  picker: {
    display: 'flex',
    width: '100%',
    position: 'relative',
  },
  popperRoot: {
    boxShadow: 'none',
    borderRadius: 4,
    zIndex: 1,
  },
  popperMain: {
    boxSizing: 'border-box',
    backgroundColor: primary.white,
    border: `1px solid ${primary.bluish6}`,
    height: 323,
    borderRadius: 4,
    padding: spacing(4),
    marginTop: spacing(1),
  },
  popperMainSmall: {
    height: 200,
  },
  popperContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  popoverFooter: {
    display: 'flex',
    marginTop: spacing(4),
    paddingTop: 24,
    borderTop: `1px solid ${primary.bluish7}`,
  },
  item: {
    borderRadius: 4,
  },
  selectAllItem: {
    padding: spacing(2),
  },
  userItem: {
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    padding: spacing(2),
  },
  userAvatar: {
    width: 'calc(100% - 24px)',
  },
  avatarLabel: {
    color: primary.bluish2,
    fontSize: 16,
    lineHeight: '20px',
    marginLeft: 0,
    paddingRight: spacing(1.5),
    width: '100%',
    userSelect: 'none',
  },
  avatarLabelSelected: {
    color: primary.bluish1,
    fontFamily: 'ProximaNova-Bold',
    fontSize: 14,
    lineHeight: '18px',
  },
  checkboxContainer: {
    display: 'flex',
    flexShrink: 0,
    width: 24,
    height: 24,
  },
  directReports: {
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    marginTop: spacing(1),
    padding: spacing(2),
  },
  directReportText: {
    color: primary.bluish2,
    cursor: 'pointer',
    marginLeft: spacing(2),
    flexGrow: 1,
    userSelect: 'none',
  },
  noResults: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: spacing(33.5),
  },
  noResultsWithSelectAllEmployees: {
    marginTop: spacing(17.5),
  },
  noResultsWithSelectAll: {
    marginTop: spacing(12.5),
  },
  arrowIcon: {
    marginLeft: spacing(2.75),
    marginBottom: spacing(2.25),
  },
  scrollY: {
    top: 11,
    right: -10,
    height: 'calc(100% - 22px)',
    width: 4,
  },
  hover: {
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      backgroundColor: primary.bluish8,
    },
  },
  selectButton: {
    marginLeft: 'auto',
  },
  selectionWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  selectedUser: {
    borderBottom: `1px solid ${primary.bluish7}`,
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(4),
    paddingBottom: spacing(4),
    width: '100%',
    '&:last-of-type': {
      borderBottom: 'none',
      paddingBottom: 0,
      marginBottom: 0,
    },
  },
  closeIconButton: {
    height: 24,
    width: 24,
  },
  closeIcon: {
    height: 16,
    width: 16,
    '& path': {
      fill: primary.bluish1,
    },
  },
  selectAllWrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  selectedUsersWrapper: {
    marginTop: spacing(6),
  },
  selectedUsersWrapperScrollable: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 147,
  },
  scrollYSelected: {
    backgroundColor: primary.bluish9,
    top: 0,
    right: -20,
    height: '100%',
    width: 8,
  },
  scrollSelected: {
    backgroundColor: primary.bluish7,
  },
});

class PeoplePicker extends PureComponent {
  constructor(props) {
    super(props);

    this.inputRef = createRef();
    this.state = {
      isOpen: false,
      anchorEl: null,
      search: '',
      users: props.options,
      isFocused: false,
      selectedUsers: props.preselectedUsers,
    };
  }

  componentDidMount() {
    this.setAnchorEl();
  }

  componentDidUpdate(prevProps, prevState) {
    const { preselectedUsers } = this.props;
    const { isFocused } = this.state;

    if (
      prevProps.preselectedUsers.length !== preselectedUsers.length ||
      (!isArrayEmpty(prevProps.preselectedUsers) &&
        !isArrayEmpty(preselectedUsers) &&
        !isArraySubset(prevProps.preselectedUsers, preselectedUsers))
    ) {
      this.updateSelectedUsers(preselectedUsers);
    }

    if (prevState.isFocused !== isFocused && isFocused) {
      this.getPeople();
    }
  }

  updateSelectedUsers = selectedUsers => this.setState({ selectedUsers });

  handleClose = () => {
    const { preselectedUsers } = this.props;

    this.setState({
      isOpen: false,
      search: '',
      users: [],
      selectedUsers: preselectedUsers,
      isFocused: false,
    });
  };

  setAnchorEl = () => {
    this.setState({ anchorEl: this.inputRef?.current });
  };

  handleOpen = () => {
    const { isOpen } = this.state;

    if (!isOpen) {
      this.setState({ isOpen: true });
    }
  };

  getPeople = (term = '') => {
    const { options } = this.props;
    const searchTerm = trimString(term);
    const users = customSearch(options, searchTerm, true);

    return this.setState({
      users,
    });
  };

  handleChange = event => {
    const { value } = event.target;

    this.setState(
      {
        search: value,
      },
      () => {
        this.handleOpen();
        return this.getPeople(value);
      }
    );
  };

  handleInputFocus = () => {
    this.setState({ isFocused: true, isOpen: true });
  };

  handleSelectAll = allOptions => () => {
    const { selectedUsers } = this.state;

    if (isArraySubset(selectedUsers, allOptions)) {
      this.setState({
        selectedUsers: selectedUsers.filter(
          sUser => !allOptions.some(u => u.id === sUser.id)
        ),
      });
    } else {
      this.setState({
        selectedUsers: getUnionOfTwoArrays(selectedUsers, allOptions),
      });
    }
  };

  handleUserSelect = user => () => {
    const { selectedUsers } = this.state;
    const isSelected = isItemInList(selectedUsers, user);

    if (isSelected) {
      this.setState(prevState => ({
        selectedUsers: prevState.selectedUsers.filter(u => u.id !== user.id),
      }));
    } else {
      this.setState(prevState => ({
        selectedUsers: [...prevState.selectedUsers, user],
      }));
    }
  };

  handleDirectReportSelect = reporters => () => {
    const { selectedUsers } = this.state;
    const isSelected = isArraySubset(selectedUsers, reporters);

    if (isSelected) {
      this.setState(prevState => ({
        selectedUsers: prevState.selectedUsers.filter(
          user => !isItemInList(reporters, user)
        ),
      }));
    } else {
      this.setState(prevState => ({
        selectedUsers: getUnionOfTwoArrays(prevState.selectedUsers, reporters),
      }));
    }
  };

  handleDeselectUser = user => () => {
    const { onSelect } = this.props;

    this.setState(
      prevState => ({
        selectedUsers: prevState.selectedUsers.filter(u => u.id !== user.id),
      }),
      () => onSelect(this.state.selectedUsers)
    );
  };

  handleAddUsers = () => {
    const { preselectedUsers, onSelect } = this.props;
    const { selectedUsers } = this.state;

    const shouldAddUsers = isDirty(preselectedUsers, selectedUsers);

    if (shouldAddUsers) {
      onSelect(selectedUsers);
    }

    this.handleClose();
  };

  renderSuggestions = (hasEmployees, hasAssociates) => {
    const {
      classes,
      translations,
      showDirectReports,
      hasSelectAll,
      blacklistedUsers,
      formattedUsers,
    } = this.props;
    const { users, selectedUsers, isOpen } = this.state;

    if (!isOpen) return null;

    if (!isArrayEmpty(users)) {
      return (
        <TransitionGroup>
          {users.map(user => {
            const isUserSelected = isItemInList(selectedUsers, user);
            const reportsArray = formattedUsers[user.id];
            const directReports = reportsArray
              ? reportsArray.filter(
                  reporter => !blacklistedUsers.includes(reporter.id)
                )
              : [];

            return (
              <Fade key={user.id} unmountOnExit appear addEndListener={null}>
                <div className={classes.item}>
                  <div className={classNames(classes.userItem, classes.hover)}>
                    <UserAvatar
                      className={classes.userAvatar}
                      labelClass={classes.avatarLabel}
                      user={user}
                      onClickHandler={this.handleUserSelect(user)}
                      clickableCaption
                      caption
                      small
                    />
                    <div className={classes.checkboxContainer}>
                      <CustomCheckbox
                        isChecked={isUserSelected}
                        onChange={this.handleUserSelect(user)}
                        isControlled
                      />
                    </div>
                  </div>
                  {showDirectReports && !isArrayEmpty(directReports) ? (
                    <div
                      className={classNames(
                        classes.directReports,
                        classes.hover
                      )}
                    >
                      <DirectReportArrow className={classes.arrowIcon} />
                      <Typography
                        className={classes.directReportText}
                        onClick={this.handleDirectReportSelect(directReports)}
                      >
                        {`${translations.directReports} (${directReports.length})`}
                      </Typography>
                      <div className={classes.checkboxContainer}>
                        <CustomCheckbox
                          isChecked={
                            isArrayEmpty(directReports)
                              ? false
                              : isArraySubset(selectedUsers, directReports)
                          }
                          onChange={this.handleDirectReportSelect(
                            directReports
                          )}
                          isControlled
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </Fade>
            );
          })}
        </TransitionGroup>
      );
    }
    return (
      <Typography
        className={classNames(classes.noResults, {
          [classes.noResultsWithSelectAllEmployees]: hasSelectAll,
          [classes.noResultsWithSelectAll]:
            hasSelectAll && hasEmployees && hasAssociates,
        })}
      >
        {translations.noUserResults}
      </Typography>
    );
  };

  renderSelectedUsers = () => {
    const { classes, preselectedUsers, hasScrollbar } = this.props;

    return (
      <div
        className={classNames(classes.selectedUsersWrapper, {
          [classes.selectedUsersWrapperScrollable]: hasScrollbar,
        })}
      >
        <CustomScrollBar
          customScrollBarYClass={classes.scrollYSelected}
          customScrollClass={classes.scrollSelected}
          verticalScroll={hasScrollbar}
          passContentHeight
          removeScrollX
        >
          <TransitionGroup className={classes.selectionWrapper}>
            {preselectedUsers.map(user => (
              <Fade key={user.id} unmountOnExit addEndListener={null}>
                <div className={classes.selectedUser}>
                  <UserAvatar
                    className={classes.userAvatar}
                    labelClass={classNames(
                      classes.avatarLabel,
                      classes.avatarLabelSelected
                    )}
                    user={user}
                    caption
                    small
                  />
                  <ActionButton
                    className={classes.closeIconButton}
                    iconClass={classes.closeIcon}
                    type={ACTION_BUTTON_TYPES.CLOSE}
                    onClickHandler={this.handleDeselectUser(user)}
                  />
                </div>
              </Fade>
            ))}
          </TransitionGroup>
        </CustomScrollBar>
      </div>
    );
  };

  render() {
    const {
      className,
      classes,
      translations,
      preselectedUsers,
      isDisabled,
      hasError,
      errorMessage,
      showSelection,
      hasSelectAll,
      options,
      dropdownWidth,
    } = this.props;
    const { isOpen, anchorEl, search, users, selectedUsers } = this.state;
    const allEmployees = getEmployees(options);
    const allAssociates = getAssociates(options);

    return (
      <div className={className}>
        <ClickAwayListener
          onClickAway={this.handleClose}
          mouseEvent="onMouseDown"
        >
          <div className={classes.picker}>
            <InputField
              refs={this.inputRef}
              label={translations.label}
              placeholder={translations.placeholder}
              value={search}
              error={hasError}
              errorMessage={errorMessage}
              disabled={isDisabled}
              onChange={this.handleChange}
              onInputFocus={this.handleInputFocus}
              autoComplete="off"
              fullWidth
            />
            <Popper
              className={classes.popperRoot}
              style={{
                width: anchorEl ? anchorEl.clientWidth : null,
              }}
              anchorEl={anchorEl}
              open={isOpen}
              modifiers={{
                offset: { enabled: true, offset: '-12, 0' },
                preventOverflow: {
                  enabled: true,
                  escapeWithReference: true,
                  boundariesElement: 'viewport',
                },
              }}
              popperOptions={{
                onCreate: ({ instance: { reference, popper } }) =>
                  setPopperWidth(reference, popper, dropdownWidth),
                onUpdate: ({ instance: { reference, popper } }) =>
                  setPopperWidth(reference, popper, dropdownWidth),
              }}
              placement="bottom-start"
              disablePortal
            >
              <div className={classes.popperMain}>
                {hasSelectAll && (
                  <>
                    {!isArrayEmpty(allEmployees) && (
                      <div
                        className={classNames(
                          classes.selectAllItem,
                          classes.hover,
                          classes.selectAllWrapper
                        )}
                        onClick={this.handleSelectAll(allEmployees)}
                      >
                        <Typography className={classes.directReportText}>
                          {translations.allEmployees}
                        </Typography>
                        <div className={classes.checkboxContainer}>
                          <CustomCheckbox
                            isChecked={
                              !isArrayEmpty(options) &&
                              isArraySubset(selectedUsers, allEmployees)
                            }
                            onChange={this.handleSelectAll(allEmployees)}
                            isControlled
                          />
                        </div>
                      </div>
                    )}
                    {!isArrayEmpty(allAssociates) && (
                      <div
                        className={classNames(
                          classes.selectAllItem,
                          classes.hover,
                          classes.selectAllWrapper
                        )}
                        onClick={this.handleSelectAll(allAssociates)}
                      >
                        <Typography className={classes.directReportText}>
                          {translations.allAssociates}
                        </Typography>
                        <div className={classes.checkboxContainer}>
                          <CustomCheckbox
                            isChecked={
                              !isArrayEmpty(options) &&
                              isArraySubset(selectedUsers, allAssociates)
                            }
                            onChange={this.handleSelectAll(allAssociates)}
                            isControlled
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
                <div className={classes.popperContent}>
                  <CustomScrollBar
                    customScrollBarYClass={classes.scrollY}
                    scrollBarHeight={getScrollbarHeight(
                      hasSelectAll,
                      allEmployees,
                      allAssociates
                    )}
                    verticalScroll
                    removeScrollX
                  >
                    {this.renderSuggestions(
                      !isArrayEmpty(allEmployees),
                      !isArrayEmpty(allAssociates)
                    )}
                  </CustomScrollBar>
                </div>
                {!isArrayEmpty(users) || hasSelectAll ? (
                  <div className={classes.popoverFooter}>
                    <CustomButton
                      className={classes.selectButton}
                      type="withTextLightRounded"
                      onClick={this.handleAddUsers}
                    >
                      {translations.selectButtonLabel}
                    </CustomButton>
                  </div>
                ) : null}
              </div>
            </Popper>
          </div>
        </ClickAwayListener>
        {showSelection &&
          !isArrayEmpty(preselectedUsers) &&
          this.renderSelectedUsers()}
      </div>
    );
  }
}

PeoplePicker.defaultProps = {
  className: undefined,
  preselectedUsers: [],
  hasError: false,
  errorMessage: '',
  showDirectReports: false,
  isDisabled: false,
  hasSelectAll: false,
  showSelection: false,
  hasScrollbar: false,
  options: [],
  blacklistedUsers: [],
  dropdownWidth: null,
};

PeoplePicker.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  preselectedUsers: PropTypes.arrayOf(PropTypes.shape({})),
  blacklistedUsers: PropTypes.arrayOf(PropTypes.number),
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  dropdownWidth: PropTypes.string,
  isDisabled: PropTypes.bool,
  hasSelectAll: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  showDirectReports: PropTypes.bool,
  showSelection: PropTypes.bool,
  hasScrollbar: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
};

export default withStyles(styles)(PeoplePicker);
