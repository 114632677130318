import {
  isArray,
  isArrayEmpty,
  getObjectToNumberArray,
} from '../../../utility/helpers';
import { getPersonFullName } from '../../../utility/uiUtils';

const getOptions = (options, parser, color, isUser = false) =>
  options.map(option => ({
    ...option,
    value: option[parser.value],
    label: isUser ? getPersonFullName(option) : option[parser.label],
    color: color || option?.color || option?.category?.color,
    isDisabled: option.isDisabled,
    ...(option.action ? { action: option.action } : []),
  }));

export const getParsedOptions = (
  options,
  parser,
  isCategorized,
  isUser = false
) => {
  if (isCategorized) {
    return options.reduce((acc, option) => {
      if (
        isArray(option[parser.categoryValue]) &&
        !isArrayEmpty(option[parser.categoryValue])
      ) {
        return [
          ...acc,
          {
            ...option,
            label: option[parser.categoryLabel],
            options: getOptions(
              option[parser.categoryValue],
              parser,
              option?.color
            ),
          },
        ];
      }

      return acc;
    }, []);
  }

  return getOptions(options, parser, null, isUser);
};

export const getParsedValue = (
  options,
  value,
  isMulti,
  isCategorized,
  parser,
  shouldReturnOption,
  shouldDisableSort
) => {
  const parsedMultipleValue =
    isMulti && shouldReturnOption
      ? getObjectToNumberArray(value, parser.value)
      : value;

  if (isCategorized && isMulti) {
    return options.reduce((acc, category) => {
      const categoryValue = category.options.filter(option =>
        shouldReturnOption
          ? parsedMultipleValue.includes(option.value)
          : value.includes(option.value)
      );

      return [...acc, ...categoryValue];
    }, []);
  }

  if (isMulti) {
    if (shouldReturnOption) {
      return shouldDisableSort
        ? value
        : options.filter(option => parsedMultipleValue.includes(option.value));
    }

    return value && options.filter(option => value.includes(option.value));
  }

  return (
    options.find(option =>
      shouldReturnOption
        ? option.value === value?.[parser.value]
        : option.value === value
    ) || ''
  );
};
