import { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import UsersList from '../../usersList';
import CustomScrollBar from '../../customScrollBar';
import { isArray, isArrayEmpty } from '../../../../utility/helpers';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    paddingBottom: spacing(4),
  },
  users: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 152,
  },
  title: {
    marginBottom: spacing(2),
  },
  scrollY: {
    top: 0,
    right: -21,
    height: '100%',
  },
}));

const Users = ({ title, users, survey }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="subtitle2" className={classes.title}>
        {title}
      </Typography>
      {isArray(users) && !isArrayEmpty(users) ? (
        <div className={classes.users}>
          <CustomScrollBar
            customScrollBarYClass={classes.scrollY}
            verticalScroll
            removeScrollX
            passContentHeight
            passContentWidth
          >
            <UsersList users={users} isVerticalList isSmallUserLabel />
          </CustomScrollBar>
        </div>
      ) : (
        <Typography variant="subtitle2" component="div">
          {survey?.company}
        </Typography>
      )}
    </div>
  );
};

Users.defaultProps = {
  survey: null,
  users: [],
};

Users.propTypes = {
  title: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(PropTypes.object),
  survey: PropTypes.object,
};

export default memo(Users);
