import { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import UserAvatar from '../../userAvatar';
import Report from './report';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  row: {
    backgroundColor: primary.bluish8,
    boxSizing: 'border-box',
    borderRadius: 8,
    display: 'grid',
    gridTemplateColumns: '244px 1fr',
    gridColumnGap: 6,
    padding: spacing(2, 4),
    marginBottom: spacing(2),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  userLabel: {
    fontFamily: 'ProximaNova-Bold',
    fontSize: 16,
    lineHeight: '20px',
  },
  weekReports: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const Body = ({
  translations,
  reports,
  canSeeUserProfile,
  onGoToMeetingDetails,
  onGoToUserProfile,
  onGetWeekReport,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {reports.map(userReport => {
        const isUserAccessible = canSeeUserProfile(userReport.id);

        return (
          <div key={`user_report_${userReport.id}`} className={classes.row}>
            <UserAvatar
              customWrapperClass={classes.user}
              labelClass={classes.userLabel}
              customCaptionContainerClass={classes.userLabelContainer}
              user={userReport}
              clickableCaption={isUserAccessible}
              onClickHandler={() => onGoToUserProfile(userReport.id)}
              caption
              small
            />
            <div className={classes.weekReports}>
              {userReport.weeks.map((weekReport, index) => (
                <Report
                  key={`week_report_${index}`}
                  translations={translations}
                  report={weekReport}
                  hasReport={!!weekReport?.impression}
                  onGoToMeetingDetails={onGoToMeetingDetails}
                  onGetReport={onGetWeekReport(
                    userReport.id,
                    weekReport.weekPeriod
                  )}
                />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

Body.propTypes = {
  translations: PropTypes.object.isRequired,
  reports: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onGoToUserProfile: PropTypes.func.isRequired,
  canSeeUserProfile: PropTypes.func.isRequired,
  onGoToMeetingDetails: PropTypes.func.isRequired,
  onGetWeekReport: PropTypes.func.isRequired,
};

export default memo(Body);
