import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, withStyles } from '@material-ui/core';
import { RECURRING_TYPES } from 'constants/oneOnOne';
import NotificationCard from '../../shared/notificationCard';
import Search from '../../shared/search';
import CustomButton from '../../shared/customButton';
import GridTable from '../../shared/gridTable';
import UserAvatar from '../../shared/userAvatar';
import OneOnOneTimeIndicator from '../../shared/oneOnOneTimeIndicator';
import Impression from '../../shared/impressions/impression';
import DotsMenu from '../../shared/dotsMenu';
import AlertDialog from '../../shared/alertDialog';
import ActionButton from '../../shared/actionButton';
import ManageTasks from '../../shared/manageTasks';
import ManageOneOnOne from '../../shared/manageOneOnOne';
import CustomDateRange from '../../shared/customDateRange';
import Filters from '../../shared/filters';
import RecurringIconTooltip from '../../shared/recuringIconTooltip';
import TasksIconTooltip from '../../shared/tasksIconTooltip';
import PlansIconTooltip from '../../shared/plansIconTooltip';
import RequestedIconTooltip from '../../shared/requestedIconTooltip';
import AddImpressionDialog from '../../shared/addImpressionDialog';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-dark.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-outlined.svg';
import {
  hasNextPage,
  parseDuplicateParameters,
  parseQueryParams,
  showSuccessMessage,
} from '../../../utility/uiUtils';
import { validateFreemiumAction } from '../../../utility/subscriptionHelper';
import {
  getManageOneOnOneSuccessMessages,
  getNextRecurring,
  getOneOnOneTasksAvailableUsers,
} from '../../../utility/oneOnOne';
import http from '../../../utility/http';
import { prepareTasksForSave } from '../../../utility/tasks';
import {
  isArray,
  checkUserRole,
  getObjectToNumberArray,
  isArrayEmpty,
  trimString,
  isObjectEmpty,
} from '../../../utility/helpers';
import { isDateInPast, getStartEndOfDayUtc } from '../../../utility/dateUtils';
import { ACTION_BUTTON_TYPES } from '../../shared/actionButton/config';
import {
  EVENT_ACTION_TYPES,
  tagManagerDataLayer,
} from '../../../utility/tagManager';
import { onSaveCreatableTag } from '../../../utility/tagUtils';
import { hasSelectedFilters } from '../../shared/filters/config';
import { sticky } from '../../../constants/helperCssRules';
import {
  api_one_on_one_record,
  api_one_on_one_topic,
  API_ONE_ON_ONE_TOPICS,
  api_user_one_on_one,
  API_USERS_BASIC,
} from '../../../constants/apiRoutes';
import { GENERAL_CATEGORY_ID } from '../../../constants/tags';
import { FREEMIUM_LIMIT_TYPES } from '../../../constants/appConfig';
import {
  PARAMS,
  ONE_ON_ONE_DEFAULT_PARAMS,
  PAGE_WHITELISTED_PARAMS,
} from '../../../constants/pages';
import { ROLES } from '../../../constants/rolesAndPermissionList';
import { PEOPLE_DEFAULT_ORDERING } from '../../../constants/people';
import {
  getHeaderActions,
  getPageFilters,
  getTableHeaders,
  prepareTableData,
} from './config';

const styles = ({ palette: { primary }, spacing }) => ({
  description: {
    padding: spacing(8, 0, 6, 0),
    ...sticky(primary.white, 105),
  },
  pageActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingBottom: spacing(6),
    ...sticky(primary.white, 179),
  },
  filtersWrapper: {
    display: 'flex',
  },
  filters: {
    alignItems: 'flex-end',
    marginLeft: spacing(2),
  },
  periodFilter: {
    flexShrink: 0,
    width: 260,
  },
  searchWrapper: {
    display: 'flex',
    marginLeft: 'auto',
  },
  search: {
    width: 300,
    marginRight: spacing(2),
  },
  addOneOnOne: {
    width: 'max-content',
  },
  table: {
    '& .grid-table-header': {
      ...sticky(primary.bluish8, 269, 99),
    },
    '& .date-cell': {
      '& .row-cell-value': {
        color: primary.bluish1,
      },
    },
    '& .actions-cell': {
      overflow: 'visible',
    },
  },
  userWrapper: {
    position: 'relative',
    maxWidth: '100%',
  },
  userLabel: {
    fontFamily: 'ProximaNova-Bold',
    fontSize: 16,
    lineHeight: '20px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  actionButton: {
    marginRight: spacing(2),
  },
  impression: {
    marginLeft: spacing(2.75),
  },
  statusGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  recurringIcon: {
    marginLeft: spacing(1.5),
    width: 18,
    height: 18,
  },
  iconTooltip: {
    marginLeft: spacing(1.5),
  },
});

const {
  PAGE,
  PAGE_SIZE,
  COMPLETION_STATUS,
  MEETING_TIME_START,
  MEETING_TIME_END,
  MODERATOR,
  IMPRESSION,
  TAGS,
  START,
  END,
  SEARCH,
} = PARAMS;

class OneOnOnePage extends PureComponent {
  state = {
    isInitialLoad: true,
    isLoading: false,
    [PAGE]: 1,
    period: this.props?.location?.state?.period || null,
    filters: {
      [MODERATOR]: [],
      [IMPRESSION]: [],
      [COMPLETION_STATUS]: [],
      [TAGS]: [],
      ...(this.props.location?.state?.filters
        ? this.props.location.state.filters
        : {}),
    },
    [SEARCH]: '',
    managers: [],
    currentRecordId: null,
    currentRecord: null,
    isAddImpressionOpened: false,
    isManageRecordOpened: false,
    isManageTasksOpened: false,
    isReschedule: false,
    isRequest: false,
    newRecurring: false,
    isDeleteRecordOpened: false,
    isDismissRequestOpened: false,
  };

  componentDidMount() {
    this.getInitialData();
  }

  componentWillUnmount() {
    const {
      clearOneOnOneRecords,
      clearOneOnOneTopics,
      clearTagsCategories,
      clearPageQuickFilters,
    } = this.props;

    clearOneOnOneRecords();
    clearOneOnOneTopics();
    clearTagsCategories();
    clearPageQuickFilters();
  }

  getInitialData = () => {
    const {
      location,
      auth,
      getPageQuickFilters,
      getTagsCategories,
      getOneOnOneTopics,
    } = this.props;
    const isAdmin = checkUserRole(auth.role, ROLES.ADMIN);
    const params = parseQueryParams(
      location.search,
      PAGE_WHITELISTED_PARAMS.ONE_ON_ONE
    );

    if (!isObjectEmpty(params)) {
      return this.setState(
        {
          [SEARCH]: params[SEARCH] || '',
          period:
            params[MEETING_TIME_START] && params[MEETING_TIME_END]
              ? {
                  [START]: params[MEETING_TIME_START],
                  [END]: params[MEETING_TIME_END],
                }
              : null,
          filters: {
            [MODERATOR]: params[MODERATOR]
              ? [
                  ...(isArray(params[MODERATOR])
                    ? params[MODERATOR]
                    : [params[MODERATOR]]),
                ]
              : [],
            [IMPRESSION]: params[IMPRESSION]
              ? [
                  ...(isArray(params[IMPRESSION])
                    ? params[IMPRESSION]
                    : [params[IMPRESSION]]),
                ]
              : [],
            [COMPLETION_STATUS]: params[COMPLETION_STATUS]
              ? [params[COMPLETION_STATUS]]
              : [],
            [TAGS]: params[TAGS]
              ? [...(isArray(params[TAGS]) ? params[TAGS] : [params[TAGS]])]
              : [],
          },
        },
        () => {
          return Promise.all([
            this.handlePageChange(
              false,
              ONE_ON_ONE_DEFAULT_PARAMS[PAGE_SIZE] * params[PAGE]
            ),
            getOneOnOneTopics(),
            getTagsCategories(),
            getPageQuickFilters(),
            ...(isAdmin ? [this.getAllManagers()] : []),
          ]).then(() => {
            this.setState({ isInitialLoad: false });
          });
        }
      );
    }

    return Promise.all([
      this.handlePageChange(),
      getOneOnOneTopics(),
      getTagsCategories(),
      getPageQuickFilters(),
      ...(isAdmin ? [this.getAllManagers()] : []),
    ]).then(() => {
      this.setState({ isInitialLoad: false });
    });
  };

  getAllManagers = async () => {
    const { data } = await http.get(API_USERS_BASIC, {
      params: {
        ...PEOPLE_DEFAULT_ORDERING,
        [PARAMS.EXCLUDE_ROLE]: ROLES.ASSOCIATE,
      },
    });

    return this.setState({
      managers: data,
    });
  };

  getRecordDetails = recordId => http.get(api_one_on_one_record(recordId));

  handlePageChange = (isLoadMore = false, pageSize = 0) => {
    const { history, location, getOneOnOneRecords } = this.props;
    const { page, filters, search, period } = this.state;

    const params = {
      page,
      ...(period
        ? {
            [MEETING_TIME_START]: getStartEndOfDayUtc(period[START]),
            [MEETING_TIME_END]: getStartEndOfDayUtc(period[END], true),
          }
        : {}),
      ...filters,
      ...(search ? { search } : {}),
    };

    const query = parseDuplicateParameters(params);

    if (location.search !== `?${query}`) {
      history.replace(`/1-1/?${query}`);
    }

    return getOneOnOneRecords(isLoadMore, {
      ...(pageSize ? { ...params, [PAGE_SIZE]: pageSize } : params),
    });
  };

  handleOnPeriodChange = period => {
    this.setState(
      { [PAGE]: ONE_ON_ONE_DEFAULT_PARAMS[PAGE], period },
      this.handlePageChange
    );
  };

  handleApplyFilters = filters => {
    this.setState(
      { [PAGE]: ONE_ON_ONE_DEFAULT_PARAMS[PAGE], filters, isLoading: true },
      () =>
        this.handlePageChange().then(() => this.setState({ isLoading: false }))
    );
  };

  onSearch = text => {
    const searchTerm = trimString(text);

    this.setState(
      {
        [PAGE]: ONE_ON_ONE_DEFAULT_PARAMS[PAGE],
        search: searchTerm,
        isLoading: true,
      },
      () =>
        this.handlePageChange().then(() => this.setState({ isLoading: false }))
    );
  };

  onLoadMore = () => {
    return this.setState(
      prevState => ({ ...prevState, [PAGE]: prevState[PAGE] + 1 }),
      () => this.handlePageChange(true)
    );
  };

  handleOpenManageRecord =
    (recordId = null, isReschedule = false, isRequest = false) =>
    async () => {
      if (recordId) {
        const { data } = await this.getRecordDetails(recordId);
        return this.setState({
          isManageRecordOpened: true,
          currentRecord: data,
          isReschedule,
          isRequest,
        });
      }

      return this.setState({
        isManageRecordOpened: true,
      });
    };

  handleCloseManageRecord = () => {
    const { newRecurring } = this.state;

    this.setState(
      {
        isManageRecordOpened: false,
        currentRecordId: null,
        currentRecord: null,
        isReschedule: false,
        isRequest: false,
        newRecurring: false,
        ...(newRecurring ? { [PAGE]: ONE_ON_ONE_DEFAULT_PARAMS[PAGE] } : {}),
      },
      newRecurring ? this.handlePageChange : () => {}
    );
  };

  onSaveRecord = async values => {
    const { translations } = this.props;
    const { currentRecord, isReschedule, isRequest, newRecurring } = this.state;
    const { AddOneOnOne } = EVENT_ACTION_TYPES;
    const { user, hasCustomTopic, customTopic, ...rest } = values;
    const payload = { ...rest };

    if (isReschedule && currentRecord?.id && !newRecurring) {
      await http.delete(api_one_on_one_record(currentRecord.id));
    }

    if (hasCustomTopic) {
      const { data } = customTopic?.id
        ? await http.patch(api_one_on_one_topic(customTopic?.id), {
            name: customTopic?.name,
          })
        : await http.post(API_ONE_ON_ONE_TOPICS, {
            name: customTopic?.name,
          });

      if (data?.id) {
        payload.agenda = [...payload.agenda, data.id];
      }
    }

    if (currentRecord && !isReschedule && !newRecurring) {
      await http.patch(api_one_on_one_record(currentRecord.id), {
        ...payload,
        user: user.id,
      });
    } else {
      await http.post(api_user_one_on_one(user.id), {
        ...payload,
      });

      tagManagerDataLayer(AddOneOnOne.action, AddOneOnOne.name);
    }

    showSuccessMessage(
      getManageOneOnOneSuccessMessages(
        translations.successMessages,
        currentRecord?.id,
        isRequest,
        isReschedule
      )
    );

    return this.setState(
      { [PAGE]: ONE_ON_ONE_DEFAULT_PARAMS[PAGE] },
      this.handlePageChange
    );
  };

  toggleDeleteRecord =
    (recordId = null, updatedState = {}, cb = () => {}) =>
    () => {
      this.setState(
        prevState => ({
          currentRecordId: recordId,
          isDeleteRecordOpened: !prevState.isDeleteRecordOpened,
          ...updatedState,
        }),
        cb
      );
    };

  onDeleteRecord = () => {
    const { translations } = this.props;
    const { currentRecordId } = this.state;

    return http.delete(api_one_on_one_record(currentRecordId)).then(() => {
      showSuccessMessage(translations.deleteOneOnOneRecordDialog.deleteSuccess);
      this.toggleDeleteRecord(
        null,
        {
          [PAGE]: ONE_ON_ONE_DEFAULT_PARAMS[PAGE],
          isManageRecordOpened: false,
          currentRecord: null,
        },
        this.handlePageChange
      )();
    }, this.toggleDeleteRecord());
  };

  toggleDismissRequest =
    (recordId = null, updatedState = {}, cb = () => {}) =>
    () =>
      this.setState(
        prevState => ({
          isDismissRequestOpened: !prevState.isDismissRequestOpened,
          currentRecordId: recordId,
          ...updatedState,
        }),
        cb
      );

  onDismissRequest = () => {
    const { translations } = this.props;
    const { currentRecordId } = this.state;

    return http.delete(api_one_on_one_record(currentRecordId)).then(() => {
      showSuccessMessage(
        translations.dismissOneOnOneRequestDialog.dismissSuccess
      );
      this.toggleDismissRequest(
        null,
        { [PAGE]: ONE_ON_ONE_DEFAULT_PARAMS[PAGE] },
        this.handlePageChange
      )();
    }, this.toggleDismissRequest());
  };

  toggleAddImpression =
    (record = null, updatedState = {}, cb = () => {}) =>
    () =>
      this.setState(
        prevState => ({
          isAddImpressionOpened: !prevState.isAddImpressionOpened,
          currentRecord: record,
          ...updatedState,
        }),
        cb
      );

  toggleAddImpressionDialog = async recordId => {
    await http.get(api_one_on_one_record(recordId)).then(({ data }) => {
      const record = {
        ...data,
        nextRecurring: getNextRecurring(data?.meeting_time, data?.recurring),
      };
      this.toggleAddImpression(record)();
    });
  };

  onSaveImpression = async data => {
    const {
      translations,
      organizationSettings,
      setDialogVisibility,
      getOrganizationSettings,
    } = this.props;
    const { currentRecord } = this.state;
    const { impression, nextRecurring, stoppedRecurring, notify } = data;
    const {
      id,
      recurring,
      user,
      tags,
      agenda,
      shared_note,
      note,
      tasks,
      plans,
    } = currentRecord;
    const { AddOneOnOne } = EVENT_ACTION_TYPES;
    const noteText = note ? note.text : '';

    return http
      .patch(api_one_on_one_record(id), {
        meeting_impression: impression,
        recurring: stoppedRecurring ? RECURRING_TYPES[0].id : recurring,
        tasks: prepareTasksForSave(tasks),
        user: user.id,
      })
      .then(async () => {
        showSuccessMessage(translations.addImpressionDialog.success);
        if (!stoppedRecurring) {
          const params = {
            meeting_time: nextRecurring,
            recurring: stoppedRecurring ? RECURRING_TYPES[0].id : recurring,
            tags: getObjectToNumberArray(tags),
            plans: getObjectToNumberArray(plans),
            agenda: getObjectToNumberArray(agenda),
            shared_note: shared_note || '',
            note: noteText,
            user: user.id,
            notify,
          };
          const isValid = await validateFreemiumAction(
            async () => {
              try {
                await http.post(api_user_one_on_one(user.id), {
                  ...params,
                });
                showSuccessMessage(translations.successMessages.general.create);
                tagManagerDataLayer(AddOneOnOne.action, AddOneOnOne.name);
              } finally {
                this.toggleAddImpression(
                  null,
                  { [PAGE]: ONE_ON_ONE_DEFAULT_PARAMS[PAGE] },
                  this.handlePageChange
                )();
              }
            },
            FREEMIUM_LIMIT_TYPES.ONE_ON_ONE,
            organizationSettings,
            setDialogVisibility,
            getOrganizationSettings
          )();

          if (!isValid) {
            this.toggleAddImpression(
              null,
              { [PAGE]: ONE_ON_ONE_DEFAULT_PARAMS[PAGE] },
              this.handlePageChange
            )();
          }
        } else {
          this.toggleAddImpression(
            null,
            { [PAGE]: ONE_ON_ONE_DEFAULT_PARAMS[PAGE] },
            this.handlePageChange
          )();
        }
      }, this.toggleAddImpression());
  };

  onSaveAndCreate = async (impression, tasks) => {
    const {
      translations,
      organizationSettings,
      setDialogVisibility,
      getOrganizationSettings,
    } = this.props;
    const { currentRecord } = this.state;

    await http
      .patch(api_one_on_one_record(currentRecord.id), {
        meeting_impression: impression,
        tasks: prepareTasksForSave(tasks),
        user: currentRecord.user.id,
      })
      .then(async () => {
        showSuccessMessage(translations.addImpressionDialog.success);
        const isValid = await validateFreemiumAction(
          () =>
            this.setState({
              isAddImpressionOpened: false,
              isManageRecordOpened: true,
              newRecurring: true,
            }),
          FREEMIUM_LIMIT_TYPES.ONE_ON_ONE,
          organizationSettings,
          setDialogVisibility,
          getOrganizationSettings
        )();

        if (!isValid) {
          this.setState(
            {
              [PAGE]: ONE_ON_ONE_DEFAULT_PARAMS[PAGE],
              isAddImpressionOpened: false,
            },
            this.handlePageChange
          );
        }
      }, this.toggleAddImpression());
  };

  getMenuItems = (record, isRequest, isUserActive) => {
    const { translations } = this.props;

    return [
      {
        type: 'edit',
        label: translations.menuItemLabels.edit,
        shouldItemRender: () => !isRequest && isUserActive,
        action: this.handleOpenManageRecord(record.id),
        icon: EditIcon,
      },
      {
        type: 'manageTasks',
        label: translations.menuItemLabels.manageTasks,
        shouldItemRender: () => !isRequest && isUserActive,
        action: () => this.handleOpenManageTasks(record),
        icon: EditIcon,
      },
      {
        type: 'delete',
        label: isRequest
          ? translations.menuItemLabels.dismiss
          : translations.menuItemLabels.delete,
        shouldItemRender: () => {
          return true;
        },
        action: isRequest
          ? this.toggleDismissRequest(record.id)
          : this.toggleDeleteRecord(record.id),
        icon: DeleteIcon,
      },
    ];
  };

  onCreateTag = async tagName => {
    const { addMultipleTags } = this.props;
    const tags = await onSaveCreatableTag(tagName);
    const [tag] = tags.create;

    addMultipleTags({ categoryId: GENERAL_CATEGORY_ID, tags });

    return tag;
  };

  handleOpenManageTasks = record =>
    this.setState({ isManageTasksOpened: true, currentRecord: record });

  handleCloseManageTasks = () =>
    this.setState({ isManageTasksOpened: false, currentRecord: null });

  onSaveTasks = async values => {
    const { translations } = this.props;
    const { currentRecord } = this.state;

    await http.patch(api_one_on_one_record(currentRecord.id), {
      tasks: prepareTasksForSave(values.tasks),
      user: currentRecord.user.id,
    });
    showSuccessMessage(translations.manageTasksDialog.successMessage);

    return this.setState(
      { [PAGE]: ONE_ON_ONE_DEFAULT_PARAMS[PAGE] },
      this.handlePageChange
    );
  };

  renderUser = (user, canAccessProfile) => {
    const { classes } = this.props;

    return (
      <div className={classes.userWrapper}>
        <UserAvatar
          labelClass={classes.userLabel}
          user={user}
          clickableCaption={canAccessProfile}
          caption
          small
        />
      </div>
    );
  };

  renderStatus = (
    time,
    impression,
    previousImpression,
    isRecurring,
    isRequested,
    hasTasks,
    hasPlans
  ) => {
    const { classes, translations } = this.props;
    const isPast = isDateInPast(time);

    if (time && isPast && impression !== null) {
      return (
        <div className={classes.statusGroup}>
          <Impression
            className={classNames({
              [classes.impression]:
                previousImpression === null ||
                previousImpression === impression,
            })}
            value={impression}
            previousImpression={previousImpression}
            isSmall
          />
          {isRequested && (
            <RequestedIconTooltip labels={translations.requested} />
          )}
          <PlansIconTooltip
            className={classes.iconTooltip}
            text={translations.plansTooltip}
            isVisible={hasPlans}
          />
          <TasksIconTooltip
            className={classes.iconTooltip}
            text={translations.tasksTooltip}
            isVisible={hasTasks}
          />
        </div>
      );
    }

    return (
      <div className={classes.statusGroup}>
        <OneOnOneTimeIndicator
          labels={translations.statuses}
          meetingTime={time}
          hasImpression={impression !== null}
        />
        <RecurringIconTooltip
          isVisible={isRecurring}
          text={translations.recurring}
        />
        {isRequested && (
          <RequestedIconTooltip labels={translations.requested} />
        )}
        <PlansIconTooltip
          className={classes.iconTooltip}
          text={translations.plansTooltip}
          isVisible={hasPlans}
        />
        <TasksIconTooltip
          className={classes.iconTooltip}
          text={translations.tasksTooltip}
          isVisible={hasTasks}
        />
      </div>
    );
  };

  renderActions = (
    record,
    isCreatedByCurrentUser,
    hasImpression,
    isRequest,
    isPastMeeting,
    canManageUser,
    isUserActive
  ) => {
    const { classes, translations } = this.props;
    const { ACCEPT, RESOLVE, RESCHEDULE } = ACTION_BUTTON_TYPES;

    if (isCreatedByCurrentUser) {
      return (
        <div className={classes.actions}>
          {isUserActive && !isRequest && isPastMeeting && !hasImpression && (
            <>
              <ActionButton
                className={classes.actionButton}
                type={RESOLVE}
                tooltipText={translations.actionButtons[RESOLVE.toLowerCase()]}
                onClickHandler={() => this.toggleAddImpressionDialog(record.id)}
                isSquared
              />
              {canManageUser && (
                <ActionButton
                  className={classes.actionButton}
                  type={RESCHEDULE}
                  tooltipText={
                    translations.actionButtons[RESCHEDULE.toLowerCase()]
                  }
                  onClickHandler={this.handleOpenManageRecord(record.id, true)}
                  isSquared
                />
              )}
            </>
          )}
          {isUserActive && isRequest && (
            <ActionButton
              className={classes.actionButton}
              type={ACCEPT}
              tooltipText={translations.actionButtons[ACCEPT.toLowerCase()]}
              onClickHandler={this.handleOpenManageRecord(
                record.id,
                false,
                true
              )}
              isSquared
            />
          )}
          <DotsMenu
            menuItems={this.getMenuItems(record, isRequest, isUserActive)}
          />
        </div>
      );
    }

    return null;
  };

  render() {
    const {
      classes,
      translations,
      auth,
      organizationSettings,
      oneOnOne,
      topics,
      categories,
      pageQuickFilters,
      setDialogVisibility,
      getOrganizationSettings,
    } = this.props;
    const {
      isInitialLoad,
      isLoading,
      search,
      period,
      filters,
      managers,
      currentRecord,
      isManageRecordOpened,
      isAddImpressionOpened,
      isManageTasksOpened,
      isRequest,
      isReschedule,
      newRecurring,
      isDeleteRecordOpened,
      isDismissRequestOpened,
    } = this.state;
    const isAdmin = checkUserRole(auth.role, ROLES.ADMIN);
    const isFilterActive = hasSelectedFilters(filters);
    const hasLoadMore = hasNextPage(oneOnOne);

    return (
      !isInitialLoad && (
        <div>
          <div className={classes.description}>
            <Typography variant="body2">{translations.description}</Typography>
          </div>
          <div className={classes.pageActions}>
            <div className={classes.filtersWrapper}>
              <CustomDateRange
                className={classes.periodFilter}
                label={translations.period.label}
                placeholder={translations.period.placeholder}
                initialRange={{
                  startDate: period?.[START],
                  endDate: period?.[END],
                }}
                startAtKey="start"
                endAtKey="end"
                onChange={this.handleOnPeriodChange}
                isClearable
              />
              <Filters
                className={classes.filters}
                translations={translations.filters}
                selectedFilters={filters}
                filters={getPageFilters(
                  translations,
                  isAdmin,
                  categories,
                  pageQuickFilters,
                  managers
                )}
                onApplyFilters={this.handleApplyFilters}
              />
            </div>
            <div className={classes.searchWrapper}>
              <Search
                className={classes.search}
                placeholder={translations.search}
                value={search}
                onChange={this.onSearch}
              />
              <CustomButton
                className={classes.addOneOnOne}
                type="addRoundedNew"
                onClick={validateFreemiumAction(
                  this.handleOpenManageRecord(),
                  FREEMIUM_LIMIT_TYPES.ONE_ON_ONE,
                  organizationSettings,
                  setDialogVisibility,
                  getOrganizationSettings
                )}
              >
                {translations.addOneOnOne}
              </CustomButton>
            </div>
          </div>
          <div>
            {oneOnOne?.results && !isArrayEmpty(oneOnOne?.results) && (
              <GridTable
                className={classes.table}
                translations={translations}
                headerActions={getHeaderActions(translations.columns)}
                headers={getTableHeaders(translations.columns, isAdmin)}
                hasLoadMore={hasLoadMore}
                rows={prepareTableData(
                  oneOnOne.results,
                  this.renderUser,
                  this.renderStatus,
                  this.renderActions,
                  auth,
                  organizationSettings.global_see_himself
                )}
                onLoadMore={this.onLoadMore}
              />
            )}
            {!isLoading &&
              oneOnOne?.results &&
              isArrayEmpty(oneOnOne?.results) && (
                <NotificationCard
                  title={
                    search || isFilterActive || period
                      ? ''
                      : translations.noResults.title
                  }
                  content={
                    search || isFilterActive || period
                      ? translations.noResults.noSearchResults
                      : translations.noResults.message
                  }
                />
              )}
          </div>
          <ManageOneOnOne
            translations={translations.oneOnOneRecordDialog}
            isOpened={isManageRecordOpened}
            topics={topics}
            record={currentRecord}
            createdFor={currentRecord?.user}
            currentUser={auth}
            isOneOnOneRequest={isRequest}
            isReschedule={isReschedule}
            newRecurring={newRecurring}
            isAdmin={isAdmin}
            categories={categories}
            onCreateTag={this.onCreateTag}
            onDelete={this.toggleDeleteRecord(currentRecord?.id)}
            onClose={this.handleCloseManageRecord}
            onSave={this.onSaveRecord}
          />
          {currentRecord && (
            <ManageTasks
              translations={translations.manageTasksDialog}
              isOpened={isManageTasksOpened}
              record={currentRecord}
              availableUsers={getOneOnOneTasksAvailableUsers(currentRecord)}
              onClose={this.handleCloseManageTasks}
              onSave={this.onSaveTasks}
            />
          )}
          <AlertDialog
            translations={translations.deleteOneOnOneRecordDialog}
            isOpened={isDeleteRecordOpened}
            onClose={this.toggleDeleteRecord()}
            onConfirm={this.onDeleteRecord}
            isWarning
          />
          <AlertDialog
            translations={translations.dismissOneOnOneRequestDialog}
            isOpened={isDismissRequestOpened}
            onClose={this.toggleDismissRequest()}
            onConfirm={this.onDismissRequest}
            isWarning
          />
          <AddImpressionDialog
            translations={translations.addImpressionDialog}
            isOpened={isAddImpressionOpened}
            currentRecord={currentRecord}
            recordId={currentRecord?.id}
            onCancel={this.toggleAddImpression()}
            onConfirm={this.onSaveImpression}
            onSaveAndCreate={this.onSaveAndCreate}
          />
        </div>
      )
    );
  }
}

OneOnOnePage.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  organizationSettings: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  oneOnOne: PropTypes.object.isRequired,
  topics: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pageQuickFilters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getOneOnOneRecords: PropTypes.func.isRequired,
  getOneOnOneTopics: PropTypes.func.isRequired,
  clearOneOnOneRecords: PropTypes.func.isRequired,
  clearOneOnOneTopics: PropTypes.func.isRequired,
  getTagsCategories: PropTypes.func.isRequired,
  getPageQuickFilters: PropTypes.func.isRequired,
  addMultipleTags: PropTypes.func.isRequired,
  clearTagsCategories: PropTypes.func.isRequired,
  clearPageQuickFilters: PropTypes.func.isRequired,
  setDialogVisibility: PropTypes.func.isRequired,
  getOrganizationSettings: PropTypes.func.isRequired,
};

export default withStyles(styles)(OneOnOnePage);
