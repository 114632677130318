import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles, Fade } from '@material-ui/core';
import CustomModal from '../customModal';
import UserImport from '../userImport';
import ConditionalTooltip from '../conditionalTooltip';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  content: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    columnGap: 16,
    width: '100%',
    height: 330,
  },
  option: {
    cursor: 'pointer',
    boxSizing: 'border-box',
    border: `2px solid ${primary.bluish4}`,
    borderRadius: 16,
    padding: spacing(4),
    height: '100%',
    transition: 'border .25s ease',
  },
  icon: {
    marginBottom: spacing(2),
    width: 32,
    height: 32,
  },
  spacingBottom: {
    marginBottom: spacing(2),
  },
  selected: {
    border: `2px solid ${primary.blue1}`,
  },
  disabled: {
    cursor: 'default',
    border: `2px solid ${primary.bluish6}`,
  },
  disabledText: {
    color: primary.bluish5,
  },
  actionButtons: {
    marginTop: spacing(8),
  },
  upload: {
    marginTop: spacing(4),
  },
}));

const AddUsersDialog = ({
  translations,
  isOpened,
  isConfirmDisabled,
  options,
  selectedOption,
  usersData,
  onUpload,
  onDeleteUpload,
  onCancel,
  onSelect,
  onConfirm,
}) => {
  const classes = useStyles();

  return (
    <CustomModal
      customButtonsClass={classes.actionButtons}
      title={translations.title}
      confirmButtonLabel={translations.continue}
      closeButtonLabel={translations.cancel}
      isOpened={isOpened}
      isConfirmDisabled={isConfirmDisabled}
      onClose={onCancel}
      onConfirm={onConfirm}
    >
      <div className={classes.content}>
        {options.map(option => {
          const {
            id,
            name,
            description,
            icon: Icon,
            hasImport,
            isDisabled,
            disabledMessage,
          } = option;
          const isActive = selectedOption === id;

          return (
            <ConditionalTooltip
              key={`add_user_option${id}`}
              addTooltip={isDisabled}
              message={disabledMessage}
            >
              <div
                className={classNames(classes.option, {
                  [classes.selected]: isActive,
                  [classes.disabled]: isDisabled,
                })}
                onClick={isDisabled ? () => {} : onSelect(id)}
              >
                <Icon
                  className={classNames(classes.icon, classes.spacingBottom)}
                />
                <div className={classes.spacingBottom}>
                  <Typography
                    className={classNames({
                      [classes.disabledText]: isDisabled,
                    })}
                    variant="h5"
                    component="p"
                  >
                    {name}
                  </Typography>
                </div>
                <Typography
                  className={classNames({ [classes.disabledText]: isDisabled })}
                  variant="body2"
                >
                  {description}
                </Typography>
                {hasImport && (
                  <Fade in={isActive} addEndListener={null} unmountOnExit>
                    <div className={classes.upload}>
                      <UserImport
                        translations={translations.upload}
                        usersData={usersData}
                        onUpload={onUpload}
                        onDelete={onDeleteUpload}
                      />
                    </div>
                  </Fade>
                )}
              </div>
            </ConditionalTooltip>
          );
        })}
      </div>
    </CustomModal>
  );
};

AddUsersDialog.propTypes = {
  translations: PropTypes.object.isRequired,
  isOpened: PropTypes.bool.isRequired,
  isConfirmDisabled: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedOption: PropTypes.string.isRequired,
  usersData: PropTypes.shape({}).isRequired,
  onUpload: PropTypes.func.isRequired,
  onDeleteUpload: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default memo(AddUsersDialog);
