import { withFormik } from 'formik';
import PropTypes from 'prop-types';

import ForgottenPasswordFormLayout from './layout';
import { validateFields } from '../../../utility/validation';
import http from '../../../utility/http';
import { API_REQUEST_RESET_PASSWORD } from '../../../constants/apiRoutes';
import { RESET_PASSWORD_URL } from '../../../constants/appConfig';
import fields from './fields';

const ForgottenPasswordForm = withFormik({
  mapPropsToValues: () => ({ email: '' }),
  validate: async values => {
    throw await validateFields(fields, values);
  },
  validateOnBlur: false,
  handleSubmit: (values, { props, setSubmitting }) => {
    http
      .post(API_REQUEST_RESET_PASSWORD, {
        ...values,
        activationUrl: RESET_PASSWORD_URL,
        clientDomain: window.document.location.origin,
      })
      .then(() => {
        setSubmitting(false);
        props.history.push(`/reset-link-sent/${values.email}`);
      })
      .catch(() => {
        setSubmitting(false);
      });
  },
})(ForgottenPasswordFormLayout);

ForgottenPasswordForm.propTypes = {
  history: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
};

export default ForgottenPasswordForm;
