import { memo } from 'react';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    boxSizing: 'border-box',
    padding: spacing(2),
    height: 40,
  },
}));

const NoOptions = props => {
  const classes = useStyles();

  return (
    <Typography className={classes.root} {...props.innerProps}>
      {props.children}
    </Typography>
  );
};

export default memo(NoOptions);
