import { PureComponent, cloneElement, Children } from 'react';
import PropTypes from 'prop-types';

class VisibleForPermission extends PureComponent {
  renderDisabledChildren = () => {
    const { children, disableOnly, additionalCondition } = this.props;

    return Children.toArray(children).map(child => {
      return cloneElement(child, {
        disabled: disableOnly && additionalCondition,
      });
    });
  };

  render() {
    const {
      children,
      permission,
      permissions,
      disableOnly,
      additionalCondition,
    } = this.props;

    if (permissions.includes(permission)) {
      if (disableOnly) {
        return this.renderDisabledChildren();
      }
      return additionalCondition && children;
    } else if (disableOnly && !permissions.includes(permission)) {
      return this.renderDisabledChildren();
    }

    return null;
  }
}

VisibleForPermission.defaultProps = {
  permission: '',
  permissions: [],
  disableOnly: false,
  additionalCondition: true,
};

VisibleForPermission.propTypes = {
  permission: PropTypes.string,
  permissions: PropTypes.arrayOf(PropTypes.string),
  disableOnly: PropTypes.bool,
  additionalCondition: PropTypes.bool,
};

export default VisibleForPermission;
