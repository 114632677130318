import {
  addDays,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  format,
  startOfYear,
  endOfYear,
  addYears,
  lastDayOfMonth,
} from 'date-fns';

import { createStaticRanges } from 'react-date-range';

const DATE_FORMAT = 'yyyy-LL-dd';

const predefined = {
  startOfWeek: startOfWeek(new Date(), { weekStartsOn: 1 }),
  endOfWeek: endOfWeek(new Date(), { weekStartsOn: 1 }),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7), { weekStartsOn: 1 }),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7), { weekStartsOn: 1 }),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: addMonths(startOfMonth(new Date()), -1),
  endOfLastMonth: lastDayOfMonth(addMonths(new Date(), -1)),
  startOfLast3Months: addMonths(startOfMonth(new Date()), -2),
  endOfLast3Months: endOfMonth(new Date()),
  startOfLast6Months: addMonths(startOfMonth(new Date()), -5),
  endOfLast6Months: endOfMonth(new Date()),
  startOfLast12Months: addMonths(startOfMonth(new Date()), -11),
  endOfLast12Months: endOfMonth(new Date()),
  startOfThisYear: startOfYear(new Date()),
  endOfThisYear: endOfYear(new Date()),
  startOfLastYear: startOfYear(addYears(new Date(), -1)),
  endOfLastYear: endOfYear(addYears(new Date(), -1)),
};

export const customStaticRanges = createStaticRanges([
  {
    label: 'This Week',
    range: () => ({
      startDate: predefined.startOfWeek,
      endDate: predefined.endOfWeek,
    }),
  },
  {
    label: 'Last Week',
    range: () => ({
      startDate: predefined.startOfLastWeek,
      endDate: predefined.endOfLastWeek,
    }),
  },
  {
    label: 'This Month',
    range: () => ({
      startDate: predefined.startOfMonth,
      endDate: predefined.endOfMonth,
    }),
  },
  {
    label: 'Last Month',
    range: () => ({
      startDate: predefined.startOfLastMonth,
      endDate: predefined.endOfLastMonth,
    }),
  },
  {
    label: 'Last 3 Months',
    range: () => ({
      startDate: predefined.startOfLast3Months,
      endDate: predefined.endOfLast3Months,
    }),
  },
  {
    label: 'Last 6 Months',
    range: () => ({
      startDate: predefined.startOfLast6Months,
      endDate: predefined.endOfLast6Months,
    }),
  },
  {
    label: 'Last 12 Months',
    range: () => ({
      startDate: predefined.startOfLast12Months,
      endDate: predefined.endOfLast12Months,
    }),
  },
  {
    label: 'This Year',
    range: () => ({
      startDate: predefined.startOfThisYear,
      endDate: predefined.endOfThisYear,
    }),
  },
  {
    label: 'Last Year',
    range: () => ({
      startDate: predefined.startOfLastYear,
      endDate: predefined.endOfLastYear,
    }),
  },
]);

export const formatDate = (date, dateFormat) =>
  date ? format(new Date(date), dateFormat) : null;

export const formatDateRange = (
  { startDate = '', endDate = '' },
  dateFormat
) => {
  return startDate && endDate
    ? `${formatDate(startDate, dateFormat)} - ${formatDate(
        endDate,
        dateFormat
      )}`
    : '';
};

export const getLast12Months = () => {
  const monthStart = formatDate(predefined.startOfLast12Months, DATE_FORMAT);
  const monthEnd = formatDate(predefined.endOfLast12Months, DATE_FORMAT);
  return {
    start: monthStart,
    end: monthEnd,
  };
};
