import { createAction, handleActions } from 'redux-actions';
import http from '../../utility/http';
import { api_survey_summary } from '../../constants/apiRoutes';

// ------------------------------------
// Constants
// ------------------------------------

const SET_SURVEY_SUMMARY = 'SET_SURVEY_SUMMARY';

const initialState = {};

// ------------------------------------
// Actions
// ------------------------------------

const setSurveySummary = createAction(SET_SURVEY_SUMMARY);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_SURVEY_SUMMARY]: (state, { payload }) => {
    return payload;
  },
};

export const getSurveySummary = (dispatch, summaryId) => {
  return http.get(api_survey_summary(summaryId)).then(({ data }) => {
    dispatch(setSurveySummary(data));
  });
};

export const clearSurveySummary = () => setSurveySummary(initialState);

export default handleActions(reducers, initialState);
