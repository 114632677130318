import { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import CustomModal from '../customModal';
import SelectField from '../selectField';
import { isArrayEmpty } from '../../../utility/helpers';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    height: 306,
  },
  rootSingleSelect: {
    height: 'auto',
  },
  description: {
    marginBottom: spacing(4),
  },
  selectableUsers: {
    width: '100%',
  },
}));

const AssignUsersDialog = ({
  translations,
  isOpened,
  isMulty,
  initialValue,
  shouldResetSelection,
  shouldReturnOption,
  allUsers,
  onCancel,
  onSave,
}) => {
  const classes = useStyles();
  const [users, setUsers] = useState(initialValue);

  const handleSelect = selectedUsers => setUsers(selectedUsers);

  const handleSave = async () => {
    await onSave(users);
    if (shouldResetSelection) {
      setUsers(isMulty ? [] : null);
    }
  };

  const handleCancel = () => {
    if (shouldResetSelection || !initialValue) {
      setUsers(isMulty ? [] : null);
    }

    if (!isMulty && initialValue) {
      setUsers(initialValue);
    }
    onCancel();
  };

  useEffect(() => {
    setUsers(initialValue);
  }, [initialValue]);

  return (
    <CustomModal
      title={translations.title}
      isOpened={isOpened}
      isConfirmDisabled={isArrayEmpty(users)}
      confirmButtonLabel={translations.create}
      closeButtonLabel={translations.cancel}
      onClose={handleCancel}
      onConfirm={handleSave}
      isMedium
    >
      <Grid
        container
        className={classNames(classes.root, {
          [classes.rootSingleSelect]: !isMulty,
        })}
      >
        <Grid item xs={12}>
          {translations.description && (
            <Typography className={classes.description}>
              {translations.description}
            </Typography>
          )}
          <SelectField
            className={classes.selectableUsers}
            label={translations.label}
            placeholder={translations.placeholder}
            value={users}
            options={allUsers}
            parser={{
              value: 'id',
              label: 'first_name',
            }}
            isMulti={isMulty}
            onChange={handleSelect}
            shouldReturnOption={shouldReturnOption}
            isScrollableResult={isMulty}
            isUser
          />
        </Grid>
      </Grid>
    </CustomModal>
  );
};

AssignUsersDialog.defaultProps = {
  isMulty: false,
  initialValue: [],
  shouldReturnOption: false,
  shouldResetSelection: false,
};

AssignUsersDialog.propTypes = {
  translations: PropTypes.object.isRequired,
  isOpened: PropTypes.bool.isRequired,
  allUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isMulty: PropTypes.bool,
  shouldReturnOption: PropTypes.bool,
  shouldResetSelection: PropTypes.bool,
  initialValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default memo(AssignUsersDialog);
