import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Fade, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import CustomButton from '../customButton';
import GridTableHeader from './header';
import GridTableBody from './body';
import { AUTOMATION_ID } from '../../../constants/automationId';

const styles = ({ palette: { primary }, spacing }) => ({
  table: {
    display: 'grid',
  },
  loadMoreWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: spacing(4),
    width: '100%',
  },
  loadMoreButton: {
    border: `1px solid ${primary.bluish3}`,
    borderRadius: 44,
    color: primary.bluish4,
    lineHeight: '24px',
    justifySelf: 'center',
    padding: spacing(1, 4),
    maxHeight: 32,
  },
});

class GridTable extends PureComponent {
  render() {
    const {
      className,
      customHeaderClass,
      translations,
      classes,
      headers,
      rows,
      initialSort,
      preHeaderActions,
      headerActions,
      hasLoadMore,
      onLoadMore,
      onSort,
      onRowClick,
    } = this.props;

    const headerItems = [...preHeaderActions, ...headers, ...headerActions];

    return (
      <div className={classNames(classes.table, className)}>
        <GridTableHeader
          customHeaderClass={customHeaderClass}
          headers={headerItems}
          initialSort={initialSort}
          preHeaderActions={preHeaderActions}
          onSort={onSort}
        />
        <GridTableBody
          headers={headerItems}
          rows={rows}
          onRowClick={onRowClick}
        />
        <Fade in={hasLoadMore} unmountOnExit>
          <div className={classes.loadMoreWrapper}>
            <CustomButton
              id={AUTOMATION_ID.LOAD_MORE_BUTTON}
              type="addWithTextRounded"
              className={classes.loadMoreButton}
              onClick={onLoadMore}
            >
              {translations.loadMore}
            </CustomButton>
          </div>
        </Fade>
      </div>
    );
  }
}

GridTable.defaultProps = {
  className: null,
  translations: {},
  preHeaderActions: [],
  headerActions: [],
  initialSort: {},
  customHeaderClass: null,
  hasLoadMore: false,
  rows: [],
  onRowClick: null,
  onSort: () => {},
  onLoadMore: () => {},
};

GridTable.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.shape({}),
  customHeaderClass: PropTypes.string,
  classes: PropTypes.object.isRequired,
  initialSort: PropTypes.object,
  rows: PropTypes.arrayOf(PropTypes.object),
  preHeaderActions: PropTypes.arrayOf(PropTypes.shape({})),
  headerActions: PropTypes.arrayOf(PropTypes.shape({})),
  hasLoadMore: PropTypes.bool,
  onLoadMore: PropTypes.func,
  onSort: PropTypes.func,
  onRowClick: PropTypes.func,
};

export default withStyles(styles)(GridTable);
