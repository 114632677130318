import { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography, Fade, makeStyles } from '@material-ui/core';
import CustomButton from '../customButton';
import NotificationCard from '../notificationCard';
import SlackUser from './slackUser';
import { ReactComponent as ListIcon } from '../../../assets/icons/no-items.svg';
import { ReactComponent as DeactivatedIcon } from '../../../assets/icons/do_disturb_off.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import { isArrayEmpty } from '../../../utility/helpers';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  title: {
    marginBottom: spacing(2),
  },
  description: {
    borderBottom: `1px solid ${primary.bluish7}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: spacing(4),
    marginBottom: spacing(4),
  },
  button: {
    border: `1px solid ${primary.bluish1}`,
    backgroundColor: primary.white,
    color: primary.bluish1,
    fontFamily: 'ProximaNova-Bold',
    fontSize: 16,
    lineHeight: '24px',
    padding: spacing(1, 4, 1, 3),
    '&:hover': {
      backgroundColor: primary.white,
    },
    '&:disabled': {
      backgroundColor: primary.white,
      border: `1px solid ${primary.bluish5}`,
      color: primary.bluish5,
      '& svg path': {
        fill: primary.bluish5,
      },
    },
  },
  usersSection: {
    marginBottom: spacing(6),
  },
  user: {
    marginBottom: spacing(2),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));

const SlackUsersSync = ({ translations, users, isUserImport, onSync }) => {
  const classes = useStyles();
  const hasUsers = !isArrayEmpty(users);

  return (
    <div>
      <Typography className={classes.title} variant="h5">
        {`${translations.syncLabel} (${users.length})`}
      </Typography>
      <div className={classes.description}>
        <div>
          <Typography variant="body2">
            {translations.descriptionPartOne}
          </Typography>
          <Typography variant="body2">
            {translations.descriptionPartTwo}
          </Typography>
        </div>
        <CustomButton
          className={classes.button}
          type="withTextDarkRounded"
          disabled={!hasUsers}
          startIcon={isUserImport ? <DownloadIcon /> : <DeactivatedIcon />}
          onClick={onSync}
        >
          {translations.buttonLabel}
        </CustomButton>
      </div>
      {hasUsers ? (
        <Fade in>
          <div className={classes.usersSection}>
            {users.map(user => (
              <SlackUser
                key={`slack_user_${user.id}`}
                className={classes.user}
                user={user}
              />
            ))}
          </div>
        </Fade>
      ) : (
        <NotificationCard
          title={translations.noData.title}
          content={translations.noData.content}
          customIcon={ListIcon}
          hasIcon
        />
      )}
    </div>
  );
};

SlackUsersSync.defaultProps = {
  users: [],
  isUserImport: false,
};

SlackUsersSync.propTypes = {
  translations: PropTypes.object.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})),
  isUserImport: PropTypes.bool,
  onSync: PropTypes.func.isRequired,
};

export default memo(SlackUsersSync);
