import { isItemInList, isArrayEmpty } from '../../../utility/helpers';

const SCROLLBAR_HEIGHT = 200;
const SELECT_ALL_HEIGHT = 40;

export const isDirty = (preselectedUsers = [], selectedUsers = []) =>
  preselectedUsers.length !== selectedUsers.length ||
  selectedUsers.some(user => !isItemInList(preselectedUsers, user));

export const setPopperWidth = (reference, popper, dropdownWidth) => {
  popper.style.width = dropdownWidth || `${reference.offsetWidth + 24}px`;
};

export const getScrollbarHeight = (
  hasSelectAll,
  allEmployees,
  allAssociates
) => {
  if (hasSelectAll) {
    const hasAssociates = !isArrayEmpty(allAssociates);
    const hasEmployees = !isArrayEmpty(allEmployees);
    const subtractOptionsHeight =
      hasAssociates && hasEmployees ? SELECT_ALL_HEIGHT * 2 : SELECT_ALL_HEIGHT;

    return SCROLLBAR_HEIGHT - subtractOptionsHeight;
  }

  return SCROLLBAR_HEIGHT;
};
