import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import OrganizationSettingsFormLayout from './layout';
import { validateAll } from '../../../utility/validation';
import fields from './fields';
import { showSuccessMessage } from '../../../utility/uiUtils';

const DEFAULT_FORM_VALUES = {
  name: '',
  question_limit: 20,
  global_see_himself: false,
};

const OrganizationSettingsForm = withFormik({
  mapPropsToValues: props => ({
    ...DEFAULT_FORM_VALUES,
    ...props.initialValues,
  }),
  enableReinitialize: true,
  validate: (values, props) => {
    return validateAll(fields, values, props.translations);
  },
  handleSubmit: (values, { props }) => {
    props.updateOrganizationSettings(values).then(() => {
      showSuccessMessage(props.translations.settingSavedMessage);
    });
  },
})(OrganizationSettingsFormLayout);

OrganizationSettingsForm.propTypes = {
  translations: PropTypes.object.isRequired,
  updateOrganizationSettings: PropTypes.func.isRequired,
};

export default OrganizationSettingsForm;
