import { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Typography,
  Drawer,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import classNames from 'classnames';
import CustomButton from '../customButton';
import CustomScrollBar from '../customScrollBar';
import ActionButton from '../actionButton';
import { AUTOMATION_ID } from '../../../constants/automationId';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';
import { ReactComponent as NoLevelIcon } from '../../../assets/icons/star_dark.svg';
import Tooltip from '../tooltip';
import { handleInstructionGuideDrawer } from '../../../store/modules/instructionGuide';
import { getInstructionGuideMenuItems } from '../../../utility/uiUtils';
import storageService from '../../../utility/storageService';
import { AUTH } from '../../../utility/http';
import { setAuthInfo } from '../../../store/modules/auth';
import { APP_PAGES } from '../../../constants/pages';
import { useTranslations } from '../../../utility/useTranslations';
import { instructionGuideSelector } from '../../../store/selectors/instructionGuideSelector';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  middleWidth: {
    width: 940,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    maxHeight: 71,
    padding: spacing(5, 6),
    borderBottom: `1px solid ${primary.bluish5}`,
  },
  headerMiddleWidth: {},
  title: {
    marginRight: spacing(2),
    wordBreak: 'break-word',
  },
  content: {
    boxSizing: 'border-box',
    height: 'calc(100% - 144px)',
    maxHeight: 'calc(100% - 144px)',
    padding: spacing(6, 1, 0, 6),
  },
  contentMiddleWidth: {},
  headerActions: {
    marginLeft: 'auto',
    display: 'flex',
  },
  scrollY: {
    backgroundColor: primary.bluish9,
    top: 24,
    right: 6,
    height: 'calc(100% - 48px)',
    width: 8,
  },
  scroll: {
    backgroundColor: primary.bluish7,
  },
  scroller: {
    marginRight: spacing(5),
    marginBottom: spacing(5),
  },
  guideMenu: {
    height: '100%',
    display: 'grid',
    alignContent: 'baseline',
    justifyContent: 'flex-start',
    backgroundColor: primary.bluish9,
    border: `solid ${primary.bluish7}`,
    borderWidth: '0px 1px 0px 0px',
    padding: spacing(6, 1, 0, 3),
  },
  guideBlock: {
    height: '100%',
  },
  contentMiddleLargerWidth: {
    display: 'flex',
    padding: 0,
    maxHeight: '100%',
    height: '100%',
  },
  guideButton: {
    justifyContent: 'flex-start',
    width: 270,
    padding: spacing(1, 2, 1, 2),
    borderRadius: 8,
    marginBottom: spacing(6),
    '& span': {
      height: 24,
      color: primary.bluish3,
      textTransform: 'none',
      fontFamily: 'ProximaNova-Bold',
      fontSize: 15,
      fontWeight: 700,
      lineHeight: '24px',
    },
  },
  guideWrapper: {
    padding: spacing(13, 3, 0, 3),
    display: 'grid',
    height: 406,
  },
  initialGuideWrapper: {
    height: 626,
  },
  welcomeTitle: {
    height: 29,
    fontFamily: 'ProximaNova-Bold',
    fontSize: 24,
    fontWeight: 700,
    color: primary.bluish1,
    lineHeight: '29.23px',
  },
  descriptionGuideBlock: {
    height: 220,
    justifyContent: 'space-between',
    display: 'grid',
  },
  descriptionGuide: {
    fontFamily: 'ProximaNova-Regular',
    fontSize: 14,
    fontWeight: 400,
    color: '#41395F',
    lineHeight: '18.2px',
  },
  descriptionGuideBold: {
    fontFamily: 'ProximaNova-Bold',
  },
  videoWrapper: {
    width: 609,
    height: 345,
    padding: spacing(4, 0, 4, 0),
  },
  clickedGuide: {
    backgroundColor: primary.white,
  },
  fullHeight: {
    '& > div': {
      height: '100%',
    },
  },
  guideTooltipIcon: {
    width: 16,
    height: 16,
    marginLeft: spacing(1),
  },
  customForm: {
    display: 'flex',
  },
}));

const { CLOSE_BUTTON } = AUTOMATION_ID;
const INITIAL_VIEW = 0;

const InstructionGuideDrawer = ({ user, organizationSettings }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const translations = useTranslations(APP_PAGES.INSTRUCTION_GUIDE);
  const { instructionGuide } = useSelector(instructionGuideSelector);
  const isFirstTimeLogged = user?.last_login === null;
  const isOpened = isFirstTimeLogged || instructionGuide;

  const contentButtons = getInstructionGuideMenuItems(
    translations.guideButtons,
    user,
    organizationSettings
  );
  const isLargeScreenHeight = useMediaQuery('(min-height:696px)');
  const [guideContent, setGuideContent] = useState(contentButtons[0]);

  const renderContent = () => {
    const isInitialView = guideContent?.id === INITIAL_VIEW;
    return (
      <>
        <div className={classes.guideBlock}>
          <div className={classes.guideMenu}>
            {contentButtons.map(content => {
              const isClickedGuide = content.id === guideContent.id;

              return (
                <CustomButton
                  key={`guide_button_${content.id}`}
                  className={classNames(classes.guideButton, {
                    [classes.clickedGuide]: isClickedGuide,
                  })}
                  type="backNoIcon"
                  onClick={() => setGuideContent(content)}
                >
                  {content.name}
                  {content.hasTooltip ? (
                    <Tooltip
                      customIconClass={classes.guideTooltipIcon}
                      text={translations.guideTooltipText}
                      icon={NoLevelIcon}
                    />
                  ) : null}
                </CustomButton>
              );
            })}
          </div>
        </div>
        <div
          className={classNames(classes.guideWrapper, {
            [classes.initialGuideWrapper]: isInitialView,
          })}
        >
          <Typography className={classes.welcomeTitle} variant="body2">
            {guideContent.name}
          </Typography>
          <div className={classes.videoWrapper}>
            <iframe
              title="guide"
              style={{ border: 0 }}
              width="609"
              height="345"
              src={guideContent.src}
              allowFullScreen
              // eslint-disable-next-line react/no-unknown-property
              allowtransparency="true"
            />
          </div>
          {isInitialView ? (
            <div className={classes.descriptionGuideBlock}>
              <Typography className={classes.descriptionGuide}>
                {translations.descriptionInstructionGuideOne}
              </Typography>
              <Typography className={classes.descriptionGuide}>
                {translations.descriptionInstructionGuideTwo}
              </Typography>
              <Typography className={classes.descriptionGuide}>
                {translations.descriptionInstructionGuideThree}
              </Typography>
              <Typography
                className={classNames(
                  classes.descriptionGuide,
                  classes.descriptionGuideBold
                )}
              >
                {translations.descriptionInstructionGuideFour}
              </Typography>
            </div>
          ) : null}
        </div>
      </>
    );
  };

  const handleClose = () => {
    if (isFirstTimeLogged) {
      const { last_login, ...newAuth } = user;

      storageService.set(AUTH, JSON.stringify(newAuth));
      dispatch(setAuthInfo(newAuth));
    }
    setGuideContent(contentButtons[0]);
    dispatch(handleInstructionGuideDrawer(false));
  };

  return (
    <Drawer
      anchor="right"
      classes={{
        paper: classes.middleWidth,
      }}
      open={isOpened}
      onClose={() => handleClose()}
    >
      <div className={classNames(classes.header, classes.headerMiddleWidth)}>
        <Typography className={classes.title} variant="h4">
          {translations.instructionGuideTitle}
        </Typography>
        <div className={classes.headerActions}>
          <ActionButton
            id={CLOSE_BUTTON}
            type={ACTION_BUTTON_TYPES.CLOSE}
            onClickHandler={() => handleClose()}
          />
        </div>
      </div>
      <CustomScrollBar
        customScrollBarYClass={classNames(classes.scrollY)}
        customScrollClass={classes.scroll}
        customScrollerClass={classNames(classes.scroller, {
          [classes.fullHeight]: isLargeScreenHeight,
        })}
        removeScrollX
        verticalScroll
      >
        <div
          className={classNames(
            classes.content,
            classes.contentMiddleLargerWidth,
            classes.contentMiddleWidth
          )}
        >
          {isOpened ? (
            <div className={classes.customForm}>{renderContent()}</div>
          ) : null}
        </div>
      </CustomScrollBar>
    </Drawer>
  );
};

InstructionGuideDrawer.defaultProps = {};

InstructionGuideDrawer.propTypes = {
  user: PropTypes.object.isRequired,
  organizationSettings: PropTypes.object.isRequired,
};

export default memo(InstructionGuideDrawer);
