import { createAction, handleActions } from 'redux-actions';
import http from '../../utility/http';
import { API_PENDING_SURVEYS } from '../../constants/apiRoutes';

// ------------------------------------
// Constants
// ------------------------------------

const SET_SURVEY_LIST = 'SET_SURVEY_LIST';

const initialState = {
  pendingSurveys: [],
};

// ------------------------------------
// Actions
// ------------------------------------

const setSurveyList = createAction(SET_SURVEY_LIST);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_SURVEY_LIST]: (state, { payload }) => {
    return { ...state, pendingSurveys: payload };
  },
};

// ------------------------------------
// API calls
// ------------------------------------

export const getPendingSurveys = dispatch => {
  return http.get(API_PENDING_SURVEYS).then(({ data }) => {
    dispatch(setSurveyList(data));
  });
};

export default handleActions(reducers, initialState);

// ------------------------------------
// "plain" action functions
// ------------------------------------

export const clearPendingSurveys = dispatch =>
  dispatch(setSurveyList(initialState.pendingSurveys));
