import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, withStyles } from '@material-ui/core';
import AddComment from '../../addComment';
import DeletedUserAvatar from '../../deletedUserAvatar';
import UserAvatar from '../../userAvatar';
import ActionButton from '../../actionButton';
import { getPersonFullName } from '../../../../utility/uiUtils';
import {
  areEqualInMilliseconds,
  formatDate,
} from '../../../../utility/dateUtils';
import { checkUserRole } from '../../../../utility/helpers';
import { ACTION_BUTTON_TYPES } from '../../actionButton/config';
import { ONE_ON_ONE_COMMENT_FIELDS } from '../../../../constants/oneOnOne';
import { ellipsis } from '../../../../constants/helperCssRules';
import { ROLES } from '../../../../constants/rolesAndPermissionList';

const styles = ({ palette: { primary }, spacing }) => ({
  itemWrapper: {
    display: 'flex',
    marginBottom: spacing(10),
  },
  contentWrapper: {
    marginLeft: spacing(4),
  },
  content: {
    width: '100%',
  },
  contentText: {
    lineHeight: '24px',
    ...ellipsis(),
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
  timestamp: {
    color: primary.bluish4,
    marginTop: spacing(3),
    display: 'flex',
    '& > span': {
      fontFamily: 'ProximaNova-Bold',
      marginLeft: spacing(2),
    },
  },
  user: {
    marginLeft: 6,
    '&:nth-of-type(1)': {
      marginLeft: 0,
    },
    '&:nth-of-type(2)': {
      marginRight: 0,
    },
  },
  bold: {
    fontFamily: 'ProximaNova-Bold',
  },
  comment: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  commentActionBlock: {
    display: 'flex',
  },
});

const DATE_FORMAT = 'dd MMM y';
const TIME_FORMAT = 'HH:mm a';

class CommentItem extends PureComponent {
  handleDeleteComment = commentId => () => {
    const { onCommentDelete } = this.props;

    return onCommentDelete(commentId);
  };

  handleEditComment = commentId => () => {
    const { openEditComment } = this.props;

    return openEditComment(commentId);
  };

  renderUser = user => {
    const { classes } = this.props;
    const userClasses = classNames(classes.user, classes.bold);
    return (
      <Typography component="span" className={userClasses}>
        {getPersonFullName(user)}
      </Typography>
    );
  };

  renderTimestamp = () => {
    const { classes, translations, comment } = this.props;
    const { CREATED_AT } = ONE_ON_ONE_COMMENT_FIELDS;
    const editedCommentLabel = areEqualInMilliseconds(
      comment.modified_at,
      comment.created_at
    )
      ? translations.edited
      : '';

    const date = formatDate(comment[CREATED_AT], DATE_FORMAT);
    const time = formatDate(comment[CREATED_AT], TIME_FORMAT);

    return (
      <Typography className={classes.timestamp} variant="caption">
        {`${date} ${translations.at} ${time}`}
        {editedCommentLabel && <span>{editedCommentLabel}</span>}
      </Typography>
    );
  };

  renderContent = () => {
    const { classes, comment, canEditComment, currentUser } = this.props;
    const isAdmin = checkUserRole(currentUser.role, ROLES.ADMIN);

    return (
      <div className={classes.comment}>
        <Typography className={classes.contentText}>
          {comment.comment}
        </Typography>
        <div className={classes.commentActionBlock}>
          {canEditComment && (
            <ActionButton
              type={ACTION_BUTTON_TYPES.EDIT}
              onClickHandler={this.handleEditComment(comment.id)}
            />
          )}
          {canEditComment || isAdmin ? (
            <ActionButton
              type={ACTION_BUTTON_TYPES.DELETE_FILL}
              onClickHandler={this.handleDeleteComment(comment.id)}
            />
          ) : null}
        </div>
      </div>
    );
  };

  render() {
    const {
      classes,
      comment,
      isEdit,
      translations,
      currentUser,
      onSendComment,
      onEditComment,
      closeEditComment,
    } = this.props;
    const { CREATOR } = ONE_ON_ONE_COMMENT_FIELDS;

    const contentClasses = classNames(classes.content, classes.contentWrapper);

    return (
      <>
        {isEdit ? (
          <AddComment
            translations={translations.addComment}
            currentUser={currentUser}
            onSendComment={onSendComment}
            onEditComment={onEditComment}
            isEdit
            comment={comment}
            closeEditComment={closeEditComment}
          />
        ) : (
          <div className={classes.itemWrapper}>
            {comment.creator ? (
              <UserAvatar user={comment.creator} medium />
            ) : (
              <DeletedUserAvatar
                tooltipText={translations.removedUserTooltip}
                isMedium
              />
            )}
            <div className={contentClasses}>
              {this.renderUser(comment[CREATOR])}
              <span>{this.renderContent()} </span>
              {this.renderTimestamp()}
            </div>
          </div>
        )}
      </>
    );
  }
}

CommentItem.defaultProps = {
  isEdit: undefined,
  closeEditComment: () => {},
};

CommentItem.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  translations: PropTypes.shape({}).isRequired,
  comment: PropTypes.shape({}).isRequired,
  canEditComment: PropTypes.bool.isRequired,
  onCommentDelete: PropTypes.func.isRequired,
  openEditComment: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({}).isRequired,
  isEdit: PropTypes.bool,
  closeEditComment: PropTypes.func,
};

export default withStyles(styles)(CommentItem);
