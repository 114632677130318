import { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { LineChart, Line, Tooltip } from 'recharts';
import KadarTheme from 'styles/theme';
import Typography from '@material-ui/core/Typography';
import CustomDot from './customDot';
import { isArrayEmpty } from '../../../utility/helpers';

const styles = ({ palette: { primary }, spacing }) => ({
  tooltipContainer: {
    position: 'relative',
    boxShadow: '0px 4px 44px rgba(0, 0, 0, 0.14)',
    backgroundColor: primary.white,
    borderRadius: 8,
    padding: spacing(2, 4),
    textAlign: 'start',
    boxSizing: 'border-box',
    width: 63,
    display: 'flex',
    justifyContent: 'center',
  },
  tooltipContent: {
    color: primary.bluish2,
    fontFamily: 'ProximaNova-Light',
    letterSpacing: 0.15,
  },
  tooltipArrowDown: {
    backgroundColor: primary.white,
    width: 8,
    height: 8,
    position: 'absolute',
    bottom: -4,
    left: '50%',
    transform: 'translateX(-50%) rotate(45deg)',
  },
});

const COLORS = {
  black: KadarTheme.palette.primary.black,
  gray: KadarTheme.palette.primary.bluish6,
};

const MAX_MOOD = 10;
const DATA_KEY = 'value';
const DOT_HORIZONTAL_OFFSET = 32;
const DOT_VERTICAL_OFFSET = 48;

class GeneralMoodChart extends PureComponent {
  constructor(props) {
    super(props);
    this.lineRef = createRef();
    this.state = {
      activeDot: null,
    };
  }

  onMouseMove = props => {
    const { activeDot } = this.state;

    if (props.isTooltipActive) {
      const currentDot =
        this.lineRef.current.props.points[props.activeTooltipIndex];

      if (activeDot?.x !== currentDot.x) {
        this.setState({
          activeDot: {
            x: currentDot.x - DOT_HORIZONTAL_OFFSET,
            y: currentDot.y - DOT_VERTICAL_OFFSET,
          },
        });
      }
    }
  };

  onMouseLeave = () => this.setState({ activeDot: null });

  renderCustomTooltip = ({ active, payload }) => {
    const { classes } = this.props;
    const [tooltipData] = payload;

    if (active && !isArrayEmpty(payload) && tooltipData?.payload[DATA_KEY]) {
      return (
        <div className={classes.tooltipContainer}>
          <Typography
            component="div"
            variant="caption"
            className={classes.tooltipContent}
          >
            <Typography variant="subtitle1" component="span">
              {tooltipData.value}
            </Typography>
            /{MAX_MOOD}
          </Typography>
          <div className={classes.tooltipArrowDown} />
        </div>
      );
    }

    return null;
  };

  render() {
    const { data } = this.props;
    const { activeDot } = this.state;

    return (
      <LineChart
        width={100}
        height={48}
        data={data}
        margin={{ bottom: 5, left: 5, right: 5, top: 12 }}
        onMouseMove={this.onMouseMove}
        onMouseLeave={this.onMouseLeave}
      >
        <Tooltip
          allowEscapeViewBox={{
            x: true,
            y: true,
          }}
          content={this.renderCustomTooltip}
          cursor={false}
          position={{
            x: activeDot ? activeDot.x : 0,
            y: activeDot ? activeDot.y : 0,
          }}
        />
        <Line
          type="linear"
          dataKey="placeholderValue"
          strokeDasharray="2 2"
          stroke={COLORS.gray}
          strokeWidth={2}
          dot={<CustomDot color={COLORS.gray} />}
          activeDot={<CustomDot color={COLORS.gray} />}
          isAnimationActive={false}
        />
        <Line
          ref={this.lineRef}
          type="linear"
          dataKey={DATA_KEY}
          stroke={COLORS.black}
          dot={<CustomDot color={COLORS.black} />}
          activeDot={<CustomDot color={COLORS.black} />}
          strokeWidth={2}
          isAnimationActive={false}
        />
      </LineChart>
    );
  }
}

GeneralMoodChart.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.number,
      placeholderValue: PropTypes.number,
    })
  ).isRequired,
};

export default withStyles(styles)(GeneralMoodChart);
