import { createAction, handleActions } from 'redux-actions';
import { api_course_users, api_user_courses } from '../../constants/apiRoutes';
import http from '../../utility/http';
import { replaceObjectInList } from '../../utility/helpers';
import { parseDuplicateParameters } from '../../utility/uiUtils';
import { COURSES_DEFAULT_PARAMS } from '../../constants/pages';

// ------------------------------------
// Constants
// ------------------------------------

const SET_USER_COURSES = 'SET_USER_COURSES';
const UPDATE_USER_ENROLLMENT_STATUS = 'UPDATE_USER_ENROLLMENT_STATUS';
const REMOVE_COURSE_FROM_USER_LIST = 'REMOVE_COURSE_FROM_USER_LIST';

const initialState = {
  userCourses: {},
};

// ------------------------------------
// Actions
// ------------------------------------
const setUserCourses = createAction(SET_USER_COURSES);
const updateUserEnrollmentStatusAction = createAction(
  UPDATE_USER_ENROLLMENT_STATUS
);
export const removeCourseFromUserList = createAction(
  REMOVE_COURSE_FROM_USER_LIST
);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_USER_COURSES]: (state, { payload }) => {
    return { ...state, userCourses: payload };
  },
  [REMOVE_COURSE_FROM_USER_LIST]: (state, { payload }) => {
    return {
      ...state,
      userCourses: {
        ...state.userCourses,
        results: state.userCourses?.results?.filter(
          course => course.id !== payload.courseId
        ),
      },
    };
  },
  [UPDATE_USER_ENROLLMENT_STATUS]: (state, { payload }) => {
    const index = state.userCourses?.results?.findIndex(
      enrolled => enrolled.id === payload.courseId
    );
    return {
      ...state,
      userCourses: {
        ...state.userCourses,
        results: replaceObjectInList(state.userCourses.results, index, {
          enrolment_status: payload.status,
        }),
      },
    };
  },
};

// ------------------------------------
// API calls
// ------------------------------------

export const getUserCourses = (dispatch, userId, params = {}) => {
  return http
    .get(api_user_courses(userId), {
      params: { ...COURSES_DEFAULT_PARAMS, ...params },
      paramsSerializer: data => parseDuplicateParameters(data),
    })
    .then(({ data }) => {
      dispatch(setUserCourses(data));
    });
};

export const updateEnrolledUserStatus = (dispatch, params) => {
  const { courseId, userId, statusId } = params;

  return http
    .patch(`${api_course_users(courseId)}${userId}/`, {
      status: statusId,
    })
    .then(({ data }) => {
      const actionPayload = { status: data.enrolment_status, courseId };
      dispatch(updateUserEnrollmentStatusAction(actionPayload));
    });
};

export default handleActions(reducers, initialState);

// ------------------------------------
// "plain" action functions
// ------------------------------------

export const clearUserCourses = () => setUserCourses(initialState.userCourses);
