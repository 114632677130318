import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import Tooltip from '../tooltip';
import { ReactComponent as AnonymousIcon } from '../../../assets/icons/anonymous-user.svg';
import { ReactComponent as OneTimeIcon } from '../../../assets/icons/excluded.svg';
import { ReactComponent as SharedIcon } from '../../../assets/icons/share.svg';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltipLabel: {
    color: primary.bluish2,
  },
  icon: {
    margin: spacing(0, 2),
  },
}));

const SurveyIconInfoTooltip = ({
  className,
  tooltipText,
  isOneTimeSurvey,
  isShared,
}) => {
  const classes = useStyles();

  const icon = useMemo(() => {
    if (isOneTimeSurvey) {
      return OneTimeIcon;
    }

    if (isShared) {
      return SharedIcon;
    }

    return AnonymousIcon;
  }, [isOneTimeSurvey, isShared]);

  return (
    <div className={classNames(classes.root, className)}>
      <Tooltip customIconClass={classes.icon} text={tooltipText} icon={icon} />
    </div>
  );
};

SurveyIconInfoTooltip.defaultProps = {
  className: '',
  isOneTimeSurvey: false,
  isShared: false,
};

SurveyIconInfoTooltip.propTypes = {
  className: PropTypes.string,
  tooltipText: PropTypes.string.isRequired,
  isOneTimeSurvey: PropTypes.bool,
  isShared: PropTypes.bool,
};

export default memo(SurveyIconInfoTooltip);
