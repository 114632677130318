import { getObjectToNumberArray } from './helpers';
import { ALL, COURSE_LEVELS_LIST } from '../constants/courses';
import { COURSE_ENROLLMENT_STATUSES } from '../constants/statuses';

export const prepareEnrollmentStatuses = labels => {
  return COURSE_ENROLLMENT_STATUSES.map(eStatus => ({
    ...eStatus,
    name: labels[eStatus.key],
  }));
};

export const getEnrollmentStatus = (status, statuses) => {
  return statuses.find(s => s.value === status);
};

export const prepareCourses = (courses, statuses) => {
  return courses.map(course => ({
    ...course,
    coverImage: course?.cover_image,
    enrolledUsers: course?.number_of_users,
    ...(statuses
      ? {
          enrollmentStatus: getEnrollmentStatus(
            course.status || course.enrolment_status,
            statuses
          ),
        }
      : {}),
  }));
};

export const prepareCourseData = course => ({
  ...course,
  attribute: course.attribute?.id || null,
  tags: getObjectToNumberArray(course?.tags || []) || [],
});

export const getCourseLevels = labels => {
  return [ALL, ...COURSE_LEVELS_LIST].map(level => ({
    ...level,
    name: labels[level.key],
  }));
};

export const prepareCoursesToEnroll = (
  courses = [],
  enrolledCourses = [],
  statuses = []
) => {
  return courses.map(course => {
    const enrolledCourse = enrolledCourses.find(c => c.id === course.id);

    return {
      ...course,
      coverImage: course.cover_image,
      enrolledUsers: course.number_of_users,
      ...(enrolledCourse
        ? {
            enrollmentStatus: getEnrollmentStatus(
              enrolledCourse.status || enrolledCourse.enrolment_status,
              statuses
            ),
          }
        : {}),
    };
  });
};
