import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MuiAvatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ConditionalTooltip from '../conditionalTooltip';
import { isEllipsisActive } from '../../../utility/helpers';
import { ellipsis } from '../../../constants/helperCssRules';

const styles = ({ palette: { primary }, spacing }) => ({
  main: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '100%',
    minWidth: 0,
  },
  avatarWrapper: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'flex-start',
    textTransform: 'uppercase',
    width: '100%',
  },
  large: {
    width: 64,
    height: 64,
    fontSize: 30,
    fontWeight: 300,
  },
  medium: {
    width: 48,
    height: 48,
    fontSize: 24,
    fontWeight: 300,
  },
  small: {
    fontSize: 10,
    lineHeight: '12px',
    height: 24,
    width: 24,
  },
  extraSmall: {
    height: 20,
    width: 20,
    fontSize: 7,
    lineHeight: '18px',
  },
  rounded: {
    boxShadow: '0px 0px 0px 4px rgba(176, 190, 198, .33)',
    cursor: 'pointer',
  },
  captionContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginLeft: spacing(1.5),
    minWidth: 0,
  },
  captionContainerLarge: {
    maxWidth: 'calc(100% - 70px)',
  },
  captionContainerMedium: {
    maxWidth: 'calc(100% - 54px)',
  },
  captionContainerSmall: {
    maxWidth: 'calc(100% - 30px)',
  },
  captionContainerExtraSmall: {
    maxWidth: 'calc(100% - 26px)',
  },
  caption: {
    textAlign: 'start',
    width: '100%',
    ...ellipsis(),
  },
  accessibleUser: {
    color: primary.blue1,
  },
  captionDescription: {
    textTransform: 'none',
    textAlign: 'start',
    marginTop: spacing(1),
    width: '100%',
    ...ellipsis(),
  },
  anonymousUser: {
    backgroundColor: primary.bluish5,
  },
  clickableAvatar: {
    transition: 'box-shadow .25s ease-in',
    '&:hover': {
      boxShadow: `0 0 0 1px ${primary.white}, 0 0 0 2px ${primary.blue1}`,
    },
  },
  tooltip: {
    cursor: 'pointer',
    overflow: 'hidden',
    maxWidth: '100%',
  },
  tooltipText: {
    textAlign: 'start',
  },
  accessible: {
    cursor: 'pointer',
  },
  organizationUser: {
    boxSizing: 'border-box',
    borderRadius: '50%',
    border: `1px solid ${primary.blue1}`,
    padding: spacing(0.5),
  },
});

class Avatar extends PureComponent {
  constructor(props) {
    super(props);

    this.captionRef = null;
    this.descriptionRef = null;
    this.state = {
      hasCaptionTooltip: false,
      hasDescriptionTooltip: false,
    };
  }

  componentDidMount() {
    this.setState({
      hasCaptionTooltip: isEllipsisActive(this.captionRef),
      hasDescriptionTooltip: isEllipsisActive(this.descriptionRef),
    });
  }

  getCaptionVariant = () => {
    const { variant, small, extraSmall } = this.props;
    if (variant) {
      return variant;
    }
    return extraSmall || small ? 'caption' : 'body1';
  };

  render() {
    const {
      classes,
      className,
      labelClass,
      customWrapperClass,
      customCaptionContainerClass,
      captionDescriptionClass,
      large,
      medium,
      extraSmall,
      small,
      avatarPicture,
      avatarText,
      avatarColor,
      rounded,
      caption,
      withDescription,
      captionDescription,
      anonymousUser,
      onClickHandler,
      clickableCaption,
      disabled,
      customAvatarClass,
      isOrganizationUser,
      automationId,
    } = this.props;
    const { hasCaptionTooltip, hasDescriptionTooltip } = this.state;

    const mainClasses = classNames(
      classes.main,
      {
        [classes.accessible]: !disabled && clickableCaption,
      },
      className
    );
    const avatarClasses = classNames(
      {
        [classes.large]: large,
        [classes.medium]: medium,
        [classes.rounded]: rounded,
        [classes.small]: small,
        [classes.extraSmall]: extraSmall,
        [classes.anonymousUser]: anonymousUser,
        [classes.clickableAvatar]:
          !rounded && !caption && clickableCaption && !disabled,
        [classes.organizationUser]: isOrganizationUser,
      },
      customAvatarClass
    );
    const captionContainerClasses = classNames(
      classes.captionContainer,
      {
        [classes.captionContainerLarge]: large,
        [classes.captionContainerMedium]: medium,
        [classes.captionContainerSmall]: small,
        [classes.captionContainerExtraSmall]: extraSmall,
      },
      customCaptionContainerClass
    );
    const captionClasses = classNames(
      classes.caption,
      { [classes.accessibleUser]: clickableCaption && !disabled },
      labelClass
    );
    const captionDescriptionClasses = classNames(
      classes.captionDescription,
      captionDescriptionClass
    );

    return (
      <div
        id={automationId}
        className={mainClasses}
        onClick={
          clickableCaption && !disabled
            ? event => onClickHandler(event)
            : () => {}
        }
      >
        <div className={classNames(classes.avatarWrapper, customWrapperClass)}>
          {avatarPicture ? (
            <MuiAvatar
              className={avatarClasses}
              src={avatarPicture}
              alt={avatarText}
            />
          ) : (
            <MuiAvatar
              className={avatarClasses}
              style={{
                backgroundColor: !anonymousUser ? avatarColor : undefined,
              }}
            >
              {avatarText}
            </MuiAvatar>
          )}
          {caption ? (
            <div className={captionContainerClasses}>
              <ConditionalTooltip
                className={classes.tooltip}
                customLabelClass={classes.tooltipText}
                addTooltip={hasCaptionTooltip}
                message={caption}
              >
                <Typography
                  ref={element => {
                    this.captionRef = element;
                  }}
                  className={captionClasses}
                  variant={this.getCaptionVariant()}
                >
                  {caption}
                </Typography>
              </ConditionalTooltip>
              {withDescription ? (
                <ConditionalTooltip
                  className={classes.tooltip}
                  customLabelClass={classes.tooltipText}
                  addTooltip={hasDescriptionTooltip}
                  message={captionDescription}
                >
                  <Typography
                    ref={element => {
                      this.descriptionRef = element;
                    }}
                    variant="body2"
                    className={captionDescriptionClasses}
                  >
                    {captionDescription}
                  </Typography>
                </ConditionalTooltip>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

Avatar.defaultProps = {
  className: '',
  labelClass: '',
  customWrapperClass: '',
  customCaptionContainerClass: '',
  customAvatarClass: null,
  captionDescriptionClass: null,
  large: false,
  medium: false,
  extraSmall: false,
  small: false,
  rounded: false,
  avatarPicture: '',
  avatarColor: '',
  caption: '',
  anonymousUser: false,
  clickableCaption: false,
  onClickHandler: () => {},
  disabled: false,
  withDescription: false,
  automationId: undefined,
  isOrganizationUser: false,
  variant: '',
};

Avatar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  labelClass: PropTypes.string,
  customWrapperClass: PropTypes.string,
  customCaptionContainerClass: PropTypes.string,
  customAvatarClass: PropTypes.string,
  captionDescriptionClass: PropTypes.string,
  isOrganizationUser: PropTypes.bool,
  large: PropTypes.bool,
  medium: PropTypes.bool,
  extraSmall: PropTypes.bool,
  rounded: PropTypes.bool,
  avatarPicture: PropTypes.string,
  avatarText: PropTypes.string.isRequired,
  avatarColor: PropTypes.string,
  caption: PropTypes.node,
  onClickHandler: PropTypes.func,
  anonymousUser: PropTypes.bool,
  clickableCaption: PropTypes.bool,
  disabled: PropTypes.bool,
  withDescription: PropTypes.bool,
  automationId: PropTypes.string,
  variant: PropTypes.string,
  small: PropTypes.bool,
};

export default withStyles(styles)(Avatar);
