import { useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import InputField from '../../inputField';
import SelectField from '../../selectField';
import ActionButton from '../../actionButton';
import { ACTION_BUTTON_TYPES } from '../../actionButton/config';
import { TASK_FIELDS } from '../../../../constants/tasks';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'minMax(0,1fr) minMax(0,300px)',
    gridColumnGap: 24,
  },
  field: {
    position: 'relative',
    marginBottom: spacing(6),
  },
  errorMessage: {
    position: 'absolute',
    bottom: -20,
  },
  fieldWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  deleteButton: {
    flexShrink: 0,
    marginLeft: spacing(2),
  },
  dragButton: {
    position: 'relative',
    width: 22,
    height: 22,
    flexShrink: 0,
    marginRight: spacing(1.5),
    zIndex: 11,
    '& button': {
      width: 22,
      height: 22,
    },
  },
  dragIcon: {
    width: 8,
    height: 14,
  },
  dragTooltipLabel: {
    fontFamily: 'ProximaNova-Regular',
  },
}));

const Task = ({
  translations,
  id,
  task,
  error,
  allUsers,
  dragRef,
  dragProps,
  checkCanManageTask,
  onDelete,
  onChange,
}) => {
  const classes = useStyles();
  const [descriptionField, assignToField] = TASK_FIELDS;

  const isDisabled = useMemo(
    () => !checkCanManageTask(task),
    [task, checkCanManageTask]
  );

  const handleChangeDescription = useCallback(
    e => {
      onChange({ ...task, [descriptionField.name]: e.target.value }, id);
    },
    [id, task, descriptionField, onChange]
  );

  const handleChangeAssignee = useCallback(
    assignee => {
      onChange({ ...task, [assignToField.name]: assignee }, id);
    },
    [id, task, assignToField, onChange]
  );

  return (
    <div
      className={classes.root}
      ref={dragRef}
      {...(dragProps ? { ...dragProps.draggableProps } : {})}
    >
      <div key={descriptionField.name} className={classes.field}>
        <div className={classes.fieldWrapper}>
          <ActionButton
            className={classes.dragButton}
            iconClass={classes.dragIcon}
            tooltipLabelClass={classes.dragTooltipLabel}
            type={ACTION_BUTTON_TYPES.DRAG}
            tooltipText={translations.dragTooltip}
            {...(dragProps ? { ...dragProps.dragHandleProps } : {})}
          />
          <InputField
            customErrorClass={classes.errorMessage}
            id={descriptionField.name}
            name={descriptionField.name}
            type={descriptionField.type}
            placeholder={
              translations.labels[descriptionField.translationKey].placeholder
            }
            disabled={isDisabled}
            required={descriptionField.required}
            value={task?.[descriptionField.name] || ''}
            error={!!error?.[descriptionField.name]}
            errorMessage={
              translations.validationMessages?.[error?.[descriptionField.name]]
            }
            onChange={handleChangeDescription}
            fullWidth
          />
        </div>
      </div>
      <div key={assignToField.name} className={classes.field}>
        <div className={classes.fieldWrapper}>
          <SelectField
            errorClass={classes.errorMessage}
            placeholder={
              translations.labels[assignToField.translationKey].placeholder
            }
            required={assignToField.required}
            value={task?.[assignToField.name] || null}
            options={allUsers}
            parser={assignToField.parser}
            hasError={!!error?.[assignToField.name]}
            isDisabled={isDisabled}
            errorMessage={
              translations.validationMessages?.[error?.[assignToField.name]]
            }
            onChange={handleChangeAssignee}
            isUser
            shouldReturnOption
            shouldRemoveLabel
            isFullWidth
          />
          <ActionButton
            className={classes.deleteButton}
            type={ACTION_BUTTON_TYPES.DELETE_FILL}
            isDisabled={isDisabled}
            onClickHandler={onDelete}
          />
        </div>
      </div>
    </div>
  );
};

Task.defaultProps = {
  error: null,
  allUsers: [],
  dragRef: () => {},
  dragProps: null,
  checkCanManageTask: () => true,
};

Task.propTypes = {
  translations: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  task: PropTypes.object.isRequired,
  allUsers: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.object,
  dragProps: PropTypes.object,
  dragRef: PropTypes.func,
  checkCanManageTask: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default memo(Task);
