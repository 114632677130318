import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';

const styles = ({ palette: { primary } }) => ({
  noData: {
    color: primary.bluish4,
  },
});

class NoDataText extends PureComponent {
  render() {
    const { classes, className, text, variant } = this.props;

    return (
      <Typography
        variant={variant}
        className={classNames(classes.noData, className)}
      >
        {text}
      </Typography>
    );
  }
}

NoDataText.defaultProps = {
  className: '',
  variant: 'body1',
};

NoDataText.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  variant: PropTypes.string,
};

export default withStyles(styles)(NoDataText);
