import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette: { primary } }) => ({
  root: {
    color: primary.bluish1,
    cursor: 'default',
    pointerEvents: 'auto',
    fontFamily: 'ProximaNova-Bold',
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    textTransform: 'none',
    wordBreak: 'break-word',
    textDecoration: 'none',
  },
  accessibleLink: {
    cursor: 'pointer',
    color: primary.blue1,
  },
  label: {
    cursor: 'default',
    pointerEvents: 'auto',
  },
}));

const RedirectLink = ({ className, label, path, isAccessible }) => {
  const classes = useStyles();

  const handleClick = e => e.stopPropagation();

  return isAccessible && path ? (
    <Link
      className={classNames(classes.root, classes.accessibleLink, className)}
      to={path}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleClick}
    >
      {label}
    </Link>
  ) : (
    <Typography
      className={classNames(classes.root, className)}
      variant="subtitle2"
      onClick={handleClick}
    >
      {label}
    </Typography>
  );
};

RedirectLink.defaultProps = {
  className: undefined,
  path: '',
  isAccessible: false,
};

RedirectLink.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  path: PropTypes.string,
  isAccessible: PropTypes.bool,
};

export default memo(RedirectLink);
