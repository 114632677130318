import PropTypes from 'prop-types';
import {
  Typography,
  Slider,
  FormHelperText,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(({ spacing }) => ({
  sliderWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  slider: {
    flexGrow: 1,
    maxWidth: 'calc(100% - 60px)',
  },
  sliderThumb: {
    marginLeft: ({ value }) => (value <= 10 ? 0 : -12),
  },
  value: {
    flexShrink: 0,
    marginLeft: spacing(1.5),
    marginTop: spacing(-0.5),
  },
}));

const ProgressField = ({ label, value, isDisabled, onChange }) => {
  const classes = useStyles({ value });

  const handleChange = (e, selectedValue) => {
    onChange(selectedValue);
  };

  return (
    <div>
      {label && <FormHelperText>{label}</FormHelperText>}
      <div className={classes.sliderWrapper}>
        <div className={classes.slider}>
          <Slider
            classes={{ thumb: classes.sliderThumb }}
            value={value}
            disabled={isDisabled}
            onChange={handleChange}
          />
        </div>
        <Typography className={classes.value} variant="subtitle1">
          {`${value}%`}
        </Typography>
      </div>
    </div>
  );
};

ProgressField.defaultProps = {
  label: '',
  value: 0,
  isDisabled: false,
};

ProgressField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default ProgressField;
