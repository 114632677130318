import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, Avatar, withStyles } from '@material-ui/core';
import { ReactComponent as NoAccessIcon } from '../../../assets/icons/domain_disabled.svg';
import CustomLink from '../../shared/customLink';

const styles = ({ palette: { primary }, spacing }) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainAuthorized: {
    boxSizing: 'border-box',
    minHeight: 'calc(100vh - 153px)',
    paddingTop: spacing(12),
  },
  title: {
    marginBottom: spacing(3),
  },
  iconSize: {
    width: 120,
    height: 120,
  },
  avatarSection: {
    marginBottom: '1em',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  avatar: {
    width: 160,
    height: 160,
    backgroundColor: primary.pink1,
  },
});

class UnauthorizedAccessPage extends PureComponent {
  render() {
    const { classes, translations, navigate, isAuthorized } = this.props;
    return (
      <div
        className={classNames(classes.main, {
          [classes.mainAuthorized]: isAuthorized,
        })}
      >
        <div className={classes.avatarSection}>
          <Avatar className={classes.avatar}>
            <NoAccessIcon className={classes.iconSize} />
          </Avatar>
        </div>
        <Typography variant="h1" className={classes.title}>
          {translations.pageTitle}
        </Typography>
        <CustomLink
          text={translations.goHome}
          handleClick={() => navigate('/')}
        />
      </div>
    );
  }
}

UnauthorizedAccessPage.defaultProps = {
  isAuthorized: false,
};

UnauthorizedAccessPage.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isAuthorized: PropTypes.bool,
};

export default withStyles(styles)(UnauthorizedAccessPage);
