import { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import { formatDate } from 'utility/dateUtils';
import { MONTH_DATE_FORMAT } from 'constants/oneOnOne';
import { sticky } from '../../../../constants/helperCssRules';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '260px 1fr',
    gridColumnGap: 6,
    paddingBottom: spacing(2),
    ...sticky(primary.white, 279, 98),
  },
  employee: {
    display: 'flex',
    alignItems: 'center',
  },
  period: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  weeksWrapper: {
    boxSizing: 'border-box',
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: spacing(6),
    paddingRight: spacing(4),
    width: '100%',
    alignItems: 'baseline',
  },
  weekMarker: {
    display: 'flex',
    alignItems: 'flex-end',
    height: 16,
    width: 16,
  },
  month: {
    alignSelf: 'flex-end',
  },
  monthLabel: {
    position: 'absolute',
    top: 0,
    left: 0,
    lineHeight: '24px',
  },
  monthLabelEnd: {
    position: 'absolute',
    top: 0,
    right: 0,
    lineHeight: '24px',
  },
  firstMondayOfMonth: {
    backgroundColor: primary.bluish5,
    borderRadius: 2,
    height: 8,
    width: 8,
  },
}));

const Header = ({ labels, header, periodStart }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.employee}>
        <Typography variant="subtitle2">{labels.employee}</Typography>
      </div>
      <div className={classes.weeksWrapper}>
        {header.map(currentWeek => (
          <div key={`week_${currentWeek.week}`} className={classes.weekMarker}>
            {currentWeek.isFirstMondayInMonth && (
              <div>
                <Typography variant="caption">
                  {formatDate(
                    currentWeek.weekPeriod.meeting_time_before,
                    MONTH_DATE_FORMAT
                  )}
                </Typography>
                <div className={classes.firstMondayOfMonth} />
              </div>
            )}
          </div>
        ))}
        <Typography className={classes.monthLabel} variant="subtitle2">
          {periodStart}
        </Typography>
        <Typography className={classes.monthLabelEnd} variant="subtitle2">
          {labels.today}
        </Typography>
      </div>
    </div>
  );
};

Header.propTypes = {
  labels: PropTypes.object.isRequired,
  header: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  periodStart: PropTypes.string.isRequired,
};

export default memo(Header);
