import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useCSVReader, formatFileSize } from 'react-papaparse';
import { Typography, Fade, makeStyles } from '@material-ui/core';
import TextBoxWithTooltip from '../textBoxWithTooltip';
import CustomButton from '../customButton';
import AsyncListTooltip from '../asyncListTooltip';
import ActionButton from '../actionButton';
import { ReactComponent as UploadIcon } from '../../../assets/icons/cloud_upload.svg';
import { ReactComponent as AcceptedFileIcon } from '../../../assets/icons/cloud_done.svg';
import { ReactComponent as WarningIcon } from '../../../assets/icons/notification_important.svg';
import DemoCSV from '../../../assets/examples/demo.csv';
import { isArrayEmpty } from '../../../utility/helpers';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';
import { CONFIG } from './config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  zone: {
    alignItems: 'center',
    boxSizing: 'border-box',
    border: `1px dashed ${primary.bluish5}`,
    borderRadius: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: 90,
    padding: spacing(4, 3),
    transition: 'border-color .25s ease',
  },
  zoneHover: {
    borderColor: primary.bluish4,
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  message: {
    color: primary.bluish4,
    marginTop: spacing(1),
  },
  messageSelect: {
    color: primary.blue1,
  },
  acceptedFileWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  acceptedFile: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  acceptedFileIcon: {
    flexShrink: 0,
  },
  fileInfo: {
    marginLeft: spacing(2),
  },
  fileName: {
    maxWidth: 154,
  },
  fileSize: {
    color: primary.bluish4,
  },
  deleteButton: {
    flexShrink: 0,
    marginLeft: spacing(2),
    width: 24,
    height: 24,
  },
  name: {
    color: primary.white,
    borderRadius: 3,
    marginBottom: spacing(2),
  },
  progressBar: {
    bottom: 14,
    position: 'absolute',
    width: '100%',
  },
  downloadCsvWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: spacing(4),
  },
  downloadButton: {
    border: `1px solid ${primary.bluish1}`,
    borderRadius: 44,
    display: 'block',
    color: primary.bluish1,
    fontFamily: 'ProximaNova-Bold',
    fontSize: 12,
    lineHeight: '14px',
    padding: '4px 12px',
    maxHeight: 22,
    minHeight: 22,
  },
  notifications: {
    cursor: 'default',
    marginTop: spacing(3),
  },
  notificationItem: {
    display: 'flex',
    alignItems: 'center',
    marginTop: spacing(1),
  },
  users: {
    marginLeft: spacing(1),
  },
}));

const UserImport = ({ translations, usersData, onUpload, onDelete }) => {
  const classes = useStyles();
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const { rowsWithErrors, existingUsers, validUsers } = usersData;
  const hasErrors =
    (rowsWithErrors && !isArrayEmpty(rowsWithErrors)) ||
    (existingUsers && !isArrayEmpty(existingUsers)) ||
    (validUsers && isArrayEmpty(validUsers));

  const handleDeleteFile =
    ({ onClick }) =>
    e => {
      onClick(e);
      onDelete();
    };

  const renderExistingUsers = item => <Typography>{item}</Typography>;

  const renderErrorRows = rows => (
    <div className={classes.errorRows}>
      {rows.map((row, i) => (
        <Typography key={`row_error_${row}`} component="span">
          {`${row} ${i === rows.length - 1 ? '' : ', '}`}
        </Typography>
      ))}
    </div>
  );

  return (
    <div>
      <CSVReader
        config={CONFIG}
        onUploadAccepted={results => {
          onUpload(results);
          setZoneHover(false);
        }}
        onDragOver={event => {
          event.preventDefault();
          setZoneHover(true);
        }}
        onDragLeave={event => {
          event.preventDefault();
          setZoneHover(false);
        }}
      >
        {({ getRootProps, acceptedFile, getRemoveFileProps }) => (
          <>
            <div
              {...getRootProps()}
              className={classNames(classes.zone, {
                [classes.zoneHover]: zoneHover,
              })}
            >
              {acceptedFile ? (
                <div className={classes.acceptedFileWrapper}>
                  <div className={classes.acceptedFile}>
                    {hasErrors ? (
                      <WarningIcon className={classes.acceptedFileIcon} />
                    ) : (
                      <AcceptedFileIcon className={classes.acceptedFileIcon} />
                    )}
                    <div className={classes.fileInfo}>
                      <div className={classes.fileName}>
                        <TextBoxWithTooltip
                          variant="overline"
                          text={acceptedFile.name}
                        >
                          {acceptedFile.name}
                        </TextBoxWithTooltip>
                      </div>
                      <Typography
                        className={classes.fileSize}
                        variant="caption"
                      >
                        {formatFileSize(acceptedFile.size)}
                      </Typography>
                    </div>
                  </div>
                  <ActionButton
                    className={classes.deleteButton}
                    type={ACTION_BUTTON_TYPES.DELETE}
                    onClickHandler={handleDeleteFile(getRemoveFileProps())}
                  />
                </div>
              ) : (
                <div className={classes.infoWrapper}>
                  <UploadIcon />
                  <Typography className={classes.message} variant="overline">
                    {`${translations.initialMessage} `}
                    <Typography
                      className={classes.messageSelect}
                      variant="overline"
                      component="span"
                    >
                      {translations.selectFile}
                    </Typography>
                  </Typography>
                </div>
              )}
            </div>
            {acceptedFile && (
              <div className={classes.notifications}>
                <Fade in unmountOnExit addEndListener={null}>
                  <Typography
                    className={classes.notificationItem}
                    variant="caption"
                    component="div"
                  >
                    {`${translations.validUsers}: `}
                    <Typography className={classes.users} variant="overline">
                      {validUsers.length}
                    </Typography>
                  </Typography>
                </Fade>
                <Fade
                  in={existingUsers && !isArrayEmpty(existingUsers)}
                  unmountOnExit
                  addEndListener={null}
                >
                  <Typography
                    className={classes.notificationItem}
                    variant="caption"
                    component="div"
                  >
                    {`${translations.existingUsers}: `}
                    <AsyncListTooltip
                      title={translations.existingUsersTitle}
                      labelData={existingUsers}
                      renderLabelItem={renderExistingUsers}
                      isDisabled
                    >
                      <Typography className={classes.users} variant="overline">
                        {existingUsers?.length}
                      </Typography>
                    </AsyncListTooltip>
                  </Typography>
                </Fade>
                <Fade
                  in={rowsWithErrors && !isArrayEmpty(rowsWithErrors)}
                  unmountOnExit
                  addEndListener={null}
                >
                  <Typography
                    className={classes.notificationItem}
                    variant="caption"
                    component="div"
                  >
                    {`${translations.errors}:`}
                    <AsyncListTooltip
                      title={translations.errorsTitle}
                      labelData={rowsWithErrors}
                      renderLabelItem={renderErrorRows}
                      isCustomContent
                      isDisabled
                    >
                      <Typography className={classes.users} variant="overline">
                        {rowsWithErrors?.length}
                      </Typography>
                    </AsyncListTooltip>
                  </Typography>
                </Fade>
              </div>
            )}
            {!acceptedFile && (
              <div className={classes.downloadCsvWrapper}>
                <CustomButton
                  className={classes.downloadButton}
                  type="addWithTextRounded"
                  href={DemoCSV}
                  download
                >
                  {translations.downloadCsv}
                </CustomButton>
              </div>
            )}
          </>
        )}
      </CSVReader>
    </div>
  );
};

UserImport.defaultProps = {
  className: '',
  usersData: {},
};

UserImport.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  usersData: PropTypes.shape({}),
  onUpload: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default UserImport;
