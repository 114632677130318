import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import CustomButton from '../../shared/customButton';
import { ReactComponent as ErrorPlaceholder } from '../../../assets/images/error.svg';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  main: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  mainAuthorized: {
    minHeight: 'calc(100vh - 105px)',
  },
  title: {
    color: primary.pink1,
  },
  description: {
    fontSize: 24,
  },
  button: {
    backgroundColor: primary.white,
    border: `1px solid ${primary.bluish1}`,
    color: primary.bluish1,
    borderRadius: 44,
    fontFamily: 'ProximaNova-Bold',
    fontSize: 14,
    lineHeight: '24px',
    minHeight: 40,
    maxHeight: 40,
    marginTop: spacing(3),
  },
  errorPlaceholder: {
    maxHeight: 'calc(100vh - 334px)',
    width: 'auto',
  },
}));

const PageNotFound = ({ translations, navigate, isAuthorized }) => {
  const classes = useStyles();

  const handleGoHome = () => navigate('/');

  return (
    <div
      className={classNames(classes.main, {
        [classes.mainAuthorized]: isAuthorized,
      })}
    >
      <Typography variant="h1" className={classes.title}>
        {translations.pageTitle}
      </Typography>
      <Typography className={classes.description}>
        {translations.description}
      </Typography>
      <CustomButton
        className={classes.button}
        type="addWithTextRounded"
        onClick={handleGoHome}
      >
        {translations.goHome}
      </CustomButton>
      <ErrorPlaceholder className={classes.errorPlaceholder} />
    </div>
  );
};

PageNotFound.defaultProps = {
  isAuthorized: false,
};

PageNotFound.propTypes = {
  translations: PropTypes.object.isRequired,
  isAuthorized: PropTypes.bool,
  navigate: PropTypes.func.isRequired,
};

export default memo(PageNotFound);
