import { useMemo, useState, useEffect, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { RECURRING_TYPES } from 'constants/oneOnOne';
import CustomFormDrawer from '../customFormDrawer';
import { useAvailableUsers } from '../../../utility/hooks';
import http from '../../../utility/http';
import { PARAMS } from '../../../constants/pages';
import { PEOPLE_PARAM_SCOPE_VALUES } from '../../../constants/people';
import { API_PLANS_BASIC } from '../../../constants/apiRoutes';
import {
  getDialogTitle,
  prepareRecordData,
  getNotifyAll,
  getFields,
  prepareDataForSave,
} from './config';

const useStyles = makeStyles(() => ({
  form: {
    display: 'grid',
    gridColumnGap: 24,
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    gridTemplateAreas: `
      "status status"
      "user ."
      "date time"
      "recurring tags"
      "agenda agenda"
      "plans ."
      "sharedNote sharedNote"
      "note note"
      "impression impression"
    `,
  },
  user: {
    gridArea: 'user',
  },
  status: {
    gridArea: 'status',
  },
  date: {
    gridArea: 'date',
  },
  time: {
    gridArea: 'time',
  },
  agenda: {
    gridArea: 'agenda',
  },
  recurring: {
    gridArea: 'recurring',
  },
  sharedNote: {
    gridArea: 'sharedNote',
  },
  tags: {
    gridArea: 'tags',
  },
  plans: {
    gridArea: 'plans',
  },
  privateNote: {
    gridArea: 'note',
  },
  meetingImpression: {
    gridArea: 'impression',
  },
  editorLabelWrapper: {
    justifyContent: 'space-between',
  },
  editorLabel: {
    fontFamily: 'ProximaNova-Bold',
    fontSize: 20,
    lineHeight: '24px',
  },
}));

const ManageOneOnOne = ({
  translations,
  isOpened,
  isReschedule,
  newRecurring,
  isOneOnOneRequest,
  record,
  categories,
  topics,
  createdFor,
  currentUser,
  isAdmin,
  onCreateTag,
  onDelete,
  onClose,
  onSave,
}) => {
  const classes = useStyles();
  const [userPlans, setUserPlans] = useState([]);
  const params = useMemo(() => {
    const { ALL_REPORTS, SHARED_COLLAB } = PEOPLE_PARAM_SCOPE_VALUES;

    return isOpened && !record
      ? {
          [PARAMS.EXCLUDE]: currentUser.id,
          ...(isAdmin ? {} : { scope: [ALL_REPORTS, SHARED_COLLAB] }),
        }
      : {};
  }, [isOpened, record, isAdmin, currentUser.id]);
  const allUsers = useAvailableUsers(params);

  const getUserPlans = useCallback(async userId => {
    const { data } = await http.get(API_PLANS_BASIC, {
      params: { user: userId },
    });

    return setUserPlans(data);
  }, []);

  useEffect(() => {
    if (isOpened && (createdFor || record)) {
      getUserPlans(createdFor?.id || record?.user?.id);
    }

    return setUserPlans([]);
  }, [isOpened, record, createdFor, getUserPlans]);

  const handleSave = values =>
    onSave(prepareDataForSave(values, record, newRecurring));

  return (
    <CustomFormDrawer
      customFormClass={classes.form}
      translations={translations}
      titleText={getDialogTitle(
        translations.title,
        record?.id,
        isOneOnOneRequest,
        isReschedule
      )}
      isOpened={isOpened}
      isInitialValid={
        !!record?.id && !isOneOnOneRequest && !isReschedule && !newRecurring
      }
      initialData={prepareRecordData(
        record,
        createdFor,
        currentUser,
        isOneOnOneRequest,
        isReschedule,
        newRecurring
      )}
      fields={getFields(
        classes,
        translations.formLabels,
        record,
        topics,
        createdFor,
        onCreateTag,
        getUserPlans,
        isReschedule,
        newRecurring
      )}
      userPlans={userPlans}
      allUsers={allUsers}
      categories={categories}
      recurring={RECURRING_TYPES}
      notifyAll={getNotifyAll(createdFor)}
      onDelete={onDelete}
      onClose={onClose}
      onSave={handleSave}
      isMiddleWidth
    />
  );
};

ManageOneOnOne.defaultProps = {
  isOneOnOneRequest: false,
  isReschedule: false,
  newRecurring: false,
  record: null,
  createdFor: null,
  isAdmin: false,
  onDelete: () => {},
};

ManageOneOnOne.propTypes = {
  translations: PropTypes.object.isRequired,
  isOpened: PropTypes.bool.isRequired,
  topics: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  record: PropTypes.shape({}),
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  createdFor: PropTypes.shape({}),
  currentUser: PropTypes.shape({}).isRequired,
  isAdmin: PropTypes.bool,
  isOneOnOneRequest: PropTypes.bool,
  isReschedule: PropTypes.bool,
  newRecurring: PropTypes.bool,
  onCreateTag: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default memo(ManageOneOnOne);
