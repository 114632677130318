import { memo, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core';
import {
  getOptions,
  xAxisLabelButton,
  chartXAreaBorder,
  customLabelXHover,
  SCORE_LABELS,
  getLabelBoxPosition,
} from './config';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

const useStyles = makeStyles(() => ({
  root: {
    height: 300,
  },
}));

const CompetenceMapChart = ({
  className,
  yAxisLabel,
  data,
  selectedScores,
  onScoreSelect,
}) => {
  const chartRef = useRef();
  const classes = useStyles();
  const chartOptions = useMemo(
    () => getOptions(yAxisLabel, selectedScores, data),
    [selectedScores, data, yAxisLabel]
  );

  const handleScoreSelect = e => {
    const selectedScore = getLabelBoxPosition(e, chartRef.current, data);

    if (selectedScore !== undefined) {
      onScoreSelect(selectedScore);
    }
  };

  return (
    <div className={classNames(classes.root, className)}>
      <Bar
        ref={chartRef}
        options={chartOptions}
        data={{ labels: SCORE_LABELS, ...data }}
        plugins={[xAxisLabelButton, chartXAreaBorder, customLabelXHover]}
        onClick={handleScoreSelect}
      />
    </div>
  );
};

CompetenceMapChart.defaultProps = {
  className: undefined,
  selectedScores: [],
};

CompetenceMapChart.propTypes = {
  className: PropTypes.string,
  yAxisLabel: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  selectedScores: PropTypes.arrayOf(PropTypes.number),
  onScoreSelect: PropTypes.func.isRequired,
};

export default memo(CompetenceMapChart);
