import { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Fade, FormHelperText, makeStyles } from '@material-ui/core';
import ReorderItems from '../reorderItems';
import Task from './task';
import CustomButton from '../customButton';
import {
  isArrayEmpty,
  replaceObjectInList,
  reorder,
} from '../../../utility/helpers';
import { NEW_TASK } from '../../../constants/tasks';

const useStyles = makeStyles(({ spacing }) => ({
  label: {
    fontFamily: 'ProximaNova-Bold',
    fontSize: 20,
    lineHeight: '24px',
    marginBottom: spacing(4),
    marginRight: spacing(1),
  },
  header: {
    display: 'grid',
    gridTemplateColumns: 'minMax(0,1fr) minMax(0,300px)',
    gridColumnGap: 24,
  },
  headerItem: {
    marginBottom: spacing(2),
  },
  headerItemDescription: {
    paddingLeft: spacing(7),
  },
}));

const TasksField = ({
  translations,
  tasks,
  allUsers,
  error,
  checkCanManageTask,
  onChange,
}) => {
  const classes = useStyles();
  const hasTasks = !isArrayEmpty(tasks);

  const handleAddTask = useCallback(() => {
    return onChange([...tasks, { ...NEW_TASK }]);
  }, [tasks, onChange]);

  const handleDeleteTask = useCallback(
    taskIndex => () => {
      const updatedTasks = tasks.filter((task, index) => index !== taskIndex);

      return onChange(updatedTasks);
    },
    [tasks, onChange]
  );

  const handleReorderTasks = useCallback(
    result => {
      if (
        !result.destination ||
        result.source.index === result.destination.index
      )
        return;

      const updatedOrder = reorder(
        tasks,
        result.source.index,
        result.destination.index
      );

      return onChange(updatedOrder);
    },
    [tasks, onChange]
  );

  const handleChangeTask = useCallback(
    (task, taskIndex) => {
      onChange(replaceObjectInList(tasks, taskIndex, task));
    },
    [tasks, onChange]
  );

  return (
    <div>
      {translations.label && (
        <FormHelperText classes={{ root: classes.label }}>
          {translations.label}
        </FormHelperText>
      )}
      <Fade in={hasTasks} unmountOnExit>
        <div className={classes.header}>
          <FormHelperText
            classes={{
              root: classNames(
                classes.headerItem,
                classes.headerItemDescription
              ),
            }}
          >
            {translations.headers.description}
          </FormHelperText>
          <FormHelperText
            classes={{
              root: classes.headerItem,
            }}
          >
            {translations.headers.assignTo}
          </FormHelperText>
        </div>
      </Fade>
      <ReorderItems onSave={handleReorderTasks}>
        {tasks.map((task, index) => (
          <Task
            key={`task_item_${index}`}
            id={index}
            translations={translations.task}
            task={task}
            error={error?.[index]}
            allUsers={allUsers}
            checkCanManageTask={checkCanManageTask}
            onDelete={handleDeleteTask(index)}
            onChange={handleChangeTask}
          />
        ))}
      </ReorderItems>
      <CustomButton type="addDarkRoundedOutlined" onClick={handleAddTask}>
        {translations.addTaskButton}
      </CustomButton>
    </div>
  );
};

TasksField.defaultProps = {
  tasks: [],
  allUsers: [],
  error: null,
  checkCanManageTask: () => true,
};

TasksField.propTypes = {
  translations: PropTypes.object.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.object),
  allUsers: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.object,
  checkCanManageTask: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

export default memo(TasksField);
