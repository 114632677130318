import { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  TextField,
  FormHelperText,
  InputAdornment,
  withStyles,
} from '@material-ui/core';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search-dark.svg';

const styles = ({ palette: { primary } }) => ({
  main: {
    maxWidth: 410,
  },
  searchRoot: {
    backgroundColor: primary.white,
    border: `1px solid ${primary.bluish7}`,
    color: primary.bluish1,
    borderRadius: 44,
    '&$focused': {
      border: `1px solid ${primary.blue1}`,
    },
  },
  rootFormField: {
    border: `1px solid ${primary.bluish6}`,
    borderRadius: 4,
  },

  focused: {},
});

const DELAY = 500;

class Search extends PureComponent {
  inputTimer = null;

  constructor(props) {
    super(props);

    this.inputRef = createRef();
  }

  componentDidMount() {
    this.inputRef.current.value = this.props.value;
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;

    if (prevProps.value && value === '') {
      this.inputRef.current.value = value;
    }
  }

  onChange = event => {
    const { onChange } = this.props;
    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(
      onChange.bind(this, event.target.value),
      DELAY
    );
  };

  renderAdornment = () => (
    <InputAdornment position="start">
      <SearchIcon />
    </InputAdornment>
  );

  render() {
    const { classes, className, disabled, placeholder, label, isFormField } =
      this.props;
    return (
      <div className={classNames(classes.main, className)}>
        {label && <FormHelperText>{label}</FormHelperText>}
        <TextField
          placeholder={placeholder}
          onChange={this.onChange}
          InputProps={{
            disableUnderline: true,
            classes: {
              root: classNames(classes.searchRoot, {
                [classes.rootFormField]: isFormField,
              }),
              focused: classes.focused,
            },
            startAdornment: this.renderAdornment(),
          }}
          inputRef={this.inputRef}
          disabled={disabled}
          fullWidth
        />
      </div>
    );
  }
}

Search.defaultProps = {
  className: null,
  placeholder: '',
  disabled: false,
  isFormField: false,
  label: '',
  value: '',
};

Search.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  isFormField: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(Search);
