import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { getAnswerWeight } from 'utility/attribute';
import CustomScrollBar from '../customScrollBar';
import ToggleButtonSwitch from '../toggleButtonSwitch';
import GridRow from './gridRow';
import {
  getReviewGridColumns,
  getToggleReportViewItems,
} from '../../../utility/reportUtils';
import { getColumnSizes } from './config';
import { NEUTRAL_ANSWER_WEIGHT } from '../questionEntry/config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    backgroundColor: primary.bluish9,
    boxSizing: 'border-box',
    borderRadius: 8,
    padding: spacing(6),
  },
  gridTable: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 538,
    minHeight: 234,
  },
  content: {
    display: 'grid',
    gridRowGap: 8,
    width: '100%',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: spacing(7),
  },
  toggleSwitchWrapper: {
    width: 253,
  },
  grid: {
    display: 'grid',
    gridColumnGap: 8,
    gridTemplateColumns: getColumnSizes,
    gridTemplateRows: '1fr min-content',
  },
  header: {
    backgroundColor: primary.bluish9,
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: 100,
    '&::after': {
      backgroundColor: primary.bluish9,
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: -8,
      width: '100%',
      height: 8,
    },
  },
  headerCell: {
    backgroundColor: primary.bluish7,
    boxSizing: 'border-box',
    borderRadius: 4,
    padding: spacing(3, 4),
    '&:first-of-type': {
      backgroundColor: primary.bluish9,
      borderRadius: 'initial',
      position: 'sticky',
      left: 0,
      padding: spacing(3, 2, 3, 0),
      '&::after': {
        backgroundColor: primary.bluish9,
        content: '""',
        position: 'absolute',
        top: 0,
        right: -8,
        width: 8,
        height: 38,
      },
    },
  },
  body: {
    position: 'relative',
    zIndex: 99,
    display: 'grid',
    gridRowGap: 8,
    width: '100%',
  },
  scrollY: {
    backgroundColor: primary.bluish9,
    top: 0,
    right: -16,
    height: '100%',
    width: 8,
  },
  scrollX: {
    backgroundColor: primary.bluish9,
    left: 208,
    bottom: -16,
    width: 'calc(100% - 208px)',
    height: 8,
  },
}));

const ReportAnswerGrid = ({
  className,
  translations,
  totalAnswers,
  rows,
  attributeColor,
  results,
  isPercentageViewInitial,
  hasNeutralAnswer,
  hasPercentageView,
}) => {
  const [isPercentageView, setPercentageView] = useState(
    isPercentageViewInitial
  );
  const columns = getReviewGridColumns(
    translations,
    totalAnswers,
    hasNeutralAnswer
  );
  const switchItems = getToggleReportViewItems(translations);
  const hasAverageScore = Boolean(Object.keys(results)?.length);
  const classes = useStyles({ columns });

  const handleChangeView = value => {
    if (value !== isPercentageView) {
      setPercentageView(value);
    }
  };

  const renderHeader = () => (
    <div className={classNames(classes.grid, classes.header)}>
      {columns.map((column, index) => (
        <div key={`header_column_${index}`} className={classes.headerCell}>
          <Typography variant="overline">{column.name}</Typography>
        </div>
      ))}
    </div>
  );

  const hasOnlyNeutralAnswer = row => {
    if (!results?.[row.id]) {
      return false;
    }

    const allResults = Object.keys(results?.[row.id]);
    const numberOfResults = allResults?.length;
    const singleAnswer = row.answers.find(
      answer => answer.id === parseInt(allResults[0], 10)
    );

    return Boolean(
      numberOfResults > 1 ||
        (numberOfResults === 1 &&
          getAnswerWeight(singleAnswer.weight) !== NEUTRAL_ANSWER_WEIGHT)
    );
  };

  const renderBody = () => (
    <div className={classes.body}>
      {rows.map(row => {
        return (
          <GridRow
            key={`row_${row.id}`}
            className={classes.grid}
            attributeColor={attributeColor}
            translations={translations}
            hasAverageScore={hasAverageScore && hasOnlyNeutralAnswer(row)}
            hasNeutralAnswer={hasNeutralAnswer}
            hasPercentageView={hasPercentageView}
            isPercentageView={isPercentageView}
            row={row}
            result={results?.[row.id]}
          />
        );
      })}
    </div>
  );

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.titleWrapper}>
        <Typography variant="h4">{translations.answers}</Typography>
        {hasPercentageView && (
          <div className={classes.toggleSwitchWrapper}>
            <ToggleButtonSwitch
              value={isPercentageView}
              items={switchItems}
              onSelect={handleChangeView}
              isFullWidth
            />
          </div>
        )}
      </div>
      <div className={classes.gridTable}>
        <CustomScrollBar
          customScrollBarXClass={classes.scrollX}
          customScrollBarYClass={classes.scrollY}
          passContentHeight
          verticalScroll
        >
          <div className={classes.content}>
            {renderHeader()}
            {renderBody()}
          </div>
        </CustomScrollBar>
      </div>
    </div>
  );
};

ReportAnswerGrid.defaultProps = {
  className: undefined,
  isPercentageViewInitial: false,
  attributeColor: '',
};

ReportAnswerGrid.propTypes = {
  className: PropTypes.string,
  attributeColor: PropTypes.string,
  translations: PropTypes.object.isRequired,
  hasNeutralAnswer: PropTypes.bool.isRequired,
  hasPercentageView: PropTypes.bool.isRequired,
  isPercentageViewInitial: PropTypes.bool,
  totalAnswers: PropTypes.number.isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  results: PropTypes.shape({}).isRequired,
};

export default memo(ReportAnswerGrid);
