import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import RichTextPreview from 'components/shared/richTextPreview';
import ActionButton from 'components/shared/actionButton';
import SectionTitle from 'components/shared/sectionTitle';
import { ACTION_BUTTON_TYPES } from 'components/shared/actionButton/config';
import UsersComments from 'components/shared/usersComments';
import { ACTION_PLAN_COMMENTS_DEFAULT_PARAMS } from 'constants/actionPlan';
import AlertDialog from '../../shared/alertDialog';
import ManageActionPlan from '../../shared/manageActionPlan';
import ActionPlanDetailsCard from '../../shared/actionPlanDetailsCard';
import ManageTasks from '../../shared/manageTasks';
import CustomButton from '../../shared/customButton';
import {
  isValidParameter,
  replacePropsInObject,
  checkUserRole,
  isUserProfileAccessible,
  isUserSuspended,
  trimString,
} from '../../../utility/helpers';
import http from '../../../utility/http';
import { showSuccessMessage } from '../../../utility/uiUtils';
import { onSaveCreatableTag } from '../../../utility/tagUtils';
import { prepareTasksForSave } from '../../../utility/tasks';
import {
  EVENT_ACTION_TYPES,
  tagManagerDataLayer,
} from '../../../utility/tagManager';
import {
  api_action_plan,
  api_action_plan_tasks,
  api_action_plan_comment,
  api_action_plan_comment_update,
} from '../../../constants/apiRoutes';
import { getActionPlanStatuses } from '../../../utility/actionPlans';
import { ROLES } from '../../../constants/rolesAndPermissionList';
import { GENERAL_CATEGORY_ID } from '../../../constants/tags';

const styles = ({ palette: { primary }, spacing }) => ({
  root: {
    marginTop: spacing(8),
  },
  activity: {
    marginRight: 54,
  },
  subtitle: {
    margin: spacing(8, 0, 4, 0),
  },
  notes: {
    position: 'relative',
    boxSizing: 'border-box',
    border: `1px solid ${primary.bluish6}`,
    padding: spacing(8),
    minHeight: 150,
  },
  editDescriptionButton: {
    position: 'absolute',
    top: 16,
    right: 16,
  },
  type: {
    marginRight: spacing(4),
  },
  loadMoreContainer: {
    marginLeft: spacing(16),
  },
  loadMore: {
    border: `1px solid ${primary.bluish3}`,
    borderRadius: 44,
    color: primary.bluish4,
    lineHeight: '24px',
    justifySelf: 'center',
    padding: spacing(1, 4),
    maxHeight: 32,
  },
  wrap: {
    margin: spacing(8, 0, 10, 0),
  },
});

class ActionPlanDetailsPage extends PureComponent {
  state = {
    isLoading: true,
    isEditOpened: false,
    isDeleteOpened: false,
    isManageTasksOpened: false,
    isDismissRequestOpened: false,
    commentId: 0,
    page: 1,
  };

  componentDidMount() {
    const {
      matchedActionPlanId,
      getActionPlan,
      getActionPlanComments,
      getTagsCategories,
      getAllUsers,
    } = this.props;

    if (isValidParameter(matchedActionPlanId)) {
      return Promise.all([
        getActionPlan(matchedActionPlanId),
        getActionPlanComments(matchedActionPlanId),
        getTagsCategories(),
        getAllUsers(),
      ]).then(() => {
        this.setState({ isLoading: false });
        const { VisitAPDP } = EVENT_ACTION_TYPES;
        tagManagerDataLayer(VisitAPDP.action, VisitAPDP.name);
      });
    }
  }

  componentWillUnmount() {
    const {
      clearActionPlan,
      clearActionPlanComments,
      clearTagsCategories,
      clearAllUsers,
      clearCourses,
      clearCourseEnrolledUsers,
    } = this.props;

    clearActionPlan();
    clearActionPlanComments();
    clearTagsCategories();
    clearAllUsers();
    clearCourses();
    clearCourseEnrolledUsers();
  }

  onGoToCourseDetails = courseId => () => {
    const { history } = this.props;

    return history.push(`/learning/${courseId}`);
  };

  handleSendComment = (comment, isEdit) => {
    const { actionPlan, getActionPlanComments } = this.props;
    const { commentId } = this.state;

    if (isEdit) {
      return http
        .patch(api_action_plan_comment_update(actionPlan.id, commentId), {
          comment,
        })
        .then(() => {
          this.setState({
            page: ACTION_PLAN_COMMENTS_DEFAULT_PARAMS.page,
            commentId: 0,
          });
          getActionPlanComments(actionPlan.id);
        });
    }

    return http
      .post(api_action_plan_comment(actionPlan.id), { comment })
      .then(() => {
        getActionPlanComments(actionPlan.id);
        this.setState({ page: ACTION_PLAN_COMMENTS_DEFAULT_PARAMS.page });
      });
  };

  onLoadMore = () => {
    const { actionPlan, getActionPlanComments } = this.props;
    const { page } = this.state;
    const incrementedPage = page + 1;
    const params = replacePropsInObject(ACTION_PLAN_COMMENTS_DEFAULT_PARAMS, {
      page: incrementedPage,
    });
    this.setState({ page: incrementedPage });
    return getActionPlanComments(actionPlan.id, params);
  };

  handleDeleteComment = commentId => {
    const { translations, actionPlan, getActionPlanComments } = this.props;

    return http
      .delete(api_action_plan_comment_update(actionPlan.id, commentId))
      .then(() => {
        showSuccessMessage(
          translations.details.comment.deleteCommentDialog.deleteSuccess
        );
        this.setState({ page: ACTION_PLAN_COMMENTS_DEFAULT_PARAMS.page });
        getActionPlanComments(actionPlan.id);
      });
  };

  openEditComment = commentId => {
    this.setState({ commentId });
  };

  closeEditComment = () => {
    this.setState({ commentId: 0 });
  };

  handleActionPlanEdit = () => {
    this.setState({
      isEditOpened: true,
    });
  };

  handleCloseEditActionPlan = () => {
    this.setState({
      isEditOpened: false,
      isDeleteOpened: false,
      isDismissRequestOpened: false,
    });
  };

  handleSaveActionPlan = () => {
    const { getActionPlan, actionPlan } = this.props;
    this.setState({
      isEditOpened: false,
    });
    return Promise.all([getActionPlan(actionPlan.id)]);
  };

  handleOpenManageTasks = () => this.setState({ isManageTasksOpened: true });

  handleCloseManageTasks = () => this.setState({ isManageTasksOpened: false });

  onSaveTasks = async values => {
    const { translations, auth, getActionPlan, actionPlan } = this.props;
    const isCreatedForCurrentUser = auth.id === actionPlan.user.id;
    const tasks = prepareTasksForSave(values.tasks);
    const data = {
      tasks,
      user: actionPlan.user.id,
    };

    if (isCreatedForCurrentUser) {
      await http.post(api_action_plan_tasks(actionPlan.id), data);
    } else {
      await http.patch(api_action_plan(actionPlan.id), data);
    }
    showSuccessMessage(translations.manageTasksDialog.successMessage);

    return getActionPlan(actionPlan.id);
  };

  handleActionPlanDelete = () => this.setState({ isDeleteOpened: true });

  handleCloseActionPlanDelete = () => this.setState({ isDeleteOpened: false });

  handleDismissProposal = () => {
    this.setState({ isDismissRequestOpened: true });
  };

  handleCloseDismissDialog = () => {
    this.setState({ isDismissRequestOpened: false });
  };

  onActionPlanDelete = () => {
    const { translations, history, auth, actionPlan } = this.props;
    const { isDismissRequestOpened } = this.state;
    const isUser = checkUserRole(auth.role, ROLES.USER);

    const successMessage = isDismissRequestOpened
      ? translations.dismissActionPlanProposalDialog.deleteSuccess
      : translations.deleteActionPlanDialog.deleteSuccess;

    return http.delete(api_action_plan(actionPlan.id)).then(() => {
      this.handleCloseEditActionPlan();
      showSuccessMessage(successMessage);
      history.replace(isUser ? '/' : '/action-plans');
    }, this.handleCloseEditActionPlan);
  };

  checkCanManageFormTask = isAdmin => task => {
    const { auth, actionPlan } = this.props;
    const isCurrentUserCreator = auth.id === actionPlan.creator?.id;
    const isNewTask = !task.id;

    return (
      isAdmin || isCurrentUserCreator || task.creator === auth.id || isNewTask
    );
  };

  checkCanManageTaskCompletion = isAdmin => task => {
    const { auth, actionPlan } = this.props;

    return (
      isAdmin || actionPlan?.creator?.id === auth.id || task.user.id === auth.id
    );
  };

  handleTaskStatusChange = (task, hasPlanManagingPrivileges = false) => {
    const { actionPlan, changeActionPlanTaskStatus, changeTaskStatus } =
      this.props;

    return hasPlanManagingPrivileges
      ? changeActionPlanTaskStatus(actionPlan.id, task.id)
      : changeTaskStatus(task.id);
  };

  onCreateTag = async tagName => {
    const { addMultipleTags } = this.props;
    const tags = await onSaveCreatableTag(tagName);
    const [tag] = tags.create;

    addMultipleTags({ categoryId: GENERAL_CATEGORY_ID, tags });

    return tag;
  };

  handleCoursesSearch = value => {
    const { getCourses } = this.props;
    const text = trimString(value);
    const params = {
      ...(text ? { search: text } : {}),
    };

    return getCourses(params);
  };

  canAddComment = () => {
    const { auth, actionPlan } = this.props;
    const isAdmin = checkUserRole(auth.role, ROLES.ADMIN);

    return (
      isAdmin || [actionPlan.creator?.id, actionPlan.user.id].includes(auth.id)
    );
  };

  render() {
    const {
      classes,
      auth,
      translations,
      actionPlan,
      courses,
      actionPlanComments,
      allUsers,
      categories,
      organizationSettings,
      getCourses,
      getCourseEnrolledUsers,
    } = this.props;
    const {
      isLoading,
      isEditOpened,
      isDeleteOpened,
      isManageTasksOpened,
      isDismissRequestOpened,
      commentId,
    } = this.state;
    const isAdmin = checkUserRole(auth.role, ROLES.ADMIN);
    const { user, creator, description, is_pending_proposal } = actionPlan;
    const isUserActive = user && !isUserSuspended(user);
    const statuses = getActionPlanStatuses(translations.statuses);
    const showLoadMore = !!actionPlanComments?.next;
    const isPendingProposal = actionPlan?.is_pending_proposal;

    const canManage = isAdmin || auth.id === creator?.id;
    const hasActions = canManage || auth.id === user?.id;

    return (
      <div>
        {!isLoading ? (
          <div className={classes.root}>
            <ActionPlanDetailsCard
              translations={translations.details}
              currentUser={auth}
              statuses={statuses}
              actionPlan={actionPlan}
              isDisabled={!isUserActive}
              canManage={canManage}
              hasActions={hasActions}
              checkCanManageTaskCompletion={this.checkCanManageTaskCompletion(
                isAdmin
              )}
              checkCanAccessUserProfile={isUserProfileAccessible(
                auth,
                organizationSettings.global_see_himself
              )}
              onGoToCourseDetails={this.onGoToCourseDetails}
              onTaskStatusChange={this.handleTaskStatusChange}
              onDelete={this.handleActionPlanDelete}
              onDismissProposal={this.handleDismissProposal}
              onEdit={this.handleActionPlanEdit}
              onManageTasks={this.handleOpenManageTasks}
            />
            <SectionTitle
              className={classes.subtitle}
              title={translations.details.description}
              variant="h5"
            />
            <div className={classes.notes}>
              <RichTextPreview text={description} />
              {isUserActive && !is_pending_proposal && canManage && (
                <ActionButton
                  className={classes.editDescriptionButton}
                  type={ACTION_BUTTON_TYPES.EDIT}
                  onClickHandler={this.handleActionPlanEdit}
                />
              )}
            </div>
            {!isPendingProposal ? (
              <UsersComments
                className={classes.wrap}
                translations={translations.details.comment}
                currentUser={auth}
                comments={actionPlanComments.results}
                onSendComment={this.handleSendComment}
                openEditComment={this.openEditComment}
                commentId={commentId}
                onDeleteComment={this.handleDeleteComment}
                canAddComment={this.canAddComment()}
                closeEditComment={this.closeEditComment}
              />
            ) : null}
            <div className={classes.loadMoreContainer}>
              {showLoadMore && (
                <CustomButton
                  className={classes.loadMore}
                  type="addWithTextRounded"
                  onClick={this.onLoadMore}
                >
                  {translations.loadMore}
                </CustomButton>
              )}
            </div>
          </div>
        ) : null}
        {!isLoading && (
          <ManageActionPlan
            translations={translations.details.manageActionPlan}
            isOpen={isEditOpened}
            currentUser={auth}
            statuses={statuses}
            actionPlan={actionPlan}
            isInitialValid={!!actionPlan}
            allUsers={allUsers}
            categories={categories}
            courses={courses.results}
            getCourses={getCourses}
            getCourseEnrolledUsers={getCourseEnrolledUsers}
            onCoursesSearch={this.handleCoursesSearch}
            onCreateTag={this.onCreateTag}
            onDelete={this.handleActionPlanDelete}
            onConfirm={this.handleSaveActionPlan}
            onClose={this.handleCloseEditActionPlan}
          />
        )}
        {!isLoading && (
          <ManageTasks
            translations={translations.manageTasksDialog}
            isOpened={isManageTasksOpened}
            record={actionPlan}
            availableUsers={allUsers}
            checkCanManageTask={this.checkCanManageFormTask(isAdmin)}
            onClose={this.handleCloseManageTasks}
            onSave={this.onSaveTasks}
          />
        )}
        <AlertDialog
          isOpened={isDeleteOpened}
          translations={translations.deleteActionPlanDialog}
          onClose={this.handleCloseActionPlanDelete}
          onConfirm={this.onActionPlanDelete}
          isWarning
        />
        <AlertDialog
          translations={translations.dismissActionPlanProposalDialog}
          isOpened={isDismissRequestOpened}
          onClose={this.handleCloseDismissDialog}
          onConfirm={this.onActionPlanDelete}
          isWarning
        />
      </div>
    );
  }
}

ActionPlanDetailsPage.defaultProps = {
  actionPlanComments: {},
};

ActionPlanDetailsPage.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  translations: PropTypes.shape({}).isRequired,
  auth: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  matchedActionPlanId: PropTypes.string.isRequired,
  actionPlan: PropTypes.shape({}).isRequired,
  actionPlanComments: PropTypes.shape({}),
  courses: PropTypes.shape({}).isRequired,
  allUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getActionPlan: PropTypes.func.isRequired,
  getActionPlanComments: PropTypes.func.isRequired,
  getTagsCategories: PropTypes.func.isRequired,
  addMultipleTags: PropTypes.func.isRequired,
  getCourses: PropTypes.func.isRequired,
  getCourseEnrolledUsers: PropTypes.func.isRequired,
  clearActionPlan: PropTypes.func.isRequired,
  clearActionPlanComments: PropTypes.func.isRequired,
  clearTagsCategories: PropTypes.func.isRequired,
  getAllUsers: PropTypes.func.isRequired,
  clearAllUsers: PropTypes.func.isRequired,
  changeActionPlanTaskStatus: PropTypes.func.isRequired,
  changeTaskStatus: PropTypes.func.isRequired,
  clearCourses: PropTypes.func.isRequired,
  clearCourseEnrolledUsers: PropTypes.func.isRequired,
};

export default withStyles(styles)(ActionPlanDetailsPage);
