import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { connect } from 'react-redux';

import FillSurveyPage from '../../components/pages/fillSurveyPage';
import { fillSurveySelector } from '../../store/selectors/fillSurveySelector';
import { useTranslations } from '../../utility/useTranslations';
import {
  getFillSurvey,
  clearFillSurvey,
  getSurveyReview,
  setAnswerResult,
  setAnswerTextResult,
  setReviewStep,
  setComments,
  submitReview,
  setIsInlineSurvey,
} from '../../store/modules/fillSurvey';
import {
  EVENT_ACTION_TYPES,
  tagManagerDataLayer,
} from '../../utility/tagManager';
import { APP_PAGES } from '../../constants/pages';

const mapStateToProps = state => ({
  ...fillSurveySelector(state),
});

const mapDispatchToProps = dispatch => ({
  getFillSurvey: token => getFillSurvey(dispatch, token),
  getSurveyReview: (token, reviewId) =>
    getSurveyReview(dispatch, token, reviewId),
  setAnswerResult: (token, answer) => setAnswerResult(dispatch, token, answer),
  setAnswerTextResult: (token, answer) =>
    setAnswerTextResult(dispatch, token, answer),
  setReviewStep: step => dispatch(setReviewStep(step)),
  setComments: comments => dispatch(setComments(comments)),
  submitReview: (token, comments) => submitReview(dispatch, token, comments),
  clearFillSurvey: isInline => dispatch(clearFillSurvey(isInline)),
  setIsInlineSurvey: isInline => dispatch(setIsInlineSurvey(isInline)),
});

const FillSurveyPageView = ({ getFillSurvey: getSurvey, ...rest }) => {
  const { token } = useParams();
  const translations = useTranslations(APP_PAGES.FILL_SURVEY);

  useEffect(() => {
    const { VisitFSP } = EVENT_ACTION_TYPES;
    if (token) {
      getSurvey(token);
      tagManagerDataLayer(VisitFSP.action, VisitFSP.name);
    } else {
      throw new Error('Survey token is not available!');
    }
  }, [token, getSurvey]);

  return (
    <FillSurveyPage
      {...rest}
      trackingToken={token}
      translations={translations}
    />
  );
};

FillSurveyPageView.propTypes = {
  getFillSurvey: PropTypes.func.isRequired,
  clearFillSurvey: PropTypes.func.isRequired,
  isInlineSurvey: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(FillSurveyPageView);
