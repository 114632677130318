import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import fields from './fields';
import AuthFormLayout from '../authFormLayout';
import BackLink from '../backLink';
import { ReactComponent as FlipIcon } from '../../../assets/icons/flip.svg';

class ResetPasswordFormLayout extends PureComponent {
  render() {
    const { translations, history, name, withoutBackLink } = this.props;

    if (name && name.length > 0) {
      const personalizedTranslations = {
        ...translations,
        title: translations.welcome,
        description: translations.descriptionSetPassword,
      };

      return (
        <AuthFormLayout
          {...this.props}
          translations={personalizedTranslations}
          fields={fields}
          icon={FlipIcon}
          isPaswordReset
          privacyPolicyCheck
        />
      );
    }
    return (
      <AuthFormLayout
        {...this.props}
        fields={fields}
        icon={FlipIcon}
        isPaswordReset
      >
        {withoutBackLink ? null : (
          <BackLink
            title={translations.backLink}
            history={history}
            to="/login"
          />
        )}
      </AuthFormLayout>
    );
  }
}

ResetPasswordFormLayout.propTypes = {
  translations: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default ResetPasswordFormLayout;
