import { getUserProfileNavigationItems } from '../../../utility/navigationUtils';

export const getShareTypeLabel = (labels, currentUser, user) => {
  const isCurrentUser = currentUser?.id === user.id;
  const isReport =
    !isCurrentUser && currentUser?.reporters && currentUser?.reporters[user.id];
  const isDirectReport = isReport && currentUser?.id === user.report_to;
  const isInYourBranch = isReport && currentUser?.id !== user.report_to;

  const isShared =
    !isDirectReport &&
    currentUser?.accessible &&
    currentUser?.accessible[user.id];

  if (isCurrentUser) {
    return labels.you;
  } else if (isShared) {
    return labels.sharedWith;
  } else if (isDirectReport) {
    return labels.yourDirectReport;
  } else if (isInYourBranch) {
    return labels.inYourBranch;
  }

  return null;
};

export const getNavItems = (labels, currentUser, userId) => {
  const isCurrentUser = userId === currentUser.id;

  return getUserProfileNavigationItems(userId, currentUser.id).reduce(
    (navItems, currentNavItem) => {
      if (
        currentNavItem.requiredRoles.includes(currentUser.role) &&
        (currentNavItem.isVisibleToCurrentUser || !isCurrentUser)
      ) {
        return [
          ...navItems,
          {
            ...currentNavItem,
            name: labels.personNavItems[currentNavItem.translationKey],
          },
        ];
      }

      return navItems;
    },
    []
  );
};
