import { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import CustomModal from '../customModal';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  content: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    columnGap: spacing(4),
    width: '100%',
    height: 143,
  },
  type: {
    cursor: 'pointer',
    boxSizing: 'border-box',
    border: `2px solid ${primary.bluish4}`,
    borderRadius: 16,
    padding: spacing(4, 4, 6, 4),
    height: 145,
    userSelect: 'none',
    transition: 'border-color .25s ease',
  },
  icon: {
    marginBottom: spacing(2),
    width: 32,
    height: 32,
  },
  spacingBottom: {
    marginBottom: spacing(2),
  },
  actionButtons: {
    marginTop: spacing(8),
  },
}));

const LevelingTypeDialog = ({
  translations,
  isOpened,
  types,
  selectedType,
  onCancel,
  onSelect,
  onConfirm,
}) => {
  const classes = useStyles();

  return (
    <CustomModal
      customButtonsClass={classes.actionButtons}
      title={translations.title}
      isOpened={isOpened}
      confirmButtonLabel={translations.continue}
      closeButtonLabel={translations.cancel}
      onClose={onCancel}
      onConfirm={onConfirm}
    >
      <div className={classes.content}>
        {types.map(type => {
          const { icon: Icon, name, description, activeColor } = type;

          return (
            <div
              key={`action_point_type_${type.id}`}
              className={classes.type}
              style={{
                ...(type.id === selectedType
                  ? { border: `2px solid ${activeColor}` }
                  : {}),
              }}
              onClick={() => onSelect(type.id)}
            >
              <Icon
                className={classNames(classes.icon, classes.spacingBottom)}
              />
              <div className={classes.spacingBottom}>
                <Typography variant="h5" component="p">
                  {name}
                </Typography>
              </div>
              <Typography variant="body2">{description}</Typography>
            </div>
          );
        })}
      </div>
    </CustomModal>
  );
};

LevelingTypeDialog.defaultProps = {
  selectedType: null,
};

LevelingTypeDialog.propTypes = {
  translations: PropTypes.object.isRequired,
  isOpened: PropTypes.bool.isRequired,
  types: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedType: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default memo(LevelingTypeDialog);
