import PageContainer from '../../components/shared/pageContainer';
import ReleaseNotesPage from '../../components/pages/releaseNotesPage';
import { useTranslations } from '../../utility/useTranslations';
import { APP_PAGES } from '../../constants/pages';

const ReleaseNotesPageView = props => {
  const translations = useTranslations(APP_PAGES.RELEASE_NOTES);

  return (
    <PageContainer {...props} translations={translations} isFullWidthContent>
      <ReleaseNotesPage />
    </PageContainer>
  );
};

export default ReleaseNotesPageView;
