import { createAction, handleActions } from 'redux-actions';
import { createCustomHttpClient } from '../../utility/http';
import {
  SIGN_UP_FORM_FIELD_NAMES,
  APPLICANT_TOKEN,
} from '../../components/pages/signUp/config';
import { API_VALIDATE_ORGANIZATION_NAME } from '../../constants/apiRoutes';

// ------------------------------------
// Constants
// ------------------------------------

const SET_SIGN_UP = 'SET_SIGN_UP';

const initialState = {
  signUp: {
    [SIGN_UP_FORM_FIELD_NAMES.EMAIL]: '',
    [SIGN_UP_FORM_FIELD_NAMES.FIRST_NAME]: '',
    [SIGN_UP_FORM_FIELD_NAMES.LAST_NAME]: '',
    [SIGN_UP_FORM_FIELD_NAMES.DOMAIN_NAME]: '',
    [SIGN_UP_FORM_FIELD_NAMES.PASSWORD]: '',
    [SIGN_UP_FORM_FIELD_NAMES.JOB_TITLE]: '',
    [SIGN_UP_FORM_FIELD_NAMES.INCENTIVE]: [],
    [SIGN_UP_FORM_FIELD_NAMES.COMPANY_SIZE]: '',
    [SIGN_UP_FORM_FIELD_NAMES.PREFERENCE]: null,
    [APPLICANT_TOKEN]: '',
  },
};

// ------------------------------------
// Actions
// ------------------------------------
export const setSignUp = createAction(SET_SIGN_UP);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_SIGN_UP]: (state, { payload }) => {
    return {
      ...state,
      signUp: payload,
    };
  },
};

// ------------------------------------
// "plain" action functions
// ------------------------------------
export const clearSignUp = () => setSignUp(initialState.signUp);

export const validateOrganizationName = name => {
  return createCustomHttpClient().get(
    `${API_VALIDATE_ORGANIZATION_NAME}?q=${name}`
  );
};

export default handleActions(reducers, initialState);
