import { createAction, handleActions } from 'redux-actions';
import http from '../../utility/http';
import { API_SURVEY_SUMMARY } from '../../constants/apiRoutes';
import { SURVEYS_SUMMARY_DEFAULT_PARAMS } from '../../constants/pages';
import { parseDuplicateParameters } from '../../utility/uiUtils';

// ------------------------------------
// Constants
// ------------------------------------

const SET_SURVEY_SUMMARIES = 'SET_SURVEY_SUMMARIES';
const LOAD_MORE_SURVEY_SUMMARIES = 'LOAD_MORE_SURVEY_SUMMARIES';

const initialState = {};

// ------------------------------------
// Actions
// ------------------------------------

const setSurveySummaries = createAction(SET_SURVEY_SUMMARIES);
const loadMoreSurveySummaries = createAction(LOAD_MORE_SURVEY_SUMMARIES);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_SURVEY_SUMMARIES]: (state, { payload }) => {
    return { ...payload };
  },
  [LOAD_MORE_SURVEY_SUMMARIES]: (state, { payload }) => {
    return {
      ...state,
      ...payload,
      results: state.results
        ? [...state.results, ...payload.results]
        : [...payload.results],
    };
  },
};

export const getSurveySummaries = (
  dispatch,
  isLoadMore = false,
  params = {}
) => {
  return http
    .get(API_SURVEY_SUMMARY, {
      params: { ...SURVEYS_SUMMARY_DEFAULT_PARAMS, ...params },
      paramsSerializer: data => parseDuplicateParameters(data),
    })
    .then(({ data }) => {
      dispatch(
        isLoadMore ? loadMoreSurveySummaries(data) : setSurveySummaries(data)
      );
    });
};

export const clearSurveySummaries = () => setSurveySummaries(initialState);

export default handleActions(reducers, initialState);
