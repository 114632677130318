import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import CustomButton from '../../shared/customButton';
import LevelingTable from '../../shared/levelingTable';
import CustomFormDrawer from '../../shared/customFormDrawer';
import VisibleForPermission from '../../shared/visibleForPermission';
import AlertDialog from '../../shared/alertDialog';
import NotificationCard from '../../shared/notificationCard';
import LevelingTypeDialog from '../../shared/levelingTypeDialog';
import { ReactComponent as NoResultsIcon } from '../../../assets/icons/view_list.svg';
import { isArrayEmpty, isPermissionGranted } from '../../../utility/helpers';
import {
  parseDuplicateParameters,
  showSuccessMessage,
} from '../../../utility/uiUtils';
import http from '../../../utility/http';
import { PERMISSIONS } from '../../../constants/rolesAndPermissionList';
import { API_USERS } from '../../../constants/apiRoutes';
import { UserStatuses } from '../../../constants/statuses';
import {
  LEVELING_TYPES,
  CUSTOM_LEVELING_TEMPLATE,
} from '../../../constants/levelingTemplates';
import {
  getTemplateFields,
  prepareLevelsForSave,
  prepareTracksForSave,
  getDrawerTranslations,
  getAlertDialogTranslations,
  getLevelingTypes,
  getInitialLevelingData,
  TEMPLATE_OPTIONS,
} from './config';

const styles = ({ spacing }) => ({
  title: {
    margin: 0,
  },
  subHeader: {
    maxWidth: 900,
    padding: spacing(8, 0, 4),
  },
  actionWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 32,
  },
  addTemplateButton: {
    marginLeft: spacing(2),
  },
  levelingTableContainer: {
    height: 'calc(100vh - 284px)',
  },
  noLevels: {
    width: '100%',
    maxWidth: 900,
  },
});

class LevelingPage extends PureComponent {
  state = {
    isLoading: true,
    isLevelingTypeDialogOpened: false,
    isTemplateDialogOpened: false,
    isAddCustomLevelingDialogOpened: false,
    isEditTemplateDialogOpened: false,
    isAlertDialogOpened: false,
    selectedTemplate: null,
    levelingType: LEVELING_TYPES.CUSTOM,
  };

  componentDidMount() {
    const { getLevelsAndTracks } = this.props;
    getLevelsAndTracks().then(() => this.setState({ isLoading: false }));
  }

  componentWillUnmount() {
    const { clearLevelsAndTracks } = this.props;
    clearLevelsAndTracks();
  }

  getAssignedUsers = (itemId, isTrack) => {
    const [Idle, Invited, Activated] = UserStatuses;
    const params = {
      page: 1,
      status: [Idle.id, Invited.id, Activated.id],
    };

    if (isTrack) {
      params.track_level__track = itemId;
    } else {
      params.track_level = itemId;
    }

    return http.get(API_USERS, {
      params,
      paramsSerializer: data => parseDuplicateParameters(data),
    });
  };

  toggleLevelingTypeDialog =
    (type = LEVELING_TYPES.CUSTOM) =>
    () =>
      this.setState(prevState => ({
        isLevelingTypeDialogOpened: !prevState.isLevelingTypeDialogOpened,
        levelingType: type,
      }));

  handleSelectLevelingType = type => this.setState({ levelingType: type });

  handleConfirmLevelingType = () => {
    const { levelingType } = this.state;

    if (levelingType === LEVELING_TYPES.CUSTOM) {
      return this.setState({
        isLevelingTypeDialogOpened: false,
        isAddCustomLevelingDialogOpened: true,
      });
    }

    return this.setState({
      isTemplateDialogOpened: true,
      isLevelingTypeDialogOpened: false,
      levelingType: LEVELING_TYPES.CUSTOM,
    });
  };

  handleEditTemplate = () =>
    this.setState({ isEditTemplateDialogOpened: true });

  handleCloseDrawer = () =>
    this.setState({
      isTemplateDialogOpened: false,
      isEditTemplateDialogOpened: false,
      isAddCustomLevelingDialogOpened: false,
    });

  toggleAlertDialogVisibility = () => {
    this.setState(prevState => ({
      isAlertDialogOpened: !prevState.isAlertDialogOpened,
      selectedTemplate: null,
    }));
  };

  onAddTemplate = value => {
    const { addLevels, addTracks } = this.props;

    return addLevels(prepareLevelsForSave(value.levels)).then(levels => {
      return addTracks({
        tracks: prepareTracksForSave(value.tracks, levels),
        levels,
      });
    });
  };

  onManageTemplate = values => {
    const { translations, levels } = this.props;
    const { isEditTemplateDialogOpened, isAddCustomLevelingDialogOpened } =
      this.state;
    const { template, customTemplate } = values;

    if (!isArrayEmpty(levels) && !isEditTemplateDialogOpened) {
      this.setState({
        selectedTemplate: isAddCustomLevelingDialogOpened
          ? customTemplate
          : template.value,
        isAlertDialogOpened: true,
      });

      return Promise.resolve({ shouldResetForm: false });
    }

    return this.onAddTemplate(
      isAddCustomLevelingDialogOpened || isEditTemplateDialogOpened
        ? customTemplate
        : template.value
    ).then(() =>
      showSuccessMessage(
        isEditTemplateDialogOpened
          ? translations.successMessages.editTemplate
          : translations.successMessages.addTemplate
      )
    );
  };

  onDeleteTemplate = () => {
    const { translations } = this.props;

    return this.onAddTemplate({ levels: [], tracks: [] }).then(
      () => {
        this.toggleAlertDialogVisibility();
        this.handleCloseDrawer();
        showSuccessMessage(translations.deleteTemplateDialog.deleteSuccess);
      },
      () => {
        this.toggleAlertDialogVisibility();
        this.handleCloseDrawer();
      }
    );
  };

  onConfirmAlertDialog = () => {
    const { translations } = this.props;
    const { selectedTemplate } = this.state;

    if (selectedTemplate) {
      return this.onAddTemplate(selectedTemplate).then(() => {
        this.toggleAlertDialogVisibility();
        this.handleCloseDrawer();
        showSuccessMessage(translations.successMessages.addTemplate);
      });
    }

    return this.onDeleteTemplate();
  };

  render() {
    const { classes, grantedPermissions, translations, levels, tracks } =
      this.props;
    const {
      isLoading,
      isLevelingTypeDialogOpened,
      isAddCustomLevelingDialogOpened,
      isTemplateDialogOpened,
      isEditTemplateDialogOpened,
      isAlertDialogOpened,
      selectedTemplate,
      levelingType,
    } = this.state;
    const { canManageTracks } = PERMISSIONS;
    const isReadOnly = !isPermissionGranted(
      canManageTracks,
      grantedPermissions
    );
    const [cartaOption] = TEMPLATE_OPTIONS;
    const levelingTypes = getLevelingTypes(translations.levelingTypes);

    return (
      <div>
        <div className={classes.subHeader}>
          <div className={classes.actionWrapper}>
            <Typography variant="body2">
              {isReadOnly
                ? translations.description.read
                : translations.description.admin}
            </Typography>
            <VisibleForPermission
              permission={canManageTracks}
              permissions={grantedPermissions}
            >
              <CustomButton
                className={classes.addTemplateButton}
                type="addRoundedNew"
                onClick={this.toggleLevelingTypeDialog()}
              >
                {translations.addLevelingButton}
              </CustomButton>
            </VisibleForPermission>
          </div>
        </div>
        <div className={classes.levelingTableContainer}>
          {!isLoading && !isArrayEmpty(levels) && (
            <LevelingTable
              labels={translations.tableLabels}
              isReadOnly={isReadOnly}
              levels={levels}
              tracks={tracks}
              addLevelAndTrack={this.handleEditTemplate}
            />
          )}
          {!isLoading && isArrayEmpty(levels) && (
            <NotificationCard
              className={classes.noLevels}
              customIcon={NoResultsIcon}
              title={translations.noResults.title}
              content={translations.noResults.content}
              hasIcon
            />
          )}
        </div>
        <CustomFormDrawer
          initialData={getInitialLevelingData({
            isEdit: isEditTemplateDialogOpened,
            isAdd: isAddCustomLevelingDialogOpened,
            template: isAddCustomLevelingDialogOpened
              ? CUSTOM_LEVELING_TEMPLATE
              : cartaOption,
            levels,
            tracks,
          })}
          isInitialValid={isEditTemplateDialogOpened}
          isOpened={
            isAddCustomLevelingDialogOpened ||
            isTemplateDialogOpened ||
            isEditTemplateDialogOpened
          }
          translations={getDrawerTranslations(
            translations,
            isTemplateDialogOpened
          )}
          fields={getTemplateFields(
            isTemplateDialogOpened,
            this.getAssignedUsers
          )}
          onClose={this.handleCloseDrawer}
          onSave={this.onManageTemplate}
          onDelete={this.toggleAlertDialogVisibility}
          hasCancelButton
          isFullWidth
          hasHorizontalScroll
        />
        <LevelingTypeDialog
          translations={translations.levelingTypeDialog}
          isOpened={isLevelingTypeDialogOpened}
          types={levelingTypes}
          selectedType={levelingType}
          onSelect={this.handleSelectLevelingType}
          onCancel={this.toggleLevelingTypeDialog()}
          onConfirm={this.handleConfirmLevelingType}
        />
        <AlertDialog
          translations={getAlertDialogTranslations(
            translations,
            isAlertDialogOpened,
            selectedTemplate
          )}
          isOpened={isAlertDialogOpened}
          onClose={this.toggleAlertDialogVisibility}
          onConfirm={this.onConfirmAlertDialog}
          isWarning={!selectedTemplate}
        />
      </div>
    );
  }
}

LevelingPage.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  levels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tracks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  grantedPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  addLevels: PropTypes.func.isRequired,
  addTracks: PropTypes.func.isRequired,
  getLevelsAndTracks: PropTypes.func.isRequired,
  clearLevelsAndTracks: PropTypes.func.isRequired,
};

export default withStyles(styles)(LevelingPage);
