import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import { makeStyles } from '@material-ui/core';
import { getOneOnOneCalendarData } from '../../../utility/oneOnOne';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    marginLeft: -spacing(2),
  },
}));

const calendarOptions = ['Google', 'Microsoft365', 'iCal'];

const OneOnOneCalendar = ({ className, translations, record }) => {
  const classes = useStyles();

  const details = getOneOnOneCalendarData(translations, record);

  return (
    <div
      className={classNames(classes.root, className)}
      onClick={e => e.stopPropagation()}
    >
      <AddToCalendarButton
        timeZone="currentBrowser"
        buttonStyle="neumorphism"
        lightMode="bodyScheme"
        size="0|0|0"
        options={calendarOptions}
        buttonsList
        hideTextLabelButton
        {...details}
      />
    </div>
  );
};

OneOnOneCalendar.defaultProps = {
  className: undefined,
};

OneOnOneCalendar.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired,
};

export default memo(OneOnOneCalendar);
