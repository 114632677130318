import ColorBox from 'components/shared/colorBox';
import { ReactComponent as StatusIcon } from '../../../assets/icons/status-icon.svg';
import { ReactComponent as TagsIcon } from '../../../assets/icons/tags-icon.svg';
import { isArrayEmpty } from '../../../utility/helpers';
import { filterTags } from '../../../utility/tagUtils';
import { ONE_ON_ONE_COMPLETION_STATUSES } from '../../../constants/statuses';
import { PARAMS } from '../../../constants/pages';

export const getPageFilters = (translations, tagCategories, quickFilters) => {
  const { COMPLETION_STATUS, TAGS, STATUS } = PARAMS;

  const statuses = ONE_ON_ONE_COMPLETION_STATUSES.reduce(
    (acc, status, index) => {
      if (index === 0) {
        return [...acc, { id: null, name: translations.all }];
      }

      return [
        ...acc,
        {
          ...status,
          name: translations.statuses[status.id],
        },
      ];
    },
    []
  );

  return [
    {
      id: COMPLETION_STATUS,
      name: translations.filters.labels[STATUS],
      icon: StatusIcon,
      isSingleSelect: true,
      items: statuses,
    },
    {
      id: TAGS,
      name: translations.filters.labels[TAGS],
      icon: TagsIcon,
      categoryItemsKey: TAGS,
      hasCategoryColorBox: true,
      hasSubmenuHash: true,
      isCategorized: true,
      items: tagCategories,
      categorySearch: filterTags,
    },
    ...(!isArrayEmpty(quickFilters)
      ? quickFilters?.map(filter => {
          return {
            id: filter.name,
            name: filter.name,
            icon: () => {
              return <ColorBox bgColor={filter.color} isSmall />;
            },
            hasParent: true,
            parent: {
              id: TAGS,
            },
            items: filter?.tags,
          };
        }) || {}
      : []),
  ];
};
