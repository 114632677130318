export const BULK_ACTION_OPTIONS = [
  {
    value: 1,
    label: 'Change reporting to',
    key: 'changeReportingTo',
  },
  {
    value: 2,
    label: 'Change Job title',
    key: 'changeJobTitle',
  },
  {
    value: 3,
    label: 'Change Track/Level',
    key: 'changeLevelTrack',
  },
  {
    value: 4,
    label: 'Change Role',
    key: 'changeRole',
  },
  {
    value: 5,
    label: 'Invite Users',
    key: 'inviteUsers',
  },
  {
    value: 6,
    label: 'Apply Tags',
    key: 'applyTags',
  },
];

export const BULK_ACTION_OPTIONS_VALUES = {
  changeReportingTo: 1,
  changeJobTitle: 2,
  changeLevelTrack: 3,
  changeRole: 4,
  inviteUsers: 5,
  applyTags: 6,
};
