import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactQuill from 'react-quill';
import { Typography, withStyles } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import Quill from 'quill';
import Tooltip from '../tooltip';
import { isObjectEmpty, trimString } from '../../../utility/helpers';
import 'react-quill/dist/quill.snow.css';

const styles = ({ palette: { primary }, spacing }) => ({
  main: {
    position: 'relative',
  },
  labelWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  editor: {
    height: '230px',
    '& .ql-toolbar': {
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      '& .ql-formats': {
        marginRight: 0,
        '&:last-child': {
          marginRight: 15,
        },
      },
    },
    '& .ql-container': {
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    },

    '& .ql-editor': {
      color: primary.bluish1,
      fontFamily: 'ProximaNova-Regular',
      fontSize: 16,
      lineHeight: '18px',
      letterSpacing: 'normal',
      padding: '10px 16px',
      wordBreak: 'break-word',
      '&.ql-blank::before': {
        color: primary.bluish4,
        fontFamily: 'ProximaNova-Regular',
        fontSize: 16,
        lineHeight: '18px',
        letterSpacing: 'normal',
        fontStyle: 'normal',
        right: 16,
        left: 16,
      },
      '& strong': {
        fontFamily: 'ProximaNova-Bold',
        fontWeight: 'normal',
      },
      '& ul': {
        padding: 0,
        marginLeft: -6,

        '& li': {
          paddingLeft: 16,
          marginBottom: 6,
        },
      },
    },
  },
  errorMessage: {
    position: 'absolute',
    left: 0,
    bottom: 4,
  },
  label: {
    marginTop: spacing(2),
  },
  labelTooltipIcon: {
    marginTop: spacing(0.5),
    marginLeft: spacing(1),
    width: 14,
    height: 14,
  },
});

const LINK_WHITELIST = {
  HTTP: 'http://',
  HTTPS: 'https://',
};

class CustomRichTextEditor extends Component {
  constructor(props) {
    super(props);
    this.reactQuillRef = null;
    this.state = {
      isFocused: false,
    };
    this.modules = {
      toolbar: !this.props.isReadOnly
        ? [['bold', 'italic', { list: 'bullet' }], ['link']]
        : false,
    };
    this.link = Quill.import('formats/link');
  }

  componentDidMount() {
    this.props.onInit(this.reactQuillRef);
  }

  handleLinkSanitize = url => {
    if (
      url &&
      !url.startsWith(LINK_WHITELIST.HTTP) &&
      !url.startsWith(LINK_WHITELIST.HTTPS)
    ) {
      return `${LINK_WHITELIST.HTTPS}${trimString(url)}`;
    }
    return url;
  };

  handleOnChange = (val, delta, source, editor) => {
    const { onUpdateText } = this.props;

    if (source === 'user') {
      const textLength = editor.getText().length;
      onUpdateText(textLength > 1 ? val : '');
    }
  };

  toggleIsFocused = () => {
    const { isReadOnly } = this.props;

    this.link.sanitize = url => this.handleLinkSanitize(url);
    if (!isReadOnly) {
      this.setState(prevState => ({ isFocused: !prevState.isFocused }));
    }
  };

  render() {
    const {
      className,
      value,
      classes,
      theme,
      customLabelWrapperClass,
      customLabelClass,
      customEditorClass,
      customErrorClass,
      customFocusClass,
      label,
      labelTooltip,
      placeholder,
      hasError,
      errorMessage,
      isReadOnly,
    } = this.props;
    const { isFocused } = this.state;
    const { text, icon: Icon } = labelTooltip;

    return (
      <div
        className={classNames(classes.main, className, {
          [customFocusClass]: isFocused,
        })}
        data-text-editor="name"
      >
        {!!label && (
          <div
            className={classNames(
              classes.labelWrapper,
              customLabelWrapperClass
            )}
          >
            <Typography className={customLabelClass} variant="body2">
              {label}
            </Typography>
            {!isObjectEmpty(labelTooltip) && (
              <Tooltip
                customIconClass={classes.labelTooltipIcon}
                text={text}
                icon={Icon}
              />
            )}
          </div>
        )}
        <ReactQuill
          theme={theme}
          className={classNames(
            classes.editor,
            { [classes.label]: !!label },
            customEditorClass
          )}
          value={value}
          bounds={`[data-text-editor="name"]`}
          onChange={this.handleOnChange}
          onFocus={this.toggleIsFocused}
          onBlur={this.toggleIsFocused}
          modules={this.modules}
          placeholder={placeholder}
          readOnly={isReadOnly}
          ref={el => {
            this.reactQuillRef = el;
          }}
        />
        {hasError && errorMessage && (
          <FormHelperText
            className={classNames(
              classNames(classes.errorMessage, customErrorClass)
            )}
            error={hasError}
          >
            {errorMessage}
          </FormHelperText>
        )}
      </div>
    );
  }
}

CustomRichTextEditor.defaultProps = {
  className: null,
  onInit: instance => {
    return instance;
  },
  customLabelWrapperClass: '',
  customLabelClass: undefined,
  customEditorClass: null,
  customErrorClass: null,
  customFocusClass: null,
  labelTooltip: {},
  theme: 'snow',
  hasError: false,
  placeholder: '',
  errorMessage: '',
  isReadOnly: false,
  label: '',
};

CustomRichTextEditor.propTypes = {
  className: PropTypes.string,
  customLabelWrapperClass: PropTypes.string,
  customLabelClass: PropTypes.string,
  customEditorClass: PropTypes.string,
  customErrorClass: PropTypes.string,
  customFocusClass: PropTypes.string,
  label: PropTypes.string,
  labelTooltip: PropTypes.shape({}),
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  theme: PropTypes.string,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  isReadOnly: PropTypes.bool,
  onUpdateText: PropTypes.func.isRequired,
  onInit: PropTypes.func,
};

export default withStyles(styles)(CustomRichTextEditor);
