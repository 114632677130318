import { createAction, handleActions } from 'redux-actions';
import http from '../../utility/http';
import { parseDuplicateParameters } from '../../utility/uiUtils';
import { isObjectEmpty } from '../../utility/helpers';
import { API_USERS, API_USERS_BASIC } from '../../constants/apiRoutes';
import { UserStatuses } from '../../constants/statuses';
import { PEOPLE_DEFAULT_ORDERING } from '../../constants/people';
import { PARAMS, PEOPLE_DEFAULT_PARAMS } from '../../constants/pages';
import { ROLES } from '../../constants/rolesAndPermissionList';

// ------------------------------------
// Constants
// ------------------------------------

const SET_PEOPLE = 'SET_PEOPLE';
const LOAD_MORE_PEOPLE = 'LOAD_MORE_PEOPLE';
const SET_ALL_USERS = 'SET_ALL_USERS';

const initialState = {
  people: {},
  allUsers: [],
};

// ------------------------------------
// Actions
// ------------------------------------

const setPeople = createAction(SET_PEOPLE);
const loadMorePeople = createAction(LOAD_MORE_PEOPLE);
const setAllUsers = createAction(SET_ALL_USERS);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_PEOPLE]: (state, { payload }) => {
    return { ...state, people: payload };
  },
  [LOAD_MORE_PEOPLE]: (state, { payload }) => {
    return isObjectEmpty(payload)
      ? { ...state, people: payload }
      : {
          ...state,
          people: {
            ...payload,
            results: state.people.results
              ? [...state.people.results, ...payload.results]
              : [...payload.results],
          },
        };
  },
  [SET_ALL_USERS]: (state, { payload }) => {
    return { ...state, allUsers: payload };
  },
};

// ------------------------------------
// API calls
// ------------------------------------

export const getPeople = (dispatch, isLoadMore = false, params = {}) => {
  return http
    .get(API_USERS, {
      params: { ...PEOPLE_DEFAULT_PARAMS, ...params },
      paramsSerializer: data => parseDuplicateParameters(data),
    })
    .then(({ data }) =>
      dispatch(isLoadMore ? loadMorePeople(data) : setPeople(data))
    );
};

export const getAllUsers = async (
  dispatch,
  params = {},
  shouldAllowAllUsers = false
) => {
  const [, , , SUSPENDED] = UserStatuses;
  const { EXCLUDE_ROLE, EXCLUDE_STATUS } = PARAMS;
  const { data } = await http.get(API_USERS_BASIC, {
    params: {
      ...PEOPLE_DEFAULT_ORDERING,
      ...(shouldAllowAllUsers
        ? {}
        : { [EXCLUDE_ROLE]: ROLES.ASSOCIATE, [EXCLUDE_STATUS]: SUSPENDED.id }),
      ...params,
    },
    paramsSerializer: d => parseDuplicateParameters(d),
  });

  return dispatch(setAllUsers(data));
};

export default handleActions(reducers, initialState);

// ------------------------------------
// "plain" action functions
// ------------------------------------

export const clearPeople = () => setPeople(initialState.people);
export const clearAllUsers = () => setAllUsers(initialState.allUsers);
