import { isObjectEmpty, trimString } from './helpers';
import { validateFields } from './validation';
import { TASK_FIELDS } from '../constants/tasks';

export const validateTasks = async tasks => {
  const errors = {};

  for (let i = 0; i < tasks.length; i += 1) {
    // eslint-disable-next-line no-await-in-loop
    const taskErrors = await validateFields(TASK_FIELDS, tasks[i]);

    if (!isObjectEmpty(taskErrors)) {
      errors[i] = taskErrors;
    }
  }

  return !isObjectEmpty(errors) ? errors : null;
};

export const prepareTasksForSave = (
  tasks,
  shouldKeepAllData = true,
  shouldKeepStatus = false
) =>
  tasks.reduce(
    (acc, task) => [
      ...acc,
      {
        ...(shouldKeepAllData ? { ...task } : {}),
        ...(shouldKeepStatus ? { is_finished: task.is_finished } : {}),
        text: trimString(task.text),
        user: task.user.id,
      },
    ],
    []
  );
