import { isArrayEmpty } from 'utility/helpers';
import ColorBox from 'components/shared/colorBox';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-dark.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-outlined.svg';
import { ReactComponent as CourseIcon } from '../../../assets/icons/courses-icon-dark.svg';
import { ReactComponent as CourseLevelIcon } from '../../../assets/icons/course-level-icon.svg';
import { ReactComponent as AttributeIcon } from '../../../assets/icons/checklist-icon.svg';
import { ReactComponent as TagsIcon } from '../../../assets/icons/tags-icon.svg';
import {
  forbiddenCharacters,
  isEmpty,
  validateLength,
  validateUrl,
  validateFileType,
  validateFileSize,
} from '../../../utility/validation';
import { filterTags } from '../../../utility/tagUtils';
import {
  COURSE_SOURCES,
  DEFAULT_SEARCH_VALUE,
  ALL,
} from '../../../constants/courses';
import { FIELD_TYPES } from '../../../constants/fieldTypes';
import { PARAMS } from '../../../constants/pages';
import { PHOTO_UPLOAD_CONFIG } from '../../../constants/uploadPhotoConfig';
import { CREATABLE_TAG_FIELD } from '../../../constants/tags';

const { UDEMY } = COURSE_SOURCES;
const { ADD_IMAGE, SEARCH, SELECT, SELECT_COURSE, BUTTON_SWITCH, TEXT } =
  FIELD_TYPES;

export const INITIAL_ADD_COURSE_DATA = {
  title: '',
  search: '',
  attribute: null,
  tags: [],
  level: ALL.id,
  source: UDEMY,
  selectedCourse: null,
  url: '',
  description: '',
  cover_image: null,
};

export const getPageFilters = (
  translations,
  levels,
  attributes,
  tagCategories,
  quickFilters = []
) => {
  const { LEVEL, ATTRIBUTE, TAGS } = PARAMS;
  const defaultOption = { id: null, name: translations.all };

  return [
    {
      id: LEVEL,
      name: translations.filters.labels[LEVEL],
      icon: CourseLevelIcon,
      isSingleSelect: true,
      items: [defaultOption, ...levels],
    },
    {
      id: ATTRIBUTE,
      name: translations.filters.labels[ATTRIBUTE],
      icon: AttributeIcon,
      isSingleSelect: true,
      hasSubmenuColorBox: true,
      items: [defaultOption, ...attributes],
    },
    {
      id: TAGS,
      name: translations.filters.labels[TAGS],
      icon: TagsIcon,
      categoryItemsKey: TAGS,
      hasCategoryColorBox: true,
      hasSubmenuHash: true,
      isCategorized: true,
      items: tagCategories,
      categorySearch: filterTags,
    },
    ...(!isArrayEmpty(quickFilters)
      ? quickFilters?.map(filter => {
          return {
            id: filter.name,
            name: filter.name,
            icon: () => {
              return <ColorBox bgColor={filter.color} isSmall />;
            },
            parent: {
              id: TAGS,
            },
            items: filter?.tags,
          };
        }) || {}
      : []),
  ];
};

export const getCourseFields = (
  classes,
  labels,
  source,
  isEdit,
  onSourceChange,
  onUdemySearch,
  onCreateTag
) => {
  const isUdemy = source === UDEMY;

  return [
    ...(!isEdit
      ? [
          {
            name: 'source',
            type: BUTTON_SWITCH,
            onValueChange: (value, props) => {
              onSourceChange(value);
              if (value === UDEMY) {
                onUdemySearch(DEFAULT_SEARCH_VALUE);
              }
              props.resetForm({ ...INITIAL_ADD_COURSE_DATA, source: value });
            },
            items: [
              {
                label: labels.sourceTypes.udemy,
                value: COURSE_SOURCES.UDEMY,
              },
              {
                label: labels.sourceTypes.custom,
                value: COURSE_SOURCES.CUSTOM,
              },
            ],
          },
        ]
      : []),
    ...(!isUdemy
      ? [
          {
            name: 'title',
            type: TEXT,
            required: true,
            validators: [
              {
                type: 'required',
                validator: isEmpty,
              },
              {
                type: 'forbiddenCharacters',
                validator: forbiddenCharacters,
              },
              {
                type: 'titleMaxLength',
                validator: fieldValue => validateLength(fieldValue, 0, 500),
              },
            ],
          },
        ]
      : []),
    ...(isUdemy
      ? [
          {
            name: 'search',
            type: SEARCH,
            onSearch: onUdemySearch,
            dependants: [{ name: 'selectedCourse', value: null }],
          },
        ]
      : []),
    {
      name: 'level',
      type: SELECT,
      optionsKey: 'levels',
      dependants: [
        {
          name: 'search',
          callbackFnc: (prevLevel, currentLevel, searchTerm) => {
            if (isUdemy && currentLevel !== prevLevel) {
              return onUdemySearch(searchTerm, { level: currentLevel });
            }
          },
        },
        {
          name: 'selectedCourse',
          resetFnc: (prevLevel, currentLevel, selectedCourse) => {
            if (currentLevel === prevLevel) {
              return selectedCourse;
            }

            return null;
          },
        },
      ],
      parser: {
        value: 'id',
        label: 'name',
      },
    },
    ...(!isUdemy
      ? [
          {
            name: 'url',
            type: TEXT,
            required: true,
            validators: [
              {
                type: 'required',
                validator: isEmpty,
              },
              { type: 'invalidUrl', validator: validateUrl },
            ],
          },
        ]
      : []),
    ...(!isUdemy
      ? [
          {
            name: 'description',
            type: TEXT,
            multiline: true,
            rows: 5,
            validators: [
              {
                type: 'forbiddenCharacters',
                validator: forbiddenCharacters,
              },
              {
                type: 'descriptionMaxLength',
                validator: fieldValue => validateLength(fieldValue, 0, 2500),
              },
            ],
          },
        ]
      : []),
    ...(isUdemy
      ? [
          {
            name: 'selectedCourse',
            type: SELECT_COURSE,
            optionsKey: 'courses',
            required: true,
            validators: [
              {
                type: 'required',
                validator: isEmpty,
              },
            ],
          },
        ]
      : []),
    {
      name: 'attribute',
      type: SELECT,
      optionsKey: 'allAttributes',
      hasColorBox: true,
      isClearable: true,
      parser: {
        value: 'id',
        label: 'name',
      },
    },
    {
      name: 'tags',
      type: SELECT,
      optionsKey: 'categories',
      creatableOptionField: CREATABLE_TAG_FIELD,
      isMulti: true,
      hasHash: true,
      hasCategoryColorBox: true,
      isCreatable: true,
      isCategorized: true,
      isTag: true,
      parser: {
        value: 'id',
        label: 'name',
        categoryLabel: 'name',
        categoryValue: 'tags',
      },
      onCreateOption: onCreateTag,
    },
    ...(!isUdemy
      ? [
          {
            name: 'cover_image',
            type: ADD_IMAGE,
            translationKey: 'courseImage',
            validators: [
              {
                type: 'fileType',
                validator: value =>
                  validateFileType(
                    value,
                    Object.keys(PHOTO_UPLOAD_CONFIG.mimeTypes)
                  ),
              },
              {
                type: 'fileSize',
                validator: value =>
                  validateFileSize(value, PHOTO_UPLOAD_CONFIG.maxSize),
              },
            ],
          },
        ]
      : []),
  ];
};

export const getCourseActions = (
  labels,
  onViewDetails,
  onEdit,
  onDelete,
  shouldRender
) => [
  {
    action: onViewDetails,
    label: labels.viewDetails,
    shouldItemRender: () => true,
    type: 'Details',
    icon: CourseIcon,
  },
  {
    action: onEdit,
    label: labels.edit,
    shouldItemRender: () => shouldRender,
    type: 'Enroll',
    icon: EditIcon,
  },
  {
    action: onDelete,
    label: labels.deleteAction,
    shouldItemRender: () => shouldRender,
    type: 'Delete',
    icon: DeleteIcon,
  },
];
