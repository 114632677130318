export const getSelectedUserIndex = (userId, selectedUsers) =>
  selectedUsers.findIndex(sUser => sUser === userId);

export const isUserSelectDisabled = (
  user,
  selectedUsers,
  maxSelectedUsers,
  isSelected,
  shouldEnableOnlyFinishedUsers
) =>
  !isSelected &&
  ((maxSelectedUsers && selectedUsers.length === maxSelectedUsers) ||
    (shouldEnableOnlyFinishedUsers && !user.finished));

export const getDisabledUserMessage = (translations, user, isTeamReview) => {
  const unfinishedUserMessage = isTeamReview
    ? translations.disabledUserMessageUnfinishedTeam
    : translations.disabledUserMessageUnfinished;

  return !user.finished
    ? unfinishedUserMessage
    : translations.disabledUserMessageLimitReached;
};
