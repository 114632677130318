import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import keycode from 'keycode';
import classNames from 'classnames';
import { TransitionGroup } from 'react-transition-group';
import {
  Paper,
  MenuItem,
  Chip,
  Typography,
  ClickAwayListener,
  Fade,
  alpha,
  withStyles,
} from '@material-ui/core';
import CustomScrollBar from '../customScrollBar';
import SharedIcon from '../sharedIcon';
import InputField from '../inputField';
import UserAvatar from '../userAvatar';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-icon-new.svg';
import { ReactComponent as PrivateIcon } from '../../../assets/icons/private-attribute-icon.svg';
import {
  isArrayEmpty,
  getUnionOfTwoArrays,
  asyncDebounce,
} from '../../../utility/helpers';
import { getPersonFullName, customSearch } from '../../../utility/uiUtils';
import { ellipsis } from '../../../constants/helperCssRules';

const styles = ({ palette: { primary }, spacing }) => ({
  autocompleteWrapper: { position: 'relative' },
  inputNoSpacing: {
    padding: 0,
  },
  error: {
    position: 'absolute',
    bottom: -20,
  },
  paper: {
    boxSizing: 'border-box',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 66,
    zIndex: 100,
    filter: `drop-shadow(0px 4px 10px ${alpha(primary.black, 0.2)})`,
    width: '100%',
  },
  chip: {
    color: primary.bluish2,
    display: 'flex',
    justifyContent: 'center',
    height: 20,
    margin: spacing(0, 0.5, 0.5, 0.5),
    fontFamily: 'ProximaNova-Regular',
    fontSize: 12,
    lineHeight: '14px',
    '&:focus': {
      backgroundColor: primary.bluish7,
    },

    '& div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  chipOutside: {
    borderRadius: 'unset',
    color: primary.bluish1,
    display: 'flex',
    justifyContent: 'center',
    height: 28,
    margin: spacing(0.5, 1, 0.5, 0),
    fontFamily: 'ProximaNova-Bold',
    fontSize: 14,
    lineHeight: '20px',
    maxWidth: '100%',
  },
  chipOutsideAvatar: {
    boxSizing: 'border-box',
    borderBottom: `1px solid ${primary.bluish5}`,
    backgroundColor: primary.white,
    fontFamily: 'ProximaNova-Bold',
    fontSize: 14,
    lineHeight: '18px',
    width: '100%',
    height: 'auto',
    minHeight: 24,
    margin: 0,
    paddingBottom: spacing(4),
    marginBottom: spacing(4),
    '&:focus': {
      backgroundColor: primary.white,
    },
    '&:last-of-type': {
      borderBottom: 'none',
      paddingBottom: 0,
      marginBottom: 0,
    },
  },
  chipWithAvatar: {
    backgroundColor: primary.bluish7,
  },
  deleteIconWrapper: {
    justifyContent: 'center',
    flexShrink: 0,
    width: 16,
    height: 16,
    margin: 0,
    marginRight: spacing(2),
    order: 3,
  },
  close: {
    height: 16,
    width: 16,
    '& path': {
      color: primary.bluish1,
    },
  },
  closeDisabled: {
    cursor: 'default',
  },
  chipRoot: {
    '& $chipAvatar': {
      height: 24,
      width: 24,
      marginLeft: 0,
      marginRight: spacing(-1),
    },
    '& $chipAvatarOutside': {
      height: 24,
      width: 24,
      marginRight: 0,
    },
  },
  chipAvatar: {},
  chipAvatarOutside: {
    flexShrink: 0,
  },
  chipLabel: {
    padding: spacing(0, 1, 0, 3),
  },
  chipLabelOutside: {
    order: 2,
    padding: spacing(1, 2),
  },
  chipLabelAvatar: {
    padding: '1px 6px 0 0',
    marginLeft: spacing(1.5),
    maxWidth: 250,
  },
  chipLabelAvatarOutside: {
    width: '100%',
    maxWidth: '100%',
    padding: 0,
  },
  privateIconDark: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: spacing(2),
    width: 16,
    height: 16,
    order: 1,
    '& g': {
      fill: primary.bluish1,
    },
  },
  itemMenu: {
    boxSizing: 'border-box',
    borderRadius: 4,
    padding: spacing(2),
    height: 40,
    width: '100%',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      backgroundColor: primary.bluish8,
    },
  },
  itemLabel: {
    fontFamily: 'ProximaNova-Regular',
    fontSize: 16,
    lineHeight: '20px',
    color: primary.bluish2,
    textTransform: 'none',
    width: '100%',
    ...ellipsis(),
  },
  menuItemChipValueWrapper: {
    display: 'flex',
    width: '100%',
  },
  privateIcon: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: spacing(2),
  },
  itemLabelAvatar: {
    marginLeft: 0,
    width: '100%',
  },
  bold: {
    fontFamily: 'ProximaNova-Semibold',
  },
  groupMenuItem: {
    position: 'relative',
    overflow: 'visible',
    '&:last-of-type': {
      marginBottom: spacing(4),
      '&::after': {
        backgroundColor: primary.bluish8,
        content: '""',
        position: 'absolute',
        left: 16,
        right: 16,
        top: 55,
        height: 2,
      },
    },
  },
  optionWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  optionIcon: {
    width: 32,
    height: 32,
  },
  noOptionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(4),
  },
  highlighted: {
    backgroundColor: primary.bluish8,
  },
  outsideSelectedItemsWrapper: {
    marginTop: spacing(2),
  },
  outsideSelectedItemsWrapperAvatar: {
    marginTop: spacing(6),
  },
  outsideSelectedItemsWrapperScrollable: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    marginTop: spacing(4),
    width: '100%',
    maxHeight: 252,
  },
  outsideSelectedItems: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  scrollY: {
    top: 8,
    right: -10,
    height: 'calc(100% - 16px)',
    width: 4,
  },
  scrollYSelectedItems: {
    backgroundColor: primary.bluish9,
    top: 0,
    right: -20,
    height: '100%',
    width: 8,
  },
  scroll: {
    backgroundColor: primary.bluish7,
  },
  suggestions: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(4),
    maxHeight: 200,
  },
});

const DELAY = 500;

class Autocomplete extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: props.initialValue || '',
      isOpen: false,
      isLoading: false,
    };
    this.handleAsyncInputChange = asyncDebounce(
      this.handleAsyncInputChange,
      DELAY
    );
  }

  componentDidUpdate(prevProps) {
    const { selectedItems, getSelectedItemValue, isSingleSelect } = this.props;
    const { value } = this.state;
    const isEmptySelectedItems = isArrayEmpty(selectedItems);

    if (!isArrayEmpty(prevProps.selectedItems) && isEmptySelectedItems) {
      this.resetInputValue();
    }
    if (!isEmptySelectedItems) {
      const selectedItem = getSelectedItemValue(selectedItems[0]);

      if (selectedItem !== value) {
        this.setState({
          value: isSingleSelect ? selectedItem : '',
        });
      }
    }
  }

  resetInputValue = () => this.setState({ value: '' });

  getSuggestions = value => {
    const { items, isAsync, hasAvatar } = this.props;

    return isAsync ? items : customSearch(items, value, hasAvatar);
  };

  handleKeyDown = event => {
    const { selectedItems, onChange } = this.props;
    const { value } = this.state;

    if (
      selectedItems.length &&
      !value.length &&
      keycode(event) === 'backspace'
    ) {
      onChange(selectedItems.slice(0, selectedItems.length - 1));
    }
  };

  handleAsyncInputChange = () => {
    const { onInputChange } = this.props;
    const { value } = this.state;

    return onInputChange(value).then(() => this.setState({ isLoading: false }));
  };

  handleInputChange = event => {
    const { isAsync } = this.props;

    const {
      target: { value },
    } = event;

    if (isAsync) {
      return this.setState(
        { value, isLoading: true },
        this.handleAsyncInputChange
      );
    }

    this.setState({ value });
  };

  handleChange = item => {
    const { selectedItems, onChange, isSingleSelect, getSelectedItemValue } =
      this.props;
    let newItems = [];
    if (item && item.option) {
      newItems = getUnionOfTwoArrays(selectedItems, item.option, 'id');
    } else if (
      item &&
      item.id &&
      selectedItems.map(i => i.id).indexOf(item.id) === -1
    ) {
      newItems = [...selectedItems, item];
    }

    this.setState({
      value: isSingleSelect ? getSelectedItemValue(newItems[0]) : '',
    });
    if (newItems.length) {
      onChange(newItems);
    }
  };

  handleToggleButtonClick = () => {
    const {
      isDisabled,
      isSingleSelect,
      shouldRenderSelectedOutside,
      selectedItems,
    } = this.props;

    if (
      !isDisabled &&
      !(
        isSingleSelect &&
        shouldRenderSelectedOutside &&
        selectedItems.length !== 0
      )
    ) {
      this.setState(({ isOpen }) => ({
        isOpen: !isOpen,
      }));
    }
  };

  handleStateChange = changes => {
    if (changes.inputValue) {
      return this.setState({ isOpen: true });
    }
    if (typeof changes.isOpen === 'boolean') {
      this.setState(({ isOpen }) => {
        const newState =
          changes.type === Downshift.stateChangeTypes.mouseUp
            ? isOpen
            : changes.isOpen;
        return { isOpen: newState };
      });
    }
  };

  resetState = () => {
    const { selectedItems, shouldRenderSelectedOutside } = this.props;

    const data = {
      isOpen: false,
    };

    if (shouldRenderSelectedOutside && !isArrayEmpty(selectedItems)) {
      data.value = '';
    }

    this.setState(data);
  };

  handleBlur = e => {
    const { onBlur } = this.props;

    this.resetState();
    onBlur(e);
  };

  handleDelete = itemToDelete => () => {
    const { selectedItems, onChange } = this.props;
    const newItems = selectedItems.filter(item => item.id !== itemToDelete.id);

    onChange(newItems);
  };

  renderSelectedItem = item => {
    const {
      classes,
      hasAvatar,
      isDisabled,
      isAttribute,
      shouldRenderSelectedOutside,
    } = this.props;

    return (
      <Chip
        key={item.id}
        className={classNames(classes.chip, {
          [classes.chipWithAvatar]: hasAvatar && !shouldRenderSelectedOutside,
          [classes.chipOutside]: shouldRenderSelectedOutside,
          [classes.chipOutsideAvatar]: hasAvatar && shouldRenderSelectedOutside,
        })}
        classes={{
          root: classes.chipRoot,
          avatar: classNames(classes.chipAvatar, {
            [classes.chipAvatarOutside]: shouldRenderSelectedOutside,
          }),
          label: classNames(classes.chipLabel, {
            [classes.chipLabelAvatar]: hasAvatar,
            [classes.chipLabelOutside]: shouldRenderSelectedOutside,
            [classes.chipLabelAvatarOutside]:
              shouldRenderSelectedOutside && hasAvatar,
          }),
          deleteIcon: classes.deleteIconWrapper,
        }}
        tabIndex={-1}
        label={hasAvatar ? getPersonFullName(item) : item.name}
        onDelete={!isDisabled ? this.handleDelete(item) : () => {}}
        icon={
          isAttribute && item.is_private ? (
            <SharedIcon
              customIconWrapperClass={classNames({
                [classes.privateIconDark]: isAttribute,
              })}
              icon={PrivateIcon}
              showIcon={isAttribute && item.is_private}
            />
          ) : null
        }
        deleteIcon={
          <div>
            <CloseIcon
              className={classNames(classes.close, {
                [classes.closeDisabled]: isDisabled,
              })}
            />
          </div>
        }
        avatar={
          hasAvatar ? (
            <UserAvatar
              user={item}
              small={shouldRenderSelectedOutside}
              extraSmall={!shouldRenderSelectedOutside}
            />
          ) : null
        }
        style={item.color ? { backgroundColor: item.color } : {}}
      />
    );
  };

  renderSuggestion({ suggestion, itemProps }) {
    const { classes, hasAvatar, selectedItems, isAttribute, organizationUser } =
      this.props;

    const isSelected =
      (selectedItems.map(item => item.id) || '').indexOf(suggestion.id) > -1;
    const labelClasses = classNames(classes.itemLabel, {
      [classes.itemLabelAvatar]: hasAvatar,
      [classes.bold]: isSelected,
    });

    return (
      <MenuItem
        {...itemProps}
        className={classes.itemMenu}
        key={suggestion.id}
        component="div"
      >
        {hasAvatar ? (
          <UserAvatar
            labelClass={labelClasses}
            user={suggestion}
            isOrganizationUser={organizationUser?.id === suggestion.id}
            small
            caption
          />
        ) : (
          <div className={classes.menuItemChipValueWrapper}>
            <Typography component="span" className={labelClasses}>
              {hasAvatar
                ? suggestion.label
                : suggestion.name || getPersonFullName(suggestion)}
            </Typography>
            <SharedIcon
              icon={PrivateIcon}
              showIcon={isAttribute && suggestion.is_private}
              customIconWrapperClass={classes.privateIcon}
            />
          </div>
        )}
      </MenuItem>
    );
  }

  renderSearchMessage = message => {
    const { classes } = this.props;

    return (
      <div className={classes.noOptionsWrapper}>
        <Typography>{message}</Typography>
      </div>
    );
  };

  render() {
    const {
      id,
      classes,
      className,
      label,
      selectedItems,
      errorMessage,
      hasError,
      isDisabled,
      placeholder,
      inputClass,
      customInputRootClass,
      customInputFocusClass,
      customInputDisabledClass,
      name,
      type,
      customErrorClass,
      customMenuClass,
      hasAvatar,
      labelHelp,
      isAttribute,
      noOptionsLabel,
      searchingLabel,
      isSingleSelect,
      shouldRenderSelectedOutside,
      inputEndAdornment,
      isIconOnStart,
      adornmentIcon,
      isScrollable,
    } = this.props;

    const { value, isOpen, isLoading } = this.state;
    const numberOfSelectedItems = selectedItems.length;
    const menuClasses = classNames(classes.paper, customMenuClass);
    const trimmedValue = value.trim().toLowerCase();
    const suggestions = this.getSuggestions(trimmedValue);
    const searchingPlaceholder = `${searchingLabel} ...`;

    return (
      <div className={className}>
        <Downshift
          id={id}
          itemToString={item => (item ? item.first_name : '')}
          inputValue={value}
          onChange={this.handleChange}
          selectedItem={selectedItems}
          onStateChange={this.handleStateChange}
        >
          {({ getInputProps, getItemProps }) => (
            <div className={classes.autocompleteWrapper}>
              <ClickAwayListener onClickAway={this.resetState}>
                <InputField
                  className={inputClass}
                  customRootClass={customInputRootClass}
                  customFocusClass={customInputFocusClass}
                  customDisabledClass={customInputDisabledClass}
                  customInputClass={classNames({
                    [classes.inputNoSpacing]:
                      !shouldRenderSelectedOutside &&
                      (hasAvatar || isAttribute),
                  })}
                  name={name}
                  type={type}
                  label={label}
                  labelHelp={labelHelp}
                  errorMessage={errorMessage}
                  error={hasError}
                  customErrorClass={classNames(classes.error, customErrorClass)}
                  endAdornment={inputEndAdornment}
                  isIconOnStart={isIconOnStart}
                  adornmentIcon={adornmentIcon}
                  disabled={
                    (isSingleSelect &&
                      shouldRenderSelectedOutside &&
                      numberOfSelectedItems !== 0) ||
                    isDisabled
                  }
                  placeholder={
                    numberOfSelectedItems === 0 || shouldRenderSelectedOutside
                      ? placeholder
                      : undefined
                  }
                  {...getInputProps({
                    onChange: this.handleInputChange,
                    onKeyDown: this.handleKeyDown,
                    onBlur: e => this.handleBlur(e),
                  })}
                  onClick={this.handleToggleButtonClick}
                  fullWidth
                />
              </ClickAwayListener>
              {shouldRenderSelectedOutside && !isArrayEmpty(selectedItems) ? (
                <div
                  className={classNames({
                    [classes.outsideSelectedItemsWrapper]: !isScrollable,
                    [classes.outsideSelectedItemsWrapperAvatar]:
                      !isScrollable && hasAvatar,
                    [classes.outsideSelectedItemsWrapperScrollable]:
                      isScrollable,
                  })}
                >
                  <CustomScrollBar
                    customScrollBarYClass={classes.scrollYSelectedItems}
                    customScrollClass={classes.scroll}
                    passContentHeight
                    verticalScroll={isScrollable}
                    removeScrollX
                  >
                    <TransitionGroup
                      className={classNames({
                        [classes.outsideSelectedItems]: !isScrollable,
                      })}
                    >
                      {selectedItems.map(item => (
                        <Fade
                          key={`selected_item_${item.id}`}
                          addEndListener={null}
                          unmountOnExit
                          appear
                        >
                          {this.renderSelectedItem(item)}
                        </Fade>
                      ))}
                    </TransitionGroup>
                  </CustomScrollBar>
                </div>
              ) : null}
              {isOpen ? (
                <Paper className={menuClasses} square>
                  {!isArrayEmpty(suggestions) && !isLoading && (
                    <div className={classes.suggestions}>
                      <CustomScrollBar
                        customScrollBarYClass={classes.scrollY}
                        passContentHeight
                        verticalScroll
                        removeScrollX
                      >
                        <>
                          {suggestions.map(suggestion => {
                            return this.renderSuggestion({
                              suggestion,
                              itemProps: getItemProps({
                                item: suggestion,
                              }),
                            });
                          })}
                        </>
                      </CustomScrollBar>
                    </div>
                  )}
                  {isLoading &&
                    trimmedValue.length !== 0 &&
                    this.renderSearchMessage(searchingPlaceholder)}
                  {isArrayEmpty(suggestions) &&
                    ((isSingleSelect && isArrayEmpty(selectedItems)) ||
                      !isSingleSelect) &&
                    !isLoading &&
                    this.renderSearchMessage(noOptionsLabel)}
                </Paper>
              ) : null}
            </div>
          )}
        </Downshift>
      </div>
    );
  }
}

Autocomplete.defaultProps = {
  id: undefined,
  className: '',
  customErrorClass: '',
  customMenuClass: '',
  errorMessage: '',
  name: '',
  type: '',
  label: '',
  placeholder: '',
  inputClass: '',
  customInputRootClass: '',
  customInputFocusClass: '',
  customInputDisabledClass: '',
  searchingLabel: '',
  labelHelp: {},
  hasError: false,
  isAsync: false,
  hasAvatar: false,
  isSingleSelect: false,
  isDisabled: false,
  shouldRenderSelectedOutside: false,
  isScrollable: false,
  isAttribute: false,
  hasSelectButton: false,
  inputEndAdornment: null,
  isIconOnStart: false,
  adornmentIcon: null,
  organizationUser: null,
  onBlur: () => {},
  onInputChange: () => {},
  getSelectedItemValue: getPersonFullName,
};

Autocomplete.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  inputClass: PropTypes.string,
  customInputRootClass: PropTypes.string,
  customInputFocusClass: PropTypes.string,
  customInputDisabledClass: PropTypes.string,
  customErrorClass: PropTypes.string,
  customMenuClass: PropTypes.string,
  noOptionsLabel: PropTypes.string.isRequired,
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  searchingLabel: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedItems: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.number])
  ).isRequired,
  hasAvatar: PropTypes.bool,
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isAsync: PropTypes.bool,
  isScrollable: PropTypes.bool,
  isSingleSelect: PropTypes.bool,
  labelHelp: PropTypes.object,
  isAttribute: PropTypes.bool,
  adornmentIcon: PropTypes.object,
  inputEndAdornment: PropTypes.object,
  isIconOnStart: PropTypes.bool,
  shouldRenderSelectedOutside: PropTypes.bool,
  hasSelectButton: PropTypes.bool,
  organizationUser: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  getSelectedItemValue: PropTypes.func,
  onBlur: PropTypes.func,
};

export default withStyles(styles)(Autocomplete);
