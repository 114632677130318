import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Typography, Fade, withStyles } from '@material-ui/core';
import ToggleButtonSwitch from '../toggleButtonSwitch';
import CustomRadioGroup from '../customRadioGroup';
import SelectField from '../selectField';
import CustomCheckbox from '../customCheckbox';
import AlertDialog from '../alertDialog';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning-triangle-rounded.svg';
import { isArrayEmpty } from '../../../utility/helpers';
import { ATTRIBUTES } from '../../../constants/attributes';
import { getDialogTranslations } from './config';

const styles = ({ palette: { primary }, spacing }) => ({
  answerStyle: {
    marginBottom: spacing(4),
  },
  titleText: {
    fontFamily: 'ProximaNova-Regular',
    marginBottom: spacing(3),
  },
  layoutType: {
    marginBottom: spacing(8),
  },
  horizontalMessageWarningWrap: {
    height: 44,
    display: 'flex',
    alignItems: 'center',
    background: primary.yellow3,
    borderRadius: 8,
    marginTop: spacing(-2),
    marginBottom: spacing(8),
  },
  warningIcon: {
    width: 20,
    height: 18,
    marginRight: spacing(4),
    marginLeft: spacing(4),
  },
  warningText: {
    lineHeight: '20px',
  },
  answerNumber: {
    marginBottom: spacing(6),
  },
  selectContainer: {
    width: 80,
  },
  withNeutralAnswer: {
    marginBottom: spacing(6),
  },
  highlightedLabel: {
    fontFamily: 'ProximaNova-Bold',
  },
});

const DELAY = 1000;

class QuestionStyle extends PureComponent {
  state = {
    questionStyleData: null,
    field: null,
  };

  handleQuestions = updatedValue => {
    const {
      attribute: { questions, text_reply_questions },
    } = this.props;

    if (text_reply_questions) {
      return questions;
    } else if (updatedValue) {
      return questions.map(question => {
        return {
          ...question,
          answers: question.answers.map(answer => ({
            text: answer.text,
          })),
        };
      });
    }
    return questions.map(question => {
      const answers = question.answers.map(answer => ({
        text: answer.text,
      }));
      answers.pop();
      return {
        ...question,
        answers,
      };
    });
  };

  updateAttributeWithZeroValue = updatedData => {
    const { attribute, updateAttribute, updateAttributeQuestions } = this.props;
    const {
      fields: { withNeutralAnswer },
    } = ATTRIBUTES;

    const questionsToCopy = this.handleQuestions(!attribute[withNeutralAnswer]);

    return updateAttribute(attribute.id, updatedData).then(() => {
      if (!isArrayEmpty(questionsToCopy)) {
        updateAttributeQuestions(attribute.id);
      }
    });
  };

  handleChangeAttributeStyle = (field, updatedValue) => {
    const { attribute, setDialogVisibility, updateAttribute } = this.props;
    const {
      fields: { layoutType, withAdditionalFeedback, withZeroValueAnswer },
    } = ATTRIBUTES;
    const { name } = field;

    if (attribute[name] === updatedValue) return;

    if (
      isArrayEmpty(attribute.questions) ||
      name === layoutType ||
      name === withAdditionalFeedback ||
      name === withZeroValueAnswer
    ) {
      const updatedData = { [name]: updatedValue };

      if (field.dependant) {
        updatedData[field.dependant.name] = updatedValue
          ? field.dependant.resetValue
          : field.dependant.defaultValue;
      }
      if (name === withZeroValueAnswer) {
        return this.updateAttributeWithZeroValue(updatedData);
      }

      return updateAttribute(attribute.id, updatedData);
    }

    this.setState({ field, questionStyleData: updatedValue }, () => {
      setDialogVisibility({
        dialogName: 'questionStyleChangeDialog',
        opened: true,
      });
    });
  };

  getQuestions = () => {
    const {
      attribute: { answer_number, questions, with_neutral_answer },
    } = this.props;
    const { field, questionStyleData } = this.state;
    const {
      fields: {
        answersCount,
        withNeutralAnswer,
        withTextReplyQuestions,
        withZeroValueAnswer,
      },
    } = ATTRIBUTES;
    if (field.name === answersCount) {
      if (answer_number > questionStyleData) {
        return questions.map(question => {
          return {
            ...question,
            answers: question.answers
              .slice(0, questionStyleData)
              .map(answer => ({ text: answer.text })),
          };
        });
      }
      const populateAdditionalAnswers = Array.from(
        { length: questionStyleData - answer_number },
        (_, i) => ({
          text: `${answer_number + 1 + i}`,
        })
      );

      return questions.map(question => {
        if (with_neutral_answer) {
          question.answers.pop();
        }
        return {
          ...question,
          answers: question.answers
            .map(answer => ({ text: answer.text }))
            .concat(populateAdditionalAnswers),
        };
      });
    }

    if ([withNeutralAnswer, withZeroValueAnswer].includes(field.name)) {
      return this.handleQuestions(questionStyleData);
    }

    if (field.name === withTextReplyQuestions) {
      if (questionStyleData) {
        return questions.map(question => {
          const { answers, ...rest } = question;
          return {
            ...rest,
          };
        });
      }
      return questions.map(question => {
        return {
          ...question,
          answers: Array.from(
            { length: field.dependant.defaultValue },
            (_, i) => ({
              text: `${answer_number + 1 + i}`,
            })
          ),
        };
      });
    }
  };

  handleQuestionStyleConfirmation = async () => {
    const {
      attribute: { questions, id },
      deleteAllAttributeQuestions,
      updateAttribute,
      setDialogVisibility,
      setAttributeQuestions,
    } = this.props;
    const { field, questionStyleData } = this.state;

    const updatedData = {
      [field.name]: questionStyleData,
    };

    if (field.dependant) {
      updatedData[field.dependant.name] = questionStyleData
        ? field.dependant.resetValue
        : field.dependant.defaultValue;
    }

    if (!isArrayEmpty(questions)) {
      const questionsToCopy = this.getQuestions(field.name);

      await deleteAllAttributeQuestions(id).then(() => {
        updateAttribute(id, updatedData).then(() => {
          if (!isArrayEmpty(questionsToCopy)) {
            setAttributeQuestions(id, questionsToCopy);
          }
          this.setState({ field: null, questionStyleData: null });
          setDialogVisibility({
            dialogName: 'questionStyleChangeDialog',
            opened: false,
          });
        });
      });
    }
  };

  getDialogTranslationKey = () => {
    const { attribute } = this.props;
    const {
      fields: { withTextReplyQuestions, answersCount, withNeutralAnswer },
      fieldsStyleOptions: {
        increaseAnswersCount,
        decreaseAnswersCount,
        toOpenEndedQuestions,
        toMultipleChoiceQuestions,
        addNeutralAnswer,
        removeNeutralAnswer,
      },
    } = ATTRIBUTES;
    const { field, questionStyleData } = this.state;

    if (field?.name === withTextReplyQuestions) {
      return questionStyleData
        ? toOpenEndedQuestions
        : toMultipleChoiceQuestions;
    }

    if (field?.name === answersCount) {
      if (attribute.answer_number > questionStyleData) {
        return decreaseAnswersCount;
      }
      return increaseAnswersCount;
    }

    if (field?.name === withNeutralAnswer) {
      return questionStyleData ? addNeutralAnswer : removeNeutralAnswer;
    }
  };

  render() {
    const {
      fieldData,
      classes,
      translations,
      dialogs,
      setDialogVisibility,
      attribute,
      isWarningOn,
    } = this.props;
    const { questionStyleData } = this.state;
    const { formLabels } = translations;
    const { questionStyleChangeDialogOpened } = dialogs;
    const {
      answerStyle,
      answerNumber,
      layoutType,
      neutralAnswer,
      zeroValueAnswer,
      additionalFeedback,
    } = fieldData;
    const isVertical = attribute.layout === ATTRIBUTES.verticalLayout;
    const {
      fields: { withTextReplyQuestions },
      fieldsStyleOptions: { decreaseAnswersCount },
    } = ATTRIBUTES;
    const hasDefinedAnswers = !attribute[withTextReplyQuestions];
    const displayWarning = hasDefinedAnswers && !isVertical && isWarningOn;
    const translationKey = this.getDialogTranslationKey();
    const numberOfAnswersToDelete =
      translationKey === decreaseAnswersCount
        ? attribute.answer_number - questionStyleData
        : null;

    return (
      <>
        <div key={fieldData.name}>
          <Typography variant="h4" className={classes.titleText}>
            {formLabels[fieldData.title.translationKey].label}
          </Typography>
          <ToggleButtonSwitch
            className={classes.answerStyle}
            value={attribute[answerStyle.name]}
            items={answerStyle.items}
            onSelect={newValue =>
              this.handleChangeAttributeStyle(answerStyle, newValue)
            }
            isFullWidth
          />
          {hasDefinedAnswers && (
            <SelectField
              className={classes.answerNumber}
              selectFieldClass={classes.selectContainer}
              labelClass={classes.highlightedLabel}
              label={formLabels[answerNumber.translationKey].label}
              labelHelp={formLabels[answerNumber.translationKey]?.labelHelp}
              name={answerNumber.name}
              options={answerNumber.options || []}
              parser={answerNumber.parser}
              value={attribute[answerNumber.name]}
              onChange={newValue =>
                this.handleChangeAttributeStyle(answerNumber, newValue)
              }
              isOptionValueCentered
              isFullWidth
              isSearchDisabled
            />
          )}
          <div className={classes.withNeutralAnswer}>
            <CustomCheckbox
              isChecked={attribute[additionalFeedback.name]}
              label={formLabels[additionalFeedback.translationKey].fieldLabel}
              labelText={formLabels[additionalFeedback.translationKey].label}
              onChange={newValue =>
                this.handleChangeAttributeStyle(additionalFeedback, newValue)
              }
              isHighlightedLabel
              smallText
              isControlled
            />
          </div>
          <div className={classes.withNeutralAnswer}>
            <CustomCheckbox
              isChecked={attribute[neutralAnswer.name]}
              label={formLabels[neutralAnswer.translationKey].fieldLabel}
              labelText={formLabels[neutralAnswer.translationKey].label}
              onChange={newValue =>
                this.handleChangeAttributeStyle(neutralAnswer, newValue)
              }
              isHighlightedLabel
              smallText
              isControlled
            />
          </div>
          {hasDefinedAnswers && (
            <div className={classes.withNeutralAnswer}>
              <CustomCheckbox
                isChecked={attribute[zeroValueAnswer.name]}
                label={formLabels[zeroValueAnswer.translationKey].fieldLabel}
                labelText={formLabels[zeroValueAnswer.translationKey].label}
                onChange={newValue =>
                  this.handleChangeAttributeStyle(zeroValueAnswer, newValue)
                }
                labelHelp={formLabels[zeroValueAnswer.translationKey].labelHelp}
                isHighlightedLabel
                smallText
                isControlled
              />
            </div>
          )}
          {hasDefinedAnswers && (
            <CustomRadioGroup
              className={classes.layoutType}
              label={formLabels[layoutType.name].label}
              value={attribute[layoutType.name]}
              options={layoutType.options}
              onChange={newValue =>
                this.handleChangeAttributeStyle(layoutType, newValue)
              }
            />
          )}
          {displayWarning && (
            <Fade in={displayWarning} timeout={DELAY}>
              <div className={classes.horizontalMessageWarningWrap}>
                <WarningIcon className={classes.warningIcon} />
                <Typography className={classes.warningText}>
                  {translations.horizontalMessageWarning}
                </Typography>
              </div>
            </Fade>
          )}
        </div>
        <AlertDialog
          translations={getDialogTranslations(
            translations.questionsForm.questionStyleChangeDialog,
            translationKey,
            numberOfAnswersToDelete
          )}
          isOpened={questionStyleChangeDialogOpened}
          onClose={() => {
            this.setState({ questionStyleData: null, field: null }, () => {
              setDialogVisibility({
                dialogName: 'questionStyleChangeDialog',
                opened: false,
              });
            });
          }}
          onConfirm={this.handleQuestionStyleConfirmation}
        />
      </>
    );
  }
}

QuestionStyle.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  fieldData: PropTypes.object.isRequired,
  attribute: PropTypes.object.isRequired,
  setDialogVisibility: PropTypes.func.isRequired,
  dialogs: PropTypes.object.isRequired,
  isWarningOn: PropTypes.bool.isRequired,
  updateAttribute: PropTypes.func.isRequired,
  deleteAllAttributeQuestions: PropTypes.func.isRequired,
  setAttributeQuestions: PropTypes.func.isRequired,
  updateAttributeQuestions: PropTypes.func.isRequired,
};

export default withStyles(styles)(QuestionStyle);
