import { memo } from 'react';
import classNames from 'classnames';
import { TextField, makeStyles } from '@material-ui/core';
import ControlInput from './controlInput';
import Icon from './icon';

const useStyles = makeStyles(({ palette: { primary } }) => ({
  root: {
    backgroundColor: primary.white,
  },
  disabledSearch: {
    cursor: 'pointer',
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 38,
  },
}));

const Control = props => {
  const classes = useStyles();
  const {
    startIcon,
    isSearchable,
    controlClass,
    controlFocusClass,
    controlDisabledClass,
    textFieldProps,
    hasError,
    errorAdornment,
  } = props.selectProps;

  return (
    <TextField
      InputProps={{
        inputComponent: ControlInput,
        disableUnderline: true,
        classes: {
          root: classNames(
            classes.root,
            {
              [classes.disabledSearch]: !isSearchable,
            },
            controlClass
          ),
          focused: controlFocusClass,
          disabled: controlDisabledClass,
        },
        startAdornment: startIcon ? <Icon startIcon={startIcon} /> : undefined,
        endAdornment: errorAdornment,
        inputProps: {
          className: classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...textFieldProps}
      error={hasError}
      disabled={props.isDisabled}
      fullWidth
    />
  );
};

export default memo(Control);
