import { createAction, handleActions } from 'redux-actions';
import http from '../../utility/http';
import {
  API_LEVELING,
  API_LEVELS,
  API_TRACKS,
} from '../../constants/apiRoutes';
// ------------------------------------
// Constants
// ------------------------------------

const SET_LEVELS_AND_TRACKS = 'SET_LEVELS_AND_TRACKS';

const initialState = {
  levels: [],
  tracks: [],
};

// ------------------------------------
// Actions
// ------------------------------------
const setLevelsAndTracks = createAction(SET_LEVELS_AND_TRACKS);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_LEVELS_AND_TRACKS]: (state, { payload }) => {
    return {
      ...state,
      ...payload,
    };
  },
};

// ------------------------------------
// API calls
// ------------------------------------

export const getLevelsAndTracks = dispatch => {
  return http.get(API_LEVELING).then(({ data }) => {
    dispatch(setLevelsAndTracks(data));
  });
};

export const addLevels = (dispatch, levels) => {
  return http.post(API_LEVELS, levels).then(({ data }) => {
    return data;
  });
};

export const addTracks = (dispatch, levelsAndTracks) => {
  return http.post(API_TRACKS, levelsAndTracks.tracks).then(({ data }) => {
    return dispatch(
      setLevelsAndTracks({ levels: levelsAndTracks.levels, tracks: data })
    );
  });
};

export default handleActions(reducers, initialState);

// ------------------------------------
// "plain" action functions
// ------------------------------------

export const clearLevelsAndTracks = () => setLevelsAndTracks(initialState);
