import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import PreviewDescriptionDialog from "../../shared/previewDescriptionDialog";
import Filters from "../../shared/filters";
import NotificationCard from '../../shared/notificationCard';
import Search from '../../shared/search';
import CustomButton from '../../shared/customButton';
import CustomFormDrawer from '../../shared/customFormDrawer';
import JobTitleItem from '../../shared/jobTitleItem';
import AlertDialog from '../../shared/alertDialog';
import VisibleForPermission from '../../shared/visibleForPermission';
import PlaceholderButton from '../../shared/placeholderButton';
import http from '../../../utility/http';
import {
  trimString,
  isPermissionGranted,
  canSeeEmployeeProfile,
  isArrayEmpty,
  isArray,
  getObjectToNumberArray,
  isObjectEmpty,
  checkUserRole,
} from '../../../utility/helpers';
import {
  EVENT_ACTION_TYPES,
  tagManagerDataLayer,
} from '../../../utility/tagManager';
import {
  parseDuplicateParameters,
  showSuccessMessage,
  parseQueryParams,
} from '../../../utility/uiUtils';
import { onSaveCreatableTag } from '../../../utility/tagUtils';
import { sticky } from '../../../constants/helperCssRules';
import { PERMISSIONS, ROLES } from '../../../constants/rolesAndPermissionList';
import { GENERAL_CATEGORY_ID } from '../../../constants/tags';
import {
  PARAMS,
  PAGE_WHITELISTED_PARAMS,
  JOB_TITLES_DEFAULT_PARAMS,
} from '../../../constants/pages';
import {
  API_POSITIONS,
  api_position,
  API_USERS,
  API_IMPORT_POSITIONS,
} from '../../../constants/apiRoutes';
import {
  getJobTitleFields,
  getPageFilters,
  INITIAL_JOB_TITLE_DATA,
  USERS_DEFAULT_PARAMS,
} from './config';
import { ReactComponent as PlusIconWhite } from '../../../assets/icons/plus-icon-white.svg';
import { hasSelectedFilters } from "../../shared/filters/config";

const styles = ({ palette: { primary }, spacing }) => ({
  form: {
    display: 'grid',
    gridColumnGap: 24,
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    gridTemplateAreas: `
      "name ."
      "attributes tags"
      "description description"
    `,
  },
  name: {
    gridArea: 'name',
  },
  attributes: {
    gridArea: 'attributes',
  },
  tags: {
    gridArea: 'tags',
  },
  descriptionSection: {
    gridArea: 'description',
    '& .ql-container': {
      height: 376,
    },
    '& > div': {
      height: 445,
    },
  },
  stickySubHeader: {
    ...sticky(primary.white, 105),
  },
  subHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(8, 0, 4, 0),
  },
  description: {
    padding: spacing(8, 0, 6, 0),
  },
  actionsWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  search: {
    width: 300,
  },
  addButton: {
    marginLeft: spacing(2),
    width: 'max-content',
  },
  jobTitles: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: spacing(2),
  },
  placeholderButton: {
    height: 'inherit',
  },
});

const { SEARCH, TAGS } = PARAMS;

class JobTitlesPage extends PureComponent {
  state = {
    isInitialLoad: true,
    isLoading: false,
    isLoadingImport: false,
    isOpened: false,
    isEdit: false,
    isDialogOpened: false,
    [SEARCH]: '',
    filters: {
      [TAGS]: [],
    },
    initialData: INITIAL_JOB_TITLE_DATA,
    isDelete: false,
    jobTitle: null,
  };

  componentDidMount() {
    this.getInitialData();
  }

  componentWillUnmount() {
    const {
      clearJobTitles,
      clearAttributesWithQuestions,
      clearTagsCategories,
      clearPageQuickFilters,
    } = this.props;

    clearJobTitles();
    clearAttributesWithQuestions();
    clearTagsCategories();
    clearPageQuickFilters();
  }

  getInitialData = () => {
    const {
      location,
      getPageQuickFilters,
      getTagsCategories,
      getAttributesWithQuestions,
    } = this.props;
    const params = parseQueryParams(
      location.search,
      PAGE_WHITELISTED_PARAMS.JOB_TITLES
    );

    if (!isObjectEmpty(params)) {
      return this.setState(
        {
          [SEARCH]: params[SEARCH] || '',
          filters: {
            [TAGS]: params[TAGS]
              ? [...(isArray(params[TAGS]) ? params[TAGS] : [params[TAGS]])]
              : [],
          },
        },
        () => {
          return Promise.all([
            this.handleGetJobTitles(),
            getAttributesWithQuestions({ is_private: false }),
            getTagsCategories(),
            getPageQuickFilters(),
          ]).then(() => {
            this.setState({ isInitialLoad: false });
          });
        }
      );
    }

    return Promise.all([
      this.handleGetJobTitles(),
      getAttributesWithQuestions({ is_private: false }),
      getTagsCategories(),
      getPageQuickFilters(),
    ]).then(() => {
      this.setState({ isInitialLoad: false });
    });
  };

  handleGetJobTitles = () => {
    const { history, location, getJobTitles } = this.props;
    const { filters, search } = this.state;

    const params = {
      ...filters,
      ...(search ? { search } : {}),
    };
    const query = parseDuplicateParameters(params);

    if (location.search !== `?${query}`) {
      history.replace(`/job-titles${query ? `/?${query}` : ''}`);
    }

    return getJobTitles(params).then(() => this.setState({ isLoading: false }));
  };

  canSeeUserProfile = user => {
    const { auth, organizationSettings } = this.props;

    return canSeeEmployeeProfile(
      auth,
      user.id,
      organizationSettings.global_see_himself
    );
  };

  getUsersWithAssignedJobTitle = (jobTitleId, pageSize) => () => {
    const params = {
      ...USERS_DEFAULT_PARAMS,
      [PARAMS.PAGE_SIZE]: pageSize,
      [PARAMS.JOB_TITLE]: jobTitleId,
    };

    return http.get(API_USERS, { params });
  };

  handleOpenJobTitleForm = (
    isEdit = false,
    initialData = INITIAL_JOB_TITLE_DATA
  ) => this.setState({ isOpened: true, isEdit, initialData });

  handleCloseJobTitleForm = () =>
    this.setState({
      isOpened: false,
      isEdit: false,
      initialData: INITIAL_JOB_TITLE_DATA,
      jobTitle: null,
      isDelete: false,
    });

  onSearch = value => {
    const text = trimString(value);

    this.setState(
      { [PARAMS.SEARCH]: text, isLoading: true },
      this.handleGetJobTitles
    );
  };

  handleApplyFilters = filters =>
    this.setState({ filters, isLoading: true }, this.handleGetJobTitles);

  handleAddJobTitle = values => {
    const { translations } = this.props;
    const { isEdit } = this.state;

    const data = {
      ...values,
      name: trimString(values.name),
      description: values.description ? trimString(values.description) : null,
    };

    if (isEdit) {
      return http.patch(api_position(data.id), data).then(() => {
        this.handleGetJobTitles();
        showSuccessMessage(translations.successMessages.edit);
      });
    }
    const { AddJobTitle } = EVENT_ACTION_TYPES;
    return http.post(API_POSITIONS, data).then(() => {
      tagManagerDataLayer(AddJobTitle.action, AddJobTitle.name, values.name);
      this.handleGetJobTitles();
      showSuccessMessage(translations.successMessages.add);
    });
  };

  toggleAlertDialogVisibility = () => {
    this.setState(prevState => ({ isDelete: !prevState.isDelete }));
  };

  onDelete = jobTitle => {
    this.setState({ jobTitle, isDelete: true });
  };

  onDeleteJobTitle = () => {
    const { translations } = this.props;
    const { jobTitle } = this.state;

    return http.delete(api_position(jobTitle.id)).then(() => {
      this.handleGetJobTitles();
      this.handleCloseJobTitleForm();
      showSuccessMessage(translations.deleteJobTitleDialog.deleteSuccess);
    }, this.toggleAlertDialogVisibility);
  };

  onCreateTag = async tagName => {
    const { addMultipleTags } = this.props;
    const tags = await onSaveCreatableTag(tagName);
    const [tag] = tags.create;

    addMultipleTags({ categoryId: GENERAL_CATEGORY_ID, tags });

    return tag;
  };

  handlePreviewClick = async (e, jTitle) => {
    const { isDialogOpened } = this.state;

    if (!isDialogOpened) {
      e.stopPropagation();

      this.setState({ isDialogOpened: true, initialData: jTitle });
    } else {
      this.setState({
        isDialogOpened: false,
        initialData: INITIAL_JOB_TITLE_DATA,
      });
    }
  };

  importRecommendedPositions = () => {
    const { setJobTitles } = this.props;

    this.setState({ isLoadingImport: true });
    return http
      .get(API_IMPORT_POSITIONS, {
        params: JOB_TITLES_DEFAULT_PARAMS,
      })
      .then(({ data }) => {
        setJobTitles(data);
        this.setState({ isLoadingImport: false });
      });
  };

  renderJobTitles = isReadOnly => {
    const { classes, jobTitles, translations, goToProfilePage, auth } =
      this.props;
    const { search, isLoading, isLoadingImport, filters } = this.state;
    const isAdmin = checkUserRole(auth.role, ROLES.ADMIN);
    const noResultsMessage = isAdmin ? translations.noResultsMessage : '';
    const filterIsActive = hasSelectedFilters(filters);

    return (
      <>
        {isArray(jobTitles) && !isArrayEmpty(jobTitles) && (
          <div className={classes.jobTitles}>
            {!isReadOnly && (
              <PlaceholderButton
                className={classes.placeholderButton}
                label={translations.addJobTitleForm.add}
                onAdd={() => this.handleOpenJobTitleForm()}
              />
            )}
            {jobTitles.map(jTitle => (
              <JobTitleItem
                key={`job_title_${jTitle.id}`}
                data={jTitle}
                labels={translations.labels}
                isReadOnly={isReadOnly}
                handlePreviewClick={e => this.handlePreviewClick(e, jTitle)}
                canSeeUserProfile={this.canSeeUserProfile}
                goToUserProfilePage={goToProfilePage}
                getUsers={this.getUsersWithAssignedJobTitle(
                  jTitle.id,
                  jTitle.people_count
                )}
                onJobTitleClick={() =>
                  this.handleOpenJobTitleForm(true, {
                    ...jTitle,
                    tags: getObjectToNumberArray(jTitle.tags),
                    attributes: getObjectToNumberArray(jTitle.attributes),
                  })
                }
              />
            ))}
          </div>
        )}
        {!isLoading && isArray(jobTitles) && isArrayEmpty(jobTitles) && (
          <NotificationCard
            title={search || filterIsActive ? '' : translations.noResultsTitle}
            content={
              search || filterIsActive
                ? translations.noSearchResultsMessage
                : noResultsMessage
            }
            actionButtons={
              isAdmin && !search && !filterIsActive
                ? [
                    {
                      label: translations.addRecommendedLabel,
                      type: 'rounded',
                      startIcon: <PlusIconWhite />,
                      onButtonClick: this.importRecommendedPositions,
                    },
                  ]
                : []
            }
            isLoading={isLoadingImport}
            loadingText={translations.loadingImportText}
          />
        )}
      </>
    );
  };

  render() {
    const {
      classes,
      grantedPermissions,
      translations,
      allAttributes,
      categories,
      pageQuickFilters,
    } = this.props;
    const {
      isInitialLoad,
      isOpened,
      initialData,
      isEdit,
      isDelete,
      isDialogOpened,
      filters,
      search,
    } = this.state;
    const { canAddJobTitle } = PERMISSIONS;
    const isReadOnly = !isPermissionGranted(canAddJobTitle, grantedPermissions);

    return (
      !isInitialLoad && (
        <div>
          <div className={classes.stickySubHeader}>
            <Typography variant="body2" className={classes.description}>
              {isReadOnly
                ? translations.descriptionReadOnly
                : translations.description}
            </Typography>
            <div className={classes.subHeader}>
              <Filters
                translations={translations.filters}
                selectedFilters={filters}
                filters={getPageFilters(
                  translations,
                  categories,
                  pageQuickFilters
                )}
                onApplyFilters={this.handleApplyFilters}
              />
              <div className={classes.actionsWrapper}>
                <Search
                  className={classes.search}
                  placeholder={translations.search}
                  value={search}
                  onChange={this.onSearch}
                />
                <VisibleForPermission
                  permission={canAddJobTitle}
                  permissions={grantedPermissions}
                >
                  <CustomButton
                    className={classes.addButton}
                    type="addRoundedNew"
                    onClick={() => this.handleOpenJobTitleForm()}
                  >
                    {translations.addButtonLabel}
                  </CustomButton>
                </VisibleForPermission>
              </div>
            </div>
          </div>
          {this.renderJobTitles(isReadOnly)}
          <PreviewDescriptionDialog
            isOpened={isDialogOpened}
            title={initialData.name}
            description={initialData.description}
            onClose={() => {
              this.setState({
                isDialogOpened: false,
                initialData: INITIAL_JOB_TITLE_DATA,
              });
            }}
          />
          <CustomFormDrawer
            customFormClass={classes.form}
            initialData={initialData}
            isOpened={isOpened}
            isInitialValid={isEdit}
            translations={translations.addJobTitleForm}
            fields={getJobTitleFields(this.onCreateTag, classes)}
            categories={categories}
            allAttributes={allAttributes}
            onClose={this.handleCloseJobTitleForm}
            onSave={this.handleAddJobTitle}
            onDelete={this.onDelete}
            isMiddleWidth
          />
          <AlertDialog
            translations={translations.deleteJobTitleDialog}
            isOpened={isDelete}
            onClose={this.toggleAlertDialogVisibility}
            onConfirm={this.onDeleteJobTitle}
            isWarning
          />
        </div>
      )
    );
  }
}

JobTitlesPage.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  grantedPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  organizationSettings: PropTypes.object.isRequired,
  jobTitles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  allAttributes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pageQuickFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
  getJobTitles: PropTypes.func.isRequired,
  getAttributesWithQuestions: PropTypes.func.isRequired,
  getTagsCategories: PropTypes.func.isRequired,
  getPageQuickFilters: PropTypes.func.isRequired,
  addMultipleTags: PropTypes.func.isRequired,
  clearJobTitles: PropTypes.func.isRequired,
  clearAttributesWithQuestions: PropTypes.func.isRequired,
  goToProfilePage: PropTypes.func.isRequired,
  clearTagsCategories: PropTypes.func.isRequired,
  clearPageQuickFilters: PropTypes.func.isRequired,
};

export default withStyles(styles)(JobTitlesPage);
