import { memo } from 'react';
import PropTypes from 'prop-types';
import { Button, Fab, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import SendIcon from '../../../assets/icons/email-send-1.svg';
import EditIcon from '../../../assets/icons/pencil.svg';
import EditIconDisabled from '../../../assets/icons/pencil-white.svg';
import AddIcon from '../../../assets/icons/shape.svg';
import PlusIcon from '../../../assets/icons/plus.svg';
import PlusIconWhite from '../../../assets/icons/plus-icon-white.svg';
import PlusIconDark from '../../../assets/icons/plus-icon.svg';
import PlusIconBlue from '../../../assets/icons/plus-blue.svg';
import AddCircleIcon from '../../../assets/icons/add-circle-icon.svg';
import AddCircleDarkIcon from '../../../assets/icons/add-circle-dark.svg';
import QuestionCloudIcon from '../../../assets/icons/question-cloud.svg';
import BackIcon from '../../../assets/icons/arrow-left-dark.svg';
import FilterIcon from '../../../assets/icons/filter-icon.svg';
import ShareIcon from '../../../assets/icons/share.svg';
import PeopleIcon from '../../../assets/icons/person-search.svg';
import FillSurveyArrowIcon from '../../../assets/icons/arrow-right-blue.svg';

const buttonTypes = {
  large: {
    props: { size: 'large' },
  },
  small: {
    props: { size: 'small' },
  },
  smallOutlined: {
    props: { size: 'small', variant: 'outlined' },
  },
  add: {
    props: {
      variant: 'circular',
      disableRipple: true,
      disableFocusRipple: true,
    },
    icon: AddIcon,
    style: 'add',
  },
  rounded: {
    props: { size: 'small' },
    style: 'rounded',
  },
  textRoundedSmall: {
    props: { size: 'small', variant: 'outlined' },
    style: 'textRoundedSmall',
  },
  addWithTextRounded: {
    props: { size: 'small', variant: 'outlined' },
    style: 'addWithTextRounded',
  },
  fillSurvey: {
    props: { size: 'small' },
    icon: QuestionCloudIcon,
    style: 'fillSurvey',
  },
  fillSurveySmall: {
    props: { size: 'small', variant: 'outlined' },
    icon: FillSurveyArrowIcon,
    style: 'fillSurveySmall',
  },
  send: {
    icon: SendIcon,
    style: 'send',
  },
  edit: {
    icon: EditIcon,
    disabledIcon: EditIconDisabled,
    style: 'other',
  },
  addRoundedBlue: {
    icon: PlusIconBlue,
    disabledIcon: PlusIcon,
    style: 'editRounded',
  },
  textRoundedLarge: {
    props: { size: 'large', variant: 'outlined' },
    style: 'textRoundedLarge',
  },
  addWithTextDark: {
    props: { size: 'medium' },
    icon: AddCircleIcon,
    style: 'addWithTextDark',
  },
  addWithTextDarkNew: {
    props: { size: 'large', variant: 'outlined' },
    icon: AddCircleDarkIcon,
    style: 'addWithTextDarkNew',
  },
  addWithTextDarkSlimNew: {
    props: { size: 'large', variant: 'outlined' },
    icon: PlusIconDark,
    style: 'addWithTextDarkSlimNew',
  },
  addDarkRoundedOutlined: {
    props: { size: 'medium', variant: 'outlined' },
    icon: PlusIconDark,
    style: 'addDarkRoundedOutlined',
  },
  shareDarkRoundedOutlined: {
    props: { size: 'medium', variant: 'outlined' },
    icon: ShareIcon,
    style: 'addDarkRoundedOutlined',
  },
  peopleDarkRoundedOutlined: {
    props: { size: 'medium', variant: 'outlined' },
    icon: PeopleIcon,
    style: 'addDarkRoundedOutlined',
    iconStyle: 'peopleIcon',
  },
  addRoundedNew: {
    props: { size: 'medium', color: 'primary' },
    icon: PlusIconWhite,
    style: 'addRoundedNew',
  },
  withTextDarkRounded: {
    props: { size: 'medium' },
    style: 'withTextDarkRounded',
  },
  withTextLightRounded: {
    props: { size: 'medium' },
    style: 'withTextLightRounded',
  },
  back: {
    props: { size: 'medium' },
    icon: BackIcon,
    style: 'backRounded',
  },
  backNoIcon: {
    props: { size: 'medium' },
    style: 'backRounded',
  },
  filters: {
    props: { size: 'medium' },
    disabledIconStyle: 'disabledFilterIcon',
    icon: FilterIcon,
    style: 'filters',
  },
};

const useStyles = makeStyles(({ palette: { primary } }) => ({
  send: {
    padding: '0 10px',
    minHeight: '27px',
    height: '27px',
    color: primary.white,
    backgroundColor: primary.blue2,
    '&:hover': {
      backgroundColor: primary.blue2,
    },
    '&:disabled': {
      backgroundColor: primary.bluish7,
    },
  },
  other: {
    padding: '0 10px',
    minHeight: '31px',
    height: '31px',
    border: `2px solid ${primary.blue2}`,
    color: primary.blue2,
    backgroundColor: primary.white,
    '&:hover': {
      backgroundColor: primary.white,
    },
    '&:disabled': {
      backgroundColor: primary.bluish7,
      border: `2px solid ${primary.bluish7}`,
    },
  },
  editRounded: {
    padding: '8px 16px',
    minHeight: '31px',
    height: '34px',
    border: `1px solid ${primary.bluish4}`,
    color: primary.bluish4,
    backgroundColor: primary.white,
    '&:hover': {
      backgroundColor: primary.white,
    },
    '&:disabled': {
      backgroundColor: primary.bluish7,
      border: `2px solid ${primary.bluish7}`,
    },
    borderRadius: 16,
  },
  add: {
    height: '40px',
    width: '40px',
    backgroundColor: primary.white,
    '&:hover': {
      backgroundColor: primary.white,
    },
    boxShadow: 'none',
  },
  fillSurvey: {
    padding: '4px 8px',
    '& img': {
      width: 20,
      height: 18,
    },
  },
  fillSurveySmall: {
    borderRadius: 14,
    border: 'none',
    color: primary.blue1,
    padding: '2px 8px',
    fontFamily: 'ProximaNova-Bold',
    maxHeight: 28,
    minHeight: 28,
    textTransform: 'capitalize',
    '& img': {
      width: 16,
      height: 16,
    },
  },
  textRoundedSmall: {
    borderRadius: 14,
    border: 'none',
    color: primary.blue1,
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: 'normal',
    fontFamily: 'ProximaNova-Bold',
    padding: '2px 8px',
    maxHeight: 28,
    minHeight: 28,
    textTransform: 'capitalize',
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    marginRight: 8,
    width: 24,
    height: 24,
  },
  buttonTextLightBlue: {
    color: primary.blue4,
  },
  addDarkRoundedOutlined: {
    boxSizing: 'border-box',
    borderRadius: 44,
    border: `1px solid ${primary.bluish1}`,
    color: primary.bluish4,
    padding: '7px 16px',
    maxHeight: 40,
    '&:disabled': {
      border: `2px solid ${primary.bluish7}`,
      '& span': {
        color: primary.white,
        '& img': {
          opacity: 0.2,
        },
      },
    },
  },
  addRoundedNew: {
    boxSizing: 'border-box',
    borderRadius: 44,
    padding: '8px 16px',
    maxHeight: 40,
  },
  buttonBoldText: {
    padding: 0,
    textTransform: 'uppercase',
    fontFamily: 'ProximaNova-Bold',
    fontSize: '12px',
    lineHeight: '14px',
  },
  addWithText: {
    padding: 0,
    backgroundColor: primary.white,
    color: primary.blue4,
    '&:hover': {
      backgroundColor: primary.white,
    },
  },
  rounded: {
    backgroundColor: primary.blue1,
    boxSizing: 'border-box',
    border: `1px solid ${primary.blue1}`,
    borderRadius: 44,
    color: primary.white,
    fontFamily: 'ProximaNova-Bold',
    fontSize: 14,
    lineHeight: '24px',
    padding: '4px 16px',
    maxHeight: 32,
    textTransform: 'initial',
    letterSpacing: 'initial',
    '&:disabled': {
      border: '1px solid transparent',
    },
  },
  addWithTextRounded: {
    boxSizing: 'border-box',
    border: `1px solid ${primary.bluish7}`,
    borderRadius: 14,
    color: primary.bluish5,
    fontFamily: 'ProximaNova-Regular',
    fontSize: 14,
    lineHeight: '17px',
    padding: '4px 12px',
    maxHeight: 27,
    textTransform: 'initial',
    letterSpacing: 'initial',
  },
  textRoundedLarge: {
    maxHeight: '350px',
    boxSizing: 'border-box',
    padding: '24px',
    fontFamily: 'ProximaNova-Bold',
    color: primary.black,
    border: `1px solid ${primary.bluish7}`,
    borderRadius: 8,
  },
  addWithTextDark: {
    maxHeight: '40px',
    boxSizing: 'border-box',
    backgroundColor: primary.blue2,
    padding: '8px',
    border: `2px solid ${primary.blue2}`,
    borderRadius: 4,
  },
  addWithTextDarkNew: {
    boxSizing: 'border-box',
    border: `1px solid ${primary.bluish6}`,
    borderRadius: 'unset',
    minHeight: 50,
    backgroundColor: primary.bluish9,
    padding: '12px 14px',

    '&:hover': {
      backgroundColor: primary.bluish9,
    },

    '& img': {
      width: 16,
      height: 16,
    },
  },
  addWithTextDarkSlimNew: {
    borderRadius: 8,
    height: 40,
    minHeight: 40,
    backgroundColor: primary.bluish8,
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    color: primary.bluish1,
    fontSize: 16,
    lineHeight: '24px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: primary.bluish8,
    },
    '& img': {
      width: 14,
      height: 14,
    },
    '&:disabled': {
      backgroundColor: primary.bluish7,
      border: `2px solid ${primary.bluish7}`,
      '& span': {
        backgroundColor: primary.bluish7,
      },
    },
  },
  withTextDarkRounded: {
    maxHeight: '40px',
    boxSizing: 'border-box',
    backgroundColor: primary.blue2,
    padding: '8px',
    border: `2px solid ${primary.blue2}`,
    borderRadius: 44,
    textTransform: 'none',
    textAlign: 'center',
    '&:disabled': {
      backgroundColor: primary.bluish7,
      border: `2px solid ${primary.bluish7}`,
    },
  },
  withTextLightRounded: {
    maxHeight: '40px',
    boxSizing: 'border-box',
    backgroundColor: primary.white,
    padding: '8px',
    border: `1px solid ${primary.bluish2}`,
    borderRadius: 44,
    textTransform: 'none',
    textAlign: 'center',
    color: primary.bluish1,
    '&:hover': {
      backgroundColor: primary.white,
    },
    '&:disabled': {
      backgroundColor: primary.bluish7,
      border: `2px solid ${primary.bluish7}`,
    },
  },
  buttonTextDark: {
    height: '21px',
    lineHeight: '25px',
    fontSize: '14px',
    textTransform: 'uppercase',
    letterSpacing: 0,
  },
  buttonTextDarkNew: {
    color: primary.bluish1,
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: 'normal',
    height: 24,
    textTransform: 'none',
  },
  buttonTextNew: {
    color: primary.white,
    fontFamily: 'ProximaNova-Bold',
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: 'normal',
    height: 24,
    textTransform: 'none',
  },
  buttonSemiBoldText: {
    padding: 0,
    textTransform: 'none',
    fontFamily: 'ProximaNova-SemiBold',
    fontSize: '12px',
    lineHeight: '14px',
  },
  backRounded: {
    boxSizing: 'border-box',
    maxHeight: '40px',
    backgroundColor: 'transparent',
    padding: '8px 16px',
    border: '1px solid transparent',
    borderRadius: 44,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  backRoundedLabel: {
    color: primary.bluish1,
    fontFamily: 'ProximaNova-Bold',
    fontSize: 16,
    lineHeight: '24px',
    textTransform: 'capitalize',
    marginLeft: 9,
  },
  filters: {
    backgroundColor: primary.bluish8,
    boxSizing: 'border-box',
    borderRadius: 4,
    color: primary.bluish8,
    padding: '8px 16px',
    height: 40,
    maxHeight: 40,
    '&:hover': {
      backgroundColor: primary.bluish8,
    },
    '&:disabled': {
      pointerEvents: 'none',
      backgroundColor: primary.bluish9,
    },
  },
  disabledLight: {
    color: primary.bluish5,
  },
  disabledFilterIcon: {
    filter: 'grayscale(100%) opacity(30%)',
  },
  peopleIcon: {
    width: 18,
    height: 18,
  },
  activeColor: {
    color: primary.blue1,
  },
}));

const ADD = 'add';
const BUTTON = 'button';
const SUBMIT = 'submit';
const EDIT = 'edit';
const TEXT_ROUNDED_LARGE = 'textRoundedLarge';
const ADD_WITH_TEXT_DARK = 'addWithTextDark';
const ADD_WITH_TEXT_DARK_NEW = 'addWithTextDarkNew';
const ADD_WITH_TEXT_DARK_SLIM_NEW = 'addWithTextDarkSlimNew';
const WITH_TEXT_DARK_ROUNDED = 'withTextDarkRounded';
const WITH_TEXT_LIGHT_ROUNDED = 'withTextLightRounded';
const ADD_DARK_ROUNDED_OUTLINED = 'addDarkRoundedOutlined';
const SHARE_DARK_ROUNDED_OUTLINED = 'shareDarkRoundedOutlined';
const PEOPLE_DARK_ROUNDED_OUTLINED = 'peopleDarkRoundedOutlined';
const ADD_ROUNDED_NEW = 'addRoundedNew';
const ADD_ROUNDED_BLUE = 'addRoundedBlue';
const BACK = 'back';
const FILTERS = 'filters';
const FILL_SURVEY_SMALL = 'fillSurveySmall';

const CustomButton = props => {
  const {
    children,
    type,
    submit,
    disabled,
    customImageClass,
    showIcon,
    customIconWrapper,
    ...rest
  } = props;
  const classes = useStyles();
  const buttonType = submit ? SUBMIT : BUTTON;

  if (type === ADD) {
    return (
      <Fab
        type={BUTTON}
        {...buttonTypes[type].props}
        {...rest}
        className={classNames(
          classes[buttonTypes[type].style],
          props.className
        )}
      >
        <img
          className={customImageClass}
          src={
            disabled && buttonTypes[type].disabledIcon
              ? buttonTypes[type].disabledIcon
              : buttonTypes[type].icon
          }
          alt={type}
        />
      </Fab>
    );
  }

  return buttonTypes[type].icon ? (
    <Button
      type={buttonType}
      {...buttonTypes[type].props}
      {...rest}
      disabled={disabled}
      className={classNames(classes[buttonTypes[type].style], props.className)}
    >
      {showIcon && (
        <span className={classNames(classes.iconWrapper, customIconWrapper)}>
          <img
            className={classNames(classes[buttonTypes[type].iconStyle], {
              [classes[buttonTypes[type]?.disabledIconStyle]]: disabled,
            })}
            src={
              disabled && buttonTypes[type].disabledIcon
                ? buttonTypes[type].disabledIcon
                : buttonTypes[type].icon
            }
            alt={type}
          />
        </span>
      )}
      <span
        className={classNames(classes.buttonBoldText, {
          [classes.buttonTextLightBlue]: type === EDIT,
          [classes.buttonTextDark]:
            type === ADD_WITH_TEXT_DARK || type === TEXT_ROUNDED_LARGE,
          [classes.buttonTextDarkNew]:
            type === ADD_WITH_TEXT_DARK_NEW ||
            type === ADD_DARK_ROUNDED_OUTLINED ||
            type === SHARE_DARK_ROUNDED_OUTLINED ||
            type === PEOPLE_DARK_ROUNDED_OUTLINED ||
            type === FILTERS ||
            type === FILL_SURVEY_SMALL,
          [classes.disabledLight]: type === FILTERS && disabled,
          [classes.buttonTextNew]: type === ADD_ROUNDED_NEW,
          [classes.withTextDarkRounded]: type === WITH_TEXT_DARK_ROUNDED,
          [classes.withTextLightRounded]: type === WITH_TEXT_LIGHT_ROUNDED,
          [classes.buttonSemiBoldText]: type === ADD_ROUNDED_BLUE,
          [classes.backRoundedLabel]: type === BACK,
          [classes.addWithTextDarkSlimNew]:
            type === ADD_WITH_TEXT_DARK_SLIM_NEW,
          [classes.activeColor]: type === FILL_SURVEY_SMALL,
        })}
      >
        {children}
      </span>
    </Button>
  ) : (
    <Button
      type={buttonType}
      {...buttonTypes[type].props}
      {...rest}
      className={classNames(classes[buttonTypes[type].style], props.className)}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

CustomButton.defaultProps = {
  children: undefined,
  submit: false,
  disabled: false,
  customImageClass: '',
  showIcon: true,
  customIconWrapper: null,
};

CustomButton.propTypes = {
  customIconWrapper: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.oneOf(Object.keys(buttonTypes)).isRequired,
  submit: PropTypes.bool,
  disabled: PropTypes.bool,
  showIcon: PropTypes.bool,
  customImageClass: PropTypes.string,
};

export default memo(CustomButton);
