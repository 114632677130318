import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import AlertDialog from '../../shared/alertDialog';
import SurveyInfo from '../../shared/surveyInfo';
import SurveyReviewers from '../../shared/surveyReviewers';
import SurveyActionsDialog from '../../shared/surveyActionsDialog';
import AddReviewersDialog from '../../shared/addReviewersDialog';
import NotificationCard from '../../shared/notificationCard';
import ChooseNameDialog from '../../shared/chooseNameDialog';
import http from '../../../utility/http';
import {
  api_survey_send_reminder,
  api_survey_reopen,
  api_survey_reviewers,
  api_survey,
} from '../../../constants/apiRoutes';
import { showSuccessMessage, hasNextPage } from '../../../utility/uiUtils';
import {
  isSameDate,
  getStartOfDay,
  addDaysFromSpecificHour,
  isDateInPast,
} from '../../../utility/dateUtils';
import {
  getUnionOfTwoArrays,
  isArrayEmpty,
  trimString,
  isUserClickable,
  checkUserRole,
  getObjectToNumberArray,
} from '../../../utility/helpers';
import {
  getTranslatedSurveyTypes,
  getSurveyStatuses,
} from '../../../utility/survey';
import { SURVEY_STATUS_TYPES } from '../../../constants/statuses';
import {
  CREATE_SURVEY_FIELD_NAMES,
  SURVEY_TITLE_FIELD,
  SURVEY_TYPES,
  getExpiryDialogFields,
} from '../../../constants/survey';
import { ROLES } from '../../../constants/rolesAndPermissionList';
import { sticky } from '../../../constants/helperCssRules';
import {
  DEFAULT_PARAMS,
  STATUS,
  REVIEWER_ACTION_TYPES,
  getReviewersActions,
  getSurveyUserCompletionStatuses,
  hasAddReviewerButton,
  prepareUsersToNotify,
  prepareUsersForAction,
  prepareAllUsers,
} from './config';

const styles = ({ palette: { primary }, spacing }) => ({
  divider: {
    paddingTop: spacing(8),
    ...sticky(primary.white, 105),
  },
});

class SurveySettingsPage extends PureComponent {
  state = {
    currentPage: DEFAULT_PARAMS.page,
    search: '',
    filters: {
      [STATUS]: 0,
    },
    addReviewerDialogOpened: false,
    forceCompleteDialogOpened: false,
    sendReminderDialogOpened: false,
    reopenSurveyDialogOpened: false,
    actionType: null,
    selectedUsers: [],
    reviewersToAdd: [],
  };

  goToProfilePage = userId => {
    const { history } = this.props;
    history.push(`/people/${userId}`);
  };

  toggleSendReminderDialog = () =>
    this.setState(prevState => ({
      sendReminderDialogOpened: !prevState.sendReminderDialogOpened,
      actionType: prevState.sendReminderDialogOpened
        ? null
        : REVIEWER_ACTION_TYPES.SEND_REMINDER,
    }));

  handleSendReminder = () => {
    const { translations, survey } = this.props;
    const { selectedUsers, actionType } = this.state;

    if (isArrayEmpty(selectedUsers)) {
      const params = {
        client_url: `${window.location.origin}/fill-survey`,
        group_id: survey.id,
      };

      http.post(api_survey_send_reminder(survey.id), params).then(() => {
        showSuccessMessage(translations.sendReminderAllDialog.sendSuccess);
        this.toggleSendReminderDialog();
      });
    } else {
      http
        .post(
          api_survey_reviewers(survey.id),
          prepareUsersForAction(selectedUsers, actionType)
        )
        .then(() => {
          showSuccessMessage(translations.sendReminderDialog.success);
          this.setState({
            actionType: null,
            sendReminderDialogOpened: false,
            selectedUsers: [],
          });
        });
    }
  };

  toggleReopenSurveyDialog = () =>
    this.setState(prevState => ({
      reopenSurveyDialogOpened: !prevState.reopenSurveyDialogOpened,
      actionType: prevState.reopenSurveyDialogOpened
        ? null
        : REVIEWER_ACTION_TYPES.REOPEN,
    }));

  handleReopenSurvey = async () => {
    const { translations, survey, getSurvey, editSurvey } = this.props;
    const { selectedUsers, actionType } = this.state;

    await editSurvey(survey.id, {
      [CREATE_SURVEY_FIELD_NAMES.EXPIRATION_DATE]: addDaysFromSpecificHour(),
    });

    if (isArrayEmpty(selectedUsers)) {
      const params = {
        client_url: `${window.location.origin}/fill-survey`,
        group_id: survey.id,
      };

      http.post(api_survey_reopen(survey.id), params).then(() => {
        showSuccessMessage(translations.surveyReopenAllDialog.success);
        this.setState(
          {
            currentPage: DEFAULT_PARAMS.page,
            reopenSurveyDialogOpened: false,
            selectedUsers: [],
          },
          () => {
            getSurvey(survey.id);
            this.handlePageChange();
          }
        );
      });
    } else {
      http
        .post(
          api_survey_reviewers(survey.id),
          prepareUsersForAction(selectedUsers, actionType, survey.status)
        )
        .then(() => {
          showSuccessMessage(translations.surveyReopenDialog.success);
          this.setState(
            {
              currentPage: DEFAULT_PARAMS.page,
              actionType: null,
              reopenSurveyDialogOpened: false,
              selectedUsers: [],
            },
            () => {
              getSurvey(survey.id);
              this.handlePageChange();
            }
          );
        });
    }
  };

  toggleForceCompleteDialog = () =>
    this.setState(prevState => ({
      forceCompleteDialogOpened: !prevState.forceCompleteDialogOpened,
    }));

  handleForceComplete = () => {
    const { translations, survey, editSurvey } = this.props;

    return editSurvey(survey.id, {
      [CREATE_SURVEY_FIELD_NAMES.EXPIRATION_DATE]: getStartOfDay(new Date()),
    }).then(() => {
      showSuccessMessage(translations.forceCompleteDialog.completeSuccess);
      this.setState(
        {
          currentPage: DEFAULT_PARAMS.page,
          forceCompleteDialogOpened: false,
        },
        () => {
          this.handlePageChange();
        }
      );
    });
  };

  handleActionSelect = action => {
    const { SEND_REMINDER, REOPEN } = REVIEWER_ACTION_TYPES;

    if (action === SEND_REMINDER) {
      return this.toggleSendReminderDialog();
    }
    if (action === REOPEN) {
      return this.toggleReopenSurveyDialog();
    }
    return this.toggleForceCompleteDialog();
  };

  handleSelectAll = isChecked => {
    const { reviewers } = this.props;

    if (isChecked) {
      return this.setState(prevState => ({
        selectedUsers: getUnionOfTwoArrays(
          prevState.selectedUsers,
          reviewers.results
        ),
      }));
    }

    return this.setState(prevState => ({
      selectedUsers: prevState.selectedUsers.filter(
        user => !reviewers.results.some(x => x.id === user.id)
      ),
    }));
  };

  handleUserSelect = selectedUser => isChecked => {
    if (isChecked) {
      return this.setState(prevState => ({
        selectedUsers: [...prevState.selectedUsers, { ...selectedUser }],
      }));
    }

    return this.setState(prevState => ({
      selectedUsers: prevState.selectedUsers.filter(
        user => user.id !== selectedUser.id
      ),
    }));
  };

  handlePageChange = (isLoadMore = false) => {
    const { survey, getReviewers } = this.props;
    const { currentPage, filters, search } = this.state;
    const params = {
      page: isLoadMore ? currentPage : DEFAULT_PARAMS.page,
      ...(filters[STATUS] ? filters : {}),
      ...(search ? { search } : {}),
    };

    return getReviewers(survey.id, isLoadMore, params);
  };

  handleSearch = text => {
    const searchTerm = trimString(text);

    this.setState(
      { currentPage: DEFAULT_PARAMS.page, search: searchTerm },
      this.handlePageChange
    );
  };

  handleStatusChange = newStatus =>
    this.setState(
      { currentPage: DEFAULT_PARAMS.page, filters: { [STATUS]: newStatus } },
      this.handlePageChange
    );

  handleLoadMore = () => {
    return this.setState(
      prevState => ({ ...prevState, currentPage: prevState.currentPage + 1 }),
      () => this.handlePageChange(true)
    );
  };

  toggleAddReviewers = () =>
    this.setState(prevState => ({
      addReviewerDialogOpened: !prevState.addReviewerDialogOpened,
      reviewersToAdd: [],
      actionType: prevState.addReviewerDialogOpened
        ? null
        : REVIEWER_ACTION_TYPES.APPEND,
    }));

  handleAddReviewers = reviewers =>
    this.setState({ reviewersToAdd: reviewers });

  handleSaveReviewers = () => {
    const { translations, survey, getSurvey } = this.props;
    const { actionType, reviewersToAdd } = this.state;

    return http
      .post(
        api_survey_reviewers(survey.id),
        prepareUsersForAction(reviewersToAdd, actionType)
      )
      .then(() => {
        showSuccessMessage(translations.addReviewersDialog.success);
        this.setState(
          {
            currentPage: DEFAULT_PARAMS.page,
            actionType: null,
            addReviewerDialogOpened: false,
            reviewersToAdd: [],
          },
          () => {
            getSurvey(survey.id);
            this.handlePageChange();
          }
        );
      });
  };

  handleChangeExpiryDate = expiryDate => {
    const { survey, editSurvey } = this.props;

    if (!isSameDate(survey.expireAt, expiryDate)) {
      return editSurvey(survey.id, {
        [CREATE_SURVEY_FIELD_NAMES.EXPIRATION_DATE]: expiryDate,
      }).then(() => this.setState({ selectedUsers: [] }));
    }
  };

  handleChangeGroup = groupId => {
    const { translations, survey, getSurvey } = this.props;

    return http.patch(api_survey(survey.id), { group: groupId }).then(() => {
      getSurvey(survey.id);
      showSuccessMessage(
        survey.group
          ? translations.info.manageGroupDialog.success.edit
          : translations.info.manageGroupDialog.success.create
      );
    });
  };

  render() {
    const {
      classes,
      translations,
      auth,
      organizationSettings,
      survey,
      allSurveyGroups,
      reviewers,
      allUsers,
      organizationMentor,
      isEditTitleOpened,
      onToggleTitleDialog,
      onChangeSurveyTitle,
    } = this.props;
    const {
      addReviewerDialogOpened,
      sendReminderDialogOpened,
      reopenSurveyDialogOpened,
      forceCompleteDialogOpened,
      filters,
      selectedUsers,
      reviewersToAdd,
    } = this.state;
    const isAdmin = checkUserRole(auth.role, ROLES.ADMIN);
    const {
      createdFor,
      attributes,
      status,
      type,
      isAnonymous,
      creator,
      title,
      scheduledFor,
      sentAt,
      expireAt,
    } = survey;
    const statuses = getSurveyStatuses(translations.statuses);
    const types = getTranslatedSurveyTypes(translations);
    const isScheduled = status === SURVEY_STATUS_TYPES.SCHEDULED;
    const isPeerReview = type === SURVEY_TYPES.PEER;
    const isSelfReview = type === SURVEY_TYPES.SELF;
    const hasGroups = !isArrayEmpty(allSurveyGroups);

    return (
      <div>
        <div className={classes.divider} />
        <SurveyInfo
          translations={translations.info}
          statuses={statuses}
          types={types}
          survey={survey}
          surveyGroups={allSurveyGroups}
          expiryFields={getExpiryDialogFields(sentAt || scheduledFor)}
          shouldShowGroup={isAdmin && hasGroups}
          isUserClickable={isUserClickable(
            auth,
            organizationSettings.global_see_himself,
            organizationMentor.id
          )}
          organizationUser={organizationMentor}
          onGoToPersonProfile={this.goToProfilePage}
          onChangeGroup={this.handleChangeGroup}
          onEditExpiryDate={this.handleChangeExpiryDate}
        />
        {isAnonymous ? (
          <NotificationCard title={translations.anonymous} />
        ) : (
          <SurveyReviewers
            translations={translations.reviewers}
            reviewers={reviewers?.results}
            completionStatus={filters[STATUS]}
            selectedUsers={selectedUsers}
            actions={getReviewersActions(
              translations.reviewers.actions,
              auth,
              survey,
              !isArrayEmpty(selectedUsers)
            )}
            completionStatuses={getSurveyUserCompletionStatuses(
              translations.reviewers.completionStatuses,
              true
            )}
            hasLoadMore={hasNextPage(reviewers)}
            isScheduled={isScheduled}
            isExpired={isDateInPast(expireAt)}
            isUserClickable={isUserClickable(
              auth,
              organizationSettings.global_see_himself
            )}
            isDisabled={
              isDateInPast(expireAt) || !(isAdmin || auth.id === creator.id)
            }
            canAddReviewer={hasAddReviewerButton(survey)}
            onAddReviewers={this.toggleAddReviewers}
            onGoToPersonProfile={this.goToProfilePage}
            onActionSelect={this.handleActionSelect}
            onStatusChange={this.handleStatusChange}
            onSearch={this.handleSearch}
            onLoadMore={this.handleLoadMore}
            onSelectAll={this.handleSelectAll}
            onUserSelect={this.handleUserSelect}
          />
        )}
        <AlertDialog
          translations={translations.sendReminderAllDialog}
          isOpened={sendReminderDialogOpened && isArrayEmpty(selectedUsers)}
          onClose={this.toggleSendReminderDialog}
          onConfirm={this.handleSendReminder}
        />
        <AlertDialog
          translations={translations.surveyReopenAllDialog}
          isOpened={reopenSurveyDialogOpened && isArrayEmpty(selectedUsers)}
          onClose={this.toggleReopenSurveyDialog}
          onConfirm={this.handleReopenSurvey}
        />
        <AlertDialog
          translations={translations.forceCompleteDialog}
          isOpened={forceCompleteDialogOpened}
          onClose={this.toggleForceCompleteDialog}
          onConfirm={this.handleForceComplete}
          isWarning
        />
        <SurveyActionsDialog
          translations={translations.sendReminderDialog}
          isOpened={sendReminderDialogOpened && !isArrayEmpty(selectedUsers)}
          onClose={this.toggleSendReminderDialog}
          onConfirm={this.handleSendReminder}
          {...prepareUsersToNotify(selectedUsers, status)}
        />
        <SurveyActionsDialog
          translations={translations.surveyReopenDialog}
          isOpened={reopenSurveyDialogOpened && !isArrayEmpty(selectedUsers)}
          users={selectedUsers}
          usersToNotify={selectedUsers}
          onClose={this.toggleReopenSurveyDialog}
          onConfirm={this.handleReopenSurvey}
          {...prepareUsersToNotify(selectedUsers, status, true)}
        />
        <AddReviewersDialog
          isOpened={addReviewerDialogOpened}
          translations={translations.addReviewersDialog}
          reviewers={reviewersToAdd}
          existingReviewers={getObjectToNumberArray(
            isPeerReview
              ? [...survey.reviewers, ...createdFor]
              : survey.reviewers
          )}
          users={prepareAllUsers(
            allUsers,
            auth,
            survey.reviewers,
            createdFor,
            isPeerReview,
            isSelfReview
          )}
          usersToReview={type !== SURVEY_TYPES.SELF ? createdFor : []}
          attributes={attributes}
          isScheduled={isScheduled}
          onAdd={this.handleAddReviewers}
          onClose={this.toggleAddReviewers}
          onConfirm={this.handleSaveReviewers}
        />
        <ChooseNameDialog
          translations={translations.renameSurveyDialog}
          title={translations.renameSurveyDialog.title}
          description={translations.renameSurveyDialog.description}
          isOpened={isEditTitleOpened}
          initialValue={title}
          field={SURVEY_TITLE_FIELD}
          onCancel={onToggleTitleDialog}
          onSave={onChangeSurveyTitle}
        />
      </div>
    );
  }
}

SurveySettingsPage.defaultProps = {
  allSurveyGroups: [],
};

SurveySettingsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  survey: PropTypes.shape({}).isRequired,
  allSurveyGroups: PropTypes.arrayOf(PropTypes.object),
  organizationMentor: PropTypes.shape({}).isRequired,
  reviewers: PropTypes.shape({}).isRequired,
  isEditTitleOpened: PropTypes.bool.isRequired,
  onToggleTitleDialog: PropTypes.func.isRequired,
  onChangeSurveyTitle: PropTypes.func.isRequired,
  allUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  organizationSettings: PropTypes.shape({}).isRequired,
  getSurvey: PropTypes.func.isRequired,
  editSurvey: PropTypes.func.isRequired,
};

export default withStyles(styles)(SurveySettingsPage);
