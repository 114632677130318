import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import CustomButton from '../../customButton';
import VisibleForPermission from '../../visibleForPermission';
import http from '../../../../utility/http';
import {
  STRIPE_PRO_PRICE_ID,
  FREEMIUM_LIMIT_TYPES,
} from '../../../../constants/appConfig';
import { PERMISSIONS } from '../../../../constants/rolesAndPermissionList';
import { API_SUBSCRIPTION } from '../../../../constants/apiRoutes';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginBottom: spacing(4),
  },
  content: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridColumnGap: 16,
    gridRowGap: 8,
  },
  limit: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    margin: spacing(0, 0.5),
  },
  unlimited: {
    fontSize: 24,
  },
  limitLabel: {
    marginLeft: spacing(1),
    paddingBottom: spacing(0.5),
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: spacing(4),
  },
  actionButton: {
    marginLeft: spacing(3),
  },
}));

const UpgradePlan = ({
  translations,
  grantedPermissions,
  limits,
  onGoToPricingPlan,
}) => {
  const classes = useStyles();
  const { usage, limit } = limits;
  const { canAddAppSubscription } = PERMISSIONS;

  const onUpgrade = async () => {
    const { data } = await http.post(API_SUBSCRIPTION, {
      price_id: STRIPE_PRO_PRICE_ID,
      success_url: `${window.location.origin}/`,
      cancel_url: window.location.origin,
    });
    window.location.href = data.subscription_session_url;
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h4">
        {translations.freePlan}
      </Typography>
      <div className={classes.content}>
        <div className={classes.limit}>
          <Typography variant="subtitle2">
            {usage?.[FREEMIUM_LIMIT_TYPES.ADMINS]}
          </Typography>
          <Typography variant="body2" className={classes.divider}>
            /
          </Typography>
          <Typography variant="body2">
            {limit?.[FREEMIUM_LIMIT_TYPES.ADMINS]}
          </Typography>
          <Typography className={classes.limitLabel}>
            {translations.admins}
          </Typography>
        </div>
        <div className={classes.limit}>
          <Typography variant="subtitle2">
            {usage?.[FREEMIUM_LIMIT_TYPES.ONE_ON_ONE]}
          </Typography>
          <Typography variant="body2" className={classes.divider}>
            /
          </Typography>
          <Typography variant="body2">
            {limit?.[FREEMIUM_LIMIT_TYPES.ONE_ON_ONE]}
          </Typography>
          <Typography className={classes.limitLabel}>
            {translations.oneOnOne}
          </Typography>
        </div>
        <div className={classes.limit}>
          <Typography variant="subtitle2">
            {usage?.[FREEMIUM_LIMIT_TYPES.MODERATORS]}
          </Typography>
          <Typography variant="body2" className={classes.divider}>
            /
          </Typography>
          <Typography variant="body2">
            {limit?.[FREEMIUM_LIMIT_TYPES.MODERATORS]}
          </Typography>
          <Typography className={classes.limitLabel}>
            {translations.moderators}
          </Typography>
        </div>
        <div className={classes.limit}>
          <Typography variant="subtitle2">
            {usage?.[FREEMIUM_LIMIT_TYPES.COURSES]}
          </Typography>
          <Typography variant="body2" className={classes.divider}>
            /
          </Typography>
          <Typography variant="body2">
            {limit?.[FREEMIUM_LIMIT_TYPES.COURSES]}
          </Typography>
          <Typography className={classes.limitLabel}>
            {translations.courses.toLowerCase()}
          </Typography>
        </div>
        <div className={classes.limit}>
          <Typography variant="subtitle2">{usage?.users}</Typography>
          <Typography variant="body2" className={classes.divider}>
            /
          </Typography>
          <Typography variant="body2" className={classes.unlimited}>
            ∞
          </Typography>
          <Typography className={classes.limitLabel}>
            {translations.users}
          </Typography>
        </div>
        <div className={classes.limit}>
          <Typography variant="subtitle2">
            {usage?.[FREEMIUM_LIMIT_TYPES.SURVEYS]}
          </Typography>
          <Typography variant="body2" className={classes.divider}>
            /
          </Typography>
          <Typography variant="body2">
            {limit?.[FREEMIUM_LIMIT_TYPES.SURVEYS]}
          </Typography>
          <Typography className={classes.limitLabel}>
            {translations.surveys}
          </Typography>
        </div>
        <div className={classes.limit}>
          <Typography variant="subtitle2">
            {usage?.[FREEMIUM_LIMIT_TYPES.PLANS]}
          </Typography>
          <Typography variant="body2" className={classes.divider}>
            /
          </Typography>
          <Typography variant="body2">
            {limit?.[FREEMIUM_LIMIT_TYPES.PLANS]}
          </Typography>
          <Typography className={classes.limitLabel}>
            {translations.actionPlans.toLowerCase()}
          </Typography>
        </div>
        <div className={classes.limit}>
          <Typography variant="subtitle2">
            {usage?.[FREEMIUM_LIMIT_TYPES.ATTRIBUTES]}
          </Typography>
          <Typography variant="body2" className={classes.divider}>
            /
          </Typography>
          <Typography variant="body2">
            {limit?.[FREEMIUM_LIMIT_TYPES.ATTRIBUTES]}
          </Typography>
          <Typography className={classes.limitLabel}>
            {translations.attributes.toLowerCase()}
          </Typography>
        </div>
      </div>
      <VisibleForPermission
        permission={canAddAppSubscription}
        permissions={grantedPermissions}
      >
        <div className={classes.footer}>
          <CustomButton
            onClick={onGoToPricingPlan}
            className={classes.actionButton}
            type="withTextLightRounded"
          >
            {translations.learnMore}
          </CustomButton>
          <CustomButton
            className={classes.actionButton}
            type="withTextDarkRounded"
            onClick={onUpgrade}
          >
            {translations.upgradePlan}
          </CustomButton>
        </div>
      </VisibleForPermission>
    </div>
  );
};

UpgradePlan.defaultProps = {
  limits: {},
};

UpgradePlan.propTypes = {
  translations: PropTypes.object.isRequired,
  grantedPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  limits: PropTypes.object,
  onGoToPricingPlan: PropTypes.func.isRequired,
};
export default UpgradePlan;
