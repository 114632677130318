import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import PresetDropdown from 'components/shared/presetDropdown';
import {
  getActivePresetId,
  getActivePresetName,
  getFilteredPresets,
  getPlaceholderName,
  handleHasMaxPresets,
  handlePresetName,
} from 'utility/presets';
import { PRESETS } from 'constants/presets';
import AlertDialog from 'components/shared/alertDialog';
import OneOnOneReport from '../../shared/oneOnOneReport';
import PeoplePicker from '../../shared/peoplePicker';
import NotificationCard from '../../shared/notificationCard';
import CustomButton from '../../shared/customButton';
import { sticky } from '../../../constants/helperCssRules';
import {
  checkUserRole,
  isUserProfileAccessible,
  getObjectToNumberArray,
  isArrayEmpty,
  isArray,
  areArraysContentsEqual,
  getItemById,
  isValueInList,
} from '../../../utility/helpers';
import { hasNextPage, showSuccessMessage } from '../../../utility/uiUtils';
import http from '../../../utility/http';
import { ROLES } from '../../../constants/rolesAndPermissionList';
import {
  API_ONE_ONE_ONE_REPORT_PARTIALS,
  API_USERS_BASIC,
} from '../../../constants/apiRoutes';
import { PARAMS } from '../../../constants/pages';
import {
  PEOPLE_PARAM_SCOPE_VALUES,
  PEOPLE_DEFAULT_ORDERING,
} from '../../../constants/people';
import { AUTOMATION_ID } from '../../../constants/automationId';
import { DEFAULT_PARAMS } from '../../../constants/oneOnOne';

const styles = ({ palette: { primary }, spacing }) => ({
  description: {
    paddingTop: spacing(8),
    ...sticky(primary.white, 105),
  },
  filterWrapper: {
    padding: spacing(6, 0, 4, 0),
    ...sticky(primary.white, 173),
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0, 350px))',
    gridColumnGap: 16,
  },
  loadMoreWrapper: {
    paddingTop: spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadMoreButton: {
    border: `1px solid ${primary.bluish3}`,
    borderRadius: 44,
    color: primary.bluish4,
    lineHeight: '24px',
    justifySelf: 'center',
    padding: spacing(1, 4),
    maxHeight: 32,
  },
});

const GENERAL_MOOD_PRESET_ID = PRESETS.GENERAL_MOOD;

class OneOnOneReportPage extends PureComponent {
  state = {
    isLoading: true,
    currentPage: 1,
    selectedUsers: [],
    alreadySavedMessage: '',
    isDeletePresetDialogOpened: false,
    selectedPreset: undefined,
    defaultPlaceholder: false,
    presetPlaceholderName: '',
  };

  async componentDidMount() {
    const { auth, getAllUsers, presets } = this.props;
    const isAdmin = checkUserRole(auth.role, ROLES.ADMIN);

    const generalMoodReportPagePresets = presets[GENERAL_MOOD_PRESET_ID];
    const existsPresetsArray =
      generalMoodReportPagePresets &&
      !isArrayEmpty(generalMoodReportPagePresets);
    const initialPreset = existsPresetsArray
      ? getItemById(
          generalMoodReportPagePresets,
          presets.active[GENERAL_MOOD_PRESET_ID]
        )
      : undefined;

    const { data } = await this.getDirectReporters();
    getAllUsers({
      [PARAMS.EXCLUDE]: [auth.id],
      ...(isAdmin
        ? { [PARAMS.EXCLUDE_ROLE]: ROLES.ASSOCIATE }
        : { [PARAMS.SCOPE]: PEOPLE_PARAM_SCOPE_VALUES.ALL_REPORTS }),
    }).then(async () => {
      if (!isArrayEmpty(data)) {
        const tempUsersData = initialPreset
          ? this.props.allUsers?.filter(user =>
              isValueInList(initialPreset?.data?.users, user.id)
            )
          : data;
        this.setState(
          { selectedUsers: tempUsersData, isLoading: false },
          this.onGetOneOnOneReport
        );
      } else {
        this.setState({ isLoading: false });
      }
    });
  }

  componentWillUnmount() {
    const { clearOneOnOneReport, clearAllUsers } = this.props;

    clearOneOnOneReport();
    clearAllUsers();
  }

  goToProfilePage = userId => {
    const { history } = this.props;

    history.push(`/people/${userId}`);
  };

  onGoToMeetingDetails = meetingId => {
    const { history } = this.props;

    history.push(`/1-1/${meetingId}/details`);
  };

  getDirectReporters = () => {
    const { SCOPE } = PARAMS;
    const { DIRECT_REPORTS } = PEOPLE_PARAM_SCOPE_VALUES;

    return http.get(API_USERS_BASIC, {
      params: {
        ...PEOPLE_DEFAULT_ORDERING,
        [SCOPE]: DIRECT_REPORTS,
      },
    });
  };

  handleResetPreset = () => {
    const { editPresetActivity } = this.props;

    editPresetActivity(-1, GENERAL_MOOD_PRESET_ID, false);
    this.setState({
      selectedPreset: undefined,
      alreadySavedMessage: '',
    });
  };

  checkValueInPresets = users => {
    const { presets, editPresetActivity, translations } = this.props;
    const generalMoodPresets = presets[GENERAL_MOOD_PRESET_ID];

    if (generalMoodPresets && !isArrayEmpty(generalMoodPresets)) {
      const activePreset = generalMoodPresets.find(preset => {
        const presetData = preset?.data;

        return areArraysContentsEqual(presetData.users, users);
      });
      if (activePreset) {
        editPresetActivity(activePreset.id, GENERAL_MOOD_PRESET_ID, true);
        this.setState({
          selectedPreset: activePreset,
          alreadySavedMessage: translations.presets.used,
        });
      } else {
        this.handleResetPreset();
      }
    }
  };

  onGetOneOnOneReport = (isLoadMore = false) => {
    const { getOneOnOneReport, clearOneOnOneReport } = this.props;
    const { currentPage, selectedUsers } = this.state;

    this.checkValueInPresets(selectedUsers.map(user => user.id));
    if (isArrayEmpty(selectedUsers)) {
      return clearOneOnOneReport();
    }

    return getOneOnOneReport(isLoadMore, {
      page: isLoadMore ? currentPage : DEFAULT_PARAMS.page,
      user: getObjectToNumberArray(selectedUsers),
    });
  };

  onLoadMore = () => {
    return this.setState(
      prevState => ({ ...prevState, currentPage: prevState.currentPage + 1 }),
      () => this.onGetOneOnOneReport(true)
    );
  };

  handleUsersSelect = users =>
    this.setState(
      { currentPage: DEFAULT_PARAMS.page, selectedUsers: users },
      this.onGetOneOnOneReport
    );

  getWeekReport = (userId, week) => async () => {
    const { USER } = PARAMS;

    const params = {
      [USER]: userId,
      ...week,
    };

    return http.get(API_ONE_ONE_ONE_REPORT_PARTIALS, { params });
  };

  handlePlaceholder = name =>
    this.setState({
      defaultPlaceholder: false,
      presetPlaceholderName: name,
    });

  handleDeletePresetDialog = preset => {
    this.setState({ selectedPreset: preset, isDeletePresetDialogOpened: true });
  };

  handleDeletePreset = () => {
    const { deleteSelectedPreset, translations, presets } = this.props;
    const { selectedPreset } = this.state;

    deleteSelectedPreset(selectedPreset.id, GENERAL_MOOD_PRESET_ID).then(() => {
      this.setState({
        isDeletePresetDialogOpened: false,
        ...(presets.active[GENERAL_MOOD_PRESET_ID] === selectedPreset.id
          ? { selectedPreset: undefined, alreadySavedMessage: '' }
          : {}),
      });
      showSuccessMessage(translations.presets.successMessages.deletedPreset);
    });
  };

  handleSavePreset = presetName => {
    const { translations, addNewPreset } = this.props;
    const { selectedUsers } = this.state;

    const data = {
      users: selectedUsers.map(user => user.id),
    };
    addNewPreset({
      name: presetName,
      location: GENERAL_MOOD_PRESET_ID,
      data,
    }).then(() => {
      this.setState({
        alreadySavedMessage: translations.presets.used,
      });
      showSuccessMessage(translations.presets.successMessages.createdPreset);
    });
  };

  handleApplyPreset = preset => {
    const { editPresetActivity, allUsers } = this.props;
    const presetData = preset?.data;

    editPresetActivity(preset.id, GENERAL_MOOD_PRESET_ID, true);
    this.setState(
      {
        selectedUsers: allUsers.filter(user =>
          presetData?.users?.includes(user.id)
        ),
        currentPage: DEFAULT_PARAMS.page,
        selectedPreset: preset,
      },
      this.onGetOneOnOneReport
    );
  };

  handleSelectedFilter = filteredUsers =>
    isArrayEmpty(filteredUsers)
      ? this.props.translations.presets.oneOnOnePresetConditionTooltip
      : '';

  render() {
    const {
      classes,
      translations,
      auth,
      organizationSettings,
      allUsers,
      oneOnOneReport,
      formattedUsers,
      presets,
    } = this.props;
    const {
      isLoading,
      selectedUsers,
      selectedPreset,
      alreadySavedMessage,
      isDeletePresetDialogOpened,
    } = this.state;
    const activePresetId = getActivePresetId(presets, GENERAL_MOOD_PRESET_ID);
    const filteredPresets = getFilteredPresets(presets, GENERAL_MOOD_PRESET_ID);
    const presetName = getActivePresetName(filteredPresets, activePresetId);
    const handledPresetName = handlePresetName(
      filteredPresets,
      activePresetId,
      presetName,
      this.state,
      translations.presets.startSearch
    );
    const placeholderName = getPlaceholderName(
      handledPresetName,
      presetName,
      translations.presets.startSearch
    );
    const filteredUsers = !selectedPreset?.data
      ? selectedUsers
      : allUsers.filter(user => selectedPreset?.data?.users?.includes(user.id));
    const hasResults =
      isArray(oneOnOneReport?.results) &&
      !isArrayEmpty(oneOnOneReport?.results);
    const hasLoadMore = hasNextPage(oneOnOneReport);

    return (
      <div>
        <div className={classes.description}>
          <Typography variant="body2">
            {translations.descriptionPartOne}
          </Typography>
          <Typography variant="body2">
            {translations.descriptionPartTwo}
          </Typography>
        </div>
        <div className={classes.filterWrapper}>
          <PeoplePicker
            translations={translations.peoplePicker}
            preselectedUsers={filteredUsers}
            options={allUsers}
            blacklistedUsers={[auth.id]}
            onSelect={this.handleUsersSelect}
            showDirectReports
            formattedUsers={formattedUsers}
            hasSelectAll
          />
          <PresetDropdown
            translations={translations.presets}
            options={filteredPresets}
            activePreset={activePresetId}
            handleApply={this.handleApplyPreset}
            handleDelete={this.handleDeletePresetDialog}
            handleSavePreset={this.handleSavePreset}
            disabledPreset={
              handleHasMaxPresets(filteredPresets, translations.presets) ||
              this.handleSelectedFilter(filteredUsers)
            }
            alreadySavedMessage={alreadySavedMessage}
            presetPlaceholderName={placeholderName}
            handlePlaceholder={this.handlePlaceholder}
          />
        </div>
        <div>
          {!isLoading && hasResults && (
            <OneOnOneReport
              translations={translations.report}
              reports={oneOnOneReport.results}
              canSeeUserProfile={isUserProfileAccessible(
                auth,
                organizationSettings.global_see_himself
              )}
              onGoToUserProfile={this.goToProfilePage}
              onGoToMeetingDetails={this.onGoToMeetingDetails}
              onGetWeekReport={this.getWeekReport}
            />
          )}
          {!isLoading && isArrayEmpty(filteredUsers) && (
            <NotificationCard
              className={classes.noResults}
              content={translations.noResults}
            />
          )}
          {hasLoadMore && (
            <div className={classes.loadMoreWrapper}>
              <CustomButton
                id={AUTOMATION_ID.LOAD_MORE_BUTTON}
                type="addWithTextRounded"
                className={classes.loadMoreButton}
                onClick={this.onLoadMore}
              >
                {translations.loadMore}
              </CustomButton>
            </div>
          )}
        </div>
        <AlertDialog
          translations={translations.presets.presetDeleteDialog}
          isOpened={isDeletePresetDialogOpened}
          onClose={() => this.setState({ isDeletePresetDialogOpened: false })}
          onConfirm={this.handleDeletePreset}
          isWarning
        />
      </div>
    );
  }
}

OneOnOneReportPage.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  organizationSettings: PropTypes.object.isRequired,
  oneOnOneReport: PropTypes.object.isRequired,
  allUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getOneOnOneReport: PropTypes.func.isRequired,
  getAllUsers: PropTypes.func.isRequired,
  clearAllUsers: PropTypes.func.isRequired,
  clearOneOnOneReport: PropTypes.func.isRequired,
  formattedUsers: PropTypes.object.isRequired,
};

export default withStyles(styles)(OneOnOneReportPage);
