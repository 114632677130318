import { connect } from 'react-redux';
import { useParams } from 'react-router';
import PeoplePageContainer from '../../components/shared/peoplePageContainer';
import PeopleNotesPage from '../../components/pages/peopleNotesPage';
import { notesSelector } from '../../store/selectors/notesSelector';
import { tagsSelector } from '../../store/selectors/tagsSelector';
import { peopleSelector } from '../../store/selectors/peopleSelector';
import {
  getNotes,
  clearNotes,
  createNote,
  deleteNote,
  editNote,
  editTaskStatus,
} from '../../store/modules/notes';
import {
  addMultipleTags,
  getTagsCategories,
  clearTagsCategories,
} from '../../store/modules/tags';
import { getAllUsers, clearAllUsers } from '../../store/modules/people';
import { useTranslations } from '../../utility/useTranslations';
import { APP_PAGES } from '../../constants/pages';

const mapStateToProps = state => ({
  ...notesSelector(state),
  user: state.person,
  ...tagsSelector(state),
  allUsers: [...peopleSelector(state).allUsers],
});

const mapDispatchToProps = dispatch => ({
  getNotes: userId => getNotes(dispatch, userId),
  clearNotes: () => dispatch(clearNotes()),
  createNote: note => createNote(dispatch, note),
  deleteNote: noteId => deleteNote(dispatch, noteId),
  editNote: note => editNote(dispatch, note),
  editTaskStatus: (note, task) => editTaskStatus(dispatch, note, task),
  addMultipleTags: data => dispatch(addMultipleTags(data)),
  getTagsCategories: () => getTagsCategories(dispatch),
  clearTagsCategories: () => dispatch(clearTagsCategories()),
  getAllUsers: () => getAllUsers(dispatch),
  clearAllUsers: () => dispatch(clearAllUsers()),
});

const PeopleNotesView = props => {
  const { id } = useParams();
  const userId = (!Number.isNaN(Number(id)) && +id) || -1;
  const translations = useTranslations(APP_PAGES.PEOPLE_PRIVATE_NOTES);

  return (
    <PeoplePageContainer {...props} userId={userId} translations={translations}>
      <PeopleNotesPage />
    </PeoplePageContainer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PeopleNotesView);
