import { useState, useCallback, useMemo, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Fade, Typography, makeStyles } from '@material-ui/core';
import { PRESETS } from 'constants/presets';
import PresetDropdown from '../../shared/presetDropdown';
import AlertDialog from '../../shared/alertDialog';
import PageContainer from '../../shared/pageContainer';
import SelectField from '../../shared/selectField';
import CustomDateRange from '../../shared/customDateRange';
import SectionTitle from '../../shared/sectionTitle';
import MultipartPeoplePicker from '../../shared/multipartPeoplePicker';
import AttributeInfoBar from '../../shared/attributeInfoBar';
import ReportDetailsTable from '../../shared/reportDetailsTable';
import DeepDiveReport from '../../shared/deepDiveReport';
import NotificationCard from '../../shared/notificationCard';
import ReadLessMore from '../../shared/readLessMore';
import { surveysSelector } from '../../../store/selectors/surveysSelector';
import { peopleSelector } from '../../../store/selectors/peopleSelector';
import { tagsSelector } from '../../../store/selectors/tagsSelector';
import { jobTitlesSelector } from '../../../store/selectors/jobTitlesSelector';
import { levelsAndTracksSelector } from '../../../store/selectors/levelsAndTracksSelector';
import {
  getAttributesWithQuestions,
  clearAttributesWithQuestions,
} from '../../../store/modules/surveys';
import { getJobTitles, clearJobTitles } from '../../../store/modules/jobTitles';
import {
  getLevelsAndTracks,
  clearLevelsAndTracks,
} from '../../../store/modules/levelsAndTracks';
import {
  getTagsCategories,
  clearTagsCategories,
} from '../../../store/modules/tags';
import {
  getEmployees,
  isArrayEmpty,
  getObjectToNumberArray,
  isObjectEmpty,
  isUserClickable,
  isValueInList,
  areArraysContentsEqual,
  getItemById,
} from '../../../utility/helpers';
import {
  NO_ACTIVE_PRESET,
  getActivePresetId,
  getActivePresetName,
  getFilteredPresets,
  getPlaceholderName,
  getSurveyType,
  handleHasMaxPresets,
  handlePresetName,
  presetPeriod,
} from '../../../utility/presets';
import {
  addNewPreset,
  deleteSelectedPreset,
  editPresetActivity,
} from '../../../store/modules/organizationSettings';
import http from '../../../utility/http';
import { getAllUsers, clearAllUsers } from '../../../store/modules/people';
import {
  getGroupedSurveyOptions,
  prepareAnswerGridResults,
  prepareTextReplyResults,
  transformReviewData,
} from '../../../utility/reportUtils';
import { useTranslations } from '../../../utility/useTranslations';
import {
  parseDuplicateParameters,
  showSuccessMessage,
} from '../../../utility/uiUtils';
import { getStartEndOfDayUtc } from '../../../utility/dateUtils';
import { getSurveyStatuses } from '../../../utility/survey';
import {
  API_USERS_BASIC,
  API_REPORT_ATTRIBUTE_ANSWERS,
  API_REPORT_ATTRIBUTE_REPLIES,
  API_REPORT_ATTRIBUTE_FEEDBACK,
  API_REPORT_ATTRIBUTE_TIMELINE,
  API_REPORT_ATTRIBUTE_SCORE,
  api_attribute,
} from '../../../constants/apiRoutes';
import { ROLES } from '../../../constants/rolesAndPermissionList';
import { getLast12Months } from '../../shared/customDateRange/config';
import { getPageFilters, getUserRoles } from '../peoplePage/config';
import {
  GROUPED_SURVEY_REPORTING_TYPES,
  GROUPED_SURVEY_TYPES_KEYS,
} from '../../../constants/survey';
import { sticky } from '../../../constants/helperCssRules';
import { APP_PAGES, PARAMS } from '../../../constants/pages';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  pageDescription: {
    ...sticky(primary.white, 105),
    width: '100%',
    padding: spacing(8, 0, 8, 0),
  },
  filtersWrapper: {
    borderBottom: `1px solid ${primary.bluish7}`,
    display: 'grid',
    gridTemplateColumns: 'repeat(4, minmax(0,350px))',
    gridColumnGap: 16,
    marginBottom: spacing(8),
    paddingBottom: spacing(6),
    '&:last-of-type': {
      marginBottom: spacing(0),
    },
  },
  filtersUsersWrapper: {
    display: 'block',
  },
  usersSectionDescription: {
    margin: spacing(1, 0, 6),
  },
  topSpacing: {
    marginTop: spacing(6),
  },
  attributeInfoNoScore: {
    padding: spacing(3, 4, 3, 0),
  },
}));

const ATTRIBUTE_PRESET_ID = PRESETS.ATTRIBUTE;
const DROPDOWN_WIDTH = '350px';
const INPUT_WIDTH = 350;

const AttributeReportPage = ({
  navigate,
  auth,
  organizationSettings,
  presets,
  isPremiumUser,
  ...rest
}) => {
  const classes = useStyles();
  const translations = useTranslations(APP_PAGES.ATTRIBUTE_REPORT);
  const { SELF_ASSESSMENT, PULSE } = GROUPED_SURVEY_TYPES_KEYS;
  const [PeerTeanAndDirect] = GROUPED_SURVEY_REPORTING_TYPES;

  const [isDeletePresetDialogOpened, setIsDeletePresetDialogOpened] =
    useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const [tempState, setTempState] = useState(location?.state);
  const attributeState = tempState?.attribute;
  const getInitialPreset = () => {
    const attributeReportPagePresets = attributeState
      ? null
      : presets[ATTRIBUTE_PRESET_ID];
    const existsPresetsArray =
      attributeReportPagePresets && !isArrayEmpty(attributeReportPagePresets);
    const tempInitialPreset = existsPresetsArray
      ? getItemById(
          attributeReportPagePresets,
          presets.active[ATTRIBUTE_PRESET_ID]
        )
      : null;
    return tempInitialPreset;
  };
  const [initialPreset, setInitialPreset] = useState(getInitialPreset());
  const [selectedPreset, setSelectedPreset] = useState(initialPreset);
  const [defaultPlaceholder, setDefaultPlaceholder] = useState(false);
  const [presetPlaceholderName, setPresetPlaceholderName] = useState('');

  const [isLoading, setIsLoading] = useState(true);
  const [isReadMore, setIsReadMore] = useState(true);
  const [surveyType, setSurveyType] = useState(PeerTeanAndDirect);
  const [currentAttributeData, setCurrentAttributeData] = useState(null);
  const [period, setPeriod] = useState(getLast12Months());
  const [results, setResults] = useState({});
  const [attributeScore, setAttributeScore] = useState(null);
  const [openEndedQuestionId, setOpenEndedQuestionId] = useState(null);
  const [feedback, setFeedback] = useState([]);
  const [surveyReference, setSurveyReference] = useState([]);

  const { attributesWithQuestions } = useSelector(surveysSelector);
  const { allUsers } = useSelector(peopleSelector);
  const { jobTitles } = useSelector(jobTitlesSelector);
  const { tracks } = useSelector(levelsAndTracksSelector);
  const { categories } = useSelector(tagsSelector);

  const isOpenEndedAttribute = currentAttributeData?.text_reply_questions;
  const surveyTypeOptions = getGroupedSurveyOptions(
    translations.surveyOptions,
    true
  );
  const surveyStatuses = getSurveyStatuses(translations.surveyStatuses);
  const detailsTableColumns = [
    { label: translations.review.details.date },
    { label: translations.review.details.commentText },
    { label: translations.review.details.status },
  ];

  const allEmployees = useMemo(() => getEmployees(allUsers), [allUsers]);
  const [reviewedUsers, setReviewedUsers] = useState([]);
  const [reviewers, setReviewers] = useState([]);
  const [attribute, setAttribute] = useState(attributeState || initialPreset);

  const initialAttribute = useMemo(
    () =>
      initialPreset && !isArrayEmpty(attributesWithQuestions)
        ? getItemById(attributesWithQuestions, initialPreset?.data?.attribute)
        : attribute,
    [initialPreset, attribute, attributesWithQuestions]
  );
  const isSelfReport = surveyType.key === SELF_ASSESSMENT;
  const isPulseReport = surveyType.key === PULSE;
  const presetState = {
    defaultPlaceholder,
    presetPlaceholderName,
    isLoading,
  };

  const activePresetId = attributeState
    ? NO_ACTIVE_PRESET
    : getActivePresetId(presets, ATTRIBUTE_PRESET_ID);
  const message =
    activePresetId !== NO_ACTIVE_PRESET ? translations.presets.used : '';
  const [alreadySavedMessage, setAlreadySavedMessage] = useState(message);
  const filteredPresets = getFilteredPresets(presets, ATTRIBUTE_PRESET_ID);
  const presetName = getActivePresetName(filteredPresets, activePresetId);
  const handledPresetName = handlePresetName(
    filteredPresets,
    activePresetId,
    presetName,
    presetState,
    translations.presets.startSearch
  );
  const placeholderName = getPlaceholderName(
    handledPresetName,
    presetName,
    translations.presets.startSearch
  );

  const reviewedUsersFilters = useMemo(
    () =>
      !isLoading
        ? getPageFilters(
            translations,
            jobTitles,
            tracks,
            categories,
            getUserRoles(translations, true),
            false,
            auth
          )
        : [],
    [translations, isLoading, auth, jobTitles, tracks, categories]
  );
  const reviewersFilters = useMemo(
    () =>
      !isLoading
        ? getPageFilters(
            translations,
            jobTitles,
            tracks,
            categories,
            getUserRoles(translations, true, isPremiumUser),
            false,
            auth
          )
        : [],
    [
      translations,
      isLoading,
      auth,
      isPremiumUser,
      jobTitles,
      tracks,
      categories,
    ]
  );

  const handleResetPreset = useCallback(() => {
    editPresetActivity(dispatch, -1, ATTRIBUTE_PRESET_ID, false);
    setSelectedPreset(undefined);
    setInitialPreset(undefined);
    setAlreadySavedMessage('');
  }, [dispatch]);

  const areFilterAndPresetEqual = (selectedData, presetData) => {
    return (
      areArraysContentsEqual(
        selectedData?.subject || [],
        presetData?.subject || []
      ) &&
      areArraysContentsEqual(
        selectedData?.reviewer || [],
        presetData?.reviewer || []
      ) &&
      selectedData?.from_date === presetData.from_date &&
      selectedData?.to_date === presetData.to_date &&
      areArraysContentsEqual(
        selectedData?.survey_type,
        presetData.survey_type
      ) &&
      selectedData?.attribute === presetData?.attribute
    );
  };

  const checkValueInPresets = selectedData => {
    const attributePresets = presets[ATTRIBUTE_PRESET_ID];
    setTempState(null);

    if (attributePresets && !isArrayEmpty(attributePresets)) {
      const activePreset = attributePresets.find(preset => {
        const presetData = preset?.data;
        return areFilterAndPresetEqual(selectedData, presetData);
      });
      if (activePreset) {
        editPresetActivity(
          dispatch,
          activePreset.id,
          ATTRIBUTE_PRESET_ID,
          true
        );
        setInitialPreset(undefined);
        setAlreadySavedMessage(translations.presets.used);
      } else {
        handleResetPreset();
      }
    }
  };

  const reportParams = useMemo(() => {
    const { ATTRIBUTE, SURVEY_TYPE, START, END, START_AT, END_AT } = PARAMS;
    const subject = getObjectToNumberArray(reviewedUsers);
    const reviewer = getObjectToNumberArray(reviewers);

    return {
      [ATTRIBUTE]: initialAttribute?.id,
      [SURVEY_TYPE]: surveyType?.values,
      [START_AT]: initialPreset
        ? initialPreset?.data?.from_date
        : getStartEndOfDayUtc(period[START]),
      [END_AT]: initialPreset
        ? initialPreset?.data?.to_date
        : getStartEndOfDayUtc(period[END], true),
      ...(isPulseReport ? {} : { subject }),
      ...(isSelfReport ? {} : { reviewer }),
    };
  }, [
    initialAttribute,
    period,
    reviewers,
    reviewedUsers,
    surveyType,
    initialPreset,
    isSelfReport,
    isPulseReport,
  ]);

  const goToUserProfilePage = userId => navigate(`/people/${userId}`);

  const getSurveyLinkPath = survey => `/surveys/${survey.id}/report`;

  const canGoToUserProfile = user =>
    isUserClickable(auth, organizationSettings.global_see_himself)(user);

  const handleInitData = data => {
    const [, allUsersResults, , , ,] = data;
    const users = allUsersResults?.payload;
    const employees = getEmployees(users);

    const initialReviewedUsers =
      initialPreset && !isArrayEmpty(users)
        ? users.filter(user =>
            isValueInList(initialPreset?.data?.subject, user.id)
          )
        : reviewedUsers;

    const initialReviewers =
      initialPreset && !isArrayEmpty(employees)
        ? employees.filter(user =>
            isValueInList(initialPreset?.data?.reviewer, user.id)
          )
        : reviewers;

    const initialSurveyType = initialPreset
      ? getSurveyType(
          GROUPED_SURVEY_REPORTING_TYPES,
          initialPreset?.data?.survey_type
        )
      : surveyType;

    const initialPeriod = initialPreset
      ? presetPeriod(initialPreset?.data)
      : period;

    setReviewers(initialReviewers);
    setReviewedUsers(initialReviewedUsers);
    setSurveyType(initialSurveyType);
    setPeriod(initialPeriod);
  };

  const getInitialData = useCallback(async () => {
    try {
      await Promise.all([
        getAttributesWithQuestions(dispatch),
        getAllUsers(dispatch, {}, true),
        getJobTitles(dispatch),
        getTagsCategories(dispatch),
        getLevelsAndTracks(dispatch),
      ]).then(data => {
        handleInitData(data);
      });

      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const onGetReviewedUsers = useCallback(data => {
    const { EXCLUDE_ROLE } = PARAMS;

    const params = {
      ...data,
      [EXCLUDE_ROLE]: ROLES.ASSOCIATE,
    };

    return http.get(API_USERS_BASIC, {
      params,
      paramsSerializer: d => parseDuplicateParameters(d),
    });
  }, []);

  const onGetReviewers = useCallback(data => {
    const params = {
      ...data,
    };

    return http.get(API_USERS_BASIC, {
      params,
      paramsSerializer: d => parseDuplicateParameters(d),
    });
  }, []);

  const handleChange = useCallback(async () => {
    const isOpenEnded = initialAttribute?.text_reply_questions;
    const reviewersIds = getObjectToNumberArray(reviewers);
    let currentScore = null;
    let reviewFeedback = [];

    if (
      initialAttribute &&
      (isSelfReport || !isArrayEmpty(reviewers)) &&
      (isPulseReport || !isArrayEmpty(reviewedUsers))
    ) {
      try {
        const [reportResults, currentReference, currentAttribute] =
          await Promise.all([
            http.get(
              isOpenEnded
                ? API_REPORT_ATTRIBUTE_REPLIES
                : API_REPORT_ATTRIBUTE_ANSWERS,
              {
                params: reportParams,
                paramsSerializer: d => parseDuplicateParameters(d),
              }
            ),
            http.get(API_REPORT_ATTRIBUTE_TIMELINE, {
              params: reportParams,
              paramsSerializer: d => parseDuplicateParameters(d),
            }),
            http.get(api_attribute(initialAttribute?.id)),
          ]);

        if (!isObjectEmpty(reportResults?.data)) {
          let currentAttributeScore = null;
          let currentFeedback = [];

          if (!isOpenEnded) {
            currentAttributeScore = await http.get(API_REPORT_ATTRIBUTE_SCORE, {
              params: reportParams,
              paramsSerializer: d => parseDuplicateParameters(d),
            });
          }

          if (currentAttribute?.data?.feedback_field) {
            currentFeedback = await http.get(API_REPORT_ATTRIBUTE_FEEDBACK, {
              params: reportParams,
              paramsSerializer: d => parseDuplicateParameters(d),
            });
          }
          currentScore = currentAttributeScore?.data?.score || null;
          reviewFeedback = currentFeedback?.data || [];
        }
        setAttributeScore(currentScore);
        setCurrentAttributeData(transformReviewData(currentAttribute?.data));
        setFeedback(reviewFeedback);
        setSurveyReference(currentReference?.data || []);
        setOpenEndedQuestionId(null);
        return setResults(
          isOpenEnded
            ? prepareTextReplyResults(reportResults?.data, reviewersIds)
            : prepareAnswerGridResults(reportResults?.data, reviewersIds)
        );
      } catch {
        setResults({});
        setAttributeScore(currentScore);
        setCurrentAttributeData(null);
        setFeedback(reviewFeedback);
        setSurveyReference([]);
        setOpenEndedQuestionId(null);
      }
    }
    setSurveyReference([]);
    setOpenEndedQuestionId(null);
    setAttributeScore(currentScore);
    setFeedback(reviewFeedback);

    return setResults({});
  }, [
    initialAttribute,
    reviewers,
    reviewedUsers,
    reportParams,
    isSelfReport,
    isPulseReport,
  ]);

  const cleanup = useCallback(() => {
    dispatch(clearAttributesWithQuestions());
    dispatch(clearAllUsers());
    dispatch(clearLevelsAndTracks());
    dispatch(clearJobTitles());
    dispatch(clearTagsCategories());
  }, [dispatch]);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  useEffect(() => {
    return cleanup;
  }, [cleanup]);

  useEffect(() => {
    if (initialAttribute) {
      handleChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleChange]);

  const handlePreviewOpenEndedQuestion = questionId => e => {
    e.stopPropagation();

    setOpenEndedQuestionId(prevQuestionId =>
      prevQuestionId !== questionId ? questionId : null
    );
  };

  const handleAttributeChange = selectedAttribute => {
    setAttribute(selectedAttribute);
    checkValueInPresets({ ...reportParams, attribute: selectedAttribute?.id });
  };

  const handlePeriodChange = newRange => {
    const { START, END } = PARAMS;
    setPeriod(newRange);
    checkValueInPresets({
      ...reportParams,
      from_date: getStartEndOfDayUtc(newRange[START]),
      to_date: getStartEndOfDayUtc(newRange[END], true),
    });
  };

  const handleSurveyTypeChange = selectedSurveyType => {
    setSurveyType(selectedSurveyType);
    const isSelf =
      selectedSurveyType.key === GROUPED_SURVEY_TYPES_KEYS.SELF_ASSESSMENT;
    const isPulse = selectedSurveyType.key === GROUPED_SURVEY_TYPES_KEYS.PULSE;

    checkValueInPresets({
      ...reportParams,
      survey_type: selectedSurveyType.values,
      ...(isPulse ? {} : { subject: getObjectToNumberArray(reviewedUsers) }),
      ...(isSelf ? {} : { reviewer: getObjectToNumberArray(reviewers) }),
    });
  };

  const handleReviewedUsersChange = useCallback(
    selectedUsers => {
      setReviewedUsers(selectedUsers);
      checkValueInPresets({
        ...reportParams,
        subject: getObjectToNumberArray(selectedUsers),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportParams]
  );

  const handleReviewersUsersChange = useCallback(
    selectedUsers => {
      setReviewers(selectedUsers);
      checkValueInPresets({
        ...reportParams,
        reviewer: getObjectToNumberArray(selectedUsers),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportParams]
  );

  const handleToggleReadMore = () =>
    setIsReadMore(prevReadMore => !prevReadMore);

  const handlePlaceholder = name => {
    setDefaultPlaceholder(false);
    setPresetPlaceholderName(name);
  };

  const handleDeletePresetDialog = preset => {
    setSelectedPreset(preset);
    setIsDeletePresetDialogOpened(true);
  };

  const handleDeletePreset = () => {
    deleteSelectedPreset(dispatch, selectedPreset.id, ATTRIBUTE_PRESET_ID).then(
      () => {
        setIsDeletePresetDialogOpened(false);

        if (presets.active[ATTRIBUTE_PRESET_ID] === selectedPreset.id) {
          setSelectedPreset(undefined);
          setInitialPreset(undefined);
          setAlreadySavedMessage('');
        }
        showSuccessMessage(translations.presets.successMessages.deletedPreset);
      }
    );
  };

  const handleSavePreset = name => {
    const { START, END } = PARAMS;

    const data = {
      attribute: initialAttribute?.id,
      survey_type: surveyType?.values,
      from_date: getStartEndOfDayUtc(period[START]),
      to_date: getStartEndOfDayUtc(period[END], true),
      subject: getObjectToNumberArray(reviewedUsers),
      reviewer: getObjectToNumberArray(reviewers),
    };
    addNewPreset(dispatch, {
      name,
      location: ATTRIBUTE_PRESET_ID,
      data,
    }).then(() => {
      setAlreadySavedMessage(translations.presets.used);
      showSuccessMessage(translations.presets.successMessages.createdPreset);
    });
  };

  const handleApplyPreset = preset => {
    const presetData = preset?.data;
    const presetSurveyType = getSurveyType(
      GROUPED_SURVEY_REPORTING_TYPES,
      presetData?.survey_type
    );
    const presetAttribute = getItemById(
      attributesWithQuestions,
      presetData.attribute
    );
    const presetPeriodData = presetPeriod(presetData);
    const presetReviewedUsers = allEmployees.filter(user =>
      isValueInList(presetData?.subject, user.id)
    );
    const presetReviewers = allUsers.filter(user =>
      isValueInList(presetData?.reviewer, user.id)
    );

    setTempState(null);
    editPresetActivity(dispatch, preset.id, ATTRIBUTE_PRESET_ID, true);
    setSelectedPreset(preset);
    setInitialPreset(null);
    setAttribute(presetAttribute);
    setSurveyType(presetSurveyType);
    setPeriod(presetPeriodData);
    setReviewedUsers(presetReviewedUsers);
    setReviewers(presetReviewers);
  };

  const attributeDisabledPresetMessage = () => {
    const isPeerTeamDirectReport = !isSelfReport && !isPulseReport;
    const noReviewedUsers = !reviewedUsers || isArrayEmpty(reviewedUsers);
    const noReviewers = !reviewers || isArrayEmpty(reviewers);

    return !initialAttribute ||
      (isSelfReport && noReviewedUsers) ||
      (isPulseReport && noReviewers) ||
      (isPeerTeamDirectReport && (noReviewedUsers || noReviewers))
      ? translations.presets.attributePresetConditionTooltip
      : '';
  };

  return (
    <PageContainer
      {...rest}
      translations={translations}
      auth={auth}
      navigate={navigate}
      organizationSettings={organizationSettings}
      isPremiumUser={isPremiumUser}
      shouldPassProps={false}
      isFullWidthContent
    >
      {!isLoading && (
        <div>
          <div className={classes.pageDescription}>
            <ReadLessMore
              translations={translations}
              isReadMore={isReadMore}
              toggleReadMore={handleToggleReadMore}
            >
              <Typography variant="body2">
                {translations.descriptionPartOne}
              </Typography>
              <Typography variant="body2">
                {translations.descriptionPartTwo}
              </Typography>
              <Typography variant="body2">
                {translations.descriptionPartThree}
              </Typography>
              <Typography variant="body2">
                {translations.descriptionPartFour}
              </Typography>
            </ReadLessMore>
          </div>
          <div className={classes.filtersWrapper}>
            <SelectField
              value={surveyType}
              label={translations.selectFilters.surveyType}
              options={surveyTypeOptions}
              parser={{ value: 'key', label: 'name' }}
              onChange={handleSurveyTypeChange}
              shouldReturnOption
              isSearchDisabled
            />
            <SelectField
              label={translations.selectFilters.attribute.label}
              placeholder={translations.selectFilters.attribute.placeholder}
              value={initialAttribute}
              options={attributesWithQuestions}
              parser={{ value: 'id', label: 'name' }}
              onChange={handleAttributeChange}
              shouldReturnOption
              hasColorBox
              isAttribute
            />
            <CustomDateRange
              label={translations.selectFilters.period}
              initialRange={{
                startDate: period[PARAMS.START],
                endDate: period[PARAMS.END],
              }}
              startAtKey={PARAMS.START}
              endAtKey={PARAMS.END}
              onChange={handlePeriodChange}
            />
            <PresetDropdown
              translations={translations.presets}
              dropdownWidth={DROPDOWN_WIDTH}
              inputWidth={INPUT_WIDTH}
              options={filteredPresets}
              activePreset={activePresetId}
              handleApply={handleApplyPreset}
              handleDelete={handleDeletePresetDialog}
              handleSavePreset={handleSavePreset}
              disabledPreset={
                handleHasMaxPresets(filteredPresets, translations.presets) ||
                attributeDisabledPresetMessage()
              }
              alreadySavedMessage={alreadySavedMessage}
              presetPlaceholderName={placeholderName}
              handlePlaceholder={handlePlaceholder}
            />
          </div>
          <Fade in={!isPulseReport} appear={false} unmountOnExit>
            <div
              className={classNames(
                classes.filtersWrapper,
                classes.filtersUsersWrapper
              )}
            >
              <SectionTitle
                title={translations.users.objectsOfReview.title}
                variant="h4"
              />
              <Typography
                className={classes.usersSectionDescription}
                variant="body2"
              >
                {translations.users.objectsOfReview.description}
              </Typography>
              <MultipartPeoplePicker
                translations={translations.objectsPicker}
                filters={reviewedUsersFilters}
                allUsers={allEmployees}
                selectedUsers={reviewedUsers}
                onGetUsers={onGetReviewedUsers}
                onSave={handleReviewedUsersChange}
              />
            </div>
          </Fade>
          <Fade in={!isSelfReport} appear={false} unmountOnExit>
            <div
              className={classNames(
                classes.filtersWrapper,
                classes.filtersUsersWrapper
              )}
            >
              <SectionTitle
                title={translations.users.reviewers.title}
                variant="h4"
              />
              <Typography
                className={classes.usersSectionDescription}
                variant="body2"
              >
                {translations.users.reviewers.description}
              </Typography>
              <MultipartPeoplePicker
                translations={translations.reviewersPicker}
                filters={reviewersFilters}
                allUsers={allUsers}
                selectedUsers={reviewers}
                onGetUsers={onGetReviewers}
                onSave={handleReviewersUsersChange}
              />
            </div>
          </Fade>
          {currentAttributeData && !isObjectEmpty(results) && (
            <AttributeInfoBar
              className={classes.topSpacing}
              translations={translations.attributeInfoBar}
              attribute={currentAttributeData}
              averageScore={attributeScore}
              isOpenEndedAttribute={isOpenEndedAttribute}
              shouldDisableIndividualScores
              shouldShowScores
            />
          )}
          <DeepDiveReport
            translations={translations.review}
            results={results}
            feedback={feedback}
            attribute={currentAttributeData}
            openEndedQuestionId={openEndedQuestionId}
            isOpenEndedAttribute={isOpenEndedAttribute}
            canGoToUserProfile={canGoToUserProfile}
            onOpenEndedQuestionPreview={handlePreviewOpenEndedQuestion}
            onGoToUserProfile={goToUserProfilePage}
          />
          <NotificationCard
            className={classes.topSpacing}
            shouldFade={isObjectEmpty(results)}
            content={translations.noResults}
          />
          {!isArrayEmpty(surveyReference) && (
            <ReportDetailsTable
              className={classes.topSpacing}
              translations={translations.review.details}
              columns={detailsTableColumns}
              statuses={surveyStatuses}
              details={surveyReference}
              getRedirectLinkPath={getSurveyLinkPath}
              isTimeline
            />
          )}
          <AlertDialog
            translations={translations.presets.presetDeleteDialog}
            isOpened={isDeletePresetDialogOpened}
            onClose={() => setIsDeletePresetDialogOpened(false)}
            onConfirm={handleDeletePreset}
            isWarning
          />
        </div>
      )}
    </PageContainer>
  );
};

AttributeReportPage.propTypes = {
  navigate: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  organizationSettings: PropTypes.object.isRequired,
  isPremiumUser: PropTypes.bool.isRequired,
};

export default memo(AttributeReportPage);
