import {
  isEmpty,
  forbiddenCharacters,
  validateLength,
} from '../../../utility/validation';

export const TITLE_FIELD = {
  required: true,
  validators: [
    { type: 'isEmpty', validator: isEmpty },
    { type: 'forbiddenCharacters', validator: forbiddenCharacters },
    {
      type: 'maxEntryLength',
      validator: value => validateLength(value, 0, 500),
    },
  ],
};

export const ANSWER_FIELD = {
  required: true,
  validators: [
    { type: 'isEmpty', validator: isEmpty },
    { type: 'forbiddenCharacters', validator: forbiddenCharacters },
    {
      type: 'maxAnswerEntryLength',
      validator: value => validateLength(value, 0, 1000),
    },
  ],
};

export const QUESTION_TITLE_ERROR = 'error_question_title';
export const ANSWER_TITLE_ERROR = 'error_answer_title';
export const TEXT = 'text';
export const BOX_SIZE_BREAKPOINT = {
  THRESHOLD_FOR_MEDIUM: 6,
  THRESHOLD_FOR_LARGE: 10,
};
export const NEUTRAL_ANSWER = 'Neutral answer';
export const NEUTRAL_ANSWER_WEIGHT = -1;
export const N_A = 'N/A';
export const WARNING_BREAKPOINT_ANSWERS = 10;
export const WARNING_BREAKPOINTS_CHARACTERS = {
  SMALL: 2,
  MEDIUM: 5,
};
export const DELAY = 600;
