import classNames from 'classnames';
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  withStyles,
} from '@material-ui/core';
import Breadcrumbs from '../../shared/breadcrumbs';
import { gridColumn } from '../../../constants/gridColumn';

const styles = ({ palette: { primary }, spacing }) => ({
  wrapper: {
    height: '100vh',
    overflowY: 'auto',
  },
  main: {
    padding: spacing(8),
    marginBottom: spacing(16),
  },
  breadcrumbs: {
    marginTop: spacing(6),
    marginBottom: spacing(11),
  },
  title: {
    width: '100%',
    textAlign: 'center',
    margin: spacing(16, 0),
  },
  headerSubtitle: {
    color: primary.bluish4,
    fontFamily: 'ProximaNova-RegularIt',
    fontWeight: 'bold',
  },

  subtitle: {
    margin: spacing(2, 0),
    padding: spacing(5),
  },
  subtitleAnnex: {
    margin: spacing(16, 0, 4),
  },
  details: {
    marginBottom: spacing(6),
    padding: spacing(0, 5),
  },
  definitions: {
    color: primary.bluish2,
    display: 'flex',
    flexWrap: 'wrap',
    fontFamily: 'ProximaNova-Regular',
    fontSize: 14,
    lineHeight: '17px',
    padding: spacing(0, 5, 6, 5),
    margin: 0,
    width: '100%',
  },
  definitionTerm: {
    fontFamily: 'ProximaNova-Semibold',
    flexShrink: 0,
    width: 100,
    paddingRight: spacing(4),
    marginBottom: spacing(3),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  definitionDescription: {
    marginLeft: 0,
    marginBottom: spacing(3),
    '&:last-of-type': {
      marginBottom: 0,
    },
    width: 'calc(100% - 140px)',
  },
  expansionPanelRoot: {
    margin: 0,
    '&$expanded': {
      marginTop: 0,
      marginBottom: spacing(4),
    },
  },
  expanded: {},
  itemWrapper: {
    marginBottom: spacing(3),
    '&:last-of-type': { marginBottom: 0 },
  },
  item: { display: 'flex', marginBottom: spacing(2) },
  order: {
    boxSizing: 'border-box',
    fontFamily: 'ProximaNova-Bold',
    paddingRight: spacing(1),
    flexShrink: 0,
    minWidth: 35,
  },
  orderNestedFirstLevel: { minWidth: 20 },
  nestedItem: {
    display: 'flex',
    paddingLeft: spacing(9),
    marginBottom: spacing(2),
    '&:last-of-type': { marginBottom: 0 },
  },
  nestedItemSecondLevel: { paddingLeft: spacing(5) },
  noData: { padding: spacing(0, 5) },
  agreementScope: {
    margin: spacing(4, 0),
  },
  bold: {
    fontfamily: 'ProximaNova-Bold',
  },
});

const translations = {
  back: 'back',
};

const routes = [];

const TermsOfServicePage = props => {
  const { classes, history } = props;

  const nestedItemClasses = classNames(
    classes.order,
    classes.orderNestedFirstLevel
  );

  return (
    <Grid container spacing={0} className={classes.wrapper}>
      <Grid item sm={gridColumn.one} lg={gridColumn.three} />
      <Grid item xs={gridColumn.twelve} sm={gridColumn.ten} lg={gridColumn.six}>
        <div className={classes.main}>
          <Breadcrumbs
            className={classes.breadcrumbs}
            translations={translations}
            routes={routes}
            onHandleBack={() => history.back()}
          />
          <Typography variant="h1" className={classes.title}>
            SUBSCRIPTION AGREEMENT
          </Typography>
          <Typography
            className={classNames(classes.subtitle, classes.headerSubtitle)}
          >
            Published June 20th, 2022
          </Typography>
          <Typography variant="h4" className={classes.subtitle}>
            Definitions
          </Typography>
          <dl className={classes.definitions}>
            <dt className={classes.definitionTerm}>Kadar</dt>
            <dd className={classes.definitionDescription}>
              Represents a limited liability company incorporated in Commercial
              Register of Republic of Serbia under the business name Kadar
              Technologies Inc., with registered seat in 651 N Broad St, Suite
              206, Middletown, DE 19709, USA;
            </dd>
            <dt className={classes.definitionTerm}>Customer</dt>
            <dd className={classes.definitionDescription}>
              Represents legal entity or an individual entering in this
              Agreement, by providing its consent;
            </dd>
            <dt className={classes.definitionTerm}>Parties/Party</dt>
            <dd className={classes.definitionDescription}>
              Represents the referral to Kadar and Customer, together or
              separately;
            </dd>
            <dt className={classes.definitionTerm}>Third party</dt>
            <dd className={classes.definitionDescription}>
              Represents any legal entity or individual that is not Kadar,
              Customer, or any of its employees or outsourced contractors;
            </dd>
            <dt className={classes.definitionTerm}>Platform</dt>
            <dd className={classes.definitionDescription}>
              Represents an employee engagement system produced and in ownership
              by Kadar, and available on the kadar.app, which is at the time of
              publishing of this Agreement in its beta version;
            </dd>
            <dt className={classes.definitionTerm}>Documentation</dt>
            <dd className={classes.definitionDescription}>
              Represents documents containing legal, financial and technical
              data relevant to the Platform, and available online, at any given
              time as of the effective date of this Agreement;
            </dd>
            <dt className={classes.definitionTerm}>Services</dt>
            <dd className={classes.definitionDescription}>
              Represents functionalities available to the Customer trough use of
              the Platform, as well as professional services listed in Annex B
              and provided by Kadar directly to the Customer, in accordance with
              this Agreement;
            </dd>
            <dt className={classes.definitionTerm}>Authorised User</dt>
            <dd className={classes.definitionDescription}>
              Represents users designated by the Customer and who are given the
              right of access to the Platform and to use the Platform, in
              accordance with this Agreement;
            </dd>
            <dt className={classes.definitionTerm}>Customer Data</dt>
            <dd className={classes.definitionDescription}>
              Represents any data or information uploaded by Customer and/or its
              Authorized Users into the Platform or provided by Customer to
              Kadar in connection with the provision of Services;
            </dd>
            <dt className={classes.definitionTerm}>Subscription Period</dt>
            <dd className={classes.definitionDescription}>
              Period in respect to which the Customer has concluded this
              Agreement and has paid the fee;
            </dd>
            <dt className={classes.definitionTerm}>Effective Date</dt>
            <dd className={classes.definitionDescription}>
              Represents the date of the acceptance of this Agreement by the
              Customer;
            </dd>
          </dl>
          <Typography variant="h4" className={classes.subtitle}>
            Scope of the agreement
          </Typography>
          <Typography component="div" className={classes.details}>
            <Typography className={classes.agreementScope}>
              <b>A.</b> This Agreement regulates the mutual rights and
              obligations of the Parties, in respect to the use of the Platform
              by the Customer, all in accordance with this Agreement and Data
              Processing Agreement attached hereto as
              <b>Annex A</b>;
            </Typography>
            <Typography className={classes.agreementScope}>
              <b>B.</b> This Agreement will specify the functionalities that
              will be made available to Customer through use of the Platform as
              well as any professional services to be performed by Kadar.
            </Typography>
            <Typography className={classes.agreementScope}>
              <b>C.</b> Customer is granted the rights to create any number of
              Authorized users, in accordance with the terms of this Agreement;
            </Typography>
          </Typography>
          <Typography variant="h4" className={classes.subtitle}>
            The parties therefore agree as follows:
          </Typography>
          <Accordion
            classes={{
              root: classes.expansionPanelRoot,
              expanded: classes.expanded,
            }}
          >
            <AccordionSummary>
              <Typography variant="h4" className={classes.subtitle}>
                1. Provision of services
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.details}>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      1.1
                    </Typography>
                    Kadar shall in accordance with this Agreement:
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      a.
                    </Typography>
                    provide the Customer with technical details required to
                    create and manage accounts of agreed number of Authorized
                    Users;
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      b.
                    </Typography>
                    provide the Authorized users with access to and use of the
                    Platform, and
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      c.
                    </Typography>
                    perform the perform the Professional Services listed in
                    Annex B attached to this Agreement, as per written request
                    of the Customer submitted in accordance with the Request for
                    professional Services Protocol attached hereto as Annex C.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      1.2
                    </Typography>
                    Kadar shall use reasonable efforts to make the Platform
                    available, excluding the following excused outages:
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      a.
                    </Typography>
                    scheduled maintenance outside 9am to 5pm CET on Monday to
                    Friday (“Business Hours” and “Business Days” respectively),
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      b.
                    </Typography>
                    unscheduled maintenance in the case of actual or anticipated
                    emergency, and
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      c.
                    </Typography>
                    unavailability for reasons beyond Kadar&apos;s reasonable
                    control.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      1.3
                    </Typography>
                    Kadar shall
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      a.
                    </Typography>
                    perform the Services substantially in accordance with this
                    agreement and with reasonable skill and care,
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      b.
                    </Typography>
                    comply with laws applicable to this Agreement and
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      c.
                    </Typography>
                    maintain any licenses and consents required for provision of
                    Services in accordance with this Agreement.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      1.4
                    </Typography>
                    Kadar shall use reasonable efforts to promptly correct any
                    material non-conformity or malfunction of the Platform as
                    detailed in the Documentation, however Kadar will not be
                    liable for any damage, cost incurred, breach of law etc.
                    arisen out of:
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      a.
                    </Typography>
                    the use of the Platform and/or Services during the beta
                    stage;
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      b.
                    </Typography>
                    the use of the Platform and/or Services contrary to the
                    terms of this Agreement, Documentation, or Kadar&apos;s
                    instructions, or
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      c.
                    </Typography>
                    the use of the Platform and/or Services that are/were
                    modified other than by, or on behalf of Kadar, or
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      d.
                    </Typography>
                    Customer&apos;s connection to the Platform over the internet
                    or integration to the Platform. Customer is responsible for
                    ensuring that all technical requirements required for
                    connection and integration to the Platform and for Services
                    are duly met, and that the Authorized users are made
                    available with equipment fit for purpose.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      1.5
                    </Typography>
                    Customer accepts and acknowledge that during the beta stage
                    Kadar may unilaterally and without prior notification to the
                    Customer, modify the Documentation and the Platform, make
                    Platform unavailable for any period of time, etc.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      1.6
                    </Typography>
                    Once the beta stage is completed, Kadar may unilaterally and
                    without prior notification to the Customer modify the
                    Documentation and the Platform provided that such
                    modification does not materially reduce the functionality of
                    the Platform and/or is providing alternative features that
                    have materially the same benefits as the previous feature.
                    Kadar may unilaterally and without prior notification to the
                    Customer modify the Platform in order to exclude part of
                    functionalities that are not often used or which are
                    affecting the security, integrity or well-functioning of the
                    Platform.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      1.7
                    </Typography>
                    Kadar may use the name and logo of the Customer for
                    promotional and marketing purposes.
                  </Typography>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary>
              <Typography variant="h4" className={classes.subtitle}>
                2. Customer data
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.details}>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      2.1
                    </Typography>
                    Customer is the sole owner of Customer data. Customer is
                    solely responsible for the content of the Customer Data, and
                    shall indemnify, defend and hold Kadar harmless from any
                    liability, claim or penalty with respect to breach of law,
                    nonconformity, copyright infringement etc. which relates in
                    any way to the content of the Customer Data or to its use,
                    processing and other.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      2.2
                    </Typography>
                    Kadar shall back-up Customer Data as part of security
                    measures. If there is any loss or damage to Customer data
                    due to a Platform error, then Kadar shall use reasonable
                    efforts to restore the lost or damaged Customer data from
                    the latest back-up as its sole liability. Kadar shall not be
                    responsible for any loss, destruction, alteration or
                    disclosure of Customer data caused by Third party.
                  </Typography>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h4" className={classes.subtitle}>
                3. Customer&#39;s obligations
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.details}>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      3.1
                    </Typography>
                    Customer is obligated to use the Platform and Professional
                    Services, in accordance with this Agreement and
                    Documentation.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      3.2
                    </Typography>
                    Customer is authorized to designate the agreed number of
                    Authorized Users which will be awarded the right to access
                    and to use the Platform via individual accounts, all in
                    accordance with the terms of this Agreement.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      3.3
                    </Typography>
                    Customer is obliged to designate as Authorized user only its
                    employees or its outsourced contractors, as well as to
                    secure that only one individual use one account. Authorized
                    User&apos;s account may not be shared.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      3.4
                    </Typography>
                    Customer shall secure via internal individual contracts with
                    its employees and outsourced contractors that each
                    Authorized User comply with this Agreement, and will be
                    liable for any breach of this Agreement, or applicable laws,
                    made via Platform by its Authorized users.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      3.5
                    </Typography>
                    Customer is obligated to use the Platform and the
                    Professional Services for the benefit of its internal
                    business operation, and may not sell the services of the
                    Platform or Professional Services to Third parties;
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      3.6
                    </Typography>
                    Customer shall:
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      a.
                    </Typography>
                    co-operate with Kadar and provide any necessary information,
                    as required for provision of the Services,
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      b.
                    </Typography>
                    comply with laws applicable to this Agreement, or any other
                    laws that may be applicable to Customer data or its use, and
                    maintain any necessary licenses and consents to allow the
                    use of the Customer Data in accordance with this Agreement,
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      c.
                    </Typography>
                    secure that the Authorized Users keep their Platform
                    passwords confidential, and
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      d.
                    </Typography>
                    use reasonable efforts to prevent unauthorized access or use
                    of the Platform (and if Customer is aware of unauthorized
                    access or use, promptly notify Kadar).
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      3.7
                    </Typography>
                    Customer shall not (and Kadar may suspend Customer&apos;s
                    access to the Platform if any of the following occur):
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      a.
                    </Typography>
                    access, store, distribute or transmit any viruses or any
                    material that is unlawful, harmful, threatening, defamatory,
                    obscene, infringing, harassing, discriminatory or otherwise
                    offensive,
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      b.
                    </Typography>
                    <Typography component="span">
                      except as expressly permitted under this Agreement or
                      allowed by any applicable law that is incapable of
                      exclusion:
                      <Typography
                        component="span"
                        className={classes.nestedItem}
                      >
                        <Typography
                          component="span"
                          className={nestedItemClasses}
                        >
                          i.
                        </Typography>
                        copy, modify, duplicate, create derivative works from,
                        frame, mirror, republish, download, display, transmit,
                        or distribute any portion of the Platform, or
                      </Typography>
                      <Typography
                        component="span"
                        className={classes.nestedItem}
                      >
                        <Typography
                          component="span"
                          className={nestedItemClasses}
                        >
                          ii.
                        </Typography>
                        de-compile, reverse compile, disassemble, reverse
                        engineer or otherwise reduce to human-perceivable form
                        any part of the Platform,
                      </Typography>
                    </Typography>
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      c.
                    </Typography>
                    use the Platform or Services to provide services to third
                    parties or build a product or service which competes with
                    the Platform or Services,
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      d.
                    </Typography>
                    subvert any security restrictions imposed by Kadar,
                    including attempting to obtain, or assist others in
                    obtaining, access to the Platform, other than as permitted
                    under this agreement,
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      e.
                    </Typography>
                    use the Platform in a way that adversely affects the
                    Platform or other users use of the Platform, or
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      f.
                    </Typography>
                    make the Services or Platform available to any Third party
                    or assist Third parties in obtaining access.
                  </Typography>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h4" className={classes.subtitle}>
                4. Charges and payment
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.details}>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      4.1
                    </Typography>
                    During the period Platform is in beta stage, Kadar will
                    charge Customer&apos;s use of the Platform and the Services.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      4.2
                    </Typography>
                    Kadar may unilaterally impose monthly/yearly fee for use of
                    the Platform and Services, at any moment no matter the
                    production stage of the Platform. Kadar may limit the use
                    without charge of the Platform in any manner deemed
                    justifiable by Kadar.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      4.3
                    </Typography>
                    All payment and financial terms, policies, fees, etc. will
                    be separately published within the Documentation. Kadar may
                    unilaterally change the price list, fees, payment policy,
                    level and structure of fees, as well as any and all
                    financial terms of this Agreement.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      4.4
                    </Typography>
                    All unilateral changes to this Agreement, described within
                    this Article 4 &ndash; Charges and Payment &ndash; will be
                    deemed effective provided that the Customer is notified on
                    such changes and allowed 30 days adjustment period before
                    entering into force of notified changes to this Agreement.
                    During the 30 days adjustment period Customer has the right
                    to opt for termination of the Agreement (&quot;Exit
                    Option&quot;). Exit Option, if exercised during the
                    adjustment period, will terminate this Agreement effective
                    on the last day of the adjustment period, if not the new
                    terms apply.
                  </Typography>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h4" className={classes.subtitle}>
                5. Proprietary rights
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.details}>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      5.1
                    </Typography>
                    Customer grants to Kadar the right to incorporate and to
                    use, without any charge or without obligation to award any
                    right to the Platform, any suggestion, enhancement request,
                    recommendation or other feedback provided by Customer or its
                    Authorized user.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      5.2
                    </Typography>
                    Where applicable Customer/Authorized user grants to Kadar
                    non-exclusive, royalty-free, worldwide, transferable,
                    sublicensable, irrevocable, perpetual license to use or
                    incorporate into the Platform and Service any suggestions,
                    enhancement requests, recommendations or other feedback
                    provided by Customer or its Authorized Users relating to the
                    operation of the Platform and Service.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      5.3
                    </Typography>
                    Where applicable, If Customer/Authorized user has any rights
                    in respect to the Platform that cannot be assigned to Kadar
                    (including any moral rights, such as the right to be named
                    as author, the right to modify, the right to prevent
                    mutilation and the right to prevent commercial
                    exploitation), Customer/Authorized user hereby
                    unconditionally and irrevocably waives the enforcement of
                    such rights and waives and quitclaims to Kadar any and all
                    claims and causes of action of any kind against Kadar, its
                    Affiliates, and its licensees (through multiple tiers) with
                    respect to such rights.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      5.4
                    </Typography>
                    Kadar&apos;s (and its licensees&apos;) exercise of all
                    rights assigned and granted under this Agreement will not
                    require any third party consents or clearances or any
                    payment of fees, residuals or other amounts of any kind to
                    any third party (including any mechanical license fee, fees
                    payable to any union or guild, or any other consideration)
                    for the right to exploit any proprietary rights included in
                    the Platform.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      5.5
                    </Typography>
                    Nothing in this Agreement will be deemed to transfer any
                    intellectual property rights between the parties in relation
                    to the Customer data, except for the following: The Customer
                    is transferring to Kadar a non-exclusive right to use,
                    process, and store the Customer Data solely for the purpose
                    of securing the desired use of Platform and Services by the
                    Customer.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      5.6
                    </Typography>
                    Customer may use the Platform by viewing it in a browser or
                    printing out copies for Customer&apos;s use, but Kadar
                    reserves all other rights.
                  </Typography>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h4" className={classes.subtitle}>
                6. Confidentiality
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.details}>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      6.1
                    </Typography>
                    The parties shall each
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      a.
                    </Typography>
                    keep confidential,
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      b.
                    </Typography>
                    only use for the purposes of this agreement and
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      c.
                    </Typography>
                    <Typography component="span">
                      only disclose in confidence to the recipient&apos;s
                      employees, contractors on a need to know bases, the
                      confidential information of the other party received in
                      connection with this Agreement, unless the confidential
                      information
                      <Typography
                        component="span"
                        className={classes.nestedItem}
                      >
                        <Typography
                          component="span"
                          className={nestedItemClasses}
                        >
                          i.
                        </Typography>
                        has become public knowledge otherwise than through a
                        breach of this section,
                      </Typography>
                      <Typography
                        component="span"
                        className={classes.nestedItem}
                      >
                        <Typography
                          component="span"
                          className={nestedItemClasses}
                        >
                          ii.
                        </Typography>
                        can reasonably be shown to have been known by the
                        recipient before being received from the discloser,
                      </Typography>
                      <Typography
                        component="span"
                        className={classes.nestedItem}
                      >
                        <Typography
                          component="span"
                          className={nestedItemClasses}
                        >
                          iii.
                        </Typography>
                        was obtained by a third party that had not breached a
                        duty of confidentiality, or
                      </Typography>
                      <Typography
                        component="span"
                        className={classes.nestedItem}
                      >
                        <Typography
                          component="span"
                          className={nestedItemClasses}
                        >
                          iv.
                        </Typography>
                        is required to be disclosed by law or a party&apos;s
                        regulatory body.
                      </Typography>
                    </Typography>
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      6.2
                    </Typography>
                    Upon termination of this Agreement each party shall on
                    request promptly return or take reasonable steps to delete
                    the confidential information of the other party. This
                    section shall survive termination of this Agreement.
                  </Typography>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h4" className={classes.subtitle}>
                7. Indemnity
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.details}>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      7.1
                    </Typography>
                    Kadar shall defend and indemnify Customer and their
                    respective officers, directors and employees from and
                    against any third party or regulatory claims, actions,
                    proceedings, or fines, and for any related losses, damages,
                    expenses and costs, to the extent arising out of or in
                    connection with any breach by Kadar of the Data Processing
                    Agreement.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      7.2
                    </Typography>
                    Customer shall defend and indemnify Kadar and their
                    respective officers, directors and employees from and
                    against:
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      a.
                    </Typography>
                    any claims, actions, proceedings, losses, damages, expenses
                    and costs arising in connection with the use of the Platform
                    in breach of this Agreement by the Customer its employees,
                    its outsourced contractors, or by any person under the
                    auspices or control of the Customer; and
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      b.
                    </Typography>
                    any third party or regulatory claims, actions, proceedings,
                    or fines, and for any related losses, damages, expenses and
                    costs, to the extent arising out of or in connection with
                    any breach by Customer of the Data Processing Agreement.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      7.3
                    </Typography>
                    Section 7.1 and 7.2 are subject to:
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      a.
                    </Typography>
                    the indemnifying party being given prompt notice of any
                    matter for which indemnified party wishes to be indemnified;
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      b.
                    </Typography>
                    the indemnified party providing reasonable co-operation in
                    the defense and settlement of the relevant claim, at the
                    indemnifying party&apos;s expense; and
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      c.
                    </Typography>
                    the indemnifying party being given sole authority to defend
                    or settle the relevant claim, provided that no settlement
                    shall be made which prejudices the indemnified party&apos;s
                    rights or imposes any obligations on it without its prior
                    written approval (such approval not to be unreasonably
                    withheld or delayed).
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      7.4
                    </Typography>
                    In the defense or settlement of any third party claim, Kadar
                    may procure the right for the Customer to continue using the
                    Platform, replace or modify the Platform so that it becomes
                    non-infringing or, if such remedies are not reasonably
                    available, terminate this Agreement with two (2) Business
                    Days&apos; notice to the Customer without any additional
                    liability.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      7.5
                    </Typography>
                    Kadar will not be liable to the Customer to the extent that
                    an alleged infringement is based on:
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      a.
                    </Typography>
                    a modification of the Services or Documentation by anyone
                    other than Kadar or its subcontractors;
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      b.
                    </Typography>
                    Customer&apos;s use of the Services or Documentation in a
                    manner contrary to the instructions given by Kadar; or
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      c.
                    </Typography>
                    Customer&apos;s use of the Services or Documentation after
                    notice of the alleged infringement.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      7.6
                    </Typography>
                    Each party shall make reasonable efforts to mitigate any
                    loss, damage or liability it may suffer or incur as a result
                    of a breach by the other party of this agreement or in
                    respect of which it seeks indemnification from the other
                    party under this agreement.
                  </Typography>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h4" className={classes.subtitle}>
                8. Limitation of liability
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.details}>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      8.1
                    </Typography>
                    Except as expressly and specifically provided in this
                    Agreement and to the fullest extent permitted by applicable
                    law:
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      a.
                    </Typography>
                    Customer assumes sole responsibility for all information,
                    notifications, results, data or disclosures collectively
                    (collectively &quot;Results&quot;) obtained or delivered in
                    the course of the use of the Services and the Documentation,
                    and Kadar expressly disclaims any and all responsibility and
                    liability in respect of such Results;
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      b.
                    </Typography>
                    Kadar shall have no liability for any damage caused by
                    errors or omissions in any information, instructions or
                    scripts provided to Kadar by Customer in connection with the
                    Services, or any actions taken by Kadar at Customer&apos;s
                    direction;
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      c.
                    </Typography>
                    all terms implied by law are excluded from this agreement;
                    and
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      d.
                    </Typography>
                    the Platform and the Documentation are provided to the
                    Customer on an &quot;as is&quot; basis, and during the beta
                    version Kadar will not be liable for its well-functioning,
                    as well as for any security issue that may arise due to the
                    problems with the code errors.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      8.2
                    </Typography>
                    KADAR SHALL NOT BE LIABLE FOR ANY LOSS OF USE, INTERRUPTION
                    OF BUSINESS, LOST PROFITS, OR ANY INDIRECT, SPECIAL,
                    INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY KIND REGARDLESS
                    OF THE FORM OF ACTION WHETHER IN CONTRACT, TORT (INCLUDING
                    NEGLIGENCE), STRICT PRODUCT LIABILITY, OR OTHERWISE, EVEN IF
                    THE CUSTOMER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                    DAMAGES. IN THE EVENT THIS CLAUSE IS NOT EFFECTIVE,
                    KADAR&apos;S AGGREGATE LIABILITY UNDER THIS AGREEMENT IS
                    LIMITED TO THE AMOUNT OF 5.000,00 EUR.
                  </Typography>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h4" className={classes.subtitle}>
                9. Term and termination
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.details}>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      9.1
                    </Typography>
                    This Agreement shall commence on the date of the acceptance
                    of this Agreement by the Customer and continue, unless
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      a.
                    </Typography>
                    During the beta stage, either party notifies the other of
                    its intention to terminate, giving at least 30 days&apos;
                    notice; or
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      b.
                    </Typography>
                    Customer executed the Exit option described under section
                    4.4 of this Agreement; or
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      c.
                    </Typography>
                    After the beta stage, once the Subscription period has
                    elapsed, provided that the Customer has not opted out of the
                    Agreement at least 15 days prior to the end of the
                    Subscription period. In the event that the Customer has not
                    opted out of the Agreement in accordance with this paragraph
                    the Subscription period will be deemed automatically
                    prolonged for the subscribed period;
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      d.
                    </Typography>
                    otherwise terminates in accordance with this section.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      9.2
                    </Typography>
                    Either party may terminate this agreement with immediate
                    effect by giving written notice to the other party if the
                    other party:
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      a.
                    </Typography>
                    commits a material breach of any other term of this
                    agreement which breach is irremediable or (if remediable)
                    fails to remedy that breach within a period of 30 days after
                    being notified in writing to do so; or
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      b.
                    </Typography>
                    the other party is subject to any of the following events
                    (or any event analogous to any of the following in a
                    jurisdiction other than Republic of Serbia) in relation to
                    the relevant entity: becomes insolvent, enters into
                    liquidation, whether voluntary or compulsory (other than for
                    reasons of bona fide amalgamation or reconstruction), passes
                    a resolution for its winding-up, has a receiver or
                    administrator manager, trustee, liquidator or similar
                    officer appointed over the whole or any part of its assets,
                    makes any composition or arrangement with its creditors or
                    takes or suffers any similar action in consequence of its
                    debt, or becomes unable to pay its debts or suspends or
                    ceases, or threatens to suspend or cease, all or a
                    substantial part of its business.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      9.3
                    </Typography>
                    On termination of this agreement for any reason:
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      a.
                    </Typography>
                    Customer shall cease using the Platform;
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      b.
                    </Typography>
                    each party shall return and make no further use of any
                    equipment, property, Documentation and other items (and all
                    copies of them) belonging to the other party;
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      c.
                    </Typography>
                    without prejudice to Kadar&apos;s rights in respect of
                    Anonymized Data as set out in the Data Processing Agreement,
                    Kadar shall delete the Customer Data within 90 days of the
                    termination of this Agreement, provided that Customer Data
                    contained on backup copies of Kadar&apos;s databases shall
                    not be deleted for up to 180 days from the date of
                    termination, upon expiry of the then-current backup;
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      d.
                    </Typography>
                    subscription fee is not reimbursable, in whole or in part,
                    to the Customer, no matter the reason for termination; and
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      e.
                    </Typography>
                    any rights except for the rights to access and to use the
                    Platform, remedies, obligations or liabilities of the
                    parties that have accrued up to the date of termination
                    shall not be affected.
                  </Typography>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h4" className={classes.subtitle}>
                10. General
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.details}>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      10.1
                    </Typography>
                    Neither party shall have any liability for non or delayed
                    performance by events beyond its reasonable control,
                    provided that the other party is notified of such event and
                    its expected duration and such affected party uses
                    reasonable endeavors to mitigate its effect. If a party is
                    prevented due to any such events from substantially
                    performing its obligations under this agreement for a period
                    in excess of 30 consecutive days, then the other party may
                    terminate this agreement on 30 days&apos; notice.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      10.2
                    </Typography>
                    Except for part of this Agreement where the contrary is
                    expressly indicated, no variation of this Agreement shall be
                    effective unless it is expressly accepted by the Customer.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      10.3
                    </Typography>
                    No failure or delay by a party to exercise any right or
                    remedy shall constitute a waiver of that or any other right
                    or remedy. No single or partial exercise of such right or
                    remedy shall prevent or restrict the further exercise of
                    that or any other right or remedy.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      10.4
                    </Typography>
                    Except as expressly provided in this Agreement, the rights
                    and remedies provided under this Agreement are in addition
                    to any rights or remedies provided by law.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      10.5
                    </Typography>
                    If any provision of this Agreement is found to be invalid,
                    unenforceable or illegal, the other provisions shall remain
                    in force. If any provision would be valid, enforceable or
                    legal if some part of it were deleted, the provision shall
                    apply with whatever modification is necessary to give effect
                    to the commercial intention of the parties.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      10.6
                    </Typography>
                    This Agreement constitutes the entire agreement between the
                    parties and supersedes all previous agreements (written or
                    oral) relating to its subject matter.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      10.7
                    </Typography>
                    Each party acknowledges that it does not rely on, and shall
                    have no remedies in respect of, any statement not set out in
                    this Agreement. Each party agrees that it shall have no
                    claim for innocent or negligent misrepresentation or
                    negligent misstatement based on any statement in this
                    Agreement.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      10.8
                    </Typography>
                    This Agreement may not be assigned or transferred by either
                    party without the prior written approval of the other but
                    may be assigned or transferred by either party without the
                    others consent to
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      a.
                    </Typography>
                    a parent or subsidiary,
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      b.
                    </Typography>
                    an acquirer of assets, or
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      c.
                    </Typography>
                    a successor by merger.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      10.9
                    </Typography>
                    Nothing in this Agreement shall create a partnership between
                    the parties or authorize either party to act as agent on
                    behalf of the other.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      10.10
                    </Typography>
                    This Agreement does not confer any rights on any third
                    person or third party.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      10.11
                    </Typography>
                    Any notice under this Agreement shall be in writing and
                    shall be delivered online to registered e mail addressor
                    sent by pre-paid first class post or recorded delivery post
                    to the other party at its address set out in this Agreement,
                    or such other address as may have been notified by that
                    party for such purposes.. A notice delivered by hand shall
                    be deemed received when delivered (or if delivery is not in
                    Business Hours, at 9 am on the first Business Day following
                    delivery). A correctly addressed notice sent by pre-paid
                    first-class post or recorded delivery post shall be deemed
                    received at the time at which it would have been delivered
                    in the normal course of post. A notice sent by email shall
                    be deemed received at the time of transmission.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      10.12
                    </Typography>
                    This agreement and any dispute or claim arising out of or in
                    connection with it or its subject matter or formation shall
                    be governed by and construed in accordance with Serbian law
                    and subject to the exclusive jurisdiction of the Serbian
                    courts.
                  </Typography>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <Typography
            variant="h4"
            className={classNames(classes.subtitle, classes.subtitleAnnex)}
          >
            ANNEX A: Data processing agreement
          </Typography>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h4" className={classes.subtitle}>
                1. General
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.details}>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      1.1
                    </Typography>
                    Each party shall comply with its respective obligations
                    under the applicable law and regulation concerning data
                    protection and/or privacy in or relating to Republic of
                    Serbia and the European Union countries, including the EU
                    General Data Protection Regulation (2016/679)
                    (&quot;GDPR&quot;) and local implementing law or regulations
                    (&quot;Data Protection Legislation&quot;). The terms
                    &quot;process&quot;, &quot;controller&quot;,
                    &quot;processor&quot;, &quot;personal data&quot; and
                    &quot;data subject&quot; shall have the same meaning as in
                    the applicable Data Protection Legislation.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      1.2
                    </Typography>
                    Subject to section 1.4 of this Annex, Customer will be the
                    controller of the personal data in the Customer Data
                    (&quot;Personal Data&quot;) and Kadar will be the processor.
                    Kadar shall:
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      1.2.1
                    </Typography>
                    process the Personal Data only to the extent, and in such a
                    manner, as is necessary for performing this agreement and in
                    accordance with Customer&apos;s written instructions from
                    time to time and shall not process the Personal Data for any
                    other purpose. Where Kadar is required by law to process the
                    Personal Data, Kadar will promptly inform Customer of such
                    legal requirement prior to carrying out the processing,
                    unless it is prohibited from doing so by law;
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      1.2.2
                    </Typography>
                    limit access to Personal Data to those of its authorized
                    personnel who need access to it in order to meet
                    Kadar&apos;s obligations under this agreement, ensure that
                    all such personnel are bound by appropriate obligations of
                    confidentiality and ensure that all such Personal Data is
                    kept separate from any Personal Data of Kadar or of any
                    other client of Kadar;
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      1.2.3
                    </Typography>
                    implement and maintain appropriate technical and
                    organizational measures, to ensure an appropriate level of
                    security in respect such Personal Data, against accidental,
                    unauthorized or unlawful loss, destruction, alteration,
                    disclosure of or access to such Personal Data; such measures
                    shall be implemented with regard to: (a) encryption of
                    Personal Data; (b) back-up and disaster recovery
                    arrangements; (c) the ability to ensure ongoing
                    confidentiality, integrity, availability and resilience of
                    the IT infrastructure and environment; and (d) the regular
                    testing and evaluation of the effectiveness of such
                    measures. In particular, Kadar shall, in providing the
                    Services, follow and comply with the data privacy and
                    security measures in connection with the Personal Data;
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      1.2.4
                    </Typography>
                    only engage sub-processors in accordance with section 3 of
                    this Annex;
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      1.2.5
                    </Typography>
                    promptly notify Customer if it receives any complaint,
                    notice or communication which relates to the processing of
                    the Personal Data, or any request from a data subject
                    exercising any rights pursuant to the applicable Data
                    Protection Legislation and reasonably cooperate with and
                    assist Customer in relation to any such complaint, notice
                    communication, or request and shall not disclose any of the
                    Personal Data to any data subject or to a third party other
                    than at the request of Customer, or as provided for in this
                    Data Processing Agreement;
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      1.2.6
                    </Typography>
                    promptly notify Customer if it becomes aware of any
                    unauthorized or unlawful processing, loss of, damage to,
                    disclosure of, access to or destruction of the Personal Data
                    (“Data Breach”) and provide Customer with any co-operation,
                    information and assistance in respect of any Data Breach,
                    reasonably requested by Customer;
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      1.2.7
                    </Typography>
                    unless otherwise requested by the Customer, upon termination
                    of this agreement, Kadar will delete the Personal Data in
                    accordance with the terms of the this agreement; and
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      1.2.8
                    </Typography>
                    upon reasonable notice, make available to the Customer or
                    grant to the Customer and its auditors and agents, a right
                    of access to and to take copies of any information or
                    records kept by Kadar pursuant to this Data Processing
                    Agreement, solely to the extent necessary to demonstrate
                    Kadar&apos;s compliance with the Data Protection Legislation
                    and provided always that this section shall not require
                    Kadar to disclose any confidential information relating to
                    Authorized Users, individual responses to employee
                    engagement surveys or any other personally identifiable data
                    of Authorized Users save to the extent required by the Data
                    Protection Legislation. In relation to any sub-processors
                    that are engaged pursuant to this agreement, the Customer
                    acknowledges and agrees that it is sufficient, for the
                    purposes of satisfying the requirements of this section,
                    that Kadar has a right to audit those sub-processors on
                    behalf of the Customer, subject to reasonable restrictions.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      1.3
                    </Typography>
                    <Typography component="span">
                      The subject-matter and duration of the processing of the
                      Personal Data by Kadar, the nature and purpose of the
                      processing and the type of Personal Data and categories of
                      data subjects are all as set out in Kadar&apos;s data
                      privacy policy accessible at{' '}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://kadar.app/privacy-policy"
                      >
                        https://kadar.app/privacy-policy
                      </a>
                      . Kadar may change the policy after the date of this
                      agreement by giving not less than 30 days prior written
                      notice to Customer, provided that Customer may terminate
                      this agreement by giving notice within 14 days of having
                      received such notification if Kadar materially increases
                      the manner or scope in which it processes the Personal
                      Data.
                    </Typography>
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      1.4
                    </Typography>
                    Kadar may anonymize Customer Data in which case: (a) the
                    data (“Anonymized Data”) will not be treated as Personal
                    Data provided that it is not personal data for the purposes
                    of the GDPR, (b) Kadar may use the Anonymized Data for
                    statistical or benchmarking purposes to contribute towards
                    the development of Kadar&apos;s products and services during
                    or after the term of this agreement and will not be required
                    to delete the Anonymized Data on termination.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      1.5
                    </Typography>
                    For the purposes of section 1.2.1 of this Annex, Customer
                    shall not direct Kadar to process the Personal Data in a way
                    that is inconsistent with Kadar&apos;s standard services,
                    or, require Kadar to provide Customer Data other than in
                    aggregate form, unless otherwise agreed with Kadar. Customer
                    keeping its account active shall be deemed to be an
                    instruction to Kadar to continue to process the Personal
                    Data to allow use of the Platform.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      1.6
                    </Typography>
                    Customer shall not (and shall not permit its Authorized
                    Users to) configure the Platform dashboard or other
                    interface by reference to, or devise or undertake any
                    surveys or analysis using the Platform by reference to, any
                    special category of personal data (within the meaning of the
                    GDPR), namely: racial or ethnic origin; political opinions;
                    religious or philosophical beliefs; trade union membership;
                    genetic or biometric data; health data; sex life or sexual
                    orientation, without obtaining Kadar&apos;s prior written
                    agreement. If Kadar agrees, Customer shall ensure such
                    processing complies with an appropriate legal basis in
                    accordance with applicable Data Protection Legislation.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      1.7
                    </Typography>
                    Kadar shall be paid its reasonable costs by Customer to
                    support any Customer-requested actions under section 1.2 of
                    this Annex, including audits, subject access requests or
                    Customer&apos;s interactions with regulators (unless
                    required as a result of Kadar breaching this agreement).
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      1.8
                    </Typography>
                    Customer shall ensure that:
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      1.8.1
                    </Typography>
                    it is entitled to transfer any relevant Personal Data to
                    Kadar, such that Kadar may lawfully use, process and
                    transfer such Personal Data in accordance with this
                    agreement on Customer&apos;s behalf; and
                  </Typography>
                  <Typography className={classes.nestedItem}>
                    <Typography component="span" className={nestedItemClasses}>
                      1.8.2
                    </Typography>
                    all relevant Data Subjects have been informed of such use,
                    processing, and transfer as required by all applicable Data
                    Protection Legislation.
                  </Typography>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary>
              <Typography variant="h4" className={classes.subtitle}>
                2. Overseas data transfers
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.details}>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      2.1
                    </Typography>
                    <Typography component="span">
                      The Customer hereby acknowledges and agrees that Kadar
                      shall be entitled to transfer and/or process such Personal
                      Data outside the European Economic Area in connection with
                      the provision of the features of the Platform to the third
                      parties and at the physical server locations as approved
                      in accordance with section 3 of this Annex, in connection
                      with the functioning and support of such features in the
                      course of the provision of the Platform; and the Customer
                      hereby consents to such transfer and processing where such
                      features are requested to be included within the Platform.
                      Kadar and the Customer shall document any relevant
                      contractual requirements of the Customer as required under
                      applicable Data Protection Legislation to ensure compliant
                      transfer and processing of such Personal Data outside the
                      European Economic Area. In this respect the parties hereby
                      agree that, unless the relevant transfer is to a third
                      party based in a country confirmed as having adequate data
                      protection safeguards by the European Commission, or
                      unless the relevant transfer is to a US-based third party
                      which is validly certified under the Privacy Shield as set
                      out at{' '}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.privacyshield.gov/"
                      >
                        https://www.privacyshield.gov/
                      </a>{' '}
                      from time to time, they will adopt the standard
                      contractual clauses for data export as stipulated from
                      time to time by the European Commission, insofar as and
                      for so long as such contractual clauses remain legally
                      valid and enforceable.
                    </Typography>
                  </Typography>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion square={false}>
            <AccordionSummary>
              <Typography variant="h4" className={classes.subtitle}>
                3. Use of sub-processors
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.details}>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      3.1
                    </Typography>
                    Customer hereby consents to Kadar using the mandatory
                    sub-processors. If Customer uses the features identified on
                    such page as being provided by any of the optional
                    sub-processors, will be deemed to have consented to the use
                    of such sub-processors.
                  </Typography>
                </div>
                <div className={classes.itemWrapper}>
                  <Typography className={classes.item}>
                    <Typography component="span" className={classes.order}>
                      3.2
                    </Typography>
                    The Customer hereby grants to Kadar a general authorization
                    to appoint additional or replacement sub-processors under
                    this agreement, provided that Kadar shall ensure that such
                    sub-processor is bound by equivalent contractual terms as
                    those set out in this Data Processing Agreement.
                  </Typography>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Typography
            variant="h4"
            className={classNames(classes.subtitle, classes.subtitleAnnex)}
          >
            ANNEX B: Professional Services
          </Typography>
          <Typography className={classes.noData}>
            During the period Platform is in beta stage, Kadar will not provide
            additional Professional Services.
          </Typography>
          <Typography
            variant="h4"
            className={classNames(classes.subtitle, classes.subtitleAnnex)}
          >
            ANNEX C: Request for Professional Services Protocol
          </Typography>
          <Typography className={classes.noData}>
            To be executed upon completion of the beta stage of the Platform and
            execution of the Annex B - Professional Services.
          </Typography>
        </div>
      </Grid>
      <Grid item sm={gridColumn.one} lg={gridColumn.three} />
    </Grid>
  );
};

export default withStyles(styles)(TermsOfServicePage);
