import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, Fade, makeStyles } from '@material-ui/core';
import Tooltip from '../tooltip';
import { ReactComponent as TasksIcon } from '../../../assets/icons/clipboard.svg';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: spacing(1.5),
  },
  tasks: {
    marginBottom: spacing(-0.5),
  },
}));

const TasksIconTooltip = ({ className, text, numberOfTasks, isVisible }) => {
  const classes = useStyles();
  const hasTasks = numberOfTasks !== 0;

  return (
    <Fade
      className={classNames(classes.root, className)}
      in={isVisible}
      unmountOnExit
    >
      <div className={classes.root}>
        <Tooltip
          customIconClass={classNames({ [classes.icon]: hasTasks })}
          text={text}
          icon={TasksIcon}
        />
        {hasTasks && (
          <Typography variant="subtitle2" className={classes.tasks}>
            {numberOfTasks}
          </Typography>
        )}
      </div>
    </Fade>
  );
};

TasksIconTooltip.defaultProps = {
  className: undefined,
  numberOfTasks: 0,
  isVisible: false,
};

TasksIconTooltip.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  numberOfTasks: PropTypes.number,
  isVisible: PropTypes.bool,
};

export default TasksIconTooltip;
