import { getTimeRangeFromToday } from '../utility/dateUtils';
import { PEOPLE_PARAM_SCOPE_VALUES } from './people';

export const REPORT_TO_FILTER = [
  {
    id: null,
    name: 'Company View',
    translationKey: 'companyView',
  },
  {
    id: PEOPLE_PARAM_SCOPE_VALUES.DIRECT_REPORTS,
    name: 'My Direct Reporters',
    translationKey: 'direct',
  },
  {
    id: PEOPLE_PARAM_SCOPE_VALUES.ALL_REPORTS,
    name: 'All Reporters',
    translationKey: 'subUsers',
  },
];

export const ACTION_PLANS_FILTER = [
  {
    id: null,
    name: 'None',
    translationKey: 'allUsers',
  },
  {
    id: false,
    name: 'Without Action plans',
    translationKey: 'withoutActionPlan',
  },
];

export const PERIOD_FILTER = [
  {
    label: 'All time',
    value: null,
    key: 'all',
  },
  {
    label: '2 months range',
    value: 1,
    filter: getTimeRangeFromToday(2),
    key: 'twoMonths',
  },
  {
    label: '6 months range',
    value: 2,
    filter: getTimeRangeFromToday(),
    key: 'sixMonths',
  },
  {
    label: '12 months range',
    value: 3,
    filter: getTimeRangeFromToday(12),
    key: 'twelveMonths',
  },
];

export const DUE_DATE_FILTERS = [
  {
    id: 'UPCOMING',
    name: 'Upcoming',
    translationKey: 'upcoming',
  },
  {
    id: 'PAST',
    name: 'Past',
    translationKey: 'past',
  },
];

export const ACTION_PLAN_STATUS_FILTERS_TYPES = {
  DONE: 'DONE',
  IN_PROGRESS: 'IN_PROGRESS',
  PROPOSED: 'IS_PENDING_PROPOSAL',
};

export const ACTION_PLAN_STATUS_FILTERS = [
  {
    id: ACTION_PLAN_STATUS_FILTERS_TYPES.IN_PROGRESS,
    name: 'In progress',
    translationKey: 'inProgress',
  },
  {
    id: ACTION_PLAN_STATUS_FILTERS_TYPES.DONE,
    name: 'Done',
    translationKey: 'done',
  },
  {
    id: ACTION_PLAN_STATUS_FILTERS_TYPES.PROPOSED,
    name: 'Proposed',
    translationKey: 'proposed',
  },
];
