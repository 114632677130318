import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import PageContainer from '../../shared/pageContainer';
import OrganizationSettingsForm from '../../shared/organizationSettingsForm';
import { useTranslations } from '../../../utility/useTranslations';
import { updateOrganizationSettings } from '../../../store/modules/organizationSettings';
import { APP_PAGES } from '../../../constants/pages';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  main: {
    marginTop: spacing(8),
  },
  titleContainer: {
    padding: spacing(5, 0),
    marginBottom: spacing(8),
    borderBottom: `1px solid ${primary.bluish8}`,
  },
}));

const OrganizationSettingsPage = ({ organizationSettings, ...rest }) => {
  const classes = useStyles();
  const translations = useTranslations(APP_PAGES.ORGANIZATION_SETTINGS);
  const dispatch = useDispatch();

  const handleUpdateSettings = useCallback(
    data => updateOrganizationSettings(dispatch, data),
    [dispatch]
  );

  return (
    <PageContainer
      {...rest}
      translations={translations}
      organizationSettings={organizationSettings}
      shouldPassProps={false}
    >
      <div className={classes.main}>
        <OrganizationSettingsForm
          translations={translations}
          initialValues={organizationSettings}
          updateOrganizationSettings={handleUpdateSettings}
        />
      </div>
    </PageContainer>
  );
};

OrganizationSettingsPage.propTypes = {
  organizationSettings: PropTypes.object.isRequired,
};

export default memo(OrganizationSettingsPage);
