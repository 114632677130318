import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';

const styles = ({ palette: { primary } }) => ({
  link: {
    color: primary.blue1,
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 0,
    '&:hover p': {
      color: primary.blue1,
      fontFamily: 'ProximaNova-Bold',
    },
    '&:focus': {
      outline: 'none',
    },
  },
});

class CustomLink extends PureComponent {
  render() {
    const {
      classes,
      id,
      text,
      handleClick,
      className,
      disabled,
      customTextClass,
      customDisabledClass,
    } = this.props;
    return (
      <button
        id={id}
        type="button"
        className={classNames(classes.link, className)}
        disabled={disabled}
        onClick={!disabled ? handleClick : () => {}}
      >
        <Typography
          variant="body2"
          className={classNames(customTextClass, {
            [customDisabledClass]: disabled,
          })}
        >
          {text}
        </Typography>
      </button>
    );
  }
}

CustomLink.defaultProps = {
  className: '',
  id: undefined,
  disabled: false,
  customTextClass: '',
  customDisabledClass: '',
};

CustomLink.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  id: PropTypes.string,
  text: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  customTextClass: PropTypes.string,
  customDisabledClass: PropTypes.string,
};

export default withStyles(styles)(CustomLink);
