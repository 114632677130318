import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import Impression from './impression';
import { ReactComponent as LockerIcon } from '../../../assets/icons/locker.svg';
import { IMPRESSION_VALUES } from '../../../constants/oneOnOne';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  label: {
    marginBottom: spacing(4),
  },
  indicators: {
    display: 'flex',
  },
  list: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: spacing(4),
  },
  item: {
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${primary.bluish5}`,
    height: 80,
    width: 80,
    borderRadius: '50%',
    flexShrink: 0,
    transition: 'background-color .3s ease',
    userSelect: 'none',
  },
  valueLabel: {
    color: primary.bluish3,
  },
  positive: {
    marginLeft: 'auto',
  },
  disabled: {
    opacity: 0.5,
    cursor: 'default',
  },
  selected: {
    color: primary.white,
    backgroundColor: primary.blue1,
    border: 'none',
  },
  addAfter: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(4, 6),
    marginBottom: spacing(8),
    background: primary.bluish8,
    borderRadius: 8,
  },
  addAfterText: {
    color: primary.bluish3,
    lineHeight: '24px',
    marginLeft: spacing(4),
  },
}));

const Impressions = ({
  className,
  translations,
  label,
  value,
  isMedium,
  isSmall,
  isFutureMeeting,
  onSelectHandler,
}) => {
  const classes = useStyles();

  return (
    <div className={className}>
      {isFutureMeeting ? (
        <div className={classes.addAfter}>
          <LockerIcon />
          <Typography className={classes.addAfterText} variant="subtitle2">
            {translations.info}
          </Typography>
        </div>
      ) : null}

      {label && (
        <Typography
          variant="h4"
          className={classNames(classes.label, {
            [classes.disabled]: isFutureMeeting,
          })}
        >
          {label}
        </Typography>
      )}
      <div
        className={classNames(classes.indicators, {
          [classes.disabled]: isFutureMeeting,
        })}
      >
        <Typography variant="subtitle2">{translations.negative}</Typography>
        <Typography className={classes.positive} variant="subtitle2">
          {translations.positive}
        </Typography>
      </div>
      <div className={classes.list}>
        {IMPRESSION_VALUES.map(impression => (
          <Impression
            key={`impression_${impression.value}`}
            value={impression.value}
            isActive={impression.value === value}
            isDisabled={isFutureMeeting}
            isMedium={isMedium}
            isSmall={isSmall}
            onSelect={onSelectHandler}
          >
            {impression.value}
          </Impression>
        ))}
      </div>
    </div>
  );
};

Impressions.defaultProps = {
  className: undefined,
  label: '',
  value: null,
  isFutureMeeting: false,
  isMedium: false,
  isSmall: false,
};

Impressions.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  label: PropTypes.string,
  value: PropTypes.number,
  isMedium: PropTypes.bool,
  isSmall: PropTypes.bool,
  isFutureMeeting: PropTypes.bool,
  onSelectHandler: PropTypes.func.isRequired,
};

export default memo(Impressions);
