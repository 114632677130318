import { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TransitionGroup } from 'react-transition-group';
import { Typography, Fade, makeStyles } from '@material-ui/core';
import UserAvatar from '../userAvatar';
import ActionButton from '../actionButton';
import CustomCheckbox from '../customCheckbox';
import { getPersonShortName } from '../../../utility/helpers';
import { getPersonFullName } from '../../../utility/uiUtils';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  vertical: {
    display: 'block',
    width: '100%',
  },
  userWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: spacing(2),
    marginBottom: spacing(2),
    width: 'auto',
  },
  userWrapperVertical: {
    borderBottom: `1px solid ${primary.bluish7}`,
    paddingBottom: spacing(2),
    marginBottom: spacing(2),
    marginRight: 0,
    width: '100%',
    '&:last-of-type': {
      borderBottom: 'none',
      paddingBottom: 0,
      marginBottom: 0,
    },
  },
  userWrapperVerticalRemovable: {
    justifyContent: 'space-between',
  },
  userLabel: {
    fontFamily: 'ProximaNova-Bold',
    fontSize: 16,
    lineHeight: '20px',
  },
  userLabelSmall: {
    fontSize: 14,
  },
  removeButton: {
    width: 24,
    height: 24,
    marginLeft: spacing(1.5),
  },
  removeIcon: {
    height: 16,
    width: 16,
  },
  checkbox: {
    marginRight: spacing(2),
  },
  label: {
    color: primary.bluish6,
    padding: spacing(1, 2),
    marginLeft: spacing(2),
    border: `1px solid ${primary.bluish6}`,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
  },
}));

const UsersList = ({
  selectedUserWrapperClass,
  users,
  selectedUsers,
  isVerticalList,
  isSmallUserLabel,
  isSelectable,
  isUserRemovable,
  hasUserLabel,
  getUserLabel,
  isUserAccesible,
  onUserClickHandler,
  onRemoveUser,
}) => {
  const classes = useStyles();

  const handleRemove = useCallback(
    user => () => onRemoveUser(user),
    [onRemoveUser]
  );

  const handleUserClick = useCallback(
    user => () => onUserClickHandler(user),
    [onUserClickHandler]
  );

  return (
    <TransitionGroup
      className={classNames(classes.root, {
        [classes.vertical]: isVerticalList,
      })}
    >
      {users.map(user => {
        const isAccessible = isUserAccesible(user.id);
        const label = hasUserLabel ? getUserLabel(user) : null;

        return (
          <Fade key={`user_${user.id}`} in>
            <div
              className={classNames(
                classes.userWrapper,
                {
                  [classes.userWrapperVertical]: isVerticalList,
                  [classes.userWrapperVerticalRemovable]:
                    isVerticalList && isUserRemovable,
                },
                selectedUserWrapperClass
              )}
            >
              {isSelectable && (
                <CustomCheckbox
                  customRootClass={classes.checkbox}
                  isChecked={selectedUsers.includes(user.id)}
                  onChange={handleUserClick(user)}
                  isControlled
                />
              )}
              <UserAvatar
                // className={classNames({
                //   [classes.userRemovable]:
                //     isVerticalList &&
                //     (isUserRemovable || label || isSelectable),
                // })}
                labelClass={classNames(classes.userLabel, {
                  [classes.userLabelSmall]: isSmallUserLabel,
                })}
                user={user}
                clickableCaption={isSelectable || isAccessible}
                getCustomCaption={
                  isVerticalList ? getPersonFullName : getPersonShortName
                }
                onClickHandler={handleUserClick(user)}
                caption
                small
              />
              {(label || isUserRemovable) && (
                <div className={classes.actions}>
                  {label && (
                    <Typography className={classes.label} variant="caption">
                      {label}
                    </Typography>
                  )}
                  {isUserRemovable && (
                    <ActionButton
                      className={classes.removeButton}
                      iconClass={classes.removeIcon}
                      type={ACTION_BUTTON_TYPES.CLOSE}
                      onClickHandler={handleRemove(user)}
                    />
                  )}
                </div>
              )}
            </div>
          </Fade>
        );
      })}
    </TransitionGroup>
  );
};

UsersList.defaultProps = {
  selectedUserWrapperClass: undefined,
  isVerticalList: false,
  isUserRemovable: false,
  isSelectable: false,
  isSmallUserLabel: false,
  selectedUsers: [],
  hasUserLabel: false,
  getUserLabel: () => '',
  isUserAccesible: () => false,
  onUserClickHandler: () => {},
  onRemoveUser: () => {},
};

UsersList.propTypes = {
  selectedUserWrapperClass: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedUsers: PropTypes.arrayOf(PropTypes.number),
  isVerticalList: PropTypes.bool,
  isUserRemovable: PropTypes.bool,
  isSelectable: PropTypes.bool,
  isSmallUserLabel: PropTypes.bool,
  hasUserLabel: PropTypes.bool,
  getUserLabel: PropTypes.func,
  isUserAccesible: PropTypes.func,
  onUserClickHandler: PropTypes.func,
  onRemoveUser: PropTypes.func,
};

export default memo(UsersList);
