import { memo } from 'react';
import PropTypes from 'prop-types';
import { InputAdornment } from '@material-ui/core';

const Icon = ({ startIcon: StartIcon }) => (
  <InputAdornment position="start">
    <StartIcon />
  </InputAdornment>
);

Icon.propTypes = {
  startIcon: PropTypes.object.isRequired,
};

export default memo(Icon);
