import { API_COMPARE_REPORTS_USERS_BY_ATTRIBUTES } from './apiRoutes';

export const PRESETS = {
  SKILLS_MATRIX: 'SKILLS_MATRIX',
  COMPETENCE_MAP: 'COMPETENCE_MAP',
  GENERAL_MOOD: 'GENERAL_MOOD',
  ATTRIBUTE: 'ATTRIBUTE',
  COMPARE: 'COMPARE',
};

export const PRESETS_PREFIX = {
  [PRESETS.SKILLS_MATRIX]: API_COMPARE_REPORTS_USERS_BY_ATTRIBUTES,
};
