import { createAction, handleActions } from 'redux-actions';
import { isIncludedInPlacements } from 'utility/tagUtils';
import http from '../../utility/http';
import {
  replaceObjectInList,
  replaceObjectsInList,
  getUnionOfTwoArrays,
  isArrayEmpty,
} from '../../utility/helpers';
import { sortAlphabetically } from '../../utility/uiUtils';
import {
  API_TAG_CATEGORIES,
  API_TAGS,
  API_TAGS_MULTIPLE,
  api_page_tag_categories,
} from '../../constants/apiRoutes';
import {
  deleteOrganizationMenuSetup,
  editOrganizationMenuSetup,
} from './organizationSettings';

// ------------------------------------
// Constants
// ------------------------------------

const SET_CATEGORIES = 'SET_CATEGORIES';
const SET_PAGE_QUICK_FILTERS = 'SET_PAGE_QUICK_FILTERS';
const ADD_CATEGORY = 'ADD_CATEGORY';
const EDIT_CATEGORY = 'EDIT_CATEGORY';
const DELETE_CATEGORY = 'DELETE_CATEGORY';
const ADD_MULTIPLE_TAGS = 'ADD_MULTIPLE_TAGS';
const EDIT_TAG = 'EDIT_TAG';
const DELETE_TAG = 'DELETE_TAG';

const initialState = {
  categories: [],
  placements: [],
  tags: [],
  pageQuickFilters: [],
};

// ------------------------------------
// Actions
// ------------------------------------

export const setCategories = createAction(SET_CATEGORIES);
export const setPageQuickFilters = createAction(SET_PAGE_QUICK_FILTERS);
const addCategory = createAction(ADD_CATEGORY);
const editCategory = createAction(EDIT_CATEGORY);
const deleteCategory = createAction(DELETE_CATEGORY);
export const addMultipleTags = createAction(ADD_MULTIPLE_TAGS);
const editTagAction = createAction(EDIT_TAG);
const deleteTagAction = createAction(DELETE_TAG);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_CATEGORIES]: (state, { payload }) => {
    return { ...state, categories: payload };
  },
  [SET_PAGE_QUICK_FILTERS]: (state, { payload }) => {
    return { ...state, pageQuickFilters: payload };
  },
  [ADD_CATEGORY]: (state, { payload }) => ({
    ...state,
    categories: [
      ...state.categories,
      {
        ...payload,
        tags: [],
      },
    ],
  }),
  [EDIT_CATEGORY]: (state, { payload }) => {
    const { categories } = state;

    const index = categories.findIndex(category => category.id === payload.id);
    const updatedCategory = { ...categories[index], ...payload };
    const updatedTagsCategories = replaceObjectInList(
      categories,
      index,
      updatedCategory
    );
    return { ...state, categories: updatedTagsCategories };
  },
  [DELETE_CATEGORY]: (state, { payload }) => {
    const categories = state.categories.filter(
      category => category.id !== payload
    );
    return { ...state, categories };
  },
  [ADD_MULTIPLE_TAGS]: (state, { payload }) => {
    const { categories } = state;
    const { categoryId, tags } = payload;
    const categoryIndex = categories.findIndex(ctg => ctg.id === categoryId);
    const deletedTags = categories[categoryIndex].tags.filter(
      tag => !tags.delete.includes(tag.id)
    );

    const categoryTags = getUnionOfTwoArrays(
      [...tags.update, ...tags.create],
      deletedTags
    );

    const updatedCategory = {
      ...categories[categoryIndex],
      tags: sortAlphabetically(categoryTags),
    };

    return {
      ...state,
      categories: replaceObjectInList(
        categories,
        categoryIndex,
        updatedCategory
      ),
    };
  },
  [EDIT_TAG]: (state, { payload }) => {
    const { categories } = state;

    const isSameCategory =
      payload.currentCategoryId === payload.updatedTag.category;
    const updatedCategoryIndex = categories.findIndex(
      el => el.id === payload.updatedTag.category
    );

    if (isSameCategory) {
      const updatedCategory = {
        ...categories[updatedCategoryIndex],
        tags: sortAlphabetically(
          categories[updatedCategoryIndex].tags.map(tag => {
            if (tag.id === payload.updatedTag.id) {
              return payload.updatedTag;
            }
            return tag;
          })
        ),
      };

      return {
        ...state,
        categories: replaceObjectInList(
          categories,
          updatedCategoryIndex,
          updatedCategory
        ),
      };
    }
    const previousCategoryIndex = categories.findIndex(
      el => el.id === payload.currentCategoryId
    );
    const updatedPreviousCategory = {
      ...categories[previousCategoryIndex],
      tags: categories[previousCategoryIndex].tags.filter(
        tag => tag.id !== payload.updatedTag.id
      ),
    };
    const updatedCurrentCategory = {
      ...categories[updatedCategoryIndex],
      tags: sortAlphabetically([
        ...categories[updatedCategoryIndex].tags,
        { id: payload.updatedTag.id, name: payload.updatedTag.name },
      ]),
    };

    return {
      ...state,
      categories: replaceObjectsInList(
        categories,
        [updatedCategoryIndex, previousCategoryIndex],
        [updatedCurrentCategory, updatedPreviousCategory]
      ),
    };
  },
  [DELETE_TAG]: (state, { payload }) => {
    const { categories } = state;
    const categoryIndex = categories.findIndex(
      el => el.id === payload.categoryId
    );
    if (categoryIndex !== -1) {
      const updatedCategory = {
        ...categories[categoryIndex],
        tags: categories[categoryIndex].tags.filter(
          filterTag => filterTag.id !== payload.id
        ),
      };

      return {
        ...state,
        categories: replaceObjectInList(
          categories,
          categoryIndex,
          updatedCategory
        ),
      };
    }
  },
};

// ------------------------------------
// API calls
// ------------------------------------

export const getTagsCategories = dispatch => {
  return http.get(API_TAG_CATEGORIES).then(({ data }) => {
    dispatch(setCategories(data));
  });
};

export const getPageQuickFilters = (dispatch, pageId) => {
  return http.get(api_page_tag_categories(pageId)).then(({ data }) => {
    dispatch(
      setPageQuickFilters(data.filter(item => !isArrayEmpty(item.tags)))
    );
  });
};

export const addTagCategory = (dispatch, categoryData) => {
  return http.post(`${API_TAG_CATEGORIES}`, categoryData).then(({ data }) => {
    dispatch(addCategory(data));
    if (isIncludedInPlacements(categoryData)) {
      dispatch(editOrganizationMenuSetup(data));
    }
  });
};

export const editTagCategory = (dispatch, categoryData) => {
  return http
    .patch(`${API_TAG_CATEGORIES}${categoryData.category}/`, categoryData)
    .then(({ data }) => {
      dispatch(editCategory(data));
      if (isIncludedInPlacements(categoryData)) {
        dispatch(editOrganizationMenuSetup(data));
      } else {
        dispatch(deleteOrganizationMenuSetup({ id: categoryData.id }));
      }
    });
};

export const deleteTagCategory = (dispatch, categoryId) => {
  return http.delete(`${API_TAG_CATEGORIES}${categoryId}/`).then(() => {
    dispatch(deleteCategory(categoryId));
    dispatch(deleteOrganizationMenuSetup({ id: categoryId }));
  });
};

export const manageMultipleTags = (dispatch, tags, categoryId) => {
  return http.post(API_TAGS_MULTIPLE, tags).then(({ data }) =>
    dispatch(
      addMultipleTags({
        tags: { ...data, delete: tags.delete },
        categoryId,
      })
    )
  );
};

export const editTag = (dispatch, tagData) => {
  const { currentCategoryId, ...rest } = tagData;
  return http
    .post(API_TAGS_MULTIPLE, { update: [{ ...rest }] })
    .then(({ data }) => {
      dispatch(
        editTagAction({ updatedTag: { ...data.update[0] }, currentCategoryId })
      );
    });
};

export const deleteTag = (dispatch, id, categoryId) => {
  return http.delete(`${API_TAGS}${id}/`).then(() => {
    dispatch(deleteTagAction({ id, categoryId }));
  });
};

export default handleActions(reducers, initialState);

// ------------------------------------
// "plain" action functions
// ------------------------------------

export const clearTagsCategories = () => setCategories(initialState.categories);
export const clearPageQuickFilters = () =>
  setPageQuickFilters(initialState.pageQuickFilters);
