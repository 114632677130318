import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Fade, makeStyles } from '@material-ui/core';
import ReportTextReplies from '../reportTextReplies';
import ReportAnswerGrid from '../reportAnswerGrid';
import ReportFeedback from '../reportFeedback';
import { isObjectEmpty } from '../../../utility/helpers';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  resultsWrapper: {
    borderBottom: `1px solid ${primary.bluish7}`,
    marginTop: spacing(8),
    paddingBottom: spacing(6),
  },
  answers: {
    marginTop: spacing(3),
  },
  answerGrid: {
    marginTop: 0,
  },
}));

const DeepDiveReport = ({
  translations,
  results,
  attribute,
  feedback,
  openEndedQuestionId,
  isOpenEndedAttribute,
  canGoToUserProfile,
  onGoToUserProfile,
  onOpenEndedQuestionPreview,
}) => {
  const classes = useStyles();

  return (
    <Fade in={!isObjectEmpty(results)} unmountOnExit>
      {!isObjectEmpty(results) ? (
        <div>
          {isOpenEndedAttribute ? (
            <ReportTextReplies
              className={classNames(classes.resultsWrapper, classes.answers)}
              anonymousUser={translations.anonymousUser}
              notApplicableLabel={translations.neutral.notApplicable}
              questions={attribute?.questions}
              results={results}
              selectedQuestionId={openEndedQuestionId}
              onExpand={onOpenEndedQuestionPreview}
            />
          ) : (
            <div
              className={classNames(classes.resultsWrapper, classes.answers)}
            >
              <ReportAnswerGrid
                className={classes.answerGrid}
                translations={translations}
                hasNeutralAnswer={attribute?.hasNeutralAnswer}
                totalAnswers={attribute?.totalAnswers}
                rows={attribute?.questions}
                attributeColor={attribute?.color}
                results={results}
                isPercentageViewInitial
                hasPercentageView
              />
            </div>
          )}
          {attribute.hasAdditionalFeedback && (
            <ReportFeedback
              className={classes.resultsWrapper}
              translations={translations.feedback}
              feedback={feedback}
              isUserClickable={canGoToUserProfile}
              onGoToPersonProfile={onGoToUserProfile}
            />
          )}
        </div>
      ) : (
        <div />
      )}
    </Fade>
  );
};

DeepDiveReport.defaultProps = {
  attribute: null,
  feedback: [],
  openEndedQuestionId: null,
  isOpenEndedAttribute: false,
};

DeepDiveReport.propTypes = {
  translations: PropTypes.object.isRequired,
  results: PropTypes.object.isRequired,
  attribute: PropTypes.object,
  feedback: PropTypes.arrayOf(PropTypes.object),
  isOpenEndedAttribute: PropTypes.bool,
  openEndedQuestionId: PropTypes.number,
  canGoToUserProfile: PropTypes.func.isRequired,
  onOpenEndedQuestionPreview: PropTypes.func.isRequired,
  onGoToUserProfile: PropTypes.func.isRequired,
};

export default memo(DeepDiveReport);
