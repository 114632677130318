import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, withStyles } from '@material-ui/core';
import UserAvatar from '../userAvatar';
import Tooltip from '../tooltip';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-icon-new.svg';
import { ReactComponent as PersonalAttributeIcon } from '../../../assets/icons/private-attribute-icon.svg';
import { isEmpty } from '../../../utility/validation';
import { getPersonalAttributeTooltipText } from '../../../utility/uiUtils';
import CustomScrollBar from '../customScrollBar';

const styles = ({ palette: { primary }, spacing }) => ({
  root: {
    display: 'grid',
    flexDirection: 'column',
    flexBasis: 'fit-content',
    zIndex: 9999,
  },
  header: {
    display: 'flex',
    marginBottom: 6,
  },
  employeeHeader: {
    boxSizing: 'border-box',
    paddingLeft: spacing(1.5),
    width: 204,
    color: primary.bluish4,
  },
  employee: {
    boxSizing: 'border-box',
    paddingLeft: spacing(1.5),
    width: 204,
  },
  userFullName: {
    fontFamily: 'ProximaNova-Bold',
    lineHeight: '21px',
  },
  userPosition: {
    position: 'static',
  },
  attribute: {
    display: 'flex',
    justifyContent: 'center',
    width: 52,
    textAlign: 'center',
    color: primary.bluish4,
    marginLeft: spacing(1.5),
  },
  average: {
    marginLeft: spacing(1.5),
    color: primary.bluish4,
    width: 52,
    textAlign: 'center',
  },
  row: {
    display: 'flex',
    backgroundColor: primary.bluish9,
    borderRadius: 8,
    padding: spacing(1.5, 0),
  },
  square: {
    display: 'flex',
    flexDirection: 'column',
    width: 52,
    height: 52,
    alignItems: 'center',
    backgroundColor: primary.bluish8,
    borderRadius: 8,
    marginLeft: spacing(1.5),
  },
  averageSquare: {
    backgroundColor: primary.bluish3,
    marginLeft: spacing(1.5),
  },
  squareValue: {
    marginTop: spacing(4),
  },
  averageValue: {
    color: primary.bluish9,
  },
  notAvailable: {
    color: primary.bluish5,
  },
  removeEmployee: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 52,
    marginLeft: spacing(1.5),
  },
  closeIcon: {
    width: 16,
    height: 16,
    cursor: 'pointer',
  },
  barContainer: {
    marginTop: spacing(1),
    width: 40,
    height: 2,
    borderRadius: 30,
    backgroundColor: primary.bluish5,
  },
  bar: {
    height: 2,
    backgroundColor: primary.bluish1,
  },
  personalAttributeIcon: {
    marginLeft: spacing(1),
  },
  pointer: { cursor: 'pointer' },
  scrollX: {
    backgroundColor: primary.bluish9,
    left: 0,
    bottom: -16,
    width: '100%',
    height: 8,
  },
});

const AVERAGE_KEY = 'avg';

const MAX_SCORE = 10;
const USER_AND_ACTION_WIDTH = 320;
const SQUARE_SIZE = 58;
const AVERAGE_COLUMN_MARGIN = 6;

class CompareReportWidget extends PureComponent {
  calculateBarWidth = val => {
    return (val * 100) / MAX_SCORE;
  };

  calculateWidgetWidth = (attrCount, hideDeleteAction) => {
    const isSingleAttrSelected = attrCount === 1;
    const averageColumnWidth = SQUARE_SIZE + AVERAGE_COLUMN_MARGIN;

    const width =
      USER_AND_ACTION_WIDTH +
      attrCount * SQUARE_SIZE +
      (isSingleAttrSelected ? -averageColumnWidth : 0);

    // case when delete icons are hidden for example people/direct-report
    if (hideDeleteAction) {
      return width - SQUARE_SIZE;
    }

    return width;
  };

  render() {
    const {
      classes,
      teamAttributes,
      teamData,
      translations,
      onEmployeeClick,
      onRemoveEmployee,
      onRemoveAllEmployees,
      hideDeleteAction,
    } = this.props;

    const attributeKeys = teamAttributes.map(attr => attr.id);
    const privateAttributeIds = teamAttributes
      .filter(attr => attr.is_private)
      .map(attr => attr.id);

    const valueKeys =
      teamAttributes?.length > 1
        ? [...attributeKeys, AVERAGE_KEY]
        : attributeKeys;

    return (
      <CustomScrollBar
        customScrollBarXClass={classes.scrollX}
        passContentHeight
      >
        <div
          className={classes.root}
          style={{
            width: `${this.calculateWidgetWidth(
              attributeKeys?.length,
              hideDeleteAction
            )}px`,
          }}
        >
          <div className={classes.header}>
            <Typography variant="caption" className={classes.employeeHeader}>
              {translations.employee}
            </Typography>
            {teamAttributes?.map(attr => (
              <Typography
                variant="caption"
                component="div"
                className={classes.attribute}
                key={`attribute_${attr.code}_${attr.id}`}
              >
                {attr.code}
                {privateAttributeIds.includes(attr.id) ? (
                  <Tooltip
                    customIconClass={classes.personalAttributeIcon}
                    text={getPersonalAttributeTooltipText(
                      translations.personalAttributeTooltip,
                      attr.created_by
                    )}
                    icon={PersonalAttributeIcon}
                  />
                ) : null}
              </Typography>
            ))}
            {teamAttributes?.length > 1 ? (
              <Typography variant="caption" className={classes.average}>
                {translations.average}
              </Typography>
            ) : null}

            {!hideDeleteAction ? (
              <div className={classes.removeEmployee}>
                <CloseIcon
                  className={classes.closeIcon}
                  onClick={() => onRemoveAllEmployees()}
                />
              </div>
            ) : null}
          </div>
          <div>
            {teamData.map(row => {
              return (
                <div key={row.id} className={classes.row}>
                  <div className={classes.employee}>
                    <UserAvatar
                      labelClass={classes.userFullName}
                      customCaptionContainerClass={classes.userPosition}
                      user={row}
                      captionDescription={
                        row.position
                          ? row.position?.name
                          : translations.noJobTitle
                      }
                      onClickHandler={() => onEmployeeClick(row.id)}
                      clickableCaption
                      caption
                      withDescription
                      medium
                    />
                  </div>
                  {valueKeys.map(key => {
                    return (
                      <div
                        key={`${row.id}_${key}`}
                        className={classNames(classes.square, {
                          [classes.averageSquare]: key === AVERAGE_KEY,
                        })}
                      >
                        <Typography
                          variant="h5"
                          component="p"
                          className={classNames(classes.squareValue, {
                            [classes.averageValue]: key === AVERAGE_KEY,
                            [classes.notAvailable]: !isEmpty(
                              row?.attr_scores[key.toString()]
                            ),
                          })}
                        >
                          {row?.attr_scores[key.toString()]
                            ? row.attr_scores[key.toString()]
                            : translations.notAvailable}
                        </Typography>
                        {key !== AVERAGE_KEY &&
                        row?.attr_scores[key.toString()] ? (
                          <div className={classes.barContainer}>
                            <div
                              className={classes.bar}
                              style={{
                                width: `${this.calculateBarWidth(
                                  row?.attr_scores[key.toString()]
                                )}%`,
                              }}
                            />
                          </div>
                        ) : null}
                      </div>
                    );
                  })}
                  {!hideDeleteAction ? (
                    <div className={classes.removeEmployee}>
                      <CloseIcon
                        className={classes.closeIcon}
                        onClick={() => onRemoveEmployee(row.id)}
                      />
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
      </CustomScrollBar>
    );
  }
}

CompareReportWidget.defaultProps = {
  teamData: [],
  teamAttributes: [],
  onRemoveEmployee: () => {},
  onRemoveAllEmployees: () => {},
  hideDeleteAction: false,
};

CompareReportWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  onEmployeeClick: PropTypes.func.isRequired,
  onRemoveEmployee: PropTypes.func,
  onRemoveAllEmployees: PropTypes.func,
  teamAttributes: PropTypes.arrayOf(PropTypes.object),
  teamData: PropTypes.arrayOf(PropTypes.object),
  hideDeleteAction: PropTypes.bool,
};

export default withStyles(styles)(CompareReportWidget);
