import { cloneElement, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TransitionGroup } from 'react-transition-group';
import { Typography, Fade, makeStyles } from '@material-ui/core';
import DraggableScroll from '../draggableScroll';
import NotificationCard from '../notificationCard';
import SectionLoader from '../sectionLoader';
import { primaryPalette } from '../../../styles/theme';
import {
  isArrayEmpty,
  asyncDebounce,
  getUnionOfTwoArrays,
} from '../../../utility/helpers';
import { useCustomEffect, usePreviousValue } from '../../../utility/hooks';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    backgroundColor: primary.bluish8,
    borderRadius: 8,
    height: ({ isVertical }) => (isVertical ? '100%' : '280px'),
    borderLeft: ({ color }) => `3px solid ${color}`,
    position: 'relative',
  },
  header: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: ({ hasActions }) =>
      hasActions ? spacing(4.5, 6, 6.5, 6) : spacing(6, 6, 8, 6),
    height: 76,
  },
  content: {
    height: 'calc(100% - 76px)',
  },
  scrollWrapper: {
    display: 'flex',
    height: ({ isVertical }) => (isVertical ? '100%' : 'auto'),
  },
  itemsWrapper: {
    display: 'flex',
    flexDirection: ({ isVertical }) => (isVertical ? 'column' : 'row'),
    width: ({ isVertical }) => (isVertical ? '100%' : 'auto'),
  },
  itemWrapper: {
    boxSizing: 'border-box',
    padding: spacing(0.5, 0),
    marginRight: spacing(4),
    '&:first-of-type': {
      marginLeft: spacing(6),
    },
    '&:last-of-type': {
      marginRight: spacing(6),
    },
  },
  itemWrapperVertical: {
    boxSizing: 'border-box',
    padding: spacing(0, 6),
    marginBottom: spacing(4),
    '&:first-of-type': {
      paddingTop: spacing(0.5),
    },
    '&:last-of-type': {
      marginBottom: 0,
      paddingBottom: spacing(6),
    },
  },
  item: {
    boxShadow: `0px 2px 8px 0px rgba(0, 0, 0, 0.07)`,
  },
  noResults: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    padding: spacing(7.5, 6),
  },
  noResultsIcon: {
    height: 22,
    width: 'auto',
  },
}));

const DashboardSection = ({
  translations,
  isLoading,
  color,
  items,
  itemComponent: Component,
  itemPropKey,
  currentPage,
  actionButtons,
  sectionIcon: SectionIcon,
  isVertical,
  isNotificationDisabled,
  hasLoadMore,
  onLoadMoreItems,
  ...rest
}) => {
  const scrollRef = useRef(null);
  const hasItems = !isArrayEmpty(items);
  const hasActions = !isArrayEmpty(actionButtons);
  const classes = useStyles({ color, hasActions, isVertical });
  const previousItems = usePreviousValue(items);

  useCustomEffect(
    () => {
      if (
        currentPage === 1 &&
        scrollRef?.current &&
        getUnionOfTwoArrays(previousItems, items).length !== items.length
      ) {
        scrollRef?.current.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
      }
    },
    [currentPage, previousItems],
    false
  );

  const handleLoadMore = useCallback(() => {
    if (scrollRef?.current) {
      const {
        clientWidth,
        scrollWidth,
        scrollLeft,
        scrollTop,
        scrollHeight,
        clientHeight,
      } = scrollRef.current;
      const isEndOfContainer = isVertical
        ? scrollHeight - scrollTop - clientHeight <= 32
        : scrollWidth - scrollLeft - clientWidth <= 32;

      if (hasLoadMore && isEndOfContainer) {
        return onLoadMoreItems();
      }
    }
  }, [isVertical, hasLoadMore, onLoadMoreItems]);

  return (
    <Fade in unmountOnExit>
      <div className={classes.root}>
        <div className={classes.header}>
          <Typography variant="h5">{translations.title}</Typography>
          {hasActions &&
            actionButtons.map(aButton => {
              const { id, button: Button, text, ...actionProps } = aButton;
              return text ? (
                <Button key={`action_button_${id}`} {...actionProps}>
                  {text}
                </Button>
              ) : (
                <Button key={`action_button_${id}`} {...actionProps} />
              );
            })}
        </div>
        <div className={classes.content}>
          <SectionLoader isLoading={isLoading} />
          <DraggableScroll
            ref={el => {
              scrollRef.current = el;
            }}
            className={classes.scrollWrapper}
            onEndScroll={asyncDebounce(handleLoadMore)}
          >
            <TransitionGroup className={classes.itemsWrapper}>
              {items.map(item => (
                <Fade
                  key={`item_${item.id}`}
                  className={classNames({
                    [classes.itemWrapper]: !isVertical,
                    [classes.itemWrapperVertical]: isVertical,
                  })}
                  in
                  unmountOnExit
                >
                  <div>
                    {cloneElement(
                      <Component
                        className={classes.item}
                        translations={
                          translations?.[itemPropKey] || translations
                        }
                        {...rest}
                      />,
                      { [itemPropKey]: item }
                    )}
                  </div>
                </Fade>
              ))}
            </TransitionGroup>
          </DraggableScroll>
          <NotificationCard
            className={classes.noResults}
            iconClass={classes.noResultsIcon}
            title={translations.noData.title}
            content={translations.noData.message}
            customIcon={SectionIcon}
            shouldFade={!isNotificationDisabled && !isLoading && !hasItems}
            hasIcon
          />
        </div>
      </div>
    </Fade>
  );
};

DashboardSection.defaultProps = {
  actionButtons: [],
  color: primaryPalette.bluish1,
  isVertical: false,
  items: [],
  isNotificationDisabled: false,
  currentPage: 1,
  hasLoadMore: false,
  onLoadMoreItems: () => {},
  itemComponent: () => {},
};

DashboardSection.propTypes = {
  translations: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
  sectionIcon: PropTypes.object.isRequired,
  itemComponent: PropTypes.func,
  itemPropKey: PropTypes.string.isRequired,
  isNotificationDisabled: PropTypes.bool,
  actionButtons: PropTypes.arrayOf(PropTypes.object),
  currentPage: PropTypes.number,
  color: PropTypes.string,
  isVertical: PropTypes.bool,
  hasLoadMore: PropTypes.bool,
  onLoadMoreItems: PropTypes.func,
};

export default DashboardSection;
