import {
  forbiddenCharacters,
  validateLength,
} from '../../../utility/validation';

export const DELAY_VALIDATION = 300;

export const INITIAL_COMMENT_STATE = {
  comment: '',
  error: '',
};

export const COMMENT_FIELD = {
  name: 'comment',
  type: 'text',
  translationKey: 'comment',
  validators: [
    {
      type: 'forbiddenCharacters',
      validator: forbiddenCharacters,
    },
    { type: 'maxLength', validator: value => validateLength(value, 0, 1000) },
  ],
};
