import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { List, ListItem, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(
  ({ breakpoints, palette: { primary }, spacing }) => ({
    legend: {
      padding: 0,
    },
    legendItem: {
      alignItems: 'center',
      marginBottom: spacing(2),
      padding: 0,
      paddingLeft: spacing(7),
      width: 180,

      '&:last-of-type': {
        marginBottom: 0,
      },

      [breakpoints.up('xl')]: {
        marginBottom: spacing(6),
        width: 180,
        padding: 0,
      },
    },
    legendIcon: {
      width: 10,
      height: 10,
      borderRadius: '50%',
      flexShrink: 0,
      marginRight: spacing(1),

      [breakpoints.up('xl')]: {
        marginRight: spacing(2),
      },
    },
    legendName: {
      color: primary.blue1,
      fontFamily: 'Helvetica',
      fontSize: 12,
      lineHeight: '18px',
      fontWeight: 700,
      minWidth: 85,
      marginRight: 8,

      [breakpoints.up('xl')]: {
        fontSize: 14,
        marginRight: 24,
      },
    },
    legendValue: {
      color: primary.bluish1,
      fontFamily: 'Helvetica',
      fontSize: 14,
      lineHeight: '18px',
      fontWeight: 700,

      [breakpoints.up('xl')]: {
        fontSize: 18,
      },
    },
    noValue: {
      opacity: 0.5,
    },
  })
);

const CustomLegend = ({ payload, hasNoData }) => {
  const classes = useStyles();
  const legendItems = hasNoData
    ? payload.filter(entry => entry.payload.value === 0)
    : payload;

  return (
    <List className={classes.legend}>
      {legendItems.map((entry, index) => (
        <ListItem
          className={classNames(classes.legendItem, {
            [classes.noValue]: entry.payload.value === 0,
          })}
          key={`item-${index}`}
        >
          <span
            className={classes.legendIcon}
            style={{ backgroundColor: entry.color }}
          />
          <span className={classes.legendName}>{entry.value}</span>
          <span className={classes.legendValue}>{entry.payload.value}</span>
        </ListItem>
      ))}
    </List>
  );
};

CustomLegend.propTypes = {
  hasNoData: PropTypes.bool.isRequired,
  payload: PropTypes.array.isRequired,
};

export default memo(CustomLegend);
