import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import CustomButton from '../../shared/customButton';
import EnrollCourseDialog from '../../shared/enrollCourseDialog';
import AlertDialog from '../../shared/alertDialog';
import CourseCard from '../../shared/courseCard';
import Search from '../../shared/search';
import Filters from '../../shared/filters';
import NotificationCard from '../../shared/notificationCard';
import {
  showSuccessMessage,
  manageQuery,
  parameterizeQuery,
  parseDuplicateParameters,
  parseQueryParams,
} from '../../../utility/uiUtils';
import {
  isObjectEmpty,
  canManagePerson,
  isArrayEmpty,
  trimString,
  isArray,
  isUserSuspended,
} from '../../../utility/helpers';
import { hasSelectedFilters } from '../../shared/filters/config';
import {
  getCourseLevels,
  prepareCourses,
  prepareEnrollmentStatuses,
  prepareCoursesToEnroll,
} from '../../../utility/courseUtils';
import http from '../../../utility/http';
import { sticky } from '../../../constants/helperCssRules';
import { PARAMS, PAGE_WHITELISTED_PARAMS } from '../../../constants/pages';
import {
  api_course_users_disenroll,
  api_course_users_enroll,
} from '../../../constants/apiRoutes';
import { getPageFilters, getCourseActions } from './config';

const styles = ({ palette: { primary }, spacing }) => ({
  header: {
    ...sticky(primary.white, 169),
  },
  pageDescription: {
    marginBottom: spacing(4),
  },
  filtersAndActionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: spacing(4),
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  search: {
    width: 200,
  },
  enrollButton: {
    marginLeft: spacing(2),
    width: 'max-content',
  },
  userCourses: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    gridGap: spacing(8),
  },
});

const { SEARCH, COURSE_LEVEL, COURSE_ATTRIBUTE, STATUS, COURSE_TAGS } = PARAMS;

class PeopleCoursesPage extends PureComponent {
  state = {
    isInitialLoad: true,
    isFiltersLoading: false,
    courseId: null,
    disenrollDialogOpened: false,
    isEnrollCourseDialogOpened: false,
    filters: {
      [COURSE_LEVEL]: [],
      [COURSE_ATTRIBUTE]: [],
      [STATUS]: [],
      [COURSE_TAGS]: [],
    },
    [SEARCH]: '',
  };

  componentDidMount() {
    this.getInitialData();
  }

  componentWillUnmount() {
    const {
      clearUserCourses,
      clearCourseEnrolledUsers,
      clearAttributesWithQuestions,
      clearTagsCategories,
      clearPageQuickFilters,
    } = this.props;

    clearUserCourses();
    clearCourseEnrolledUsers();
    clearAttributesWithQuestions();
    clearTagsCategories();
    clearPageQuickFilters();
  }

  getInitialData = () => {
    const {
      location,
      getTagsCategories,
      getPageQuickFilters,
      getAttributesWithQuestions,
    } = this.props;
    const { state, search } = location;
    const params = parseQueryParams(
      search,
      PAGE_WHITELISTED_PARAMS.PEOPLE_LEARNING
    );

    if (!isObjectEmpty(params)) {
      return this.setState(
        {
          [SEARCH]: params[SEARCH] || '',
          filters: {
            [COURSE_LEVEL]: params[COURSE_LEVEL] ? [params[COURSE_LEVEL]] : [],
            [COURSE_ATTRIBUTE]: params[COURSE_ATTRIBUTE]
              ? [params[COURSE_ATTRIBUTE]]
              : [],
            [STATUS]: params[STATUS] ? [params[STATUS]] : [],
            [COURSE_TAGS]: params[COURSE_TAGS]
              ? [
                  ...(isArray(params[COURSE_TAGS])
                    ? params[COURSE_TAGS]
                    : [params[COURSE_TAGS]]),
                ]
              : [],
          },
        },
        () => {
          return Promise.all([
            this.handleGetCourses(),
            getTagsCategories(),
            getPageQuickFilters(),
            getAttributesWithQuestions({ is_private: false }),
          ]).then(() => {
            this.setState({ isInitialLoad: false });
          });
        }
      );
    }

    return Promise.all([
      ...(state?.shouldOpenDialog
        ? [this.handleEnrollCourseDialog()]
        : [this.handleGetCourses()]),
      getTagsCategories(),
      getPageQuickFilters(),
      getAttributesWithQuestions({ is_private: false }),
    ]).then(() => {
      this.setState({ isInitialLoad: false });
    });
  };

  handleGetCourses = () => {
    const { history, location, user, getUserCourses } = this.props;
    const { filters, search } = this.state;
    const params = {
      ...filters,
      ...(search ? { search } : {}),
    };
    const query = parseDuplicateParameters(params);

    if (location.search !== `?${query}`) {
      history.replace(
        `/people/${user.id}/learning${query ? `/?${query}` : ''}`
      );
    }

    return getUserCourses(user.id, params);
  };

  handleApplyFilters = filters =>
    this.setState({ filters, isFiltersLoading: true }, () =>
      this.handleGetCourses().then(() =>
        this.setState({ isFiltersLoading: false })
      )
    );

  onEnrollCoursesSearch = value => {
    const { getCourses } = this.props;
    const text = trimString(value);

    const query = manageQuery({
      key: 'search',
      value: text,
      shouldDelete: !text,
      query: '',
    });

    return getCourses(parameterizeQuery(query));
  };

  onEnrolledCoursesSearch = value => {
    const searchTerm = trimString(value);

    this.setState({ [SEARCH]: searchTerm, isFiltersLoading: true }, () =>
      this.handleGetCourses().then(() =>
        this.setState({ isFiltersLoading: false })
      )
    );
  };

  onViewDetails = courseId => () => {
    const { history } = this.props;

    history.push(`/learning/${courseId}`);
  };

  toggleDisenrollDialog = courseId =>
    this.setState(prevState => ({
      disenrollDialogOpened: !prevState.disenrollDialogOpened,
      courseId: courseId || null,
    }));

  onDisenrollUser = () => {
    const { courseId } = this.state;
    const { removeCourseFromUserList, user, translations } = this.props;
    if (courseId && user && user.id) {
      http
        .post(api_course_users_disenroll(courseId), {
          users: [user.id],
        })
        .then(() => {
          removeCourseFromUserList({ courseId });
          showSuccessMessage(translations.disenrollDialog.disenrollSuccess);
        })
        .finally(this.toggleDisenrollDialog);
    }
  };

  toggleEnrollDialog = () =>
    this.setState(prevState => ({
      isEnrollCourseDialogOpened: !prevState.isEnrollCourseDialogOpened,
    }));

  handleEnrollCourseDialog = () => {
    const { getCourses } = this.props;

    return getCourses().then(this.toggleEnrollDialog);
  };

  onEnrollCourses = course => {
    const { translations, user, getUserCourses } = this.props;
    return http
      .post(api_course_users_enroll(course.id), { users: [user.id] })
      .then(() => {
        showSuccessMessage(translations.successMessages.enrolled);
        getUserCourses(user.id);
      })
      .finally(this.toggleEnrollDialog);
  };

  onChangeEnrollmentStatus = courseId => statusId => {
    const { translations, user, updateEnrolledUserStatus } = this.props;

    return updateEnrolledUserStatus({
      statusId,
      courseId,
      userId: user.id,
    }).then(() => {
      showSuccessMessage(translations.successMessages.status);
    });
  };

  render() {
    const {
      classes,
      translations,
      userCourses,
      user,
      auth,
      allAttributes,
      courses,
      categories,
      pageQuickFilters,
      getCourseEnrolledUsers,
    } = this.props;
    const {
      isInitialLoad,
      isFiltersLoading,
      disenrollDialogOpened,
      isEnrollCourseDialogOpened,
      filters,
      search,
    } = this.state;
    const courseLevels = getCourseLevels(translations.levels).slice(1);
    const enrollmentStatuses = prepareEnrollmentStatuses(
      translations.enrollmentStatuses
    );
    const enrolledCourses = prepareCourses(
      userCourses?.results || [],
      enrollmentStatuses
    );
    const canAddCourse = user.id === auth.id || canManagePerson(auth, user.id);
    const filterIsActive = hasSelectedFilters(filters);
    const isUserDeactivated = isUserSuspended(user);

    return (
      !isInitialLoad && (
        <div>
          <div className={classes.header}>
            <Typography variant="body2" className={classes.pageDescription}>
              {auth.id === user.id
                ? translations.descriptionOwnProfile
                : translations.pageDescription}
            </Typography>
            <div className={classes.filtersAndActionsWrapper}>
              <Filters
                translations={translations.filters}
                selectedFilters={filters}
                filters={getPageFilters(
                  translations,
                  courseLevels,
                  allAttributes,
                  enrollmentStatuses,
                  categories,
                  pageQuickFilters
                )}
                onApplyFilters={this.handleApplyFilters}
              />
              <div className={classes.actions}>
                <Search
                  className={classes.search}
                  placeholder={translations.search}
                  value={search}
                  onChange={this.onEnrolledCoursesSearch}
                />
                {canAddCourse ? (
                  <CustomButton
                    className={classes.enrollButton}
                    type="addDarkRoundedOutlined"
                    disabled={isUserDeactivated}
                    onClick={this.handleEnrollCourseDialog}
                  >
                    {translations.enrollEmployeeToMoreCourses}
                  </CustomButton>
                ) : null}
              </div>
            </div>
          </div>
          {isArray(enrolledCourses) && !isArrayEmpty(enrolledCourses) && (
            <div className={classes.userCourses}>
              {enrolledCourses.map(course => (
                <CourseCard
                  key={`course_item_${course.id}`}
                  translations={translations.courseLabels}
                  course={course}
                  levels={courseLevels}
                  statuses={enrollmentStatuses}
                  actions={getCourseActions(
                    translations.courseActions,
                    this.onViewDetails(course.id),
                    () => this.toggleDisenrollDialog(course.id),
                    canAddCourse
                  )}
                  isDisabled={isUserDeactivated}
                  onChangeStatus={this.onChangeEnrollmentStatus(course.id)}
                  onCardClick={this.onViewDetails(course.id)}
                  isStatusSelectable={canAddCourse}
                  hasCourseUrl
                />
              ))}
            </div>
          )}
          {!isFiltersLoading &&
            isArray(enrolledCourses) &&
            isArrayEmpty(enrolledCourses) && (
              <NotificationCard
                title={
                  search || filterIsActive ? '' : translations.noResultsTitle
                }
                content={
                  search || filterIsActive
                    ? translations.noSearchResultsMessage
                    : translations.noResultsMessage
                }
              />
            )}
          <EnrollCourseDialog
            translations={translations.enrollCourseDialog}
            isOpened={isEnrollCourseDialogOpened}
            levels={courseLevels}
            courses={prepareCoursesToEnroll(
              courses?.results || [],
              userCourses?.results || [],
              enrollmentStatuses
            )}
            getCourseEnrolledUsers={getCourseEnrolledUsers}
            onSearch={this.onEnrollCoursesSearch}
            onCancel={this.toggleEnrollDialog}
            onEnroll={this.onEnrollCourses}
          />
          <AlertDialog
            translations={translations.disenrollDialog}
            isOpened={disenrollDialogOpened}
            onClose={this.toggleDisenrollDialog}
            onConfirm={this.onDisenrollUser}
          />
        </div>
      )
    );
  }
}

PeopleCoursesPage.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  userCourses: PropTypes.shape({}).isRequired,
  allAttributes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  courses: PropTypes.shape({}).isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pageQuickFilters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getCourses: PropTypes.func.isRequired,
  getUserCourses: PropTypes.func.isRequired,
  updateEnrolledUserStatus: PropTypes.func.isRequired,
  getCourseEnrolledUsers: PropTypes.func.isRequired,
  getAttributesWithQuestions: PropTypes.func.isRequired,
  clearUserCourses: PropTypes.func.isRequired,
  clearCourseEnrolledUsers: PropTypes.func.isRequired,
  clearAttributesWithQuestions: PropTypes.func.isRequired,
  getTagsCategories: PropTypes.func.isRequired,
  getPageQuickFilters: PropTypes.func.isRequired,
  clearTagsCategories: PropTypes.func.isRequired,
  clearPageQuickFilters: PropTypes.func.isRequired,
};

export default withStyles(styles)(PeopleCoursesPage);
