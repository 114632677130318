import { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import CustomCheckbox from '../customCheckbox';
import UserAvatar from '../userAvatar';
import ConditionalTooltip from '../conditionalTooltip';
import DeletedUserAvatar from '../deletedUserAvatar';
import { getPersonFullName } from '../../../utility/uiUtils';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    backgroundColor: primary.white,
    boxSizing: 'border-box',
    borderRadius: 16,
    display: 'grid',
    gridTemplateColumns: '1fr 28px',
    gridColumnGap: 8,
    alignItems: 'center',
    padding: spacing(4),
    width: '100%',
    minHeight: 64,
    userSelect: 'none',
  },
  taskWrapper: {
    width: '100%',
    flexShrink: 1,
  },
  userWrapper: {
    boxSizing: 'border-box',
    paddingTop: spacing(0.5),
    height: '100%',
  },
  tooltipWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: spacing(0.5),
  },
  user: {
    transition: 'box-shadow .25s ease-in',
    '&:hover': {
      boxShadow: `0 0 0 1px ${primary.white}, 0 0 0 2px ${primary.blue1}`,
    },
  },
}));

const TaskCard = ({ className, translations, currentUser, task, onChange }) => {
  const classes = useStyles();
  const { text, is_finished, creator } = task;
  const isCurrentUserCreator = currentUser?.id === creator?.id;

  const tooltipText = `${
    isCurrentUserCreator
      ? `${translations.assignedBy} ${translations.you}`
      : translations.assignedBy
  } ${isCurrentUserCreator ? '' : getPersonFullName(creator)}`;

  const handleChange = useCallback(() => {
    return onChange(task);
  }, [task, onChange]);

  return (
    <div className={classNames(classes.root, className)}>
      <CustomCheckbox
        className={classes.taskWrapper}
        isChecked={is_finished}
        labelText={text}
        onChange={handleChange}
        shouldTopAlign
        isControlled
        highlightedText
        smallText
      />
      {creator?.id ? (
        <div className={classes.userWrapper}>
          <ConditionalTooltip
            className={classes.tooltipWrapper}
            message={tooltipText}
            addTooltip
          >
            <UserAvatar customAvatarClass={classes.user} user={creator} small />
          </ConditionalTooltip>
        </div>
      ) : (
        <DeletedUserAvatar
          tooltipText={translations.deletedUserTooltip}
          isSmall
        />
      )}
    </div>
  );
};

TaskCard.defaultProps = {
  className: undefined,
  task: {},
};

TaskCard.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  task: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TaskCard;
