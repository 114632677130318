import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import CustomModal from '../customModal';
import CustomScrollBar from '../customScrollBar';
import UserAvatar from '../userAvatar';
import { isArrayEmpty } from '../../../utility/helpers';

const styles = ({ palette: { primary }, spacing }) => ({
  usersWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 147,
  },
  scrollY: {
    backgroundColor: primary.bluish9,
    top: 0,
    right: -20,
    height: '100%',
    width: 8,
  },
  scroll: {
    backgroundColor: primary.bluish7,
  },
  avatars: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  userAvatar: {
    borderBottom: `1px solid ${primary.bluish7}`,
    paddingBottom: spacing(2),
    marginBottom: spacing(2),
    width: '100%',
    '&:last-of-type': {
      borderBottom: 'none',
      paddingBottom: 0,
      marginBottom: 0,
    },
  },
  description: {
    marginBottom: spacing(4),
  },
  descriptionWithoutNotification: {
    marginTop: spacing(6),
  },
});

class SurveyActionsDialog extends PureComponent {
  render() {
    const {
      classes,
      translations,
      isOpened,
      usersToNotify,
      usersWithoutNotification,
      onClose,
      onConfirm,
    } = this.props;

    const hasUsersToNotify = !isArrayEmpty(usersToNotify);

    return (
      <CustomModal
        title={translations.title}
        closeButtonLabel={translations.close}
        confirmButtonLabel={hasUsersToNotify ? translations.confirm : undefined}
        isOpened={isOpened}
        onClose={onClose}
        onConfirm={onConfirm}
        isMedium
      >
        <>
          {hasUsersToNotify && (
            <div>
              <Typography className={classes.description}>
                {translations.description}
              </Typography>
              <div className={classes.usersWrapper}>
                <CustomScrollBar
                  customScrollBarYClass={classes.scrollY}
                  customScrollClass={classes.scroll}
                  passContentHeight
                  verticalScroll
                  removeScrollX
                >
                  <div className={classes.avatars}>
                    {usersToNotify.map(user => {
                      return (
                        <UserAvatar
                          key={user.id}
                          className={classes.userAvatar}
                          user={user}
                          variant="subtitle2"
                          small
                          caption
                        />
                      );
                    })}
                  </div>
                </CustomScrollBar>
              </div>
            </div>
          )}
          {!isArrayEmpty(usersWithoutNotification) && (
            <div>
              <Typography
                className={classNames(classes.description, {
                  [classes.descriptionWithoutNotification]: hasUsersToNotify,
                })}
              >
                {translations.withoutNotification}
              </Typography>
              <div className={classes.usersWrapper}>
                <CustomScrollBar
                  customScrollBarYClass={classes.scrollY}
                  customScrollClass={classes.scroll}
                  passContentHeight
                  verticalScroll
                  removeScrollX
                >
                  <div className={classes.avatars}>
                    {usersWithoutNotification.map(user => {
                      return (
                        <UserAvatar
                          key={user.id}
                          className={classes.userAvatar}
                          customWrapperClass={classes.avatarWrapper}
                          customCaptionContainerClass={classes.avatarCaption}
                          labelClass={classes.userLabel}
                          user={user}
                          variant="subtitle2"
                          small
                          caption
                        />
                      );
                    })}
                  </div>
                </CustomScrollBar>
              </div>
            </div>
          )}
        </>
      </CustomModal>
    );
  }
}

SurveyActionsDialog.defaultProps = {
  usersWithoutNotification: [],
  usersToNotify: [],
};

SurveyActionsDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  isOpened: PropTypes.bool.isRequired,
  usersWithoutNotification: PropTypes.arrayOf(PropTypes.shape({})),
  usersToNotify: PropTypes.arrayOf(PropTypes.shape({})),
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
export default withStyles(styles)(SurveyActionsDialog);
