export const AUTOMATION_ID = {
  APP_LOGO: 'app-logo',
  USER_FIRST_NAME: 'user-first-name',
  USER_LAST_NAME: 'user-last-name',
  USER_EMAIL: 'user-email',
  USER_PASSWORD: 'user-password',
  USER_ROLE: 'user-role',
  USER_REPORT_TO: 'user-report-to',
  USER_INVITATION_STATUS: 'user-invitation-status',
  USER_MENU_BUTTON: 'user-menu-button',
  SIGN_IN_BUTTON: 'sign-in-button',
  SIGN_UP_BUTTON: 'sign-up-button',
  SUB_DOMAIN_SIGN_IN: 'sub-domain-sign-in',
  WORKSPACE_URL: 'workspace-url',
  CREATE_ACCOUNT_BUTTON: 'create-account-button',
  CHANGE_ORGANIZATION_BUTTON: 'change-organization-button',
  FORGOTTEN_PASSWORD_BUTTON: 'forgotten-password-button',
  SUBMIT_FORM_BUTTON: 'submit-form-button',
  LOAD_MORE_BUTTON: 'load-more-button',
  DOTS_MENU_BUTTON: 'dots-menu-button',
  CLOSE_BUTTON: 'close-button',
  DELETE_BUTTON: 'delete-button',
  CANCEL_BUTTON: 'cancel-button',
  SAVE_BUTTON: 'save-button',
  SAVE_AND_ADD_BUTTON: 'save-and-add-button',
  DUPLICATE_BUTTON: 'duplicate-button',
  IMPORT_BUTTON: 'import-button',
  ADD_BUTTON: 'add-button',
  FILTER_BUTTON: 'filter-button',
  QUESTION: 'question',
  QUESTION_TITLE: 'question-title',
  QUESTION_ANSWER: 'question-answer',
  ACCEPT_BUTTON: 'accept-button',
  RESOLVE_BUTTON: 'resolve-button',
  RESCHEDULE_BUTTON: 'reschedule-button',
};
