import { memo, forwardRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { IconButton, makeStyles } from '@material-ui/core';
import ConditionalTooltip from '../conditionalTooltip';
import { BUTTON_ICONS } from './config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  squared: {
    boxSizing: 'border-box',
    border: ({ button }) =>
      `1px solid ${button?.borderColor || primary.bluish3}`,
    borderRadius: 44,
    padding: spacing(1, 2),
    height: 24,
    width: 32,
  },
  roundedWithBorder: {
    border: ({ button, isDisabled }) =>
      `1px solid ${
        isDisabled
          ? button?.borderDisabledColor || primary.bluish5
          : button?.borderColor || primary.bluish3
      }`,
  },
  tooltipLabel: {
    fontFamily: 'ProximaNova-Bold',
  },
  tooltipLabelDisabled: {
    fontFamily: 'ProximaNova-Regular',
  },
  disabled: {
    cursor: 'default',
  },
  link: {
    display: 'flex',
  },
}));

const ActionButton = forwardRef(
  (
    {
      id,
      className,
      iconClass,
      tooltipLabelClass,
      type,
      isSquared,
      isDisabled,
      tooltipText,
      disabledTooltipText,
      isLink,
      hasBorder,
      linkProps,
      onClickHandler,
      ...rest
    },
    ref
  ) => {
    const classes = useStyles({ button: BUTTON_ICONS[type], isDisabled });
    const {
      id: automationId,
      icon: Icon,
      iconDisabled: IconDisabled,
    } = BUTTON_ICONS[type];

    const renderIcon = () => (
      <>
        {isDisabled && IconDisabled ? (
          <IconDisabled className={iconClass} />
        ) : (
          <Icon className={iconClass} />
        )}
      </>
    );

    return (
      <ConditionalTooltip
        className={classNames({
          [className]: !!tooltipText,
          [classes.disabled]: isDisabled,
        })}
        customLabelClass={classNames(
          classes.tooltipLabel,
          { [classes.tooltipLabelDisabled]: isDisabled },
          tooltipLabelClass
        )}
        addTooltip={
          (!!tooltipText && !isDisabled) ||
          (isDisabled && !!disabledTooltipText)
        }
        message={isDisabled ? disabledTooltipText : tooltipText}
      >
        <IconButton
          id={id || automationId}
          ref={ref}
          className={classNames({
            [classes.squared]: isSquared,
            [className]: !tooltipText,
            [classes.roundedWithBorder]: hasBorder,
          })}
          disabled={isDisabled}
          onClick={isLink ? e => e.stopPropagation() : onClickHandler}
          {...rest}
        >
          {isLink ? (
            <Link className={classes.link} {...linkProps}>
              {renderIcon()}
            </Link>
          ) : (
            renderIcon()
          )}
        </IconButton>
      </ConditionalTooltip>
    );
  }
);

ActionButton.displayName = 'ActionButton';

ActionButton.defaultProps = {
  id: undefined,
  className: undefined,
  iconClass: undefined,
  tooltipLabelClass: undefined,
  tooltipText: '',
  disabledTooltipText: '',
  isSquared: false,
  isDisabled: false,
  isLink: false,
  hasBorder: false,
  linkProps: {},
  onClickHandler: () => {},
};

ActionButton.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  iconClass: PropTypes.string,
  tooltipLabelClass: PropTypes.string,
  type: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  disabledTooltipText: PropTypes.string,
  isSquared: PropTypes.bool,
  isLink: PropTypes.bool,
  linkProps: PropTypes.object,
  isDisabled: PropTypes.bool,
  hasBorder: PropTypes.bool,
  onClickHandler: PropTypes.func,
};

export default memo(ActionButton);
