import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import OrganizationChart from '../../shared/organizationChart';
import { isObjectEmpty } from '../../../utility/helpers';

const styles = ({ spacing }) => ({
  main: { width: '100%' },
  description: {
    margin: spacing(8, 0, 6, 0),
  },
});

class OrganizationPage extends PureComponent {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    const { organizationSettings, auth } = this.props;
    this.props
      .getEmployeesHierarchy({ ...auth, ...organizationSettings })
      .then(() => {
        this.setState({ isLoading: false });
      });
  }

  componentWillUnmount() {
    this.props.clearEmployeesHierarchy();
  }

  render() {
    const {
      classes,
      employeesHierarchy,
      translations,
      organizationSettings,
      auth,
      setEmployeeRespondents,
      goToProfilePage,
    } = this.props;
    const { isLoading } = this.state;

    return (
      <div className={classes.main}>
        <div className={classes.description}>
          <Typography variant="body2">
            {translations.descriptionPartOne}
          </Typography>
          <Typography variant="body2">
            {translations.descriptionPartTwo}
          </Typography>
        </div>
        {!isLoading && !isObjectEmpty(employeesHierarchy) && (
          <OrganizationChart
            currentUser={auth}
            hierarchyTree={employeesHierarchy}
            organizationSettings={organizationSettings}
            onGoToProfile={goToProfilePage}
            setEmployeeRespondents={setEmployeeRespondents}
            translations={translations}
          />
        )}
      </div>
    );
  }
}

OrganizationPage.propTypes = {
  auth: PropTypes.object.isRequired,
  employeesHierarchy: PropTypes.object.isRequired,
  organizationSettings: PropTypes.object.isRequired,
  goToProfilePage: PropTypes.func.isRequired,
  setEmployeeRespondents: PropTypes.func.isRequired,
  getEmployeesHierarchy: PropTypes.func.isRequired,
  clearEmployeesHierarchy: PropTypes.func.isRequired,
};

export default withStyles(styles)(OrganizationPage);
