import { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import CustomModal from '../customModal';
import SelectField from '../selectField';
import { validateField } from '../../../utility/validation';

const useStyles = makeStyles(({ spacing }) => ({
  subTitle: {
    marginBottom: spacing(3),
  },
  description: {
    marginBottom: spacing(4),
  },
  field: {
    marginBottom: spacing(6),
  },
  error: {
    marginBottom: spacing(1),
  },
}));

const CustomSelectDialog = ({
  translations,
  subTitle,
  isOpened,
  initialValue,
  isEdit,
  field,
  options,
  onSave,
  onCancel,
}) => {
  const { title, labels, validationMessages } = translations;
  const classes = useStyles();

  const [value, setValue] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const isDirty = initialValue !== value;

  useEffect(() => {
    if (isOpened) {
      setValue(isEdit ? initialValue : null);
    }
  }, [isOpened, isEdit, initialValue]);

  const handleValidateSingleField = async () => {
    const errorType = await validateField(field, value);

    setErrorMessage(validationMessages[errorType] || '');

    return errorType;
  };

  const handleReset = () => {
    setValue(null);
    setErrorMessage('');
  };

  const handleChange = selectedValue => setValue(selectedValue);

  const handleClose = () => {
    onCancel();
    handleReset();
  };

  const handleSave = async () => {
    const hasError = await handleValidateSingleField();

    if (!hasError) {
      if (isDirty) {
        onSave(value);
        handleClose();
      } else {
        handleClose();
      }
    }
  };

  return (
    <CustomModal
      title={isEdit ? title.edit : title.add}
      confirmButtonLabel={translations.save}
      closeButtonLabel={translations.cancel}
      isOpened={isOpened}
      onClose={handleClose}
      onConfirm={handleSave}
      isMedium
    >
      <div className={classes.content}>
        {translations.description && (
          <Typography className={classes.description}>
            {translations.description}
          </Typography>
        )}
        {subTitle && (
          <Typography className={classes.subTitle} variant="subtitle2">
            {subTitle}
          </Typography>
        )}
        <div
          key={field.name}
          className={classNames(classes.field, {
            [classes.error]: !!errorMessage,
          })}
        >
          <SelectField
            id={field.name}
            label={labels[field.translationKey || field.name]?.label}
            shouldRemoveLabel={field.shouldRemoveLabel}
            labelHelp={labels[field.translationKey || field.name]?.labelHelp}
            placeholder={
              labels[field.translationKey || field.name]?.placeholder
            }
            customStyles={field.customStyles}
            creatableOptionLabel={
              labels[field.translationKey || field.name]?.creatableOptionLabel
            }
            startIcon={field.startIcon}
            options={options}
            parser={field.parser}
            value={value}
            renderRolesTooltip={labels[field.name]?.renderRolesTooltip}
            creatableOptionField={field.creatableOptionField}
            shouldReturnOption={field.shouldReturnOption}
            shouldDisableSort={field.shouldDisableSort}
            isCategorized={field.isCategorized}
            isUser={field.isUser}
            isAttribute={field.isAttribute}
            isAttributeDraggable={field.isAttributeDraggable}
            hasHash={field.hasHash}
            hasCategoryColorBox={field.hasCategoryColorBox}
            isSearchDisabled={field.isSearchDisabled}
            isMulti={field.isMulti}
            isCreatable={field.isCreatable}
            hasError={!!errorMessage}
            errorMessage={errorMessage}
            isClearable={field.isClearable}
            hasColorBox={field.hasColorBox}
            onCreateOption={field.onCreateOption}
            onChange={handleChange}
            isFullWidth
          />
        </div>
      </div>
    </CustomModal>
  );
};

CustomSelectDialog.defaultProps = {
  subTitle: '',
  initialValue: null,
};

CustomSelectDialog.propTypes = {
  translations: PropTypes.object.isRequired,
  subTitle: PropTypes.string,
  isOpened: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool.isRequired,
  initialValue: PropTypes.number,
  field: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default memo(CustomSelectDialog);
