import { isEmpty } from '../../../utility/validation';

const fields = [
  {
    name: 'name',
    type: 'text',
    label: 'name',
    required: true,
    defaultValue: '',
    validators: [
      {
        translationKey: 'required',
        validator: isEmpty,
      },
    ],
  },
  {
    name: 'question_limit',
    type: 'number',
    label: 'questionLimit',
    defaultValue: 0,
    required: true,
    validators: [
      {
        translationKey: 'minQuestionsNumber',
        validator: value => value >= 1,
      },
    ],
  },
];

export default fields;
