import { isArrayEmpty } from '../../../utility/helpers';
import {
  CREATE_SURVEY_FIELD_NAMES,
  SURVEY_TYPES,
} from '../../../constants/survey';
import { ATTRIBUTES } from '../../../constants/attributes';

const { CREATED_FOR, SURVEY_TYPE } = CREATE_SURVEY_FIELD_NAMES;

export const isPreviewAvailable = values => {
  const isCompanyPulse = values[SURVEY_TYPE] === SURVEY_TYPES.PULSE;

  if (isCompanyPulse)
    return !isArrayEmpty(values[CREATE_SURVEY_FIELD_NAMES.ATTRIBUTES]);

  return (
    !isArrayEmpty(values[CREATE_SURVEY_FIELD_NAMES.ATTRIBUTES]) &&
    !isArrayEmpty(values[CREATED_FOR])
  );
};

export const getOrganizationSubject = organizationName => [
  { id: 1, first_name: organizationName, last_name: '' },
];

export const prepareAttributeData = (attribute, createdFor, values) => {
  const isSelfAssessment = values[SURVEY_TYPE] === SURVEY_TYPES.SELF;
  const isCompanyPulse = values[SURVEY_TYPE] === SURVEY_TYPES.PULSE;
  const subjects =
    isSelfAssessment || isCompanyPulse ? [{ ...createdFor[0] }] : createdFor;

  if (isArrayEmpty(attribute.questions)) return {};

  return {
    ...attribute,
    subjects,
    results: {},
    comments: [],
    isVertical: attribute.layout === ATTRIBUTES.verticalLayout,
    hasNeutralAnswer: attribute[ATTRIBUTES.fields.withNeutralAnswer],
    hasAdditionalFeedback: attribute[ATTRIBUTES.fields.withAdditionalFeedback],
    withTextReplyQuestions:
      attribute?.[ATTRIBUTES.fields.withTextReplyQuestions],
    totalSteps: attribute.questions.length + 1,
  };
};
