import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Typography, Fade, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import SectionTitle from '../sectionTitle';
import NotificationCard from '../notificationCard';
import RichTextPreview from '../richTextPreview';
import { ReactComponent as ProgressIcon } from '../../../assets/icons/suitcase-icon.svg';
import { getActionButtons } from './config';
import { isArrayEmpty } from '../../../utility/helpers';

const styles = ({ palette: { primary }, spacing }) => ({
  title: {
    marginBottom: spacing(8),
  },
  levelsWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    width: '100%',
    marginTop: 19,
  },
  level: {
    backgroundColor: primary.bluish8,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: 24,
    '&:first-of-type:not($currentLevel)': {
      borderRadius: '4px 0 0 4px',
    },
    '&:last-of-type:not($currentLevel)': {
      borderRadius: '0 4px 4px 0',
    },
  },
  levelTitle: {
    lineHeight: '18px',
    marginBottom: spacing(4),
    wordBreak: 'break-word',
  },
  currentLevel: {
    position: 'relative',
    backgroundColor: primary.blue1,
    '&::before': {
      backgroundColor: primary.blue1,
      borderRadius: '4px 4px 0 0',
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      top: -18,
      height: 19,
    },
    '&::after': {
      backgroundColor: primary.blue1,
      borderRadius: '0 0 4px 4px',
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: -18,
      height: 19,
    },
  },
  currentLevelTitle: {
    color: primary.white,
  },
  currentLevelDescription: {
    color: primary.white,
    marginBottom: spacing(5),
    '& span': {
      color: `${primary.white} !important`,
    },
  },
  currentLevelLabel: {
    position: 'absolute',
    left: 24,
    bottom: 0,
    color: primary.white,
    textTransform: 'uppercase',
  },
});

class CareerProgress extends PureComponent {
  render() {
    const {
      className,
      classes,
      translations,
      currentLevelId,
      hasAddLevelButton,
      hasAssignLevelButton,
      levels,
      onAssignLevel,
      onAddLevel,
      isUserSuspended,
    } = this.props;

    return (
      <Fade in appear unmountOnExit>
        <div className={className}>
          <SectionTitle
            className={classes.title}
            title={translations.title}
            variant="h4"
          />
          <div>
            {currentLevelId && !isArrayEmpty(levels) ? (
              <div className={classes.levelsWrapper}>
                {levels.map(level => (
                  <div
                    key={`level_item_${level.id}`}
                    className={classNames(classes.level, {
                      [classes.currentLevel]: level.isCurrentLevel,
                    })}
                  >
                    <Typography
                      className={classNames(classes.levelTitle, {
                        [classes.currentLevelTitle]: level.isCurrentLevel,
                      })}
                      variant="subtitle1"
                      component="h4"
                    >
                      {level.name}
                    </Typography>
                    {level.description && (
                      <RichTextPreview
                        className={classNames({
                          [classes.currentLevelDescription]:
                            level.isCurrentLevel,
                        })}
                        text={level.description}
                      />
                    )}
                    {level.isCurrentLevel && (
                      <Typography
                        className={classes.currentLevelLabel}
                        variant="subtitle2"
                      >
                        {translations.currentLevel}
                      </Typography>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <NotificationCard
                customIcon={ProgressIcon}
                title={translations.noLevel.title}
                actionButtons={getActionButtons(
                  translations.noLevel,
                  hasAddLevelButton,
                  hasAssignLevelButton,
                  onAssignLevel,
                  onAddLevel,
                  isUserSuspended
                )}
                hasIcon
              />
            )}
          </div>
        </div>
      </Fade>
    );
  }
}

CareerProgress.defaultProps = {
  className: null,
  currentLevelId: null,
  isUserSuspended: false,
  levels: [],
};

CareerProgress.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  currentLevelId: PropTypes.number,
  levels: PropTypes.arrayOf(PropTypes.shape({})),
  hasAddLevelButton: PropTypes.bool.isRequired,
  hasAssignLevelButton: PropTypes.bool.isRequired,
  isUserSuspended: PropTypes.bool,
  onAssignLevel: PropTypes.func.isRequired,
  onAddLevel: PropTypes.func.isRequired,
};

export default withStyles(styles)(CareerProgress);
