import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Typography, makeStyles } from '@material-ui/core';
import InputField from '../inputField';
import FormLayout from '../formLayout';
import NextButton from '../nextButton';
import CustomCheckbox from '../customCheckbox';
import ActionButton from '../actionButton';
import { isObjectEmpty } from '../../../utility/helpers';
import { FIELD_TYPES } from '../../../constants/fieldTypes';
import { AUTOMATION_ID } from '../../../constants/automationId';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';

const useStyles = makeStyles(
  ({ breakpoints, palette: { primary }, spacing }) => ({
    content: {
      display: 'grid',
      justifyContent: 'center',
    },
    contentNoBacklink: {
      paddingTop: spacing(3),
      [breakpoints.up('xLg')]: {
        paddingTop: spacing(8),
      },
      [breakpoints.up('xl')]: {
        paddingTop: spacing(12),
      },
    },
    backlinkWrapper: {
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
      padding: spacing(6, 8, 4, 8),
      [breakpoints.up('xLg')]: {
        padding: spacing(8, 10, 8, 10),
      },
      [breakpoints.up('xl')]: {
        padding: spacing(8, 10, 11, 10),
      },
    },
    title: {
      marginBottom: spacing(2),
    },
    subtitle: {
      textAlign: 'center',
      justifyContent: 'center',
    },
    resetPasswordSubtitle: {
      justifyContent: 'center',
    },
    fields: {
      display: 'grid',
      gridRowGap: 16,
    },
    input: {
      borderRadius: 32,
      border: `1px solid ${primary.bluish7}`,
      lineHeight: '24px',
      paddingLeft: spacing(4),
      paddingRight: spacing(4),
      gap: 4,
    },
    checkboxContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: spacing(3),
    },
    checkboxDescription: {
      marginLeft: spacing(2),
    },
    checkboxLink: {
      color: primary.bluish2,
    },
    error: {
      position: 'absolute',
      top: 40,
    },
    nextButton: {
      marginBottom: 0,
    },
    paswordForm: {
      marginBottom: spacing(8),
      [breakpoints.up('xLg')]: {
        marginBottom: spacing(9),
      },
      [breakpoints.up('xl')]: {
        marginBottom: spacing(10),
      },
    },
    visibilityButton: {
      '& g, & path': {
        fill: primary.bluish5,
        stroke: primary.bluish5,
      },
    },
  })
);

const AuthFormLayout = ({
  translations,
  values,
  errors,
  fields,
  children,
  icon,
  subtitleVariant,
  isPaswordReset,
  isSubmitting,
  hideSubmitButton,
  privacyPolicyCheck,
  handleChange,
  handleSubmit,
  handleBlur,
}) => {
  const classes = useStyles();

  const [isChecked, setIsChecked] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const hasErrors = !isObjectEmpty(errors);
  const [error] = Object.values(errors);
  const errorMessage = translations[error] || '';

  const onCheckboxChange = () => setIsChecked(prevIsChecked => !prevIsChecked);

  const onVisibilityChange = () =>
    setIsVisible(prevIsVisible => !prevIsVisible);

  return (
    <form onSubmit={handleSubmit}>
      <div>
        {children && <div className={classes.backlinkWrapper}>{children}</div>}
        <div
          className={classNames(classes.content, {
            [classes.contentNoBacklink]: !children,
          })}
        >
          <FormLayout
            icon={icon}
            field={{
              ...translations,
              subtitle: translations.description,
            }}
            titleClass={classNames({
              [classes.title]: isPaswordReset,
            })}
            subtitleClass={classNames(classes.subtitle, {
              [classes.resetPasswordSubtitle]: isPaswordReset,
            })}
            subtitleVariant={subtitleVariant}
            childrenClass={classNames({
              [classes.paswordForm]: isPaswordReset,
            })}
          >
            <div className={classes.fields}>
              {fields.map(field => {
                return (
                  <InputField
                    id={field.id}
                    key={field.name}
                    className={classes.input}
                    customErrorClass={classes.error}
                    placeholder={translations[field.label]}
                    type={isVisible ? FIELD_TYPES.TEXT : field.type}
                    name={field.name}
                    error={hasErrors}
                    errorMessage={field.shouldShowError ? errorMessage : ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values[field.name] || ''}
                    fullWidth
                    {...(field.hasVisibilityToggle
                      ? {
                          endAdornment: (
                            <ActionButton
                              className={classes.visibilityButton}
                              type={
                                isVisible
                                  ? ACTION_BUTTON_TYPES.PREVIEW_HIDE
                                  : ACTION_BUTTON_TYPES.PREVIEW
                              }
                              onClickHandler={onVisibilityChange}
                            />
                          ),
                        }
                      : {})}
                  />
                );
              })}
            </div>
          </FormLayout>
          {!hideSubmitButton && (
            <NextButton
              id={AUTOMATION_ID.SUBMIT_FORM_BUTTON}
              customClass={classes.nextButton}
              isDisabled={isSubmitting || (privacyPolicyCheck && !isChecked)}
              buttonLabel={translations.submit}
            />
          )}
          {privacyPolicyCheck && (
            <div className={classes.checkboxContainer}>
              <CustomCheckbox
                isChecked={isChecked}
                onChange={onCheckboxChange}
              />
              <Typography className={classes.checkboxDescription}>
                {translations.agree}{' '}
                <Link
                  to={translations.terms_of_service.url}
                  className={classes.checkboxLink}
                >
                  {translations.terms_of_service.name}
                </Link>{' '}
                {translations.and}{' '}
                <Link
                  to={translations.privacy_policy.url}
                  className={classes.checkboxLink}
                >
                  {translations.privacy_policy.name}
                </Link>{' '}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

AuthFormLayout.defaultProps = {
  children: null,
  hideSubmitButton: false,
  privacyPolicyCheck: false,
  isPaswordReset: false,
  icon: null,
  subtitleVariant: 'subtitle1',
};

AuthFormLayout.propTypes = {
  translations: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  children: PropTypes.element,
  isPaswordReset: PropTypes.bool,
  hideSubmitButton: PropTypes.bool,
  privacyPolicyCheck: PropTypes.bool,
  icon: PropTypes.object,
  subtitleVariant: PropTypes.string,
};

export default AuthFormLayout;
