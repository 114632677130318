import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import UserAvatar from '../../userAvatar';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    boxSizing: 'border-box',
    backgroundColor: primary.bluish9,
    borderRadius: 8,
    padding: spacing(1, 4),
  },
  userLabel: {
    fontFamily: 'ProximaNova-Bold',
    fontSize: 16,
    lineHeight: '20px',
  },
}));

const SlackUser = ({ className, user }) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.root, className)}>
      <UserAvatar labelClass={classes.userLabel} user={user} caption small />
    </div>
  );
};

SlackUser.defaultProps = {
  className: null,
};

SlackUser.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object.isRequired,
};

export default memo(SlackUser);
