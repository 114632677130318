import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TransitionGroup } from 'react-transition-group';
import {
  Typography,
  makeStyles,
  ListItem,
  Collapse,
  Fade,
} from '@material-ui/core';
import NavigationLink from '../navigationLink';
import ActionButton from '../../actionButton';
import { ACTION_BUTTON_TYPES } from '../../actionButton/config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    backgroundColor: primary.blue8,
    cursor: 'default',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: spacing(5),
    width: '100%',
    padding: 0,
    '&:last-of-type': {
      marginBottom: 0,
    },
    '&:hover': {
      backgroundColor: primary.blue8,
    },
  },
  contentWrapper: {
    boxSizing: 'border-box',
    backgroundColor: primary.blue8,
    borderRadius: 8,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    transition: 'background-color .25s ease-in',
    padding: spacing(1, 2),
    width: '100%',
    '&:hover': {
      backgroundColor: primary.blue7,
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: spacing(2),
    width: 24,
    height: 24,
  },
  iconActive: {
    '& path': {
      fill: primary.bluish1,
    },
  },
  collapseButton: {
    width: 24,
    height: 24,
  },
  expandIcon: {
    transform: 'rotate(0deg)',
    transition: 'transform .3s cubic-bezier(0.77, 0.2, 0.05, 1)',
  },
  expandIconOpened: {
    transform: 'rotate(180deg)',
  },
  label: {
    color: primary.bluish3,
  },
  labelActive: {
    color: primary.bluish1,
  },
  links: {
    boxSizing: 'border-box',
    paddingLeft: spacing(8),
    width: '100%',
  },
}));

const NavigationSection = ({
  section,
  isExpanded,
  activeRouteId,
  onExpand,
}) => {
  const classes = useStyles();
  const { id, title, icon: Icon, items, childrenSection } = section;
  const isRouteActive = activeRouteId === id;

  return (
    <div className={classes.root}>
      <ListItem className={classes.contentWrapper} button onClick={onExpand}>
        <div className={classes.content}>
          <Icon
            className={classNames(classes.icon, {
              [classes.iconActive]: isRouteActive,
            })}
          />
          <Typography
            className={classNames(classes.label, {
              [classes.labelActive]: isRouteActive,
            })}
            variant="subtitle1"
          >
            {title}
          </Typography>
        </div>
        <ActionButton
          className={classes.collapseButton}
          iconClass={classNames(classes.expandIcon, {
            [classes.expandIconOpened]: isExpanded,
          })}
          size="small"
          edge="end"
          type={ACTION_BUTTON_TYPES.ARROW}
          onClickHandler={onExpand}
        />
      </ListItem>
      <Collapse
        className={classes.links}
        in={isExpanded}
        timeout={300}
        unmountOnExit
        addEndListener={null}
      >
        <TransitionGroup>
          {items.map(item => (
            <Fade
              key={`nav_item_link_${item.title}`}
              in={isExpanded}
              appear
              addEndListener={null}
              unmountOnExit
            >
              <NavigationLink linkData={{ ...item, childrenSection }} />
            </Fade>
          ))}
        </TransitionGroup>
      </Collapse>
    </div>
  );
};

NavigationSection.defaultProps = {
  isExpanded: false,
  activeRouteId: null,
};

NavigationSection.propTypes = {
  section: PropTypes.object.isRequired,
  isExpanded: PropTypes.bool,
  activeRouteId: PropTypes.number,
  onExpand: PropTypes.func.isRequired,
};

export default memo(NavigationSection);
