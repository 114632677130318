import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';

const styles = ({ spacing }) => ({
  main: {
    display: 'flex',
  },
  label: {
    fontFamily: 'ProximaNova-Bold',
  },
  buttons: {
    flexDirection: 'row',
  },
  optionLabelWrapper: {
    fontSize: 14,
    marginRight: spacing(5),
    '& svg:not($optionIcon)': {
      width: 16,
      height: 16,
    },
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  optionLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  optionIcon: {
    marginLeft: spacing(1),
  },
  optionLabelText: {
    marginLeft: spacing(1),
  },
});

class CustomRadioGroup extends PureComponent {
  handleChange = e => {
    const { value, onChange } = this.props;
    const newValue = e.target.value;

    if (newValue !== value) {
      onChange(newValue);
    }
  };

  renderOptionLabel = option => {
    const { classes } = this.props;
    const { label, icon: Icon } = option;

    return (
      <span className={classes.optionLabel}>
        {Icon && <Icon className={classes.optionIcon} />}
        <Typography
          className={classes.optionLabelText}
          variant="body2"
          component="span"
        >
          {label}
        </Typography>
      </span>
    );
  };

  render() {
    const { className, classes, label, value, options } = this.props;

    return (
      <FormControl
        className={classNames(classes.main, className)}
        component="fieldset"
      >
        {label && (
          <FormLabel classes={{ root: classes.label }} component="legend">
            {label}
          </FormLabel>
        )}
        <RadioGroup
          classes={{ root: classes.buttons }}
          value={value}
          onChange={this.handleChange}
        >
          {options.map(option => (
            <FormControlLabel
              key={`option_item_${option.value}`}
              classes={{ root: classes.optionLabelWrapper }}
              label={this.renderOptionLabel(option)}
              value={option.value}
              control={<Radio />}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  }
}

CustomRadioGroup.defaultProps = {
  className: null,
  label: '',
};

CustomRadioGroup.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
    PropTypes.string,
  ]).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(CustomRadioGroup);
