import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import CustomButton from '../customButton';
import { ReactComponent as RightNextIcon } from '../../../assets/icons/chevron_right.svg';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    margin: spacing(0, 0, 8, 0),
    display: 'flex',
    justifyContent: 'center',
    height: 40,
  },
  nextButton: {
    width: 360,
    minHeight: 40,
    borderRadius: 44,
    fontFamily: 'ProximaNova-Bold',
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '24px',
    textAlign: 'center',
    boxSizing: 'border-box',
    backgroundColor: primary.blue1,
    border: 'none',
    padding: spacing(2, 4, 2, 4),
    color: primary.white,
    '&:hover': {
      backgroundColor: primary.blue1,
    },
    '&:disabled': {
      backgroundColor: primary.blue5,
      border: 'none',
    },
  },
  endIcon: {
    '& g, & path': {
      fill: primary.white,
    },
  },
}));

const NextButton = ({
  isDisabled,
  onClick,
  buttonLabel,
  isSubmit,
  customClass,
}) => {
  const classes = useStyles({});

  const handleNext = useCallback(() => {
    if (!isSubmit) {
      onClick();
    }
  }, [isSubmit, onClick]);

  return (
    <div className={classNames(classes.root, customClass)}>
      <CustomButton
        className={classes.nextButton}
        type="addWithTextRounded"
        size="large"
        endIcon={<RightNextIcon className={classes.endIcon} />}
        disabled={isDisabled}
        submit={isSubmit}
        onClick={handleNext}
      >
        {buttonLabel}
      </CustomButton>
    </div>
  );
};

NextButton.displayName = 'NextButton';

NextButton.defaultProps = {
  customClass: null,
  isDisabled: false,
  isSubmit: true,
  onClick: () => {},
};

NextButton.propTypes = {
  customClass: PropTypes.string,
  buttonLabel: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isSubmit: PropTypes.bool,
  onClick: PropTypes.func,
};

export default NextButton;
