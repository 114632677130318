import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { PLACEMENTS } from 'constants/tags';
import PeoplePageContainer from '../../components/shared/peoplePageContainer';
import PeopleOneOnOnePage from '../../components/pages/peopleOneOnOnePage';
import { dialogSelector } from '../../store/selectors/dialogSelector';
import { personOneOnOneSelector } from '../../store/selectors/personOneOnOneSelector';
import {
  getPersonOneOnOneRecords,
  clearPersonOneOnOneRecords,
  getPersonOneOnOneRequest,
  clearPersonOneOnOneRequest,
  deletePersonOneOnOneRecord,
} from '../../store/modules/personOneOnOne';
import { oneOnOneTopicsSelector } from '../../store/selectors/oneOnOneTopicsSelector';
import { tagsSelector } from '../../store/selectors/tagsSelector';
import {
  getOneOnOneTopics,
  clearOneOnOneTopics,
} from '../../store/modules/oneOnOneTopics';
import { useTranslations } from '../../utility/useTranslations';
import { APP_PAGES } from '../../constants/pages';
import {
  getTagsCategories,
  clearTagsCategories,
  addMultipleTags,
  getPageQuickFilters,
  clearPageQuickFilters,
} from '../../store/modules/tags';

const mapStateToProps = state => ({
  ...dialogSelector(state),
  ...personOneOnOneSelector(state),
  ...oneOnOneTopicsSelector(state),
  ...tagsSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getPersonOneOnOneRecords: (userId, isLoadMore, data) =>
    getPersonOneOnOneRecords(dispatch, isLoadMore, userId, data),
  clearPersonOneOnOneRecords: () => dispatch(clearPersonOneOnOneRecords()),
  getOneOnOneTopics: () => getOneOnOneTopics(dispatch),
  getPersonOneOnOneRequest: userId =>
    getPersonOneOnOneRequest(dispatch, userId),
  clearPersonOneOnOneRequest: () => dispatch(clearPersonOneOnOneRequest()),
  clearOneOnOneTopics: () => clearOneOnOneTopics(dispatch),
  deletePersonOneOnOneRecord: id => dispatch(deletePersonOneOnOneRecord(id)),
  addMultipleTags: data => dispatch(addMultipleTags(data)),
  getTagsCategories: () => getTagsCategories(dispatch),
  getPageQuickFilters: () =>
    getPageQuickFilters(dispatch, PLACEMENTS.ONE_ON_ONE.id),
  clearPageQuickFilters: () => dispatch(clearPageQuickFilters()),
  clearTagsCategories: () => dispatch(clearTagsCategories()),
});

const PeopleOneOnOnePageView = props => {
  const { id } = useParams();
  const userId = (!Number.isNaN(Number(id)) && +id) || -1;
  const translations = useTranslations(APP_PAGES.PEOPLE_ONE_ON_ONE);

  return (
    <PeoplePageContainer {...props} userId={userId} translations={translations}>
      <PeopleOneOnOnePage />
    </PeoplePageContainer>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PeopleOneOnOnePageView);
