import { createAction, handleActions } from 'redux-actions';
import http from '../../utility/http';
import {
  api_user_reviewed_attributes,
  api_user_reports_aggregated,
  api_user_reports_comments,
} from '../../constants/apiRoutes';
import { parseDuplicateParameters } from '../../utility/uiUtils';

// ------------------------------------
// Constants
// ------------------------------------

const SET_REVIEWED_ATTRIBUTES = 'SET_REVIEWED_ATTRIBUTES';
const SET_USER_REPORTS = 'SET_USER_REPORTS';
const SET_REPORT_COMMENTS = 'SET_REPORT_COMMENTS';

const initialState = {
  reviewedAttributes: [],
  userReports: [],
  reportComments: [],
};

// ------------------------------------
// Actions
// ------------------------------------

const setReviewedAttributes = createAction(SET_REVIEWED_ATTRIBUTES);
const setUserReports = createAction(SET_USER_REPORTS);
const setReportComments = createAction(SET_REPORT_COMMENTS);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_REVIEWED_ATTRIBUTES]: (state, { payload }) => {
    return { ...state, reviewedAttributes: payload };
  },
  [SET_USER_REPORTS]: (state, { payload }) => {
    return { ...state, userReports: payload };
  },
  [SET_REPORT_COMMENTS]: (state, { payload }) => {
    return { ...state, reportComments: payload };
  },
};

export const getReviewedAttributes = (dispatch, userId, params) =>
  http
    .get(api_user_reviewed_attributes(userId), {
      params,
      paramsSerializer: par => parseDuplicateParameters(par),
    })
    .then(({ data }) => {
      dispatch(setReviewedAttributes(data));

      return data;
    });

export const getReportComments = (dispatch, userId, params) =>
  http
    .get(api_user_reports_comments(userId), {
      params,
      paramsSerializer: data => parseDuplicateParameters(data),
    })
    .then(({ data }) => {
      dispatch(setReportComments(data));

      return data;
    });

export const getBarChartData = (dispatch, userId, groupBy, params) =>
  http
    .get(api_user_reports_aggregated(userId, groupBy), {
      params,
      paramsSerializer: data => parseDuplicateParameters(data),
    })
    .then(({ data }) => {
      dispatch(setUserReports(data));
    });

// ------------------------------------
// "plain" action functions
// ------------------------------------

export const clearReviewedAttributes = () =>
  setUserReports(initialState.userReports);

export const clearUserReports = () =>
  setReviewedAttributes(initialState.reviewedAttributes);

export const clearReportComments = () =>
  setReportComments(initialState.reportComments);

export default handleActions(reducers, initialState);
