import { memo } from 'react';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as DropdownIcon } from '../../../../assets/icons/arrow-down-dark.svg';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    width: 22,
    height: 22,
  },
  icon: {
    cursor: 'pointer',
    flexShrink: 0,
    width: 12,
    height: 8,
  },
}));

const DropdownIndicator = props => {
  const classes = useStyles();

  return (
    !props.isDisabled && (
      <div {...props.innerProps} className={classes.root}>
        <DropdownIcon className={classes.icon} />
      </div>
    )
  );
};

export default memo(DropdownIndicator);
