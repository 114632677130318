import { memo } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup } from 'react-transition-group';
import { Fade, makeStyles } from '@material-ui/core';
import OneOnOneRecord from './oneOnOneRecord';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'block',
    width: '100%',
  },
  recordWrapper: {
    marginBottom: spacing(4),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));

const OneOnOneRecords = ({
  translations,
  records,
  isDisabled,
  canManageUser,
  onDeleteRecord,
  onEditRecord,
  onManageTasks,
  onRescheduleRecord,
  onOpenCustomTopic,
  onAddImpression,
  isMiniCard,
  onCardClickHandler,
}) => {
  const classes = useStyles();

  return (
    <TransitionGroup className={classes.root}>
      {records.map(record => (
        <Fade
          key={`record_${record.id}`}
          className={classes.recordWrapper}
          in
          unmountOnExit
        >
          <div>
            <OneOnOneRecord
              key={record.id}
              className={classes.item}
              record={record}
              translations={translations}
              isMiniCard={isMiniCard}
              isDisabled={isDisabled}
              canManageUser={canManageUser}
              onDelete={onDeleteRecord(record)}
              onEdit={onEditRecord(record)}
              onManageTasks={onManageTasks(record)}
              onReschedule={onRescheduleRecord(record)}
              onOpenCustomTopic={onOpenCustomTopic(record)}
              onAddImpression={onAddImpression(record)}
              onCardClickHandler={onCardClickHandler}
            />
          </div>
        </Fade>
      ))}
    </TransitionGroup>
  );
};

OneOnOneRecords.defaultProps = {
  isMiniCard: false,
  isDisabled: false,
  canManageUser: false,
  onOpenCustomTopic: () => {},
  onRescheduleRecord: () => {},
  onEditRecord: () => {},
  onDeleteRecord: () => {},
  onAddImpression: () => {},
  onCardClickHandler: () => {},
  onManageTasks: () => {},
};

OneOnOneRecords.propTypes = {
  translations: PropTypes.shape({}).isRequired,
  records: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isMiniCard: PropTypes.bool,
  isDisabled: PropTypes.bool,
  canManageUser: PropTypes.bool,
  onDeleteRecord: PropTypes.func,
  onEditRecord: PropTypes.func,
  onOpenCustomTopic: PropTypes.func,
  onRescheduleRecord: PropTypes.func,
  onAddImpression: PropTypes.func,
  onCardClickHandler: PropTypes.func,
  onManageTasks: PropTypes.func,
};

export default memo(OneOnOneRecords);
