import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as NoLevelIcon } from '../../../assets/icons/star.svg';

const styles = ({ palette: { primary }, spacing }) => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    height: 20,
  },
  bars: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  bar: {
    backgroundColor: primary.bluish5,
    boxSizing: 'border-box',
    borderRadius: 2,
    marginRight: spacing(0.5),
    width: 5,
    flexShrink: 0,
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  barActive: {
    backgroundColor: primary.blue1,
  },
  label: {
    color: primary.bluish2,
    marginLeft: spacing(1.5),
  },
});

const DEFAULT_BAR_HEIGHT = 5;
const MULTIPLIER = 2;

class LevelBars extends PureComponent {
  renderBars = () => {
    const { classes, levels, level } = this.props;
    const levelIndex = levels.findIndex(lvl => lvl.id === level);

    return (
      <div className={classes.bars}>
        {levels.map((lvl, index) => (
          <div
            key={`level_bar_${lvl.id}`}
            className={classNames(classes.bar, {
              [classes.barActive]: index <= levelIndex,
            })}
            style={{ height: DEFAULT_BAR_HEIGHT * (index + MULTIPLIER) }}
          />
        ))}
      </div>
    );
  };

  renderNoLevel = () => {
    const { classes, noLevelLabel } = this.props;

    return (
      <>
        <NoLevelIcon />
        <Typography className={classes.label} variant="subtitle2">
          {noLevelLabel}
        </Typography>
      </>
    );
  };

  render() {
    const { classes, levels, level } = this.props;
    const label = levels.find(l => l.id === level)?.name;

    return (
      <div className={classes.main}>
        {label ? (
          <>
            {this.renderBars()}
            <Typography className={classes.label} variant="subtitle2">
              {label}
            </Typography>
          </>
        ) : (
          this.renderNoLevel()
        )}
      </div>
    );
  }
}

LevelBars.defaultProps = {
  level: '',
};

LevelBars.propTypes = {
  classes: PropTypes.object.isRequired,
  noLevelLabel: PropTypes.string.isRequired,
  levels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  level: PropTypes.string,
};

export default withStyles(styles)(LevelBars);
