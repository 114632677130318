import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import stringToColor from 'string-to-color';

import {
  getInitials,
  getPersonFullName,
  getUserAvatarPicture,
} from '../../../utility/uiUtils';
import Avatar from '../avatar';

class UserAvatar extends PureComponent {
  render() {
    const {
      user: { first_name, last_name, avatar, id, role, slack_image },
      caption,
      anonymousUser,
      getCustomCaption,
      isOrganizationUser,
      ...rest
    } = this.props;

    const generatedColor = stringToColor(id);

    return (
      <Avatar
        avatarText={getInitials(first_name, last_name)}
        caption={
          caption
            ? getCustomCaption(this.props.user) ||
              getPersonFullName(this.props.user)
            : null
        }
        avatarColor={anonymousUser ? '' : generatedColor}
        avatarPicture={getUserAvatarPicture(
          role,
          avatar,
          slack_image,
          isOrganizationUser
        )}
        anonymousUser={anonymousUser}
        isOrganizationUser={isOrganizationUser}
        {...rest}
      />
    );
  }
}

UserAvatar.defaultProps = {
  caption: false,
  anonymousUser: false,
  customWrapperClass: '',
  customCaptionContainerClass: '',
  labelClass: '',
  captionDescription: '',
  isOrganizationUser: false,
  getCustomCaption: () => {},
};

UserAvatar.propTypes = {
  user: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    avatar: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  isOrganizationUser: PropTypes.bool,
  caption: PropTypes.bool,
  anonymousUser: PropTypes.bool,
  customWrapperClass: PropTypes.string,
  customCaptionContainerClass: PropTypes.string,
  labelClass: PropTypes.string,
  getCustomCaption: PropTypes.func,
  captionDescription: PropTypes.string,
};

export default UserAvatar;
