import { useState, useRef, useMemo, useCallback, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import PageContainer from '../../shared/pageContainer';
import TextBoxWithTooltip from '../../shared/textBoxWithTooltip';
import SurveySummaryInfo from '../../shared/surveySummaryInfo';
import SurveySummaryDescription from '../../shared/surveySummaryDescription';
import SurveySummaryReviews from '../../shared/surveySummaryReviews';
import SurveySummaryComment from '../../shared/surveySummaryComment';
import ReviewReport from '../../shared/reviewReport';
import { surveySummarySelector } from '../../../store/selectors/surveySummarySelector';
import {
  getSurveySummary,
  clearSurveySummary,
} from '../../../store/modules/surveySummary';
import { transformSurveySummaryData } from '../../../utility/surveySummary';
import { getTranslatedSurveyTypes } from '../../../utility/survey';
import { isArrayEmpty, checkUserRole } from '../../../utility/helpers';
import { useTranslations } from '../../../utility/useTranslations';
import { usePreviousValue } from '../../../utility/hooks';
import { sticky } from '../../../constants/helperCssRules';
import { ROLES } from '../../../constants/rolesAndPermissionList';
import { APP_PAGES } from '../../../constants/pages';
import { SURVEY_TYPES } from '../../../constants/survey';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  title: {
    maxWidth: 'calc(100% - 260px)',
    width: '100%',
  },
  divider: {
    paddingTop: spacing(8),
    ...sticky(primary.white, 105),
  },
  reviews: {
    marginTop: spacing(4),
  },
  description: {
    marginTop: spacing(6),
    marginBottom: spacing(2),
  },
  summary: {
    marginTop: spacing(9),
  },
}));

const SurveySummaryDetailsPage = ({
  dispatch,
  auth,
  organizationSettings,
  ...rest
}) => {
  const classes = useStyles();
  const translations = useTranslations(APP_PAGES.SURVEY_SUMMARY_DETAILS);
  const { summaryId } = useParams();
  const reviewRef = useRef({});
  const pageScrollRef = useRef(null);
  const defaultPageOffset = 137; // Header height + divider height
  const defaultReviewOffset = 77; // Height of collapsed review

  const [isLoading, setIsLoading] = useState(true);
  const [reviewId, setReviewId] = useState(null);
  const [textReplyQuestionId, setTextReplyQuestionId] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState({});
  const [isManagingView, setIsManagingView] = useState(false);

  const previousReviewId = usePreviousValue(reviewId);

  const surveySummary = useSelector(surveySummarySelector);
  const report = useMemo(
    () => transformSurveySummaryData(surveySummary, isManagingView),
    [surveySummary, isManagingView]
  );
  const { title, survey, description, reviews } = report;

  const surveyTypes = getTranslatedSurveyTypes(translations);
  const isTeamReview = survey?.type === SURVEY_TYPES.TEAM;
  const canManage =
    checkUserRole(auth.role, ROLES.ADMIN) || report?.creator?.id === auth.id;
  const hasSharedResults = !isArrayEmpty(reviews);

  const getReportData = useCallback(async () => {
    await getSurveySummary(dispatch, summaryId);

    setIsLoading(false);
  }, [dispatch, summaryId]);

  const hadleReviewScroll = useCallback(() => {
    if (previousReviewId !== reviewId) {
      const prevReview =
        reviewRef?.current?.[previousReviewId]?.getBoundingClientRect();
      const prevReviewOffset =
        reviewRef?.current?.[previousReviewId]?.offsetTop;
      const currReviewOffset = reviewRef?.current?.[reviewId]?.offsetTop;

      if (prevReview && prevReviewOffset < currReviewOffset) {
        setTimeout(() => {
          pageScrollRef?.current?.scrollTo({
            top:
              currReviewOffset -
              prevReview.height -
              defaultPageOffset +
              defaultReviewOffset,
            behavior: 'smooth',
          });
        }, 300);
      } else {
        setTimeout(() => {
          pageScrollRef?.current?.scrollTo({
            top: currReviewOffset - defaultPageOffset,
            behavior: 'smooth',
          });
        }, 300);
      }
    }
  }, [previousReviewId, reviewId]);

  const handleViewReport = useCallback(
    attributeId => async () => {
      if (attributeId !== reviewId) {
        if (
          reviewRef?.current?.[reviewId] &&
          reviewRef?.current?.[reviewId]?.offsetTop <
            reviewRef?.current?.[attributeId]?.offsetTop
        ) {
          pageScrollRef?.current?.scrollTo({
            top: 0,
          });
        }
        setReviewId(attributeId);
        setTextReplyQuestionId(null);
      } else {
        setReviewId(null);
        setTextReplyQuestionId(null);
      }
    },
    [reviewId]
  );

  const onUserSelect = useCallback(
    currentReviewId => (selectedUserId, isSelected) => {
      if (selectedUsers[currentReviewId]) {
        if (isSelected) {
          return setSelectedUsers(prevSelectedUsers => ({
            ...prevSelectedUsers,
            [currentReviewId]: prevSelectedUsers[currentReviewId].filter(
              userId => userId !== selectedUserId
            ),
          }));
        }

        return setSelectedUsers(prevSelectedUsers => ({
          ...prevSelectedUsers,
          [currentReviewId]: [
            ...prevSelectedUsers[currentReviewId],
            selectedUserId,
          ],
        }));
      }

      setSelectedUsers(prevSelectedUsers => ({
        ...prevSelectedUsers,
        [currentReviewId]: [selectedUserId],
      }));
    },
    [selectedUsers]
  );

  const onClearSelectedUsers = useCallback(() => {
    setSelectedUsers(prevSelectedUsers => {
      return Object.keys(prevSelectedUsers).reduce(
        (acc, reviewKey) => ({
          ...acc,
          ...(+reviewKey !== reviewId
            ? { [reviewKey]: [...prevSelectedUsers[reviewKey]] }
            : {}),
        }),
        {}
      );
    });
  }, [reviewId]);

  const handleViewTextReply = questionId => e => {
    e.stopPropagation();

    setTextReplyQuestionId(prevTextReplyQuestionId =>
      prevTextReplyQuestionId !== questionId ? questionId : null
    );
  };

  const handleChangeManagingView = updatedView => {
    setSelectedUsers({});
    setIsManagingView(!updatedView);
  };

  useEffect(() => {
    getReportData();

    return () => {
      dispatch(clearSurveySummary());
    };
  }, [dispatch, getReportData]);

  const renderReportHeader = () => {
    return (
      <div className={classes.title}>
        <TextBoxWithTooltip variant="h1" text={title} />
      </div>
    );
  };

  return (
    !isLoading && (
      <PageContainer
        {...rest}
        translations={translations}
        auth={auth}
        organizationSettings={organizationSettings}
        headerProps={{
          renderTitle: renderReportHeader,
        }}
        shouldPassProps={false}
        isFullWidthContent
        pageScrollRef={pageScrollRef}
      >
        <div className={classes.divider} />
        <SurveySummaryInfo
          translations={translations.info}
          report={report}
          canManage={canManage}
          isManagingView={isManagingView}
          hasSharedResults={hasSharedResults}
          onChangeView={handleChangeManagingView}
        />
        <SurveySummaryDescription
          className={classes.description}
          translations={translations.description}
          surveyTypes={surveyTypes}
          report={report}
        />
        {hasSharedResults ? (
          <>
            {reviews.map(review => (
              <div
                key={`review_${review.id}`}
                ref={el => {
                  reviewRef.current[review.id] = el;
                }}
              >
                <ReviewReport
                  translations={translations.review}
                  review={review}
                  selectedUsers={selectedUsers[review.id]}
                  isExpanded={reviewId === review.id}
                  isTeamReview={isTeamReview}
                  isAnonymous={survey.isAnonymous}
                  textReplyQuestionId={textReplyQuestionId}
                  onUserSelect={onUserSelect(review.id)}
                  onClearSelectedUsers={onClearSelectedUsers}
                  onViewIndividualScores={() => {}}
                  onViewTextReply={handleViewTextReply}
                  onViewReport={handleViewReport(review.id)}
                  onScrollInToView={hadleReviewScroll}
                  isReadOnlyFeedback
                  shouldDisableIndividualScores
                />
              </div>
            ))}
          </>
        ) : (
          <SurveySummaryReviews
            className={classes.reviews}
            translations={translations.reviews}
            reviews={survey.attributes}
          />
        )}
        <SurveySummaryComment
          className={classes.summary}
          title={translations.summaryTitle}
          comment={description}
        />
      </PageContainer>
    )
  );
};

SurveySummaryDetailsPage.propTypes = {
  auth: PropTypes.object.isRequired,
  organizationSettings: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default memo(SurveySummaryDetailsPage);
