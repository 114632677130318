import { memo } from 'react';
import PropTypes from 'prop-types';
import { isFuture, parseJSON } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import StatusIndicator from '../statusIndicator';
import UserAvatar from '../userAvatar';
import ListWithTooltip from '../listWithTooltip';
import SurveyType from '../surveyType';
import { getItemById } from '../../../utility/helpers';
import { formatDate } from '../../../utility/dateUtils';

const useStyles = makeStyles(
  ({ palette: { primary }, spacing, breakpoints }) => ({
    root: {
      borderBottom: `1px solid ${primary.bluish8}`,
      display: 'grid',
      gridColumnGap: 12,
      gridAutoColumns: 'minmax(0, 1fr)',
      gridAutoFlow: 'column',
      paddingBottom: spacing(8),
      [breakpoints.up('xLg')]: {
        gridColumnGap: 24,
      },
    },
    label: {
      color: primary.bluish3,
      lineHeight: '20px',
      marginBottom: spacing(2),
    },
    userMoreItems: {
      backgroundColor: 'transparent',
      fontSize: 16,
      lineHeight: '20px',
      padding: spacing(0, 2),
      marginTop: spacing(0.5),
      maxWidth: 'fit-content',
    },
  })
);

const SurveyReportInfo = ({
  labels,
  survey,
  statuses,
  types,
  isTeamReview,
  isUserClickable,
  onGoToPersonProfile,
}) => {
  const classes = useStyles();
  const {
    creator,
    sentAt,
    scheduledFor,
    expireAt,
    type,
    status,
    createdFor,
    reviewers,
  } = survey;
  const isCreatorClickable = isUserClickable(creator);
  const isScheduled = scheduledFor && isFuture(parseJSON(scheduledFor));

  const renderLabel = label => (
    <Typography className={classes.label} variant="body2">
      {label}
    </Typography>
  );

  const renderStatus = () => {
    const surveyStatus = getItemById(statuses, status);

    return (
      <div>
        {renderLabel(labels.status)}
        <StatusIndicator statuses={statuses} status={surveyStatus} />
      </div>
    );
  };

  const renderCreator = () => (
    <div>
      {renderLabel(labels.createdBy)}
      <UserAvatar
        variant="subtitle1"
        user={creator}
        clickableCaption={isCreatorClickable}
        onClickHandler={() => onGoToPersonProfile(creator.id)}
        small
        caption
      />
    </div>
  );

  const renderDate = (label, date) => {
    return (
      <div>
        {renderLabel(label)}
        <Typography variant="subtitle1">{formatDate(date)}</Typography>
      </div>
    );
  };

  const renderReviewUsers = () => (
    <div>
      <ListWithTooltip
        labelClass={classes.label}
        moreItemsClass={classes.userMoreItems}
        label={isTeamReview ? labels.reviewedUsers : labels.reviewers}
        items={isTeamReview ? createdFor : reviewers}
        visibleItemsCount={4}
        isItemClickable={isUserClickable}
        onItemClick={onGoToPersonProfile}
        asyncListProps={{
          position: 'top',
          shouldDisablePortal: false,
        }}
        isUserList
      />
    </div>
  );

  return (
    <div className={classes.root}>
      {renderStatus()}
      {renderCreator()}
      <SurveyType
        label={labels.type}
        type={getItemById(types, type)}
        hasTooltip
      />
      {renderDate(
        isScheduled ? labels.scheduledFor : labels.dateSent,
        isScheduled ? scheduledFor : sentAt
      )}
      {renderDate(labels.expire, expireAt)}
      {renderReviewUsers()}
    </div>
  );
};

SurveyReportInfo.propTypes = {
  labels: PropTypes.object.isRequired,
  survey: PropTypes.shape({}).isRequired,
  statuses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  types: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isTeamReview: PropTypes.bool.isRequired,
  isUserClickable: PropTypes.func.isRequired,
  onGoToPersonProfile: PropTypes.func.isRequired,
};

export default memo(SurveyReportInfo);
