import { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import ActionButton from '../../../actionButton';
import TextBoxWithTooltip from '../../../textBoxWithTooltip';
import { ACTION_BUTTON_TYPES } from '../../../actionButton/config';

const useStyles = makeStyles(
  ({ palette: { primary }, spacing, breakpoints }) => ({
    root: {
      cursor: 'pointer',
      display: 'grid',
      alignItems: 'center',
      gridTemplateColumns:
        'minmax(80px, 80px) minmax(132px, 1.5fr) minmax(auto, 1fr) minmax(90px, 1fr) minmax(90px, 1fr) minmax(160px, 190px) minmax(60px, 80px)',
      boxSizing: 'border-box',
      borderBottom: `1px solid ${primary.bluish7}`,
      transition: 'background-color .3s ease',
      '&:hover': {
        backgroundColor: primary.bluish9,
      },
      '&:last-of-type': {
        borderBottom: 'none',
      },
    },
    item: {
      boxSizing: 'border-box',
      padding: spacing(4, 2),

      [breakpoints.up('xLg')]: {
        padding: spacing(4),
      },
    },
    surveyTitleWrapper: {
      minWidth: 0,
    },
    dragItemWrapper: {
      display: 'flex',
      alignItems: 'center',
      minWidth: 0,
      padding: spacing(4, 4, 4, 2),
      [breakpoints.up('xLg')]: {
        padding: spacing(4, 4, 4, 2),
      },
    },
    actions: {
      boxSizing: 'border-box',
      cursor: 'default',
      padding: spacing(3, 1),

      [breakpoints.up('xLg')]: {
        padding: spacing(3.5, 3),
      },
    },
    dragButton: {
      position: 'relative',
      width: 22,
      height: 22,
      flexShrink: 0,
      marginRight: spacing(1.5),
      zIndex: 11,
      '& button': {
        width: 22,
        height: 22,
      },
    },
    dragIcon: {
      width: 8,
      height: 14,
    },
    dragTooltipLabel: {
      fontFamily: 'ProximaNova-Regular',
    },
  })
);

const Survey = ({
  translations,
  survey,
  dragRef,
  dragProps,
  onSurveyClick,
}) => {
  const classes = useStyles();
  const { title, type, createdBy, sentAt, expireAt, status, actions } = survey;

  const handleSurveyClick = useCallback(
    () => onSurveyClick(survey),
    [survey, onSurveyClick]
  );

  const handleActionsClick = e => e.stopPropagation();

  return (
    <div
      ref={dragRef}
      className={classes.root}
      onClick={handleSurveyClick}
      {...(dragProps ? { ...dragProps.draggableProps } : {})}
    >
      <div className={classNames(classes.item, classes.dragItemWrapper)}>
        <ActionButton
          className={classes.dragButton}
          iconClass={classes.dragIcon}
          tooltipLabelClass={classes.dragTooltipLabel}
          type={ACTION_BUTTON_TYPES.DRAG}
          tooltipText={translations.surveyDragTooltip}
          {...(dragProps ? { ...dragProps.dragHandleProps } : {})}
        />
        <div>{type}</div>
      </div>
      <div className={classNames(classes.item, classes.surveyTitleWrapper)}>
        {title}
      </div>
      <TextBoxWithTooltip className={classes.item} text={createdBy} />
      {sentAt ? (
        <TextBoxWithTooltip className={classes.item} text={sentAt || ''} />
      ) : (
        <div className={classes.item} />
      )}
      <TextBoxWithTooltip className={classes.item} text={expireAt || ''} />
      <div>{status}</div>
      <div className={classes.actions} onClick={handleActionsClick}>
        {actions}
      </div>
    </div>
  );
};

Survey.defaultProps = {
  dragRef: () => {},
  dragProps: null,
};

Survey.propTypes = {
  translations: PropTypes.object.isRequired,
  survey: PropTypes.object.isRequired,
  dragProps: PropTypes.object,
  dragRef: PropTypes.func,
};

export default memo(Survey);
