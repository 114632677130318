import { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import CustomModal from '../customModal';
import CustomDatePicker from '../customDatePicker';
import CustomTimePicker from '../customTimePicker';
import { FIELD_TYPES } from '../../../constants/fieldTypes';

const useStyles = makeStyles(({ spacing }) => ({
  description: {
    marginBottom: spacing(4),
  },
  fieldsWrapper: {
    display: 'grid',
    gridTemplateColumns: 'minMax(0, 2fr) minmax(0,1fr)',
    gridColumnGap: 24,
  },
}));

const DateAndTimeDialog = ({
  translations,
  isOpened,
  initialDate,
  fields,
  onClose,
  onSave,
}) => {
  const classes = useStyles();

  const [date, setDate] = useState(initialDate);

  useEffect(() => {
    setDate(initialDate);
  }, [initialDate]);

  const handleSave = () => {
    onSave(date);
    setDate(initialDate);
  };

  const handleClose = () => {
    onClose();
    setDate(initialDate);
  };

  const handleChange = updatedDate => setDate(updatedDate);

  return (
    <CustomModal
      title={translations.title}
      confirmButtonLabel={translations.save}
      closeButtonLabel={translations.close}
      isOpened={isOpened}
      onClose={handleClose}
      onConfirm={handleSave}
      isMedium
    >
      <div>
        {translations.description && (
          <Typography className={classes.description}>
            {translations.description}
          </Typography>
        )}
        <div className={classes.fieldsWrapper}>
          {fields.map(field => {
            if (field.type === FIELD_TYPES.DATE_PICKER) {
              return (
                <CustomDatePicker
                  key={field.name}
                  name={field.name}
                  label={
                    translations.formLabels[field.translationKey || field.name]
                      ?.label
                  }
                  placeholder={
                    translations.formLabels[field.translationKey || field.name]
                      ?.placeholder
                  }
                  tooltipInfo={
                    translations.formLabels[field.translationKey || field.name]
                      ?.tooltipInfo
                  }
                  value={date}
                  required={field.required}
                  disableFuture={field.disableFuture}
                  disablePast={field.isPastDisabled}
                  disableToolbar={field.isToolbarDisabled}
                  minDate={field.getMinDate ? field.getMinDate() : undefined}
                  onChange={handleChange}
                  fullWidth
                  autoOk
                  isIconOnStart
                />
              );
            }
            return (
              <CustomTimePicker
                key={field.name}
                label={
                  translations.formLabels[field.translationKey || field.name]
                    ?.label
                }
                selectedTime={date}
                minuteStep={field.minuteStep}
                onChange={handleChange}
              />
            );
          })}
        </div>
      </div>
    </CustomModal>
  );
};

DateAndTimeDialog.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  initialDate: PropTypes.object.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default memo(DateAndTimeDialog);
