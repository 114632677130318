import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import AsyncListTooltip from '../../../asyncListTooltip';
import Tooltip from '../../../tooltip';
import { ReactComponent as PrivateIcon } from '../../../../../assets/icons/anonymous-user.svg';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    width: 20,
    height: 18,
    marginRight: spacing(2),
  },
  icon: {
    width: 18,
    height: 18,
  },
}));

const PrivateNote = ({ title, helpTooltip, note, itemComponent }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AsyncListTooltip
        position="top"
        title={title}
        labelData={note}
        renderLabelItem={itemComponent}
        isDisabled
      >
        <Tooltip
          customIconClass={classes.icon}
          text={helpTooltip}
          icon={PrivateIcon}
        />
      </AsyncListTooltip>
    </div>
  );
};

PrivateNote.propTypes = {
  title: PropTypes.string.isRequired,
  helpTooltip: PropTypes.string.isRequired,
  note: PropTypes.arrayOf(PropTypes.string).isRequired,
  itemComponent: PropTypes.func.isRequired,
};

export default PrivateNote;
