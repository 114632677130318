import { connect } from 'react-redux';
import { signUpSelector } from '../../store/selectors/signUpSelector';
import {
  clearSignUp,
  setSignUp,
  validateOrganizationName,
} from '../../store/modules/signUp';
import { useTranslations } from '../../utility/useTranslations';
import { APP_PAGES } from '../../constants/pages';
import SignUpPage from '../../components/pages/signUp';

const mapStateToProps = state => ({
  ...signUpSelector(state),
});

const mapDispatchToProps = dispatch => ({
  setSignUp: data => dispatch(setSignUp(data)),
  clearSignUp: () => dispatch(clearSignUp()),
  validateOrganizationName: name => validateOrganizationName(name),
});

const SignUpPageView = props => {
  const translations = useTranslations(APP_PAGES.SIGN_UP);

  return <SignUpPage {...props} translations={translations} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPageView);
