import { connect } from 'react-redux';
import SurveysPage from '../../components/pages/surveysPage';
import PageContainer from '../../components/shared/pageContainer';
import {
  getSurveys,
  clearSurveys,
  getSurveyGroups,
  clearSurveyGroups,
  getSurveyTrackingById,
  getAllUsers,
  clearAllUsers,
  getAttributesWithQuestions,
  clearAttributesWithQuestions,
  getAllSurveyGroups,
  clearAllSurveyGroups,
  setGroupSurveys,
  deleteSurveyFromGroup,
} from '../../store/modules/surveys';
import { editSurvey, clearSurvey } from '../../store/modules/survey';
import { getAttribute, clearAttribute } from '../../store/modules/attribute';
import { setIsInlineSurvey } from '../../store/modules/fillSurvey';
import { surveysSelector } from '../../store/selectors/surveysSelector';
import { attributeSelector } from '../../store/selectors/attributeSelector';
import { dialogSelector } from '../../store/selectors/dialogSelector';
import { pendingSurveysSelector } from '../../store/selectors/pendingSurveysSelector';
import {
  clearPendingSurveys,
  getPendingSurveys,
} from '../../store/modules/pendingSurveys';
import { useTranslations } from '../../utility/useTranslations';
import { APP_PAGES } from '../../constants/pages';

const mapStateToProps = state => ({
  ...surveysSelector(state),
  ...dialogSelector(state),
  ...pendingSurveysSelector(state),
  ...attributeSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getSurveys: (isLoadMore, data) => getSurveys(dispatch, isLoadMore, data),
  getSurveyGroups: (isLoadMore, data) =>
    getSurveyGroups(dispatch, isLoadMore, data),
  getAllUsers: () => getAllUsers(dispatch),
  getAllSurveyGroups: () => getAllSurveyGroups(dispatch),
  setGroupSurveys: data => dispatch(setGroupSurveys(data)),
  clearAllUsers: () => dispatch(clearAllUsers()),
  getTrackingById: async id => getSurveyTrackingById(id),
  clearSurveys: () => dispatch(clearSurveys()),
  setIsInlineSurvey: isInline => dispatch(setIsInlineSurvey(isInline)),
  getPendingSurveys: () => getPendingSurveys(dispatch),
  clearPendingSurveys: () => clearPendingSurveys(dispatch),
  deleteSurveyFromGroup: data => dispatch(deleteSurveyFromGroup(data)),
  getAttributesWithQuestions: params =>
    getAttributesWithQuestions(dispatch, params),
  clearAttributesWithQuestions: () => dispatch(clearAttributesWithQuestions()),
  getAttribute: id => getAttribute(dispatch, id),
  clearAttribute: () => dispatch(clearAttribute()),
  editSurvey: (surveyId, data) => editSurvey(dispatch, surveyId, data),
  clearSurvey: () => dispatch(clearSurvey()),
  clearSurveyGroups: () => dispatch(clearSurveyGroups()),
  clearAllSurveyGroups: () => dispatch(clearAllSurveyGroups()),
});

const SurveysPageView = props => {
  const translations = useTranslations(APP_PAGES.SURVEYS);

  return (
    <PageContainer {...props} translations={translations} isFullWidthContent>
      <SurveysPage />
    </PageContainer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveysPageView);
