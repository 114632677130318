import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';

const styles = ({ breakpoints, palette: { primary }, spacing }) => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  progress: {
    width: 50,
    [breakpoints.up('xLg')]: {
      width: 60,
    },
  },
  progressRoot: {
    height: 7,
  },
  progressBackground: {
    backgroundColor: primary.bluish6,
  },
  barColor: {
    backgroundColor: primary.blue1,
  },
  barColorSecondary: {
    backgroundColor: primary.bluish1,
  },
  barColorTernary: {
    backgroundColor: primary.blue3,
  },
  label: {
    color: primary.bluish2,
    minWidth: 40,
    textAlign: 'center',
    marginLeft: spacing(4),
  },
});

const BG_COLOR_TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERNARY: 'ternary',
};

class ProgressBarWithLabel extends PureComponent {
  render() {
    const { classes, customLabelClass, value, barColorType } = this.props;

    return (
      <div className={classes.main}>
        <div className={classes.progress}>
          <LinearProgress
            classes={{
              root: classes.progressRoot,
              colorPrimary: classes.progressBackground,
              barColorPrimary: classNames({
                [classes.barColor]: barColorType === BG_COLOR_TYPES.PRIMARY,
                [classes.barColorSecondary]:
                  barColorType === BG_COLOR_TYPES.SECONDARY,
                [classes.barColorTernary]:
                  barColorType === BG_COLOR_TYPES.TERNARY,
              }),
            }}
            variant="determinate"
            value={value}
          />
        </div>
        <Typography
          variant="subtitle1"
          className={classNames(classes.label, customLabelClass)}
        >
          {`${value}%`}
        </Typography>
      </div>
    );
  }
}

ProgressBarWithLabel.defaultProps = {
  barColorType: 'primary',
  value: 0,
  customLabelClass: null,
};

ProgressBarWithLabel.propTypes = {
  classes: PropTypes.object.isRequired,
  customLabelClass: PropTypes.string,
  value: PropTypes.number,
  barColorType: PropTypes.oneOf(['primary', 'secondary', 'ternary']),
};

export default withStyles(styles)(ProgressBarWithLabel);
