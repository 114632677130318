import { cloneElement, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import PageHeader from '../pageHeader';
import {
  SIDEBAR_WIDTH,
  SIDEBAR_WIDTH_SMALL,
} from '../../../constants/sidebarNavigation';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    marginLeft: SIDEBAR_WIDTH_SMALL,
    width: `calc(100vw - ${SIDEBAR_WIDTH_SMALL}px)`,
    [breakpoints.up('xl')]: {
      marginLeft: SIDEBAR_WIDTH,
      width: `calc(100vw - ${SIDEBAR_WIDTH}px)`,
    },
  },
  mainWrapper: {
    display: 'flex',
    height: '100vh',
  },
  main: {
    flexGrow: 1,
    padding: spacing(0, 4.5, 0, 4.5),
    height: '100%',
    overflowY: 'auto',
    [breakpoints.up('xLg')]: {
      padding: spacing(0, 10, 0, 6),
    },
    [breakpoints.up('xl')]: {
      padding: spacing(0, 12, 0, 12),
    },
  },
  content: {
    width: '100%',
    maxWidth: 900,
    paddingBottom: spacing(12),
  },
  mediumWidthContent: {
    maxWidth: 1350,
  },
  fullWidthContent: {
    maxWidth: '100%',
  },
  noContentSpacing: {
    paddingBottom: 0,
  },
}));

const PageContainer = ({
  translations,
  children,
  location,
  navigate,
  auth,
  grantedPermissions,
  organizationSettings,
  headerProps,
  isLoading,
  personNavItems,
  isFullWidthContent,
  isMediumWidthContent,
  shouldRemoveSpacing,
  shouldPassProps,
  isPremiumUser,
  pageScrollRef,
  ...rest
}) => {
  const classes = useStyles();

  const goToProfilePage = userId => navigate(`/people/${userId}`);

  return (
    <div className={classes.root} id="pageContainer">
      <div className={classes.mainWrapper}>
        <div ref={pageScrollRef} className={classes.main}>
          {!isLoading && (
            <PageHeader
              translations={translations.pageHeader}
              navigate={navigate}
              location={location}
              title={translations.title}
              user={auth}
              grantedPermissions={grantedPermissions}
              organizationSettings={organizationSettings}
              isPremiumUser={isPremiumUser}
              navItems={personNavItems}
              onGoToProfile={goToProfilePage}
              {...headerProps}
            />
          )}
          <div
            className={classNames(classes.content, {
              [classes.mediumWidthContent]: isMediumWidthContent,
              [classes.fullWidthContent]: isFullWidthContent,
              [classes.noContentSpacing]: shouldRemoveSpacing,
            })}
          >
            {shouldPassProps
              ? cloneElement(children, {
                  location,
                  navigate,
                  translations,
                  auth,
                  grantedPermissions,
                  organizationSettings,
                  isPremiumUser,
                  goToProfilePage,
                  ...rest,
                })
              : children}
          </div>
        </div>
      </div>
    </div>
  );
};

PageContainer.defaultProps = {
  isPremiumUser: false,
  isFullWidthContent: false,
  isMediumWidthContent: false,
  personNavItems: [],
  headerProps: {},
  shouldRemoveSpacing: false,
  isLoading: false,
  shouldPassProps: true,
};

PageContainer.propTypes = {
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  translations: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  auth: PropTypes.object.isRequired,
  grantedPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  organizationSettings: PropTypes.object.isRequired,
  isFullWidthContent: PropTypes.bool,
  isMediumWidthContent: PropTypes.bool,
  isLoading: PropTypes.bool,
  isPremiumUser: PropTypes.bool,
  shouldRemoveSpacing: PropTypes.bool,
  shouldPassProps: PropTypes.bool,
  headerProps: PropTypes.shape({}),
  personNavItems: PropTypes.arrayOf(PropTypes.shape({})),
};

export default memo(PageContainer);
