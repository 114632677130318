import { createAction, handleActions } from 'redux-actions';
import http from '../../utility/http';
import { parseDuplicateParameters } from '../../utility/uiUtils';
import {
  API_MEASURED_ATTRIBUTES,
  API_ATTRIBUTES_SCORE_DISTRIBUTION,
  API_ATTRIBUTES_USER_SCORES,
} from '../../constants/apiRoutes';

// ------------------------------------
// Constants
// ------------------------------------

const SET_MEASURED_ATTRIBUTES = 'SET_MEASURED_ATTRIBUTES';
const SET_ATTRIBUTES_SCORE_DISTRIBUTION = 'SET_ATTRIBUTES_SCORE_DISTRIBUTION';
const SET_ATTRIBUTES_USER_SCORES = 'SET_ATTRIBUTES_USER_SCORES';

const initialState = {
  attributes: [],
  scoreDistribution: [],
  usersScores: [],
};

// ------------------------------------
// Actions
// ------------------------------------

const setMeasuredAttributes = createAction(SET_MEASURED_ATTRIBUTES);
const setAttributesScoreDistribution = createAction(
  SET_ATTRIBUTES_SCORE_DISTRIBUTION
);
const setAttributesUsersScores = createAction(SET_ATTRIBUTES_USER_SCORES);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_MEASURED_ATTRIBUTES]: (state, { payload }) => {
    return { ...state, attributes: payload };
  },
  [SET_ATTRIBUTES_SCORE_DISTRIBUTION]: (state, { payload }) => {
    return {
      ...state,
      scoreDistribution: payload,
    };
  },
  [SET_ATTRIBUTES_USER_SCORES]: (state, { payload }) => {
    return {
      ...state,
      usersScores: payload,
    };
  },
};

// ------------------------------------
// API calls
// ------------------------------------

export const getMeasuredAttributes = (dispatch, params) => {
  return http
    .get(API_MEASURED_ATTRIBUTES, {
      params,
      paramsSerializer: data => parseDuplicateParameters(data),
    })
    .then(({ data }) => {
      dispatch(setMeasuredAttributes(data));

      return data;
    });
};

export const getAttributesScoreDistribution = (dispatch, params) => {
  return http
    .get(API_ATTRIBUTES_SCORE_DISTRIBUTION, {
      params,
      paramsSerializer: d => {
        return parseDuplicateParameters(d);
      },
    })
    .then(({ data }) => {
      return dispatch(setAttributesScoreDistribution(data));
    });
};

export const getAttributesUsersScores = (dispatch, params) => {
  return http
    .get(API_ATTRIBUTES_USER_SCORES, {
      params,
      paramsSerializer: data => parseDuplicateParameters(data),
    })
    .then(({ data }) => {
      return dispatch(setAttributesUsersScores(data));
    });
};

// ------------------------------------
// "plain" action functions
// ------------------------------------

export const clearMeasuredAttributes = () =>
  setMeasuredAttributes(initialState.attributes);

export const clearAttributesScoreDistribution = () =>
  setAttributesScoreDistribution(initialState.scoreDistribution);

export const clearAttributesUsersScores = () =>
  setAttributesUsersScores(initialState.usersScores);

export default handleActions(reducers, initialState);
