import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import { ReactComponent as TrendIcon } from '../../../../assets/icons/arrow_up_thick.svg';
import { IMPRESSION_VALUES } from '../../../../constants/oneOnOne';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  impression: {
    backgroundColor: ({ value }) => IMPRESSION_VALUES[value - 1].color,
    boxSizing: 'border-box',
    borderRadius: '50%',
    boxShadow: 'none',
    cursor: 'pointer',
    color: primary.bluish3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 60,
    width: 60,
    flexShrink: 0,
    transition: 'box-shadow .3s ease',
    userSelect: 'none',
  },
  disabled: {
    opacity: 0.5,
    cursor: 'default',
  },
  active: {
    boxShadow: `0 0 0 3px ${primary.white}, 0 0 0 6px ${primary.green6}`,
  },
  positiveColor: {
    color: primary.white,
  },
  medium: {
    height: 32,
    width: 32,
  },
  small: {
    height: 20,
    width: 20,
  },
  trendIcon: {
    flexShrink: 0,
    marginRight: spacing(1),
    transform: ({ value, previousImpression }) =>
      value < previousImpression ? 'rotate(180deg)' : 'rotate(0deg)',
    '& path': {
      fill: ({ value }) => IMPRESSION_VALUES[value - 1].color,
    },
  },
}));

const NEGATIVE_EDGE_VALUE = 4;

const Impression = ({
  className,
  value,
  isDisabled,
  isActive,
  previousImpression,
  isMedium,
  isSmall,
  onSelect,
}) => {
  const classes = useStyles({ value, previousImpression });

  const variant = useMemo(() => {
    if (isMedium) return 'subtitle1';
    if (isSmall) return 'overline';

    return 'h2';
  }, [isMedium, isSmall]);

  const handleSelect = () => {
    if (!isDisabled) {
      onSelect(value);
    }
  };

  return (
    <div className={classes.root}>
      {previousImpression && previousImpression !== value && (
        <TrendIcon className={classes.trendIcon} />
      )}
      <Typography
        variant={variant}
        component="div"
        className={classNames(
          classes.impression,
          {
            [classes.small]: isSmall,
            [classes.medium]: isMedium,
            [classes.positiveColor]: value > NEGATIVE_EDGE_VALUE,
            [classes.disabled]: isDisabled,
            [classes.active]: isActive,
          },
          className
        )}
        onClick={handleSelect}
      >
        {value}
      </Typography>
    </div>
  );
};

Impression.defaultProps = {
  className: null,
  previousImpression: null,
  isActive: false,
  isDisabled: false,
  isMedium: false,
  isSmall: false,
  onSelect: () => {},
};

Impression.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number.isRequired,
  previousImpression: PropTypes.number,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isMedium: PropTypes.bool,
  isSmall: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default memo(Impression);
