export const ellipsis = () => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

export const sticky = (bgColor, topDistance = 0, zIndex = 999) => ({
  position: 'sticky',
  zIndex,
  background: bgColor,
  top: topDistance,
});
