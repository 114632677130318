import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Answers from '../answers';

const styles = ({ spacing }) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  questionTitle: {
    wordBreak: 'break-word',
    marginBottom: spacing(6),
  },
  verticalWeightLabel: {
    fontSize: 12,
    fontFamily: 'ProximaNova-Bold',
    justifyContent: 'end',
    display: 'flex',
    marginBottom: spacing(6),
    marginRight: spacing(2),
  },
});

class AttributeQuestion extends PureComponent {
  render() {
    const {
      className,
      translations,
      classes,
      question,
      isVertical,
      isSingleReview,
      withTextReplyQuestions,
      isPreview,
      isAttributePreview,
      hasNeutralAnswer,
      results,
      answerReplyErrors,
      subjects,
      onSetAnswerReplyErrors,
      onAnswerSelect,
      onAnswerChange,
    } = this.props;

    return (
      <div className={classNames(classes.main, className)}>
        <Typography className={classes.questionTitle} variant="h3">
          {question.text}
        </Typography>
        {isVertical && isAttributePreview && !withTextReplyQuestions && (
          <div className={classes.verticalWeightLabel}>
            {translations.weight}
          </div>
        )}
        <Answers
          translations={translations}
          questionId={question.id}
          isVertical={isVertical}
          answers={question.answers}
          subjects={subjects}
          hasNeutralAnswer={hasNeutralAnswer}
          isSingleReview={isSingleReview}
          withTextReplyQuestions={withTextReplyQuestions}
          isPreview={isPreview}
          isAttributePreview={isAttributePreview}
          results={results}
          answerReplyErrors={answerReplyErrors}
          onSetAnswerReplyErrors={onSetAnswerReplyErrors}
          onSelect={onAnswerSelect}
          onChange={onAnswerChange}
        />
      </div>
    );
  }
}

AttributeQuestion.defaultProps = {
  className: null,
  subjects: [],
  isSingleReview: false,
  withTextReplyQuestions: false,
  hasNeutralAnswer: false,
  isPreview: false,
  isAttributePreview: false,
  results: {},
  answerReplyErrors: {},
  onSetAnswerReplyErrors: () => {},
  onAnswerSelect: () => {},
  onAnswerChange: () => {},
};

AttributeQuestion.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.shape({}).isRequired,
  classes: PropTypes.object.isRequired,
  isVertical: PropTypes.bool.isRequired,
  isSingleReview: PropTypes.bool,
  answerReplyErrors: PropTypes.shape({}),
  withTextReplyQuestions: PropTypes.bool,
  isPreview: PropTypes.bool,
  isAttributePreview: PropTypes.bool,
  hasNeutralAnswer: PropTypes.bool,
  question: PropTypes.shape({}).isRequired,
  subjects: PropTypes.arrayOf(PropTypes.shape({})),
  results: PropTypes.shape({}),
  onSetAnswerReplyErrors: PropTypes.func,
  onAnswerSelect: PropTypes.func,
  onAnswerChange: PropTypes.func,
};

export default withStyles(styles)(AttributeQuestion);
