import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import { getAnswerWeight } from 'utility/attribute';
import { NEUTRAL_ANSWER_WEIGHT } from 'components/shared/questionEntry/config';
import SelectableUserList from '../../selectableUserList';
import { isArray, isArrayEmpty } from '../../../../utility/helpers';
import { shouldRenderSubject } from '../../../../utility/fillSurveyUtils';

const styles = ({ palette: { primary }, spacing }) => ({
  main: {
    boxSizing: 'border-box',
    border: `1px solid ${primary.bluish6}`,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: spacing(2),
    userSelect: 'none',
    '&:last-of-type': {
      marginBottom: 0,
    },
    '&.selected': {
      backgroundColor: primary.blue6,
      '& $answerOrder': {
        backgroundColor: primary.blue1,
        border: 'none',
        color: primary.white,
      },
    },
  },
  mainBlock: {
    marginBottom: spacing(2),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  answerWrapper: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'flex-start',
    padding: spacing(4),
  },
  singleSelect: {
    cursor: 'pointer',
  },
  singleSelectDisabled: {
    cursor: 'default',
  },
  answerOrder: {
    boxSizing: 'border-box',
    border: `1px solid ${primary.bluish6}`,
    borderRadius: 8,
    lineHeight: '18px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    marginRight: spacing(4),
    height: 32,
    width: 32,
  },
  answerText: {
    lineHeight: '18px',
    flexGrow: 1,
    alignSelf: 'center',
    wordWrap: 'break-word',
  },
  weightLabel: {
    width: '91.5%',
  },
  block: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  answerWeight: {
    marginBottom: spacing(2),
    fontSize: 12,
    fontFamily: 'ProximaNova-Regular',
  },
});

class VerticalAnswer extends PureComponent {
  handleSingleSelect = (answerId, user) => () => {
    const { questionId, isDisabled, onSelect } = this.props;

    if (isDisabled) return;

    onSelect(questionId, answerId, user);
  };

  handleMultipleSelect = answerId => (user, shouldDeleteAnswer) => {
    const { questionId, isDisabled, onSelect } = this.props;

    if (!isDisabled) {
      onSelect(questionId, answerId, user, shouldDeleteAnswer);
    }
  };

  isSubjectVisible = answerId => user => {
    const { questionId, selection } = this.props;

    return shouldRenderSubject(questionId, answerId, selection, user);
  };

  render() {
    const {
      classes,
      answer,
      isSingleReview,
      isDisabled,
      isAttributePreview,
      neutralAnswerLabel,
      subjects,
      selection,
      getSelectedAnswer,
    } = this.props;
    const [subject] = subjects;
    const isSelected = getSelectedAnswer(selection, answer.id, subject);
    const answerWeight = getAnswerWeight(answer.weight);

    return (
      <div
        className={classNames({
          [classes.block]: isAttributePreview,
          [classes.mainBlock]: !isAttributePreview,
        })}
      >
        <div
          className={classNames(classes.main, {
            [classes.singleSelect]: isSingleReview,
            [classes.singleSelectDisabled]: isDisabled,
            [classes.weightLabel]: isAttributePreview,
            selected: isSingleReview && isSelected,
          })}
          onClick={
            isSingleReview && !isSelected
              ? this.handleSingleSelect(answer.id, subject)
              : undefined
          }
        >
          <div className={classes.answerWrapper}>
            <Typography
              className={classes.answerOrder}
              variant="subtitle1"
              component="div"
            >
              {answer.order <= 9 ? `0${answer.order}` : answer.order}
            </Typography>
            <Typography className={classes.answerText} variant="subtitle1">
              {answerWeight === NEUTRAL_ANSWER_WEIGHT
                ? neutralAnswerLabel
                : answer.text}
            </Typography>
          </div>
          {!isSingleReview && isArray(subjects) && !isArrayEmpty(subjects) && (
            <SelectableUserList
              users={subjects}
              selectionKey={answer.id}
              selection={selection}
              shouldRenderUser={this.isSubjectVisible(answer.id)}
              isDisabled={isDisabled}
              getSelectedUser={getSelectedAnswer}
              onSelect={this.handleMultipleSelect(answer.id)}
            />
          )}
        </div>
        {isAttributePreview && (
          <div className={classes.answerWeight}>
            {answerWeight !== NEUTRAL_ANSWER_WEIGHT
              ? getAnswerWeight(answer.weight)
              : ''}
          </div>
        )}
      </div>
    );
  }
}

VerticalAnswer.defaultProps = {
  subjects: [],
  neutralAnswerLabel: '',
  isSingleReview: false,
  isDisabled: false,
  isAttributePreview: false,
  questionId: 0,
  selection: {},
  getSelectedAnswer: () => {},
  onSelect: () => {},
};

VerticalAnswer.propTypes = {
  classes: PropTypes.object.isRequired,
  answer: PropTypes.shape({}).isRequired,
  subjects: PropTypes.arrayOf(PropTypes.shape({})),
  questionId: PropTypes.number,
  isSingleReview: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isAttributePreview: PropTypes.bool,
  neutralAnswerLabel: PropTypes.string,
  selection: PropTypes.shape({}),
  getSelectedAnswer: PropTypes.func,
  onSelect: PropTypes.func,
};

export default withStyles(styles)(VerticalAnswer);
