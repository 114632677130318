import { addMonths } from 'date-fns';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar-icon-gray.svg';
import {
  isEmpty,
  forbiddenCharacters,
  validateLength,
} from '../../../utility/validation';
import { getPersonFullName } from '../../../utility/uiUtils';
import { formatDate } from '../../../utility/dateUtils';
import {
  isObjectEmpty,
  // isUserAppAccessForbidden,
  // isItemInList,
  getObjectToNumberArray,
} from '../../../utility/helpers';
import { ACTION_PLAN_FIELDS } from '../../../constants/actionPlan';
import { FIELD_TYPES } from '../../../constants/fieldTypes';
import { CREATABLE_TAG_FIELD } from '../../../constants/tags';

const { TEXT, SELECT, DATE_PICKER, SLIDER, ADD_COURSES } = FIELD_TYPES;

const DEFAULT_STATUS = false;
const DATE_FORMAT = 'dd MMM y';

const {
  COURSES,
  CREATOR,
  DESCRIPTION,
  DUE_DATE,
  STATUS,
  // NOTIFY,
  CREATED_ON,
  USER,
  TAGS,
  TASKS,
  TITLE,
  PROGRESS,
  IS_PENDING_PROPOSAL,
} = ACTION_PLAN_FIELDS;

export const INITIAL_DATA = {
  [TITLE]: '',
  [USER]: {},
  [DESCRIPTION]: '',
  [TAGS]: [],
  [DUE_DATE]: addMonths(new Date(), 1),
  [STATUS]: DEFAULT_STATUS,
  [TASKS]: [],
  [PROGRESS]: 0,
  [COURSES]: [],
  // [NOTIFY]: [],
};

// const getNotificationItemLabel = (translations, currentUserId, user = {}) => {
//   if (user.id === currentUserId) {
//     return `${getPersonFullName(user)} (${translations.me})`;
//   }

//   return getPersonFullName(user);
// };

// const getUsersToNotify =
//   (translations, currentUser, actionPlan = {}) =>
//   values => {
//     const creator = actionPlan[CREATOR];
//     const user = values[USER];
//     const users = [];

//     if (!isItemInList(users, currentUser) && !actionPlan?.id) {
//       users.push({
//         ...currentUser,
//         label: getNotificationItemLabel(
//           translations,
//           currentUser.id,
//           currentUser
//         ),
//         isDisabled: false,
//       });
//     }

//     if (creator && !isObjectEmpty(creator) && !isItemInList(users, creator)) {
//       users.push({
//         ...creator,
//         label: getNotificationItemLabel(translations, currentUser.id, creator),
//         isDisabled: isUserAppAccessForbidden(creator),
//       });
//     }

//     if (user && !isObjectEmpty(user) && !isItemInList(users, user)) {
//       users.push({
//         ...user,
//         label: getNotificationItemLabel(translations, currentUser.id, user),
//         isDisabled: isUserAppAccessForbidden(user),
//       });
//     }

//     return users;
//   };

export const getInitialData = user => {
  return {
    [TITLE]: '',
    [USER]: isObjectEmpty(user) ? {} : user,
    [DESCRIPTION]: '',
    [TAGS]: [],
    [DUE_DATE]: addMonths(new Date(), 1),
    [STATUS]: DEFAULT_STATUS,
    [TASKS]: [],
    [PROGRESS]: 0,
    [COURSES]: [],
    // [NOTIFY]: [],
  };
};

export const getInitialEditData = actionPlan => ({
  id: actionPlan.id,
  [TITLE]: actionPlan[TITLE],
  [USER]: actionPlan[USER],
  [DESCRIPTION]: actionPlan[DESCRIPTION] || '',
  [DUE_DATE]: actionPlan[DUE_DATE],
  [STATUS]: actionPlan[STATUS],
  [TAGS]: getObjectToNumberArray(actionPlan[TAGS]),
  [TASKS]: actionPlan[TASKS],
  [PROGRESS]: actionPlan[PROGRESS],
  [COURSES]: actionPlan[COURSES] ?? [],
  // [NOTIFY]: [
  //   ...(actionPlan[CREATOR] && !isUserAppAccessForbidden(actionPlan[CREATOR])
  //     ? [actionPlan[CREATOR]?.id]
  //     : []),
  //   actionPlan[USER].id,
  // ],
  [CREATED_ON]: formatDate(actionPlan[CREATED_ON], DATE_FORMAT),
  [CREATOR]: getPersonFullName(actionPlan[CREATOR]),
});

export const getAddActionPlanFields = (
  classes,
  // translations,
  // currentUser,
  user,
  hasCreatedForField,
  isPlanProposal,
  onCreateTag,
  getCourses,
  getCourseEnrolledUsers,
  onCoursesSearch
) => {
  return [
    ...(hasCreatedForField
      ? [
          {
            name: USER,
            type: SELECT,
            translationKey: 'user',
            required: true,
            fieldWrapperClass: classes.user,
            optionsKey: 'allUsers',
            isUser: true,
            shouldReturnOption: true,
            parser: {
              value: 'id',
              label: 'first_name',
            },
            isDisabled: !isObjectEmpty(user),
            // dependants: [
            //   {
            //     name: NOTIFY,
            //     resetFnc: (previousUser, selectedUser, usersToNotify) => {
            //       return usersToNotify.filter(
            //         userId =>
            //           selectedUser.id === userId || currentUser.id === userId
            //       );
            //     },
            //   },
            // ],
            validators: isObjectEmpty(user)
              ? [
                  {
                    type: 'required',
                    validator: value => !isObjectEmpty(value),
                  },
                ]
              : [],
          },
        ]
      : []),
    {
      name: TITLE,
      type: TEXT,
      translationKey: 'title',
      fieldWrapperClass: classes.title,
      required: true,
      validators: [
        { type: 'required', validator: isEmpty },
        { type: 'forbiddenCharacters', validator: forbiddenCharacters },
        {
          type: 'titleMaxLength',
          validator: value => validateLength(value, 0, 200),
        },
      ],
    },
    {
      name: DUE_DATE,
      type: DATE_PICKER,
      fieldWrapperClass: classes.dueDate,
      translationKey: 'dueDate',
      disableFuture: false,
      validators: [{ type: 'required', validator: isEmpty }],
    },
    ...(!isPlanProposal
      ? [
          {
            name: STATUS,
            type: SELECT,
            fieldWrapperClass: classes.status,
            translationKey: 'status',
            optionsKey: 'statuses',
            isSearchDisabled: true,
            validators: [{ type: 'required', validator: isEmpty }],
          },
        ]
      : []),
    {
      name: 'tags',
      type: SELECT,
      fieldWrapperClass: classes.tags,
      optionsKey: 'categories',
      creatableOptionField: CREATABLE_TAG_FIELD,
      isCategorized: true,
      hasCategoryColorBox: true,
      isMulti: true,
      hasHash: true,
      isCreatable: true,
      isTag: true,
      parser: {
        value: 'id',
        label: 'name',
        categoryLabel: 'name',
        categoryValue: 'tags',
      },
      onCreateOption: onCreateTag,
    },
    // {
    //   name: NOTIFY,
    //   type: CHECKBOX_GROUP,
    //   translationKey: NOTIFY,
    //   fieldWrapperClass: classes.notify,
    //   getItems: getUsersToNotify(translations, currentUser, {}),
    // },
    {
      name: DESCRIPTION,
      type: FIELD_TYPES.RICH_TEXT_EDITOR,
      translationKey: 'description',
      fieldWrapperClass: classes.description,
      required: true,
      validators: [
        {
          type: 'descriptionMaxLength',
          validator: value => validateLength(value, 0, 20000),
        },
      ],
    },
    {
      name: COURSES,
      type: ADD_COURSES,
      fieldWrapperClass: classes.courses,
      optionsKey: 'courses',
      getCourses,
      getCourseEnrolledUsers,
      onCoursesSearch,
    },
    ...(!isPlanProposal
      ? [
          {
            name: PROGRESS,
            type: SLIDER,
            translationKey: 'completeness',
            fieldWrapperClass: classes.progress,
          },
        ]
      : []),
  ];
};

export const getEditActionPlanFields = (
  classes,
  // translations,
  // currentUser,
  hasCreatedForField,
  // actionPlan,
  isEditProposal,
  hasCreator,
  onCreateTag,
  getCourses,
  getCourseEnrolledUsers,
  onCoursesSearch
) => {
  return [
    ...(hasCreatedForField && !isEditProposal
      ? [
          {
            name: USER,
            translationKey: 'user',
            fieldWrapperClass: classes.user,
            type: SELECT,
            optionsKey: 'allUsers',
            isUser: true,
            shouldReturnOption: true,
            parser: {
              value: 'id',
              label: 'first_name',
            },
            isDisabled: true,
            validators: [],
          },
        ]
      : []),
    {
      name: TITLE,
      type: TEXT,
      translationKey: 'title',
      fieldWrapperClass: classes.title,
      required: true,
      validators: [
        { type: 'required', validator: isEmpty },
        { type: 'forbiddenCharacters', validator: forbiddenCharacters },
        {
          type: 'titleMaxLength',
          validator: value => validateLength(value, 0, 200),
        },
      ],
    },
    {
      name: DUE_DATE,
      translationKey: 'dueDate',
      fieldWrapperClass: classes.dueDate,
      type: DATE_PICKER,
      validators: [],
    },
    ...(!isEditProposal
      ? [
          {
            name: STATUS,
            translationKey: 'status',
            fieldWrapperClass: classes.status,
            type: SELECT,
            optionsKey: 'statuses',
            validators: [],
          },
        ]
      : []),
    {
      name: 'tags',
      type: SELECT,
      optionsKey: 'categories',
      fieldWrapperClass: classes.tags,
      creatableOptionField: CREATABLE_TAG_FIELD,
      isCategorized: true,
      hasCategoryColorBox: true,
      isMulti: true,
      isCreatable: true,
      hasHash: true,
      isTag: true,
      parser: {
        value: 'id',
        label: 'name',
        categoryLabel: 'name',
        categoryValue: 'tags',
      },
      onCreateOption: onCreateTag,
    },
    // {
    //   name: NOTIFY,
    //   translationKey: NOTIFY,
    //   fieldWrapperClass: classes.notify,
    //   type: CHECKBOX_GROUP,
    //   getItems: getUsersToNotify(translations, currentUser, actionPlan),
    // },
    ...(!isEditProposal && hasCreator
      ? [
          {
            name: CREATOR,
            translationKey: 'creator',
            fieldWrapperClass: classes.creator,
            isDisabled: true,
            type: TEXT,
          },
        ]
      : []),
    ...(!isEditProposal && hasCreator
      ? [
          {
            name: CREATED_ON,
            translationKey: 'createdOn',
            fieldWrapperClass: classes.createdOn,
            isDisabled: true,
            type: TEXT,
            adornmentIcon: CalendarIcon,
            isIconOnStart: true,
          },
        ]
      : []),
    {
      name: DESCRIPTION,
      translationKey: 'description',
      fieldWrapperClass: classes.description,
      type: FIELD_TYPES.RICH_TEXT_EDITOR,
      required: true,
      validators: [
        {
          type: 'descriptionMaxLength',
          validator: value => validateLength(value, 0, 20000),
        },
      ],
    },
    ...(!isEditProposal
      ? [
          {
            name: PROGRESS,
            type: SLIDER,
            translationKey: 'completeness',
            isDisabledCheck: values => values[STATUS],
            fieldWrapperClass: classes.progress,
          },
        ]
      : []),
    {
      name: COURSES,
      type: ADD_COURSES,
      fieldWrapperClass: classes.courses,
      optionsKey: 'courses',
      getCourses,
      getCourseEnrolledUsers,
      onCoursesSearch,
    },
  ];
};

export const getEditActionPlanSuccessMessage = (
  translations,
  actionPlan,
  currentUser
) => {
  if (actionPlan[IS_PENDING_PROPOSAL]) {
    if (actionPlan[USER].id !== currentUser?.id) {
      return translations.successAcceptProposal;
    }

    return translations.successEditProposal;
  }

  return translations.successEdit;
};

export const getTitle = (translations, isPlanProposal, isPendingProposal) => {
  if (isPlanProposal) {
    return translations.proposalTitle;
  }

  if (isPendingProposal && !isPlanProposal) {
    return translations.acceptProposalTitle;
  }
  return '';
};
