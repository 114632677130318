import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import OneOnOneTimeIndicator from '../../oneOnOneTimeIndicator';
import UserLink from '../../userLink';
import CustomButton from '../../customButton';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  title: {
    color: primary.bluish4,
    marginBottom: spacing(2),
  },
  user: {
    margin: spacing(3, 0),
  },
  requestedOn: {
    color: primary.bluish4,
    marginBottom: spacing(2),
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  acceptButton: {
    marginLeft: spacing(-2),
  },
  dismissButton: {
    color: primary.bluish1,
  },
}));

const OneOnOneRequestCard = ({
  translations,
  request,
  onAccept,
  onDismiss,
}) => {
  const classes = useStyles();

  const { user, isAccessible, requestedOn } = request;

  const handleAccept = useCallback(() => {
    return onAccept(request.id);
  }, [request, onAccept]);

  const handleDismiss = useCallback(() => {
    onDismiss(request.id);
  }, [request, onDismiss]);

  return (
    <div>
      <Typography className={classes.title} variant="caption">
        {translations.title.toUpperCase()}
      </Typography>
      <OneOnOneTimeIndicator labels={translations.statuses} />
      <UserLink
        className={classes.user}
        user={user}
        isUserAccessible={isAccessible}
      />
      <Typography
        className={classes.requestedOn}
        variant="caption"
      >{`${translations.requestedOn}: ${requestedOn}`}</Typography>
      <div className={classes.actions}>
        <CustomButton
          className={classes.acceptButton}
          type="textRoundedSmall"
          onClick={handleAccept}
        >
          {translations.accept}
        </CustomButton>
        <CustomButton
          className={classes.dismissButton}
          type="textRoundedSmall"
          onClick={handleDismiss}
        >
          {translations.dismiss}
        </CustomButton>
      </div>
    </div>
  );
};

OneOnOneRequestCard.defaultProps = {
  request: {},
};

OneOnOneRequestCard.propTypes = {
  request: PropTypes.object,
};

export default OneOnOneRequestCard;
