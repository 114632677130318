import { GROUPED_SURVEY_REPORTING_TYPES } from 'constants/survey';
import { areArraysContentsEqual, isArrayEmpty } from './helpers';

export const NO_ACTIVE_PRESET = -1;
export const MAX_PRESETS = 10;

export const getActivePresetId = (presets, presetLocation) =>
  presets?.active && presets?.active[presetLocation]
    ? presets?.active[presetLocation]
    : NO_ACTIVE_PRESET;

export const getFilteredPresets = (presets, presetLocation) =>
  presets[presetLocation] ? presets[presetLocation] : [];

export const getActivePresetName = (filteredPresets, activePresetId) =>
  filteredPresets
    ?.filter(preset => preset.id === activePresetId)
    .map(preset => preset.name)[0];

export const getPlaceholderName = (
  handledPresetName,
  presetName,
  startSearch
) => handledPresetName || presetName || startSearch;

export const handlePresetName = (
  filteredPresets,
  activePresetId,
  presetName,
  state,
  label
) => {
  const { defaultPlaceholder, presetPlaceholderName, isLoading } = state;

  if (!isLoading) {
    if (
      (defaultPlaceholder && !presetName) ||
      (activePresetId === -1 && !isArrayEmpty(filteredPresets))
    ) {
      return label;
    }

    return presetName || presetPlaceholderName;
  }
};

export const presetPeriod = preset => {
  return preset
    ? {
        start: preset?.from_date,
        end: preset?.to_date,
      }
    : null;
};

export const getSurveyType = (surveyType, presetSurveyType) =>
  surveyType?.find(groupedType =>
    areArraysContentsEqual(groupedType.values, presetSurveyType)
  );

export const handleHasMaxPresets = (filteredPresets, translation) =>
  filteredPresets?.length >= MAX_PRESETS
    ? translation.presetConditionTooltip
    : '';

export const handleDisabledPresetMessage = (data, translations) => {
  const [PeerTeamAndDirect] = GROUPED_SURVEY_REPORTING_TYPES;

  return data?.subject &&
    areArraysContentsEqual(data?.survey_type, PeerTeamAndDirect.values) &&
    (!data?.reviewer || isArrayEmpty(data?.reviewer))
    ? translations.comparePresetConditionTooltip
    : '';
};
