import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ellipsis } from '../../../../constants/helperCssRules';

const styles = ({ palette: { primary }, spacing }) => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: props =>
      props.isSurveyStarted ? 'flex-start' : 'space-between',
    marginBottom: props => (props.isSurveyStarted ? spacing(3) : spacing(4)),
  },
  reviewName: {
    wordBreak: 'break-word',
    ...ellipsis(),
  },
  completionTime: {
    color: primary.bluish2,
    marginLeft: spacing(3),
  },
  numberOfReviews: {
    color: primary.bluish3,
    marginLeft: spacing(1),
    flexShrink: 0,
  },
});

class SurveyInfo extends PureComponent {
  render() {
    const { classes, survey, surveyStep } = this.props;

    return (
      <div className={classes.main}>
        <Typography
          className={classes.reviewName}
          variant="subtitle2"
          component="h4"
        >
          {survey.attributes[surveyStep].name}
        </Typography>
        <Typography variant="subtitle2" className={classes.numberOfReviews}>
          {`(${surveyStep + 1}/${survey.totalReviews})`}
        </Typography>
      </div>
    );
  }
}

SurveyInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  survey: PropTypes.shape({}).isRequired,
  surveyStep: PropTypes.number.isRequired,
};

export default withStyles(styles)(SurveyInfo);
