import { useState, useMemo, useCallback, useEffect, memo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Typography, makeStyles } from '@material-ui/core';
import AlertDialog from '../../shared/alertDialog';
import PageContainer from '../../shared/pageContainer';
import UserCompare from '../../shared/userCompare';
import NotificationCard from '../../shared/notificationCard';
import SelectField from '../../shared/selectField';
import PresetDropdown from '../../shared/presetDropdown';
import http from '../../../utility/http';
import {
  parseDuplicateParameters,
  showSuccessMessage,
} from '../../../utility/uiUtils';
import { getAllUsers, clearAllUsers } from '../../../store/modules/people';
import { getJobTitles, clearJobTitles } from '../../../store/modules/jobTitles';
import {
  getTagsCategories,
  clearTagsCategories,
} from '../../../store/modules/tags';
import {
  getLevelsAndTracks,
  clearLevelsAndTracks,
} from '../../../store/modules/levelsAndTracks';
import {
  areArraysContentsEqual,
  getEmployees,
  getItemById,
  isArrayEmpty,
} from '../../../utility/helpers';
import {
  NO_ACTIVE_PRESET,
  getActivePresetId,
  getActivePresetName,
  getFilteredPresets,
  getPlaceholderName,
  handleDisabledPresetMessage,
  handleHasMaxPresets,
  handlePresetName,
} from '../../../utility/presets';
import { useTranslations } from '../../../utility/useTranslations';
import { peopleSelector } from '../../../store/selectors/peopleSelector';
import { jobTitlesSelector } from '../../../store/selectors/jobTitlesSelector';
import { levelsAndTracksSelector } from '../../../store/selectors/levelsAndTracksSelector';
import { tagsSelector } from '../../../store/selectors/tagsSelector';
import {
  addNewPreset,
  deleteSelectedPreset,
  editPresetActivity,
} from '../../../store/modules/organizationSettings';
import { APP_PAGES } from '../../../constants/pages';
import {
  API_USERS_BASIC,
  API_REPORT_USER_COMPARE,
} from '../../../constants/apiRoutes';
import { PRESETS } from '../../../constants/presets';
import { sticky } from '../../../constants/helperCssRules';
import { getPageFilters, getUserRoles } from '../peoplePage/config';
import { SURVEY_TYPES } from '../../../constants/survey';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {},
  pageDescription: {
    padding: spacing(8, 0, 6, 0),
    width: '100%',
    ...sticky(primary.white, 105),
  },
  userSelectWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0, 300px))',
    gridColumnGap: 16,
    paddingBottom: spacing(6),
    ...sticky(primary.white, 179),
  },
  userSelect: {
    minWidth: 300,
    maxWidth: 300,
  },
}));

const COMPARE_PRESET_ID = PRESETS.COMPARE;
const DROPDOWN_WIDTH = '340px';

const CompareReportPage = ({
  navigate,
  auth,
  organizationSettings,
  presets,
  isPremiumUser,
  ...rest
}) => {
  const classes = useStyles();
  const compareRef = useRef();
  const translations = useTranslations(APP_PAGES.COMPARE);

  const dispatch = useDispatch();
  const location = useLocation();
  const [tempState, setTempState] = useState(location?.state);
  const { allUsers } = useSelector(peopleSelector);
  const { jobTitles } = useSelector(jobTitlesSelector);
  const { tracks } = useSelector(levelsAndTracksSelector);
  const { categories } = useSelector(tagsSelector);

  const [isLoading, setIsLoading] = useState(true);
  const [initialPreset, setInitialPreset] = useState(null);
  const [selectedPreset, setSelectedPreset] = useState(null);
  const [createdForUser, setCreatedForUser] = useState(tempState?.user || null);

  const [alreadySavedMessage, setAlreadySavedMessage] = useState('');
  const [createdForDisabledPresetMessage, setCreatedForDisabledPresetMessage] =
    useState('');
  const [
    compareWithDisabledPresetMessage,
    setCompareWithDisabledPresetMessage,
  ] = useState('');

  const [isDeletePresetDialogOpened, setIsDeletePresetDialogOpened] =
    useState(false);
  const [defaultPlaceholder, setDefaultPlaceholder] = useState(false);
  const [presetPlaceholderName, setPresetPlaceholderName] = useState('');
  const presetState = {
    defaultPlaceholder,
    presetPlaceholderName,
    isLoading,
  };

  const activePresetId = tempState
    ? NO_ACTIVE_PRESET
    : getActivePresetId(presets, COMPARE_PRESET_ID);
  const filteredPresets = getFilteredPresets(presets, COMPARE_PRESET_ID);
  const activePresetName = getActivePresetName(filteredPresets, activePresetId);
  const handledPresetName = handlePresetName(
    filteredPresets,
    activePresetId,
    activePresetName,
    presetState,
    translations.presets.startSearch
  );
  const placeholderName = getPlaceholderName(
    handledPresetName,
    activePresetName,
    translations.presets.startSearch
  );

  const allEmployees = useMemo(() => getEmployees(allUsers), [allUsers]);
  const userFilters = useMemo(
    () =>
      !isLoading
        ? getPageFilters(
            translations,
            jobTitles,
            tracks,
            categories,
            getUserRoles(translations, true, isPremiumUser),
            false,
            auth
          )
        : [],
    [
      translations,
      isLoading,
      auth,
      isPremiumUser,
      jobTitles,
      tracks,
      categories,
    ]
  );
  const initialReviewers = tempState?.user ? allUsers : [];
  const initialSurveyType = tempState?.surveyType ? tempState.surveyType : null;

  const goToUserProfilePage = userId => () => navigate(`/people/${userId}`);

  const onGetReviewers = useCallback(data => {
    const params = {
      ...data,
    };

    return http.get(API_USERS_BASIC, {
      params,
      paramsSerializer: d => parseDuplicateParameters(d),
    });
  }, []);

  const getInitialPreset = () => {
    const compareReportPagePresets = tempState
      ? null
      : presets[COMPARE_PRESET_ID];
    const existsPresetsArray =
      compareReportPagePresets && !isArrayEmpty(compareReportPagePresets);
    const tempInitialPreset = existsPresetsArray
      ? getItemById(compareReportPagePresets, presets.active[COMPARE_PRESET_ID])
      : undefined;

    if (tempInitialPreset) {
      setInitialPreset(tempInitialPreset);
      setSelectedPreset(tempInitialPreset);
      setCreatedForUser(tempInitialPreset?.data?.createdFor?.subject);
    }
  };

  const getInitialData = useCallback(async () => {
    try {
      await Promise.all([
        getAllUsers(dispatch, {}, true),
        getJobTitles(dispatch),
        getTagsCategories(dispatch),
        getLevelsAndTracks(dispatch),
      ]);

      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  }, [dispatch]);

  const cleanup = useCallback(() => {
    dispatch(clearAllUsers());
    dispatch(clearLevelsAndTracks());
    dispatch(clearJobTitles());
    dispatch(clearTagsCategories());
  }, [dispatch]);

  useEffect(() => {
    getInitialData();
    getInitialPreset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInitialData]);

  useEffect(() => {
    return cleanup;
  }, [cleanup]);

  const handleResetPreset = useCallback(() => {
    editPresetActivity(dispatch, -1, COMPARE_PRESET_ID, false);
    setSelectedPreset(undefined);
    setInitialPreset(undefined);
    compareRef?.current?.updateCreatedForPreset(null);
    compareRef?.current?.updateCompareWithPreset(null);
    setAlreadySavedMessage('');
  }, [dispatch]);

  const areFilterAndPresetEqual = (selectedData, presetData) => {
    if (
      (!selectedData && !presetData) ||
      (!selectedData?.subject && !presetData)
    )
      return true;
    return (
      selectedData &&
      presetData &&
      areArraysContentsEqual(
        selectedData?.reviewer || [],
        presetData?.reviewer || []
      ) &&
      selectedData?.from_date === presetData.from_date &&
      selectedData?.to_date === presetData.to_date &&
      (selectedData?.subject.id === presetData?.subject.id ||
        selectedData?.subject === presetData?.subject.id) &&
      areArraysContentsEqual(selectedData?.survey_type, presetData.survey_type)
    );
  };

  const checkValueInPresets = useCallback(
    (createdFor, compareWith) => {
      const comparePresets = presets[COMPARE_PRESET_ID];
      setTempState(null);
      if (comparePresets && !isArrayEmpty(comparePresets)) {
        const activePreset = comparePresets.find(preset => {
          const presetData = preset?.data;

          return (
            areFilterAndPresetEqual(createdFor, presetData?.createdFor) &&
            areFilterAndPresetEqual(compareWith, presetData?.compareWith)
          );
        });
        if (activePreset) {
          editPresetActivity(
            dispatch,
            activePreset.id,
            COMPARE_PRESET_ID,
            true
          );
          setSelectedPreset(activePreset);
          setInitialPreset(undefined);
          setAlreadySavedMessage(translations.presets.used);
        } else {
          handleResetPreset();
        }
      }
    },
    [dispatch, handleResetPreset, presets, translations.presets.used]
  );

  const onGetAttributeScores = useCallback(async (filters, isSelf = false) => {
    if (isSelf || !isArrayEmpty(filters.reviewer)) {
      try {
        const params = {
          ...filters,
        };

        const { data } = await http.get(API_REPORT_USER_COMPARE, {
          params,
          paramsSerializer: d => parseDuplicateParameters(d),
        });

        return data;
      } catch {
        return [];
      }
    }

    return [];
  }, []);

  const handleCreatedForUserSelect = selectedUser => {
    const createdForData = compareRef?.current?.getCreatedForData();
    const compareWithData = compareRef?.current?.getCompareWithData();
    setCreatedForUser(selectedUser);
    checkValueInPresets(
      { ...createdForData, subject: selectedUser },
      compareWithData
    );
  };

  const handlePlaceholder = name => {
    setDefaultPlaceholder(false);
    setPresetPlaceholderName(name);
  };

  const handleDeletePresetDialog = preset => {
    setSelectedPreset(preset);
    setIsDeletePresetDialogOpened(true);
  };

  const handleDeletePreset = () => {
    deleteSelectedPreset(dispatch, selectedPreset.id, COMPARE_PRESET_ID).then(
      () => {
        setIsDeletePresetDialogOpened(false);
        if (presets.active[COMPARE_PRESET_ID] === selectedPreset.id) {
          setSelectedPreset(undefined);
          compareRef?.current?.updateCreatedForPreset(null);
          compareRef?.current?.updateCompareWithPreset(null);
          setInitialPreset(undefined);
          setAlreadySavedMessage('');
        }
        showSuccessMessage(translations.presets.successMessages.deletedPreset);
      }
    );
  };

  const handleSavePreset = presetName => {
    const createdForData = compareRef?.current?.getCreatedForData();
    const compareWithData = compareRef?.current?.getCompareWithData();
    const data = {
      createdFor: createdForData,
      ...(compareWithData?.subject ? { compareWith: compareWithData } : {}),
    };
    addNewPreset(dispatch, {
      name: presetName,
      location: COMPARE_PRESET_ID,
      data,
    }).then(() => {
      setAlreadySavedMessage(translations.presets.used);
      showSuccessMessage(translations.presets.successMessages.createdPreset);
    });
  };

  const handleApplyPreset = preset => {
    const presetData = preset?.data;

    setTempState(null);
    editPresetActivity(dispatch, preset.id, COMPARE_PRESET_ID, true);
    setCreatedForUser(presetData?.createdFor.subject);
    compareRef?.current?.updateCreatedForPreset(presetData?.createdFor);
    compareRef?.current?.updateCompareWithPreset(
      presetData?.compareWith || { subject: null }
    );
    setSelectedPreset(preset);
  };

  const handleCompareWithDisabledPresetMessage = compareWithData => {
    const label = handleDisabledPresetMessage(
      compareWithData,
      translations.presets
    );
    setCompareWithDisabledPresetMessage(label);
  };

  const handleCreatedForDisabledPresetMessage = createdForData => {
    const label = handleDisabledPresetMessage(
      createdForData,
      translations.presets
    );
    setCreatedForDisabledPresetMessage(label);
  };

  const handleCreatedForDisabledMessage = () => {
    const createdforData = compareRef?.current?.getCreatedForData();
    const isCreatedForSelf = createdforData?.survey_type?.includes(
      SURVEY_TYPES.SELF
    );
    const hasReviewers = !(
      !createdforData ||
      (!isCreatedForSelf && isArrayEmpty(createdforData?.reviewer))
    );

    return createdForUser && hasReviewers
      ? ''
      : translations.presets.comparePresetConditionTooltip;
  };

  return (
    <PageContainer
      {...rest}
      translations={translations}
      auth={auth}
      navigate={navigate}
      organizationSettings={organizationSettings}
      isPremiumUser={isPremiumUser}
      shouldPassProps={false}
    >
      {!isLoading && (
        <div className={classes.root}>
          <Typography className={classes.pageDescription} variant="body2">
            {translations.description}
          </Typography>
          <div className={classes.userSelectWrapper}>
            <SelectField
              className={classes.userSelect}
              label={translations.user.label}
              placeholder={translations.user.placeholder}
              parser={{
                value: 'id',
                label: 'first_name',
              }}
              value={createdForUser}
              options={allEmployees}
              onChange={handleCreatedForUserSelect}
              shouldReturnOption
              isUser
            />
            <PresetDropdown
              translations={translations.presets}
              dropdownWidth={DROPDOWN_WIDTH}
              options={filteredPresets}
              activePreset={activePresetId}
              handleApply={handleApplyPreset}
              handleDelete={handleDeletePresetDialog}
              handleSavePreset={handleSavePreset}
              disabledPreset={
                handleHasMaxPresets(filteredPresets, translations.presets) ||
                compareWithDisabledPresetMessage ||
                createdForDisabledPresetMessage ||
                handleCreatedForDisabledMessage()
              }
              alreadySavedMessage={alreadySavedMessage}
              presetPlaceholderName={placeholderName}
              handlePlaceholder={handlePlaceholder}
            />
          </div>
          {createdForUser ? (
            <UserCompare
              ref={compareRef}
              translations={translations.compare}
              initialReviewers={initialReviewers}
              initialSurveyType={initialSurveyType}
              allUsers={allUsers}
              allEmployees={allEmployees}
              userFilters={userFilters}
              createdForUser={createdForUser}
              onGetAttributeScores={onGetAttributeScores}
              onCompareWithDisabledPresetMessage={
                handleCompareWithDisabledPresetMessage
              }
              onCreatedForDisabledPresetMessage={
                handleCreatedForDisabledPresetMessage
              }
              onGoToProfilePage={goToUserProfilePage}
              onGetReviewers={onGetReviewers}
              initialPreset={initialPreset}
              checkValueInPresets={checkValueInPresets}
            />
          ) : (
            <NotificationCard content={translations.noResults} />
          )}
        </div>
      )}
      <AlertDialog
        translations={translations.presets.presetDeleteDialog}
        isOpened={isDeletePresetDialogOpened}
        onClose={() => setIsDeletePresetDialogOpened(false)}
        onConfirm={handleDeletePreset}
        isWarning
      />
    </PageContainer>
  );
};

CompareReportPage.propTypes = {
  auth: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  organizationSettings: PropTypes.object.isRequired,
  isPremiumUser: PropTypes.bool.isRequired,
};

export default memo(CompareReportPage);
