import { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import UserAvatar from '../userAvatar';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  name: {
    color: primary.bluish4,
    marginBottom: spacing(3),
  },
  scores: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    gridGap: 8,
  },
  scoreWrapper: {
    boxSizing: 'border-box',
    backgroundColor: primary.bluish9,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(2, 4),
  },
  score: {
    borderRadius: 8,
    backgroundColor: ({ attribute }) => attribute.color,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    marginLeft: spacing(2),
    width: 24,
    height: 24,
  },
}));

const UserCompetenceScores = ({
  className,
  attribute,
  isUserProfileAccessible,
  onGoToProfilePage,
}) => {
  const classes = useStyles({ attribute });

  return (
    <div className={className}>
      <Typography className={classes.name} variant="subtitle1">
        {attribute.name}
      </Typography>
      <div className={classes.scores}>
        {attribute.users.map(user => {
          const canAccessProfile = isUserProfileAccessible(user.id);

          return (
            <div
              key={`attribute_score_${attribute.id}_${user.id}`}
              className={classes.scoreWrapper}
            >
              <UserAvatar
                className={classes.user}
                user={user}
                clickableCaption={canAccessProfile}
                onClickHandler={onGoToProfilePage(user.id)}
                variant="subtitle1"
                caption
                small
              />
              <Typography className={classes.score} variant="overline">
                {Math.round(user.score * 10) / 10}
              </Typography>
            </div>
          );
        })}
      </div>
    </div>
  );
};

UserCompetenceScores.defaultProps = {
  className: undefined,
};

UserCompetenceScores.propTypes = {
  className: PropTypes.string,
  attribute: PropTypes.object.isRequired,
  onGoToProfilePage: PropTypes.func.isRequired,
};
export default memo(UserCompetenceScores);
