import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Typography, makeStyles } from '@material-ui/core';
import UserAvatar from '../userAvatar';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    maxWidth: '100%',
    minWidth: 0,
  },
  label: {
    marginBottom: spacing(1.5),
  },
  link: {
    display: 'flex',
    textDecoration: 'none',
    width: 'fit-content',
    maxWidth: '100%',
  },
}));

const UserLink = ({
  className,
  labelClass,
  label,
  meLabel,
  user,
  avatarProps,
  isCurrentUser,
  isUserAccessible,
}) => {
  const classes = useStyles();

  const handleCreatorClick = e => e.stopPropagation();

  if (!user) {
    return (
      <div className={classNames(classes.root, className)}>
        {label && (
          <Typography
            className={classNames(classes.label, labelClass)}
            variant="body2"
          >
            {label}
          </Typography>
        )}
        <Typography variant="body1">-</Typography>
      </div>
    );
  }

  if (isCurrentUser) {
    return (
      <Typography variant="body2" className={classNames(className, labelClass)}>
        {meLabel}
      </Typography>
    );
  }

  return (
    <div className={classNames(classes.root, className)}>
      {label && (
        <Typography
          className={classNames(classes.label, labelClass)}
          variant="body2"
        >
          {label}
        </Typography>
      )}
      {isUserAccessible ? (
        <Link
          className={classes.link}
          to={`/people/${user.id}`}
          onClick={handleCreatorClick}
        >
          <UserAvatar
            user={user}
            variant="subtitle2"
            clickableCaption
            caption
            extraSmall
            {...avatarProps}
          />
        </Link>
      ) : (
        <UserAvatar user={user} variant="subtitle2" caption extraSmall />
      )}
    </div>
  );
};

UserLink.defaultProps = {
  className: undefined,
  labelClass: undefined,
  label: '',
  meLabel: '',
  user: null,
  isCurrentUser: false,
  isUserAccessible: false,
  avatarProps: {},
};

UserLink.propTypes = {
  className: PropTypes.string,
  labelClass: PropTypes.string,
  label: PropTypes.string,
  meLabel: PropTypes.string,
  user: PropTypes.object,
  avatarProps: PropTypes.object,
  isUserAccessible: PropTypes.bool,
  isCurrentUser: PropTypes.bool,
};

export default UserLink;
