import { memo } from 'react';
import PropTypes from 'prop-types';

const CustomDot = ({ cx, cy, value, payload, color }) =>
  value ? (
    <circle key={payload.name} cx={cx} cy={cy} r="3" fill={color} />
  ) : (
    false
  );

CustomDot.defaultProps = {
  value: null,
  cx: null,
  cy: null,
};

CustomDot.propTypes = {
  color: PropTypes.string.isRequired,
  value: PropTypes.number,
  cx: PropTypes.number,
  cy: PropTypes.number,
  payload: PropTypes.object.isRequired,
};

export default memo(CustomDot);
