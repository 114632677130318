import { createAction, handleActions } from 'redux-actions';
// ------------------------------------
// Constants
// ------------------------------------

const OPEN_INSTRUCTION_GUIDE_DRAWER = 'OPEN_INSTRUCTION_GUIDE_DRAWER';

const initialState = {
  instructionGuide: false,
};

// ------------------------------------
// Actions
// ------------------------------------

export const handleInstructionGuideDrawer = createAction(
  OPEN_INSTRUCTION_GUIDE_DRAWER
);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [OPEN_INSTRUCTION_GUIDE_DRAWER]: (state, { payload }) => {
    return { ...state, instructionGuide: payload };
  },
};

export default handleActions(reducers, initialState);
