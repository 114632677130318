import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as ChatIcon } from '../../../assets/icons/tidio-chat.svg';
import { ReactComponent as PencilIcon } from '../../../assets/icons/edit-active.svg';
import { toggleTidioChatVisibility } from '../../../utility/uiUtils';

const styles = ({ breakpoints, palette: { primary } }) => ({
  root: {
    boxShadow: `${primary.bluish5} 0px 2px 4px`,
    borderRadius: '4px 0 0 4px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    width: 16,
    height: 'auto',
    position: 'fixed',
    right: 0,
    top: '50%',
    bottom: 'unset',
    transform: 'translateY(-50%)',
    [breakpoints.up('sm')]: {
      alignItems: 'flex-start',
      borderRadius: '50%',
      width: 36,
      height: 36,
      right: 20,
      top: 'unset',
      bottom: 12,
      transform: 'translateY(0)',
      '&:hover': {
        '& $mainIconContainer': {
          opacity: 0,
        },

        '& $hoverIconContainer': {
          opacity: 1,
          scale: 1,
        },
      },

      '&:active': {
        animation: 'glowing .3s 1 ease-out',
      },
    },
  },
  hidden: {
    opacity: 0,
  },
  icon: {
    width: 6,
    height: 6,
    [breakpoints.up('sm')]: {
      width: 14,
      height: 14,
    },
  },
  mainIconContainer: {
    backgroundColor: primary.blue1,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 14,
    height: 14,
    transition: 'all .3s ease-in-out',
    marginTop: 6,
    [breakpoints.up('sm')]: {
      marginTop: 0,
      width: 36,
      height: 36,
    },
  },
  hoverIconContainer: {
    borderRadius: '50%',
    backgroundColor: primary.white,
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 14,
    height: 14,
    opacity: 0,
    scale: 0,
    transition: 'all .3s ease-in-out',
    marginTop: 6,
    [breakpoints.up('sm')]: {
      marginTop: 0,
      width: 36,
      height: 36,
    },
  },
  supportText: {
    color: primary.blue1,
    display: 'flex',
    width: 12,
    marginBottom: 6,
    marginTop: 4,
    textAlign: 'center',
    textTransform: 'uppercase',
    wordBreak: 'break-all',
    userSelect: 'none',
    [breakpoints.up('sm')]: {
      display: 'none',
    },
  },
});

class ChatButton extends PureComponent {
  state = {
    isOpen: false,
  };

  componentDidMount() {
    if (window.tidioChatApi && window.tidioChatApi.readyEventWasFired) {
      this.onTidioChatApiReady();
    } else {
      window.addEventListener('tidioChat-ready', this.onTidioChatApiReady);
    }
  }

  onTidioChatApiReady = () => {
    toggleTidioChatVisibility();

    window.tidioChatApi.on('close', () => {
      this.setState({ isOpen: false });
      toggleTidioChatVisibility();
    });
  };

  onChatOpen = () => {
    this.setState({ isOpen: true }, () => {
      toggleTidioChatVisibility(false);
    });
  };

  render() {
    const { classes, translations } = this.props;
    const { isOpen } = this.state;

    return (
      <div
        id="tidio-chat-button"
        className={classNames(classes.root, { [classes.hidden]: isOpen })}
        onClick={this.onChatOpen}
      >
        <div className={classes.mainIconContainer}>
          <ChatIcon className={classes.icon} />
        </div>
        <div className={classes.hoverIconContainer}>
          <PencilIcon className={classes.icon} />
        </div>
        <Typography variant="overline" className={classes.supportText}>
          {translations.support}
        </Typography>
      </div>
    );
  }
}

ChatButton.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChatButton);
