import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DraggableScroll from '../../draggableScroll';

import CustomButton from '../../customButton';
import { ReactComponent as SubmittedIcon } from '../../../../assets/icons/check-circle-outline-green.svg';

const styles = ({ breakpoints, spacing }) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    [breakpoints.up('sm')]: {
      height: 'auto',
      maxHeight: '100%',
    },
  },
  header: {
    overflow: 'hidden',
    [breakpoints.up('sm')]: {
      height: 'auto',
      maxHeight: '100%',
    },
  },
  welcomeMessage: {
    marginBottom: spacing(4),
    [breakpoints.up('sm')]: {
      marginBottom: spacing(6),
    },
  },
  description: {
    marginBottom: spacing(4),
    [breakpoints.up('sm')]: {
      marginBottom: spacing(6),
    },
  },
  surveyInfo: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  review: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: spacing(4),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  reviewName: {
    wordBreak: 'break-word',
  },
  completionTimeWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  submitted: {
    marginLeft: 'auto',
    width: 21,
    height: 21,
  },
  getStarted: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 'auto',
    paddingTop: 24,
    marginBottom: 8,
    minHeight: 40,
    flexShrink: 0,
    [breakpoints.up('sm')]: {
      paddingTop: 40,
    },
  },
  button: {
    padding: '8px 16px',
    '& span': {
      fontFamily: 'ProximaNova-Bold',
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: 'normal',
    },
  },
  draggableScroll: {
    maxHeight: 'calc(100% - 44px)',
    [breakpoints.up('sm')]: {
      maxHeight: 'calc(100% - 52px)',
    },
  },
});

class SurveyWelcomeScreen extends PureComponent {
  renderNumberOfSurveys = () => {
    const { translations, survey } = this.props;
    return survey.totalReviews === 1
      ? translations.oneGroup
      : `${survey.totalReviews} ${translations.groups}`;
  };

  renderSurveyInfo = () => {
    const { classes, translations, survey } = this.props;

    return (
      <div className={classes.header}>
        <Typography className={classes.welcomeMessage} variant="h3">
          {translations.welcomeMessage}
        </Typography>
        <DraggableScroll
          className={classes.draggableScroll}
          showDesktopScrollbars
        >
          <div>
            <Typography className={classes.description}>
              {` ${translations.numberOfSurveys.replace(
                '[NUMBER_OF_SURVEYS]',
                this.renderNumberOfSurveys()
              )}`}
              {` ${translations.numberOfQuestions.replace(
                '[NUMBER_OF_QUESTIONS]',
                survey.totalQuestions
              )}`}
            </Typography>
            <div className={classes.surveyInfo}>
              {survey.attributes.map(review => (
                <div
                  key={`review_item_${review.id}`}
                  className={classes.review}
                >
                  <Typography className={classes.reviewName} variant="h5">
                    {review.name}
                  </Typography>
                  <div className={classes.completionTimeWrapper}>
                    {!review.active && (
                      <SubmittedIcon className={classes.submitted} />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </DraggableScroll>
      </div>
    );
  };

  render() {
    const { classes, translations, onGetStarted } = this.props;

    return (
      <div className={classes.main}>
        {this.renderSurveyInfo()}
        <div className={classes.getStarted}>
          <CustomButton
            type="withTextDarkRounded"
            className={classes.button}
            onClick={() => onGetStarted()}
          >
            {translations.getStartedButtonLabel}
          </CustomButton>
        </div>
      </div>
    );
  }
}

SurveyWelcomeScreen.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  survey: PropTypes.shape({}).isRequired,
  onGetStarted: PropTypes.func.isRequired,
};

export default withStyles(styles)(SurveyWelcomeScreen);
