import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  ButtonBase,
  FormHelperText,
  withStyles,
} from '@material-ui/core';
import Dropzone from 'react-dropzone';
import ActionButton from '../actionButton';
import { ReactComponent as PlaceholderIcon } from '../../../assets/icons/image-placeholder.svg';
import { isObject } from '../../../utility/helpers';
import { PHOTO_UPLOAD_CONFIG } from '../../../constants/uploadPhotoConfig';
import { validateFileType } from '../../../utility/validation';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';

const styles = ({ palette: { primary }, spacing }) => ({
  main: {
    width: '100%',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  label: {
    marginBottom: spacing(2),
  },
  placeholderContainer: {
    boxSizing: 'border-box',
    cursor: 'pointer',
    border: `1px solid ${primary.bluish1}`,
    borderRadius: 44,
    display: 'flex',
    alignItems: 'center',
    padding: spacing(0, 4),
    height: 40,
    userSelect: 'none',
  },
  placeholder: {
    marginLeft: spacing(2),
  },
  imageContainer: {
    borderRadius: 8,
    cursor: 'pointer',
    width: 106,
    height: 79,
  },
  image: {
    borderRadius: 8,
    width: '100%',
    height: '100%',
  },
});

class AddImageField extends PureComponent {
  getImageLink = image => {
    if (isObject(image)) {
      return URL.createObjectURL(image);
    }

    return image;
  };

  onAcceptImage = ([file]) => {
    const { onAddImage } = this.props;

    onAddImage(file);
  };

  onDeleteImage = () => {
    const { onAddImage } = this.props;

    onAddImage(null);
  };

  renderImage = () => {
    const { classes, image } = this.props;

    return (
      <div className={classes.imageContainer}>
        <img
          className={classes.image}
          src={this.getImageLink(image)}
          alt="course"
        />
      </div>
    );
  };

  renderPlaceholder = () => {
    const { classes, placeholder } = this.props;

    return (
      <ButtonBase className={classes.placeholderContainer} component="div">
        <PlaceholderIcon />
        <Typography variant="subtitle1" className={classes.placeholder}>
          {placeholder}
        </Typography>
      </ButtonBase>
    );
  };

  render() {
    const { classes, image, label, hasError, errorMessage } = this.props;
    const { mimeTypes, multiple } = PHOTO_UPLOAD_CONFIG;

    return (
      <div className={classes.main}>
        {label && (
          <Typography variant="body2" className={classes.label}>
            {label}
          </Typography>
        )}
        <div className={classes.content}>
          <Dropzone onDropAccepted={this.onAcceptImage} multiple={multiple}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                {image && validateFileType(image, Object.keys(mimeTypes))
                  ? this.renderImage()
                  : this.renderPlaceholder()}
              </div>
            )}
          </Dropzone>
          {image && (
            <ActionButton
              type={ACTION_BUTTON_TYPES.DELETE}
              onClickHandler={this.onDeleteImage}
            />
          )}
        </div>
        {hasError && errorMessage && (
          <FormHelperText error={hasError}>{errorMessage}</FormHelperText>
        )}
      </div>
    );
  }
}

AddImageField.defaultProps = {
  label: '',
  image: null,
};

AddImageField.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onAddImage: PropTypes.func.isRequired,
};

export default withStyles(styles)(AddImageField);
