import { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import OneOnOneTimeIndicator from '../../../oneOnOneTimeIndicator';
import ActionButton from '../../../actionButton';
import OneOnOneCalendar from '../../../oneOnOneCalendar';
import Impression from '../../../impressions/impression';
import UserLink from '../../../userLink';
import DotsMenu from '../../../dotsMenu';
import TasksIconTooltip from '../../../tasksIconTooltip';
import PlansIconTooltip from '../../../plansIconTooltip';
import RecurringIconTooltip from '../../../recuringIconTooltip';
import RequestedIconTooltip from '../../../requestedIconTooltip';
import PrivateNote from '../privateNote';
import { formatDate } from '../../../customDateRange/config';
import { getDotsMenuItems } from '../../../../../utility/oneOnOne';
import { getContentLength } from '../../../../../utility/uiUtils';
import { isArrayEmpty } from '../../../../../utility/helpers';
import { ACTION_BUTTON_TYPES } from '../../../actionButton/config';
import {
  TIME_FORMAT,
  DATE_FORMAT,
  RECURRING_TYPES_MAP,
} from '../../../../../constants/oneOnOne';

const useStyles = makeStyles(
  ({ palette: { primary }, breakpoints, spacing }) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      boxSizing: 'border-box',
      padding: spacing(6, 5, 4, 5),
      position: 'relative',
    },
    rootMin: {
      padding: spacing(4, 3, 4, 3),
      [breakpoints.up('xl')]: {
        padding: spacing(6, 5, 4, 5),
      },
    },
    statusWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: spacing(2),
    },
    actionsWrapper: {
      display: 'flex',
    },
    rescheduleWrapper: {
      position: 'absolute',
      top: 22,
      right: 50,
    },
    date: {
      marginBottom: spacing(1),
    },
    time: {
      marginBottom: spacing(4),
    },
    impression: {
      marginBottom: spacing(4),
    },
    impressionLabel: {
      color: primary.bluish4,
      textTransform: 'uppercase',
      letterSpacing: 1,
      marginBottom: spacing(2),
    },
    impressionValue: {
      cursor: 'default',
    },
    autoSpacing: {
      marginTop: 'auto',
    },
    creator: {
      color: primary.bluish4,
      fontFamily: 'ProximaNova-Bold',
      fontSize: 14,
      lineHeight: '18px',
    },
    dotsMenu: {
      position: 'absolute',
      right: 20,
      top: 24,
    },
    spacingBottom: {
      marginBottom: spacing(4),
    },
    iconsWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    iconSpacing: {
      marginRight: spacing(2),
    },
  })
);

const Info = ({
  translations,
  record,
  isMiniCard,
  isFuture,
  hasRescheduleButton,
  isDisabled,
  noteLabelComponent,
  onReschedule,
  onAddImpression,
  onEdit,
  onDelete,
  onManageTasks,
}) => {
  const classes = useStyles();
  const {
    moderator,
    meeting_time,
    has_impression,
    requested,
    meeting_impression,
    isCreatedByMe,
    isCreatedForMe,
    note,
    canAccessModeratorProfile,
    recurring,
    tasks,
    plans,
  } = record;
  const hasAddToCalendarButton = isFuture && (isCreatedByMe || isCreatedForMe);
  const isRecurring = recurring !== RECURRING_TYPES_MAP.DOES_NOT_REPEAT.id;
  const hasActions =
    meeting_impression === null && isCreatedByMe && !isDisabled;
  const hasPlans = !isArrayEmpty(plans);
  const hasTasks = !isArrayEmpty(tasks);
  const hasPrivateNote =
    !isMiniCard && isCreatedByMe && note && getContentLength(note?.text) > 0;

  const handleRescedule = useCallback(
    e => {
      e.stopPropagation();
      onReschedule();
    },
    [onReschedule]
  );

  const handleAddImpression = useCallback(
    e => {
      e.stopPropagation();
      onAddImpression();
    },
    [onAddImpression]
  );

  return (
    <div
      className={classNames(classes.root, { [classes.rootMin]: isMiniCard })}
    >
      <div className={classes.statusWrapper}>
        <OneOnOneTimeIndicator
          labels={translations.statuses}
          meetingTime={meeting_time}
          hasImpression={has_impression}
        />
        <RecurringIconTooltip
          isVisible={isRecurring}
          text={translations.recurring}
        />
        {requested && (
          <RequestedIconTooltip
            labels={translations.requested}
            isRequestedByCurrentUser={isMiniCard}
          />
        )}
        {hasRescheduleButton && hasActions && (
          <div
            className={classNames(
              classes.actionsWrapper,
              classes.rescheduleWrapper
            )}
          >
            <ActionButton
              type={ACTION_BUTTON_TYPES.RESCHEDULE}
              tooltipText={translations.actionButtons.reschedule}
              onClickHandler={handleRescedule}
              isSquared
            />
          </div>
        )}
      </div>
      <div>
        <Typography variant="subtitle1" className={classes.date}>
          {formatDate(meeting_time, DATE_FORMAT)}
        </Typography>
        <Typography
          variant="caption"
          className={classNames({ [classes.time]: !hasAddToCalendarButton })}
        >
          {formatDate(meeting_time, TIME_FORMAT)}
        </Typography>
        {hasAddToCalendarButton ? (
          <OneOnOneCalendar
            className={classes.time}
            translations={translations.calendar}
            record={record}
          />
        ) : null}
      </div>
      {!isMiniCard && !isFuture && (
        <div className={classes.impression}>
          {(hasActions || !!meeting_impression) && (
            <Typography variant="overline" className={classes.impressionLabel}>
              {translations.generalImpression}
            </Typography>
          )}
          {hasActions && (
            <div className={classes.actionsWrapper}>
              <ActionButton
                type={ACTION_BUTTON_TYPES.RESOLVE}
                tooltipText={translations.actionButtons.resolve}
                onClickHandler={handleAddImpression}
                isSquared
              />
            </div>
          )}
          {!!meeting_impression && (
            <Impression
              className={classes.impressionValue}
              value={meeting_impression}
              isSmall
            />
          )}
        </div>
      )}
      <div
        className={classNames(classes.iconsWrapper, {
          [classes.spacingBottom]: hasPrivateNote || hasTasks,
        })}
      >
        <PlansIconTooltip
          className={classes.iconSpacing}
          text={translations.plansIconTooltip}
          isVisible={hasPlans}
          numberOfPlans={plans.length}
        />
        {hasPrivateNote && (
          <PrivateNote
            title={translations.note}
            helpTooltip={translations.viewPrivateNote}
            note={[note.text]}
            itemComponent={noteLabelComponent}
          />
        )}
        <TasksIconTooltip
          className={classes.iconSpacing}
          text={translations.tasksIconTooltip}
          isVisible={hasTasks}
          numberOfTasks={tasks.length}
        />
      </div>
      <UserLink
        className={classes.autoSpacing}
        labelClass={classes.creator}
        label={translations.createdBy}
        meLabel={translations.createdByMe}
        user={moderator}
        isCurrentUser={isCreatedByMe}
        isUserAccessible={canAccessModeratorProfile}
      />
      {!isMiniCard && isCreatedByMe && (
        <DotsMenu
          className={classes.dotsMenu}
          menuItems={getDotsMenuItems(
            translations,
            isDisabled,
            false,
            record.isCreatedForMe,
            onEdit,
            onDelete,
            onManageTasks
          )}
        />
      )}
    </div>
  );
};

Info.defaultProps = {
  isMiniCard: false,
  hasRescheduleButton: false,
  isFuture: false,
  isDisabled: false,
  onReschedule: () => {},
  onAddImpression: () => {},
  onEdit: () => {},
  onDelete: () => {},
  onManageTasks: () => {},
};

Info.propTypes = {
  translations: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired,
  isMiniCard: PropTypes.bool,
  hasRescheduleButton: PropTypes.bool,
  isFuture: PropTypes.bool,
  isDisabled: PropTypes.bool,
  noteLabelComponent: PropTypes.func.isRequired,
  onReschedule: PropTypes.func,
  onAddImpression: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onManageTasks: PropTypes.func,
};

export default Info;
