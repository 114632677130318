import { useState, useMemo, useCallback, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup } from 'react-transition-group';
import classNames from 'classnames';
import { Fade, makeStyles } from '@material-ui/core';
import InputField from '../inputField';
import Tag from '../tag';
import ActionButton from '../actionButton';
import { sortAlphabetically, onScrollIntoView } from '../../../utility/uiUtils';
import { replaceObjectInList, trimString } from '../../../utility/helpers';
import { FIELD_TYPES } from '../../../constants/fieldTypes';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {},
  input: {
    marginBottom: spacing(6),
  },
  error: {
    marginBottom: spacing(1),
  },
  tag: {
    maxWidth: 338,
  },
  tagRestore: {
    maxWidth: 310,
  },
  tagWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: spacing(3),
    maxWidth: '100%',
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  actionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    marginLeft: spacing(2),
  },
  actionButton: {
    width: 24,
    height: 24,
    marginLeft: spacing(1),
  },
  actionIcon: {
    width: 12,
    height: 16,
  },
  actionIconDisabled: {
    '& path': {
      fill: primary.bluish5,
    },
  },
  saveButton: {
    marginLeft: spacing(2),
  },
}));

const CreatableTagsField = ({
  label,
  placeholder,
  startAdornment,
  hasError,
  errorMessage,
  category,
  initialTags,
  tags,
  field,
  onSetError,
  onValidate,
  onChange,
}) => {
  const classes = useStyles();
  const inputRef = useRef(null);
  const [name, setName] = useState('');
  const [selectedTag, setSelectedTag] = useState(null);

  const isSaveTagDisabled = !trimString(name);
  const initialTagIndex = useMemo(
    () =>
      selectedTag
        ? initialTags.findIndex(cTag => cTag.id === selectedTag?.id)
        : -1,
    [initialTags, selectedTag]
  );
  const selectedTagIndex = useMemo(
    () =>
      selectedTag
        ? tags.findIndex(cTag => cTag.name === selectedTag?.name)
        : -1,
    [tags, selectedTag]
  );

  const initialTagValue = useMemo(() => {
    if (selectedTag) {
      if (initialTagIndex !== -1) {
        return initialTags[initialTagIndex].name;
      }
      if (selectedTagIndex !== -1) {
        return tags[selectedTagIndex].name;
      }

      return null;
    }
    return null;
  }, [selectedTag, initialTags, tags, selectedTagIndex, initialTagIndex]);

  const resetState = () => {
    setName('');
    setSelectedTag(null);
  };

  const onInputChange = e => {
    const { value } = e.target;

    onValidate(
      { ...field, validators: [...field.itemValidators] },
      trimString(value),
      initialTagValue,
      tags
    );
    setName(value);
  };

  const onTagEdit = useCallback(
    tag => () => {
      onScrollIntoView(inputRef);
      setSelectedTag(tag);
      setName(tag.name);
      if (hasError) {
        onSetError(field.name, null);
      }
    },
    [field.name, hasError, onSetError]
  );

  const onTagDelete = useCallback(
    tag => () => {
      const deletedTag = {
        ...tag,
        ...(tag?.id ? { isDeleted: true } : {}),
      };
      const deletedTagIndex = tag?.id
        ? tags.findIndex(cTag => cTag.name === tag?.name)
        : -1;

      onChange(
        tag?.id
          ? replaceObjectInList(tags, deletedTagIndex, deletedTag)
          : tags.filter(currentTag => currentTag.name !== tag.name)
      );
      resetState();
    },
    [tags, onChange]
  );

  const onTagRestore = useCallback(
    tag => () => {
      const restoredTagIndex = tag?.id
        ? tags.findIndex(cTag => cTag.name === tag?.name)
        : -1;
      onChange(
        replaceObjectInList(tags, restoredTagIndex, {
          ...tag,
          isDeleted: false,
        })
      );
    },
    [tags, onChange]
  );

  const onSave = async () => {
    const error = await onValidate(
      { ...field, validators: [...field.itemValidators] },
      trimString(name),
      initialTagValue,
      tags
    );

    if (!error) {
      const tag = {
        category: category?.id,
        name: trimString(name),
        ...(selectedTag?.id ? { id: selectedTag.id, isUpdated: true } : {}),
      };
      const tagIndex = tags.findIndex(cTag => cTag.name === selectedTag?.name);

      onChange(
        sortAlphabetically(
          tagIndex === -1
            ? [...tags, tag]
            : replaceObjectInList(tags, tagIndex, tag)
        )
      );
      resetState();
    }
  };

  const renderSaveButton = () => {
    return (
      <ActionButton
        className={classes.saveButton}
        type={ACTION_BUTTON_TYPES.ACCEPT}
        isDisabled={isSaveTagDisabled}
        onClickHandler={onSave}
        isSquared
      />
    );
  };

  return (
    <div className={classes.root}>
      <div
        ref={el => {
          inputRef.current = el;
        }}
      >
        <InputField
          className={classNames(classes.input, {
            [classes.error]: hasError,
          })}
          type={FIELD_TYPES.TEXT}
          label={label}
          placeholder={placeholder}
          value={name}
          startAdornment={startAdornment}
          error={hasError}
          errorMessage={errorMessage}
          endAdornment={renderSaveButton()}
          onChange={onInputChange}
          fullWidth
          required
        />
      </div>
      <TransitionGroup>
        {tags.map((tag, index) => (
          <Fade key={`tag_item_${tag.id || index}`} in>
            <div className={classes.tagWrapper}>
              <Tag
                className={classNames(classes.tag, {
                  [classes.tagRestore]: tag.isDeleted,
                })}
                tag={tag}
                color={category?.color}
                isDisabled={!!tag?.isDeleted}
                isSelected={tag.name === selectedTag?.name}
                onTagSelect={onTagEdit(tag)}
                isSelectable
              />
              <div className={classes.actionsWrapper}>
                <ActionButton
                  className={classes.actionButton}
                  iconClass={classNames(classes.actionIcon, {
                    [classes.actionIconDisabled]: tag.isDeleted,
                  })}
                  type={ACTION_BUTTON_TYPES.EDIT}
                  size="small"
                  isDisabled={!!tag?.isDeleted}
                  onClickHandler={onTagEdit(tag)}
                />
                <ActionButton
                  className={classes.actionButton}
                  iconClass={classNames(classes.actionIcon, {
                    [classes.actionIconDisabled]: tag.isDeleted,
                  })}
                  type={ACTION_BUTTON_TYPES.DELETE}
                  size="small"
                  isDisabled={!!tag?.isDeleted}
                  onClickHandler={onTagDelete(tag)}
                />
                {tag.isDeleted && (
                  <ActionButton
                    className={classes.actionButton}
                    type={ACTION_BUTTON_TYPES.RELOAD}
                    size="small"
                    onClickHandler={onTagRestore(tag)}
                  />
                )}
              </div>
            </div>
          </Fade>
        ))}
      </TransitionGroup>
    </div>
  );
};

CreatableTagsField.defaultProps = {
  initialTags: [],
  tags: [],
  startAdornment: undefined,
  hasError: false,
  errorMessage: '',
  category: null,
};

CreatableTagsField.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  category: PropTypes.object,
  initialTags: PropTypes.arrayOf(PropTypes.object),
  tags: PropTypes.arrayOf(PropTypes.object),
  startAdornment: PropTypes.node,
  field: PropTypes.object.isRequired,
  onSetError: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default memo(CreatableTagsField);
