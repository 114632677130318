import { isArrayEmpty } from './helpers';
import { getPersonFullName } from './uiUtils';
import { ATTRIBUTES } from '../constants/attributes';
import { SURVEY_TYPES } from '../constants/survey';

export const HORIZONTAL_ANSWER_SIZES = {
  SMALL: 5,
  MEDIUM: 10,
  LARGE: 16,
};

const isSinglePersonSurvey = survey =>
  survey &&
  ((survey.subjects && survey.subjects.length === 1) ||
    survey.type === SURVEY_TYPES.SELF ||
    survey.survey_type === SURVEY_TYPES.SELF);

export const prepareSurveyData = survey => {
  const isSingleSurvey = isSinglePersonSurvey(survey);
  const title = isSingleSurvey ? getPersonFullName(survey.subjects[0]) : null;
  const { attributes } = survey;
  let totalQuestions = 0;
  attributes.forEach(attribute => {
    totalQuestions += attribute.question_count || attribute.total_questions;
  });
  const totalReviews = attributes.length;

  return {
    ...survey,
    title,
    attributes,
    totalQuestions,
    isSingleSurvey,
    isAnonymous: survey.is_anonymous,
    totalReviews,
  };
};

export const prepareReviewData = review => {
  const hasAdditionalFeedback =
    review[ATTRIBUTES.fields.withAdditionalFeedback];
  const withTextReplyQuestions =
    review?.[ATTRIBUTES.fields.withTextReplyQuestions];
  const results = review.subjects.reduce((acc, subject) => {
    return {
      ...acc,
      [subject.review_tracking]: {
        ...(withTextReplyQuestions ? subject.replied : subject.answered),
      },
    };
  }, {});
  const comments = review.subjects.reduce((acc, subject) => {
    return [
      ...acc,
      {
        review_tracking: subject.review_tracking,
        comment: subject.comment || '',
      },
    ];
  }, []);

  return {
    ...review,
    results,
    comments,
    isVertical: review.layout === ATTRIBUTES.verticalLayout,
    hasNeutralAnswer: review[ATTRIBUTES.fields.withNeutralAnswer],
    hasAdditionalFeedback,
    withTextReplyQuestions,
    totalSteps: review.questions.length + (hasAdditionalFeedback ? 1 : 0),
    isSubmitted: !review.active,
  };
};

export const prepareAnswerTextResult = answerData => ({
  review_tracking: answerData.review_tracking,
  question: answerData.question,
  reply: answerData.answer.reply,
  not_applicable: answerData.answer.not_applicable,
});

const getUnansweredQuestion = review => {
  const { questions, subjects } = review;
  const withTextReplyQuestions =
    review?.[ATTRIBUTES.fields.withTextReplyQuestions];

  return questions.find(question =>
    subjects.some(subject => {
      const answered = Object.keys(
        withTextReplyQuestions ? subject.replied : subject.answered
      );

      return answered.indexOf(question.id.toString()) === -1;
    })
  );
};

export const isQuestionCompleted = (results, subjects, questionId) => {
  return subjects?.every(
    subject => results[subject.review_tracking]?.[questionId]
  );
};

export const isAnswerSelected = (results, answerId, user) => {
  const userResults = results?.[user?.review_tracking];
  return (
    userResults &&
    Object.values(results?.[user.review_tracking]).includes(answerId)
  );
};

export const shouldRenderSubject = (questionId, answerId, results, user) => {
  const selectedValue = results?.[user.review_tracking]?.[questionId];

  return !selectedValue || selectedValue === answerId;
};

export const resumeReview = (reviews, review) => {
  if (!isArrayEmpty(reviews)) {
    let reviewStep = 0;
    const surveyStep = reviews.findIndex(
      currReview => currReview.id === review.id
    );
    const unansweredQuestion = getUnansweredQuestion(review);

    if (unansweredQuestion) {
      reviewStep = review.questions.indexOf(unansweredQuestion);
    }
    return {
      surveyStep,
      reviewStep,
    };
  }

  return {
    surveyStep: 0,
    reviewStep: 0,
  };
};

export const hasErrors = errors => Object.values(errors).some(error => !!error);

export const updateScrollPosition = (
  scrollContainer,
  isVertical = true,
  selectedAnswerClass = 'selected'
) => {
  if (!scrollContainer) return;

  // reset scroll
  scrollContainer.scrollLeft = 0;
  scrollContainer.scrollTop = 0;
  const containerVerticalPosition = scrollContainer.scrollTop;
  const containerHorizontalPosition = scrollContainer.scrollLeft;
  const containerBox = scrollContainer.getBoundingClientRect();
  const selectedAnswer = scrollContainer.querySelector(
    `[class*=${selectedAnswerClass}]`
  );

  if (selectedAnswer) {
    const answerBox = selectedAnswer.getBoundingClientRect();
    const containerTop = isVertical ? containerBox.top : 0;

    const top =
      answerBox.top +
      answerBox.height / 2 -
      containerTop -
      containerBox.height / 2;
    const left =
      answerBox.left +
      answerBox.width / 2 -
      containerBox.left -
      containerBox.width / 2;

    return scrollContainer.scrollBy({
      top: !isVertical || top < 0 ? 0 : top,
      left: isVertical || left < 0 ? 0 : left,
      behavior: 'smooth',
    });
  }

  if (containerVerticalPosition !== 0 || containerHorizontalPosition !== 0) {
    return scrollContainer.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
};

export const getActiveReviewIndex = (reviews = []) =>
  reviews.findIndex(review => review.active);
