import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Collapse, Fade } from '@material-ui/core';
import TextBoxWithTooltip from '../textBoxWithTooltip';
import ActionButton from '../actionButton';
import Answers from './answers';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    marginTop: spacing(6),
  },
  question: {
    backgroundColor: primary.bluish9,
    borderRadius: 8,
    padding: spacing(6),
    marginBottom: spacing(4),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  info: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 30,
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    maxWidth: 'calc(100% - 36px)',
  },
  collapseButton: {
    marginLeft: spacing(1.5),
    alignSelf: 'flex-start',
  },
  expandIcon: {
    transform: 'rotate(0deg)',
    transition: 'transform .3s cubic-bezier(0.77, 0.2, 0.05, 1)',
  },
  expandIconOpened: {
    transform: 'rotate(180deg)',
  },
}));

const ReportTextReplies = ({
  className,
  isAnonymous,
  anonymousUser,
  notApplicableLabel,
  questions,
  results,
  selectedQuestionId,
  onExpand,
}) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.root, className)}>
      {questions.map(question => {
        const hasResults = !!results?.[question.id];
        const isExpanded = selectedQuestionId === question.id;

        return (
          <div key={`question_${question.id}`} className={classes.question}>
            <div className={classes.info} onClick={onExpand(question.id)}>
              <div className={classes.titleWrapper}>
                <TextBoxWithTooltip variant="h4" text={question.text} />
              </div>
              <Fade in={hasResults} unmountOnExit>
                <ActionButton
                  className={classes.collapseButton}
                  iconClass={classNames(classes.expandIcon, {
                    [classes.expandIconOpened]: isExpanded,
                  })}
                  size="small"
                  edge="end"
                  type={ACTION_BUTTON_TYPES.ARROW}
                  onClickHandler={onExpand(question.id)}
                />
              </Fade>
            </div>
            <Collapse
              in={isExpanded && hasResults}
              timeout="auto"
              unmountOnExit
            >
              <Answers
                isAnonymous={isAnonymous}
                anonymousUser={anonymousUser}
                notApplicableLabel={notApplicableLabel}
                answers={results?.[question.id]}
              />
            </Collapse>
          </div>
        );
      })}
    </div>
  );
};

ReportTextReplies.defaultProps = {
  className: null,
  selectedQuestionId: null,
  isAnonymous: false,
};

ReportTextReplies.propTypes = {
  className: PropTypes.string,
  isAnonymous: PropTypes.bool,
  anonymousUser: PropTypes.object.isRequired,
  notApplicableLabel: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  results: PropTypes.shape({}).isRequired,
  selectedQuestionId: PropTypes.number,
  onExpand: PropTypes.func.isRequired,
};

export default memo(ReportTextReplies);
