import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, withStyles } from '@material-ui/core';

const styles = ({ spacing }) => ({
  main: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: spacing(4),
  },
});

class SectionTitle extends PureComponent {
  render() {
    const {
      classes,
      className,
      title,
      iconImg: Icon,
      variant,
      textClassName,
      iconSize,
    } = this.props;

    return (
      <div className={classNames(classes.main, className)}>
        {Icon ? <Icon className={classNames(classes.icon, iconSize)} /> : null}
        <Typography className={textClassName} variant={variant}>
          {title}
        </Typography>
      </div>
    );
  }
}

SectionTitle.defaultProps = {
  className: '',
  iconImg: null,
  variant: 'h3',
  textClassName: '',
  iconSize: '',
};

SectionTitle.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  iconImg: PropTypes.object,
  variant: PropTypes.string,
  textClassName: PropTypes.string,
  iconSize: PropTypes.string,
};

export default withStyles(styles)(SectionTitle);
