import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';

import { getAnswerWeight } from 'utility/attribute';
import QuestionEntry from '../questionEntry';
import CustomButton from '../customButton';
import AlertDialog from '../alertDialog';
import ConditionalTooltip from '../conditionalTooltip';
import http from '../../../utility/http';
import {
  createSingleQuestion,
  duplicateQuestion,
  reorder,
  debounce,
  isArrayEmpty,
} from '../../../utility/helpers';
import { ATTRIBUTES } from '../../../constants/attributes';
import { AUTOMATION_ID } from '../../../constants/automationId';
import { api_question } from '../../../constants/apiRoutes';

const styles = ({ spacing }) => ({
  main: { width: '100%' },
  titleClass: {
    textTransform: 'none',
    marginBottom: spacing(6),
  },
  questions: {
    marginBottom: spacing(10),
  },
  removeSpace: {
    marginBottom: 0,
  },
});

const ADD_QUESTION_DELAY = 250;

class AddAttributeQuestions extends PureComponent {
  constructor(props) {
    super(props);

    this.onAddNewQuestion = debounce(
      this.onAddNewQuestion,
      ADD_QUESTION_DELAY
    ).bind(this);
    this.onUpdateQuestion = this.onUpdateQuestion.bind(this);
    this.onDuplicateQuestion = this.onDuplicateQuestion.bind(this);
    this.onDeleteQuestion = this.onDeleteQuestion.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  state = {
    questionId: -1,
    question: null,
    disabledDragItems: [],
  };

  onUpdateQuestion = updatedQuestion => {
    const { updateAttributeQuestion } = this.props;

    return updateAttributeQuestion(updatedQuestion);
  };

  onDuplicateQuestion(question) {
    const { setDialogVisibility } = this.props;

    this.setState({ question });

    setDialogVisibility({
      dialogName: 'confirmQuestionDuplicate',
      opened: true,
    });
  }

  handleSaveDuplicate = async () => {
    const { attribute, createQuestion, setDialogVisibility } = this.props;
    const { question } = this.state;

    const { data } = await http.get(api_question(question.id));

    return createQuestion(
      duplicateQuestion(
        data,
        attribute.questions.length + 1,
        attribute.id,
        attribute.with_neutral_answer
      )
    ).then(() => {
      setDialogVisibility({
        dialogName: 'confirmQuestionDuplicate',
        opened: false,
      });
    });
  };

  onDeleteQuestion(questionId) {
    const { setDialogVisibility } = this.props;

    setDialogVisibility({
      dialogName: 'confirmQuestionDeletion',
      opened: true,
    });

    this.setState({ questionId });
  }

  onDragEnd(result) {
    const { reorderQuestions, attribute, setAttribute } = this.props;

    if (
      !result.destination ||
      result.source.index === result.destination.index
    ) {
      return;
    }

    const newOrder = reorder(
      attribute.questions,
      result.source.index,
      result.destination.index
    );

    setAttribute({ questions: newOrder });

    reorderQuestions({
      attributeId: attribute.id,
      orderedIds: { order: newOrder.map(item => item.id) },
    });
  }

  onAddNewQuestion = () => {
    const { attribute, createQuestion, translations } = this.props;

    const { questions, answer_number, id } = attribute;

    const emptyQuestion = createSingleQuestion(
      translations.placeholders.questionTitle,
      questions.length + 1,
      answer_number,
      id
    );

    createQuestion(emptyQuestion);
  };

  render() {
    const {
      translations,
      classes,
      dialogs,
      setDialogVisibility,
      attribute,
      deleteAttributeQuestion,
      getAttribute,
      switchHorizontalWarning,
      questionsLimit,
    } = this.props;

    const { questionId, disabledDragItems } = this.state;

    const { confirmQuestionDeletionOpened, confirmQuestionDuplicateOpened } =
      dialogs;

    const isVertical = attribute.layout === ATTRIBUTES.verticalLayout;
    const hasDefinedAnswers =
      !attribute[ATTRIBUTES.fields.withTextReplyQuestions];
    const numberOfQuestions = attribute.questions.length;
    const isMaxQuestionsReached = numberOfQuestions >= questionsLimit;

    return (
      <Grid container spacing={0} className={classes.main}>
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.titleClass}>
            {translations.questions}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <div>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable">
                {provided => (
                  <div
                    ref={provided.innerRef}
                    className={classNames(classes.questions, {
                      [classes.removeSpace]: isArrayEmpty(attribute.questions),
                    })}
                  >
                    {attribute.questions.map((questionItem, index) => (
                      <Draggable
                        key={questionItem.id}
                        draggableId={questionItem.id.toString()}
                        index={index}
                        isDragDisabled={
                          !attribute.is_editable ||
                          disabledDragItems.includes(questionItem.id)
                        }
                      >
                        {providedItem => (
                          <QuestionEntry
                            id={`${AUTOMATION_ID.QUESTION}-${index}`}
                            draggableProps={providedItem}
                            forwardedRef={providedItem.innerRef}
                            key={`question_${questionItem.id}`}
                            translations={translations}
                            questionIndex={index}
                            item={questionItem}
                            answerWeights={questionItem.answers?.map(answer =>
                              getAnswerWeight(answer.weight)
                            )}
                            disableActions={!attribute.is_editable}
                            isVertical={isVertical}
                            hasDefinedAnswers={hasDefinedAnswers}
                            hasNeutralAnswer={attribute.with_neutral_answer}
                            isDuplicateDisabled={isMaxQuestionsReached}
                            onDuplicate={this.onDuplicateQuestion}
                            onDelete={this.onDeleteQuestion}
                            onSave={this.onUpdateQuestion}
                            switchHorizontalWarning={switchHorizontalWarning}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <ConditionalTooltip
              addTooltip={isMaxQuestionsReached}
              message={translations.maxNumberOfQuestions}
            >
              <CustomButton
                id={AUTOMATION_ID.ADD_BUTTON}
                type="addWithTextDarkSlimNew"
                disabled={isMaxQuestionsReached}
                onClick={this.onAddNewQuestion}
                fullWidth
              >
                {translations.newQuestion}
              </CustomButton>
            </ConditionalTooltip>
          </div>
        </Grid>
        <AlertDialog
          translations={translations.deleteQuestionDialog}
          isOpened={confirmQuestionDeletionOpened}
          onClose={() => {
            setDialogVisibility({
              dialogName: 'confirmQuestionDeletion',
              opened: false,
            });
          }}
          onConfirm={() => {
            deleteAttributeQuestion(questionId).then(() => {
              getAttribute(attribute.id).then(() => {
                setDialogVisibility({
                  dialogName: 'confirmQuestionDeletion',
                  opened: false,
                });
              });
            });
          }}
          isWarning
        />
        <AlertDialog
          translations={translations.duplicateQuestionDialog}
          isOpened={confirmQuestionDuplicateOpened}
          onClose={() => {
            setDialogVisibility({
              dialogName: 'confirmQuestionDuplicate',
              opened: false,
            });
          }}
          onConfirm={this.handleSaveDuplicate}
        />
      </Grid>
    );
  }
}

AddAttributeQuestions.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  setDialogVisibility: PropTypes.func.isRequired,
  attribute: PropTypes.object.isRequired,
  updateAttributeQuestion: PropTypes.func.isRequired,
  questionsLimit: PropTypes.number.isRequired,
  reorderQuestions: PropTypes.func.isRequired,
  setAttribute: PropTypes.func.isRequired,
  dialogs: PropTypes.object.isRequired,
  createQuestion: PropTypes.func.isRequired,
  deleteAttributeQuestion: PropTypes.func.isRequired,
  getAttribute: PropTypes.func.isRequired,
  switchHorizontalWarning: PropTypes.func.isRequired,
};

export default withStyles(styles)(AddAttributeQuestions);
