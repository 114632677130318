import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TransitionGroup } from 'react-transition-group';
import { Typography, Fade, makeStyles } from '@material-ui/core';
import AsyncListTooltip from '../../../asyncListTooltip';
import ListWithTooltip from '../../../listWithTooltip';
import Tag from '../../../tag';
import Agenda from '../agenda';
import { isArrayEmpty, isEllipsisActive } from '../../../../../utility/helpers';
import {
  getEmployeeAgenda,
  getModeratorAgenda,
} from '../../../../../utility/oneOnOne';
import { getContentLength } from '../../../../../utility/uiUtils';
import { ellipsis } from '../../../../../constants/helperCssRules';

const VISIBLE_TAGS = 3;

const useStyles = makeStyles(
  ({ palette: { primary }, breakpoints, spacing }) => ({
    root: {
      boxSizing: 'border-box',
      borderRight: `1px dotted ${primary.bluish6}`,
      padding: spacing(6),
    },
    rootMin: {
      padding: spacing(4, 3),
      [breakpoints.up('xLg')]: {
        padding: spacing(4),
      },
      [breakpoints.up('xl')]: {
        padding: spacing(6),
      },
    },
    contentWrapper: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridColumnGap: 16,
      gridRowGap: 24,
    },
    contentWrapperMin: {
      gridTemplateColumns: '1fr',
    },
    note: {
      color: primary.bluish3,
      marginBottom: spacing(1),
    },
    label: {
      color: primary.bluish3,
      marginBottom: spacing(1),
    },
    overflow: {
      ...ellipsis(),
      display: '-webkit-box',
      '-webkit-line-clamp': 3,
      '-webkit-box-orient': 'vertical',
      whiteSpace: 'normal',
    },
    tagsWrapper: {
      gridColumn: 'span 2',
    },
    tagsList: {
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      minHeight: 28,
      width: '100%',
    },
    tag: {
      margin: spacing(0, 2, 2, 0),
    },
    tagsListWrapperMini: {
      minHeight: 28,
      gridColumn: 'span 3',
    },
    tagsListMini: {
      gridTemplateColumns: `repeat(${VISIBLE_TAGS}, minmax(0,min-content)) minmax(auto, min-content)`,
      gridColumnGap: spacing(2),
    },
  })
);

const Content = ({
  translations,
  record,
  isFuture,
  isMiniCard,
  noteLabelComponent,
  onOpenCustomTopic,
}) => {
  const classes = useStyles();
  const { shared_note, tags } = record;
  const employeeAgenda = getEmployeeAgenda(record);
  const moderatorAgenda = getModeratorAgenda(record);

  const [hasSharedNoteTooltip, setHasSharedNoteTooltip] = useState(false);
  const sharedNoteRef = useRef(null);

  useEffect(() => {
    setHasSharedNoteTooltip(!!isEllipsisActive(sharedNoteRef?.current, true));
  }, [shared_note]);

  return (
    <div
      className={classNames(classes.root, {
        [classes.rootMin]: isMiniCard,
      })}
    >
      <div
        className={classNames(classes.contentWrapper, {
          [classes.contentWrapperMin]: isMiniCard,
        })}
      >
        <Agenda
          translations={translations}
          moderatorAgenda={moderatorAgenda}
          employeeAgenda={employeeAgenda}
          isMiniCard={isMiniCard}
          isFuture={isFuture}
          onOpenCustomTopic={onOpenCustomTopic}
        />
        {!isMiniCard && (
          <div>
            <Typography className={classes.label} variant="subtitle2">
              {translations.sharedNote}
            </Typography>
            {shared_note && getContentLength(shared_note) > 0 ? (
              <AsyncListTooltip
                position="top"
                labelData={[shared_note]}
                renderLabelItem={noteLabelComponent}
                isTooltipDisabled={!hasSharedNoteTooltip}
                isDisabled
              >
                <div
                  ref={sharedNoteRef}
                  className={classNames(classes.overflow, {
                    [classes.noteText]: !hasSharedNoteTooltip,
                  })}
                >
                  {noteLabelComponent(shared_note)}
                </div>
              </AsyncListTooltip>
            ) : (
              <Typography variant="body2">-</Typography>
            )}
          </div>
        )}
        <div className={classNames({ [classes.tagsWrapper]: !isMiniCard })}>
          <Typography className={classes.label} variant="subtitle2">
            {translations.tags}
          </Typography>
          {!isMiniCard &&
            (!isArrayEmpty(tags) ? (
              <TransitionGroup className={classes.tagsList}>
                {tags.map(tag => (
                  <Fade key={`tag_item_${tag.id}`} in>
                    <div className={classes.tag}>
                      <Tag tag={tag} color={tag.category.color} isSelected />
                    </div>
                  </Fade>
                ))}
              </TransitionGroup>
            ) : (
              <div className={classes.tagsList}>-</div>
            ))}
          {isMiniCard && (
            <div className={classes.tagsListWrapperMini}>
              <ListWithTooltip
                listClass={classes.tagsListMini}
                items={tags}
                asyncListProps={{
                  isDisabled: true,
                }}
                visibleItemsCount={VISIBLE_TAGS}
                isTagsList
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Content.defaultProps = {
  isMiniCard: false,
  isFuture: false,
  onOpenCustomTopic: () => {},
};

Content.propTypes = {
  translations: PropTypes.object.isRequired,
  isMiniCard: PropTypes.bool,
  isFuture: PropTypes.bool,
  record: PropTypes.object.isRequired,
  noteLabelComponent: PropTypes.func.isRequired,
  onOpenCustomTopic: PropTypes.func,
};

export default Content;
