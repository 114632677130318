export const COMPANY_OVERVIEW_STATS_TYPES = {
  ACTION_PLANS_OVERVIEW: 'actionPlansOverview',
  COURSES_OVERVIEW: 'coursesOverview',
  ONE_ON_ONE_OVERVIEW: 'oneOnOneOverview',
};

export const TEAMS_OVERVIEW__DEFAULT_PARAMS = {
  page: 1,
  page_size: 7,
};

// Two months period
export const COMPANY_OVERVIEW_DEFAULT_PERIOD_FILTER = 1;
