import { connect } from 'react-redux';
import { useParams } from 'react-router';
import PageContainer from '../../components/shared/pageContainer';
import CourseDetailsPage from '../../components/pages/courseDetailsPage';
import { peopleSelector } from '../../store/selectors/peopleSelector';
import { dialogSelector } from '../../store/selectors/dialogSelector';
import { courseDetailsSelector } from '../../store/selectors/courseDetailsSelector';
import { surveysSelector } from '../../store/selectors/surveysSelector';
import { tagsSelector } from '../../store/selectors/tagsSelector';
import {
  getCourse,
  setCourse,
  getCourseEnrolledUsers,
  clearCourse,
  clearCourseEnrolledUsers,
} from '../../store/modules/courseDetails';
import {
  getTagsCategories,
  clearTagsCategories,
  addMultipleTags,
} from '../../store/modules/tags';
import { getAllUsers, clearAllUsers } from '../../store/modules/people';
import {
  getAttributesWithQuestions,
  clearAttributesWithQuestions,
} from '../../store/modules/surveys';
import { useTranslations } from '../../utility/useTranslations';
import { APP_PAGES } from '../../constants/pages';

const mapStateToProps = state => ({
  ...courseDetailsSelector(state),
  ...dialogSelector(state),
  ...tagsSelector(state),
  allAttributes: [...(surveysSelector(state)?.attributesWithQuestions || [])],
  allUsers: [...peopleSelector(state).allUsers],
});

const mapDispatchToProps = dispatch => ({
  getCourse: courseId => getCourse(dispatch, courseId),
  getCourseEnrolledUsers: (courseId, params) =>
    getCourseEnrolledUsers(dispatch, courseId, params),
  setCourse: data => dispatch(setCourse(data)),
  clearCourse: () => dispatch(clearCourse()),
  clearCourseEnrolledUsers: () => dispatch(clearCourseEnrolledUsers()),
  getAttributesWithQuestions: params =>
    getAttributesWithQuestions(dispatch, params),
  clearAttributesWithQuestions: () => dispatch(clearAttributesWithQuestions()),
  addMultipleTags: data => dispatch(addMultipleTags(data)),
  getTagsCategories: () => getTagsCategories(dispatch),
  clearTagsCategories: () => dispatch(clearTagsCategories()),
  getAllUsers: data => getAllUsers(dispatch, data),
  clearAllUsers: () => dispatch(clearAllUsers()),
});

const CourseDetailsPageView = props => {
  const translations = useTranslations(APP_PAGES.COURSE_DETAILS);
  const { courseId } = useParams();

  return (
    <PageContainer
      {...props}
      translations={translations}
      matchedCourseId={courseId}
    >
      <CourseDetailsPage />
    </PageContainer>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseDetailsPageView);
