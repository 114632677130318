import { PARAMS } from './pages';

export const ACTION_PLAN_FIELDS = {
  CREATOR: 'creator',
  CREATED_ON: 'created_on',
  COURSES: 'courses',
  DESCRIPTION: 'description',
  DUE_DATE: 'due_date',
  STATUS: 'is_finished',
  NOTIFY: 'notify',
  USER: 'user',
  TITLE: 'title',
  TYPE: 'type',
  TAGS: 'tags',
  TASKS: 'tasks',
  PROGRESS: 'progress',
  MODIFIED_ON: 'modified_on',
  IS_PROPOSED: 'is_proposed',
  IS_PENDING_PROPOSAL: 'is_pending_proposal',
};

export const ACTION_POINT_ACTIVITY_TYPES = {
  COMMENT: 'COMMENT',
  DESCRIPTION: 'DESCRIPTION',
  DUE_DATE: 'DUE_DATE',
  STATUS: 'STATUS',
};

export const ACTION_POINT_ACTIVITY_FIELDS = {
  NEW_COMMENT: 'comment',
  NEW_DESCRIPTION: ACTION_PLAN_FIELDS.DESCRIPTION,
  NEW_DUE_DATE: ACTION_PLAN_FIELDS.DUE_DATE,
  PREVIOUS_DUE_DATE: 'previous_due_date',
  CREATED_AT: 'created_at',
  EDITOR: 'editor',
  NEW_TYPE: 'type',
  PREVIOUS_TYPE: 'previous_type',
  NEW_STATUS: ACTION_PLAN_FIELDS.STATUS,
  PREVIOUS_STATUS: 'previous_status',
};

export const ACTION_PLAN_COMMENTS_DEFAULT_PARAMS = {
  [PARAMS.PAGE]: 1,
  [PARAMS.PAGE_SIZE]: 10,
};

export const DUE_DATE_TIME_FORMAT = 'MMM d, yyyy';

