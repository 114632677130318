import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import Autocomplete from '../autocomplete';
import InputField from '../inputField';
import SelectField from '../selectField';
import CustomCheckbox from '../customCheckbox';
import CustomButton from '../customButton';
import ArrowTooltip from '../arrowTooltip';
import ActionButton from '../actionButton';
import { ReactComponent as ErrorIcon } from '../../../assets/icons/warning-triangle.svg';
import { replaceObjectInList } from '../../../utility/helpers';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';
import { FIELD_TYPES } from '../../../constants/fieldTypes';
import { UserStatuses } from '../../../constants/statuses';
import { USER_INFO } from '../../../constants/people';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    display: 'grid',
  },
  description: {
    marginBottom: spacing(6),
  },
  header: {
    marginBottom: spacing(0.25),
  },
  body: {
    display: 'grid',
    gridRowGap: 1,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: ({ numberOfColumns, hasInvite, hasDelete }) =>
      `repeat(${
        hasInvite ? numberOfColumns - 1 : numberOfColumns
      }, minmax(0, 1fr)) ${hasInvite ? '110px' : ''} ${
        hasDelete ? '38px' : ''
      }`,
    gridColumnGap: 1,
  },
  cell: {
    boxSizing: 'border-box',
    boxShadow: `0 0 0 1px ${primary.bluish6}`,
    display: 'flex',
    alignItems: 'center',
    padding: spacing(2.5, 4),
    minHeight: 49,
    transition: 'box-shadow .25s ease',
  },
  cellContent: {
    color: primary.bluish1,
    fontFamily: 'ProximaNova-Regular',
    fontSize: 16,
    lineHeight: '18px',
  },
  cellHover: {
    '&:hover': {
      boxShadow: `0 0 0 1px ${primary.bluish3}`,
      position: 'relative',
      zIndex: 100,
    },
  },
  input: {
    borderRadius: 'unset',
    border: 'none',

    '& input': {
      padding: 0,
      '&::placeholder': {
        color: primary.bluish4,
        fontSize: 16,
        lineHeight: '18px !important',
        letterSpacing: 'normal',
      },
    },
  },
  columnCell: {
    backgroundColor: primary.bluish8,
    padding: spacing(3.75, 4),
  },
  focus: {
    position: 'relative',
    border: 'none !important',
    boxShadow: `0 0 0 1px ${primary.bluish3}`,
    zIndex: 100,
  },
  focusDisabled: {
    border: 'none !important',
    boxShadow: `0 0 0 1px ${primary.bluish6}`,
  },
  selectField: {
    minWidth: 'auto',
  },
  selectFieldControl: {
    boxSizing: 'border-box',
    border: 'none',
    borderRadius: 0,
    boxShadow: `0 0 0 1px ${primary.bluish6}`,
    display: 'flex',
    alignItems: 'center',
    padding: spacing(1, 4),
    minHeight: 49,
    transition: 'box-shadow .25s ease',
    outline: 'none',
  },
  disabledField: {
    border: 'none !important',
  },
  addButton: {
    backgroundColor: primary.bluish8,
    border: 'none',
    boxShadow: `0 0 0 1px ${primary.bluish5}`,
    borderRadius: 0,
    height: 38,
    padding: 7,
    marginTop: 1,

    '&:hover': {
      backgroundColor: primary.bluish8,
    },
  },
  removeUserWrapper: {
    backgroundColor: primary.bluish8,
    boxShadow: `0 0 0 1px ${primary.bluish5}`,
  },
  removeUserButton: {
    borderRadius: 0,
    width: 38,
    height: 49,
  },
  menu: {
    zIndex: 101,
  },
  autocompleteMenu: {
    top: 58,
  },
  error: {
    border: 'none !important',
    boxShadow: `0 0 0 1px ${primary.red1}`,
    position: 'relative',
    zIndex: 100,
  },
  errorMessage: {
    margin: spacing(2),
  },
  errorIcon: {
    marginLeft: spacing(1.5),
    cursor: 'pointer',
  },
}));

const [NOT_INVITED, INVITED] = UserStatuses;

const AddUsersTableField = ({
  translations,
  data: {
    description,
    currentUser,
    isAdmin,
    columns,
    hasInvite,
    hasAddButton,
    hasDelete,
    roles,
    reportToOptions,
    userAddType,
    getNewUser,
    onMentorChange,
    onChangeReportTo,
  },
  organizationUser,
  users,
  errors,
  onChange,
}) => {
  const classes = useStyles({
    numberOfColumns: columns?.length,
    hasInvite,
    hasDelete,
  });
  const isDeleteDisabled = users.length === 1;

  const handleValueChange = (field, rowIndex, value) => {
    const updatedUsers = replaceObjectInList(users, rowIndex, {
      [field.name]: value,
    });

    onChange(updatedUsers, rowIndex);
  };

  const handleAddUser = () => {
    onChange(
      [...users, getNewUser(currentUser, isAdmin, userAddType)],
      users.length
    );
  };

  const handleDeleteUser = rowIndex => () => {
    onChange(
      users.filter((user, index) => index !== rowIndex),
      rowIndex
    );
  };

  const handleMentorChange = async (field, rowIndex, user, searchValue) => {
    handleValueChange(field, rowIndex, []);
    await onMentorChange(user)(searchValue);
  };

  const handleMentorSelect = async (field, rowIndex, user, newSelected) => {
    await onMentorChange()();

    if (user.id) {
      const [newReportTo] = newSelected;

      await onChangeReportTo(
        user.id,
        {
          [USER_INFO.REPORT_TO]: newReportTo.id,
        },
        false
      );
    }

    handleValueChange(field, rowIndex, newSelected);
  };

  const handleInvitationChange = (field, rowIndex, isChecked) => {
    handleValueChange(field, rowIndex, isChecked ? INVITED.id : NOT_INVITED.id);
  };

  const renderErrorMessage = errorMessage => (
    <Typography variant="body2" className={classes.errorMessage}>
      {errorMessage}
    </Typography>
  );

  const renderError = errorMessage => (
    <ArrowTooltip
      position="top"
      tooltipLabel={renderErrorMessage(errorMessage)}
    >
      <ErrorIcon className={classes.errorIcon} />
    </ArrowTooltip>
  );

  const renderCell = (field, user, rowIndex) => {
    const { AUTOCOMPLETE, SELECT, CHECKBOX } = FIELD_TYPES;
    const { formLabels } = translations;
    const key = `user_info_${field.name}`;
    const hasError = !!errors?.[rowIndex]?.[field.name];
    const errorMessage =
      translations.validationMessages?.[errors?.[rowIndex]?.[field.name]];

    switch (field.type) {
      case AUTOCOMPLETE:
        return (
          <Autocomplete
            key={key}
            customInputRootClass={classNames(
              classes.cell,
              classes.input,
              classes.cellContent,
              {
                [classes.cellHover]: !field.isDisabled,
                [classes.error]: hasError,
              }
            )}
            customInputFocusClass={classNames(classes.focus, {
              [classes.focusDisabled]: field.isDisabled,
            })}
            customInputDisabledClass={classes.disabledField}
            customMenuClass={classNames(classes.menu, classes.autocompleteMenu)}
            id={field.name}
            name={field.name}
            placeholder={formLabels[field.key || field.name].placeholder}
            noOptionsLabel={formLabels[field.key || field.name].noOptions}
            items={reportToOptions}
            organizationUser={organizationUser}
            selectedItems={user[field.name] || []}
            hasError={hasError}
            errorMessage=""
            isDisabled={field.isDisabled}
            initialValue={field.initialValue}
            inputEndAdornment={hasError ? renderError(errorMessage) : null}
            onChange={newSelected =>
              handleMentorSelect(field, rowIndex, user, newSelected)
            }
            onInputChange={value =>
              handleMentorChange(field, rowIndex, user, value)
            }
            onBlur={() => onMentorChange()()}
            hasAvatar
            isSingleSelect
            isAsync
          />
        );
      case SELECT:
        return (
          <SelectField
            key={key}
            className={classes.selectField}
            controlClass={classNames(
              classes.selectFieldControl,
              classes.cellHover,
              { [classes.error]: hasError }
            )}
            controlFocusClass={classNames(classes.focus, {
              [classes.focusDisabled]: field.isDisabled,
            })}
            controlDisabledClass={classes.disabledField}
            menuClass={classes.menu}
            id={field.name}
            placeholder={formLabels[field.key || field.name]?.placeholder}
            name={field.name}
            options={roles}
            parser={field.parser}
            value={user[field.name]}
            isDisabled={field.isDisabled}
            hasError={hasError}
            errorAdornment={hasError && renderError(errorMessage)}
            onChange={selectedOption =>
              handleValueChange(field, rowIndex, selectedOption)
            }
            isSearchDisabled
            shouldRemoveLabel
            isFullWidth
          />
        );
      case CHECKBOX:
        return (
          <div
            key={key}
            className={classNames(classes.cell, classes.cellHover)}
          >
            <CustomCheckbox
              customRootClass={classNames({
                [classes.checkbox]: field.shouldTopAlign,
              })}
              isChecked={user[field.name] === INVITED.id}
              onChange={isChecked =>
                handleInvitationChange(field, rowIndex, isChecked)
              }
              isControlled
            />
          </div>
        );
      default:
        return (
          <InputField
            key={key}
            customRootClass={classNames(
              classes.cell,
              classes.input,
              classes.cellContent,
              {
                [classes.cellHover]: !field.isDisabled,
                [classes.error]: hasError,
              }
            )}
            customFocusClass={classNames(classes.focus, {
              [classes.focusDisabled]: field.isDisabled,
            })}
            id={field.name}
            type={field.type}
            name={field.name}
            placeholder={formLabels[field.key || field.name].placeholder}
            value={user[field.name] || ''}
            error={hasError}
            endAdornment={hasError && renderError(errorMessage)}
            onChange={e => handleValueChange(field, rowIndex, e.target.value)}
            disabled={field.isDisabled}
            fullWidth
          />
        );
    }
  };

  const renderHeader = () => (
    <div className={classNames(classes.grid, classes.header)}>
      {columns?.map(column => (
        <div
          key={`column_${column.name}`}
          className={classNames(classes.cell, classes.columnCell)}
        >
          <Typography variant="subtitle1">
            {translations.formLabels[column.key].label}
          </Typography>
        </div>
      ))}
      {hasDelete && (
        <div className={classNames(classes.cell, classes.columnCell)} />
      )}
    </div>
  );

  const renderBody = () => {
    return (
      <div className={classes.body}>
        {users.map((user, index) => (
          <div key={`row_${index}`} className={classes.grid}>
            {columns?.map(column => renderCell(column, user, index))}
            {hasDelete && (
              <div
                className={classNames(classes.removeUserWrapper, {
                  [classes.cellHover]: !isDeleteDisabled,
                })}
              >
                <ActionButton
                  className={classes.removeUserButton}
                  type={ACTION_BUTTON_TYPES.REMOVE}
                  isDisabled={isDeleteDisabled}
                  onClickHandler={handleDeleteUser(index)}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.description} variant="body2">
        {description}
      </Typography>
      {renderHeader()}
      {renderBody()}
      {hasAddButton && (
        <CustomButton
          type="addWithTextDarkSlimNew"
          className={classes.addButton}
          onClick={handleAddUser}
        >
          {translations.addUserButton}
        </CustomButton>
      )}
    </div>
  );
};

AddUsersTableField.defaultProps = {
  errors: {},
  organizationUser: null,
  data: {},
};

AddUsersTableField.propTypes = {
  translations: PropTypes.object.isRequired,
  data: PropTypes.shape({
    description: PropTypes.string.isRequired,
    currentUser: PropTypes.shape({}),
    isAdmin: PropTypes.bool,
    columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    hasInvite: PropTypes.bool.isRequired,
    hasAddButton: PropTypes.bool.isRequired,
    hasDelete: PropTypes.bool.isRequired,
    roles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    userAddType: PropTypes.string,
    getNewUser: PropTypes.func,
    onOpenUpgradeDialog: PropTypes.func,
    onMentorChange: PropTypes.func.isRequired,
    onChangeReportTo: PropTypes.func,
  }),
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  organizationUser: PropTypes.object,
  errors: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
};

export default memo(AddUsersTableField);
