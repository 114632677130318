import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import { ReactComponent as ProposeIcon } from '../../../assets/icons/person-search.svg';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    border: `1px solid ${primary.bluish1}`,
    display: 'flex',
    alignItems: 'center',
    padding: spacing(1, 2),
    width: 'fit-content',
  },
  icon: {
    marginRight: spacing(1),
  },
  label: {
    fontSize: 10,
    lineHeight: '12px',
    textTransform: 'uppercase',
    userSelect: 'none',
  },
}));

const ProposedStatus = ({ proposedLabel }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ProposeIcon className={classes.icon} />
      <Typography className={classes.label} variant="overline">
        {proposedLabel}
      </Typography>
    </div>
  );
};

ProposedStatus.propTypes = {
  proposedLabel: PropTypes.string.isRequired,
};

export default ProposedStatus;
