import { memo } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { Typography, makeStyles } from '@material-ui/core';
import http from '../../../utility/http';
import { API_REQUEST_RESET_PASSWORD } from '../../../constants/apiRoutes';
import BackLink from '../../shared/backLink';
import { RESET_PASSWORD_URL } from '../../../constants/appConfig';
import { ReactComponent as MailIcon } from '../../../assets/icons/mail_outline.svg';
import { useTranslations } from '../../../utility/useTranslations';
import { APP_PAGES } from '../../../constants/pages';
import BackgroundContainer from '../../shared/backgroundContainer';
import FormLayout from '../../shared/formLayout';
import NextButton from '../../shared/nextButton';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  main: {
    display: 'block',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    display: 'grid',
    justifyContent: 'center',
  },
  description: {
    marginBottom: spacing(6),
    [breakpoints.up('xLg')]: {
      marginBottom: spacing(8),
    },
    [breakpoints.up('xl')]: {
      marginBottom: spacing(10),
    },
  },
  backLink: {
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    padding: spacing(6, 8, 4, 8),
    [breakpoints.up('xLg')]: {
      padding: spacing(8, 10, 8, 10),
    },
    [breakpoints.up('xl')]: {
      padding: spacing(8, 10, 11, 10),
    },
  },
  descriptionText: {
    textAlign: 'center',
    display: 'flex',
    wordBreak: 'break-word',
    marginBottom: spacing(3),
    [breakpoints.up('xLg')]: {
      marginBottom: spacing(4),
    },
    [breakpoints.up('xl')]: {
      marginBottom: spacing(6),
    },
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  nextButton: {
    marginBottom: 0,
  },
  formWrapper: {
    marginBottom: 0,
  },
}));

const ResetLinkSentPage = ({ history }) => {
  const { email, resend } = useParams();
  const classes = useStyles();
  const translations = useTranslations(APP_PAGES.RESET_LINK);
  const sentTo = translations.resetLink.replace('[EMAIL]', email);

  const resendPasswordLink = () => {
    http
      .post(API_REQUEST_RESET_PASSWORD, {
        email,
        activationUrl: RESET_PASSWORD_URL,
        clientDomain: window.document.location.origin,
      })
      .then(() => {
        history.push(`/reset-link-sent/${email}/resend`);
      });
  };

  return (
    <BackgroundContainer signUpPage translations={translations.authContainer}>
      <form>
        <div className={classes.main}>
          <div className={classes.backLink}>
            <BackLink
              title={translations.backLink}
              history={history}
              to="/login"
            />
          </div>
          <div className={classes.content}>
            <FormLayout
              childrenClass={classes.formWrapper}
              icon={MailIcon}
              field={{ ...translations }}
            >
              <div>
                <div className={classes.description}>
                  <Typography className={classes.descriptionText}>
                    {sentTo}
                  </Typography>
                  <Typography className={classes.descriptionText}>
                    {translations.checkIfMissing}
                  </Typography>
                </div>
                <NextButton
                  onClick={resendPasswordLink}
                  isDisabled={resend !== undefined}
                  buttonLabel={translations.submit}
                  isSubmit={false}
                  customClass={classes.nextButton}
                />
              </div>
            </FormLayout>
          </div>
        </div>
      </form>
    </BackgroundContainer>
  );
};

ResetLinkSentPage.propTypes = {
  history: PropTypes.object.isRequired,
};

export default memo(ResetLinkSentPage);
