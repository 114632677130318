import { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import ConditionalTooltip from '../conditionalTooltip';
import LevelBars from '../levelBars';
import UserCount from '../userCount';
import StatusIndicator from '../statusIndicator';
import DotsMenu from '../dotsMenu';
import AttributeChip from '../attributeChip';
import ListWithTooltip from '../listWithTooltip';
import { isEllipsisActive, isArrayEmpty } from '../../../utility/helpers';
import { ellipsis } from '../../../constants/helperCssRules';

const VISIBLE_TAGS = 4;

const styles = ({ palette: { primary }, spacing }) => ({
  main: {
    boxSizing: 'border-box',
    borderRadius: 8,
    border: `1px solid ${primary.bluish6}`,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    width: 434,
    minHeight: 180,
    transition: 'border .2s ease',
    '&:hover': {
      border: `1px solid ${primary.blue1}`,
    },
  },
  content: {
    boxSizing: 'border-box',
    display: 'grid',
    gridTemplateColumns: 'minmax(154px, min-content) minmax(0, 1fr)',
    height: '100%',
    padding: spacing(4, 4, 3),
  },
  contentWithActions: {
    gridTemplateColumns: 'minmax(154px, min-content) minmax(0, 1fr) 28px',
  },
  coverImageWrapper: {
    backgroundColor: primary.bluish5,
    borderRadius: 8,
    flexShrink: 0,
    marginRight: spacing(4),
    width: 138,
    height: 105,
  },
  coverImage: {
    borderRadius: 8,
    width: '100%',
    height: '100%',
  },
  courseInfo: {
    width: '100%',
    overflow: 'hidden',
  },
  title: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    marginBottom: spacing(2),
    overflow: 'hidden',
  },
  description: {
    ...ellipsis(),
    wordBreak: 'break-word',
    marginBottom: spacing(2),
  },
  footer: {
    boxSizing: 'border-box',
    backgroundColor: primary.bluish9,
    borderRadius: '0px 0px 8px 8px',
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    height: 43,
    padding: spacing(0, 4),
  },
  userCount: {
    flexShrink: 0,
    marginLeft: spacing(4),
  },
  attribute: {
    marginLeft: spacing(4),
  },
  linkWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    marginLeft: spacing(4),
  },
  link: {
    textDecoration: 'none',
    marginLeft: spacing(1),
  },
  linkText: {
    color: primary.blue1,
  },
  dotsMenu: {
    flexShrink: 0,
    width: 24,
    height: 24,
    marginLeft: spacing(1),
  },
  tagsWrapper: {
    minHeight: 28,
    gridColumn: 'span 3',
  },
  tagsList: {
    gridTemplateColumns: `repeat(${VISIBLE_TAGS}, minmax(0,min-content)) minmax(auto, min-content)`,
    gridColumnGap: spacing(2),
  },
});

class CourseCard extends PureComponent {
  constructor(props) {
    super(props);

    this.titleRef = createRef();
    this.descriptionRef = createRef();
    this.state = {
      hasTitleTooltip: false,
      hasDescriptionTooltip: false,
    };
  }

  componentDidMount() {
    this.shouldShowTooltips(
      this.titleRef.current,
      this.descriptionRef?.current
    );
  }

  componentDidUpdate(prevProps) {
    const { course } = this.props;

    if (
      prevProps.course.title !== course.title ||
      prevProps.course.description !== course.description
    ) {
      this.shouldShowTooltips(
        this.titleRef.current,
        this.descriptionRef?.current
      );
    }
  }

  shouldShowTooltips = (titleNode, descriptionNode) => {
    return this.setState({
      hasTitleTooltip: !!isEllipsisActive(titleNode, true),
      hasDescriptionTooltip: isEllipsisActive(descriptionNode),
    });
  };

  renderCoverImage = coverImage => {
    const { classes } = this.props;

    return (
      <div className={classes.coverImageWrapper}>
        {coverImage && (
          <img
            className={classes.coverImage}
            src={coverImage}
            alt="course cover"
          />
        )}
      </div>
    );
  };

  renderInfo = (isTitle = true) => {
    const { classes, course } = this.props;
    const { hasTitleTooltip, hasDescriptionTooltip } = this.state;
    const { title, description } = course;

    return (
      <ConditionalTooltip
        addTooltip={isTitle ? hasTitleTooltip : hasDescriptionTooltip}
        message={isTitle ? title : description}
      >
        <Typography
          ref={isTitle ? this.titleRef : this.descriptionRef}
          className={classNames({
            [classes.title]: isTitle,
            [classes.description]: !isTitle,
          })}
          variant={isTitle ? 'h5' : 'body2'}
        >
          {isTitle ? title : description}
        </Typography>
      </ConditionalTooltip>
    );
  };

  renderTags = tags => {
    const { classes } = this.props;

    return (
      <div className={classes.tagsWrapper}>
        <ListWithTooltip
          listClass={classes.tagsList}
          items={tags}
          asyncListProps={{
            isDisabled: true,
            shouldDisablePortal: false,
          }}
          visibleItemsCount={VISIBLE_TAGS}
          isTagsList
        />
      </div>
    );
  };

  renderFooter = () => {
    const {
      classes,
      translations,
      course,
      levels,
      hasCourseUrl,
      shouldDisablePortal,
      hasEnrolledUsersCount,
      getCourseEnrolledUsers,
    } = this.props;
    const { attribute, level, enrolledUsers, url } = course;

    return (
      <div className={classes.footer}>
        <LevelBars
          noLevelLabel={translations.all}
          levels={levels}
          level={level}
        />
        {hasEnrolledUsersCount && (
          <UserCount
            className={classes.userCount}
            tooltipTitle={translations.enrolledUsers}
            userCount={enrolledUsers}
            shouldDisablePortal={shouldDisablePortal}
            getUsers={getCourseEnrolledUsers}
            isTooltipDisabled={enrolledUsers === 0}
            isReadOnly
          />
        )}
        {attribute && (
          <AttributeChip
            customClass={classes.attribute}
            name={attribute.name}
            color={attribute.color}
          />
        )}
        {hasCourseUrl && (
          <div className={classes.linkWrapper}>
            <Typography variant="subtitle2">{translations.url}:</Typography>
            <Link
              className={classes.link}
              to={url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={e => e.stopPropagation()}
            >
              <Typography className={classes.linkText} variant="subtitle2">
                {translations.visitCourse}
              </Typography>
            </Link>
          </div>
        )}
      </div>
    );
  };

  render() {
    const {
      className,
      classes,
      course,
      onCardClick,
      actions,
      statuses,
      isStatusSelectable,
      isDisabled,
      onChangeStatus,
    } = this.props;
    const { description, coverImage, enrollmentStatus, tags } = course;
    const hasActions = !isArrayEmpty(actions);

    return (
      <div
        className={classNames(classes.main, className)}
        onClick={onCardClick}
      >
        <div
          className={classNames(classes.content, {
            [classes.contentWithActions]: hasActions,
          })}
        >
          {this.renderCoverImage(coverImage)}
          <div className={classes.courseInfo}>
            {this.renderInfo()}
            {description && this.renderInfo(false)}
            {enrollmentStatus && (
              <StatusIndicator
                status={enrollmentStatus}
                statuses={statuses}
                isSelectable={!isDisabled && isStatusSelectable}
                onSelect={onChangeStatus}
              />
            )}
          </div>
          {hasActions && (
            <DotsMenu className={classes.dotsMenu} menuItems={actions} />
          )}
          {!isArrayEmpty(tags) && this.renderTags(tags)}
        </div>
        {this.renderFooter()}
      </div>
    );
  }
}

CourseCard.defaultProps = {
  className: null,
  hasCourseUrl: false,
  hasEnrolledUsersCount: false,
  isStatusSelectable: false,
  isDisabled: false,
  shouldDisablePortal: true,
  statuses: [],
  actions: [],
  onChangeStatus: () => {},
  getCourseEnrolledUsers: () => {},
};

CourseCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  course: PropTypes.shape({}).isRequired,
  hasCourseUrl: PropTypes.bool,
  hasEnrolledUsersCount: PropTypes.bool,
  levels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  statuses: PropTypes.arrayOf(PropTypes.shape({})),
  isStatusSelectable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.shape({})),
  shouldDisablePortal: PropTypes.bool,
  onCardClick: PropTypes.func.isRequired,
  onChangeStatus: PropTypes.func,
  getCourseEnrolledUsers: PropTypes.func,
};

export default withStyles(styles)(CourseCard);
