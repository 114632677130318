import { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DraggableScroll from '../draggableScroll';
import FreeTextAnswer from './freeTextAnswer';
import VerticalAnswer from './verticalAnswer';
import HorizontalAnswers from './horizontalAnswers';
import {
  isAnswerSelected,
  updateScrollPosition,
} from '../../../utility/fillSurveyUtils';

const styles = ({ breakpoints }) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
  },
  draggableScroll: {
    minHeight: 232,
    [breakpoints.up('sm')]: {
      minHeight: 'auto',
    },
  },
});

class Answers extends PureComponent {
  constructor(props) {
    super(props);

    this.draggableScrollRef = createRef();
  }

  componentDidMount() {
    updateScrollPosition(this.draggableScrollRef.current);
  }

  componentDidUpdate(prevProps) {
    const { questionId } = this.props;

    if (prevProps.questionId !== questionId) {
      updateScrollPosition(this.draggableScrollRef.current);
    }
  }

  renderAnswers = () => {
    const {
      translations,
      answers,
      isSingleReview,
      withTextReplyQuestions,
      subjects,
      results,
      isVertical,
      questionId,
      hasNeutralAnswer,
      answerReplyErrors,
      isPreview,
      isAttributePreview,
      onSetAnswerReplyErrors,
      onSelect,
      onChange,
    } = this.props;

    if (withTextReplyQuestions) {
      return subjects.map(subject => (
        <FreeTextAnswer
          key={`answer_item_${subject.id}`}
          translations={translations}
          initialAnswer={results?.[subject.review_tracking]?.[questionId]}
          questionId={questionId}
          subject={subject}
          error={answerReplyErrors?.[subject.review_tracking]}
          isSingleReview={isSingleReview}
          hasNeutralAnswer={hasNeutralAnswer}
          isDisabled={isPreview}
          onSetError={onSetAnswerReplyErrors}
          onChange={onChange}
        />
      ));
    }

    if (isVertical) {
      return answers.map(answer => (
        <VerticalAnswer
          key={`answer_item_${answer.id}`}
          questionId={questionId}
          subjects={subjects}
          answer={answer}
          selection={results}
          neutralAnswerLabel={translations.neutralAnswer.label}
          isSingleReview={isSingleReview}
          isDisabled={isPreview}
          isAttributePreview={isAttributePreview}
          getSelectedAnswer={isAnswerSelected}
          onSelect={onSelect}
        />
      ));
    }

    return (
      <HorizontalAnswers
        questionId={questionId}
        isSingleReview={isSingleReview}
        neutralAnswerLabel={translations.neutralAnswer.label}
        weightLabel={translations.weight}
        answers={answers}
        selection={results}
        subjects={subjects}
        isDisabled={isPreview}
        isAttributePreview={isAttributePreview}
        getSelectedAnswer={isAnswerSelected}
        onSelect={onSelect}
      />
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <DraggableScroll
        ref={this.draggableScrollRef}
        className={classes.draggableScroll}
        showDesktopScrollbars
      >
        <div className={classes.main}>{this.renderAnswers()}</div>
      </DraggableScroll>
    );
  }
}

Answers.defaultProps = {
  questionId: 0,
  subjects: [],
  isSingleReview: false,
  withTextReplyQuestions: false,
  isPreview: false,
  isAttributePreview: false,
  hasNeutralAnswer: false,
  results: {},
  answerReplyErrors: {},
  onSetAnswerReplyErrors: () => {},
  onSelect: () => {},
  onChange: () => {},
};

Answers.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.shape({}).isRequired,
  isVertical: PropTypes.bool.isRequired,
  isSingleReview: PropTypes.bool,
  withTextReplyQuestions: PropTypes.bool,
  isPreview: PropTypes.bool,
  isAttributePreview: PropTypes.bool,
  hasNeutralAnswer: PropTypes.bool,
  answers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  subjects: PropTypes.arrayOf(PropTypes.shape({})),
  results: PropTypes.shape({}),
  questionId: PropTypes.number,
  answerReplyErrors: PropTypes.shape({}),
  onSetAnswerReplyErrors: PropTypes.func,
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
};

export default withStyles(styles)(Answers);
