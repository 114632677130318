import { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Typography,
  Popover,
  MenuList,
  MenuItem,
  makeStyles,
  alpha,
} from '@material-ui/core';
import CustomScrollBar from 'components/shared/customScrollBar';
import SubMenu from './subMenu';
import { ReactComponent as SubmenuArrowIcon } from '../../../../assets/icons/arrow-right-dark.svg';
import { getSubMenuItems, shouldRenderSubMenu } from '../config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  popover: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    marginTop: spacing(3),
    paddingRight: spacing(1.5),
    overflowX: 'visible',
    overflowY: 'visible',
  },
  menu: {
    backgroundColor: primary.white,
    boxSizing: 'border-box',
    border: `1px solid ${primary.bluish6}`,
    borderRadius: 4,
    filter: `drop-shadow(0px 4px 10px ${alpha(primary.black, 0.2)})`,
    width: 255,
    padding: 0,
    maxHeight: 430,
    display: 'flex',
    flexDirection: 'column',
  },
  menuItemContent: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  menuItem: {
    padding: spacing(4),
    '&:hover': {
      backgroundColor: primary.bluish8,
    },
  },
  menuItemActive: {
    backgroundColor: primary.bluish8,
  },
  menuItemLabel: {
    flexGrow: 1,
    '-webkit-line-clamp': 3,
    overflow: 'hidden',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    marginBottom: spacing(-0.75),
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    marginRight: spacing(2),
    width: 16,
    height: 16,
  },
  arrowIconWrapper: {
    marginRight: 0,
    marginLeft: spacing(2),
  },
  wrapper: {
    boxShadow: `0px 24px 3px -24px ${primary.bluish5}`,
    '& > span:last-child': {
      zIndex: 9999,
    },
  },
  scrollY: {
    top: 0,
    right: 0,
    height: '100%',
  },
}));

const Menu = ({
  translations,
  menuItems,
  anchorEl,
  selectedSubMenu,
  selectedFilters,
  onToggleSubMenu,
  searchTerm,
  onClose,
  onFilterSearch,
  onSelectFilter,
  onApply,
}) => {
  const classes = useStyles();
  const subMenuItems = useMemo(
    () => getSubMenuItems(menuItems, selectedSubMenu),
    [menuItems, selectedSubMenu]
  );
  const shouldRenderSubmenu = useMemo(
    () => shouldRenderSubMenu(selectedSubMenu, subMenuItems),
    [subMenuItems, selectedSubMenu]
  );

  return (
    <Popover
      anchorEl={anchorEl}
      open={!!anchorEl}
      PaperProps={{
        classes: { root: classes.popover },
      }}
      onClose={onClose}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom',
      }}
      keepMounted
    >
      <MenuList classes={{ padding: classes.menu }}>
        <CustomScrollBar
          customScrollBarYClass={classes.scrollY}
          verticalScroll
          removeScrollX
          passContentHeight
          passContentWidth
        >
          <>
            {menuItems.map(filter => {
              const { icon: Icon } = filter;
              const addedFilters = filter.parent
                ? filter.items.filter(item =>
                    selectedFilters[filter.parent.id].includes(item.id)
                  )?.length
                : selectedFilters[filter.id]?.length;

              return (
                <MenuItem
                  key={filter.id}
                  id={`primary-menu-item-${filter.id}`}
                  classes={{
                    root: classNames(classes.menuItem, {
                      [classes.menuItemActive]:
                        filter.id === selectedSubMenu?.id,
                      [classes.wrapper]: filter.hasChildren,
                    }),
                  }}
                  onClick={onToggleSubMenu(filter)}
                >
                  <span className={classes.menuItemContent}>
                    <span className={classes.iconWrapper}>
                      <Icon />
                    </span>
                    <span className={classes.menuItemLabel}>
                      <Typography component="span">
                        {`${filter.name} ${
                          addedFilters ? `(${addedFilters})` : ''
                        }`}
                      </Typography>
                    </span>
                    <span
                      className={classNames(
                        classes.iconWrapper,
                        classes.arrowIconWrapper
                      )}
                    >
                      <SubmenuArrowIcon />
                    </span>
                  </span>
                </MenuItem>
              );
            })}
          </>
        </CustomScrollBar>
      </MenuList>
      {selectedSubMenu && (
        <SubMenu
          translations={translations}
          shouldRender={shouldRenderSubmenu}
          items={subMenuItems}
          selectedFilters={
            selectedFilters?.[
              selectedSubMenu?.parent?.id || selectedSubMenu?.id
            ]
          }
          currentSubMenu={selectedSubMenu}
          searchTerm={searchTerm}
          onSearch={onFilterSearch}
          onSelect={onSelectFilter}
          onApply={onApply}
        />
      )}
    </Popover>
  );
};

Menu.defaultProps = {
  anchorEl: null,
  selectedSubMenu: null,
  selectedFilters: {},
  searchTerm: '',
};

Menu.propTypes = {
  translations: PropTypes.object.isRequired,
  anchorEl: PropTypes.object,
  menuItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedSubMenu: PropTypes.shape({}),
  selectedFilters: PropTypes.shape({}),
  searchTerm: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onToggleSubMenu: PropTypes.func.isRequired,
  onFilterSearch: PropTypes.func.isRequired,
  onSelectFilter: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
};

export default memo(Menu);
