import LevelingAndTracksFieldTable from '../../shared/levelingAndTracksFieldTable';
import { trimString, isObjectEmpty } from '../../../utility/helpers';
import {
  forbiddenCharacters,
  isEmpty,
  validateLength,
} from '../../../utility/validation';
import { FIELD_TYPES } from '../../../constants/fieldTypes';
import {
  LEVELING_TEMPLATES,
  LEVELING_TYPES_MAP,
} from '../../../constants/levelingTemplates';
import { EMPTY_TRACK_LEVEL } from '../../shared/levelingAndTracksFieldTable/config';

const LEVEL_NAME_VALIDATORS = [
  {
    validator: isEmpty,
    type: 'required',
  },
  {
    validator: forbiddenCharacters,
    type: 'forbiddenCharacters',
  },
  {
    validator: value => validateLength(value, 0, 3),
    type: 'levelMaxLength',
  },
];

const TRACK_NAME_VALIDATORS = [
  {
    validator: isEmpty,
    type: 'required',
  },
  {
    validator: forbiddenCharacters,
    type: 'forbiddenCharacters',
  },
  {
    validator: value => validateLength(value, 0, 250),
    type: 'trackMaxLength',
  },
];

const TRACK_LEVEL_NAME_VALIDATORS = [
  {
    validator: forbiddenCharacters,
    type: 'forbiddenCharacters',
  },
  {
    validator: value => validateLength(value, 0, 250),
    type: 'trackMaxLength',
  },
];

const TRACK_LEVEL_DESCRIPTION_VALIDATORS = [
  {
    validator: value => validateLength(value, 0, 5000),
    type: 'trackDescriptionMaxLength',
  },
];

const validateCell = (value, validators) => {
  let error = null;
  for (let i = 0; i < validators.length; i += 1) {
    const isValid = validators[i].validator(trimString(value));

    if (!isValid) {
      error = validators[i].type;
      break;
    }
  }

  return error;
};

const validateLevelsAndTracks = levelsAndTracks => {
  const levelErrors = {};
  const tracksErrors = {};
  const errors = {};

  const { levels, tracks } = levelsAndTracks;

  levels.forEach((level, index) => {
    const error = validateCell(level.name, LEVEL_NAME_VALIDATORS);

    if (error) {
      levelErrors[index] = error;
    }
  });

  tracks.forEach((track, index) => {
    const trackNameError = validateCell(track.name, TRACK_NAME_VALIDATORS);
    const trackLevelErrors = {};

    track.track_levels.forEach((trLvl, i) => {
      const nameError = validateCell(trLvl.name, TRACK_LEVEL_NAME_VALIDATORS);
      const descriptionError = validateCell(
        trLvl.description,
        TRACK_LEVEL_DESCRIPTION_VALIDATORS
      );

      if (nameError) {
        trackLevelErrors[i] = {
          ...(trackLevelErrors[i]
            ? { ...trackLevelErrors[i], name: nameError }
            : { name: nameError }),
        };
      }
      if (descriptionError) {
        trackLevelErrors[i] = {
          ...(trackLevelErrors[i]
            ? { ...trackLevelErrors[i], description: descriptionError }
            : { description: descriptionError }),
        };
      }
    });

    if (trackNameError) {
      tracksErrors[index] = {
        ...(tracksErrors[index]
          ? { ...tracksErrors[index], name: trackNameError }
          : { name: trackNameError }),
      };
    }

    if (!isObjectEmpty(trackLevelErrors)) {
      tracksErrors[index] = {
        ...(tracksErrors[index]
          ? { ...tracksErrors[index], levels: trackLevelErrors }
          : { levels: trackLevelErrors }),
      };
    }
  });

  if (!isObjectEmpty(levelErrors)) {
    errors.levels = levelErrors;
  }

  if (!isObjectEmpty(tracksErrors)) {
    errors.tracks = tracksErrors;
  }
  return isObjectEmpty(errors) ? null : errors;
};

export const TEMPLATE_OPTIONS = [
  {
    id: 1,
    value: LEVELING_TEMPLATES.CARTA,
  },
  {
    id: 2,
    value: LEVELING_TEMPLATES.FACEBOOK,
  },
  {
    id: 3,
    value: LEVELING_TEMPLATES.AMAZON,
  },
];

const TEMPLATE_FIELD = [
  {
    name: 'template',
    type: FIELD_TYPES.SELECT_WITH_PREVIEW,
    options: TEMPLATE_OPTIONS,
    previewComponent: LevelingAndTracksFieldTable,
  },
];

export const getTemplateFields = (isAddTemplate, getAssignedUsers) => {
  return isAddTemplate
    ? TEMPLATE_FIELD
    : [
        {
          name: 'customTemplate',
          type: FIELD_TYPES.LEVELING_AND_TRACKS_TABLE,
          getAssignedUsers,
          validators: [
            {
              validator: levelsAndTracks =>
                validateLevelsAndTracks(levelsAndTracks),
              isMultiple: true,
            },
          ],
        },
      ];
};

const prepareFormData = (levels, tracks) => {
  return {
    customTemplate: {
      levels,
      tracks: tracks.map(track => ({
        ...track,
        track_levels: track.track_levels.map(level => {
          if (!level) {
            return { ...EMPTY_TRACK_LEVEL };
          }

          return level;
        }),
      })),
    },
  };
};

export const getInitialLevelingData = ({
  isEdit,
  isAdd,
  template,
  levels,
  tracks,
}) => {
  if (isEdit) {
    return prepareFormData(levels, tracks);
  }

  return { ...(isAdd ? { customTemplate: template } : { template }) };
};

export const prepareLevelsForSave = levels =>
  levels.map(level => ({
    ...level,
    name: trimString(level.name),
  }));

export const prepareTracksForSave = (tracks, levels) => {
  return tracks.map(track => ({
    ...track,
    track_levels: track.track_levels.reduce((acc, tLvl, index) => {
      if (!tLvl || !trimString(tLvl.name)) {
        return [...acc, null];
      }

      return [...acc, { ...tLvl, level: levels[index].id }];
    }, []),
  }));
};

export const getDrawerTranslations = (translations, isAdd = true) => {
  if (isAdd) {
    return translations.addTemplates;
  }

  return translations.editLevelsAndTracks;
};

export const getAlertDialogTranslations = (translations, isOpened, isEdit) => {
  if (isOpened) {
    if (isEdit) return translations.editLevelingTemplate;

    return translations.deleteTemplateDialog;
  }
};

export const getLevelingTypes = labels =>
  LEVELING_TYPES_MAP.map(type => ({
    ...type,
    name: labels[type.key].name,
    description: labels[type.key].description,
  }));
