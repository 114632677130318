import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { PLACEMENTS } from 'constants/tags';
import PageContainer from '../../components/shared/pageContainer';
import PeoplePage from '../../components/pages/peoplePage';
import { getPeople, clearPeople } from '../../store/modules/people';
import {
  getTagsCategories,
  getPageQuickFilters,
  clearPageQuickFilters,
  clearTagsCategories,
} from '../../store/modules/tags';
import { getJobTitles, clearJobTitles } from '../../store/modules/jobTitles';
import { updateCurrentUserInfo } from '../../store/modules/auth';
import {
  getLevelsAndTracks,
  clearLevelsAndTracks,
} from '../../store/modules/levelsAndTracks';
import { peopleSelector } from '../../store/selectors/peopleSelector';
import { tagsSelector } from '../../store/selectors/tagsSelector';
import { dialogSelector } from '../../store/selectors/dialogSelector';
import { jobTitlesSelector } from '../../store/selectors/jobTitlesSelector';
import { levelsAndTracksSelector } from '../../store/selectors/levelsAndTracksSelector';
import { useTranslations } from '../../utility/useTranslations';
import { APP_PAGES } from '../../constants/pages';

const mapStateToProps = state => ({
  ...peopleSelector(state),
  tagCategories: [...tagsSelector(state).categories],
  ...tagsSelector(state),
  ...jobTitlesSelector(state),
  ...dialogSelector(state),
  tracks: levelsAndTracksSelector(state).tracks,
});

const mapDispatchToProps = dispatch => ({
  getPeople: (isLoadMore, data) => getPeople(dispatch, isLoadMore, data),
  clearPeople: () => dispatch(clearPeople()),
  getTagsCategories: () => getTagsCategories(dispatch),
  getPageQuickFilters: () =>
    getPageQuickFilters(dispatch, PLACEMENTS.PEOPLE.id),
  clearTagsCategories: () => dispatch(clearTagsCategories()),
  getJobTitles: () => getJobTitles(dispatch),
  clearJobTitles: () => dispatch(clearJobTitles()),
  getLevelsAndTracks: () => getLevelsAndTracks(dispatch),
  clearLevelsAndTracks: () => dispatch(clearLevelsAndTracks()),
  updateCurrentUserInfo: data => dispatch(updateCurrentUserInfo(data)),
  clearPageQuickFilters: () => dispatch(clearPageQuickFilters()),
});

const PeoplePageView = props => {
  const translations = useTranslations(APP_PAGES.PEOPLE);

  const renderPageTitle = () => {
    const totalUsers = props.people?.count;

    return (
      <Typography variant="h1">
        {totalUsers
          ? `${translations.pageTitle} (${totalUsers})`
          : `${translations.pageTitle}`}
      </Typography>
    );
  };

  return (
    <PageContainer
      {...props}
      translations={translations}
      headerProps={{
        renderTitle: renderPageTitle,
      }}
      isFullWidthContent
    >
      <PeoplePage />
    </PageContainer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PeoplePageView);
