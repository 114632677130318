import { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { Fade, makeStyles } from '@material-ui/core';
import SurveyGroup from './surveyGroup';
import CustomButton from '../customButton';
import ReorderItems from '../reorderItems';
import { reorder } from '../../../utility/helpers';
import { AUTOMATION_ID } from '../../../constants/automationId';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  loadMoreWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: spacing(4),
    width: '100%',
  },
  loadMoreButton: {
    border: `1px solid ${primary.bluish3}`,
    borderRadius: 44,
    color: primary.bluish4,
    lineHeight: '24px',
    justifySelf: 'center',
    padding: spacing(1, 4),
    maxHeight: 32,
  },
}));

const SurveyGroups = ({
  translations,
  groups,
  surveyColumns,
  hasLoadMore,
  onSurveyClick,
  onEditGroupTitle,
  onDeleteGroup,
  onDuplicateGroup,
  onLoadMore,
  onCreateSurvey,
  onReorderGroupItems,
  onReorderGroups,
}) => {
  const classes = useStyles();

  const handleReorder = useCallback(
    result => {
      if (
        !result.destination ||
        result.source.index === result.destination.index
      )
        return;

      const updatedOrder = reorder(
        groups,
        result.source.index,
        result.destination.index
      );

      return onReorderGroups(updatedOrder);
    },
    [groups, onReorderGroups]
  );

  return (
    <div>
      <ReorderItems onSave={handleReorder} isDragDisabled>
        {groups.map(group => (
          <SurveyGroup
            key={`survey_group_${group.title}`}
            id={group.id}
            translations={translations}
            group={group}
            surveyColumns={surveyColumns}
            onEditTitle={onEditGroupTitle}
            onDelete={onDeleteGroup}
            onDuplicate={onDuplicateGroup}
            onSurveyClick={onSurveyClick}
            onCreateSurvey={onCreateSurvey}
            onReorder={onReorderGroupItems}
            isDragDisabled
          />
        ))}
      </ReorderItems>
      <Fade in={hasLoadMore} unmountOnExit>
        <div className={classes.loadMoreWrapper}>
          <CustomButton
            className={classes.loadMoreButton}
            id={AUTOMATION_ID.LOAD_MORE_BUTTON}
            type="addWithTextRounded"
            onClick={onLoadMore}
          >
            {translations.loadMoreButton}
          </CustomButton>
        </div>
      </Fade>
    </div>
  );
};

SurveyGroups.propTypes = {
  translations: PropTypes.object.isRequired,
  hasLoadMore: PropTypes.bool.isRequired,
  groups: PropTypes.arrayOf(PropTypes.object).isRequired,
  surveyColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSurveyClick: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  onCreateSurvey: PropTypes.func.isRequired,
  onEditGroupTitle: PropTypes.func.isRequired,
  onDeleteGroup: PropTypes.func.isRequired,
  onDuplicateGroup: PropTypes.func.isRequired,
  onReorderGroups: PropTypes.func.isRequired,
  onReorderGroupItems: PropTypes.func.isRequired,
};

export default memo(SurveyGroups);
