import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import classNames from 'classnames';

const styles = ({ palette: { primary }, spacing }) => ({
  main: {
    border: `1px solid ${primary.bluish1}`,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: spacing(1, 2),
    width: 'fit-content',
    height: 26,
  },
});

class ShareTypeBox extends PureComponent {
  render() {
    const { className, classes, label } = this.props;

    return (
      <Typography
        className={classNames(classes.main, className)}
        variant="caption"
      >
        {label}
      </Typography>
    );
  }
}

ShareTypeBox.defaultProps = {
  className: null,
};

ShareTypeBox.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
};

export default withStyles(styles)(ShareTypeBox);
