import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import { NEUTRAL_ANSWER_WEIGHT } from 'components/shared/questionEntry/config';
import { getAnswerWeight } from 'utility/attribute';
import { ellipsis } from '../../../../../constants/helperCssRules';
import { HORIZONTAL_ANSWER_SIZES } from '../../../../../utility/fillSurveyUtils';

const styles = ({ palette: { primary }, spacing }) => ({
  main: {
    boxSizing: 'border-box',
    borderRadius: 3,
    border: `1px solid ${primary.bluish6}`,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    padding: spacing(2),
    marginRight: spacing(2),
    flexShrink: 0,
    userSelect: 'none',
    '&:last-of-type': {
      marginRight: 0,
    },
    '&.selected': {
      backgroundColor: primary.blue1,
      border: 'none',
      '& $answerText': {
        color: primary.white,
      },
    },
  },
  small: {
    width: 50,
  },
  medium: {
    width: 78,
  },
  large: {
    width: 107,
  },
  disabled: {
    cursor: 'default',
  },
  answerText: {
    lineHeight: '18px',
    alignSelf: 'center',
    wordWrap: 'break-word',
    ...ellipsis(),
  },
});

class HorizontalAnswer extends PureComponent {
  render() {
    const {
      classes,
      answer,
      isSelected,
      isDisabled,
      neutralAnswerLabel,
      numberOfAnswers,
      onSelect,
    } = this.props;
    const { SMALL, MEDIUM } = HORIZONTAL_ANSWER_SIZES;

    return (
      <div
        className={classNames(classes.main, {
          [classes.disabled]: isDisabled,
          [classes.large]: numberOfAnswers < SMALL,
          [classes.medium]:
            numberOfAnswers >= SMALL && numberOfAnswers <= MEDIUM,
          [classes.small]: numberOfAnswers > MEDIUM,
          selected: isSelected,
        })}
        onClick={!isSelected ? onSelect : undefined}
      >
        <Typography className={classes.answerText} variant="subtitle1">
          {getAnswerWeight(answer.weight) === NEUTRAL_ANSWER_WEIGHT
            ? neutralAnswerLabel
            : answer.text}
        </Typography>
      </div>
    );
  }
}

HorizontalAnswer.defaultProps = {
  numberOfAnswers: HORIZONTAL_ANSWER_SIZES.LARGE,
  isSelected: false,
  onSelect: () => {},
};

HorizontalAnswer.propTypes = {
  classes: PropTypes.object.isRequired,
  answer: PropTypes.shape({}).isRequired,
  numberOfAnswers: PropTypes.number,
  neutralAnswerLabel: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default withStyles(styles)(HorizontalAnswer);
