import KadarTheme from 'styles/theme';

const COLORS = [
  KadarTheme.palette.primary.blue1,
  KadarTheme.palette.primary.orange,
  KadarTheme.palette.primary.bluish1,
];
const PLACEHOLDER_CELL = {
  name: 'No Data',
  value: 1,
  fill: KadarTheme.palette.primary.bluish5,
  isPlaceholder: true,
};

export const prepareChartData = (
  data,
  isEmptyData,
  placeholderText,
  hasNotStartedStatus
) => {
  const chartData = data.map((entry, index) => ({
    ...entry,
    fill: COLORS[hasNotStartedStatus ? index : index + 1],
  }));

  return isEmptyData
    ? [...chartData, { ...PLACEHOLDER_CELL, placeholderText }]
    : chartData;
};
