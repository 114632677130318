import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import AlertDialog from '../../shared/alertDialog';
import CourseDetailsCard from '../../shared/courseDetailsCard';
import SectionTitle from '../../shared/sectionTitle';
import EnrolledEmployeeCard from '../../shared/enrolledEmployeeCard';
import NotificationCard from '../../shared/notificationCard';
import CustomButton from '../../shared/customButton';
import AssignUsersDialog from '../../shared/assignUsersDialog';
import Filter from '../../shared/filter';
import CustomFormDrawer from '../../shared/customFormDrawer';
import {
  isPermissionGranted,
  isArrayEmpty,
  isObjectEmpty,
  trimString,
  checkUserRole,
  canManagePerson,
  isUserSuspended,
  getObjectToNumberArray,
} from '../../../utility/helpers';
import http from '../../../utility/http';
import {
  showSuccessMessage,
  manageQuery,
  parameterizeQuery,
  isEmployeeInReporters,
  hasCollaborationPrivileges,
} from '../../../utility/uiUtils';
import { onSaveCreatableTag } from '../../../utility/tagUtils';
import {
  api_course,
  api_course_users_disenroll,
  api_course_users_enroll,
  API_COURSES,
  api_course_users,
} from '../../../constants/apiRoutes';
import { PERMISSIONS, ROLES } from '../../../constants/rolesAndPermissionList';
import { PEOPLE_PARAM_SCOPE_VALUES } from '../../../constants/people';
import { GENERAL_CATEGORY_ID } from '../../../constants/tags';
import { PARAMS, COURSES_DEFAULT_PARAMS } from '../../../constants/pages';
import {
  getCourseLevels,
  prepareEnrollmentStatuses,
  getEnrollmentStatus,
  prepareCourseData,
} from '../../../utility/courseUtils';
import { getEditCourseFields, getCourseActions } from './config';
import {
  EVENT_ACTION_TYPES,
  tagManagerDataLayer,
} from '../../../utility/tagManager';

const styles = ({ spacing }) => ({
  sectionTitleWrapper: {
    marginTop: spacing(8),
    marginBottom: spacing(4),
  },
  enrolledUser: {
    marginBottom: spacing(4),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(4),
    width: '100%',
  },
  enrollButton: {
    marginLeft: 'auto',
  },
  status: {
    width: 200,
  },
});

class CourseDetailsPage extends PureComponent {
  state = {
    isLoading: false,
    isFilterLoading: false,
    enrollToCourseDialogOpened: false,
    disenrollDialogOpened: false,
    disenrollUserId: null,
    isEditCourseOpened: false,
    status: 0,
  };

  componentDidMount() {
    const {
      matchedCourseId,
      grantedPermissions,
      getCourse,
      getAttributesWithQuestions,
      getTagsCategories,
    } = this.props;
    const canManageCourse = isPermissionGranted(
      PERMISSIONS.canManageCourse,
      grantedPermissions
    );

    if (canManageCourse) {
      getTagsCategories();
      getAttributesWithQuestions({ is_private: false });
    }

    return Promise.all([
      getCourse(matchedCourseId),
      this.getCourseUsers(matchedCourseId),
    ]).then(() => {
      this.setState({ isLoading: false });
      const { VisitCDP } = EVENT_ACTION_TYPES;
      tagManagerDataLayer(VisitCDP.action, VisitCDP.name);
    });
  }

  componentWillUnmount() {
    const {
      clearCourse,
      clearCourseEnrolledUsers,
      clearAttributesWithQuestions,
      clearTagsCategories,
      clearAllUsers,
    } = this.props;
    clearTagsCategories();
    clearCourse();
    clearCourseEnrolledUsers();
    clearAttributesWithQuestions();
    clearAllUsers();
  }

  getCourseUsers = courseId => {
    const { location, getCourseEnrolledUsers } = this.props;
    const { status } = this.state;

    const query = manageQuery({
      key: 'status',
      value: status,
      shouldDelete: !status,
      query: location.search,
    });

    return getCourseEnrolledUsers(courseId, parameterizeQuery(query));
  };

  onGoToPersonCourses = userId => () => {
    const { history } = this.props;
    history.push(`/people/${userId}/learning`);
  };

  toggleDeleteCourseDialog = () => {
    const { setDialogVisibility, dialogs } = this.props;
    const { forceDeleteCourseDialogOpened } = dialogs;

    setDialogVisibility({
      dialogName: 'forceDeleteCourseDialog',
      opened: !forceDeleteCourseDialogOpened,
    });
  };

  toggleEditCourseDialog = () =>
    this.setState(prevState => ({
      isEditCourseOpened: !prevState.isEditCourseOpened,
    }));

  toggleDisenrollDialog = userId =>
    this.setState(prevState => ({
      disenrollDialogOpened: !prevState.disenrollDialogOpened,
      disenrollUserId: userId || null,
    }));

  toggleEnrollToCourseDialog = (shouldClearUsers = true) => {
    const { clearAllUsers } = this.props;

    this.setState(
      prevState => ({
        enrollToCourseDialogOpened: !prevState.enrollToCourseDialogOpened,
      }),
      () => {
        if (shouldClearUsers) {
          clearAllUsers();
        }
      }
    );
  };

  handleEnrollToCourseDialog = (isAdmin, isUser) => async () => {
    const { course, getAllUsers } = this.props;

    const alreadyEnrolled = await http
      .get(api_course_users(course.id), {
        params: { ...COURSES_DEFAULT_PARAMS },
      })
      .then(({ data }) => getObjectToNumberArray(data.results));

    const params = {
      [PARAMS.EXCLUDE]: alreadyEnrolled,
      ...(isUser
        ? {
            [PARAMS.SCOPE]: [
              PEOPLE_PARAM_SCOPE_VALUES.ME,
              PEOPLE_PARAM_SCOPE_VALUES.SHARED_COLLAB,
            ],
          }
        : {}),
      ...(!isUser && !isAdmin
        ? {
            [PARAMS.SCOPE]: [
              PEOPLE_PARAM_SCOPE_VALUES.ME,
              PEOPLE_PARAM_SCOPE_VALUES.ALL_REPORTS,
              PEOPLE_PARAM_SCOPE_VALUES.SHARED_COLLAB,
            ],
          }
        : {}),
    };
    await getAllUsers(params);
    this.toggleEnrollToCourseDialog(false);
  };

  onEnrollToCourse = users => {
    const { course, translations } = this.props;

    return http
      .post(api_course_users_enroll(course.id), { users })
      .then(() => {
        showSuccessMessage(
          `${users.length} ${translations.successMessages.enroll}`
        );
        const { EnrollEmployeeOnCourse } = EVENT_ACTION_TYPES;
        tagManagerDataLayer(
          EnrollEmployeeOnCourse.action,
          EnrollEmployeeOnCourse.name,
          users.length
        );
      })
      .finally(() => {
        this.getCourseUsers(course.id);
        this.toggleEnrollToCourseDialog();
      });
  };

  onDeleteCourse = courseId => () => {
    const { translations, history } = this.props;

    return http.delete(`${API_COURSES}${courseId}/`).then(() => {
      this.toggleDeleteCourseDialog();
      showSuccessMessage(translations.deleteCourseDialog.deleteSuccess);
      history.push('/learning');
    }, this.toggleDeleteCourseDialog);
  };

  onChangeEnrollmentStatus = userId => statusId => {
    const { translations, course } = this.props;

    return http
      .patch(`${api_course_users(course.id)}${userId}/`, {
        status: statusId,
      })
      .then(() => {
        showSuccessMessage(translations.successMessages.status);
        return this.getCourseUsers(course.id);
      });
  };

  onChangeStatusFilter = newStatus => {
    const { course } = this.props;

    return this.setState({ status: newStatus, isFilterLoading: true }, () =>
      this.getCourseUsers(course.id).then(() =>
        this.setState({ isFilterLoading: false })
      )
    );
  };

  onSaveCourse = values => {
    const { translations, setCourse } = this.props;
    const { id, title, url, attribute, tags, level, description, cover_image } =
      values;

    const payload = new FormData();
    const sanitizedDescription = description ? trimString(description) : '';
    payload.append('title', title);
    payload.append('url', url);

    payload.append('description', sanitizedDescription || '');
    payload.append('level', level || '');
    if (!cover_image || cover_image instanceof File) {
      payload.append('cover_image', cover_image || '');
    }
    payload.append('attribute', attribute || '');
    if (!isArrayEmpty(tags)) {
      tags.forEach(tag => {
        payload.append('tags', tag);
      });
    } else {
      payload.append('tags', '');
    }

    return http.patch(api_course(id), payload).then(({ data }) => {
      showSuccessMessage(translations.successMessages.editCourse);
      setCourse(data);
    });
  };

  onDisenrollUser = () => {
    const { translations, course } = this.props;
    const { disenrollUserId } = this.state;
    if (disenrollUserId) {
      http
        .post(api_course_users_disenroll(course.id), {
          users: [disenrollUserId],
        })
        .then(() => {
          this.getCourseUsers(course.id);
          showSuccessMessage(
            translations.disenrollCourseDialog.disenrollSuccess
          );
        })
        .finally(() => {
          this.toggleDisenrollDialog();
        });
    }
  };

  onCreateTag = async tagName => {
    const { addMultipleTags } = this.props;
    const tags = await onSaveCreatableTag(tagName);
    const [tag] = tags.create;

    addMultipleTags({ categoryId: GENERAL_CATEGORY_ID, tags });

    return tag;
  };

  render() {
    const {
      classes,
      translations,
      course,
      enrolledUsers,
      dialogs,
      auth,
      grantedPermissions,
      organizationSettings,
      allAttributes,
      categories,
      allUsers,
    } = this.props;
    const {
      isLoading,
      disenrollDialogOpened,
      isEditCourseOpened,
      enrollToCourseDialogOpened,
      status,
      isFilterLoading,
    } = this.state;
    const { forceDeleteCourseDialogOpened } = dialogs;
    const isAdmin = checkUserRole(auth.role, ROLES.ADMIN);
    const isUser = checkUserRole(auth.role, ROLES.USER);

    const { canManageCourse } = PERMISSIONS;
    const enrollmentStatuses = prepareEnrollmentStatuses(
      translations.enrollmentStatuses
    );
    const statusFilterOptions = [
      { id: 0, name: translations.all },
      ...enrollmentStatuses,
    ];
    const canEditCourse = isPermissionGranted(
      canManageCourse,
      grantedPermissions
    );

    const courseLevels = getCourseLevels(translations.levels);
    const courseActions = getCourseActions(
      translations.courseActions,
      this.toggleEditCourseDialog,
      this.toggleDeleteCourseDialog
    );
    const isStatusSelected = status > 0;

    return (
      <div>
        {!isLoading && (
          <>
            {!isObjectEmpty(course) && (
              <div>
                <SectionTitle
                  className={classes.sectionTitleWrapper}
                  title={translations.courseDetails}
                />
                <CourseDetailsCard
                  translations={translations.courseDetailsCard}
                  isReadOnly={!canEditCourse}
                  levels={courseLevels}
                  actions={courseActions}
                  course={course}
                />
              </div>
            )}
            {!isObjectEmpty(enrolledUsers) && (
              <div>
                <SectionTitle
                  className={classes.sectionTitleWrapper}
                  title={`${translations.enrolledUsers} (${enrolledUsers.results.length})`}
                />
                <div className={classes.actions}>
                  <div className={classes.status}>
                    <Filter
                      name={translations.status}
                      options={statusFilterOptions}
                      value={status}
                      onChange={this.onChangeStatusFilter}
                    />
                  </div>
                  <CustomButton
                    className={classes.enrollButton}
                    type="addDarkRoundedOutlined"
                    onClick={this.handleEnrollToCourseDialog(isAdmin, isUser)}
                  >
                    {translations.enrollUser}
                  </CustomButton>
                </div>
                <div>
                  {!isArrayEmpty(enrolledUsers.results) &&
                    enrolledUsers.results.map(user => {
                      const isCurrentUser = user.id === auth.id;

                      return (
                        <EnrolledEmployeeCard
                          key={`enrolled_user_${user.id}`}
                          className={classes.enrolledUser}
                          translations={translations}
                          user={user}
                          status={getEnrollmentStatus(
                            user.enrolment_status,
                            enrollmentStatuses
                          )}
                          statuses={enrollmentStatuses}
                          isReadOnly={
                            !(
                              isAdmin ||
                              isCurrentUser ||
                              (isUser
                                ? hasCollaborationPrivileges(
                                    auth.accessible,
                                    user.id
                                  )
                                : canManagePerson(auth, user.id))
                            )
                          }
                          isStatusChangeDisabled={isUserSuspended(user)}
                          canOpenPersonProfile={
                            isAdmin ||
                            (isCurrentUser &&
                              organizationSettings.global_see_himself) ||
                            isEmployeeInReporters(
                              user.id,
                              auth?.accessibleProfiles
                            )
                          }
                          onDisenroll={() =>
                            this.toggleDisenrollDialog(user.id)
                          }
                          onChangeEnrollmentStatus={this.onChangeEnrollmentStatus(
                            user.id
                          )}
                          onGoToCourses={this.onGoToPersonCourses(user.id)}
                        />
                      );
                    })}
                  {!isFilterLoading && isArrayEmpty(enrolledUsers.results) && (
                    <NotificationCard
                      content={
                        isStatusSelected
                          ? translations.noSearchResultsMessage
                          : translations.noEnrolledEmployees
                      }
                    />
                  )}
                </div>
              </div>
            )}
          </>
        )}
        <CustomFormDrawer
          translations={translations.editCourseForm}
          initialData={prepareCourseData(course)}
          isOpened={isEditCourseOpened}
          fields={getEditCourseFields(this.onCreateTag)}
          allAttributes={allAttributes}
          categories={categories}
          levels={courseLevels}
          onClose={this.toggleEditCourseDialog}
          onSave={this.onSaveCourse}
          onDelete={this.toggleDeleteCourseDialog}
          isInitialValid
          hasCancelButton
        />
        <AssignUsersDialog
          translations={translations.enrollToCourseDialog}
          isOpened={enrollToCourseDialogOpened}
          allUsers={allUsers}
          onCancel={this.toggleEnrollToCourseDialog}
          onSave={this.onEnrollToCourse}
          isMulty
          shouldResetSelection
        />
        <AlertDialog
          translations={translations.disenrollCourseDialog}
          isOpened={disenrollDialogOpened}
          onClose={this.toggleDisenrollDialog}
          onConfirm={this.onDisenrollUser}
        />
        <AlertDialog
          translations={translations.deleteCourseDialog}
          isOpened={forceDeleteCourseDialogOpened}
          onClose={this.toggleDeleteCourseDialog}
          onConfirm={this.onDeleteCourse(course.id)}
          isWarning
        />
      </div>
    );
  }
}

CourseDetailsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  matchedCourseId: PropTypes.string.isRequired,
  dialogs: PropTypes.object.isRequired,
  grantedPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  organizationSettings: PropTypes.object.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  course: PropTypes.object.isRequired,
  enrolledUsers: PropTypes.shape({}).isRequired,
  allAttributes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  allUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setDialogVisibility: PropTypes.func.isRequired,
  getCourse: PropTypes.func.isRequired,
  setCourse: PropTypes.func.isRequired,
  clearCourse: PropTypes.func.isRequired,
  getAttributesWithQuestions: PropTypes.func.isRequired,
  clearAttributesWithQuestions: PropTypes.func.isRequired,
  getCourseEnrolledUsers: PropTypes.func.isRequired,
  getTagsCategories: PropTypes.func.isRequired,
  addMultipleTags: PropTypes.func.isRequired,
  clearTagsCategories: PropTypes.func.isRequired,
  getAllUsers: PropTypes.func.isRequired,
  clearAllUsers: PropTypes.func.isRequired,
};

export default withStyles(styles)(CourseDetailsPage);
