import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
  Typography,
  Box,
  useMediaQuery,
  alpha,
  makeStyles,
} from '@material-ui/core';
import { ReactComponent as LogoIcon } from '../../../assets/icons/logo-dark.svg';
import LoginBackground from '../../../assets/images/bck_kadar.png';
import { AUTOMATION_ID } from '../../../constants/automationId';
import { API_DOMAIN, API_PROTOCOL } from '../../../utility/http';
import { KADAR_LANDING } from '../../../constants/appConfig';

const useStyles = makeStyles(
  ({ palette: { primary }, spacing, breakpoints }) => ({
    root: {
      boxSizing: 'border-box',
      padding: spacing(6, 8, 6, 8),
      height: '100vh',
      [breakpoints.up('xLg')]: {
        padding: ({ isLargeScreenHeight }) =>
          isLargeScreenHeight
            ? spacing(12, 19, 25, 19)
            : spacing(8, 12, 10, 12),
      },
      [breakpoints.up('xxl')]: {
        padding: spacing(12, 19, 25, 19),
      },
    },
    content: {
      background: `${primary.white} url(${LoginBackground}) left / cover no-repeat`,
      justifyContent: 'center',
      height: 'calc(100% - 76px)',
      maxHeight: '100%',
      display: 'grid',
      [breakpoints.up('lg')]: {
        background: `${primary.white} url(${LoginBackground}) center / auto 100% no-repeat`,
      },
      [breakpoints.up('xLg')]: {
        height: ({ isLargeScreenHeight }) =>
          isLargeScreenHeight ? 'calc(100% - 128px)' : 'calc(100% - 84px)',
      },
      [breakpoints.up('xxl')]: {
        height: 'calc(100% - 128px)',
      },
    },
    logo: {
      marginBottom: spacing(6),
      [breakpoints.up('xLg')]: {
        marginBottom: ({ isLargeScreenHeight }) =>
          isLargeScreenHeight ? spacing(19) : spacing(8),
      },
      [breakpoints.up('xxl')]: {
        marginBottom: spacing(19),
      },
    },
    iconWrapper: {
      display: 'flex',
      height: 52,
      textDecoration: 'none',
    },
    app: {
      color: primary.bluish1,
      fontFamily: 'ProximaNova-Bold',
      fontWeight: 800,
      fontSize: 32,
      marginLeft: spacing(3),
      lineHeight: '52px',
      alignContent: 'center',
    },
    logoIcon: {
      width: 52,
      height: 52,
    },
    boxWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    boxWrap: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
    },
    wrap: {
      boxShadow: `0px 4px 8px 0px ${alpha(primary.black, 0.1)}`,
      borderRadius: 24,
      backgroundColor: primary.white,
      display: 'grid',
      height: 415,
      width: 424,
      [breakpoints.up('xLg')]: {
        height: 515,
        width: 524,
      },
      [breakpoints.up('xl')]: {
        height: 615,
        width: 600,
      },
    },
    changeOrganizationContainer: {
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      top: 425,
      [breakpoints.up('xLg')]: {
        top: 535,
      },
      [breakpoints.up('xl')]: {
        top: 635,
      },
    },
    notIn: {
      marginRight: spacing(2),
    },
    changeOrganizationText: {
      fontFamily: 'ProximaNova-Bold',
      fontWeight: 700,
      fontSize: 16,
      color: primary.bluish1,
    },
    privacyLinksContainer: {
      position: 'absolute',
      left: 32,
      bottom: 12,
      [breakpoints.up('xLg')]: {
        left: ({ isLargeScreenHeight }) =>
          isLargeScreenHeight ? '76px' : '48px',
      },
      [breakpoints.up('xxl')]: {
        left: 76,
      },
    },
    privacyLinks: {
      textDecoration: 'none',
    },
    privacyLinksText: {
      lineHeight: '24px',
      paddingRight: spacing(5),
    },
    desktop: {
      display: 'none',
      [breakpoints.up('sm')]: {
        display: 'flex',
      },
    },
    mobile: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [breakpoints.up('sm')]: {
        display: 'none',
      },
    },
  })
);

const BackgroundContainer = ({
  translations,
  children,
  switchWorkspace,
  removeTopButton,
  signUpPage,
  subDomain,
}) => {
  const isLargeScreenHeight = useMediaQuery('(min-height:950px)');
  const classes = useStyles({ isLargeScreenHeight });

  const { SIGN_IN_BUTTON, SIGN_UP_BUTTON, CHANGE_ORGANIZATION_BUTTON } =
    AUTOMATION_ID;
  const changeOrganizationUrl = `${API_PROTOCOL}://${API_DOMAIN}/login`;
  const signUpUrl = `${API_PROTOCOL}://${API_DOMAIN}/sign-up`;

  const renderLogo = () => (
    <div className={classes.logo}>
      <a
        id={AUTOMATION_ID.APP_LOGO}
        href={KADAR_LANDING}
        className={classes.iconWrapper}
      >
        <LogoIcon className={classes.logoIcon} />
        <Typography className={classes.app} variant="h4">
          {translations.kadar}
        </Typography>
      </a>
    </div>
  );

  return (
    <div className={classes.root}>
      {renderLogo()}
      <div className={classes.content}>
        <Box className={classNames(classes.boxWrapper, classes.desktop)}>
          <Box className={classes.boxWrap}>
            <Box className={classes.wrap}>{children}</Box>
            {!removeTopButton && (
              <div className={classes.changeOrganizationContainer}>
                <Typography className={classes.notIn}>
                  {signUpPage
                    ? translations.singInText
                    : translations.singUpText}
                </Typography>
                <a
                  id={signUpPage ? SIGN_IN_BUTTON : SIGN_UP_BUTTON}
                  href={signUpPage ? changeOrganizationUrl : signUpUrl}
                >
                  <Typography className={classes.changeOrganizationText}>
                    {signUpPage
                      ? translations.signInButton
                      : translations.signUpButton}
                  </Typography>
                </a>
              </div>
            )}
            {switchWorkspace && (
              <div className={classes.changeOrganizationContainer}>
                <Typography className={classes.notIn}>
                  {`${translations.notIn} ${subDomain}?`}
                </Typography>
                <a id={CHANGE_ORGANIZATION_BUTTON} href={changeOrganizationUrl}>
                  <Typography className={classes.changeOrganizationText}>
                    {translations.changeOrganization}
                  </Typography>
                </a>
              </div>
            )}
          </Box>
        </Box>
        <div className={classes.mobile}>
          <Typography variant="subtitle1">
            {translations.desktopOnly}
          </Typography>
        </div>
      </div>
      <div className={classes.privacyLinksContainer}>
        <Typography className={classes.privacyLinksText} variant="body2">
          {translations.copyrightText}
        </Typography>
        <Link
          to={translations.privacy_policy.url}
          className={classes.privacyLinks}
        >
          <Typography
            variant="body2"
            component="span"
            className={classes.privacyLinksText}
          >
            {translations.privacy_policy.name}
          </Typography>
        </Link>
        <Link
          to={translations.terms_of_service.url}
          className={classes.privacyLinks}
        >
          <Typography
            variant="body2"
            component="span"
            className={classes.privacyLinksText}
          >
            {translations.terms_of_service.name}
          </Typography>
        </Link>
      </div>
    </div>
  );
};

BackgroundContainer.defaultProps = {
  switchWorkspace: false,
  removeTopButton: false,
  signUpPage: false,
  subDomain: '',
};

BackgroundContainer.propTypes = {
  translations: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
  switchWorkspace: PropTypes.bool,
  removeTopButton: PropTypes.bool,
  signUpPage: PropTypes.bool,
  subDomain: PropTypes.string,
};

export default BackgroundContainer;
