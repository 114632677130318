import { useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Typography,
  MenuList,
  MenuItem,
  ClickAwayListener,
  makeStyles,
} from '@material-ui/core';
import ArrowTooltip from '../arrowTooltip';
import ActionButton from '../actionButton';
import { AUTOMATION_ID } from '../../../constants/automationId';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  container: {
    color: primary.bluish3,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: 'auto',
    height: 'auto',
  },
  popper: {
    borderRadius: 'unset',
    zIndex: 1,
    pointerEvents: 'auto',
  },
  tooltip: {
    borderRadius: 'unset',
    padding: 0,
    margin: 0,
    minWidth: 140,
  },
  menuList: {
    padding: 0,
    width: '100%',
  },
  menuItem: {
    boxSizing: 'border-box',
    borderBottom: `1px solid ${primary.bluish8}`,
    display: 'flex',
    alignItems: 'center',
    padding: spacing(2, 4),
    width: '100%',
    '&:last-of-type': {
      borderBottom: 'none',
    },
    '&:hover': {
      backgroundColor: primary.bluish8,
    },
  },
  menuItemLabel: {
    lineHeight: '24px',
  },
  labelIcon: {
    marginRight: spacing(2),
    width: 16,
    height: 16,
    flexShrink: 0,
  },
  iconButton: {
    margin: 0,
    height: 24,
    width: 24,
  },
  iconDisabled: {
    color: primary.bluish7,
    '& svg path': {
      fill: primary.bluish7,
    },
  },
  triggerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const DotsMenu = ({ className, menuItems, isDisabled, position }) => {
  const classes = useStyles();
  const [isOpened, setIsOpened] = useState(false);

  const handleToggle = event => {
    event.stopPropagation();
    event.preventDefault();

    if (isDisabled) return;

    setIsOpened(prevOpened => !prevOpened);
  };

  const handleItemClick = useCallback(
    itemAction => event => {
      event.stopPropagation();
      event.preventDefault();

      setIsOpened(false);
      itemAction();
    },
    []
  );

  const handleClose = event => {
    event.stopPropagation();
    setIsOpened(false);
  };

  const renderTooltipText = () => (
    <MenuList className={classes.menuList}>
      {menuItems.map(item => {
        const { type, action, label, icon: Icon, shouldItemRender } = item;
        return shouldItemRender() ? (
          <MenuItem
            id={type}
            key={type}
            className={classes.menuItem}
            onClick={handleItemClick(action)}
          >
            {Icon && <Icon className={classes.labelIcon} />}
            <Typography className={classes.menuItemLabel} variant="subtitle2">
              {label}
            </Typography>
          </MenuItem>
        ) : null;
      })}
    </MenuList>
  );

  return (
    <div
      className={classNames(classes.container, className)}
      onClick={handleToggle}
    >
      <ClickAwayListener onClickAway={handleClose} mouseEvent="onMouseDown">
        <div>
          <ArrowTooltip
            tooltipClassName={classes.tooltip}
            tooltipPopperClassName={classes.popper}
            tooltipLabel={renderTooltipText()}
            open={isOpened}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            shouldDisablePortal
            position={position}
            interactive
            isArrowHidden
          >
            <ActionButton
              id={AUTOMATION_ID.DOTS_MENU_BUTTON}
              className={classes.iconButton}
              iconClass={classNames({ [classes.iconDisabled]: isDisabled })}
              type={ACTION_BUTTON_TYPES.DOTS}
              isDisabled={isDisabled}
              onClickHandler={handleToggle}
            />
          </ArrowTooltip>
        </div>
      </ClickAwayListener>
    </div>
  );
};

DotsMenu.defaultProps = {
  className: null,
  position: 'bottom-end',
  menuItems: [],
  isDisabled: false,
};

DotsMenu.propTypes = {
  className: PropTypes.string,
  menuItems: PropTypes.arrayOf(PropTypes.object),
  position: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default memo(DotsMenu);
