import { Fragment, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, withStyles } from '@material-ui/core';
import ArrowTooltip from '../arrowTooltip';

const styles = () => ({
  main: {
    cursor: 'default',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  tooltipText: {
    textAlign: 'start',
    wordBreak: 'break-word',
  },
});

const ConditionalTooltip = ({
  className,
  classes,
  message,
  addTooltip,
  customLabelClass,
  tooltipClassName,
  noDefaultStyles,
  children,
  position,
  ...rest
}) => {
  const renderLabel = () => {
    return (
      <Typography
        variant="body2"
        component="div"
        className={classNames(
          { [classes.tooltipText]: !noDefaultStyles },
          customLabelClass
        )}
      >
        {message}
      </Typography>
    );
  };

  return addTooltip ? (
    <ArrowTooltip
      tooltipLabel={renderLabel()}
      tooltipClassName={tooltipClassName}
      {...rest}
      position={position}
    >
      <div
        className={classNames({ [classes.main]: !noDefaultStyles }, className)}
      >
        {Children.toArray(children).map(child => {
          return cloneElement(child, { ...rest });
        })}
      </div>
    </ArrowTooltip>
  ) : (
    <Fragment>{children}</Fragment>
  );
};

ConditionalTooltip.defaultProps = {
  className: '',
  tooltipClassName: null,
  customLabelClass: '',
  addTooltip: false,
  noDefaultStyles: false,
  position: 'top',
};

ConditionalTooltip.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  customLabelClass: PropTypes.string,
  tooltipClassName: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  addTooltip: PropTypes.bool,
  noDefaultStyles: PropTypes.bool,
  position: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(ConditionalTooltip);
