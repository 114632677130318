import { isArrayEmpty } from 'utility/helpers';
import ColorBox from 'components/shared/colorBox';
import { ReactComponent as CourseIcon } from '../../../assets/icons/courses-icon-dark.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-outlined.svg';
import { ReactComponent as CourseLevelIcon } from '../../../assets/icons/course-level-icon.svg';
import { ReactComponent as AttributeIcon } from '../../../assets/icons/checklist-icon.svg';
import { ReactComponent as StatusIcon } from '../../../assets/icons/status-icon.svg';
import { ReactComponent as TagsIcon } from '../../../assets/icons/tags-icon.svg';
import { filterTags } from '../../../utility/tagUtils';
import { PARAMS } from '../../../constants/pages';

export const getCourseActions = (
  labels,
  onViewDetails,
  onDisenroll,
  canManageEnrollment = false
) => {
  const viewAction = {
    action: onViewDetails,
    label: labels.viewDetails,
    shouldItemRender: () => true,
    type: 'Details',
    icon: CourseIcon,
  };
  if (canManageEnrollment) {
    return [
      viewAction,
      {
        action: onDisenroll,
        label: labels.disenroll,
        shouldItemRender: () => true,
        type: 'Disenroll',
        icon: DeleteIcon,
      },
    ];
  }
  return [viewAction];
};

export const getPageFilters = (
  translations,
  levels,
  attributes,
  statuses,
  tagCategories,
  quickFilters = []
) => {
  const {
    COURSE_LEVEL,
    COURSE_ATTRIBUTE,
    LEVEL,
    ATTRIBUTE,
    STATUS,
    TAGS,
    COURSE_TAGS,
  } = PARAMS;
  const defaultOption = { id: null, name: translations.all };

  return [
    {
      id: COURSE_LEVEL,
      name: translations.filters.labels[LEVEL],
      icon: CourseLevelIcon,
      isSingleSelect: true,
      items: [defaultOption, ...levels],
    },
    {
      id: COURSE_ATTRIBUTE,
      name: translations.filters.labels[ATTRIBUTE],
      icon: AttributeIcon,
      isSingleSelect: true,
      hasSubmenuColorBox: true,
      items: [defaultOption, ...attributes],
    },
    {
      id: STATUS,
      name: translations.filters.labels[STATUS],
      icon: StatusIcon,
      isSingleSelect: true,
      items: [defaultOption, ...statuses],
    },
    {
      id: COURSE_TAGS,
      name: translations.filters.labels[TAGS],
      icon: TagsIcon,
      categoryItemsKey: TAGS,
      hasCategoryColorBox: true,
      hasSubmenuHash: true,
      isCategorized: true,
      items: tagCategories,
      categorySearch: filterTags,
    },
    ...(quickFilters && !isArrayEmpty(quickFilters)
      ? quickFilters?.map(filter => {
          return {
            id: filter.name,
            name: filter.name,
            icon: () => {
              return <ColorBox bgColor={filter.color} isSmall />;
            },
            parent: {
              id: COURSE_TAGS,
            },
            items: filter?.tags,
          };
        }) || {}
      : []),
  ];
};
