export const ROLES = {
  ADMIN: 'ADMIN',
  ASSOCIATE: 'ASSOCIATE',
  MODERATOR: 'MODERATOR',
  USER: 'USER',
};

export const EMPLOYEE_ROLES_LIST = ['ADMIN', 'MODERATOR', 'USER'];

export const ACCOUNT_TYPES = [
  { value: 'USER', label: 'user' },
  { value: 'MODERATOR', label: 'moderator' },
  { value: 'ADMIN', label: 'admin' },
  { value: 'ASSOCIATE', label: 'associate' },
];

export const PERMISSIONS = {
  // People
  canAddNewEmployee: 'CAN_ADD_NEW_EMPLOYEE',
  canSeeEmployeeProfile: 'CAN_SEE_EMPLOYEE_PROFILE',
  canEditGeneralInfo: 'CAN_EDIT_GENERAL_INFO',
  canUseBulkEdit: 'CAN_USE_BULK_EDIT',

  // General
  canAddAppSubscription: 'CAN_ADD_APP_SUBSCRIPTION',
  canAccessDashboard: 'CAN_ACCESS_DASHBOARD',
  canAccessPeopleListing: 'CAN_ACCESS_PEOPLE_LISTING',
  canAccessSurveysManagement: 'CAN_ACCESS_SURVEYS_MANAGEMENT',
  canAccessOrganizationChart: 'CAN_ACCESS_ORGANIZATION_CHART',
  canAccessCumulativeActionPlans: 'CAN_ACCESS_CUMULATIVE_ACTION_PLANS',
  canAccessReports: 'CAN_ACCESS_REPORTS',
  canAccessHelpCenter: 'CAN_ACCESS_HELP_CENTER',
  canManageOrganizationSettings: 'CAN_MANAGE_ORG_SETTINGS',
  canManageSlackIntegration: 'CAN_MANAGE_SLACK_INTEGRATION',
  canSeePrivateNotes: 'CAN_SEE_PRIVATE_NOTES',
  canSeeMyInfo: 'CAN_SEE_MY_INFO',
  canManageTeams: 'CAN_MANAGE_TEAMS',

  // Tags
  canSeeTags: 'CAN_SEE_TAGS',
  canAddTags: 'CAN_ADD_TAGS',
  canDeleteTags: 'CAN_DELETE_TAGS',

  // Career
  canAccessCareer: 'CAN_ACCESS_CAREER',
  canManageTracks: 'CAN_MANAGE_TRACKS',
  canAddJobTitle: 'CAN_ADD_JOB_TITLE',

  // Learning
  canAccessCourses: 'CAN_ACCESS_COURSES',
  canAddCourse: 'CAN_ADD_COURSE',
  canManageCourse: 'CAN_MANAGE_COURSE',
};

export const PERMISSIONS_LIST_BY_ROLE = {
  USER: {
    general: [
      {
        name: 'Can add app subscription',
        key: 'CAN_ADD_APP_SUBSCRIPTION',
        granted: false,
      },
      {
        name: 'Can access dashboard',
        key: 'CAN_ACCESS_DASHBOARD',
        granted: false,
      },
      {
        name: 'Can access people listing',
        key: 'CAN_ACCESS_PEOPLE_LISTING',
        granted: true,
      },
      {
        name: 'Can access surveys management',
        key: 'CAN_ACCESS_SURVEYS_MANAGEMENT',
        granted: false,
      },
      {
        name: 'Can access organization chart',
        key: 'CAN_ACCESS_ORGANIZATION_CHART',
        granted: true,
      },
      {
        name: 'Can access cumulative action plans',
        key: 'CAN_ACCESS_CUMULATIVE_ACTION_PLANS',
        granted: false,
      },
      {
        name: 'Can access reports',
        key: 'CAN_ACCESS_REPORTS',
        granted: false,
      },
      {
        name: 'Can access help center',
        key: 'CAN_ACCESS_HELP_CENTER',
        granted: true,
      },
      {
        name: 'Can see company stats',
        key: 'CAN_SEE_COMPANY_STATS',
        granted: true,
      },
      {
        name: 'Can manage organization settings',
        key: 'CAN_MANAGE_ORG_SETTINGS',
        granted: false,
      },
      {
        name: 'Can manage Slack integration',
        key: 'CAN_MANAGE_SLACK_INTEGRATION',
        granted: false,
      },
      {
        name: 'Can see survey comments',
        key: 'CAN_SEE_SURVEY_COMMENTS',
        granted: false,
      },
      {
        name: 'Can see private notes',
        key: 'CAN_SEE_PRIVATE_NOTES',
        granted: false,
      },
      {
        name: 'Can see my info',
        key: 'CAN_SEE_MY_INFO',
        granted: true,
      },
      {
        name: 'Can manage teams',
        key: 'CAN_MANAGE_TEAMS',
        granted: false,
      },
    ],
    dashboard: [
      {
        name: 'Can see my team',
        key: 'CAN_SEE_MY_TEAM',
        granted: false,
      },
      {
        name: 'Can see recent surveys',
        key: 'CAN_SEE_RECENT_SURVEYS',
        granted: false,
      },
      {
        name: 'Can see team performance',
        key: 'CAN_SEE_TEAM_PERFORMANCE',
        granted: false,
      },
      {
        name: 'Can see my action points',
        key: 'CAN_SEE_MY_ACTION_PLANS',
        granted: true,
      },
    ],
    people: [
      {
        name: 'Can add new employee',
        key: 'CAN_ADD_NEW_EMPLOYEE',
        granted: false,
      },
      {
        name: 'Can see employee profile',
        key: 'CAN_SEE_EMPLOYEE_PROFILE',
        granted: true,
      },
      {
        name: 'Can edit general info',
        key: 'CAN_EDIT_GENERAL_INFO',
        granted: false,
      },
      {
        name: 'Can use bulk edit',
        key: 'CAN_USE_BULK_EDIT',
        granted: false,
      },
    ],
    career: [
      {
        name: 'Can access career',
        key: 'CAN_ACCESS_CAREER',
        granted: false,
      },
      {
        name: 'Can manage tracks',
        key: 'CAN_MANAGE_TRACKS',
        granted: false,
      },
      {
        name: 'Can add job title',
        key: 'CAN_ADD_JOB_TITLE',
        granted: false,
      },
    ],
    tags: [
      {
        name: 'Can see tags',
        key: 'CAN_SEE_TAGS',
        granted: false,
      },
      {
        name: 'Can add tags',
        key: 'CAN_ADD_TAGS',
        granted: false,
      },
      {
        name: 'Can delete tags',
        key: 'CAN_DELETE_TAGS',
        granted: false,
      },
    ],
    learning: [
      {
        name: 'Can access courses',
        key: 'CAN_ACCESS_COURSES',
        granted: true,
      },
      {
        name: 'Can add course',
        key: 'CAN_ADD_COURSE',
        granted: false,
      },
      {
        name: 'Can manage course',
        key: 'CAN_MANAGE_COURSE',
        granted: false,
      },
    ],
  },
  MODERATOR: {
    general: [
      {
        name: 'Can add app subscription',
        key: 'CAN_ADD_APP_SUBSCRIPTION',
        granted: false,
      },
      {
        name: 'Can access dashboard',
        key: 'CAN_ACCESS_DASHBOARD',
        granted: true,
      },
      {
        name: 'Can access people listing',
        key: 'CAN_ACCESS_PEOPLE_LISTING',
        granted: true,
      },
      {
        name: 'Can access surveys management',
        key: 'CAN_ACCESS_SURVEYS_MANAGEMENT',
        granted: true,
      },
      {
        name: 'Can access organization chart',
        key: 'CAN_ACCESS_ORGANIZATION_CHART',
        granted: true,
      },
      {
        id: 26279,
        name: 'Can access cumulative action plans',
        key: 'CAN_ACCESS_CUMULATIVE_ACTION_PLANS',
        granted: true,
      },
      {
        name: 'Can access reports',
        key: 'CAN_ACCESS_REPORTS',
        granted: true,
      },
      {
        name: 'Can access help center',
        key: 'CAN_ACCESS_HELP_CENTER',
        granted: true,
      },
      {
        name: 'Can see company stats',
        key: 'CAN_SEE_COMPANY_STATS',
        granted: true,
      },
      {
        name: 'Can manage organization settings',
        key: 'CAN_MANAGE_ORG_SETTINGS',
        granted: false,
      },
      {
        name: 'Can manage Slack integration',
        key: 'CAN_MANAGE_SLACK_INTEGRATION',
        granted: false,
      },
      {
        name: 'Can see survey comments',
        key: 'CAN_SEE_SURVEY_COMMENTS',
        granted: true,
      },
      {
        name: 'Can see private notes',
        key: 'CAN_SEE_PRIVATE_NOTES',
        granted: true,
      },
      {
        name: 'Can see my info',
        key: 'CAN_SEE_MY_INFO',
        granted: true,
      },
      {
        name: 'Can manage teams',
        key: 'CAN_MANAGE_TEAMS',
        granted: true,
      },
    ],
    dashboard: [
      {
        name: 'Can see my team',
        key: 'CAN_SEE_MY_TEAM',
        granted: true,
      },
      {
        name: 'Can see recent surveys',
        key: 'CAN_SEE_RECENT_SURVEYS',
        granted: true,
      },
      {
        name: 'Can see team performance',
        key: 'CAN_SEE_TEAM_PERFORMANCE',
        granted: true,
      },
      {
        name: 'Can see my action points',
        key: 'CAN_SEE_MY_ACTION_PLANS',
        granted: true,
      },
    ],
    people: [
      {
        name: 'Can add new employee',
        key: 'CAN_ADD_NEW_EMPLOYEE',
        granted: true,
      },
      {
        name: 'Can see employee profile',
        key: 'CAN_SEE_EMPLOYEE_PROFILE',
        granted: true,
      },
      {
        name: 'Can edit general info',
        key: 'CAN_EDIT_GENERAL_INFO',
        granted: true,
      },
      {
        name: 'Can use bulk edit',
        key: 'CAN_USE_BULK_EDIT',
        granted: true,
      },
    ],
    career: [
      {
        name: 'Can access career',
        key: 'CAN_ACCESS_CAREER',
        granted: true,
      },
      {
        name: 'Can manage tracks',
        key: 'CAN_MANAGE_TRACKS',
        granted: false,
      },
      {
        name: 'Can add job title',
        key: 'CAN_ADD_JOB_TITLE',
        granted: false,
      },
    ],
    tags: [
      {
        name: 'Can see tags',
        key: 'CAN_SEE_TAGS',
        granted: true,
      },
      {
        name: 'Can add tags',
        key: 'CAN_ADD_TAGS',
        granted: false,
      },
      {
        name: 'Can delete tags',
        key: 'CAN_DELETE_TAGS',
        granted: false,
      },
    ],
    learning: [
      {
        name: 'Can access courses',
        key: 'CAN_ACCESS_COURSES',
        granted: true,
      },
      {
        name: 'Can add course',
        key: 'CAN_ADD_COURSE',
        granted: true,
      },
      {
        name: 'Can manage course',
        key: 'CAN_MANAGE_COURSE',
        granted: true,
      },
    ],
  },
  ADMIN: {
    general: [
      {
        name: 'Can add app subscription',
        key: 'CAN_ADD_APP_SUBSCRIPTION',
        granted: true,
      },
      {
        name: 'Can access dashboard',
        key: 'CAN_ACCESS_DASHBOARD',
        granted: true,
      },
      {
        name: 'Can access people listing',
        key: 'CAN_ACCESS_PEOPLE_LISTING',
        granted: true,
      },
      {
        name: 'Can access surveys management',
        key: 'CAN_ACCESS_SURVEYS_MANAGEMENT',
        granted: true,
      },
      {
        name: 'Can access organization chart',
        key: 'CAN_ACCESS_ORGANIZATION_CHART',
        granted: true,
      },
      {
        name: 'Can access cumulative action plans',
        key: 'CAN_ACCESS_CUMULATIVE_ACTION_PLANS',
        granted: true,
      },
      {
        name: 'Can access reports',
        key: 'CAN_ACCESS_REPORTS',
        granted: true,
      },
      {
        name: 'Can access help center',
        key: 'CAN_ACCESS_HELP_CENTER',
        granted: true,
      },
      {
        name: 'Can see company stats',
        key: 'CAN_SEE_COMPANY_STATS',
        granted: true,
      },
      {
        name: 'Can manage organization settings',
        key: 'CAN_MANAGE_ORG_SETTINGS',
        granted: true,
      },
      {
        name: 'Can manage Slack integration',
        key: 'CAN_MANAGE_SLACK_INTEGRATION',
        granted: true,
      },
      {
        name: 'Can see survey comments',
        key: 'CAN_SEE_SURVEY_COMMENTS',
        granted: true,
      },
      {
        name: 'Can see private notes',
        key: 'CAN_SEE_PRIVATE_NOTES',
        granted: true,
      },
      {
        name: 'Can see my info',
        key: 'CAN_SEE_MY_INFO',
        granted: true,
      },
      {
        name: 'Can manage teams',
        key: 'CAN_MANAGE_TEAMS',
        granted: true,
      },
    ],
    dashboard: [
      {
        name: 'Can see my team',
        key: 'CAN_SEE_MY_TEAM',
        granted: true,
      },
      {
        name: 'Can see recent surveys',
        key: 'CAN_SEE_RECENT_SURVEYS',
        granted: true,
      },
      {
        name: 'Can see team performance',
        key: 'CAN_SEE_TEAM_PERFORMANCE',
        granted: true,
      },
      {
        name: 'Can see my action points',
        key: 'CAN_SEE_MY_ACTION_PLANS',
        granted: true,
      },
    ],
    people: [
      {
        name: 'Can add new employee',
        key: 'CAN_ADD_NEW_EMPLOYEE',
        granted: true,
      },
      {
        name: 'Can see employee profile',
        key: 'CAN_SEE_EMPLOYEE_PROFILE',
        granted: true,
      },
      {
        name: 'Can edit general info',
        key: 'CAN_EDIT_GENERAL_INFO',
        granted: true,
      },
      {
        name: 'Can use bulk edit',
        key: 'CAN_USE_BULK_EDIT',
        granted: true,
      },
    ],
    career: [
      {
        name: 'Can access career',
        key: 'CAN_ACCESS_CAREER',
        granted: true,
      },
      {
        name: 'Can manage tracks',
        key: 'CAN_MANAGE_TRACKS',
        granted: true,
      },
      {
        name: 'Can add job title',
        key: 'CAN_ADD_JOB_TITLE',
        granted: true,
      },
    ],
    tags: [
      {
        name: 'Can see tags',
        key: 'CAN_SEE_TAGS',
        granted: true,
      },
      {
        name: 'Can add tags',
        key: 'CAN_ADD_TAGS',
        granted: true,
      },
      {
        name: 'Can delete tags',
        key: 'CAN_DELETE_TAGS',
        granted: true,
      },
    ],
    learning: [
      {
        name: 'Can access courses',
        key: 'CAN_ACCESS_COURSES',
        granted: true,
      },
      {
        name: 'Can add course',
        key: 'CAN_ADD_COURSE',
        granted: true,
      },
      {
        name: 'Can manage course',
        key: 'CAN_MANAGE_COURSE',
        granted: true,
      },
    ],
  },
};

export const PERMISSIONS_BY_ROLE = {
  USER: {
    items: [
      {
        key: 'CREATE_SELF_SURVEY',
        text: 'Create Self Survey',
      },
      {
        key: 'CREATE_PRIVATE_ATTRIBUTES',
        text: 'Create private Attributes',
      },
      {
        key: 'VIEW_SDP_THAT_INCLUDE_ONLY_HIMSELF',
        text: 'View Survey results page that include only himself',
      },
      {
        key: 'VIEW_LIBRARY',
        text: 'View Library of courses',
      },
      {
        key: 'ENROLL_TO_COURSE',
        text: 'Enroll to course',
      },
      {
        key: 'ADD_PRIVATE_NOTE',
        text: 'Add private note',
      },
    ],
  },
  MODERATOR: {
    subtitle: 'Everything that Moderator can',
    items: [
      {
        key: 'CREATE_PUBLIC_PRIVATE_ATTRIBUTES',
        text: 'Create public/private Attributes',
      },
      { key: 'ADD_USERS', text: 'Add Users' },
      { key: 'SEND_SURVEYS', text: 'Send Surveys' },
      {
        key: 'VIEW_SDP_FOR_SURVEYS',
        text: 'View Survey results page for surveys',
      },
      { key: 'SETTING_UP_TEAMS', text: 'Setting up Teams' },
      { key: 'CREATE_ACTION_POINT', text: 'Create Action Points' },
      { key: 'MANAGE_LIBRARY_OF_COURSES', text: 'Manage Library of Courses' },
      {
        key: 'ENROLL_USERS_TO_COURSES',
        text: 'Enroll users to Courses',
      },
      {
        key: 'ADD_PRIVATE_NOTES',
        text: 'Add Private notes',
      },
    ],
  },
  ADMIN: {
    subtitle: 'Everything that Admin can',
    items: [
      {
        key: 'MANAGE_USERS',
        text: 'Manage Users',
      },
      {
        key: 'MANAGE_POSITIONS',
        text: 'Manage Positions',
      },
      {
        key: 'MANAGE_TAGS',
        text: 'Manage Tags',
      },
      {
        key: 'CHANGING_PERMISSION_TO_ALL_USERS',
        text: 'Changing Permission to all users',
      },
      {
        key: 'SETTING_CEO',
        text: 'Setting CEO',
      },
    ],
  },
};

export const VISIBILITY = {
  Visibility: [
    {
      key: 0,
      text:
        'Each user sees details about all users that are below him/her in' +
        ' application hierarchy. ' +
        'Configured by reporting-to flag.',
    },
    {
      key: 2,
      text: 'Admin sees all details about all users in the organization.',
    },
  ],
};
