import { memo } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import ColorBox from '../../colorBox';
import { ellipsis } from '../../../../constants/helperCssRules';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    boxSizing: 'border-box',
    borderBottom: `1px solid ${primary.bluish5}`,
    display: 'flex',
    alignItems: 'center',
    padding: spacing(2),
    marginBottom: spacing(1),
  },
  name: {
    flexGrow: 1,
    ...ellipsis(),
  },
  adornment: {
    flexShrink: 0,
    marginRight: spacing(2),
  },
}));

const GroupHeading = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {props.selectProps?.hasCategoryColorBox && (
        <ColorBox
          className={classes.adornment}
          bgColor={props.data.color}
          isSmall
        />
      )}
      <Typography variant="subtitle1" className={classes.name}>
        {props.children}
      </Typography>
    </div>
  );
};

export default memo(GroupHeading);
