import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, Fade, makeStyles } from '@material-ui/core';
import Tooltip from '../tooltip';
import { ReactComponent as PlansIcon } from '../../../assets/icons/assignment_darker.svg';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: 18,
    height: 18,
  },
  iconSpacing: {
    marginRight: spacing(1.5),
  },
  plans: {
    marginBottom: spacing(-0.5),
  },
}));

const PlansIconTooltip = ({ className, text, numberOfPlans, isVisible }) => {
  const classes = useStyles();
  const hasPlans = numberOfPlans !== 0;

  return (
    <Fade
      className={classNames(classes.root, className)}
      in={isVisible}
      unmountOnExit
    >
      <div className={classes.root}>
        <Tooltip
          customIconClass={classNames(classes.icon, {
            [classes.iconSpacing]: hasPlans,
          })}
          text={text}
          icon={PlansIcon}
        />
        {hasPlans && (
          <Typography variant="subtitle2" className={classes.plans}>
            {numberOfPlans}
          </Typography>
        )}
      </div>
    </Fade>
  );
};

PlansIconTooltip.defaultProps = {
  className: undefined,
  numberOfPlans: 0,
  isVisible: false,
};

PlansIconTooltip.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  numberOfPlans: PropTypes.number,
  isVisible: PropTypes.bool,
};

export default PlansIconTooltip;
