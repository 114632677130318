import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isObjectEmpty } from 'utility/helpers';
import Question from '../question';
import Answer from '../answer';
import {
  getResponseCountPerQuestion,
  getQuestionAverage,
} from '../../../../utility/reportUtils';

const GridRow = ({
  className,
  translations,
  row,
  result,
  attributeColor,
  isPercentageView,
  hasPercentageView,
  hasNeutralAnswer,
  hasAverageScore,
}) => {
  const responseCountPerQuestion = useMemo(
    () => getResponseCountPerQuestion(result, hasPercentageView),
    [result, hasPercentageView]
  );
  const totalResponses = useMemo(() => {
    if (!result || !hasPercentageView || isObjectEmpty(result)) {
      return 0;
    }
    return responseCountPerQuestion;
  }, [result, hasPercentageView, responseCountPerQuestion]);

  const questionAverage = useMemo(
    () => getQuestionAverage(row, result, responseCountPerQuestion),
    [result, row, responseCountPerQuestion]
  );

  return (
    <div className={className}>
      <Question
        question={row}
        hasAverageScore={hasAverageScore}
        questionAverage={questionAverage}
        attributeColor={attributeColor}
        translations={translations}
      />
      {row.answers.map((answer, index) => (
        <Answer
          key={`row_${row.id}_${answer.id}`}
          neutralAnswerLabel={translations.neutral.notApplicable}
          isNeutralAnswer={hasNeutralAnswer && index === row.answers.length - 1}
          totalQuestionResponses={totalResponses}
          isPercentageView={isPercentageView}
          answer={answer}
          result={result?.[answer.id]}
        />
      ))}
    </div>
  );
};

GridRow.defaultProps = {
  result: {},
  attributeColor: '',
};

GridRow.propTypes = {
  className: PropTypes.string.isRequired,
  attributeColor: PropTypes.string,
  hasAverageScore: PropTypes.bool.isRequired,
  translations: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  result: PropTypes.shape({}),
  isPercentageView: PropTypes.bool.isRequired,
  hasPercentageView: PropTypes.bool.isRequired,
  hasNeutralAnswer: PropTypes.bool.isRequired,
};

export default memo(GridRow);
