import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Fade, makeStyles } from '@material-ui/core';
import Tooltip from '../tooltip';
import { ReactComponent as RecurringIcon } from '../../../assets/icons/sync.svg';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: 18,
    height: 18,
    marginLeft: spacing(1.5),
  },
}));

const RecurringIconTooltip = ({ iconClass, text, isVisible }) => {
  const classes = useStyles();

  return (
    <Fade in={isVisible} unmountOnExit>
      <div className={classes.root}>
        <Tooltip
          customIconClass={classNames(classes.icon, iconClass)}
          text={text}
          icon={RecurringIcon}
        />
      </div>
    </Fade>
  );
};

RecurringIconTooltip.defaultProps = {
  iconClass: undefined,
};

RecurringIconTooltip.propTypes = {
  iconClass: PropTypes.string,
  text: PropTypes.string.isRequired,
};

export default RecurringIconTooltip;
