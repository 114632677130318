import { useState, useMemo, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Typography,
  Fade,
  FormHelperText,
  makeStyles,
} from '@material-ui/core';
import Tooltip from '../tooltip';
import ColorBox from '../colorBox';
import { getRandomColorFromColorArray } from '../../../utility/uiUtils';
import { ATTRIBUTE_COLORS } from '../../../constants/attributes';
import { AUTOMATION_ID } from '../../../constants/automationId';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  labelWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltipIcon: {
    marginLeft: spacing(1),
    width: 14,
    height: 14,
  },
  pickerButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: spacing(4),
  },
  colorBoxButton: {
    marginRight: spacing(2),
  },
  colorPicker: {
    display: 'grid',
    gridTemplateColumns: 'repeat(15, minMax(20px, 1fr))',
    gridColumnGap: 6,
    gridRowGap: 6,
  },
  colorBox: {
    cursor: 'pointer',
    boxSizing: 'border-box',
    borderRadius: 'unset',
    width: 20,
    height: 20,
    transition: 'box-shadow .25s ease',
  },
  selected: {
    boxShadow: `0 0 0 1px ${primary.white}, 0 0 0 3px ${primary.blue1}`,
  },
}));

const ColorPicker = ({
  label,
  tooltipText,
  shouldOpenPicker,
  colors,
  selectedColor,
  onColorPick,
}) => {
  const classes = useStyles();
  const initialColor = useMemo(
    () => getRandomColorFromColorArray(colors),
    [colors]
  );

  const [isOpened, setIsOpened] = useState(shouldOpenPicker);
  const [color, setColor] = useState(selectedColor || initialColor);

  useEffect(() => {
    if (!selectedColor) {
      onColorPick(initialColor);
    }
  }, [selectedColor, initialColor, onColorPick]);

  const toggleOpen = () => setIsOpened(prevIsOpened => !prevIsOpened);

  const handleColorPick = newColor => {
    if (newColor !== color) {
      setColor(newColor);
      onColorPick(newColor);
    }
  };

  return (
    <div>
      <FormHelperText>
        <span>{label}</span>
        <Tooltip customIconClass={classes.tooltipIcon} text={tooltipText} />
      </FormHelperText>
      <div
        id={AUTOMATION_ID.ADD_BUTTON}
        className={classes.pickerButtonWrapper}
        onClick={toggleOpen}
      >
        <ColorBox className={classes.colorBoxButton} bgColor={color} isLarge />
        <Typography variant="body2">{color}</Typography>
      </div>
      {isOpened ? (
        <Fade in>
          <div className={classes.colorPicker}>
            {colors.map(colorCode => (
              <ColorBox
                key={`attribute_color_${colorCode}`}
                className={classNames(classes.colorBox, {
                  [classes.selected]: colorCode === color,
                })}
                bgColor={colorCode}
                onClickHandler={handleColorPick}
              />
            ))}
          </div>
        </Fade>
      ) : null}
    </div>
  );
};

ColorPicker.defaultProps = {
  selectedColor: '',
  colors: ATTRIBUTE_COLORS,
  shouldOpenPicker: false,
};

ColorPicker.propTypes = {
  label: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
  colors: PropTypes.arrayOf(PropTypes.string),
  selectedColor: PropTypes.string,
  shouldOpenPicker: PropTypes.bool,
  onColorPick: PropTypes.func.isRequired,
};

export default memo(ColorPicker);
