import { parseJSON } from 'date-fns';
import http from './http';
import { isDateInPast } from './dateUtils';
import { API_SUBSCRIPTION } from '../constants/apiRoutes';

export const isPremiumUserCheck = subscriptionData => {
  const { subscription } = subscriptionData;

  return !!subscription;
};

export const isSubscriptionExpiredCheck = subscriptionData => {
  const { subscription, subscription_end_at } = subscriptionData;

  return (
    !!subscription &&
    (subscription_end_at === null ||
      isDateInPast(parseJSON(subscription_end_at)))
  );
};

export const validateFreemiumAction =
  (
    action,
    limitType,
    organizationSettings,
    setDialogVisibility,
    getOrgSettings
  ) =>
  async (...params) => {
    const isPremiumUser = isPremiumUserCheck(organizationSettings);
    const isSubscriptionExpired =
      isSubscriptionExpiredCheck(organizationSettings);

    if (isPremiumUser) {
      if (isSubscriptionExpired) {
        const { data } = await http.get(API_SUBSCRIPTION);

        if (data.is_active) {
          await getOrgSettings();
          await action(...params);
          return Promise.resolve(true);
        }

        if (data.usage[limitType] < data.limit[limitType]) {
          await action(...params);
          return Promise.resolve(true);
        }

        const isExpired = await getOrgSettings();

        if (isExpired) return Promise.resolve(false);

        await action(...params);

        return Promise.resolve(true);
      }

      await action(...params);

      return Promise.resolve(true);
    }

    const { data } = await http.get(API_SUBSCRIPTION);

    if (data.is_active) {
      await getOrgSettings();
      await action(...params);

      return Promise.resolve(true);
    }

    if (data.usage[limitType] < data.limit[limitType]) {
      await action(...params);
      return Promise.resolve(true);
    }

    setDialogVisibility({
      dialogName: 'isLimitReachedDialog',
      opened: true,
    });

    return Promise.resolve(false);
  };
