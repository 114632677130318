import { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography, MenuItem, Radio, makeStyles } from '@material-ui/core';
import TagHash from '../../../../tagHash';
import ColorBox from '../../../../colorBox';
import CustomCheckbox from '../../../../customCheckbox';
import { isArrayEmpty } from '../../../../../../utility/helpers';
import { ellipsis } from '../../../../../../constants/helperCssRules';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    padding: spacing(2, 1, 2, 2),
    margin: spacing(0, 2),
    borderRadius: 4,
    '&:hover': {
      backgroundColor: primary.bluish8,
    },
  },
  contentWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  label: {
    flexGrow: 1,
    ...ellipsis(),
  },
  iconWrapper: {
    display: 'block',
    flexShrink: 0,
    marginRight: spacing(2),
  },
  adornment: {
    flexShrink: 0,
    marginRight: spacing(2),
  },
  radioButton: {
    color: primary.bluish4,
    flexShrink: 0,
    width: 24,
    height: 24,
    marginLeft: spacing(1.5),
  },
  radioChecked: {
    color: `${primary.bluish4} !important`,
  },
  checkbox: {
    marginLeft: spacing(1.5),
  },
}));

const SubMenuItem = ({
  item,
  isSelected,
  isSingleSelect,
  hasHash,
  hasColorBox,
  selectedFilters,
  onSelect,
}) => {
  const classes = useStyles();
  const { id, name, icon: Icon, color, category } = item;

  return (
    <MenuItem
      id={`sub-menu-item-${id}`}
      classes={{
        root: classes.root,
      }}
      onClick={onSelect(id)}
    >
      <span className={classes.contentWrapper}>
        {Icon && (
          <span className={classes.iconWrapper}>
            <Icon />
          </span>
        )}
        {hasHash && (
          <TagHash
            className={classes.adornment}
            bgColor={color || category?.color}
          />
        )}
        {hasColorBox && (
          <ColorBox className={classes.adornment} bgColor={color} isSmall />
        )}
        <Typography className={classes.label} component="span">
          {name}
        </Typography>
        {isSingleSelect ? (
          <Radio
            classes={{
              root: classes.radioButton,
              checked: classes.radioChecked,
            }}
            checked={
              isSelected || (isArrayEmpty(selectedFilters) && id === null)
            }
            onChange={onSelect(id)}
          />
        ) : (
          <CustomCheckbox
            customRootClass={classes.checkbox}
            isChecked={isSelected}
            onChange={onSelect(id)}
            isControlled
          />
        )}
      </span>
    </MenuItem>
  );
};

SubMenuItem.defaultProps = {
  isSelected: false,
  isSingleSelect: false,
  hasHash: false,
  hasColorBox: false,
};

SubMenuItem.propTypes = {
  item: PropTypes.object.isRequired,
  selectedFilters: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
  ).isRequired,
  isSelected: PropTypes.bool,
  isSingleSelect: PropTypes.bool,
  hasHash: PropTypes.bool,
  hasColorBox: PropTypes.bool,
};

export default memo(SubMenuItem);
