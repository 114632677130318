import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const styles = ({ palette: { primary }, spacing }) => ({
  main: {
    display: 'flex',
    color: primary.bluish4,
  },
  backContainer: {
    display: 'flex',
  },
  back: {
    cursor: 'pointer',
    color: primary.blue4,
    textTransform: 'capitalize',
  },
  verticalDivider: {
    margin: spacing(0, 3),
    lineHeight: '14px',
  },
  routes: {
    display: 'flex',
  },
  route: {
    color: primary.bluish4,
    textTransform: 'capitalize',
  },
  hoverRoute: {
    '&:hover': {
      cursor: 'pointer',
      color: primary.blue4,
    },
  },
  routeDivider: {
    margin: spacing(0, 2),
  },
});

class Breadcrumbs extends PureComponent {
  render() {
    const {
      classes,
      translations,
      onHandleBack,
      onRouteClick,
      routes,
      className,
    } = this.props;

    return (
      <div className={classNames(classes.main, className)}>
        <div className={classes.backContainer} onClick={onHandleBack}>
          <Typography variant="body2" className={classes.back}>
            {`< ${translations.back}`}
          </Typography>
          <div className={classes.verticalDivider}>|</div>
        </div>
        {routes.length > 0 &&
          routes.map((route, i) => {
            const isLast = i === routes.length - 1;
            const routeClasses = classNames(classes.route, {
              [classes.hoverRoute]: !isLast,
            });
            return (
              <div key={`route_${i}`} className={classes.routes}>
                <Typography
                  variant="body2"
                  className={routeClasses}
                  onClick={isLast ? () => {} : () => onRouteClick(route.text)}
                >
                  {route.text}
                </Typography>
                {!isLast && <span className={classes.routeDivider}>&gt;</span>}
              </div>
            );
          })}
      </div>
    );
  }
}

Breadcrumbs.defaultProps = {
  className: '',
  onRouteClick: () => {},
};

Breadcrumbs.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  onHandleBack: PropTypes.func.isRequired,
  routes: PropTypes.array.isRequired,
  onRouteClick: PropTypes.func,
};

export default withStyles(styles)(Breadcrumbs);
