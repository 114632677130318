import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { isMeetingAccessible } from 'utility/oneOnOne';
import AsyncListTooltip from '../../../asyncListTooltip';
import Meeting from './meeting';
import { IMPRESSION_VALUES } from '../../../../../constants/oneOnOne';

const useStyles = makeStyles(({ palette: { primary } }) => ({
  root: {
    backgroundColor: ({ report }) =>
      report?.impression
        ? IMPRESSION_VALUES[report.impression - 1].color
        : primary.white,
    borderRadius: '50%',
    alignSelf: 'center',
    height: 16,
    width: 16,
  },
  clickable: {
    cursor: 'pointer',
  },
  asyncTooltip: {
    minWidth: 200,
    maxWidth: 200,
  },
  tooltipLabel: {
    maxHeight: 221,
  },
  asyncNoMeetingTooltip: {
    minWidth: 120,
    maxWidth: 120,
  },
  tooltipNoMeetingLabel: {
    padding: 0,
    marginBottom: 0,
  },
}));

const Report = ({
  translations,
  report,
  hasReport,
  onGetReport,
  onGoToMeetingDetails,
}) => {
  const classes = useStyles({ report });

  const renderMeeting = meeting => <Meeting meeting={meeting} />;
  const renderNoMeeting = label => (
    <Typography variant="caption">{label}</Typography>
  );

  return hasReport ? (
    <AsyncListTooltip
      customPopperClass={classes.asyncTooltip}
      customTooltipClass={classes.asyncTooltip}
      customTooltipLabelClass={classes.tooltipLabel}
      title={translations.meetings}
      renderLabelItem={renderMeeting}
      getLabelData={onGetReport}
      isItemClickable={isMeetingAccessible}
      onItemClickHandler={onGoToMeetingDetails}
    >
      <div className={classNames(classes.root, classes.clickable)} />
    </AsyncListTooltip>
  ) : (
    <AsyncListTooltip
      customPopperClass={classes.asyncNoMeetingTooltip}
      customTooltipClass={classes.asyncNoMeetingTooltip}
      customTooltipLabelClass={classes.tooltipNoMeetingLabel}
      renderLabelItem={renderNoMeeting}
      labelData={[translations.noMeeting]}
    >
      <div className={classes.root} />
    </AsyncListTooltip>
  );
};

Report.defaultProps = {
  report: null,
};

Report.propTypes = {
  report: PropTypes.shape({}),
  hasReport: PropTypes.bool.isRequired,
  onGoToMeetingDetails: PropTypes.func.isRequired,
  onGetReport: PropTypes.func.isRequired,
};

export default memo(Report);
