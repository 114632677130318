import { createAction, handleActions } from 'redux-actions';
import http from '../../utility/http';
import { API_ONE_ON_ONE_TOPICS } from '../../constants/apiRoutes';

// ------------------------------------
// Constants
// ------------------------------------

const SET_ONE_ON_ONE_TOPICS = 'SET_ONE_ON_ONE_TOPICS';

const initialState = {
  topics: [],
};

// ------------------------------------
// Actions
// ------------------------------------

const setOneOnOneTopics = createAction(SET_ONE_ON_ONE_TOPICS);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_ONE_ON_ONE_TOPICS]: (state, { payload }) => {
    return { ...state, topics: payload };
  },
};

// ------------------------------------
// API calls
// ------------------------------------

export const getOneOnOneTopics = dispatch => {
  return http
    .get(`${API_ONE_ON_ONE_TOPICS}?is_recommended=true`)
    .then(({ data }) => {
      dispatch(setOneOnOneTopics(data.results));
    });
};

export default handleActions(reducers, initialState);

// ------------------------------------
// "plain" action functions
// ------------------------------------

export const clearOneOnOneTopics = () => setOneOnOneTopics(initialState.topics);
