import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

const styles = ({ palette: { primary }, spacing }) => ({
  root: {
    backgroundColor: primary.white,
    maxWidth: '100%',
    paddingBottom: spacing(6),
  },
  nav: {
    borderBottom: `1px solid ${primary.bluish7}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 41,
  },
  links: {
    boxSizing: 'border-box',
    display: 'flex',
    height: 40,
  },
  navItem: {
    boxSizing: 'border-box',
    backgroundColor: 'transparent',
    borderBottom: '4px solid transparent',
    textDecoration: 'none',
    lineHeight: '20px',
    fontSize: '16px',
    color: primary.bluish1,
    fontFamily: 'ProximaNova-Bold',
    textTransform: 'uppercase',
    letterSpacing: 1,
    paddingBottom: spacing(2),
    paddingTop: spacing(2),
    marginRight: spacing(6),
    '&.active': {
      borderBottom: `4px solid ${primary.blue1}`,
    },
  },
});

class HorizontalNavigation extends PureComponent {
  render() {
    const { classes, navItems } = this.props;

    return (
      <Grid container spacing={0} className={classes.root}>
        <Grid item xs={12} className={classes.nav}>
          <div className={classes.links}>
            {navItems.map(navItem => {
              return (
                <NavLink
                  className={classes.navItem}
                  to={navItem.url}
                  key={navItem.name}
                  end={navItem.end}
                >
                  {navItem.name}
                </NavLink>
              );
            })}
          </div>
        </Grid>
      </Grid>
    );
  }
}

HorizontalNavigation.propTypes = {
  classes: PropTypes.object.isRequired,
  navItems: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withStyles(styles)(HorizontalNavigation);
