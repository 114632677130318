import { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import CustomButton from 'components/shared/customButton';
import CustomModal from '../../customModal';
import Filters from '../../filters';
import Search from '../../search';
import UsersList from '../../usersList';
import CustomScrollBar from '../../customScrollBar';
import CustomCheckbox from '../../customCheckbox';
import NotificationCard from '../../notificationCard';
import {
  getObjectToNumberArray,
  isArrayEmpty,
} from '../../../../utility/helpers';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    minHeight: 376,
  },
  filtersWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: spacing(4),
  },
  search: {
    flexShrink: 0,
    width: 280,
  },
  usersWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: 352,
    width: '100%',
  },
  allUsersWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 56,
  },
  clearButton: {
    width: 'max-content',
    height: 30,
  },
  checkboxWrapper: {
    width: 100,
  },
  scrollY: {
    top: 0,
    right: -21,
    height: '100%',
  },
  checkbox: {
    marginBottom: spacing(4),
    width: 100,
  },
}));

const Picker = ({
  translations,
  isOpened,
  filters,
  selectedFilters,
  searchTerm,
  selectedUsers,
  users,
  areAllSelected,
  onSelectAll,
  onSelectUser,
  onApplyFilters,
  onSearch,
  onCancel,
  onSave,
  onClearAll,
}) => {
  const isNotEmptySelectedUsers = !isArrayEmpty(selectedUsers);
  const isNotEmptyUsers = !isArrayEmpty(users);
  const classes = useStyles();
  const selected = getObjectToNumberArray(selectedUsers);

  return (
    <CustomModal
      title={translations.title}
      isOpened={isOpened}
      closeButtonLabel={translations.cancel}
      confirmButtonLabel={translations.save}
      onClose={onCancel}
      onConfirm={onSave}
    >
      <div className={classes.root}>
        <div className={classes.filtersWrapper}>
          <Filters
            translations={translations.filters}
            selectedFilters={selectedFilters}
            filters={filters}
            onApplyFilters={onApplyFilters}
          />
          <Search
            className={classes.search}
            placeholder={translations.search}
            value={searchTerm}
            onChange={onSearch}
          />
        </div>
        <div className={classes.usersWrapper}>
          <div className={classes.allUsersWrapper}>
            <div className={classes.checkboxWrapper}>
              {isNotEmptyUsers && (
                <CustomCheckbox
                  customRootClass={classes.checkbox}
                  labelText={translations.allUsers}
                  isChecked={areAllSelected}
                  onChange={onSelectAll}
                />
              )}
            </div>
            {isNotEmptySelectedUsers && (
              <CustomButton
                className={classes.clearButton}
                type="withTextLightRounded"
                onClick={onClearAll}
              >
                {translations.clearAllUsers}
              </CustomButton>
            )}
          </div>
          {isNotEmptyUsers ? (
            <CustomScrollBar
              customScrollBarYClass={classes.scrollY}
              verticalScroll
              removeScrollX
              passContentHeight
              passContentWidth
            >
              <UsersList
                users={users}
                selectedUsers={selected}
                onUserClickHandler={onSelectUser}
                isVerticalList
                isSelectable
              />
            </CustomScrollBar>
          ) : (
            <NotificationCard content={translations.noSearchResults} />
          )}
        </div>
      </div>
    </CustomModal>
  );
};

Picker.defaultProps = {
  searchTerm: '',
  selectedUsers: [],
};

Picker.propTypes = {
  translations: PropTypes.object.isRequired,
  isOpened: PropTypes.bool.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedUsers: PropTypes.arrayOf(PropTypes.object),
  filters: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedFilters: PropTypes.object.isRequired,
  searchTerm: PropTypes.string,
  areAllSelected: PropTypes.bool.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  onSelectUser: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onClearAll: PropTypes.func.isRequired,
};

export default memo(Picker);
