class LoaderService {
  constructor() {
    this.loading = 0;
  }

  start(cb) {
    this.loading += 1;

    if (this.loading === 1) {
      return cb();
    }

    return null;
  }

  end(cb) {
    clearTimeout();

    setTimeout(() => {
      this.loading -= 1;

      if (this.loading === 0) {
        return cb();
      }
    }, 300);

    return null;
  }
}

export default new LoaderService();
