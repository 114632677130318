import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import RichTextPreview from '../richTextPreview';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    maxWidth: 900,
  },
  title: {
    marginBottom: spacing(6),
  },
  descriptionWrapper: {
    border: `1px solid ${primary.bluish6}`,
    padding: spacing(4),
    boxSizing: 'border-box',
    minHeight: 150,
  },
}));

const SurveySummaryComment = ({ className, title, comment }) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.root, className)}>
      <Typography className={classes.title} variant="h5">
        {title}
      </Typography>
      <div className={classes.descriptionWrapper}>
        <RichTextPreview text={comment} />
      </div>
    </div>
  );
};

SurveySummaryComment.defaultProps = {
  className: '',
  comment: null,
};

SurveySummaryComment.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  comment: PropTypes.string,
};

export default SurveySummaryComment;
