import {
  forbiddenCharacters,
  validateLength,
  isEmpty,
} from '../../../utility/validation';
import { isArrayEmpty, trimString } from '../../../utility/helpers';
import http from '../../../utility/http';
import { API_TAGS, API_TAG_CATEGORIES } from '../../../constants/apiRoutes';
import { FIELD_TYPES } from '../../../constants/fieldTypes';
import {
  CATEGORY_PLACEMENTS,
  TAG_CATEGORY_COLORS,
} from '../../../constants/tags';

export const formatPlacements = (data = []) => {
  return data.reduce((acc, item) => {
    return [...acc, { id: item.id, name: CATEGORY_PLACEMENTS[item.name].name }];
  }, []);
};

export const getCategoryData = category => {
  if (category) {
    return {
      ...category,
      category: category.id,
      color: category.color,
      placements: [...(formatPlacements(category.placements) || [])],
    };
  }

  return {
    name: '',
    color: '',
    placements: [],
  };
};

export const getCategoryFields = (renderStartAdornment, placements) => [
  {
    name: 'name',
    required: true,
    type: FIELD_TYPES.TEXT,
    startAdornment: renderStartAdornment,
    validators: [
      { type: 'required', validator: isEmpty },
      {
        type: 'forbiddenCharacters',
        validator: forbiddenCharacters,
      },
      {
        type: 'maxLength',
        validator: validateLength,
      },
      {
        type: 'availableName',
        validator: categoryName => {
          const params = {
            name: trimString(categoryName),
          };

          return http
            .get(API_TAG_CATEGORIES, { params })
            .then(({ data }) => isArrayEmpty(data));
        },
        async: true,
      },
    ],
  },
  {
    name: 'color',
    type: FIELD_TYPES.COLOR_PICKER,
    labelHelp: true,
    shouldOpenPicker: true,
    colors: TAG_CATEGORY_COLORS,
  },
  {
    id: 1,
    name: 'placements',
    type: FIELD_TYPES.FILTERS_SETUP,
    translationKey: 'placements',
    placements: placements?.slice(0, -1),
  },
  {
    id: 2,
    name: 'placements',
    type: FIELD_TYPES.FILTERS_SETUP,
    translationKey: 'organizationMenu',
    placements: [placements?.at(-1)],
  },
];

export const getTagData = (category, tag) => {
  if (tag) {
    return {
      ...tag,
      category: category?.id,
      color: category?.color,
    };
  }

  return {
    category: category?.id,
    color: category?.color,
    tags: category?.tags,
  };
};

export const getTagFields = (
  categories,
  renderTagAdornment,
  isSingleEdit,
  currentCategory
) => {
  if (isSingleEdit) {
    return [
      {
        name: 'category',
        type: FIELD_TYPES.SELECT,
        optionsKey: 'categories',
        hasColorBox: true,
        dependants: [
          {
            name: 'color',
            resetFnc: (prevCategory, selectedCategory) => {
              const category = categories.find(
                ctg => ctg.id === selectedCategory
              );

              return category.color;
            },
          },
        ],
        parser: {
          value: 'id',
          label: 'name',
        },
      },
      {
        name: 'name',
        required: true,
        type: FIELD_TYPES.TEXT,
        startAdornment: renderTagAdornment,
        validators: [
          { type: 'required', validator: isEmpty },
          {
            type: 'forbiddenCharacters',
            validator: forbiddenCharacters,
          },
          {
            type: 'maxLength',
            validator: value => validateLength(value, 0, 50),
          },
          {
            type: 'availableName',
            validator: tagName => {
              const params = {
                name: trimString(tagName),
              };

              return http
                .get(API_TAGS, { params })
                .then(({ data }) => isArrayEmpty(data));
            },
            async: true,
          },
        ],
      },
    ];
  }

  return [
    {
      name: 'category',
      type: FIELD_TYPES.SELECT,
      optionsKey: 'categories',
      isDisabled: true,
      hasColorBox: true,
      parser: {
        value: 'id',
        label: 'name',
      },
    },
    {
      name: 'tags',
      required: true,
      translationKey: 'name',
      type: FIELD_TYPES.CREATABLE_TAGS,
      startAdornment: renderTagAdornment,
      category: currentCategory,
      validators: [
        { type: 'requiredTags', validator: value => !isArrayEmpty(value) },
      ],
      itemValidators: [
        {
          type: 'required',
          validator: (value, tags) => {
            if (!isArrayEmpty(tags)) return true;

            return isEmpty(value);
          },
          shouldPassFormValues: true,
        },
        {
          type: 'forbiddenCharacters',
          validator: forbiddenCharacters,
        },
        {
          type: 'maxLength',
          validator: value => validateLength(value, 0, 50),
        },
        {
          type: 'availableNameInCategory',
          validator: (value, tags) =>
            !tags.some(tag => tag.name === trimString(value)),
          shouldPassFormValues: true,
        },
        {
          type: 'availableName',
          validator: tagName => {
            const sanitizedName = trimString(tagName);

            const params = {
              name: sanitizedName,
            };

            if (!sanitizedName) return true;

            return http
              .get(API_TAGS, { params })
              .then(({ data }) => isArrayEmpty(data));
          },
          async: true,
        },
      ],
    },
  ];
};
