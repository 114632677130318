import { createAction, handleActions } from 'redux-actions';
import http from '../../utility/http';
import { parseDuplicateParameters } from '../../utility/uiUtils';
import {
  isObjectEmpty,
  replaceObjectInList,
  removeObjectFromArray,
  reorderItemsInArrayByIds,
  getFormattedUsers,
} from '../../utility/helpers';
import {
  API_SURVEYS,
  API_SURVEY_GROUPS,
  api_get_survey_tracking_by_id,
  API_USERS_BASIC,
  API_ATTRIBUTES_BASIC,
  API_SURVEY_GROUPS_BASIC,
} from '../../constants/apiRoutes';
import { UserStatuses } from '../../constants/statuses';
import {
  PARAMS,
  SURVEYS_DEFAULT_PARAMS,
  SURVEY_GROUPS_DEFAULT_PARAMS,
} from '../../constants/pages';
import { PEOPLE_DEFAULT_ORDERING } from '../../constants/people';

// ------------------------------------
// Constants
// ------------------------------------

const SET__SURVEYS = 'SET__SURVEYS';
const APPEND_SURVEYS = 'APPEND_SURVEYS';
const SET_SURVEY_GROUPS = 'SET_SURVEY_GROUPS';
const APPEND_SURVEY_GROUPS = 'APPEND_SURVEY_GROUPS';
const SET_ALL_USERS = 'SET_ALL_USERS';
const SET_ATTRIBUTES_WITH_QUESTIONS = 'SET_ATTRIBUTES_WITH_QUESTIONS';
const SET_ALL_SURVEY_GROUPS = 'SET_ALL_SURVEY_GROUPS';
const SET_GROUP_SURVEYS = 'SET_GROUP_SURVEYS';
const DELETE_SURVEY_FROM_GROUP = 'DELETE_SURVEY_FROM_GROUP';

const initialState = {
  surveys: {},
  surveyGroups: {},
  allSurveyGroups: [],
  allUsers: [],
  attributesWithQuestions: [],
  formattedUsers: {},
};

// ------------------------------------
// Actions
// ------------------------------------

const setSurveys = createAction(SET__SURVEYS);
const appendSurveys = createAction(APPEND_SURVEYS);
const setSurveyGroups = createAction(SET_SURVEY_GROUPS);
const appendSurveyGroups = createAction(APPEND_SURVEY_GROUPS);
export const setGroupSurveys = createAction(SET_GROUP_SURVEYS);
const setAllUsers = createAction(SET_ALL_USERS);
const setAttributesWithQuestions = createAction(SET_ATTRIBUTES_WITH_QUESTIONS);
const setAllSurveyGroups = createAction(SET_ALL_SURVEY_GROUPS);
export const deleteSurveyFromGroup = createAction(DELETE_SURVEY_FROM_GROUP);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET__SURVEYS]: (state, { payload }) => {
    return { ...state, surveys: payload };
  },
  [APPEND_SURVEYS]: (state, { payload }) => {
    return isObjectEmpty(payload)
      ? { ...state, surveys: payload }
      : {
          ...state,
          surveys: {
            ...payload,
            results: state.surveys.results
              ? [...state.surveys.results, ...payload.results]
              : [...payload.results],
          },
        };
  },
  [SET_SURVEY_GROUPS]: (state, { payload }) => {
    return { ...state, surveyGroups: payload };
  },
  [APPEND_SURVEY_GROUPS]: (state, { payload }) => {
    return isObjectEmpty(payload)
      ? { ...state, surveyGroups: payload }
      : {
          ...state,
          surveyGroups: {
            ...payload,
            results: state.surveyGroups.results
              ? [...state.surveyGroups.results, ...payload.results]
              : [...payload.results],
          },
        };
  },
  [SET_GROUP_SURVEYS]: (state, { payload }) => {
    const groupIndex = state.surveyGroups.results.findIndex(
      group => group.id === payload.id
    );

    return {
      ...state,
      surveyGroups: {
        ...state.surveyGroups,
        results: replaceObjectInList(state.surveyGroups.results, groupIndex, {
          ...state.surveyGroups.results[groupIndex],
          surveys: reorderItemsInArrayByIds(
            state.surveyGroups.results[groupIndex].surveys,
            payload.surveyIds
          ),
        }),
      },
    };
  },
  [DELETE_SURVEY_FROM_GROUP]: (state, { payload }) => {
    const groupIndex = state.surveyGroups.results.findIndex(
      group => group.id === payload.groupId
    );
    const updatedGroup = {
      ...state.surveyGroups.results[groupIndex],
      surveys: removeObjectFromArray(
        state.surveyGroups.results[groupIndex].surveys,
        payload.survey
      ),
    };

    return {
      ...state,
      surveyGroups: {
        ...state.surveyGroups,
        results: replaceObjectInList(
          state.surveyGroups.results,
          groupIndex,
          updatedGroup
        ),
      },
    };
  },
  [SET_ALL_SURVEY_GROUPS]: (state, { payload }) => {
    return {
      ...state,
      allSurveyGroups: payload,
    };
  },
  [SET_ALL_USERS]: (state, { payload }) => {
    const preparedUsers = Array.isArray(payload)
      ? payload.map(user => {
          return { ...user, label: `${user.first_name} ${user.last_name} ` };
        })
      : [];

    return {
      ...state,
      allUsers: preparedUsers,
      formattedUsers: getFormattedUsers(payload, true),
    };
  },
  [SET_ATTRIBUTES_WITH_QUESTIONS]: (state, { payload }) => {
    return { ...state, attributesWithQuestions: payload };
  },
};

// ------------------------------------
// API calls
// ------------------------------------

export const getSurveys = (dispatch, isLoadMore = false, params = {}) => {
  return http
    .get(API_SURVEYS, {
      params: { ...SURVEYS_DEFAULT_PARAMS, ...params },
      paramsSerializer: data => parseDuplicateParameters(data),
    })
    .then(({ data }) => {
      dispatch(isLoadMore ? appendSurveys(data) : setSurveys(data));
    });
};

export const getSurveyGroups = (dispatch, isLoadMore = false, params = {}) => {
  return http
    .get(API_SURVEY_GROUPS, {
      params: { ...SURVEY_GROUPS_DEFAULT_PARAMS, ...params },
      paramsSerializer: data => parseDuplicateParameters(data),
    })
    .then(({ data }) => {
      dispatch(isLoadMore ? appendSurveyGroups(data) : setSurveyGroups(data));
    });
};

export const getAllUsers = (dispatch, params = {}) => {
  const [, , , SUSPENDED] = UserStatuses;

  return http
    .get(API_USERS_BASIC, {
      params: {
        ...PEOPLE_DEFAULT_ORDERING,
        [PARAMS.EXCLUDE_STATUS]: SUSPENDED.id,
        ...params,
      },
      paramsSerializer: data => parseDuplicateParameters(data),
    })
    .then(({ data }) => {
      dispatch(setAllUsers(data));
    });
};

export const getAttributesWithQuestions = (dispatch, params = {}) => {
  return http
    .get(API_ATTRIBUTES_BASIC, {
      params: { ...params, with_questions: true, is_active: true },
      paramsSerializer: data => parseDuplicateParameters(data),
    })
    .then(({ data }) => {
      dispatch(setAttributesWithQuestions(data));
    });
};

export const getSurveyTrackingById = id => {
  return http.get(api_get_survey_tracking_by_id(id));
};

export const getAllSurveyGroups = dispatch => {
  return http
    .get(API_SURVEY_GROUPS_BASIC, {
      params: {
        [PARAMS.ORDERING]: SURVEY_GROUPS_DEFAULT_PARAMS[PARAMS.ORDERING],
      },
    })
    .then(({ data }) => dispatch(setAllSurveyGroups(data)));
};

export default handleActions(reducers, initialState);

// ------------------------------------
// "plain" action functions
// ------------------------------------

export const clearSurveys = () => setSurveys(initialState.surveys);
export const clearSurveyGroups = () =>
  setSurveyGroups(initialState.surveyGroups);
export const clearAllUsers = () => setAllUsers(initialState.allUsers);
export const clearAttributesWithQuestions = () =>
  setAttributesWithQuestions(initialState.attributesWithQuestions);
export const clearAllSurveyGroups = () =>
  setAllSurveyGroups(initialState.allSurveyGroups);
