import { memo } from 'react';
import PropTypes from 'prop-types';
import { Fade, makeStyles } from '@material-ui/core';
import SectionTitle from '../sectionTitle';
import JobTitle from './jobTitle';
import AttributesPerformance from './attributesPerformance';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  title: {
    marginBottom: spacing(4),
  },
  content: {
    boxSizing: 'border-box',
    borderRadius: 4,
    border: `1px solid ${primary.bluish6}`,
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    gridColumnGap: 1,
    position: 'relative',
    width: '100%',
  },
  divider: {
    '&:before': {
      boxSizing: 'border-box',
      borderRight: `1px dashed ${primary.bluish6}`,
      content: '""',
      position: 'absolute',
      right: -1,
      top: 0,
      width: 1,
      height: '100%',
    },
  },
}));

const CareerPerformance = ({
  className,
  translations,
  jobTitle,
  core,
  niche,
  selfCore,
  selfNiche,
  hasAssignJobTitleButton,
  hasAddJobTitleButton,
  hasSendSurveyButton,
  hasCompareReportButtonCheck,
  onAddJobTitle,
  onAssignJobTitle,
  onSendSurvey,
  isUserSuspended,
  onRedirectToCompareReport,
}) => {
  const classes = useStyles();

  return (
    <Fade in appear unmountOnExit>
      <div className={className}>
        <SectionTitle
          className={classes.title}
          title={translations.title}
          variant="h4"
        />
        <div className={classes.content}>
          <JobTitle
            className={classes.divider}
            translations={translations}
            jobTitle={jobTitle}
            hasAssignJobTitleButton={hasAssignJobTitleButton}
            hasAddJobTitleButton={hasAddJobTitleButton}
            isAssignDisabled={isUserSuspended}
            onAddJobTitle={onAddJobTitle}
            onAssignJobTitle={onAssignJobTitle}
          />
          <AttributesPerformance
            className={classes.divider}
            translations={translations.measuredByOthers}
            coreAttributes={core}
            nicheAttributes={niche}
            hasSendSurveyButton={hasSendSurveyButton}
            hasCompareReportButtonCheck={hasCompareReportButtonCheck}
            isSendSurveyDisabled={isUserSuspended}
            onRedirectToCompareReport={onRedirectToCompareReport}
            onSendSurvey={onSendSurvey}
            hasJobTitle={!!jobTitle.name}
          />
          <AttributesPerformance
            translations={translations.selfMeasured}
            coreAttributes={selfCore}
            nicheAttributes={selfNiche}
            hasSendSurveyButton={hasSendSurveyButton}
            hasCompareReportButtonCheck={hasCompareReportButtonCheck}
            isSendSurveyDisabled={isUserSuspended}
            hasJobTitle={!!jobTitle.name}
            onRedirectToCompareReport={onRedirectToCompareReport}
            onSendSurvey={onSendSurvey}
            isSelf
          />
        </div>
      </div>
    </Fade>
  );
};

CareerPerformance.defaultProps = {
  className: null,
  isUserSuspended: false,
  core: [],
  niche: [],
  selfCore: [],
  selfNiche: [],
};

CareerPerformance.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  jobTitle: PropTypes.shape({}).isRequired,
  core: PropTypes.arrayOf(PropTypes.object),
  niche: PropTypes.arrayOf(PropTypes.object),
  selfCore: PropTypes.arrayOf(PropTypes.object),
  selfNiche: PropTypes.arrayOf(PropTypes.object),
  hasAssignJobTitleButton: PropTypes.bool.isRequired,
  hasCompareReportButtonCheck: PropTypes.func.isRequired,
  hasAddJobTitleButton: PropTypes.bool.isRequired,
  hasSendSurveyButton: PropTypes.bool.isRequired,
  isUserSuspended: PropTypes.bool,
  onAddJobTitle: PropTypes.func.isRequired,
  onAssignJobTitle: PropTypes.func.isRequired,
  onSendSurvey: PropTypes.func.isRequired,
  onRedirectToCompareReport: PropTypes.func.isRequired,
};

export default memo(CareerPerformance);
