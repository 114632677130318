import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import InputField from '../inputField';
import CustomRichTextEditor from '../customRichTextEditor';
import CustomButton from '../customButton';
import AlertDialog from '../alertDialog';
import ArrowTooltip from '../arrowTooltip';
import ActionButton from '../actionButton';
import { ReactComponent as ErrorIcon } from '../../../assets/icons/warning-triangle.svg';
import { isArrayEmpty, trimString } from '../../../utility/helpers';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';
import {
  getColumnStyles,
  getDeleteDialogTranslations,
  getTrackLevelsForDelete,
  FIELDS,
  NO_VALUE_PLACEHOLDER,
  EMPTY_FIELD,
  EMPTY_TRACK_LEVEL,
  MINIMUM_NUMBER_OF_LEVELS,
} from './config';

const styles = ({ palette: { primary }, spacing }) => ({
  main: {
    display: 'grid',
    width: 'fit-content',
  },
  grid: {
    display: 'grid',
  },
  cell: {
    backgroundColor: primary.white,
    boxSizing: 'border-box',
    boxShadow: `0 0 0 1px ${primary.bluish6}`,
    padding: spacing(3.5, 3),
    margin: spacing(0.25, 0.25, 0, 0),
    minHeight: 50,
    transition: 'box-shadow .3s ease',
  },
  cellHover: {
    '&:hover': {
      boxShadow: `0 0 0 1px ${primary.bluish3}`,
      position: 'relative',
      zIndex: 100,
    },
  },
  trackCell: {
    padding: spacing(2.5, 4),
    maxWidth: 374,
  },
  trackCellDeletable: {
    padding: spacing(2.5, 3, 2, 4),
  },
  dark: {
    backgroundColor: primary.bluish8,
  },
  cellContent: {
    color: primary.bluish1,
    fontFamily: 'ProximaNova-Bold',
    fontSize: 16,
    lineHeight: '18px',
  },
  input: {
    borderRadius: 'unset',
    border: 'none',

    '& input': {
      padding: 0,
      '&::placeholder': {
        color: primary.bluish4,
        fontFamily: 'ProximaNova-Bold',
        fontSize: 16,
        lineHeight: '18px',
        letterSpacing: 'normal',
      },
    },
  },
  levelCell: {
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    maxWidth: 71,
  },
  levelCellEditable: {
    maxWidth: 100,
  },
  levelCellWithError: {
    padding: spacing(4, 10, 4, 3),
  },
  focus: {
    position: 'relative',
    border: 'none !important',
    boxShadow: `0 0 0 1px ${primary.bluish3}`,
    zIndex: 100,
  },
  focusDisabled: {
    border: 'none !important',
    boxShadow: `0 0 0 1px ${primary.bluish6}`,
  },
  trackLevelsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 374,
  },
  descriptionCellWrapper: {
    height: '100%',
    marginTop: spacing(0.25),
    position: 'relative',
  },
  descriptionCell: {
    height: '100%',
    minHeight: 133,
    boxShadow: `0 0 0 1px ${primary.bluish6}`,
    transition: 'box-shadow .3s ease',
  },
  descriptionCellFocus: {
    boxShadow: `0 0 0 1px ${primary.bluish3}`,
    position: 'relative',
    zIndex: 100,

    '& .ql-container': {
      height: 'calc(100% - 38px)',
    },
    '& .ql-toolbar': {
      padding: '8px !important',
      maxHeight: '38px !important',
      transition: 'all 0.25s ease-in',
    },
  },
  editor: {
    height: '100%',
    '& :first-child': {
      border: 'none',
      borderRadius: 'unset',
    },
    '& :last-child': {
      border: 'none',
      borderRadius: 'unset',
    },
    '& .ql-toolbar': {
      transition: 'all .15s ease-out',
      maxHeight: 0,
      padding: 0,
      overflow: 'hidden',
    },
  },
  addButton: {
    border: 'none',
    marginTop: spacing(0.25),
    boxShadow: `0 0 0 1px ${primary.bluish6}`,
  },
  addTrackPlaceholderCell: {
    boxSizing: 'border-box',
    boxShadow: `0 0 0 1px ${primary.bluish6}`,
    marginTop: spacing(0.25),
    minHeight: 50,
  },
  addLevelButton: {
    backgroundColor: primary.bluish8,
    border: 'none',
    boxShadow: `0 0 0 1px ${primary.bluish5}`,
    minHeight: 38,
    padding: 7,
    marginTop: 1,
    width: 'calc(100% - 40px)',

    '&:hover': {
      backgroundColor: primary.bluish8,
    },
  },
  actionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
  },
  deleteButton: {
    marginLeft: spacing(1.5),
  },
  errorIcon: {
    marginLeft: spacing(1.5),
    cursor: 'pointer',
  },
  cellError: {
    boxShadow: `0 0 0 1px ${primary.red1}`,
    position: 'relative',
    zIndex: 101,
  },
  levelNameError: {
    position: 'absolute',
    right: 12,
    top: 14,
  },
  trackLevelDescriptionError: {
    position: 'absolute',
    right: 16,
    top: 10,
    zIndex: 999,
  },
  error: {
    margin: spacing(2),
  },
});

class LevelingAndTracksFieldTable extends PureComponent {
  state = {
    isDeleteLevelOpened: false,
    isDeleteTrackOpened: false,
    isDeleteTrackLevelOpened: false,
    deleteItemIndex: -1,
    deleteItemParentIndex: -1,
    numberOfUsers: 0,
  };

  toggleDeleteDialogVisibility = () =>
    this.setState({
      isDeleteLevelOpened: false,
      isDeleteTrackOpened: false,
      isDeleteTrackLevelOpened: false,
      deleteItemIndex: -1,
      deleteItemParentIndex: -1,
      numberOfUsers: 0,
    });

  handleDelete = async (
    itemIndex,
    itemId,
    isTrackDelete,
    isTrackLevelDelete,
    deleteItemParentIndex = -1
  ) => {
    const { getAssignedUsers } = this.props;

    const { data = {} } = itemId
      ? await getAssignedUsers(itemId, isTrackDelete)
      : {};

    return this.setState({
      isDeleteLevelOpened: !isTrackDelete && !isTrackLevelDelete,
      isDeleteTrackOpened: !!isTrackDelete,
      isDeleteTrackLevelOpened: !!isTrackLevelDelete,
      deleteItemIndex: itemIndex,
      numberOfUsers: data.count || 0,
      deleteItemParentIndex,
    });
  };

  onAddNewTrack = () => {
    const { values, onChange } = this.props;
    const newTrack = {
      ...EMPTY_FIELD,
      track_levels: Array(values.levels.length).fill(EMPTY_TRACK_LEVEL),
    };

    return onChange({ ...values, tracks: [...values.tracks, newTrack] });
  };

  onAddNewLevel = () => {
    const { values, onChange } = this.props;
    const tracks = values.tracks.map(track => ({
      ...track,
      track_levels: track.track_levels.concat(EMPTY_TRACK_LEVEL),
    }));

    return onChange({
      ...values,
      levels: values.levels.concat(EMPTY_FIELD),
      tracks,
    });
  };

  onDeleteLevel = levelIndex => {
    const { values, onChange } = this.props;

    onChange({
      ...values,
      levels: values.levels.filter((level, index) => index !== levelIndex),
      tracks: [
        ...values.tracks.map(track => ({
          ...track,
          track_levels: track.track_levels.filter(
            (lvl, index) => index !== levelIndex
          ),
        })),
      ],
    });
  };

  onDeleteTrack = trackIndex => {
    const { values, onChange } = this.props;

    onChange({
      ...values,
      tracks: [...values.tracks.filter((track, index) => index !== trackIndex)],
    });
  };

  onDeleteTrackLevel = (trackIndex, trackLevelIndex) => {
    const { values, onChange } = this.props;

    const track = {
      ...values.tracks[trackIndex],
      track_levels: values.tracks[trackIndex].track_levels.map(
        (trLvl, index) => {
          if (index === trackLevelIndex) {
            return {
              ...trLvl,
              ...EMPTY_TRACK_LEVEL,
            };
          }
          return trLvl;
        }
      ),
    };
    const tracks = [...values.tracks];
    tracks.splice(trackIndex, 1, track);

    return onChange({
      ...values,
      tracks,
    });
  };

  onDelete = () => {
    const {
      deleteItemIndex,
      deleteItemParentIndex,
      isDeleteLevelOpened,
      isDeleteTrackOpened,
    } = this.state;

    if (isDeleteLevelOpened) {
      this.onDeleteLevel(deleteItemIndex);
    } else if (isDeleteTrackOpened) {
      this.onDeleteTrack(deleteItemIndex);
    } else {
      this.onDeleteTrackLevel(deleteItemParentIndex, deleteItemIndex);
    }

    this.toggleDeleteDialogVisibility();
  };

  onInputChange = (fieldName, fieldIndex, value, fieldPosition) => {
    const { values, onChange } = this.props;

    switch (fieldName) {
      case FIELDS.TRACK_NAME: {
        const track = { ...values.tracks[fieldIndex], name: value };
        const tracks = [...values.tracks];
        tracks.splice(fieldIndex, 1, track);

        return onChange({
          ...values,
          tracks,
        });
      }
      case FIELDS.LEVEL_NAME: {
        const levels = [...values.levels];
        levels.splice(fieldIndex, 1, {
          ...values.levels[fieldIndex],
          name: value,
        });

        return onChange({
          ...values,
          levels,
        });
      }
      default: {
        const updatedTrack = {
          ...values.tracks[fieldIndex],
          track_levels: values.tracks[fieldIndex].track_levels.map(
            (trLvl, index) => {
              return {
                ...trLvl,
                name:
                  fieldName === FIELDS.TRACK_LEVEL_NAME &&
                  index === fieldPosition
                    ? value
                    : trLvl.name,
                description:
                  fieldName === FIELDS.TRACK_LEVEL_DESCRIPTION &&
                  index === fieldPosition
                    ? value
                    : trLvl.description,
              };
            }
          ),
        };
        const tracks = [...values.tracks];
        tracks.splice(fieldIndex, 1, updatedTrack);

        return onChange({
          ...values,
          tracks,
        });
      }
    }
  };

  renderErrorMessage = errorMessage => {
    const { classes } = this.props;

    return (
      <Typography variant="body2" className={classes.error}>
        {errorMessage}
      </Typography>
    );
  };

  renderError = errorMessage => {
    const { classes } = this.props;

    return (
      <ArrowTooltip
        position="top"
        tooltipLabel={this.renderErrorMessage(errorMessage)}
      >
        <ErrorIcon className={classes.errorIcon} />
      </ArrowTooltip>
    );
  };

  renderFieldActions = ({
    className,
    itemIndex,
    itemId,
    isTrackDelete,
    shouldRender = true,
    hasDelete = true,
    parentIndex,
    isTrackLevelDelete,
    errorMessage,
  }) => {
    const { classes, isReadOnly } = this.props;

    return (
      !isReadOnly &&
      shouldRender && (
        <div className={classNames(classes.actionsWrapper, className)}>
          {errorMessage && this.renderError(errorMessage)}
          {hasDelete && (
            <ActionButton
              className={classes.deleteButton}
              size="small"
              type={
                isTrackLevelDelete
                  ? ACTION_BUTTON_TYPES.CLEAR
                  : ACTION_BUTTON_TYPES.DELETE
              }
              onClickHandler={() =>
                this.handleDelete(
                  itemIndex,
                  itemId,
                  isTrackDelete,
                  isTrackLevelDelete,
                  parentIndex
                )
              }
            />
          )}
        </div>
      )
    );
  };

  renderHeader = () => {
    const { classes, translations, values, errors, isReadOnly } = this.props;
    const numberOfTracks = values.tracks.length;

    return (
      <div
        className={classes.grid}
        style={getColumnStyles(numberOfTracks, !isReadOnly)}
      >
        <div className={classNames(classes.cell, classes.dark)}>
          <Typography variant="subtitle1" className={classes.cellContent}>
            {translations.level}
          </Typography>
        </div>
        {values.tracks.map((track, index) => {
          const trackNameErrorMessage =
            translations.validationMessages?.[errors?.tracks?.[index]?.name];

          return (
            <InputField
              key={`header_column_${index}`}
              customRootClass={classNames(
                classes.cell,
                classes.trackCell,
                classes.dark,
                classes.input,
                classes.cellContent,
                {
                  [classes.cellHover]: !isReadOnly,
                  [classes.trackCellDeletable]: !isReadOnly,
                  [classes.cellError]: trackNameErrorMessage,
                }
              )}
              customFocusClass={classNames(classes.focus, {
                [classes.focusDisabled]: isReadOnly,
              })}
              placeholder={translations.track}
              endAdornment={this.renderFieldActions({
                itemIndex: index,
                itemId: track.id,
                isTrackDelete: true,
                errorMessage: trackNameErrorMessage,
              })}
              value={track.name}
              readOnly={isReadOnly}
              onChange={e =>
                this.onInputChange(FIELDS.TRACK_NAME, index, e.target.value)
              }
              fullWidth
            />
          );
        })}
        {!isReadOnly && (
          <CustomButton
            type="addWithTextDarkNew"
            className={classes.addButton}
            onClick={this.onAddNewTrack}
          >
            {translations.addTrackButtonLabel}
          </CustomButton>
        )}
      </div>
    );
  };

  renderBody = () => {
    const { classes, translations, values, isReadOnly, errors } = this.props;
    const shouldRenderLevelDelete =
      values.levels.length !== MINIMUM_NUMBER_OF_LEVELS;

    return values.levels.map((level, index) => {
      const levelNameError =
        translations.validationMessages?.[errors?.levels?.[index]];

      return (
        <div
          key={`row_${index}`}
          className={classes.grid}
          style={getColumnStyles(values.tracks.length, !isReadOnly)}
        >
          <InputField
            customRootClass={classNames(
              classes.cell,
              classes.input,
              classes.cellContent,
              classes.levelCell,
              {
                [classes.levelCellEditable]: !isReadOnly,
                [classes.cellHover]: !isReadOnly,
                [classes.cellError]: levelNameError,
                [classes.levelCellWithError]: levelNameError,
              }
            )}
            customFocusClass={classNames(classes.focus, {
              [classes.focusDisabled]: isReadOnly,
            })}
            endAdornment={this.renderFieldActions({
              hasDelete: false,
              errorMessage: levelNameError,
              className: classes.levelNameError,
            })}
            placeholder={translations.level}
            value={level.name}
            readOnly={isReadOnly}
            onChange={e =>
              this.onInputChange(FIELDS.LEVEL_NAME, index, e.target.value)
            }
            fullWidth
          />
          {values.tracks.map((track, i) => {
            const shouldRenderTrackLevelDelete =
              !isReadOnly &&
              (trimString(track.track_levels[index]?.name) ||
                trimString(track.track_levels[index]?.description));
            const trackLevelNameError =
              translations.validationMessages?.[
                errors?.tracks?.[i]?.levels?.[index]?.name
              ];
            const trackLevelDescriptionError =
              translations.validationMessages?.[
                errors?.tracks?.[i]?.levels?.[index]?.description
              ];

            return (
              <div
                key={`track_cell_${i}`}
                className={classes.trackLevelsWrapper}
              >
                <InputField
                  key={`header_column_${index}`}
                  customRootClass={classNames(
                    classes.cell,
                    classes.trackCell,
                    classes.input,
                    classes.cellContent,
                    {
                      [classes.cellHover]: !isReadOnly,
                      [classes.trackCellDeletable]: !isReadOnly,
                      [classes.cellError]: trackLevelNameError,
                    }
                  )}
                  customFocusClass={classNames(classes.focus, {
                    [classes.focusDisabled]: isReadOnly,
                  })}
                  endAdornment={this.renderFieldActions({
                    itemIndex: index,
                    itemId: track.track_levels[index]?.id,
                    shouldRender: shouldRenderTrackLevelDelete,
                    parentIndex: i,
                    isTrackLevelDelete: true,
                    errorMessage: trackLevelNameError,
                  })}
                  placeholder={
                    isReadOnly ? NO_VALUE_PLACEHOLDER : translations.title
                  }
                  value={track.track_levels[index]?.name || ''}
                  readOnly={isReadOnly}
                  onChange={e =>
                    this.onInputChange(
                      FIELDS.TRACK_LEVEL_NAME,
                      i,
                      e.target.value,
                      index
                    )
                  }
                  fullWidth
                />
                <div className={classes.descriptionCellWrapper}>
                  <CustomRichTextEditor
                    className={classNames(classes.descriptionCell, {
                      [classes.cellHover]: !isReadOnly,
                      [classes.cellError]: trackLevelDescriptionError,
                    })}
                    customEditorClass={classes.editor}
                    customFocusClass={classes.descriptionCellFocus}
                    placeholder={
                      isReadOnly
                        ? NO_VALUE_PLACEHOLDER
                        : translations.description
                    }
                    value={track.track_levels[index]?.description || ''}
                    isReadOnly={isReadOnly}
                    onUpdateText={value =>
                      this.onInputChange(
                        FIELDS.TRACK_LEVEL_DESCRIPTION,
                        i,
                        value,
                        index
                      )
                    }
                  />
                  {trackLevelDescriptionError &&
                    this.renderFieldActions({
                      className: classes.trackLevelDescriptionError,
                      hasDelete: false,
                      errorMessage: trackLevelDescriptionError,
                    })}
                </div>
              </div>
            );
          })}
          {!isReadOnly && (
            <>
              <div
                className={classNames(
                  classes.addTrackPlaceholderCell,
                  classes.dark
                )}
              />
              {this.renderFieldActions({
                itemIndex: index,
                itemId: getTrackLevelsForDelete(values.tracks, index),
                shouldRender: shouldRenderLevelDelete,
              })}
            </>
          )}
        </div>
      );
    });
  };

  render() {
    const { classes, translations, values, isReadOnly } = this.props;
    const {
      isDeleteTrackOpened,
      isDeleteLevelOpened,
      isDeleteTrackLevelOpened,
      numberOfUsers,
    } = this.state;

    return (
      values &&
      !isArrayEmpty(values.levels) && (
        <div className={classes.main}>
          {this.renderHeader()}
          {this.renderBody()}
          {!isReadOnly && (
            <CustomButton
              type="addWithTextDarkNew"
              className={classes.addLevelButton}
              onClick={this.onAddNewLevel}
            >
              {translations.addLevelButtonLabel}
            </CustomButton>
          )}
          {!isReadOnly && (
            <>
              <AlertDialog
                translations={getDeleteDialogTranslations(
                  translations.deleteTrackDialog,
                  numberOfUsers
                )}
                isOpened={isDeleteTrackOpened}
                onClose={this.toggleDeleteDialogVisibility}
                onConfirm={this.onDelete}
                isWarning
              />
              <AlertDialog
                translations={getDeleteDialogTranslations(
                  translations.deleteLevelDialog,
                  numberOfUsers
                )}
                isOpened={isDeleteLevelOpened}
                onClose={this.toggleDeleteDialogVisibility}
                onConfirm={this.onDelete}
                isWarning
              />
              <AlertDialog
                translations={getDeleteDialogTranslations(
                  translations.deleteTrackLevelDialog,
                  numberOfUsers
                )}
                isOpened={isDeleteTrackLevelOpened}
                onClose={this.toggleDeleteDialogVisibility}
                onConfirm={this.onDelete}
                isWarning
              />
            </>
          )}
        </div>
      )
    );
  }
}

LevelingAndTracksFieldTable.defaultProps = {
  values: null,
  isReadOnly: false,
  errors: null,
  getAssignedUsers: () => {},
  onChange: () => {},
};

LevelingAndTracksFieldTable.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  values: PropTypes.shape({}),
  isReadOnly: PropTypes.bool,
  errors: PropTypes.object,
  getAssignedUsers: PropTypes.func,
  onChange: PropTypes.func,
};

export default withStyles(styles)(LevelingAndTracksFieldTable);
