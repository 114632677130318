import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { isArrayEmpty } from '../../../utility/helpers';
import CustomRichTextEditor from '../customRichTextEditor';

const styles = ({ palette: { primary } }) => ({
  label: {
    lineHeight: '18px',
    marginBottom: 24,
  },
  options: {
    display: 'grid',
    gridColumnGap: 24,
    marginBottom: 24,
    width: 'max-content',
  },
  option: {
    cursor: 'pointer',
    boxSizing: 'border-box',
    border: `1px dashed ${primary.bluish3}`,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 258,
    height: 125,
    gridRow: 1,
    transition: 'border .3s ease',
  },
  optionIcon: {
    width: 'inherit',
    '& > div': {
      height: 'inherit',
      '& .ql-toolbar': {
        border: 0,
      },
      '& .ql-container': {
        border: 0,
      },
      '& .ql-editor > p': {
        cursor: 'pointer',
        fontFamily: 'ProximaNova-Regular',
      },
    },
  },
  isSelected: {
    border: `1px solid ${primary.blue1}`,
  },
});

class SelectWithPreview extends PureComponent {
  renderOptions = () => {
    const { classes, options, selectedOption, onSelect } = this.props;

    return (
      !isArrayEmpty(options) && (
        <div className={classes.options}>
          {options.map(option => (
            <div
              key={`option_item_${option.id}`}
              className={classNames(classes.option, {
                [classes.isSelected]: selectedOption?.id === option.id,
              })}
              onClick={() => onSelect(option)}
            >
              <CustomRichTextEditor
                className={classes.optionIcon}
                isReadOnly
                value={option.value.description}
                onUpdateText={() => {}}
              />
            </div>
          ))}
        </div>
      )
    );
  };

  renderPreview = () => {
    const {
      translations,
      selectedOption,
      previewComponent: Preview,
    } = this.props;

    return (
      selectedOption && (
        <Preview
          translations={translations.preview}
          values={selectedOption.value}
          isReadOnly
        />
      )
    );
  };

  render() {
    const { classes, translations } = this.props;

    return (
      <div>
        {translations.label && (
          <Typography className={classes.label}>
            {translations.label}
          </Typography>
        )}
        {this.renderOptions()}
        {this.renderPreview()}
      </div>
    );
  }
}

SelectWithPreview.defaultProps = {
  options: [],
  selectedOption: null,
};

SelectWithPreview.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  selectedOption: PropTypes.shape({}),
  previewComponent: PropTypes.elementType.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default withStyles(styles)(SelectWithPreview);
