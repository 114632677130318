import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import ProposedStatus from '../../proposedStatus';
import UserLink from '../../userLink';
import CustomButton from '../../customButton';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  title: {
    color: primary.bluish4,
    marginBottom: spacing(2),
  },
  user: {
    margin: spacing(3, 0),
  },
  proposedOn: {
    color: primary.bluish4,
    marginBottom: spacing(2),
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  acceptButton: {
    marginLeft: spacing(-2),
  },
  dismissButton: {
    color: primary.bluish1,
  },
}));

const PlanProposalCard = ({ translations, proposal, onAccept, onDismiss }) => {
  const classes = useStyles();

  const { user, isAccessible, proposedOn } = proposal;

  const handleAccept = useCallback(() => {
    return onAccept(proposal.id);
  }, [proposal, onAccept]);

  const handleDismiss = useCallback(() => {
    onDismiss(proposal);
  }, [proposal, onDismiss]);

  return (
    <div>
      <Typography className={classes.title} variant="caption">
        {translations.title.toUpperCase()}
      </Typography>
      <ProposedStatus proposedLabel={translations.proposed} />
      <UserLink
        className={classes.user}
        user={user}
        isUserAccessible={isAccessible}
      />
      <Typography
        className={classes.proposedOn}
        variant="caption"
      >{`${translations.proposedOn}: ${proposedOn}`}</Typography>
      <div className={classes.actions}>
        <CustomButton
          className={classes.acceptButton}
          type="textRoundedSmall"
          onClick={handleAccept}
        >
          {translations.accept}
        </CustomButton>
        <CustomButton
          className={classes.dismissButton}
          type="textRoundedSmall"
          onClick={handleDismiss}
        >
          {translations.dismiss}
        </CustomButton>
      </div>
    </div>
  );
};

PlanProposalCard.defaultProps = {
  proposal: {},
};

PlanProposalCard.propTypes = {
  proposal: PropTypes.object,
  onAccept: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default PlanProposalCard;
