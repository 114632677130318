import { Route, Routes, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReduxRouter } from '@lagunovsky/redux-react-router';
import './styles/App.css';
import { MuiThemeProvider } from '@material-ui/core';
import ProtectedRoute from './components/shared/protectedRoute';
import AlertComponent from './components/shared/alertComponent';
import SidebarNavigation from './components/shared/sidebarNavigation';
import KadarTheme from './styles/theme';
import ScrollToTop from './components/shared/scrollToTop';
import SurveysPageView from './views/surveys';
import SurveySettingsPageView from './views/surveySettings';
import SurveyReportPage from './components/pages/surveyReportPage';
import AttributesPageView from './views/attributes';
import CompareReportPage from './components/pages/compareReportPage';
import PeoplePageView from './views/people';
import PeopleProfilePageView from './views/peopleProfile';
import PeopleActionPlansPageView from './views/peopleActionPlans';
import PeopleTeamPageView from './views/peopleTeam';
import PeopleInsightsPageView from './views/peopleInsights';
import PeopleCoursesPageView from './views/peopleCourses';
import PeopleNotesView from './views/peopleNotes';
import PeopleOneOnOnePageView from './views/peopleOneOnOne';
import PeopleCareerPageView from './views/peopleCareer';
import LoginPage from './components/pages/loginPage';
import PageNotFoundView from './views/pageNotFound';
import Loader from './views/loader';
import ForgottenPasswordPage from './components/pages/forgottenPasswordPage';
import ResetLinkSentPage from './components/pages/resetLinkSentPage';
import ResetPasswordPage from './components/pages/resetPasswordPage';
import TagsPage from './components/pages/tagsPage';
import TagCategoryPage from './components/pages/tagCategoryPage';
import CompanyOverviewPage from './components/pages/companyOverviewPage';
import FillSurveyPageView from './views/fillSurvey';
import ThankYouPage from './components/pages/thankYouPage';
import OrganizationPageView from './views/organization';
import ActionPlansPageView from './views/actionPlans';
import ActionPlanDetailsPageView from './views/actionPlanDetails';
import PrivateNotesView from './views/privateNotes';
import OneOnOnePageView from './views/oneOnOne';
import OneOnOneDetailsPageView from './views/oneOnOneDetails';
import UnauthorizedAccessPageView from './views/unauthorizedAccess';
import RouteRequiredRoles, {
  ROUTE_ALL_ROLES,
  ROUTE_MANAGING_ROLES,
} from './constants/routeRequiredRoles';
import CoursesPageView from './views/courses';
import CourseDetailsPageView from './views/courseDetails';
import PrivacyPolicyPage from './components/pages/privacyPolicyPage';
import TermsOfServicePage from './components/pages/termsOfServicePage';
import CompetenceMapPageView from './views/competenceMap';
import SkillsMatrixPageView from './views/skillsMatrix';
import DashboardPage from './components/pages/dashboardPage';
import OneOnOneReportPageView from './views/oneOnOneReport';
import AttributeReportPage from './components/pages/attributeReportPage';
import SurveySummaryPage from './components/pages/surveySummaryPage';
import SurveySummaryDetailsPage from './components/pages/surveySummaryDetailsPage';
import OrganizationSettingsPage from './components/pages/organizationSettingsPage';
import UpgradeRequiredDialog from './components/shared/upgradeRequiredDialog';
import LevelingPageView from './views/leveling';
import ReleaseNotesPageView from './views/releaseNotes';
import JobTitlesPageView from './views/jobTitles';
import ChatButton from './components/shared/chatButton';
import PricingPlansPage from './components/pages/pricingPlansPage';
import SlackIntegrationPage from './components/pages/slackIntegrationPage';
import FreePlanLimitDialog from './components/shared/freePlanLimitDialog';
import { useTranslations } from './utility/useTranslations';
import { APP_PAGES } from './constants/pages';
import { authSelector } from './store/selectors/authSelector';
import { organizationSettingsSelector } from './store/selectors/organizationSettingsSelector';
import { grantedPermissionsSelector } from './store/selectors/grantedPermissionsSelector';
import { ROLES } from './constants/rolesAndPermissionList';
import SignUpView from './views/signUp';
import InstructionGuideDrawer from './components/shared/instructionGuideDrawer';

const routerSelector = state => state.router;

const App = ({ history }) => {
  const translations = useTranslations(APP_PAGES.APP);
  const { auth } = useSelector(authSelector);
  const { organizationSettings, organizationMenuSetup, presets } = useSelector(
    organizationSettingsSelector
  );
  const { grantedPermissions } = useSelector(grantedPermissionsSelector);

  const isAuthenticated = !!auth.access;
  const isAdmin = auth.role === ROLES.ADMIN;

  return (
    <MuiThemeProvider theme={KadarTheme}>
      <ReduxRouter history={history} routerSelector={routerSelector}>
        <ScrollToTop>
          <div className="App">
            <Loader />
            <Routes>
              <Route
                path="/"
                element={
                  <ProtectedRoute
                    auth={auth}
                    organizationSettings={organizationSettings}
                    requiredRoles={RouteRequiredRoles.dashboard}
                  >
                    <DashboardPage />
                  </ProtectedRoute>
                }
              />
              <Route path="action-plans">
                <Route
                  element={
                    <ProtectedRoute
                      history={history}
                      auth={auth}
                      organizationSettings={organizationSettings}
                      requiredRoles={RouteRequiredRoles.actionPlans}
                    >
                      <ActionPlansPageView />
                    </ProtectedRoute>
                  }
                  index
                />
                <Route
                  path=":actionPlanId/details"
                  element={
                    <ProtectedRoute
                      history={history}
                      auth={auth}
                      organizationSettings={organizationSettings}
                      requiredRoles={RouteRequiredRoles.actionPlanDetails}
                    >
                      <ActionPlanDetailsPageView />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route
                path="my-private-notes"
                element={
                  <ProtectedRoute
                    history={history}
                    auth={auth}
                    organizationSettings={organizationSettings}
                    requiredRoles={RouteRequiredRoles.privateNotes}
                  >
                    <PrivateNotesView />
                  </ProtectedRoute>
                }
              />
              <Route path="1-1">
                <Route
                  element={
                    <ProtectedRoute
                      history={history}
                      auth={auth}
                      organizationSettings={organizationSettings}
                      requiredRoles={RouteRequiredRoles.oneOnOne}
                    >
                      <OneOnOnePageView />
                    </ProtectedRoute>
                  }
                  index
                />
                <Route
                  path=":oneOnOneId/details"
                  element={
                    <ProtectedRoute
                      history={history}
                      auth={auth}
                      organizationSettings={organizationSettings}
                      requiredRoles={RouteRequiredRoles.oneOnOneDetails}
                    >
                      <OneOnOneDetailsPageView />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route path="learning">
                <Route
                  element={
                    <ProtectedRoute
                      history={history}
                      auth={auth}
                      organizationSettings={organizationSettings}
                      requiredRoles={RouteRequiredRoles.course.course}
                    >
                      <CoursesPageView />
                    </ProtectedRoute>
                  }
                  index
                />
                <Route
                  path=":courseId"
                  element={
                    <ProtectedRoute
                      history={history}
                      auth={auth}
                      organizationSettings={organizationSettings}
                      requiredRoles={RouteRequiredRoles.course.courseDetails}
                    >
                      <CourseDetailsPageView />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route path="surveys">
                <Route
                  element={
                    <ProtectedRoute
                      history={history}
                      auth={auth}
                      organizationSettings={organizationSettings}
                      requiredRoles={RouteRequiredRoles.survey.survey}
                    >
                      <SurveysPageView />
                    </ProtectedRoute>
                  }
                  index
                />
                <Route
                  path=":surveyId/settings"
                  element={
                    <ProtectedRoute
                      history={history}
                      auth={auth}
                      organizationSettings={organizationSettings}
                      requiredRoles={RouteRequiredRoles.survey.surveySettings}
                    >
                      <SurveySettingsPageView />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path=":surveyId/report"
                  element={
                    <ProtectedRoute
                      auth={auth}
                      organizationSettings={organizationSettings}
                      requiredRoles={RouteRequiredRoles.survey.surveyReport}
                    >
                      <SurveyReportPage />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route
                path="attributes"
                element={
                  <ProtectedRoute
                    history={history}
                    auth={auth}
                    organizationSettings={organizationSettings}
                    requiredRoles={RouteRequiredRoles.survey.attributes}
                  >
                    <AttributesPageView />
                  </ProtectedRoute>
                }
              />
              <Route path="reports">
                {isAdmin && (
                  <Route
                    path="company-overview"
                    element={
                      <ProtectedRoute
                        history={history}
                        auth={auth}
                        organizationSettings={organizationSettings}
                        requiredRoles={
                          RouteRequiredRoles.reports.companyOverview
                        }
                      >
                        <CompanyOverviewPage />
                      </ProtectedRoute>
                    }
                  />
                )}
                <Route
                  path="competence-map"
                  element={
                    <ProtectedRoute
                      history={history}
                      auth={auth}
                      organizationSettings={organizationSettings}
                      presets={presets}
                      requiredRoles={
                        organizationSettings?.mods_see_competence
                          ? ROUTE_MANAGING_ROLES
                          : RouteRequiredRoles.reports.competenceMap
                      }
                    >
                      <CompetenceMapPageView />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="skills-matrix"
                  element={
                    <ProtectedRoute
                      history={history}
                      auth={auth}
                      organizationSettings={organizationSettings}
                      presets={presets}
                      requiredRoles={RouteRequiredRoles.reports.skillsMatrix}
                    >
                      <SkillsMatrixPageView />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="general-mood"
                  element={
                    <ProtectedRoute
                      history={history}
                      auth={auth}
                      organizationSettings={organizationSettings}
                      presets={presets}
                      requiredRoles={RouteRequiredRoles.reports.generalMood}
                    >
                      <OneOnOneReportPageView />
                    </ProtectedRoute>
                  }
                  index
                />
                {isAdmin && (
                  <Route
                    path="attribute"
                    element={
                      <ProtectedRoute
                        history={history}
                        auth={auth}
                        organizationSettings={organizationSettings}
                        presets={presets}
                        requiredRoles={RouteRequiredRoles.reports.attribute}
                      >
                        <AttributeReportPage />
                      </ProtectedRoute>
                    }
                  />
                )}
                <Route path="survey-summary">
                  <Route
                    element={
                      <ProtectedRoute
                        auth={auth}
                        organizationSettings={organizationSettings}
                        requiredRoles={RouteRequiredRoles.reports.surveySummary}
                      >
                        <SurveySummaryPage />
                      </ProtectedRoute>
                    }
                    index
                  />
                  <Route
                    path=":summaryId/details"
                    element={
                      <ProtectedRoute
                        auth={auth}
                        organizationSettings={organizationSettings}
                        requiredRoles={RouteRequiredRoles.reports.surveySummary}
                      >
                        <SurveySummaryDetailsPage />
                      </ProtectedRoute>
                    }
                  />
                </Route>
                {isAdmin && (
                  <Route
                    path="compare"
                    element={
                      <ProtectedRoute
                        history={history}
                        auth={auth}
                        organizationSettings={organizationSettings}
                        presets={presets}
                        requiredRoles={RouteRequiredRoles.reports.compare}
                      >
                        <CompareReportPage />
                      </ProtectedRoute>
                    }
                  />
                )}
              </Route>
              <Route
                path="levels-and-tracks"
                element={
                  <ProtectedRoute
                    history={history}
                    auth={auth}
                    organizationSettings={organizationSettings}
                    requiredRoles={
                      organizationSettings?.users_see_leveling
                        ? ROUTE_ALL_ROLES
                        : RouteRequiredRoles.career.leveling
                    }
                  >
                    <LevelingPageView />
                  </ProtectedRoute>
                }
              />
              <Route
                path="job-titles"
                element={
                  <ProtectedRoute
                    auth={auth}
                    organizationSettings={organizationSettings}
                    requiredRoles={
                      organizationSettings?.users_see_positions
                        ? ROUTE_ALL_ROLES
                        : RouteRequiredRoles.career.jobTitles
                    }
                  >
                    <JobTitlesPageView history={history} />
                  </ProtectedRoute>
                }
              />
              <Route path="people">
                <Route
                  element={
                    <ProtectedRoute
                      history={history}
                      auth={auth}
                      organizationSettings={organizationSettings}
                      requiredRoles={RouteRequiredRoles.people.people}
                    >
                      <PeoplePageView />
                    </ProtectedRoute>
                  }
                  index
                />
                <Route
                  path=":id"
                  element={
                    <ProtectedRoute
                      history={history}
                      auth={auth}
                      organizationSettings={organizationSettings}
                      requiredRoles={RouteRequiredRoles.people.career}
                      isPersonInfoRoute
                    >
                      <PeopleCareerPageView />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path=":id/insights"
                  element={
                    <ProtectedRoute
                      history={history}
                      auth={auth}
                      organizationSettings={organizationSettings}
                      requiredRoles={RouteRequiredRoles.people.insights}
                      isPersonInfoRoute
                    >
                      <PeopleInsightsPageView />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path=":id/my-people"
                  element={
                    <ProtectedRoute
                      history={history}
                      auth={auth}
                      organizationSettings={organizationSettings}
                      requiredRoles={RouteRequiredRoles.people.team}
                      isPersonInfoRoute
                    >
                      <PeopleTeamPageView />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path=":id/action-plans"
                  element={
                    <ProtectedRoute
                      history={history}
                      auth={auth}
                      organizationSettings={organizationSettings}
                      requiredRoles={RouteRequiredRoles.people.actionPlans}
                      isPersonInfoRoute
                    >
                      <PeopleActionPlansPageView />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path=":id/learning"
                  element={
                    <ProtectedRoute
                      history={history}
                      auth={auth}
                      organizationSettings={organizationSettings}
                      requiredRoles={RouteRequiredRoles.people.courses}
                      isPersonInfoRoute
                    >
                      <PeopleCoursesPageView />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path=":id/notes"
                  element={
                    <ProtectedRoute
                      history={history}
                      auth={auth}
                      organizationSettings={organizationSettings}
                      requiredRoles={RouteRequiredRoles.people.notes}
                      isPersonInfoRoute
                    >
                      <PeopleNotesView />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path=":id/one-on-one"
                  element={
                    <ProtectedRoute
                      history={history}
                      auth={auth}
                      organizationSettings={organizationSettings}
                      requiredRoles={RouteRequiredRoles.people.oneOnOne}
                      isPersonInfoRoute
                    >
                      <PeopleOneOnOnePageView />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path=":id/general-info"
                  element={
                    <ProtectedRoute
                      history={history}
                      auth={auth}
                      organizationSettings={organizationSettings}
                      requiredRoles={RouteRequiredRoles.people.generalInfo}
                      isPersonInfoRoute
                    >
                      <PeopleProfilePageView />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route
                path="org-chart"
                element={
                  <ProtectedRoute
                    history={history}
                    auth={auth}
                    organizationSettings={organizationSettings}
                    requiredRoles={RouteRequiredRoles.organization}
                  >
                    <OrganizationPageView />
                  </ProtectedRoute>
                }
              />
              <Route path="tags">
                <Route
                  element={
                    <ProtectedRoute
                      history={history}
                      auth={auth}
                      organizationSettings={organizationSettings}
                      requiredRoles={RouteRequiredRoles.tags}
                    >
                      <TagsPage />
                    </ProtectedRoute>
                  }
                  index
                />
                <Route
                  path=":categoryId/people"
                  element={
                    <ProtectedRoute
                      auth={auth}
                      organizationSettings={organizationSettings}
                      requiredRoles={RouteRequiredRoles.tagCategory}
                    >
                      <TagCategoryPage />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route
                path="organization-settings"
                element={
                  <ProtectedRoute
                    history={history}
                    auth={auth}
                    organizationSettings={organizationSettings}
                    requiredRoles={RouteRequiredRoles.organizationSettings}
                  >
                    <OrganizationSettingsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="release-notes"
                element={
                  <ProtectedRoute
                    history={history}
                    auth={auth}
                    organizationSettings={organizationSettings}
                    requiredRoles={RouteRequiredRoles.releaseNotes}
                  >
                    <ReleaseNotesPageView />
                  </ProtectedRoute>
                }
              />
              <Route
                path="slack-integration"
                element={
                  <ProtectedRoute
                    history={history}
                    auth={auth}
                    organizationSettings={organizationSettings}
                    requiredRoles={RouteRequiredRoles.slackIntegration}
                  >
                    <SlackIntegrationPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/privacy-policy/:landing?"
                element={<PrivacyPolicyPage history={history} />}
              />
              <Route
                path="/terms-of-service"
                element={<TermsOfServicePage history={history} />}
              />
              <Route
                path="sign-up"
                element={<SignUpView history={history} />}
              />
              <Route
                path="fill-survey/:token"
                element={<FillSurveyPageView history={history} />}
              />
              <Route
                path="pricing-plans"
                element={
                  <ProtectedRoute
                    auth={auth}
                    organizationSettings={organizationSettings}
                    requiredRoles={RouteRequiredRoles.pricingPlans}
                  >
                    <PricingPlansPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="unauthorized-access"
                element={<UnauthorizedAccessPageView />}
              />
              <Route
                path="thank-you"
                element={<ThankYouPage history={history} />}
              />
              <Route
                path="login/:token?"
                element={<LoginPage history={history} />}
              />
              <Route
                path="forgotten-password"
                element={<ForgottenPasswordPage history={history} />}
              />
              <Route
                path="reset-link-sent/:email/:resend?"
                element={<ResetLinkSentPage history={history} />}
              />
              <Route
                path="set-password/:uid/:token/:name?"
                element={<ResetPasswordPage history={history} />}
              />
              <Route path="not-found" element={<PageNotFoundView />} />
              <Route path="*" element={<Navigate to="/not-found" replace />} />
            </Routes>
            {isAuthenticated && (
              <SidebarNavigation
                translations={translations.sidebarNavigation}
                user={auth}
                grantedPermissions={grantedPermissions}
                organizationSettings={organizationSettings}
                organizationMenuSetup={organizationMenuSetup}
              />
            )}
            <AlertComponent />
            {isAuthenticated && <UpgradeRequiredDialog currentUser={auth} />}
            {isAuthenticated && <FreePlanLimitDialog currentUser={auth} />}
            {isAuthenticated && (
              <InstructionGuideDrawer
                user={auth}
                organizationSettings={organizationSettings}
              />
            )}
            <ChatButton translations={translations} />
          </div>
        </ScrollToTop>
      </ReduxRouter>
    </MuiThemeProvider>
  );
};

export default App;
