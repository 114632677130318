import { FIELD_TYPES } from './fieldTypes';
import {
  forbiddenCharacters,
  validateLength,
  isEmpty,
} from '../utility/validation';
import { trimString } from '../utility/helpers';

export const GENERAL_CATEGORY_ID = 1;

export const GENERAL_CATEGORY_COLOR = '#E2773C';

export const TAG_CATEGORY_COLORS = [
  '#E2773C',
  '#E0B494',
  '#2B6297',
  '#63975B',
  '#A6B1CA',
  '#4183E5',
  '#81B2E6',
  '#D96965',
  '#A770A9',
  '#5CAFC6',
  '#C1DE6A',
  '#E5C958',
  '#DF9C5C',
  '#55AC68',
  '#4DA38A',
  '#336089',
  '#3373E0',
  '#95AAE0',
  '#9E9E6B',
  '#8B1B5A',
  '#EA6971',
  '#57972B',
  '#6D1877',
  '#65799F',
  '#6F1510',
  '#5B59E4',
  '#3C3CE3',
  '#B296EE',
  '#778226',
  '#E9FB55',
];

export const CREATABLE_TAG_FIELD = {
  type: FIELD_TYPES.TEXT,
  required: true,
  validators: [
    { type: 'required', validator: isEmpty },
    {
      type: 'forbiddenCharacters',
      validator: forbiddenCharacters,
    },
    {
      type: 'maxLength',
      validator: value => validateLength(value, 0, 50),
    },
    {
      type: 'availableName',
      validator: (value, tagCategories) => {
        let error = true;

        for (let i = 0; i < tagCategories.length; i += 1) {
          const hasExistingTag = tagCategories[i].tags.some(
            tag => tag.name === trimString(value)
          );
          if (hasExistingTag) {
            error = !hasExistingTag;
            break;
          }
        }
        return error;
      },
      shouldPassFormValues: true,
    },
  ],
};

export const CATEGORY_PLACEMENTS = {
  PEOPLE: { label: 'People directory', name: 'People directory' },
  JOBS: { label: 'Job titles', name: 'Job titles' },
  ATTRIBUTE: { label: 'Attributes', name: 'Attributes' },
  LEARNING: { label: 'Learning hub', name: 'Learning hub' },
  ONE_ON_ONE: { label: '1-1', name: '1-1' },
  ACTION_POINTS: {
    label: 'Action plans',
    name: 'Action plans',
  },
  ORGANIZATION_MENU: {
    label: 'Organization menu',
    name: 'Organization menu setup',
  },
};

export const PLACEMENTS = {
  PEOPLE: { id: 1, name: 'PEOPLE' },
  ATTRIBUTE: { id: 2, name: 'ATTRIBUTE' },
  JOBS: { id: 3, name: 'JOBS' },
  LEARNING: { id: 4, name: 'LEARNING' },
  ONE_ON_ONE: { id: 5, name: 'ONE_ON_ONE' },
  ACTION_POINTS: { id: 6, name: 'ACTION_POINTS' },
  ORGANIZATION_MENU: { id: 7, name: 'ORGANIZATION_MENU' },
};
