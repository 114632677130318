import { memo } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup } from 'react-transition-group';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Fade } from '@material-ui/core';
import UserAvatar from '../../userAvatar';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    marginTop: spacing(6),
    width: '100%',
  },
  answer: {
    boxSizing: 'border-box',
    borderRadius: 4,
    border: `1px solid ${primary.bluish4}`,
    padding: spacing(4),
    marginBottom: spacing(4),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  answerContent: {
    display: 'grid',
    gridColumnGap: 1,
    gridTemplateColumns: 'minmax(250px, 1fr) 4fr',
  },
  user: {
    boxSizing: 'border-box',
    paddingRight: spacing(8),
    position: 'relative',
    width: '100%',
    '&::after': {
      borderRight: `1px dashed ${primary.bluish4}`,
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      width: 1,
      height: '100%',
    },
  },
  replyWrapper: {
    boxSizing: 'border-box',
    paddingLeft: spacing(8),
    width: '100%',
  },
  reply: {
    paddingTop: spacing(0.75),
    wordBreak: 'break-word',
  },
}));

const Answers = ({
  notApplicableLabel,
  answers,
  isAnonymous,
  anonymousUser,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TransitionGroup>
        {answers.map((answer, index) => (
          <Fade key={`text_reply_${index}`} in>
            <div className={classes.answer}>
              <div className={classes.answerContent}>
                <div className={classes.user}>
                  <UserAvatar
                    variant="subtitle2"
                    anonymousUser={isAnonymous}
                    user={isAnonymous ? anonymousUser : answer.user}
                    small
                    caption
                  />
                </div>
                <div className={classes.replyWrapper}>
                  <Typography className={classes.reply} variant="body2">
                    {answer.not_applicable ? notApplicableLabel : answer.reply}
                  </Typography>
                </div>
              </div>
            </div>
          </Fade>
        ))}
      </TransitionGroup>
    </div>
  );
};

Answers.defaultProps = {
  answers: [],
};

Answers.propTypes = {
  isAnonymous: PropTypes.bool.isRequired,
  anonymousUser: PropTypes.object.isRequired,
  notApplicableLabel: PropTypes.string.isRequired,
  answers: PropTypes.arrayOf(PropTypes.shape({})),
};

export default memo(Answers);
