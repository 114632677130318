import { PureComponent } from 'react';
import { getFields } from './config';
import SignUpForm from '../../shared/signUpForm';
import BackgroundContainer from '../../shared/backgroundContainer';

class SignUpPage extends PureComponent {
  render() {
    const {
      translations,
      setSignUp,
      clearSignUp,
      signUp,
      validateOrganizationName,
      history,
    } = this.props;

    return (
      <BackgroundContainer translations={translations.authContainer} signUpPage>
        <SignUpForm
          translations={translations}
          initialData={signUp}
          history={history}
          fields={getFields(translations)}
          setSignUp={setSignUp}
          clearSignUp={clearSignUp}
          validateOrganizationName={validateOrganizationName}
        />
      </BackgroundContainer>
    );
  }
}

SignUpPage.defaultProps = {};

SignUpPage.propTypes = {};

export default SignUpPage;
