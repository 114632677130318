import { isArrayEmpty } from '../../../../utility/helpers';
import { PLACEHOLDER_ATTRIBUTE } from '../../../../constants/attributes';

const hasAttributes = attributes =>
  Object.keys(attributes).some(attKey => !isArrayEmpty(attributes[attKey]));

const ATTRIBUTE_PLACEHOLDER_NUMBER = 3;

export const getAttributeGroups = (
  translations,
  hasJobTitle,
  coreAttributes,
  nicheAttributes
) => {
  const placeholderAttributes = new Array(ATTRIBUTE_PLACEHOLDER_NUMBER).fill(
    PLACEHOLDER_ATTRIBUTE
  );
  const coreGroup = {};
  const nicheGroup = {};
  const hasCoreAttr = hasAttributes(coreAttributes);
  const hasNicheAttr = hasAttributes(nicheAttributes);

  if (hasJobTitle) {
    coreGroup.title = translations.coreAttributes;
    coreGroup.attributes = hasCoreAttr ? coreAttributes : placeholderAttributes;
    if (hasNicheAttr) {
      nicheGroup.title = translations.nicheAttributes;
      nicheGroup.attributes = nicheAttributes;
    }
  } else {
    nicheGroup.title = translations.attributes;
    nicheGroup.attributes = hasNicheAttr
      ? nicheAttributes
      : placeholderAttributes;
  }

  return [coreGroup, nicheGroup];
};
