import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import { formatDate } from '../../../utility/dateUtils';
import http from '../../../utility/http';
import SectionLoader from '../../shared/sectionLoader';
import { ellipsis, sticky } from '../../../constants/helperCssRules';
import { API_RELEASE_NOTES } from '../../../constants/apiRoutes';

const styles = ({ palette: { primary }, spacing }) => ({
  topSpacing: {
    paddingTop: spacing(8),
    ...sticky(primary.white, 105),
  },
  releaseItem: {
    display: 'flex',
    boxSizing: 'border-box',
    border: `1px solid ${primary.bluish7}`,
    borderRadius: 4,
    width: '100%',
    padding: spacing(6),
    marginBottom: spacing(4),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  content: {
    width: 'calc(100% - 65px)',
  },
  name: {
    marginBottom: spacing(3),
  },
  description: {
    color: primary.bluish3,
    fontFamily: 'ProximaNova-Bold',
    lineHeight: '18px',
    '& ul': {
      marginTop: 0,
      paddingLeft: spacing(5),
    },
  },
  label: {
    color: primary.bluish4,
    marginBottom: spacing(1),
  },
  isLatest: {
    boxSizing: 'border-box',
    backgroundColor: primary.bluish7,
    color: primary.bluish1,
    padding: spacing(1, 2),
    justifySelf: 'start',
    ...ellipsis(),
    maxWidth: 62,
    height: 28,
  },
});

const DATE_FORMAT = 'MMM d, y';

class ReleaseNotesPage extends PureComponent {
  state = {
    isLoading: true,
    releases: [],
  };

  componentDidMount() {
    http
      .get(API_RELEASE_NOTES)
      .then(({ data }) => {
        this.setState({
          isLoading: false,
          releases: data.map(r => {
            const {
              id,
              tag_name,
              name,
              prerelease,
              created_at,
              published_at,
              body,
              draft,
            } = r;
            return {
              id,
              tag_name,
              name,
              prerelease,
              created_at,
              published_at,
              body,
              draft,
            };
          }),
        });
      })
      .catch(() => {
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    const { classes, translations } = this.props;
    const { isLoading, releases } = this.state;
    const currentVersion = `v${process.env.REACT_APP_VERSION_NUMBER}`;

    return (
      <div>
        {isLoading ? (
          <SectionLoader isLoading={isLoading} />
        ) : (
          <div>
            <div className={classes.topSpacing} />
            {releases.map(release => (
              <div key={release.id} className={classes.releaseItem}>
                <div className={classes.content}>
                  <Typography className={classes.label}>
                    {translations.version}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.name}>
                    {release.name}
                  </Typography>
                  <Typography className={classes.label}>
                    {translations.releaseNote}
                  </Typography>
                  <div className={classes.description}>
                    <ReactMarkdown>{release.body}</ReactMarkdown>
                  </div>
                  <Typography className={classes.label}>
                    {translations.publishedDate}
                  </Typography>
                  <Typography variant="subtitle2">
                    {formatDate(release.published_at, DATE_FORMAT)}
                  </Typography>
                </div>
                {release.name === currentVersion ? (
                  <Typography className={classes.isLatest} variant="subtitle1">
                    {translations.latest}
                  </Typography>
                ) : null}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

ReleaseNotesPage.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReleaseNotesPage);
