import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import TextBoxWithTooltip from '../textBoxWithTooltip';
import SurveyType from '../surveyType';
import { getItemById } from '../../../utility/helpers';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  basedOn: {
    marginRight: spacing(1),
    flexShrink: 0,
  },
  basedOnTitle: {
    width: 'auto',
    maxWidth: 650,
  },
  divider: {
    margin: spacing(0, 2),
  },
}));

const SurveySummaryDescription = ({
  className,
  translations,
  report,
  surveyTypes,
}) => {
  const classes = useStyles();
  const { survey } = report;
  const { title, type } = survey;
  const surveyType = getItemById(surveyTypes, type);

  return (
    <div className={classNames(classes.root, className)}>
      <Typography className={classes.basedOn} variant="body2">
        {translations.basedOn}
      </Typography>
      <TextBoxWithTooltip
        className={classes.basedOnTitle}
        variant="subtitle2"
        text={title}
      />
      <Typography className={classes.divider} variant="subtitle2">
        -
      </Typography>
      <SurveyType label={translations.type} type={surveyType} hasTooltip />.
    </div>
  );
};

SurveySummaryDescription.defaultProps = {
  className: undefined,
};

SurveySummaryDescription.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  surveyTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  report: PropTypes.object.isRequired,
};

export default SurveySummaryDescription;
