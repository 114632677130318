import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { PLACEMENTS } from 'constants/tags';
import PeoplePageContainer from '../../components/shared/peoplePageContainer';
import PeopleCoursesPage from '../../components/pages/peopleCoursesPage';
import {
  getCourseEnrolledUsers,
  clearCourseEnrolledUsers,
} from '../../store/modules/courseDetails';
import { userCoursesSelector } from '../../store/selectors/userCoursesSelector';
import { tagsSelector } from '../../store/selectors/tagsSelector';
import {
  getUserCourses,
  updateEnrolledUserStatus,
  clearUserCourses,
  removeCourseFromUserList,
} from '../../store/modules/userCourses';
import {
  clearAttributesWithQuestions,
  getAttributesWithQuestions,
} from '../../store/modules/surveys';
import {
  getTagsCategories,
  clearTagsCategories,
  getPageQuickFilters,
  clearPageQuickFilters,
} from '../../store/modules/tags';
import { getCourses } from '../../store/modules/courses';
import { coursesSelector } from '../../store/selectors/coursesSelector';
import { surveysSelector } from '../../store/selectors/surveysSelector';
import { useTranslations } from '../../utility/useTranslations';
import { APP_PAGES } from '../../constants/pages';

const mapStateToProps = state => ({
  user: state.person,
  ...userCoursesSelector(state),
  ...coursesSelector(state),
  allAttributes: [...(surveysSelector(state)?.attributesWithQuestions || [])],
  ...tagsSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getUserCourses: (userId, params) => getUserCourses(dispatch, userId, params),
  clearUserCourses: () => dispatch(clearUserCourses()),
  updateEnrolledUserStatus: params =>
    updateEnrolledUserStatus(dispatch, params),
  removeCourseFromUserList: courseId =>
    dispatch(removeCourseFromUserList(courseId)),
  getCourses: params => getCourses(dispatch, params),
  getCourseEnrolledUsers: (courseId, params) =>
    getCourseEnrolledUsers(dispatch, courseId, params),
  clearCourseEnrolledUsers: () => dispatch(clearCourseEnrolledUsers()),
  getAttributesWithQuestions: params =>
    getAttributesWithQuestions(dispatch, params),
  clearAttributesWithQuestions: () => dispatch(clearAttributesWithQuestions()),
  getTagsCategories: () => getTagsCategories(dispatch),
  getPageQuickFilters: () =>
    getPageQuickFilters(dispatch, PLACEMENTS.LEARNING.id),
  clearPageQuickFilters: () => dispatch(clearPageQuickFilters()),
  clearTagsCategories: () => dispatch(clearTagsCategories()),
});

const PeopleCoursesPageView = props => {
  const { id } = useParams();
  const userId = (!Number.isNaN(Number(id)) && +id) || -1;
  const translations = useTranslations(APP_PAGES.PEOPLE_COURSES);

  return (
    <PeoplePageContainer {...props} userId={userId} translations={translations}>
      <PeopleCoursesPage />
    </PeoplePageContainer>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PeopleCoursesPageView);
