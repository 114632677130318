import { createAction, handleActions } from 'redux-actions';
import http from '../../utility/http';
import { api_course, api_course_users } from '../../constants/apiRoutes';
import { COURSES_DEFAULT_PARAMS } from '../../constants/pages';

// ------------------------------------
// Constants
// ------------------------------------
const SET_COURSE = 'SET_COURSE';
const SET_ENROLLED_USERS = 'SET_ENROLLED_USERS';

const initialState = {
  course: {},
  enrolledUsers: {},
};

// ------------------------------------
// Actions
// ------------------------------------

export const setCourse = createAction(SET_COURSE);
const setEnrolledUsers = createAction(SET_ENROLLED_USERS);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_COURSE]: (state, { payload }) => {
    return { ...state, course: payload };
  },
  [SET_ENROLLED_USERS]: (state, { payload }) => {
    return { ...state, enrolledUsers: payload };
  },
};

// ------------------------------------
// API calls
// ------------------------------------

export const getCourse = (dispatch, courseId) => {
  return http.get(api_course(courseId)).then(({ data }) => {
    dispatch(setCourse(data));
  });
};

export const getCourseEnrolledUsers = (dispatch, courseId, params = {}) => {
  return http
    .get(api_course_users(courseId), {
      params: { ...COURSES_DEFAULT_PARAMS, ...params },
    })
    .then(({ data }) => {
      dispatch(setEnrolledUsers(data));

      return Promise.resolve({ data });
    });
};

export default handleActions(reducers, initialState);

// ------------------------------------
// "plain" action functions
// ------------------------------------

export const clearCourse = () => setCourse(initialState.course);
export const clearCourseEnrolledUsers = () =>
  setEnrolledUsers(initialState.enrolledUsers);
