import { createAction, handleActions } from 'redux-actions';
import http from '../../utility/http';
import {
  getPersonMeasuredAttributesParams,
  prepareMeasuredAttributes,
} from '../../utility/person';
import { parseDuplicateParameters } from '../../utility/uiUtils';
import { api_position, api_user_attributes } from '../../constants/apiRoutes';

// ------------------------------------
// Constants
// ------------------------------------
const initialState = {
  jobTitle: {},
  measuredAttributes: {
    core: [],
    niche: [],
    selfCore: [],
    selfNiche: [],
  },
};

const SET_PERSON_CAREER = 'SET_PERSON_CAREER';
const SET_PERSON_JOB_TITLE = 'SET_PERSON_JOB_TITLE';
const SET_PERSON_MEASURED_ATTRIBUTES = 'SET_PERSON_MEASURED_ATTRIBUTES';

// ------------------------------------
// Actions
// ------------------------------------

const setPersonCareer = createAction(SET_PERSON_CAREER);
const setPersonJobTitle = createAction(SET_PERSON_JOB_TITLE);
const setPersonMeasuredAttributes = createAction(
  SET_PERSON_MEASURED_ATTRIBUTES
);

// ------------------------------------
// Reducers
// ------------------------------------
const reducers = {
  [SET_PERSON_CAREER]: (state, { payload }) => {
    return { ...state, ...payload };
  },
  [SET_PERSON_JOB_TITLE]: (state, { payload }) => {
    return { ...state, jobTitle: payload };
  },
  [SET_PERSON_MEASURED_ATTRIBUTES]: (state, { payload }) => {
    const selfMeasured = prepareMeasuredAttributes(
      payload.selfMeasured,
      state.jobTitle?.attributes || []
    );
    const measuredByOthers = prepareMeasuredAttributes(
      payload.measuredByOthers,
      state.jobTitle?.attributes || []
    );
    return {
      ...state,
      measuredAttributes: {
        ...measuredByOthers,
        selfCore: selfMeasured.core,
        selfNiche: selfMeasured.niche,
      },
    };
  },
};

// ------------------------------------
// API calls
// ------------------------------------
export const getPersonJobTitle = (dispatch, jobTitleId) => {
  return http.get(api_position(jobTitleId)).then(({ data }) => {
    dispatch(setPersonJobTitle(data));
  });
};

export const getMeasuredAttributes = async (dispatch, userId) => {
  const [measuredByOthers, selfMeasured] = await Promise.all([
    http.get(api_user_attributes(userId), {
      params: {
        ...getPersonMeasuredAttributesParams(),
      },
      paramsSerializer: data => parseDuplicateParameters(data),
    }),
    http.get(api_user_attributes(userId), {
      params: {
        ...getPersonMeasuredAttributesParams(true),
      },
      paramsSerializer: data => parseDuplicateParameters(data),
    }),
  ]);

  dispatch(
    setPersonMeasuredAttributes({
      measuredByOthers: measuredByOthers.data,
      selfMeasured: selfMeasured.data,
    })
  );
};

export default handleActions(reducers, initialState);

// ------------------------------------
// "plain" action functions
// ------------------------------------

export const clearPersonCareer = () => setPersonCareer(initialState);
