import { connect } from 'react-redux';
import { useParams } from 'react-router';
import PeoplePageContainer from '../../components/shared/peoplePageContainer';
import PeopleProfilePage from '../../components/pages/peopleProfilePage';
import { tagsSelector } from '../../store/selectors/tagsSelector';
import { dialogSelector } from '../../store/selectors/dialogSelector';
import { jobTitlesSelector } from '../../store/selectors/jobTitlesSelector';
import { levelsAndTracksSelector } from '../../store/selectors/levelsAndTracksSelector';
import { updateCurrentUserInfo } from '../../store/modules/auth';
import {
  updateEmploymentInfo,
  clearPerson,
  getPerson,
} from '../../store/modules/person';
import {
  getTagsCategories,
  clearTagsCategories,
} from '../../store/modules/tags';
import { getJobTitles, clearJobTitles } from '../../store/modules/jobTitles';
import {
  getLevelsAndTracks,
  clearLevelsAndTracks,
} from '../../store/modules/levelsAndTracks';
import { useTranslations } from '../../utility/useTranslations';
import { APP_PAGES } from '../../constants/pages';

const mapStateToProps = state => ({
  ...jobTitlesSelector(state),
  ...tagsSelector(state),
  ...dialogSelector(state),
  ...levelsAndTracksSelector(state),
});

const mapDispatchToProps = dispatch => ({
  updateCurrentUserInfo: data => dispatch(updateCurrentUserInfo(data)),
  getJobTitles: () => getJobTitles(dispatch),
  clearJobTitles: () => dispatch(clearJobTitles()),
  getLevelsAndTracks: () => getLevelsAndTracks(dispatch),
  clearLevelsAndTracks: () => dispatch(clearLevelsAndTracks()),
  getTagsCategories: () => getTagsCategories(dispatch),
  clearTagsCategories: () => dispatch(clearTagsCategories()),
  clearPerson: () => dispatch(clearPerson()),
  updateEmploymentInfo: (id, data) => updateEmploymentInfo(dispatch, id, data),
  getPerson: id => getPerson(dispatch, id),
});

const PeopleProfilePageView = props => {
  const { id } = useParams();
  const userId = (!Number.isNaN(Number(id)) && +id) || -1;
  const translations = useTranslations(APP_PAGES.PEOPLE_INFO);

  return (
    <PeoplePageContainer {...props} userId={userId} translations={translations}>
      <PeopleProfilePage />
    </PeoplePageContainer>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PeopleProfilePageView);
