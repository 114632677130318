import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, Fade, makeStyles } from '@material-ui/core';
import { SwitchTransition } from 'react-transition-group';
import ListWithTooltip from '../../listWithTooltip';
import { ReactComponent as RespondentsIcon } from '../../../../assets/icons/people.svg';
import { ReactComponent as RespondentsIconActive } from '../../../../assets/icons/people-dark.svg';
import { isArray } from '../../../../utility/helpers';
import { calculatePercentage } from '../../../../utility/reportUtils';
import { ANSWER_GRID_VISIBLE_USERS_COUNT } from '../config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    boxSizing: 'border-box',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(4),
    minHeight: 90,
  },
  selected: {
    backgroundColor: primary.white,
    border: `1px solid ${primary.bluish4}`,
  },
  answer: {
    color: primary.bluish6,
    marginBottom: spacing(4),
    wordBreak: 'break-word',
  },
  answerSelected: {
    color: primary.bluish1,
  },
  result: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 20,
    marginTop: 'auto',
  },
  numberOfAnswers: {
    flexShrink: 0,
    marginTop: spacing(0.5),
    marginRight: spacing(2),
  },
  noResult: {
    color: primary.bluish6,
    marginTop: spacing(0.5),
  },
  icon: {
    flexShrink: 0,
    marginRight: spacing(1),
  },
  userMoreItems: {
    backgroundColor: primary.bluish9,
    fontSize: 16,
    lineHeight: '20px',
    padding: spacing(0, 2),
    maxWidth: 'fit-content',
  },
  transitionWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const Answer = ({
  neutralAnswerLabel,
  answer,
  result,
  isNeutralAnswer,
  totalQuestionResponses,
  isPercentageView,
}) => {
  const classes = useStyles();
  const hasResult = !!result;
  const isUserList = isArray(result);
  const percentage = useMemo(
    () => calculatePercentage(result?.length || result, totalQuestionResponses),
    [result, totalQuestionResponses]
  );

  const renderResult = () => {
    return (
      <div className={classes.result}>
        {hasResult ? (
          <RespondentsIconActive className={classes.icon} />
        ) : (
          <RespondentsIcon className={classes.icon} />
        )}
        {hasResult && (
          <>
            <SwitchTransition>
              <Fade key={isPercentageView} addEndListener={null}>
                <Typography
                  className={classes.numberOfAnswers}
                  variant={hasResult ? 'subtitle2' : 'body2'}
                >
                  {isPercentageView
                    ? `${percentage}%`
                    : result?.length || result}
                </Typography>
              </Fade>
            </SwitchTransition>
            <SwitchTransition>
              <Fade key={!isPercentageView} addEndListener={null}>
                {!isPercentageView && isUserList ? (
                  <ListWithTooltip
                    moreItemsClass={classes.userMoreItems}
                    items={result}
                    asyncListProps={{
                      position: 'bottom',
                      shouldDisablePortal: false,
                    }}
                    userAvatarProps={{
                      extraSmall: true,
                    }}
                    visibleItemsCount={ANSWER_GRID_VISIBLE_USERS_COUNT}
                    isUserList
                  />
                ) : (
                  <div />
                )}
              </Fade>
            </SwitchTransition>
          </>
        )}
        {!hasResult && (
          <SwitchTransition>
            <Fade key={isPercentageView} addEndListener={null}>
              <Typography className={classes.noResult} variant="body2">
                {`0${isPercentageView ? '%' : ''}`}
              </Typography>
            </Fade>
          </SwitchTransition>
        )}
      </div>
    );
  };

  return (
    <div
      className={classNames(classes.root, { [classes.selected]: hasResult })}
    >
      <Typography
        className={classNames(classes.answer, {
          [classes.answerSelected]: hasResult,
        })}
        variant="subtitle2"
      >
        {isNeutralAnswer ? neutralAnswerLabel : answer.text}
      </Typography>
      {renderResult()}
    </div>
  );
};

Answer.defaultProps = {
  result: null,
};

Answer.propTypes = {
  neutralAnswerLabel: PropTypes.string.isRequired,
  answer: PropTypes.shape({}).isRequired,
  isNeutralAnswer: PropTypes.bool.isRequired,
  totalQuestionResponses: PropTypes.number.isRequired,
  isPercentageView: PropTypes.bool.isRequired,
  result: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
};

export default memo(Answer);
