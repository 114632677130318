import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import Impression from '../../../../impressions/impression';
import { formatDate } from '../../../../../../utility/dateUtils';
import { DATE_FORMAT } from '../../../../../../constants/oneOnOne';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  impression: {
    marginRight: spacing(2),
  },
  meetingTime: {
    color: primary.blue1,
  },
  notAccessible: {
    color: primary.bluish5,
  },
}));

const Meeting = ({ meeting }) => {
  const classes = useStyles();
  const isDisabled = !meeting.is_accessible;

  return (
    <div className={classes.root}>
      <Impression
        className={classes.impression}
        value={meeting.meeting_impression}
        isDisabled={isDisabled}
        isSmall
      />
      <Typography
        variant="subtitle2"
        className={classNames(classes.meetingTime, {
          [classes.notAccessible]: isDisabled,
        })}
      >
        {formatDate(meeting.meeting_time, DATE_FORMAT)}
      </Typography>
    </div>
  );
};

Meeting.propTypes = {
  meeting: PropTypes.object.isRequired,
};

export default memo(Meeting);
