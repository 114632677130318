import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import UserAvatar from '../userAvatar';
import SubmittableInput from '../submittableInput';
import { validateField } from '../../../utility/validation';
import { asyncDebounce, trimString } from '../../../utility/helpers';
import { showSuccessMessage } from '../../../utility/uiUtils';
import {
  COMMENT_FIELD,
  INITIAL_COMMENT_STATE,
  DELAY_VALIDATION,
} from './config';

const styles = ({ spacing }) => ({
  wrap: {
    display: 'flex',
    alignItems: 'center',
    margin: spacing(8, 0, 10, 0),
  },
  comment: {
    display: 'flex',
    flexGrow: 1,
    marginLeft: spacing(4),
    paddingTop: 2,
  },
});

class AddComment extends PureComponent {
  constructor(props) {
    super(props);
    this.validateComment = asyncDebounce(
      this.validateComment,
      DELAY_VALIDATION,
      true
    );
  }

  state = {
    ...INITIAL_COMMENT_STATE,
    ...(this.props.isEdit ? { comment: this.props.comment.comment } : {}),
    isSendDisabled: true,
  };

  resetComment = () => this.setState(INITIAL_COMMENT_STATE);

  validateComment = value => {
    return validateField(COMMENT_FIELD, value).then(errorType => {
      return Promise.resolve(this.setState({ error: errorType }));
    });
  };

  handleChangeComment = e => {
    const { value } = e.target;
    const { comment, closeEditComment } = this.props;

    const isCommentEdited = value.trim() !== comment?.comment?.trim();

    if (!this.props.comment?.id) {
      closeEditComment();
    }
    this.setState({ comment: value, isSendDisabled: !isCommentEdited }, () =>
      this.validateComment(value)
    );
  };

  handleSend = () => {
    const { translations, onSendComment, isEdit } = this.props;
    const { comment } = this.state;

    const sanitizedComment = trimString(comment);

    return onSendComment(sanitizedComment, isEdit).then(() => {
      const message = isEdit ? translations.update : translations.success;
      showSuccessMessage(message);
      this.resetComment();
    });
  };

  handleSave = async () => {
    const newComment = trimString(this.state.comment);

    if (!newComment) return;
    await this.validateComment(this.state.comment);

    const { error } = this.state;

    if (!error) {
      this.handleSend();
    }
  };

  render() {
    const { classes, translations, currentUser, isEdit, closeEditComment } =
      this.props;
    const { comment, error, isSendDisabled } = this.state;

    return (
      <div className={classes.wrap}>
        <UserAvatar user={currentUser} medium />
        <div className={classes.comment}>
          <SubmittableInput
            placeholder={translations.placeholder}
            value={comment}
            hasError={!!error}
            errorMessage={translations.errorMessages[error]}
            onChange={this.handleChangeComment}
            onSubmit={this.handleSave}
            onCancel={closeEditComment}
            isEdit={isEdit}
            isSendDisabled={isSendDisabled}
          />
        </div>
      </div>
    );
  }
}

AddComment.defaultProps = {
  isEdit: false,
  comment: {},
  closeEditComment: () => {},
};

AddComment.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  translations: PropTypes.shape({}).isRequired,
  currentUser: PropTypes.shape({}).isRequired,
  isEdit: PropTypes.bool,
  comment: PropTypes.object,
  closeEditComment: PropTypes.func,
};

export default withStyles(styles)(AddComment);
