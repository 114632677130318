import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import TextBoxWithTooltip from '../textBoxWithTooltip';
import UserAvatar from '../userAvatar';
import { getPersonFullName } from '../../../utility/uiUtils';
import { SURVEY_TYPES } from '../../../constants/survey';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  spacingRight: {
    marginRight: spacing(1),
  },
  spacingLeft: {
    marginLeft: spacing(1),
  },
  userFullName: {
    width: 'auto',
    maxWidth: 300,
  },
  avatar: {
    maxWidth: 300,
  },
  userLabel: {
    marginLeft: 0,
    width: '100%',
  },
}));

const ReportDescription = ({
  className,
  translations,
  surveyType,
  createdFor,
  reviewers,
  isUserClickable,
  onGoToPersonProfile,
}) => {
  const classes = useStyles();
  const { SELF, DIRECT, PEER, TEAM } = SURVEY_TYPES;
  const [reviewedUser] = createdFor;
  const [reviewer] = reviewers;
  const isReviewerClickable = isUserClickable(reviewer);
  const isReviewedUserClickable = isUserClickable(reviewedUser);

  const renderDescription = () => {
    switch (surveyType) {
      case SELF:
        return (
          <Typography variant="body2">{translations.selfAssessment}</Typography>
        );
      case PEER:
        return (
          <>
            <Typography className={classes.spacingRight} variant="body2">
              {`${translations.takeALook} ${translations.usersAbove} ${translations.evaluated}`}
            </Typography>
            {reviewedUser && (
              <UserAvatar
                className={classes.avatar}
                variant="subtitle2"
                user={reviewedUser}
                clickableCaption={isReviewedUserClickable}
                onClickHandler={() => onGoToPersonProfile(reviewedUser.id)}
                small
                caption
              />
            )}
          </>
        );
      case DIRECT:
        return (
          <>
            <Typography className={classes.spacingRight} variant="body2">
              {translations.takeALook}
            </Typography>
            <TextBoxWithTooltip
              className={classes.userFullName}
              variant="subtitle2"
              text={getPersonFullName(reviewer)}
            />
            <Typography
              className={classNames(classes.spacingLeft, classes.spacingRight)}
              variant="body2"
            >
              {translations.evaluated}
            </Typography>
            {reviewedUser && (
              <UserAvatar
                className={classes.avatar}
                variant="subtitle2"
                user={reviewedUser}
                clickableCaption={isReviewedUserClickable}
                onClickHandler={() => onGoToPersonProfile(reviewedUser.id)}
                small
                caption
              />
            )}
          </>
        );
      case TEAM:
        return (
          <>
            <Typography className={classes.spacingRight} variant="body2">
              {translations.takeALook}
            </Typography>
            {reviewer && (
              <UserAvatar
                className={classes.avatar}
                variant="subtitle2"
                user={reviewer}
                clickableCaption={isReviewerClickable}
                onClickHandler={() => onGoToPersonProfile(reviewer.id)}
                small
                caption
              />
            )}
            <Typography
              className={classNames(classes.spacingLeft, classes.spacingRight)}
              variant="body2"
            >
              {`${translations.evaluated} ${translations.usersAbove}.`}
            </Typography>
          </>
        );

      default:
        return (
          <Typography variant="body2">{translations.companyPulse}</Typography>
        );
    }
  };

  return (
    <div className={classNames(classes.root, className)}>
      {renderDescription()}
    </div>
  );
};

ReportDescription.defaultProps = {
  className: undefined,
};

ReportDescription.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  surveyType: PropTypes.string.isRequired,
  createdFor: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  reviewers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isUserClickable: PropTypes.func.isRequired,
  onGoToPersonProfile: PropTypes.func.isRequired,
};

export default memo(ReportDescription);
