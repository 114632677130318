import { ReactComponent as TypeIcon } from '../assets/icons/survey-type-icon.svg';
import { ReactComponent as CreatorIcon } from '../assets/icons/creator-icon.svg';
import { ReactComponent as DeleteIcon } from '../assets/icons/delete-outlined.svg';
import { ReactComponent as ShareIcon } from '../assets/icons/share.svg';
import {
  getItemById,
  canSeeEmployeeProfile,
  checkUserRole,
  getObjectToNumberArray,
  isObjectEmpty,
} from './helpers';
import { getUserProfileLink, getFilterableUsers } from './uiUtils';
import { transformSurveyData, transformReviewData } from './reportUtils';
import { formatDate } from './dateUtils';
import { PARAMS } from '../constants/pages';
import { ROLES } from '../constants/rolesAndPermissionList';

export const getInitialShareReportData = sharedWith => ({
  share_to: getObjectToNumberArray(sharedWith),
});

export const getSurveySummaryActionItems = (
  labels,
  report,
  onShare,
  onDelete
) => [
  {
    type: 'share',
    label: labels.share,
    action: onShare(report),
    shouldItemRender: () => true,
    icon: ShareIcon,
  },
  {
    type: 'delete',
    label: labels.delete,
    action: () => onDelete(report),
    shouldItemRender: () => true,
    icon: DeleteIcon,
  },
];

export const getSurveySummaryPageFilters = (
  translations,
  surveyTypes,
  allEmployees
) => {
  const { SURVEY_SUMMARY_TYPE, CREATOR } = PARAMS;

  return [
    {
      id: SURVEY_SUMMARY_TYPE,
      name: translations.filters.labels.type,
      icon: TypeIcon,
      isSingleSelect: true,
      items: [{ id: null, name: translations.all }, ...surveyTypes],
    },
    {
      id: CREATOR,
      name: translations.filters.labels[CREATOR],
      icon: CreatorIcon,
      items: getFilterableUsers(allEmployees),
    },
  ];
};

export const getSurveySummaryHeaderActions = labels => [
  {
    id: 'actions-column',
    title: labels.actions,
    rowKey: 'actions',
    align: 'right',
    rowCellClass: 'actions-cell',
    isHeaderAction: true,
    minWidth: 60,
    maxWidth: 80,
  },
];

export const getSurveySummaryHeaders = labels => [
  {
    id: 1,
    title: labels.type,
    rowKey: 'type',
    sortAs: 'survey__type',
    minWidth: 77,
    maxWidth: 77,
  },
  {
    id: 2,
    title: labels.title,
    rowKey: 'title',
    sortAs: 'title',
    isHighlighted: true,
    rowCellClass: 'title-cell',
    minWidth: 200,
    maxWidth: '1.5fr',
    hasConditionalTooltip: true,
  },
  {
    id: 3,
    title: labels.createdBy,
    rowKey: 'createdBy',
    rowCellClass: 'created-by-cell',
    sortAs: 'creator',
    maxWidth: '1fr',
  },
  {
    id: 4,
    title: labels.createdAt,
    rowKey: 'createdAt',
    sortAs: 'created_at',
    rowCellClass: 'created-at-cell',
    minWidth: 90,
    maxWidth: '1fr',
  },
  {
    id: 5,
    title: labels.sharedWith,
    rowKey: 'sharedWith',
    rowCellClass: 'shared-with-cell',
    isSortingDisabled: true,
    minWidth: 150,
    maxWidth: '1fr',
  },
];

export const prepareSummaryResults = (
  summaryResults,
  currentUser,
  canSeeHimself,
  surveyTypes,
  renderSurveyType,
  renderUser,
  renderSharedWith,
  renderActions
) => {
  return summaryResults.map(sResult => {
    const { id, title, creator, survey, created_at } = sResult;
    const isAdmin = checkUserRole(currentUser.role, ROLES.ADMIN);
    const detailsUrl = `/reports/survey-summary/${id}/details/`;
    const isCurrentUserCreator = currentUser.id === creator?.id;
    const surveyType = getItemById(surveyTypes, survey?.type);
    const canAccessCreatorProfile = canSeeEmployeeProfile(
      currentUser,
      creator?.id,
      canSeeHimself
    );

    return {
      id,
      title,
      type: survey ? renderSurveyType(surveyType) : '-',
      createdBy: creator ? renderUser(creator, canAccessCreatorProfile) : '-',
      createdAt: formatDate(created_at),
      sharedWith:
        isAdmin || isCurrentUserCreator ? renderSharedWith(sResult) : '-',
      cellLinks: {
        ...(creator && canAccessCreatorProfile
          ? {
              createdBy: {
                to: getUserProfileLink(creator.id, canAccessCreatorProfile),
              },
            }
          : {
              createdBy: {
                to: detailsUrl,
              },
            }),
        title: {
          to: detailsUrl,
        },
        createdAt: {
          to: detailsUrl,
        },
        sharedWith: {
          to: detailsUrl,
        },
        type: {
          to: detailsUrl,
        },
      },
      ...(isAdmin || isCurrentUserCreator
        ? {
            actions: renderActions(sResult),
          }
        : {}),
    };
  });
};

export const transformSurveySummaryData = (report, shouldShowOriginalData) => {
  if (isObjectEmpty(report)) return {};

  const survey = transformSurveyData(
    shouldShowOriginalData ? report.raw_data.survey : report.view_data.survey
  );
  const isAnonymizedReport = !!report?.anon_reviewers;

  return {
    ...report,
    createdAt: report.created_at,
    isAnonymizedReport,
    description: report.description || '',
    survey,
    createdFor:
      isAnonymizedReport && shouldShowOriginalData
        ? report.raw_data.subjects
        : report.view_data.subjects,
    reviewers:
      isAnonymizedReport && shouldShowOriginalData
        ? report.raw_data.reviewers
        : report.view_data.reviewers,
    reviews:
      isAnonymizedReport && shouldShowOriginalData
        ? report.raw_data.attributes.map(review =>
            transformReviewData(review, survey.type)
          )
        : report.view_data.attributes.map(review =>
            transformReviewData(review, survey.type)
          ),
  };
};
