import PropTypes from 'prop-types';
import { isArray, isArrayEmpty } from '../../../utility/helpers';

const Switch = ({ condition, children }) => {
  if (!condition || !children) {
    return null;
  }

  const arrayOfChildren = isArray(children) ? children : [children];
  const cases = arrayOfChildren.filter(child => child.props.when === condition);

  return !isArrayEmpty(cases) ? <>{cases}</> : null;
};

const Case = ({ children }) => {
  return <>{children}</>;
};

Switch.defaultProps = {
  condition: '',
  children: null,
};

Switch.propTypes = {
  condition: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
};

Case.defaultProps = {
  children: null,
};

Case.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
};

export { Switch, Case };
