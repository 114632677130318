import { memo } from 'react';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import ColorBox from '../../colorBox';
import UserAvatar from '../../userAvatar';
import { ellipsis } from '../../../../constants/helperCssRules';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 'calc(100% - 6px)',
    lineHeight: '22px',
  },
  valueName: {
    ...ellipsis(),
  },
  highlightedValue: {
    fontFamily: 'Proximanova-Bold',
  },
  colorBox: {
    marginRight: spacing(2),
  },
  inputDisabled: {
    color: primary.bluish5,
    '&.MuiInputBase-input.Mui-disabled': {
      color: primary.bluish5,
    },
  },
  userLabel: {
    fontSize: 16,
    lineHeight: '22px',
  },
  userLabelDisabled: {
    color: primary.bluish5,
  },
}));

const SingleValue = props => {
  const classes = useStyles();
  const { isValueHighlighted, isDisabled, hasColorBox, isUser } =
    props.selectProps;

  return (
    <Typography
      className={classNames(classes.root, {
        [classes.highlightedValue]: isValueHighlighted,
        [classes.inputDisabled]: isDisabled,
      })}
      component="div"
      {...props.innerProps}
    >
      {hasColorBox && props.data.color && (
        <ColorBox
          className={classes.colorBox}
          bgColor={props.data.color}
          isSmall
        />
      )}
      {isUser && !props.data.shouldRemoveUserAvatar ? (
        <UserAvatar
          labelClass={classNames(classes.userLabel, {
            [classes.userLabelDisabled]: isDisabled,
          })}
          user={props.data}
          caption
          small
        />
      ) : (
        <span className={classes.valueName}>{props.children}</span>
      )}
    </Typography>
  );
};

export default memo(SingleValue);
