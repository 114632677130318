import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import AsyncListTooltip from '../asyncListTooltip';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  badge: {
    backgroundColor: primary.bluish8,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    padding: spacing(0, 3),
    borderRadius: 40,
    height: 28,
  },
  icon: {
    marginLeft: spacing(1),
  },
}));

const AsyncListBadge = ({
  className,
  badgeClass,
  badgeIconClass,
  tooltipTitle,
  count,
  items,
  icon: Icon,
  renderItem,
  checkIsClickable,
  shouldDisablePortal,
  onItemClick,
  getItems,
}) => {
  const classes = useStyles();
  const isEmpty = count === 0;

  return (
    <div className={className}>
      <AsyncListTooltip
        title={tooltipTitle}
        labelData={items}
        renderLabelItem={renderItem}
        shouldDisablePortal={shouldDisablePortal}
        isItemClickable={checkIsClickable}
        isTooltipDisabled={isEmpty}
        isDisabled={isEmpty}
        onItemClickHandler={onItemClick}
        getLabelData={getItems}
      >
        <div className={classNames(classes.badge, badgeClass)}>
          <Typography variant="subtitle1">{count}</Typography>
          <Icon className={classNames(classes.icon, badgeIconClass)} />
        </div>
      </AsyncListTooltip>
    </div>
  );
};

AsyncListBadge.defaultProps = {
  className: undefined,
  badgeClass: undefined,
  badgeIconClass: undefined,
  count: 0,
  shouldDisablePortal: true,
  items: [],
  renderItem: () => {},
  checkIsClickable: () => true,
  onItemClick: () => {},
  getItems: () => {},
};

AsyncListBadge.propTypes = {
  className: PropTypes.string,
  badgeClass: PropTypes.string,
  badgeIconClass: PropTypes.string,
  count: PropTypes.number,
  icon: PropTypes.object.isRequired,
  checkIsClickable: PropTypes.func,
  tooltipTitle: PropTypes.string.isRequired,
  shouldDisablePortal: PropTypes.bool,
  renderItem: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.object),
  getItems: PropTypes.func,
  onItemClick: PropTypes.func,
};

export default AsyncListBadge;
