import {
  isEmpty,
  forbiddenCharacters,
  validateLength,
} from '../../../../utility/validation';

export const REPLY_FIELD = {
  name: 'freeText',
  rows: 5,
  isMultiline: true,
  required: true,
  validators: [
    {
      type: 'required',
      validator: isEmpty,
    },
    {
      type: 'forbiddenCharacters',
      validator: forbiddenCharacters,
    },
    {
      type: 'maxLength',
      validator: fieldValue => validateLength(fieldValue, 0, 5000),
    },
  ],
};
