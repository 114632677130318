import { memo, useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import ConditionalTooltip from '../conditionalTooltip';
import { isEllipsisActive } from '../../../utility/helpers';
import { ellipsis } from '../../../constants/helperCssRules';

const useStyles = makeStyles(() => ({
  main: {
    width: '100%',
    ...ellipsis(),
  },
  mainVertical: {
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
  tooltip: {
    cursor: 'default',
    overflow: 'hidden',
    maxWidth: '100%',
  },
  tooltipClickable: {
    cursor: 'pointer',
  },
  tooltipText: {
    textAlign: 'start',
  },
}));

const TextBoxWithTooltip = ({
  className,
  text,
  variant,
  itemNumber,
  isVerticalEllipsis,
  isClickable,
  onClickHandler,
}) => {
  const classes = useStyles();
  const textRef = useRef(null);
  const [hasTooltip, setHasTooltip] = useState(false);

  const handleUpdateTooltip = useCallback(() => {
    setHasTooltip(isEllipsisActive(textRef.current, isVerticalEllipsis));
  }, [isVerticalEllipsis]);

  useEffect(() => {
    handleUpdateTooltip();
  }, [text, itemNumber, handleUpdateTooltip]);

  return (
    <ConditionalTooltip
      className={classNames(classes.tooltip, {
        [classes.tooltipClickable]: isClickable,
      })}
      customLabelClass={classes.tooltipText}
      addTooltip={hasTooltip}
      message={text}
    >
      <Typography
        ref={textRef}
        className={classNames(
          classes.main,
          { [classes.mainVertical]: isVerticalEllipsis },
          className
        )}
        variant={variant}
        onClick={onClickHandler}
      >
        {text}
      </Typography>
    </ConditionalTooltip>
  );
};

TextBoxWithTooltip.defaultProps = {
  className: null,
  variant: undefined,
  isVerticalEllipsis: false,
  itemNumber: 0,
  isClickable: false,
  onClickHandler: () => {},
};

TextBoxWithTooltip.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  variant: PropTypes.string,
  isClickable: PropTypes.bool,
  isVerticalEllipsis: PropTypes.bool,
  itemNumber: PropTypes.number,
  onClickHandler: PropTypes.func,
};

export default memo(TextBoxWithTooltip);
