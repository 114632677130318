import { connect } from 'react-redux';

import PrivateNotesPage from '../../components/pages/privateNotesPage';
import PageContainer from '../../components/shared/pageContainer';
import {
  clearPrivateNotes,
  getPrivateNotes,
} from '../../store/modules/privateNotes';
import { privateNotesSelector } from '../../store/selectors/privateNotesSelector';
import { tagsSelector } from '../../store/selectors/tagsSelector';
import { deleteNote, editNote } from '../../store/modules/notes';
import {
  addMultipleTags,
  getTagsCategories,
  clearTagsCategories,
} from '../../store/modules/tags';
import { useTranslations } from '../../utility/useTranslations';
import { APP_PAGES } from '../../constants/pages';

const mapStateToProps = state => ({
  ...privateNotesSelector(state),
  ...tagsSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getPrivateNotes: (isLoadMore, data) =>
    getPrivateNotes(dispatch, data, isLoadMore),
  clearPrivateNotes: () => dispatch(clearPrivateNotes()),
  deleteNote: noteId => deleteNote(dispatch, noteId),
  editNote: note => editNote(dispatch, note),
  addMultipleTags: data => dispatch(addMultipleTags(data)),
  getTagsCategories: () => getTagsCategories(dispatch),
  clearTagsCategories: () => dispatch(clearTagsCategories()),
});

const PrivateNotesView = props => {
  const translations = useTranslations(APP_PAGES.PRIVATE_NOTES);
  return (
    <PageContainer {...props} translations={translations}>
      <PrivateNotesPage />
    </PageContainer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateNotesView);
