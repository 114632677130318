import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Tooltip from '../tooltip';
import { isObjectEmpty } from '../../../utility/helpers';
import { ellipsis } from '../../../constants/helperCssRules';

const styles = ({ palette: { primary }, spacing }) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',

    '& textarea': {
      boxSizing: 'border-box',
      height: '100%',
      paddingRight: 11,
    },
  },
  rootMultiline: {
    paddingRight: 0,
  },
  input: {
    width: 'auto',
    flexGrow: 1,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    wordBreak: 'break-word',
  },
  labelExplanation: {
    margin: spacing(-1, -1, 1, 0),
    color: primary.bluish4,
    ...ellipsis(),
  },
  labelIcon: {
    width: 13,
    height: 13,
    marginLeft: spacing(1.5),
    cursor: 'pointer',
  },
  labelContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  adornmentIcon: {
    cursor: 'pointer',
  },
  hasIcon: {
    paddingLeft: 0,
  },
});

const ADORNMENT_START = 'start';

class InputField extends PureComponent {
  renderAdornment = () => {
    const { classes, adornmentIcon: AdornmentIcon } = this.props;
    return (
      <InputAdornment
        className={classes.adornmentIcon}
        position={ADORNMENT_START}
      >
        <AdornmentIcon />
      </InputAdornment>
    );
  };

  renderInput = props => {
    const {
      classes,
      large,
      errorMessage,
      customErrorClass,
      labelHelp,
      customLabelClass,
      refs,
      customRootClass,
      customFocusClass,
      onInputBlur,
      onInputFocus,
      customInputClass,
      customDisabledClass,
      isIconOnStart,
      adornmentIcon,
      labelExplanation,
      ...rest
    } = props;

    return (
      <Input
        classes={{
          root: classNames(
            classes.root,
            { [classes.rootMultiline]: this.props.multiline },
            customRootClass
          ),
          input: classNames(
            classes.input,
            {
              [classes.hasIcon]: isIconOnStart && adornmentIcon,
            },
            customInputClass
          ),
          focused: customFocusClass,
          disabled: customDisabledClass,
        }}
        inputRef={refs}
        startAdornment={
          isIconOnStart && adornmentIcon ? this.renderAdornment() : null
        }
        onBlur={e => onInputBlur(e.target.value)}
        onFocus={e => onInputFocus(e.target.value)}
        {...rest}
      />
    );
  };

  render() {
    const {
      classes,
      label,
      labelExplanation,
      error,
      errorMessage,
      fullWidth,
      customErrorClass,
      labelHelp,
      customLabelClass,
    } = this.props;

    return (
      <FormControl fullWidth={fullWidth}>
        {label && (
          <div className={classes.labelContainer}>
            <FormHelperText
              className={classNames(classes.label, customLabelClass)}
            >
              <span>{label}</span>
              {!isObjectEmpty(labelHelp) && (
                <Tooltip
                  customIconClass={classes.labelIcon}
                  text={labelHelp.tooltipText}
                />
              )}
            </FormHelperText>
          </div>
        )}
        {labelExplanation ? (
          <Typography variant="body2" className={classes.labelExplanation}>
            {labelExplanation}
          </Typography>
        ) : null}
        {this.renderInput(this.props)}
        {error && errorMessage && (
          <FormHelperText className={customErrorClass} error={error}>
            {errorMessage}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

InputField.defaultProps = {
  id: undefined,
  label: '',
  labelExplanation: '',
  disabled: false,
  error: false,
  errorMessage: '',
  fullWidth: false,
  customErrorClass: '',
  large: false,
  labelHelp: {},
  customLabelClass: '',
  customRootClass: '',
  customFocusClass: '',
  customDisabledClass: '',
  onInputBlur: () => {},
  onInputFocus: () => {},
  customInputClass: '',
  isIconOnStart: false,
  adornmentIcon: null,
};

InputField.propTypes = {
  id: PropTypes.string,
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  labelExplanation: PropTypes.string,
  customFocusClass: PropTypes.string,
  customDisabledClass: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  fullWidth: PropTypes.bool,
  customErrorClass: PropTypes.string,
  large: PropTypes.bool,
  customLabelClass: PropTypes.string,
  labelHelp: PropTypes.object,
  adornmentIcon: PropTypes.object,
  customRootClass: PropTypes.string,
  onInputBlur: PropTypes.func,
  onInputFocus: PropTypes.func,
  customInputClass: PropTypes.string,
  isIconOnStart: PropTypes.bool,
};

export default withStyles(styles)(InputField);
