import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import parse from 'html-react-parser';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ellipsis } from '../../../constants/helperCssRules';

const styles = ({ palette: { primary }, spacing }) => ({
  main: {
    wordBreak: 'break-word',
    '& p': {
      margin: 0,
      padding: 0,
    },
    '& strong': {
      fontFamily: 'ProximaNova-Bold',
      fontWeight: 'normal',
      fontStyle: 'normal',
      '& em': {
        fontFamily: 'ProximaNova-Bold',
        fontStyle: 'italic',
      },
    },
    '& em': {
      fontFamily: 'ProximaNova-RegularIt',
      fontWeight: 'normal',
      fontStyle: 'normal',
    },
    '& ul': {
      margin: 0,
      padding: 0,
      paddingLeft: spacing(4),
      '& li': {
        marginBottom: spacing(1.5),
      },
    },
    '& a': {
      color: primary.main,
    },
  },
  ellipsis: {
    ...ellipsis(),
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    '& > *': {
      margin: 0,
    },
    whiteSpace: 'normal',
  },
});

class RichTextPreview extends PureComponent {
  render() {
    const { className, classes, text, isEllipsis } = this.props;

    return (
      <Typography
        className={classNames(
          classes.main,
          { [classes.ellipsis]: isEllipsis },
          className
        )}
        variant="body2"
        component="div"
      >
        {parse(text)}
      </Typography>
    );
  }
}

RichTextPreview.defaultProps = {
  className: null,
  text: '',
  isEllipsis: false,
};

RichTextPreview.propTypes = {
  className: PropTypes.string,
  isEllipsis: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  text: PropTypes.string,
};

export default withStyles(styles)(RichTextPreview);
