import { isObjectEmpty } from '../../../utility/helpers';

export const AUTO_CHANGE_QUESTION_DELAY = 500;

export const getSurveySteps = review => {
  if (isObjectEmpty(review)) {
    return [];
  }

  return [
    ...review.questions.map(question => ({
      id: question.id,
    })),
    ...(review.hasAdditionalFeedback
      ? [
          {
            id: 'survey_comments',
          },
        ]
      : []),
  ];
};

export const setPageHeight = () => {
  document.getElementById(
    'fill-survey-page'
  ).style.height = `${window.innerHeight}px`;
};
