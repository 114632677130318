import { connect } from 'react-redux';
import { useParams } from 'react-router';
import ActionPlanDetailsPage from '../../components/pages/actionPlanDetailsPage';
import PageContainer from '../../components/shared/pageContainer';
import { actionPlanDetailsSelector } from '../../store/selectors/actionPlanDetailsSelector';
import { tagsSelector } from '../../store/selectors/tagsSelector';
import { coursesSelector } from '../../store/selectors/coursesSelector';
import { peopleSelector } from '../../store/selectors/peopleSelector';
import {
  getActionPlan,
  clearActionPlan,
  getActionPlanComments,
  changeActionPlanTaskStatus,
  changeTaskStatus,
  clearActionPlanComments,
} from '../../store/modules/actionPlanDetails';
import {
  getCourseEnrolledUsers,
  clearCourseEnrolledUsers,
} from '../../store/modules/courseDetails';
import { getCourses, clearCourses } from '../../store/modules/courses';
import { useTranslations } from '../../utility/useTranslations';
import { APP_PAGES } from '../../constants/pages';
import {
  getTagsCategories,
  clearTagsCategories,
  addMultipleTags,
} from '../../store/modules/tags';
import { getAllUsers, clearAllUsers } from '../../store/modules/people';

const mapStateToProps = state => ({
  ...actionPlanDetailsSelector(state),
  ...coursesSelector(state),
  allUsers: [...peopleSelector(state).allUsers],
  ...tagsSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getActionPlan: actionPlanId => getActionPlan(dispatch, actionPlanId),
  getActionPlanComments: (actionPlanId, params) =>
    getActionPlanComments(dispatch, actionPlanId, params),
  clearActionPlan: () => dispatch(clearActionPlan()),
  clearActionPlanComments: () => dispatch(clearActionPlanComments()),
  addMultipleTags: data => dispatch(addMultipleTags(data)),
  getTagsCategories: () => getTagsCategories(dispatch),
  clearTagsCategories: () => dispatch(clearTagsCategories()),
  getAllUsers: () => getAllUsers(dispatch),
  clearAllUsers: () => dispatch(clearAllUsers()),
  changeActionPlanTaskStatus: (actionPlanId, taskId) =>
    changeActionPlanTaskStatus(dispatch, actionPlanId, taskId),
  changeTaskStatus: taskId => changeTaskStatus(dispatch, taskId),
  getCourses: params => getCourses(dispatch, params),
  clearCourses: () => dispatch(clearCourses()),
  getCourseEnrolledUsers: (courseId, params) =>
    getCourseEnrolledUsers(dispatch, courseId, params),
  clearCourseEnrolledUsers: () => dispatch(clearCourseEnrolledUsers()),
});

const ActionPlanDetailsPageView = props => {
  const translations = useTranslations(APP_PAGES.ACTION_PLAN_DETAILS);
  const { actionPlanId } = useParams();

  return (
    <PageContainer {...props} translations={translations}>
      <ActionPlanDetailsPage matchedActionPlanId={actionPlanId} />
    </PageContainer>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActionPlanDetailsPageView);
