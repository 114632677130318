import PropTypes from 'prop-types';
import { LinearProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette: { primary } }) => ({
  root: {
    position: 'fixed',
    width: '100%',
    height: '100vh',
    background: 'transparent',
    zIndex: 10000,
  },
  loader: {
    backgroundColor: 'transparent',
    position: 'absolute',
    width: '100%',
    height: '3px',
    zIndex: 10000,
    '& div': {
      backgroundColor: `${primary.blue2} !important`,
    },
  },
}));

const Loader = ({ loading }) => {
  const classes = useStyles();

  return (
    loading > 0 && (
      <div className={classes.root}>
        <LinearProgress className={classes.loader} />
      </div>
    )
  );
};

Loader.propTypes = {
  loading: PropTypes.number.isRequired,
};

export default Loader;
