import { memo } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    minHeight: 'auto',
    marginRight: spacing(1.5),
  },
}));

const ValueContainer = props => {
  const classes = useStyles();

  return <div className={classes.root}>{props.children}</div>;
};

export default memo(ValueContainer);
