import { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import TextBoxWithTooltip from '../textBoxWithTooltip';
import StatusIndicator from '../statusIndicator';
import AsyncListBadge from '../asyncListBadge';
import Tag from '../tag';
import { ReactComponent as TagsIcon } from '../../../assets/icons/tag-dark.svg';
import { isArrayEmpty } from '../../../utility/helpers';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    backgroundColor: primary.white,
    boxSizing: 'border-box',
    borderRadius: 16,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(4),
    width: 270,
    minHeight: 170,
    userSelect: 'none',
  },
  titleWrapper: {
    display: 'flex',
  },
  verticalEllipsis: {
    lineHeight: '24px',
    cursor: 'pointer',
    '-webkit-line-clamp': 2,
  },
  description: {
    marginTop: spacing(1),
  },
  coverWrapper: {
    backgroundColor: primary.bluish5,
    borderRadius: 8,
    flexShrink: 0,
    marginRight: spacing(2),
    width: 48,
    height: 48,
  },
  coverImage: {
    borderRadius: 8,
    width: '100%',
    height: '100%',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 'auto',
  },
  badgeIcon: {
    marginTop: spacing(-0.5),
  },
}));

const CourseMiniCard = ({
  className,
  translations,
  course,
  statuses,
  onStatusChange,
  onCardClick,
}) => {
  const classes = useStyles();
  const { title, description, coverImage, enrollmentStatus, tags } = course;
  const hasTags = !isArrayEmpty(tags);

  const handleCardClick = useCallback(() => {
    onCardClick(course.id);
  }, [course, onCardClick]);

  const handleStatusChange = useCallback(
    statusId => {
      onStatusChange(course.id, statusId);
    },
    [course, onStatusChange]
  );

  const renderTag = tag => (
    <Tag tag={tag} color={tag?.category?.color} isSelected />
  );

  return (
    <div
      className={classNames(classes.root, className)}
      onClick={handleCardClick}
    >
      <div className={classes.titleWrapper}>
        <div className={classes.coverWrapper}>
          {coverImage && (
            <img
              className={classes.coverImage}
              src={coverImage}
              alt="Course cover"
            />
          )}
        </div>
        <TextBoxWithTooltip
          className={classes.verticalEllipsis}
          text={title}
          variant="h5"
          isVerticalEllipsis
        />
      </div>
      {description && (
        <TextBoxWithTooltip
          className={classNames(classes.description, classes.verticalEllipsis)}
          text={description}
          variant="body2"
          isVerticalEllipsis
        />
      )}
      <div className={classes.footer}>
        <StatusIndicator
          status={enrollmentStatus}
          statuses={statuses}
          onSelect={handleStatusChange}
          isSelectable
        />
        {hasTags && (
          <AsyncListBadge
            badgeIconClass={classes.badgeIcon}
            tooltipTitle={translations.tags}
            count={tags.length}
            items={tags}
            icon={TagsIcon}
            renderItem={renderTag}
          />
        )}
      </div>
    </div>
  );
};

CourseMiniCard.defaultProps = {
  className: undefined,
  course: {},
};

CourseMiniCard.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  course: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    level: PropTypes.string,
    coverImage: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.object),
  }),
  statuses: PropTypes.arrayOf(PropTypes.object).isRequired,
  onStatusChange: PropTypes.func.isRequired,
  onCardClick: PropTypes.func.isRequired,
};

export default CourseMiniCard;
