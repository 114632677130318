import { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import UserAvatar from '../userAvatar';
import { getPersonShortName } from '../../../utility/helpers';
import { ReactComponent as RemoveIcon } from '../../../assets/icons/close-gray.svg';
import { updateScrollPosition } from '../../../utility/fillSurveyUtils';
import DraggableScroll from '../draggableScroll';

const styles = ({ palette: { primary }, spacing, breakpoints }) => ({
  main: {
    boxSizing: 'border-box',
    alignItems: 'center',
    overflowX: 'hidden',
    width: '100%',
  },
  users: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 40,
    '& .selected': {
      backgroundColor: primary.blue6,
    },
  },
  user: {
    border: `1px solid ${primary.bluish6}`,
    borderRadius: 60,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    padding: spacing(2, 4),
    marginRight: spacing(2),
    pointerEvents: 'auto !important',
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  userDisabled: {
    cursor: 'default',
  },
  userLabel: {
    fontFamily: 'ProximaNova-Bold',
    lineHeight: '18px',
    whiteSpace: 'nowrap',
  },
  removeIcon: {
    flexShrink: 0,
    height: 14,
    width: 14,
    marginLeft: spacing(3),
  },
  draggableScroll: {
    display: 'flex',
    margin: spacing(0, 0, 2.5, 4),
    [breakpoints.up('sm')]: {
      '&::-webkit-scrollbar-track': {
        marginRight: spacing(4),
      },
    },
    '&:after': {
      content: '""',
      flex: '0 0 16px',
    },
  },
});

class SelectableUserList extends PureComponent {
  constructor(props) {
    super(props);

    this.draggableScrollRef = createRef();
  }

  componentDidMount() {
    updateScrollPosition(this.draggableScrollRef.current, false);
  }

  componentDidUpdate(prevProps) {
    const { selectionKey } = this.props;

    if (prevProps.selectionKey !== selectionKey) {
      updateScrollPosition(this.draggableScrollRef.current, false);
    }
  }

  render() {
    const {
      className,
      classes,
      users,
      selection,
      selectionKey,
      isDisabled,
      getSelectedUser,
      shouldRenderUser,
      onSelect,
    } = this.props;

    return (
      <div className={classNames(classes.main, className)}>
        <DraggableScroll
          ref={this.draggableScrollRef}
          className={classNames(classes.draggableScroll, classes.users)}
          showHorizontalScrollbar
        >
          <>
            {users.map(user => {
              const isSelected = getSelectedUser(selection, selectionKey, user);

              return (
                shouldRenderUser(user) && (
                  <div
                    key={`user_${user.id}`}
                    className={classNames(classes.user, {
                      [classes.userDisabled]: isDisabled,
                      selected: isSelected,
                    })}
                    onClick={() => onSelect(user, isSelected)}
                  >
                    <UserAvatar
                      labelClass={classes.userLabel}
                      getCustomCaption={getPersonShortName}
                      user={user}
                      caption
                    />
                    {isSelected && !isDisabled && (
                      <RemoveIcon className={classes.removeIcon} />
                    )}
                  </div>
                )
              );
            })}
          </>
        </DraggableScroll>
      </div>
    );
  }
}

SelectableUserList.defaultProps = {
  className: null,
  selectionKey: 0,
  selection: {},
  isDisabled: false,
  getSelectedUser: () => {},
  shouldRenderUser: () => true,
  onSelect: () => {},
};

SelectableUserList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectionKey: PropTypes.number,
  selection: PropTypes.shape({}),
  isDisabled: PropTypes.bool,
  getSelectedUser: PropTypes.func,
  shouldRenderUser: PropTypes.func,
  onSelect: PropTypes.func,
};

export default withStyles(styles)(SelectableUserList);
