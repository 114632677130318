import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';

import { ReactComponent as WarningIcon } from '../../../assets/icons/warning-white.svg';
import { ReactComponent as SuccessIcon } from '../../../assets/icons/done.svg';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  iconWrapper: {
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: spacing(5),
    width: 40,
    height: 40,
  },
  contentBody: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  success: {
    backgroundColor: primary.green1,
  },
  error: {
    backgroundColor: primary.red1,
  },
}));

const ToastIcon = ({ type }) => {
  const classes = useStyles();

  switch (type) {
    case 'success':
      return (
        <div className={classNames(classes.iconWrapper, classes.success)}>
          <SuccessIcon />
        </div>
      );
    case 'error':
      return (
        <div className={classNames(classes.iconWrapper, classes.error)}>
          <WarningIcon />
        </div>
      );
    default:
      return null;
  }
};

const AlertBody = ({ text, type }) => {
  const classes = useStyles();

  return (
    <div className={classes.contentBody}>
      <ToastIcon type={type} />
      <Typography>{text}</Typography>
    </div>
  );
};

AlertBody.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node])
    .isRequired,
  type: PropTypes.oneOf(['success', 'error']).isRequired,
};

export default memo(AlertBody);
