import { FIELD_TYPES } from './fieldTypes';

export const INITIAL_SORT = {
  column: 'createdAt',
  asc: false,
  sortKey: '-created_at',
};
export const SHARE_REPORT_FORM_FIELDS = [
  {
    name: 'share_to',
    type: FIELD_TYPES.SELECT,
    translationKey: 'shareWith',
    optionsKey: 'allUsers',
    isUser: true,
    isMulti: true,
    parser: {
      value: 'id',
      label: 'first_name',
    },
  },
];
