import { memo } from 'react';
import { makeStyles } from '@material-ui/core';
import { components } from 'react-select';

const useStyles = makeStyles(({ palette: { primary } }) => ({
  root: {
    '& div': {
      color: primary.bluish1,
      fontFamily: 'Proximanova-Regular',
      fontSize: 16,
      lineHeight: '22px',
      margin: 0,
      padding: 0,
    },
  },
}));

const Input = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <components.Input {...props} />
    </div>
  );
};

Input.propTypes = {};

export default memo(Input);
