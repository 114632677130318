import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import SectionTitle from '../sectionTitle';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  title: {
    textAlign: 'center',
    fontSize: 28,
    lineHeight: '30px',
    marginBottom: spacing(4),
    marginRight: 0,
    marginLeft: 0,
    [breakpoints.up('xLg')]: {
      fontSize: 32,
      lineHeight: '40px',
      marginBottom: spacing(6),
      marginRight: spacing(4),
      marginLeft: spacing(4),
    },
    [breakpoints.up('xl')]: {
      fontSize: 32,
      lineHeight: '40px',
      marginRight: 30,
      marginLeft: 30,
      marginBottom: spacing(8),
    },
  },
  subtitleWrapper: {
    marginBottom: spacing(4),
    [breakpoints.up('xLg')]: {
      marginBottom: spacing(6),
    },
    [breakpoints.up('xl')]: {
      marginBottom: spacing(8),
    },
  },
  content: {
    width: 360,
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    width: 64,
    height: 64,
    marginBottom: spacing(2),
    [breakpoints.up('xLg')]: {
      width: 80,
      height: 80,
    },
    [breakpoints.up('xl')]: {
      width: 100,
      height: 100,
    },
  },
  children: {
    marginBottom: spacing(6),
    [breakpoints.up('xLg')]: {
      marginBottom: spacing(8),
    },
    [breakpoints.up('xl')]: {
      marginBottom: spacing(10),
    },
  },
}));

const FormLayout = ({
  className,
  childrenClass,
  contentClass,
  subtitleWrapperClass,
  iconClass,
  subtitleClass,
  titleClass,
  field,
  subtitleVariant,
  icon: Icon,
  children,
}) => {
  const classes = useStyles();

  return (
    <div className={className}>
      {Icon ? (
        <div className={classes.iconWrapper}>
          <Icon className={classNames(classes.icon, iconClass)} />
        </div>
      ) : null}
      <div className={classNames(classes.content, contentClass)}>
        <div>
          <Typography
            variant="h1"
            className={classNames(classes.title, titleClass)}
          >
            {field.title}
          </Typography>
          {field.subtitle && (
            <div
              className={classNames(
                classes.subtitleWrapper,
                subtitleWrapperClass
              )}
            >
              <SectionTitle
                className={subtitleClass}
                title={field.subtitle}
                variant={subtitleVariant}
              />
            </div>
          )}
        </div>
        <div className={classNames(classes.children, childrenClass)}>
          {children}
        </div>
      </div>
    </div>
  );
};

FormLayout.defaultProps = {
  className: '',
  iconClass: '',
  subtitleClass: '',
  subtitleWrapperClass: '',
  titleClass: '',
  childrenClass: '',
  contentClass: '',
  children: null,
  subtitleVariant: 'h3',
};

FormLayout.propTypes = {
  className: PropTypes.string,
  subtitleClass: PropTypes.string,
  subtitleWrapperClass: PropTypes.string,
  titleClass: PropTypes.string,
  childrenClass: PropTypes.string,
  contentClass: PropTypes.string,
  iconClass: PropTypes.string,
  field: PropTypes.shape({}).isRequired,
  subtitleVariant: PropTypes.string,
  children: PropTypes.element,
};

export default FormLayout;
