import { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  withStyles,
  TextField,
  FormControlLabel,
  Stepper,
  Step,
  FormHelperText,
} from '@material-ui/core';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import InputField from '../inputField';
import CustomButton from '../customButton';
import FormLayout from '../formLayout';
import CustomCheckbox from '../customCheckbox';
import SectionTitle from '../sectionTitle';
import NextButton from '../nextButton';
import ActionButton from '../actionButton';
import SectionLoader from '../sectionLoader';
import { ReactComponent as PersonIcon } from '../../../assets/icons/person_outline.svg';
import { ReactComponent as SecurityIcon } from '../../../assets/icons/security.svg';
import { ReactComponent as PublicIcon } from '../../../assets/icons/public.svg';
import { ReactComponent as MailIcon } from '../../../assets/icons/mail_outline.svg';
import { ReactComponent as DisturbIcon } from '../../../assets/icons/do_disturb_on.svg';
import { ReactComponent as RadioIcon } from '../../../assets/icons/radio_button_unchecked.svg';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg';
import { isEmpty, validateField } from '../../../utility/validation';
import { showErrorMessage } from '../../../utility/uiUtils';
import { asyncDebounce, isArray, isArrayEmpty } from '../../../utility/helpers';
import http, { API_DOMAIN } from '../../../utility/http';
import {
  APPLICANT_TOKEN,
  COMPANY_SIZE_MAP,
  DEMO_INIT,
  KADAR_APPLICANT,
  SIGN_UP_FORM_FIELDS,
  SIGN_UP_FORM_FIELD_NAMES,
  STEPS_MAP,
} from '../../pages/signUp/config';
import {
  API_APPLY_REGISTRATION_EMAIL,
  API_REGISTRATION_CONFIRM,
  API_REGISTRATION_UPDATE,
  API_VERIFY_EMAIL,
} from '../../../constants/apiRoutes';
import { BOOK_A_DEMO_LINK } from '../../../constants/appConfig';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';
import { FIELD_TYPES } from '../../../constants/fieldTypes';
import TextBoxWithTooltip from '../textBoxWithTooltip';

const styles = ({ palette: { primary }, spacing, breakpoints }) => ({
  root: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
  },
  rootFirstStep: {
    paddingTop: spacing(8),
    [breakpoints.up('xl')]: {
      paddingTop: spacing(20),
    },
  },
  formContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  layoutChild: {
    marginBottom: 0,
  },
  field: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    display: 'flex',
  },
  grid: {
    display: 'grid',
    justifyContent: 'center',
  },
  wrap: {
    height: 58,
    width: 360,
    justifyContent: 'space-between',
    display: 'grid',
    marginTop: spacing(4),
    [breakpoints.up('xLg')]: {
      height: 68,
    },
    [breakpoints.up('xl')]: {
      height: 98,
      marginTop: spacing(8),
    },
  },
  backButtonWrapper: {
    boxSizing: 'border-box',
    display: 'flex',
    width: '100%',
    padding: spacing(6, 8, 4, 8),
    [breakpoints.up('xLg')]: {
      padding: spacing(8, 10, 8, 10),
    },
    [breakpoints.up('xl')]: {
      padding: spacing(8, 10, 11, 10),
    },
  },
  backButton: {
    justifyContent: 'flex-start',
    padding: 0,
    border: 'none',
    '& span': {
      width: 32,
      height: 18,
      color: primary.black,
      textTransform: 'capitalize',
      fontFamily: 'ProximaNova-Bold',
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '18px',
    },
  },
  actionsWrapper: {
    marginBottom: spacing(10),
    [breakpoints.up('xl')]: {
      marginBottom: spacing(20),
    },
  },
  actionsWrapperVerification: {
    marginBottom: spacing(10),
  },
  nextButton: {
    marginTop: spacing(8),
    marginBottom: 0,
    '& span': {
      textTransform: 'capitalize',
    },
  },
  resendButton: {
    justifyContent: 'center',
    display: 'flex',
    marginTop: spacing(3),
    '& span': {
      height: 18,
      color: primary.black,
      textTransform: 'capitalize',
      fontFamily: 'ProximaNova-Bold',
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '18px',
    },
    [breakpoints.up('xl')]: {
      marginTop: spacing(6),
    },
  },
  disclaimer: {
    color: primary.bluish4,
    width: 360,
    marginTop: spacing(4),
    fontSize: 12,
  },
  emailTitle: {
    color: primary.blue1,
    margin: spacing(0, 0, 2, 0),
  },
  emailSubtitle: {
    color: primary.black,
    justifyContent: 'center',
  },
  verificationLabelBlock: {
    width: 360,
    textAlign: 'start',
    height: 48,
    lineHeight: '20px',
    fontSize: 16,
    color: primary.black,
  },
  regularLabel: {
    fontFamily: 'ProximaNova-Regular',
  },
  boldLabel: {
    fontFamily: 'ProximaNova-Bold',
  },
  domainStep: {
    width: 360,
  },
  domainNameSubtitle: {
    color: primary.black,
    justifyContent: 'center',
    marginBottom: spacing(8),
    [breakpoints.up('xLg')]: {
      marginBottom: spacing(10),
    },
    [breakpoints.up('xl')]: {
      marginBottom: spacing(13),
    },
  },
  domainNameWrapper: {
    gap: 4,
    display: 'flex',
    alignItems: 'baseline',
    fontFamily: 'ProximaNova-Bold',
    height: 60,
  },
  domainName: {
    color: primary.black,
  },
  block: {
    display: 'grid',
    gridGap: 8,
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    [breakpoints.up('xLg')]: {
      gridGap: 16,
    },
  },
  blockButton: {
    height: 58,
    '& span': {
      color: primary.bluish1,
      fontSize: 18,
      lineHeight: '20px',
      textTransform: 'none',
    },
    [breakpoints.up('xLg')]: {
      height: 72,
    },
  },
  jobTitleTitle: {
    marginBottom: spacing(2),
  },
  jobTitleSubtitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: spacing(3),
    '& > p': {
      width: 'auto',
    },
    [breakpoints.up('xLg')]: {
      marginBottom: spacing(6),
    },
    [breakpoints.up('xl')]: {
      marginBottom: spacing(10),
    },
  },
  buttonsSelectLabel: {
    color: primary.black,
    marginBottom: spacing(1),
    [breakpoints.up('xLg')]: {
      marginBottom: spacing(2),
    },
  },
  incentiveContent: {
    width: 400,
    [breakpoints.up('xLg')]: {
      width: 450,
    },
  },
  incentiveTitle: {
    marginBottom: spacing(2),
    marginRight: 0,
    marginLeft: 0,
    [breakpoints.up('xLg')]: {
      marginBottom: spacing(4),
    },
    [breakpoints.up('xl')]: {
      marginBottom: spacing(6),
      marginRight: spacing(30),
      marginLeft: spacing(30),
    },
  },
  companySizeTitle: {
    marginBottom: spacing(8),
    [breakpoints.up('xLg')]: {
      marginBottom: spacing(15),
    },
    [breakpoints.up('xl')]: {
      marginBottom: spacing(20),
    },
  },
  companySizeButton: {
    minHeight: 52,
    height: 52,
  },
  preferenceTitle: {
    marginBottom: spacing(20),
  },
  preferenceButton: {
    minHeight: 92,
    height: 92,
  },
  passwordBlock: {
    gridGap: 2,
    display: 'grid',
    marginTop: spacing(2),
    marginLeft: 'auto',
    width: 360,
    [breakpoints.up('xLg')]: {
      gridGap: 2,
      marginTop: spacing(3),
    },
    [breakpoints.up('xl')]: {
      gridGap: 4,
      marginTop: spacing(4),
    },
  },
  customRoot: {
    height: 20,
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',
    justifyContent: 'center',
    padding: 0,
    borderRadius: 0,
    border: `2px solid ${primary.bluish1}`,
    width: 40,
    '&.MuiInput-root.Mui-focused': {
      border: `2px solid ${primary.bluish1}`,
      borderLeft: 'none',
      borderRight: 'none',
      borderTop: 'none',
      borderRadius: 0,
      width: 40,
    },
  },
  customInput: {
    lineHeight: '20px',
    height: 20,
    width: 22,
    fontSize: 36,
    fontWeight: 400,
    fontFamily: 'ProximaNova-Regular',
    color: primary.black,
    textAlign: 'center',
  },
  verificationTextField: {
    alignItems: 'end',
    display: 'grid',
    gridGap: 24,
    gridTemplateColumns: 'repeat(6, minmax(0, 1fr))',
  },
  inputField: {
    borderRadius: 32,
    border: `1px solid ${primary.bluish7}`,
    gap: 4,
    height: 40,
    lineHeight: '24px',
    padding: spacing(0, 4),
    '& > input': {
      borderRadius: 32,
      padding: spacing(1.75, 0, 1.75, 0),
      fontWeight: 400,
      fontFamily: 'ProximaNova-Regular',
      color: primary.bluish1,
      fontSize: 16,
      backgroundColor: primary.white,
    },
  },
  fullNameWrapper: {
    height: 120,
    '& > div': {
      height: 60,
    },
  },
  passwordInput: {
    lineHeight: '24px',
    '& > input': {
      color: primary.bluish1,
    },
  },
  domainNameInput: {
    borderRadius: 32,
    border: `1px solid ${primary.bluish1}`,
    lineHeight: '24px',

    '& > input': {
      color: primary.bluish1,
    },
  },
  radioButton: {
    pointerEvents: 'none',
    lineHeight: '24px',
    fontFamily: 'ProximaNova-Regular',
    fontWeight: 400,
    fontSize: 14,
    color: primary.bluish1,
    gap: 8,
    '& > svg': {
      width: 16,
      height: 16,
      color: primary.bluish3,
    },
    '& > span': {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      [breakpoints.up('xLg')]: {
        lineHeight: '18px',
      },
      [breakpoints.up('xl')]: {
        lineHeight: '24px',
      },
    },
  },
  radioButtonError: {
    '& > span': {
      color: primary.red1,
    },
  },
  endAdornment: {
    '& g, & path': {
      fill: primary.bluish5,
      stroke: primary.bluish5,
    },
  },
  buttonColumn: {
    display: 'grid',
    gridGap: 4,
    [breakpoints.up('xLg')]: {
      gridGap: 6,
    },
    [breakpoints.up('xl')]: {
      gridGap: 8,
    },
  },
  checkboxWrapper: {
    borderRadius: 8,
    border: `1px solid ${primary.bluish6}`,
    padding: spacing(1, 2),
    display: 'grid',
    alignItems: 'center',
    [breakpoints.up('xl')]: {
      padding: spacing(2, 4, 2, 4),
    },
  },
  checkboxRoot: {
    padding: spacing(0, 1.5, 0, 1.5),
  },
  customCheckbox: {
    '& > div': {
      width: 32,
      height: 32,
      borderRadius: 8,
      border: `1px solid ${primary.bluish6}`,
    },
  },
  customCheckboxLabelText: {
    fontSize: 14,
    lineHeight: '18px',
    [breakpoints.up('xLg')]: {
      fontFamily: 'ProximaNova-Regular',
      fontSize: 16,
      lineHeight: '20px',
    },
  },
  customCheckedCheckbox: {
    '& > svg': {
      width: 32,
      height: 32,
      borderRadius: 8,
      border: `1px solid ${primary.bluish1}`,
      '& > path': {
        fill: primary.bluish1,
        color: primary.white,
        transform: 'scale(0.5)',
        transformOrigin: 'center',
      },
    },
  },
  stepperWrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: -24,
    left: 0,
    right: 0,
    height: 16,
  },
  stepper: {
    flexGrow: 1,
  },
  step: {
    backgroundColor: primary.bluish7,
    borderRadius: 30,
    flexGrow: 1,
    flexShrink: 0,
    height: 8,
    padding: 0,
    marginRight: spacing(2),
  },
  completedStep: {
    backgroundColor: primary.bluish1,
  },
  stepperLabelWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    width: 18,
  },
  stepperLabel: {
    color: primary.bluish3,
  },
  checked: {
    border: `1px solid ${primary.bluish1}`,
  },
  unchecked: {
    border: `1px solid ${primary.bluish6}`,
  },
  inProgressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: 360,
  },
  loadingSection: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: spacing(6),
    [breakpoints.up('xLg')]: {
      marginBottom: spacing(8),
    },
    [breakpoints.up('xl')]: {
      marginBottom: spacing(10),
    },
  },
  loadingTitle: {
    textAlign: 'center',
    marginBottom: spacing(4),
    [breakpoints.up('xLg')]: {
      marginBottom: spacing(6),
    },
    [breakpoints.up('xl')]: {
      marginBottom: spacing(8),
    },
  },
  loadingDescription: {
    textAlign: 'center',
    marginBottom: spacing(2),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  errorWrap: {
    height: 0,
    width: 360,
  },
  error: {
    position: 'relative',
  },
  disabledBackButton: {
    '&:disabled': {
      backgroundColor: primary.white,
      '& > span': {
        color: primary.bluish5,
      },
    },
  },
  privacyLinks: {
    textDecoration: 'none',
  },
  privacyLinksText: {
    fontSize: 12,
  },
  subtitleWrapper: {
    marginBottom: spacing(4),
    [breakpoints.up('xLg')]: {
      marginBottom: spacing(6),
    },
    [breakpoints.up('xl')]: {
      marginBottom: spacing(8),
    },
  },
});

const FULL_NAME_FIELDS = [
  SIGN_UP_FORM_FIELD_NAMES.FIRST_NAME,
  SIGN_UP_FORM_FIELD_NAMES.LAST_NAME,
];
export const VERIFICATION_CODE_LENGTH = 6;
export const VERIFICATION_CODE_INIT_VALUE = Array.from(
  { length: VERIFICATION_CODE_LENGTH },
  () => ''
);

class SignUpLayout extends PureComponent {
  constructor(props) {
    super(props);
    this.handleValidateSingleField = asyncDebounce(
      this.handleValidateSingleField
    );

    this.verificationRef = Array(VERIFICATION_CODE_LENGTH)
      .fill()
      .map(() => createRef());
    this.state = {
      inProgress: false,
      isDisabled: true,
      isTextType: false,
      calculatedErrors: [],
      fieldNames: [],
      errors: {},
      verificationCode: VERIFICATION_CODE_INIT_VALUE,
      step: 0,
    };
  }

  componentWillUnmount() {
    const { clearSignUp } = this.props;

    clearSignUp();
  }

  handleStep = responseData => {
    const { setFieldError, validateOrganizationName, setSignUp } = this.props;
    const applicant = responseData?.applicant;
    let tempStep = 0;

    Object.values(SIGN_UP_FORM_FIELDS).some(value => {
      const { label, step } = value;

      tempStep = step;
      if (!applicant[label]) {
        const tempFieldNames = FULL_NAME_FIELDS.includes(label)
          ? FULL_NAME_FIELDS
          : [label];
        this.setState({ fieldNames: tempFieldNames, isDisabled: true });
        return true;
      }
      return false;
    });
    setSignUp({
      ...applicant,
      [APPLICANT_TOKEN]: responseData[APPLICANT_TOKEN],
    });
    const domainValue = applicant[SIGN_UP_FORM_FIELDS.DOMAIN_NAME.label];
    if (domainValue) {
      http.defaults.headers[KADAR_APPLICANT] = responseData[APPLICANT_TOKEN];
      validateOrganizationName(domainValue)
        .then(() => {
          this.setState({
            step: applicant[DEMO_INIT] ? STEPS_MAP.PASSWORD : tempStep,
          });
        })
        .catch(() => {
          setFieldError(SIGN_UP_FORM_FIELDS.DOMAIN_NAME.label, 'alreadyTaken');
          this.setState({ step: STEPS_MAP.DOMAIN_NAME });
        });
    } else {
      this.setState({
        step: applicant[DEMO_INIT] ? STEPS_MAP.PASSWORD : tempStep,
      });
    }
  };

  handleValidateSingleField = (field, value, initialValue, values) => {
    const { setFieldError } = this.props;

    return validateField(field, value, initialValue, values).then(errorType => {
      this.handleDisableNext(Boolean(errorType));
      setFieldError(field.name, errorType);
      return errorType;
    });
  };

  handleInputChange = async (e, field) => {
    const { initialData, setFieldValue } = this.props;

    this.handleValidateSingleField(
      field,
      e.target.value,
      initialData[field.name]
    );

    this.setState({ fieldNames: [field.name] });
    setFieldValue(field.name, e.target.value);
  };

  handlePasswordChange = async (e, field) => {
    const { setFieldValue } = this.props;
    const newValue = e.target.value;
    const calculatedErrors = this.handleErrors(newValue, field);
    const hasError = calculatedErrors.some(error => error);

    this.setState({
      fieldNames: [field.name],
      calculatedErrors,
    });
    this.handleDisableNext(hasError || !newValue);
    setFieldValue(field.name, newValue);
  };

  handleButtonChange = async (value, field) => {
    const { setFieldValue } = this.props;

    this.handleDisableNext(false);

    this.setState({ fieldNames: [field.name] });
    setFieldValue(field.name, value);
  };

  handleCheckboxChange = async (field, value) => {
    const { setFieldValue, values } = this.props;
    let newValue = values[field.name] || [];

    if (newValue.includes(value)) {
      newValue = newValue.filter(item => item !== value);
    } else {
      newValue.push(value);
    }
    this.handleDisableNext(isArrayEmpty(newValue));
    this.setState({ fieldNames: [field.name] });
    setFieldValue(field.name, newValue);
  };

  handleOtpChange = async (e, field, index) => {
    const { translations } = this.props;
    const { verificationCode } = this.state;
    const char = e.target.value;
    const newCode = [...verificationCode];

    newCode[index] = char;

    if (char.length === 1 && index < VERIFICATION_CODE_LENGTH - 1) {
      this.verificationRef[index + 1].current?.focus();
    }

    if (newCode.every(code => code !== '')) {
      this.handleDisableNext(false);
      this.setState({ errors: { [field.name]: null } });
    } else {
      this.setState({
        errors: { [field.name]: translations.validationMessages.codeLength },
      });
      this.handleDisableNext(true);
    }

    this.setState({ fieldNames: [field.name], verificationCode: newCode });
  };

  handleDisableNext = value => {
    this.setState({ isDisabled: value });
  };

  handleName = (e, field) => {
    const { setFieldValue, values } = this.props;
    const otherFieldName =
      field.name === SIGN_UP_FORM_FIELD_NAMES.FIRST_NAME
        ? {
            label: SIGN_UP_FORM_FIELD_NAMES.LAST_NAME,
            name: SIGN_UP_FORM_FIELD_NAMES.LAST_NAME,
          }
        : {
            label: SIGN_UP_FORM_FIELD_NAMES.FIRST_NAME,
            name: SIGN_UP_FORM_FIELD_NAMES.FIRST_NAME,
          };
    const newValue = e.target.value;
    const emptyString = '';
    setFieldValue(field.name, newValue);

    validateField(field, newValue, emptyString, values).then(errorType => {
      validateField(
        { ...field, name: otherFieldName.label },
        values[otherFieldName.name],
        emptyString,
        values
      ).then(error => {
        if (error || errorType) {
          this.handleDisableNext(true);
        } else {
          this.handleDisableNext(false);
          this.setState({ fieldNames: FULL_NAME_FIELDS });
        }
        this.setState({
          errors: {
            [field.name]: errorType,
            [otherFieldName.label]: error,
          },
        });
      });
    });
  };

  handleSubmit = token => {
    const { values, translations } = this.props;
    const port =
      window.location.port.length > 0 ? `:${window.location.port}` : '';
    const { protocol } = window.location;
    if (token) {
      http.defaults.headers.Authorization = `Bearer ${token}`;

      window.location.href = `${protocol}//${
        values[SIGN_UP_FORM_FIELD_NAMES.DOMAIN_NAME]
      }.${API_DOMAIN.split(':')[0]}${port}/login/${token}`;
    } else {
      showErrorMessage(translations.somethingWentWrong);
      this.setState({ inProgress: false });
    }
  };

  handleDisableNextCheck = newStep => {
    const { values } = this.props;
    const allValues = [];
    Object.values(SIGN_UP_FORM_FIELDS).forEach(item => {
      if (item.step === newStep) {
        allValues.push(values[item.label]);
      }
    });
    if (
      allValues.every(
        value =>
          (!isArray(value) && isEmpty(value)) ||
          (isArray(value) && value.length)
      )
    ) {
      this.handleDisableNext(false);
    } else {
      this.handleDisableNext(true);
    }
  };

  handleNextUpdate = async (newStep, reducedData) => {
    const { values } = this.props;

    this.handleDisableNextCheck(newStep);
    http.defaults.headers[KADAR_APPLICANT] = values[APPLICANT_TOKEN];
    await http.post(API_REGISTRATION_UPDATE, reducedData).then(() => {
      this.setState({ step: newStep });
    });
  };

  handleUpdate = newStep => {
    const { values, setFieldError, validateOrganizationName } = this.props;
    const { fieldNames, step } = this.state;
    const isDomainNameStep = step === STEPS_MAP.DOMAIN_NAME;
    const reducedData = fieldNames.reduce((acc, curr) => {
      return {
        ...acc,
        [curr]: values[curr],
      };
    }, {});

    if (isDomainNameStep) {
      validateOrganizationName(
        values[SIGN_UP_FORM_FIELD_NAMES.DOMAIN_NAME]
      ).then(({ data }) => {
        if (data?.exists) {
          setFieldError(SIGN_UP_FORM_FIELDS.DOMAIN_NAME.label, 'alreadyTaken');
          this.handleDisableNext(true);
        } else {
          this.handleNextUpdate(newStep, reducedData);
        }
      });
    } else {
      this.handleNextUpdate(newStep, reducedData);
    }
  };

  handleNext = async (back = false, sameStep = null) => {
    const { setFieldValue, values } = this.props;
    const { step, verificationCode } = this.state;
    const isEmailStep = sameStep === STEPS_MAP.EMAIL;
    const tempStep = isEmailStep ? sameStep : step;
    const newStep = back ? step - 1 : step + 1;

    if (!back) {
      switch (tempStep) {
        case STEPS_MAP.EMAIL: {
          const emailStep = isEmailStep
            ? STEPS_MAP.EMAIL_VERIFICATION_CODE
            : newStep;

          await http.post(API_APPLY_REGISTRATION_EMAIL, {
            email: values.email,
          });

          this.setState({
            step: emailStep,
            isDisabled: true,
          });
          break;
        }
        case STEPS_MAP.EMAIL_VERIFICATION_CODE:
          await http
            .post(API_VERIFY_EMAIL, {
              email: values.email,
              email_verification_code: verificationCode.join(''),
            })
            .then(({ data }) => {
              if (Object.keys(data?.error)?.length) {
                Object.values(data.error).forEach(value => {
                  this.setState({
                    errors: {
                      [SIGN_UP_FORM_FIELD_NAMES.VERIFICATION]: value[0],
                    },
                  });
                });
                this.handleDisableNext(true);
              } else {
                this.setState({
                  verificationCode: VERIFICATION_CODE_INIT_VALUE,
                });
                this.handleStep(data);
                setFieldValue(APPLICANT_TOKEN, data[APPLICANT_TOKEN]);
              }
            });
          break;
        case STEPS_MAP.PREFERENCE:
          if (values[SIGN_UP_FORM_FIELD_NAMES.PREFERENCE]) {
            this.setState({
              step: newStep,
              isDisabled: true,
            });
          } else {
            http.defaults.headers[KADAR_APPLICANT] = values[APPLICANT_TOKEN];
            await http
              .post(API_REGISTRATION_UPDATE, {
                [DEMO_INIT]: true,
              })
              .then(() => {
                // go to Calendly page
                this.props.history.push(BOOK_A_DEMO_LINK);
              });
          }
          break;
        case STEPS_MAP.PASSWORD:
          http.defaults.headers[KADAR_APPLICANT] = values[APPLICANT_TOKEN];
          this.setState({ inProgress: true }, () => {
            http
              .post(API_REGISTRATION_CONFIRM, {
                [SIGN_UP_FORM_FIELD_NAMES.PASSWORD]:
                  values[SIGN_UP_FORM_FIELD_NAMES.PASSWORD],
              })
              .then(
                ({ data }) => {
                  this.handleSubmit(data?.token);
                },
                () => this.props.history.replace('/not-found')
              );
          });
          break;
        default: {
          this.handleUpdate(newStep);
        }
      }
    } else {
      const name =
        newStep === SIGN_UP_FORM_FIELDS.FIRST_NAME.step
          ? FULL_NAME_FIELDS
          : [
              Object.values(SIGN_UP_FORM_FIELDS).find(
                value => value.step === newStep
              )?.label,
            ];
      const isVerificationCodeStep = step === STEPS_MAP.EMAIL_VERIFICATION_CODE;
      const isExcludedStep = [
        STEPS_MAP.EMAIL_VERIFICATION_CODE,
        STEPS_MAP.PREFERENCE,
        STEPS_MAP.PASSWORD,
      ].includes(step);
      if (!isExcludedStep) {
        this.handleUpdate(newStep);
      }
      this.setState({
        ...(isExcludedStep ? { step: newStep } : {}),
        fieldNames: name,
        isDisabled: false,
        ...(isVerificationCodeStep
          ? { verificationCode: VERIFICATION_CODE_INIT_VALUE }
          : {}),
      });
    }
  };

  handleResend = () => {
    const sameStep = STEPS_MAP.EMAIL;

    this.setState({
      errors: { [SIGN_UP_FORM_FIELD_NAMES.VERIFICATION]: null },
    });
    this.setState({ verificationCode: VERIFICATION_CODE_INIT_VALUE });
    this.handleNext(false, sameStep);
  };

  handleErrors = (value, field) => {
    const tempValue = Array.from({ length: 4 }, () => undefined);
    if (!isEmpty(value)) {
      return tempValue;
    }
    field.validators.forEach((each, index) => {
      tempValue[index] = !each.validator(value);
    });

    return tempValue;
  };

  handleIcon = (field, error) => {
    const { values } = this.props;

    if (!values[field.name]) return <RadioIcon />;

    if (error) {
      return <DisturbIcon />;
    }

    return <CheckIcon />;
  };

  handleDisclaimerText = () => {
    const { classes, translations } = this.props;
    const privacyPolicy = (
      <Link
        to={translations.authContainer.privacy_policy.url}
        className={classes.privacyLinks}
      >
        <Typography
          variant="body1"
          component="span"
          className={classes.privacyLinksText}
        >
          {translations.authContainer.privacy_policy.name}
        </Typography>
      </Link>
    );

    const termsOfService = (
      <Link
        to={translations.authContainer.terms_of_service.url}
        className={classes.privacyLinks}
      >
        <Typography
          variant="body1"
          component="span"
          className={classes.privacyLinksText}
        >
          {translations.authContainer.terms_of_service.name}
        </Typography>
      </Link>
    );

    return (
      <Typography className={classes.disclaimer}>
        {translations.disclaimer} {privacyPolicy} {translations.and}{' '}
        {termsOfService}
      </Typography>
    );
  };

  renderField = field => {
    const { classes, errors, translations, values } = this.props;

    const { formLabels, validationMessages } = translations;

    switch (field.id) {
      case SIGN_UP_FORM_FIELD_NAMES.EMAIL:
        return (
          <div key={field.name} className={classes.field}>
            <FormLayout
              field={field}
              subtitleVariant="subtitle1"
              titleClass={classes.emailTitle}
              subtitleClass={classes.emailSubtitle}
              childrenClass={classes.layoutChild}
            >
              <>
                <InputField
                  className={classNames(classes.inputField, {})}
                  name={field.name}
                  type={field.fieldType}
                  placeholder={formLabels.email.placeholder}
                  onChange={e => {
                    this.handleInputChange(e, field);
                  }}
                  error={!!errors[field.name]}
                  errorMessage={validationMessages?.[errors[field.name]]}
                  value={values[field.name]}
                  fullWidth
                />
              </>
            </FormLayout>
          </div>
        );
      case SIGN_UP_FORM_FIELD_NAMES.VERIFICATION: {
        const errorMessage =
          this.state.errors[SIGN_UP_FORM_FIELD_NAMES.VERIFICATION];
        const error = Boolean(errorMessage);

        return (
          <div key={field.name}>
            <FormLayout
              field={field}
              icon={MailIcon}
              childrenClass={classes.layoutChild}
            >
              <>
                <div className={classes.verificationLabelBlock}>
                  <SectionTitle
                    subtitleClass={classes.regularLabel}
                    title={field.label}
                    variant="body1"
                  />
                  <Typography variant="body1" className={classes.boldLabel}>
                    {values.email}
                  </Typography>
                </div>
                <div className={classes.wrap}>
                  <Typography variant="subtitle2">
                    {field.inputLabel}
                  </Typography>
                  <div className={classes.verificationTextField}>
                    {Array.from(
                      { length: VERIFICATION_CODE_LENGTH },
                      (_, index) => (
                        <TextField
                          inputRef={this.verificationRef[index]}
                          key={`verification_code_${index}`}
                          id="standard-basic"
                          value={this.state.verificationCode[index]}
                          InputProps={{
                            classes: { root: classes.customRoot },
                            inputProps: {
                              className: classes.customInput,
                              maxLength: 1,
                            },
                          }}
                          variant="standard"
                          onChange={e => {
                            return e.target.value === '' ||
                              !Number.isNaN(Number(parseFloat(e.target.value)))
                              ? this.handleOtpChange(e, field, index)
                              : null;
                          }}
                        />
                      )
                    )}
                  </div>
                </div>
                <div className={classes.errorWrap}>
                  {error && errorMessage && (
                    <FormHelperText className={classes.error} error={error}>
                      {errorMessage}
                    </FormHelperText>
                  )}
                </div>
              </>
            </FormLayout>
          </div>
        );
      }
      case SIGN_UP_FORM_FIELD_NAMES.FULL_NAME:
        return (
          <div key={field.name}>
            <FormLayout
              childrenClass={classes.layoutChild}
              field={field}
              icon={PersonIcon}
            >
              <div className={classes.fullNameWrapper}>
                {field.fields.map(item => (
                  <InputField
                    key={`field_${item.name}`}
                    className={classes.inputField}
                    name={item.name}
                    type={field.fieldType}
                    placeholder={item.placeholder}
                    onChange={e =>
                      this.handleName(e, { ...field, name: item.name })
                    }
                    value={values[item.name]}
                    error={!!this.state.errors[item.name]}
                    errorMessage={
                      validationMessages?.[this.state.errors[item.name]]
                    }
                    fullWidth
                  />
                ))}
              </div>
            </FormLayout>
          </div>
        );
      case SIGN_UP_FORM_FIELD_NAMES.DOMAIN_NAME:
        return (
          <div className={classes.domainStep} key={field.name}>
            <FormLayout
              field={{
                ...field,
                title: `${field.title} ${
                  values[SIGN_UP_FORM_FIELD_NAMES.FIRST_NAME]
                }!`,
              }}
              icon={PublicIcon}
              subtitleVariant="subtitle1"
              subtitleClass={classes.domainNameSubtitle}
              childrenClass={classes.layoutChild}
            >
              <div className={classes.domainNameWrapper}>
                <InputField
                  className={classNames(
                    classes.inputField,
                    classes.domainNameInput
                  )}
                  name={field.name}
                  type={field.fieldType}
                  placeholder={field.placeholder}
                  onChange={e => this.handleInputChange(e, field)}
                  value={values[field.name]}
                  error={!!errors[field.name]}
                  errorMessage={validationMessages?.[errors[field.name]]}
                  fullWidth
                />
                <Typography className={classes.domainName} variant="subtitle1">
                  {field.domainNameLabel}
                </Typography>
              </div>
            </FormLayout>
          </div>
        );
      case SIGN_UP_FORM_FIELD_NAMES.PASSWORD: {
        const { calculatedErrors, isTextType } = this.state;
        const VALIDATION_NUMBER = 5;
        return (
          <div key={field.name}>
            <FormLayout
              field={field}
              icon={SecurityIcon}
              childrenClass={classes.layoutChild}
            >
              <InputField
                className={classNames(
                  classes.inputField,
                  classes.passwordInput
                )}
                name={field.name}
                type={isTextType ? FIELD_TYPES.TEXT : field.fieldType}
                placeholder={field.placeholder}
                onChange={e => {
                  this.handlePasswordChange(e, field);
                }}
                endAdornment={
                  <ActionButton
                    className={classes.endAdornment}
                    type={
                      isTextType
                        ? ACTION_BUTTON_TYPES.PREVIEW_HIDE
                        : ACTION_BUTTON_TYPES.PREVIEW
                    }
                    onClickHandler={() =>
                      this.setState({ isTextType: !isTextType })
                    }
                  />
                }
                value={values[field.name]}
                fullWidth
              />
            </FormLayout>
            <div className={classes.passwordBlock}>
              {Array.from({ length: VALIDATION_NUMBER }, (_, index) => (
                <FormControlLabel
                  key={`validation_${index}`}
                  classes={{
                    root: classNames(classes.radioButton, {
                      [classes.radioButtonError]: calculatedErrors[index],
                    }),
                  }}
                  label={field.passwordValidationLabels[index]}
                  value={calculatedErrors[index]}
                  control={this.handleIcon(field, calculatedErrors[index])}
                />
              ))}
            </div>
          </div>
        );
      }
      case SIGN_UP_FORM_FIELD_NAMES.JOB_TITLE: {
        const domain = values[SIGN_UP_FORM_FIELD_NAMES.DOMAIN_NAME];
        return (
          <div key={field.name}>
            <FormLayout
              field={field}
              childrenClass={classes.layoutChild}
              titleClass={classes.jobTitleTitle}
            >
              <>
                {domain && (
                  <div
                    className={classNames(
                      classes.subtitleWrapper,
                      classes.jobTitleSubtitle
                    )}
                  >
                    <TextBoxWithTooltip text={domain} variant="subtitle1" />
                    {field.domainNameSubtitle && (
                      <Typography component="span" variant="body1">
                        {field.domainNameSubtitle}
                      </Typography>
                    )}
                  </div>
                )}
                <Typography
                  className={classes.buttonsSelectLabel}
                  variant="subtitle1"
                >
                  {field.label}
                </Typography>
                <div className={classes.block}>
                  {Array.from({ length: 4 }, (_, index) => {
                    const isCheckedJobTitle =
                      field.buttonLabels[index] === values[field.name];
                    return (
                      <CustomButton
                        className={classNames(classes.blockButton, {
                          [classes.checked]: isCheckedJobTitle,
                          [classes.unchecked]: !isCheckedJobTitle,
                        })}
                        key={`button_job_title_${index}`}
                        type="textRoundedLarge"
                        onClick={() =>
                          this.handleButtonChange(
                            field.buttonLabels[index],
                            field
                          )
                        }
                      >
                        {field.buttonLabels[index]}
                      </CustomButton>
                    );
                  })}
                </div>
              </>
            </FormLayout>
          </div>
        );
      }
      case SIGN_UP_FORM_FIELD_NAMES.INCENTIVE: {
        return (
          <div key={field.name}>
            <FormLayout
              field={field}
              contentClass={classes.incentiveContent}
              titleClass={classes.incentiveTitle}
              childrenClass={classes.layoutChild}
            >
              <>
                <Typography
                  className={classes.buttonsSelectLabel}
                  variant="subtitle1"
                >
                  {field.label}
                </Typography>
                <div className={classes.buttonColumn}>
                  {Array.from({ length: 4 }, (_, index) => {
                    const isCheckedIncentive = values[field.name]
                      ? values[field.name].includes(field.buttonLabels[index])
                      : false;
                    return (
                      <div
                        className={classNames(classes.checkboxWrapper, {
                          [classes.checked]: isCheckedIncentive,
                        })}
                        key={`checkbox_incentive_${index}`}
                      >
                        <CustomCheckbox
                          customCheckBoxRoot={classes.checkboxRoot}
                          customCheckboxClass={classes.customCheckbox}
                          labelTextClass={classes.customCheckboxLabelText}
                          customCheckedCheckboxClass={classNames(
                            classes.customCheckedCheckbox
                          )}
                          isChecked={isCheckedIncentive}
                          icon={CheckIcon}
                          labelText={field.buttonLabels[index]}
                          onChange={() =>
                            this.handleCheckboxChange(
                              field,
                              field.buttonLabels[index]
                            )
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              </>
            </FormLayout>
          </div>
        );
      }
      case SIGN_UP_FORM_FIELD_NAMES.COMPANY_SIZE:
        return (
          <div key={field.name}>
            <FormLayout
              field={field}
              titleClass={classes.companySizeTitle}
              childrenClass={classes.layoutChild}
            >
              <div className={classes.block}>
                {Array.from({ length: 5 }, (_, index) => {
                  const isCheckedCompanySize =
                    COMPANY_SIZE_MAP[field.buttonLabels[index]] ===
                    values[field.name];
                  return (
                    <CustomButton
                      className={classNames(
                        classes.blockButton,
                        classes.companySizeButton,
                        {
                          [classes.checked]: isCheckedCompanySize,
                          [classes.unchecked]: !isCheckedCompanySize,
                        }
                      )}
                      key={`button_company_size_${index}`}
                      type="textRoundedLarge"
                      onClick={() =>
                        this.handleButtonChange(
                          COMPANY_SIZE_MAP[field.buttonLabels[index]],
                          field
                        )
                      }
                    >
                      {field.buttonLabels[index]}
                    </CustomButton>
                  );
                })}
              </div>
            </FormLayout>
          </div>
        );
      case SIGN_UP_FORM_FIELD_NAMES.PREFERENCE:
        return (
          <div key={field.name}>
            <FormLayout
              field={{
                ...field,
                title: `${field.title} ${
                  values[SIGN_UP_FORM_FIELD_NAMES.FIRST_NAME]
                }?`,
              }}
              titleClass={classes.preferenceTitle}
            >
              <div className={classes.block}>
                {Array.from({ length: 2 }, (_, index) => {
                  const isCheckedPreference =
                    field.buttonLabels[index].value === values[field.name];

                  return (
                    <CustomButton
                      className={classNames(
                        classes.blockButton,
                        classes.preferenceButton,
                        {
                          [classes.checked]: isCheckedPreference,
                          [classes.unchecked]: !isCheckedPreference,
                        }
                      )}
                      key={`button_preference_${index}`}
                      type="textRoundedLarge"
                      onClick={() =>
                        this.handleButtonChange(
                          field.buttonLabels[index].value,
                          field
                        )
                      }
                    >
                      {field.buttonLabels[index].label}
                    </CustomButton>
                  );
                })}
              </div>
            </FormLayout>
          </div>
        );
      default:
        return null;
    }
  };

  render() {
    const { classes, translations, fields } = this.props;
    const { isDisabled, step, inProgress } = this.state;

    const buttonLabel = step ? translations.next : translations.verifyEmail;
    const newStep = step + 1;
    const isVerificationCodeStep = step === STEPS_MAP.EMAIL_VERIFICATION_CODE;
    const isExcludedStep = [
      STEPS_MAP.EMAIL_VERIFICATION_CODE,
      STEPS_MAP.PREFERENCE,
      STEPS_MAP.PASSWORD,
    ].includes(step);

    return (
      <>
        <div className={classes.stepperWrapper}>
          <Stepper
            classes={{ root: classes.stepper }}
            activeStep={step}
            connector={null}
          >
            {Object.keys(fields).map((fieldsStep, index) => {
              return (
                <Step
                  key={`step_item_${index}`}
                  classes={{
                    horizontal: classes.step,
                    completed: classes.completedStep,
                  }}
                  completed={step >= index}
                />
              );
            })}
          </Stepper>
          <div className={classes.stepperLabelWrapper}>
            <Typography
              className={classes.stepperLabel}
              variant="caption"
            >{`${newStep}/${Object.keys(fields).length}`}</Typography>
          </div>
        </div>
        <form>
          <div
            className={classNames(classes.root, {
              [classes.rootFirstStep]: step === 0,
            })}
          >
            {!inProgress && (
              <div className={classes.formContentWrapper}>
                {step !== 0 && (
                  <div className={classes.backButtonWrapper}>
                    <CustomButton
                      className={classNames(classes.backButton, {
                        [classes.disabledBackButton]:
                          isDisabled && !isExcludedStep,
                      })}
                      type="backNoIcon"
                      onClick={() => this.handleNext(true)}
                      disabled={isDisabled && !isExcludedStep}
                      disableRipple
                      disableFocusRipple
                    >
                      {translations.back}
                    </CustomButton>
                  </div>
                )}
                {this.renderField(fields[step])}
              </div>
            )}
            {inProgress && step === STEPS_MAP.PASSWORD && (
              <div className={classes.inProgressWrapper}>
                <div className={classes.loadingSection}>
                  <SectionLoader isLoading relativePosition />
                </div>
                <Typography className={classes.loadingTitle} variant="h1">
                  {translations.loadingText}
                </Typography>
                <Typography className={classes.loadingDescription}>
                  {translations.readyInMoment}
                </Typography>
                <Typography className={classes.loadingDescription}>
                  {translations.dontReloadMessage}
                </Typography>
              </div>
            )}
            {!inProgress && (
              <div
                className={classNames(classes.grid, classes.actionsWrapper, {
                  [classes.actionsWrapperVerification]: isVerificationCodeStep,
                })}
              >
                <NextButton
                  customClass={classes.nextButton}
                  buttonLabel={buttonLabel}
                  isSubmit={false}
                  isDisabled={isDisabled}
                  onClick={this.handleNext}
                />
                {isVerificationCodeStep && (
                  <div className={classes.resendButton}>
                    <CustomButton
                      className={classes.backButton}
                      type="backNoIcon"
                      onClick={() => this.handleResend()}
                      disableRipple
                      disableFocusRipple
                    >
                      {translations.resend}
                    </CustomButton>
                  </div>
                )}
                {step === STEPS_MAP.EMAIL && this.handleDisclaimerText()}
              </div>
            )}
          </div>
        </form>
      </>
    );
  }
}

SignUpLayout.defaultProps = {};

SignUpLayout.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default withStyles(styles)(SignUpLayout);
