import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import AgendaList from '../../../agendaList';
import CustomButton from '../../../customButton';
import { isArrayEmpty } from '../../../../../utility/helpers';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  button: {
    color: primary.blue1,
    backgroundColor: primary.bluish9,
    border: 'none',
    borderRadius: 8,
    padding: spacing(2),
    width: 'fit-content',
    marginTop: spacing(1),
    '& span': {
      fontFamily: 'ProximaNova-Bold',
      fontSize: 14,
      lineHeight: '18px',
    },
    '&:hover': {
      backgroundColor: primary.bluish9,
    },
  },
  icon: {
    marginRight: spacing(1),
    width: 18,
    height: 18,
  },
}));

const Agenda = ({
  translations,
  moderatorAgenda,
  employeeAgenda,
  isMiniCard,
  isFuture,
  onOpenCustomTopic,
}) => {
  const classes = useStyles();

  const handleOpenCustomTopic = useCallback(
    e => {
      e.stopPropagation();
      onOpenCustomTopic();
    },
    [onOpenCustomTopic]
  );

  return (
    <>
      <AgendaList
        title={translations.managerAgenda}
        items={moderatorAgenda}
        showNoResults
      />
      <div>
        <AgendaList
          title={translations.employeeAgenda}
          items={employeeAgenda}
        />
        {isMiniCard && isFuture && (
          <CustomButton
            className={classes.button}
            customIconWrapper={classes.icon}
            type="addRoundedBlue"
            onClick={handleOpenCustomTopic}
          >
            {isArrayEmpty(employeeAgenda)
              ? translations.addYourAgenda
              : translations.editYourAgenda}
          </CustomButton>
        )}
      </div>
    </>
  );
};

Agenda.defaultProps = {
  isMiniCard: false,
  isFuture: false,
  employeeAgenda: [],
  onOpenCustomTopic: () => {},
};

Agenda.propTypes = {
  translations: PropTypes.object.isRequired,
  moderatorAgenda: PropTypes.arrayOf(PropTypes.object).isRequired,
  employeeAgenda: PropTypes.arrayOf(PropTypes.object),
  isMiniCard: PropTypes.bool,
  isFuture: PropTypes.bool,
  onOpenCustomTopic: PropTypes.func,
};

export default Agenda;
