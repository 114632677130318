import { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import ArrowTooltip from 'components/shared/arrowTooltip';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    backgroundColor: primary.bluish9,
    boxSizing: 'border-box',
    position: 'sticky',
    left: 0,
    padding: spacing(4, 4, 4, 0),
    minHeight: 84,
    zIndex: 99,
    '&::after': {
      backgroundColor: primary.bluish9,
      content: '""',
      position: 'absolute',
      top: 0,
      right: -8,
      width: 8,
      height: '100%',
    },
  },
  question: {
    wordBreak: 'break-word',
  },
  averageScoreWrapper: {
    float: 'right',
  },
  averageScore: {
    backgroundColor: ({ attributeColor }) => attributeColor || primary.bluish1,
    display: 'flex',
    color: primary.white,
    borderRadius: '50%',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    height: 32,
    width: 32,
    fontSize: 14,
    userSelect: 'none',
  },
}));

const Question = ({
  question,
  hasAverageScore,
  questionAverage,
  attributeColor,
  translations,
}) => {
  const classes = useStyles({ attributeColor });
  const { text, order_id } = question;

  return (
    <div className={classes.root}>
      <div className={classes.averageScoreWrapper}>
        {hasAverageScore && (
          <ArrowTooltip
            position="top"
            tooltipLabel={translations.questionScore}
          >
            <Typography
              component="div"
              className={classNames(classes.averageScore)}
            >
              {questionAverage}
            </Typography>
          </ArrowTooltip>
        )}
      </div>
      <Typography className={classes.question} variant="subtitle2">
        {`${translations.questionShorthand}.${order_id} ${text}`}
      </Typography>
    </div>
  );
};

Question.defaultProps = {
  attributeColor: '',
};

Question.propTypes = {
  hasAverageScore: PropTypes.bool.isRequired,
  translations: PropTypes.object.isRequired,
  attributeColor: PropTypes.string,
  question: PropTypes.shape({}).isRequired,
  questionAverage: PropTypes.number.isRequired,
};

export default memo(Question);
