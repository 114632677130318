import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import { ReactComponent as RequestedIcon } from '../../../assets/icons/person-search.svg';
import { isDateInPast } from '../../../utility/dateUtils';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(1, 2),
    fontSize: 10,
    lineHeight: '12px',
    textTransform: 'uppercase',
    textAlign: 'center',
    width: 'fit-content',
    userSelect: 'none',
  },
  pending: {
    background: primary.orange,
    color: primary.white,
  },
  upcoming: {
    background: primary.bluish8,
    color: primary.blue1,
  },
  past: {
    background: primary.bluish1,
    color: primary.white,
  },
  requested: {
    background: primary.bluish8,
    color: primary.bluish1,
  },
  icon: {
    width: 16,
    height: 16,
    flexShrink: 0,
    marginRight: spacing(1),
  },
}));

const OneOnOneTimeIndicator = ({ labels, meetingTime, hasImpression }) => {
  const classes = useStyles();
  const isPastOneOnOne = isDateInPast(meetingTime);
  const isRequested = meetingTime === null;

  const status = useMemo(() => {
    if (isRequested) {
      return labels.requested;
    } else if (!isPastOneOnOne) {
      return labels.upcoming;
    } else if (isPastOneOnOne && hasImpression) {
      return labels.past;
    }
    return labels.pending;
  }, [labels, isRequested, isPastOneOnOne, hasImpression]);

  return (
    <div>
      <Typography
        className={classNames(classes.root, classes.pending, {
          [classes.requested]: isRequested,
          [classes.past]: isPastOneOnOne && hasImpression,
          [classes.upcoming]: meetingTime && !isPastOneOnOne,
        })}
        variant="overline"
        component="div"
      >
        {isRequested && <RequestedIcon className={classes.icon} />}
        {status}
      </Typography>
    </div>
  );
};

OneOnOneTimeIndicator.defaultProps = {
  hasImpression: false,
  meetingTime: null,
};

OneOnOneTimeIndicator.propTypes = {
  labels: PropTypes.object.isRequired,
  meetingTime: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  hasImpression: PropTypes.bool,
};

export default memo(OneOnOneTimeIndicator);
