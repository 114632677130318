import { memo } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup } from 'react-transition-group';
import { Fade, makeStyles } from '@material-ui/core';
import Note from './note';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'block',
    width: '100%',
  },
  noteWrapper: {
    marginBottom: spacing(4),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));

const NotesList = ({
  translations,
  currentUser,
  notes,
  isPrivateOnly,
  checkCanManageNote,
  checkIsDisabledEdit,
  canAccessUserProfile,
  checkCanManageTask,
  goToUserProfile,
  onTaskStatusChange,
  onEdit,
  onDelete,
}) => {
  const classes = useStyles();

  return (
    <TransitionGroup className={classes.root}>
      {notes.map(note => {
        const isCreatedByMe = note?.creator?.id === currentUser?.id;

        return (
          <Fade
            key={`note_${note.id}`}
            className={classes.noteWrapper}
            in
            unmountOnExit
          >
            <div>
              <Note
                translations={translations}
                note={note}
                isCreatedByMe={isCreatedByMe}
                isPrivateOnly={isPrivateOnly}
                checkCanManageNote={checkCanManageNote}
                checkIsDisabledEdit={checkIsDisabledEdit}
                canAccessUserProfile={canAccessUserProfile}
                goToUserProfile={goToUserProfile}
                checkCanManageTask={checkCanManageTask}
                onTaskStatusChange={onTaskStatusChange}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            </div>
          </Fade>
        );
      })}
    </TransitionGroup>
  );
};

NotesList.defaultProps = {
  isPrivateOnly: false,
  currentUser: null,
  checkCanManageTask: () => {},
  checkIsDisabledEdit: () => false,
  checkCanManageNote: () => true,
  goToUserProfile: () => {},
  canAccessUserProfile: () => false,
  onTaskStatusChange: () => {},
};

NotesList.propTypes = {
  translations: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  notes: PropTypes.arrayOf(PropTypes.object).isRequired,
  isPrivateOnly: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  checkCanManageNote: PropTypes.func,
  canAccessUserProfile: PropTypes.func,
  checkCanManageTask: PropTypes.func,
  checkIsDisabledEdit: PropTypes.func,
  goToUserProfile: PropTypes.func,
  onTaskStatusChange: PropTypes.func,
};

export default memo(NotesList);
