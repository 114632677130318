import { isArray, isArrayEmpty } from '../../../utility/helpers';

export const getSubMenuItems = (filters, selectedSubMenu) => {
  return filters.find(filter => filter.id === selectedSubMenu?.id)?.items;
};

export const shouldRenderSubMenu = (selectedSubMenu, subMenuItems) => {
  const hasItems = isArray(subMenuItems) && !isArrayEmpty(subMenuItems);
  if (selectedSubMenu?.isCategorized) {
    return (
      hasItems &&
      subMenuItems.some(
        item =>
          isArray(item[selectedSubMenu.categoryItemsKey]) &&
          !isArrayEmpty(item[selectedSubMenu.categoryItemsKey])
      )
    );
  }

  return hasItems;
};

export const hasSelectedFilters = selectedFilters =>
  Object.keys(selectedFilters).some(
    filterKey => !isArrayEmpty(selectedFilters[filterKey])
  );

export const resetFilters = selectedFilters =>
  Object.keys(selectedFilters).reduce((acc, filterKey) => {
    return { ...acc, [filterKey]: [] };
  }, {});

export const isDirty = (selectedFilters = [], tempFilters = []) =>
  selectedFilters.length !== tempFilters.length ||
  tempFilters.some(filter => !selectedFilters.includes(filter));
