import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography } from '@material-ui/core';
import classNames from 'classnames';
import CustomButton from '../customButton';
import Tooltip from '../tooltip';
import { ReactComponent as TooltipIcon } from '../../../assets/icons/tooltip.svg';

import { getColumnWidth, getAlignItem } from '../../../constants/gridTable';

const styles = ({ breakpoints, palette: { primary }, spacing }) => ({
  table: {
    display: 'grid',
  },
  header: {
    display: 'grid',
    boxSizing: 'border-box',
  },
  headerWithSubHeaders: {
    gridColumnGap: 4,
  },
  subheaders: {
    display: 'grid',
    boxSizing: 'border-box',
  },
  headerItem: {
    '&:first-of-type': {
      '& $headerCell:first-of-type': {
        borderRadius: '4px 0 0 0',
      },
      '& $subheaderCell:first-of-type': {
        borderRadius: '0 0 0 4px',
      },
    },
    '&:last-of-type': {
      '& $headerCell:last-of-type': {
        borderRadius: '0 4px 0 0',
      },
      '& $subheaderCell:last-of-type': {
        borderRadius: '0 0 4px 0',
      },
    },
  },
  headerCell: {
    boxSizing: 'border-box',
    backgroundColor: primary.bluish7,
    lineHeight: '12px',
    padding: spacing(3, 1.5),
    minHeight: 36,
    [breakpoints.up('xLg')]: {
      padding: spacing(3, 2),
    },
  },
  headerWithTooltip: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  headerTooltipIcon: {
    marginLeft: spacing(1.5),
    width: 12,
    height: 12,
    [breakpoints.up('xLg')]: {
      marginLeft: spacing(2),
    },
  },
  subheaderCell: {
    backgroundColor: primary.bluish8,
  },
  headerCellWithoutSubCells: {
    '&:first-of-type': {
      borderRadius: '4px 0 0 4px',
    },
    '&:last-of-type': {
      borderRadius: '0 4px 4px 0',
    },
  },
  row: {
    borderBottom: `1px solid ${primary.bluish7}`,
    display: 'grid',
    alignContent: 'center',
    minHeight: 48,
    padding: spacing(4, 0),
    transition: 'background-color .3s ease',
    '&:hover': {
      backgroundColor: primary.bluish9,
    },
  },
  rowWithSubRows: {
    gridColumnGap: 4,
  },
  subRow: {
    display: 'grid',
  },
  rowItem: {
    alignSelf: 'center',
    boxSizing: 'border-box',
    padding: spacing(0, 1.5),
    [breakpoints.up('xLg')]: {
      padding: spacing(0, 2),
    },
  },
  highlighted: {
    fontFamily: 'ProximaNova-Bold',
  },
  warning: {
    color: primary.red2,
  },
  disabled: {
    color: primary.bluish4,
  },
  loadMore: {
    border: `1px solid ${primary.bluish3}`,
    borderRadius: 44,
    color: primary.bluish4,
    lineHeight: '24px',
    justifySelf: 'center',
    padding: spacing(1, 4),
    maxHeight: 32,
    marginTop: spacing(4),
  },
});

class OverviewTable extends PureComponent {
  handleLoadMore = () => {
    const { name, onLoadMore } = this.props;

    return onLoadMore(name);
  };

  renderHeaderCell = (cell, isSubheaderCell = false) => {
    const { classes, hasSubHeaders } = this.props;

    return (
      <Typography
        key={`header_cell_${cell.id}${isSubheaderCell ? '_subHeader' : ''}`}
        variant="overline"
        component="div"
        className={classNames(classes.headerCell, {
          [classes.subheaderCell]: isSubheaderCell,
          [classes.headerCellWithoutSubCells]: !hasSubHeaders,
          [classes.headerWithTooltip]: cell.tooltipMessage,
        })}
        style={getAlignItem(cell)}
      >
        {cell.title || ''}
        {cell.tooltipMessage && (
          <Tooltip
            customIconClass={classes.headerTooltipIcon}
            text={cell.tooltipMessage}
            icon={TooltipIcon}
          />
        )}
      </Typography>
    );
  };

  renderRowCell = (row, header) => {
    const { classes } = this.props;

    return (
      <Typography
        key={`row_cell_${row.id}_column_${header.id}`}
        variant="body2"
        component="div"
        className={classNames(
          classes.rowItem,
          {
            [classes.highlighted]: header.isHighlighted,
            [classes.warning]: header.isWarning,
            [classes.disabled]: header.isDisabled,
          },
          header.cellClass
        )}
        style={getAlignItem(header)}
      >
        {row[header.dataKey]}
      </Typography>
    );
  };

  renderHeaderItem = headerItem => {
    const { classes, hasSubHeaders } = this.props;

    return hasSubHeaders ? (
      <div
        key={`header_with_subheaders_${headerItem.id}`}
        className={classes.headerItem}
      >
        {this.renderHeaderCell(headerItem)}
        <div
          className={classes.subheaders}
          style={getColumnWidth(headerItem.subheaders)}
        >
          {headerItem.subheaders.map(subheader =>
            this.renderHeaderCell(subheader, true)
          )}
        </div>
      </div>
    ) : (
      this.renderHeaderCell(headerItem, true)
    );
  };

  renderRowItem = (row, header) => {
    const { classes, hasSubHeaders } = this.props;

    return hasSubHeaders ? (
      <div
        key={`row_subRow_cell_${row.id}_column_${header.id}`}
        className={classes.subRow}
        style={getColumnWidth(header.subheaders)}
      >
        {header.subheaders.map(subheader => this.renderRowCell(row, subheader))}
      </div>
    ) : (
      this.renderRowCell(row, header)
    );
  };

  render() {
    const {
      className,
      classes,
      headers,
      rows,
      showLoadMore,
      buttonLabel,
      hasSubHeaders,
    } = this.props;

    return (
      <div className={classNames(classes.table, className)}>
        <div
          className={classNames(classes.header, {
            [classes.headerWithSubHeaders]: hasSubHeaders,
          })}
          style={getColumnWidth(headers)}
        >
          {headers.map(header => this.renderHeaderItem(header))}
        </div>
        {rows.map((row, index) => (
          <div
            key={`row_${row.id}_${index}`}
            className={classNames(classes.row, {
              [classes.rowWithSubRows]: hasSubHeaders,
            })}
            style={getColumnWidth(headers)}
          >
            {headers.map(h => this.renderRowItem(row, h))}
          </div>
        ))}
        {showLoadMore && (
          <CustomButton
            className={classes.loadMore}
            type="addWithTextRounded"
            onClick={this.handleLoadMore}
          >
            {buttonLabel}
          </CustomButton>
        )}
      </div>
    );
  }
}

OverviewTable.defaultProps = {
  className: null,
  buttonLabel: '',
  name: '',
  hasSubHeaders: false,
  showLoadMore: false,
  onLoadMore: () => {},
};

OverviewTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  buttonLabel: PropTypes.string,
  name: PropTypes.string,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      cellClass: PropTypes.string,
      id: PropTypes.number.isRequired,
      title: PropTypes.string,
      dataKey: PropTypes.string,
      align: PropTypes.string,
      isHighlighted: PropTypes.bool,
      minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      subheaders: PropTypes.arrayOf(
        PropTypes.shape({
          cellClass: PropTypes.string,
          id: PropTypes.number.isRequired,
          title: PropTypes.string,
          dataKey: PropTypes.string,
          align: PropTypes.string,
          isHighlighted: PropTypes.bool,
          isWarning: PropTypes.bool,
          isDisabled: PropTypes.bool,
          minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        })
      ),
    })
  ).isRequired,
  hasSubHeaders: PropTypes.bool,
  onLoadMore: PropTypes.func,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  showLoadMore: PropTypes.bool,
};

export default withStyles(styles)(OverviewTable);
