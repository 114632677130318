import { createAction, handleActions } from 'redux-actions';

// ------------------------------------
// Constants
// ------------------------------------

const SET_AUTH = 'SET_AUTH';
const UPDATE_CURRENT_USER_INFO = 'UPDATE_CURRENT_USER_INFO';

const initialState = {
  auth: {},
};

// ------------------------------------
// Actions
// ------------------------------------
export const setAuthInfo = createAction(SET_AUTH);
export const updateCurrentUserInfo = createAction(UPDATE_CURRENT_USER_INFO);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_AUTH]: (state, { payload }) => {
    return { ...state, auth: payload };
  },
  [UPDATE_CURRENT_USER_INFO]: (state, { payload }) => {
    return {
      ...state,
      auth: { ...state.auth, ...payload },
    };
  },
};

export default handleActions(reducers, initialState);
