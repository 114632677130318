import { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { getAnswerWeight } from 'utility/attribute';
import { NEUTRAL_ANSWER_WEIGHT } from 'components/shared/questionEntry/config';
import DraggableScroll from '../../../draggableScroll';
import HorizontalAnswer from '../horizontalAnswer';
import { updateScrollPosition } from '../../../../../utility/fillSurveyUtils';

const styles = ({ breakpoints, spacing }) => ({
  answersScroll: {
    display: 'flex',
  },
  groupReviewAnswersScroll: {
    margin: spacing(0, 0, 2.5, 4),
    [breakpoints.up('sm')]: {
      '&::-webkit-scrollbar-track': {
        marginRight: spacing(4),
      },
    },
    '&:after': {
      content: '""',
      flex: '0 0 16px',
    },
  },
  answerWrapper: {
    marginRight: spacing(2),
    display: 'grid',
    justifyContent: 'flex-start',
    '&:last-child': {
      marginRight: 0,
    },
  },
  answerWeight: {
    justifyContent: 'center',
    display: 'flex',
    fontSize: 12,
    fontFamily: 'ProximaNova-Regular',
    marginTop: spacing(3),
  },
});

class HorizontalAnswersGroup extends PureComponent {
  constructor(props) {
    super(props);

    this.draggableScrollRef = createRef();
  }

  componentDidMount() {
    updateScrollPosition(this.draggableScrollRef.current, false);
  }

  componentDidUpdate(prevProps) {
    const { questionId } = this.props;

    if (prevProps.questionId !== questionId) {
      updateScrollPosition(this.draggableScrollRef.current, false);
    }
  }

  handleSelect = (answerId, user) => () => {
    const { questionId, isDisabled, onSelect } = this.props;

    if (!isDisabled) {
      onSelect(questionId, answerId, user);
    }
  };

  render() {
    const {
      classes,
      subject,
      neutralAnswerLabel,
      isSingleReview,
      isDisabled,
      answers,
      selection,
      getSelectedAnswer,
      isAttributePreview,
    } = this.props;

    return (
      <DraggableScroll
        ref={this.draggableScrollRef}
        className={classNames(classes.answersScroll, {
          [classes.groupReviewAnswersScroll]: !isSingleReview,
        })}
        showHorizontalScrollbar
      >
        <>
          {answers.map(answer => {
            const isSelected = getSelectedAnswer(selection, answer.id, subject);
            const answerWeight = getAnswerWeight(answer.weight);

            return (
              <div
                key={`answer_item_${answer.id}`}
                className={classes.answerWrapper}
              >
                <HorizontalAnswer
                  neutralAnswerLabel={neutralAnswerLabel}
                  numberOfAnswers={answers.length}
                  isSelected={isSelected}
                  isDisabled={isDisabled}
                  answer={answer}
                  onSelect={this.handleSelect(answer.id, subject)}
                />
                {isAttributePreview && (
                  <div className={classes.answerWeight}>
                    {answerWeight !== NEUTRAL_ANSWER_WEIGHT
                      ? getAnswerWeight(answer.weight)
                      : ''}
                  </div>
                )}
              </div>
            );
          })}
        </>
      </DraggableScroll>
    );
  }
}

HorizontalAnswersGroup.defaultProps = {
  subject: {},
  isSingleReview: false,
  isDisabled: false,
  isAttributePreview: false,
  questionId: 0,
  selection: {},
  getSelectedAnswer: () => {},
  onSelect: () => {},
};

HorizontalAnswersGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  answers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  questionId: PropTypes.number,
  subject: PropTypes.shape({}),
  isSingleReview: PropTypes.bool,
  isDisabled: PropTypes.bool,
  neutralAnswerLabel: PropTypes.string.isRequired,
  selection: PropTypes.shape({}),
  getSelectedAnswer: PropTypes.func,
  onSelect: PropTypes.func,
  isAttributePreview: PropTypes.bool,
};

export default withStyles(styles)(HorizontalAnswersGroup);
