import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import SectionTitle from '../sectionTitle';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  detailsTitle: {
    lineHeight: '22px',
    marginBottom: spacing(1),
  },
  titleDescription: {
    paddingBottom: spacing(5),
  },
  detailsHeader: {
    backgroundColor: primary.bluish8,
    borderRadius: 4,
    display: 'grid',
    gridColumnGap: 8,
    gridTemplateColumns: '75px 1fr 120px',
    padding: spacing(3, 2),
  },
  timeline: {
    gridTemplateColumns: '75px 1fr 135px',
  },
  noExpansion: {
    gridTemplateColumns: '75px 1fr 90px',
  },
  headerItem: {
    lineHeight: '12px',
  },
}));

const InsightsDetailsHeader = ({
  title,
  description,
  columns,
  isTimeline,
  hasExpansionPanel,
}) => {
  const classes = useStyles();

  return (
    <>
      <SectionTitle
        textClassName={classes.detailsTitle}
        title={title}
        variant="h5"
      />
      <Typography className={classes.titleDescription} variant="body2">
        {description}
      </Typography>
      <div
        className={classNames(classes.detailsHeader, {
          [classes.timeline]: isTimeline,
          [classes.noExpansion]: isTimeline && !hasExpansionPanel,
        })}
      >
        {columns.map(({ label }, index) => {
          return (
            <div key={`${label}-${index}`}>
              <Typography variant="overline" className={classes.headerItem}>
                {label}
              </Typography>
            </div>
          );
        })}
      </div>
    </>
  );
};

InsightsDetailsHeader.defaultProps = {
  isTimeline: false,
  hasExpansionPanel: false,
};

InsightsDetailsHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isTimeline: PropTypes.bool,
  hasExpansionPanel: PropTypes.bool,
};

export default memo(InsightsDetailsHeader);
