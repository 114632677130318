import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { isObject } from '../../../utility/helpers';

const VISIBLE_TEXT_POSITION = 0;
const THREE_DOTS = '...';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    width: '100%',
  },
  mainReadMore: {
    flexDirection: 'row',
  },
  content: {
    maxWidth: 937,
  },
  contentExpanded: {
    maxWidth: '100%',
  },
  readMoreLessButton: {
    color: primary.blue2,
    marginLeft: spacing(1),
  },
  readLessButton: {
    margin: 0,
  },
}));

const ReadLessMore = ({
  translations,
  children,
  isReadMore,
  toggleReadMore,
  maxWordsCount,
}) => {
  const classes = useStyles();

  const handleReadMoreLessContent = innerContent => {
    if (isReadMore) {
      // Component wrapping over multiple elements
      /*
        <ReadMoreLessComponent>
          <p>...</p>
          <p>...</p>
        </ReadMoreLessComponent>
      */
      if (Array.isArray(innerContent)) {
        const { props } = innerContent[VISIBLE_TEXT_POSITION];

        return `${[props.children.slice(0, maxWordsCount)]}${
          props.children.length > maxWordsCount ? THREE_DOTS : ''
        }`;
      } else if (isObject(innerContent)) {
        // Component wrapping one element
        /*
          <ReadMoreLessComponent>
            <p>...</p>
          </ReadMoreLessComponent>
        */
        const { props } = innerContent;
        const wordsCounter = props.children.split(' ');
        if (wordsCounter >= maxWordsCount) {
          return props.children;
        }
        return `${props.children.slice(0, maxWordsCount)}${THREE_DOTS}`;
      } else if (typeof innerContent === 'string') {
        // Component wrapping text (string)
        /*
          <ReadMoreLessComponent>
            {text}
          </ReadMoreLessComponent>
        */
        const wordsCounter = innerContent.split(' ');
        if (wordsCounter >= maxWordsCount) {
          return innerContent;
        }
        return `${innerContent.slice(0, maxWordsCount)}${THREE_DOTS}`;
      }
    }
    return innerContent;
  };

  return (
    <div
      className={classNames(classes.main, {
        [classes.mainReadMore]: isReadMore,
      })}
      onClick={toggleReadMore}
    >
      <Typography
        variant="body2"
        component="div"
        className={classNames(classes.content, {
          [classes.contentExpanded]: !isReadMore,
        })}
      >
        {handleReadMoreLessContent(children)}
      </Typography>
      <Typography
        variant="subtitle2"
        className={classNames(classes.readMoreLessButton, {
          [classes.readLessButton]: !isReadMore,
        })}
      >
        {translations.read} {isReadMore ? translations.more : translations.less}
      </Typography>
    </div>
  );
};

ReadLessMore.defaultProps = {
  maxWordsCount: 132,
};

ReadLessMore.propTypes = {
  translations: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  maxWordsCount: PropTypes.number,
  isReadMore: PropTypes.bool.isRequired,
  toggleReadMore: PropTypes.func.isRequired,
};
export default ReadLessMore;
