import {
  useState,
  useCallback,
  useMemo,
  useEffect,
  memo,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Fade, makeStyles } from '@material-ui/core';
import { getSurveyType, presetPeriod } from 'utility/presets';
import CompareFilters from './compareFilters';
import CompareScores from './compareScores';
import AssignUsersDialog from '../assignUsersDialog';
import { getGroupedSurveyOptions } from '../../../utility/reportUtils';
import {
  getObjectToNumberArray,
  isArrayEmpty,
  isValueInList,
  removeObjectFromArray,
} from '../../../utility/helpers';
import { getStartEndOfDayUtc } from '../../../utility/dateUtils';
import { getLast12Months } from '../customDateRange/config';
import {
  GROUPED_SURVEY_REPORTING_TYPES,
  GROUPED_SURVEY_TYPES_KEYS,
} from '../../../constants/survey';
import { PARAMS } from '../../../constants/pages';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  contentRoot: {
    boxSizing: 'border-box',
    border: `1px solid ${primary.bluish7}`,
    borderRadius: 4,
    display: 'grid',
    gridTemplateColumns: 'minmax(0,1fr)',
    padding: spacing(4),
    width: '50%',
  },
  contentRootFullWidth: {
    gridTemplateColumns: 'repeat(2, minmax(0,1fr))',
    gridColumnGap: 32,
    width: '100%',
  },
  createdForWrapper: {
    position: 'relative',
    padding: spacing(4),
  },
  createdForWrapperDivider: {
    '&::before': {
      content: '""',
      position: 'absolute',
      width: 1,
      height: '100%',
      top: 0,
      right: -1,
      borderRight: `1px dotted ${primary.bluish7}`,
    },
  },
  compareUserWrapper: {
    padding: spacing(4),
  },
  avatarWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
    maxWidth: 'calc(100% - 34px)',
  },
  userWrapper: {
    justifyContent: 'flex-start',
    width: '100%',
  },
  userLabel: {
    fontFamily: 'ProximaNova-Bold',
    fontSize: 16,
    lineHeight: '20px',
    color: primary.blue1,
  },
  userCaption: {
    maxWidth: 'calc(100% - 56px)',
  },
  field: {
    marginTop: spacing(6),
    width: '100%',
    maxWidth: 300,
  },
  peoplePicker: {
    width: '100%',
    maxWidth: '100%',
  },
  selectedReviewerWrapper: {
    maxWidth: 380,
  },
  topSpacing: {
    marginTop: spacing(4),
  },
  syncFiltersButton: {
    position: 'absolute',
    right: 16,
    top: 25,
  },
  noAttributes: {
    marginTop: spacing(6),
  },
}));

const UserCompare = forwardRef(
  (
    {
      translations,
      createdForUser,
      allUsers,
      allEmployees,
      initialReviewers,
      initialSurveyType,
      initialPreset,
      userFilters,
      onGetAttributeScores,
      onGetReviewers,
      onGoToProfilePage,
      checkValueInPresets,
      onCompareWithDisabledPresetMessage,
      onCreatedForDisabledPresetMessage,
    },
    ref
  ) => {
    const [PeerTeamAndDirect] = GROUPED_SURVEY_REPORTING_TYPES;
    const surveyTypes = getGroupedSurveyOptions(translations.surveyOptions);

    const classes = useStyles();

    const createdFor =
      initialPreset && initialPreset?.data?.createdFor
        ? initialPreset.data.createdFor
        : null;
    const compareWith =
      initialPreset && initialPreset?.data?.compareWith
        ? initialPreset.data.compareWith
        : null;

    const getFullUserObject = useCallback(
      users =>
        users ? allUsers.filter(user => isValueInList(users, user.id)) : null,
      [allUsers]
    );
    const [createdForPreset, setCreatedForPreset] = useState(createdFor);
    const [compareWithPreset, setCompareWithPreset] = useState(compareWith);
    const [isCreatedForAttributesLoading, setIsCreatedForAttributesLoading] =
      useState(!isArrayEmpty(initialReviewers));
    const [createdForAttributes, setCreatedForAttributes] = useState([]);
    const [compareWithAttributes, setCompareWithAttributes] = useState([]);
    const [createdForSurveyType, setCreatedForSurveyType] = useState(
      getSurveyType(GROUPED_SURVEY_REPORTING_TYPES, createdFor?.survey_type) ||
        initialSurveyType ||
        PeerTeamAndDirect
    );
    const [createdForPeriod, setCreatedForPeriod] = useState(
      presetPeriod(createdForPreset) || getLast12Months()
    );
    const [createdForReviewers, setCreatedForReviewers] = useState(
      getFullUserObject(createdForPreset?.reviewer) || initialReviewers
    );
    const [compareWithSurveyType, setCompareWithSurveyType] = useState(
      getSurveyType(GROUPED_SURVEY_REPORTING_TYPES, compareWith?.survey_type) ||
        PeerTeamAndDirect
    );
    const [compareWithPeriod, setCompareWithPeriod] = useState(
      presetPeriod(compareWithPreset) || getLast12Months()
    );
    const [compareWithReviewers, setCompareWithReviewers] = useState(
      getFullUserObject(compareWith?.reviewer) || []
    );
    const [compareWithUser, setCompareWithUser] = useState(
      compareWithPreset?.subject
    );
    const [isAddCompareOpened, setIsAddCompareOpened] = useState(false);
    const [hasCreatedForRestore, setCreatedForRestore] = useState(false);
    const [hasCompareWithRestore, setCompareWithRestore] = useState(false);

    const isCreatedForSelf =
      createdForSurveyType.key === GROUPED_SURVEY_TYPES_KEYS.SELF_ASSESSMENT;
    const isCompareWithSelf =
      compareWithSurveyType.key === GROUPED_SURVEY_TYPES_KEYS.SELF_ASSESSMENT;
    const createdForParams = useMemo(() => {
      const { SURVEY_TYPE, START, END, START_AT, END_AT, SUBJECT } = PARAMS;
      const reviewer = getObjectToNumberArray(createdForReviewers);

      return {
        [SURVEY_TYPE]: createdForSurveyType?.values,
        [START_AT]: createdForPreset
          ? createdForPreset.from_date
          : getStartEndOfDayUtc(createdForPeriod[START]),
        [END_AT]: createdForPreset
          ? createdForPreset.to_date
          : getStartEndOfDayUtc(createdForPeriod[END], true),
        [SUBJECT]: createdForUser?.id,
        ...(isCreatedForSelf ? {} : { reviewer }),
      };
    }, [
      createdForPreset,
      createdForUser,
      createdForReviewers,
      createdForSurveyType,
      createdForPeriod,
      isCreatedForSelf,
    ]);

    const compareWithParams = useMemo(() => {
      const { SURVEY_TYPE, START, END, START_AT, END_AT, SUBJECT } = PARAMS;
      const reviewer = getObjectToNumberArray(compareWithReviewers);

      return {
        [SURVEY_TYPE]: compareWithSurveyType?.values,
        [START_AT]: compareWithPreset
          ? compareWithPreset.from_date
          : getStartEndOfDayUtc(compareWithPeriod[START]),
        [END_AT]: compareWithPreset
          ? compareWithPreset.to_date
          : getStartEndOfDayUtc(compareWithPeriod[END], true),
        [SUBJECT]: compareWithUser?.id,
        ...(isCompareWithSelf ? {} : { reviewer }),
      };
    }, [
      compareWithPreset,
      compareWithUser,
      compareWithReviewers,
      compareWithSurveyType,
      compareWithPeriod,
      isCompareWithSelf,
    ]);

    useImperativeHandle(ref, () => ({
      getCreatedForData() {
        return { ...createdForParams, subject: createdForUser };
      },
      getCompareWithData() {
        return { ...compareWithParams, subject: compareWithUser };
      },
      updateCreatedForPreset(data) {
        setCreatedForPreset(data);
        if (data) {
          const surveyType = getSurveyType(
            GROUPED_SURVEY_REPORTING_TYPES,
            data?.survey_type
          );
          setCreatedForReviewers(getFullUserObject(data?.reviewer) || []);
          setCreatedForPeriod(data ? presetPeriod(data) : getLast12Months());
          setCreatedForSurveyType(surveyType);
        }
      },
      updateCompareWithPreset(data) {
        setCompareWithPreset(data);
        if (data) {
          if (data?.subject) {
            const surveyType = getSurveyType(
              GROUPED_SURVEY_REPORTING_TYPES,
              data?.survey_type
            );
            setCompareWithReviewers(getFullUserObject(data?.reviewer) || []);
            setCompareWithUser(data?.subject);
            setCompareWithPeriod(data ? presetPeriod(data) : getLast12Months());
            setCompareWithSurveyType(surveyType);
          } else {
            setCompareWithUser(null);
            setCompareWithSurveyType(PeerTeamAndDirect);
            setCompareWithPeriod(getLast12Months());
            setCompareWithReviewers([]);
            setCompareWithAttributes([]);
          }
        }
      },
    }));

    const handleCreatedForSurveyTypeChange = selectedSurveyType => {
      const isSelfCompare =
        selectedSurveyType.key === GROUPED_SURVEY_TYPES_KEYS.SELF_ASSESSMENT;
      const tempParams = {
        ...createdForParams,
        survey_type: selectedSurveyType?.values,
        reviewer: isSelfCompare
          ? []
          : getObjectToNumberArray(createdForReviewers),
      };
      setCreatedForSurveyType(selectedSurveyType);
      onCreatedForDisabledPresetMessage(tempParams);
      checkValueInPresets(tempParams, compareWithParams);
    };

    const handleCreatedForPeriodChange = newRange => {
      const { START, END } = PARAMS;
      setCreatedForPeriod(newRange);
      checkValueInPresets(
        {
          ...createdForParams,
          from_date: getStartEndOfDayUtc(newRange[START]),
          to_date: getStartEndOfDayUtc(newRange[END], true),
        },
        compareWithParams
      );
    };

    const handleCreatedForReviewersChange = useCallback(
      selectedUsers => {
        const tempParams = {
          ...createdForParams,
          reviewer: getObjectToNumberArray(selectedUsers),
        };
        setCreatedForReviewers(selectedUsers);
        onCreatedForDisabledPresetMessage(tempParams);
        checkValueInPresets(tempParams, compareWithParams);
      },
      [
        createdForParams,
        checkValueInPresets,
        compareWithParams,
        onCreatedForDisabledPresetMessage,
      ]
    );

    const handleCompareWithSurveyTypeChange = selectedSurveyType => {
      const isSelfCompare =
        selectedSurveyType.key === GROUPED_SURVEY_TYPES_KEYS.SELF_ASSESSMENT;
      const tempParams = {
        ...compareWithParams,
        survey_type: selectedSurveyType?.values,
        reviewer: isSelfCompare
          ? []
          : getObjectToNumberArray(compareWithReviewers),
      };
      setCompareWithSurveyType(selectedSurveyType);
      onCompareWithDisabledPresetMessage(tempParams);
      checkValueInPresets(createdForParams, tempParams);
    };

    const handleCompareWithPeriodChange = newRange => {
      const { START, END } = PARAMS;
      setCompareWithPeriod(newRange);
      checkValueInPresets(createdForParams, {
        ...compareWithParams,
        from_date: getStartEndOfDayUtc(newRange[START]),
        to_date: getStartEndOfDayUtc(newRange[END], true),
      });
    };

    const handleCompareWithReviewersChange = useCallback(
      selectedUsers => {
        const tempParams = {
          ...compareWithParams,
          reviewer: getObjectToNumberArray(selectedUsers),
        };
        setCompareWithReviewers(selectedUsers);
        onCompareWithDisabledPresetMessage(tempParams);
        checkValueInPresets(createdForParams, tempParams);
      },
      [
        createdForParams,
        checkValueInPresets,
        compareWithParams,
        onCompareWithDisabledPresetMessage,
      ]
    );

    const toggleAddCompareDialog = () =>
      setIsAddCompareOpened(prevOpened => !prevOpened);

    const handleSelectCompareWith = selectedUser => {
      const tempParams = {
        ...compareWithParams,
        subject: selectedUser,
      };
      setCompareWithUser(selectedUser);
      onCompareWithDisabledPresetMessage(tempParams);
      checkValueInPresets(createdForParams, tempParams);
      toggleAddCompareDialog();
    };

    const handleSyncFilters = () => {
      const { START, END } = PARAMS;

      setCompareWithSurveyType(createdForSurveyType);
      setCompareWithPeriod(createdForPeriod);
      setCompareWithReviewers(createdForReviewers);
      checkValueInPresets(createdForParams, {
        ...compareWithParams,
        survey_type: createdForSurveyType.values,
        from_date: createdForPeriod[START],
        to_date: createdForPeriod[END],
        reviewer: getObjectToNumberArray(createdForReviewers),
      });
    };

    const handleRemoveCompareWithUser = () => {
      setCompareWithUser(null);
      setCompareWithSurveyType(PeerTeamAndDirect);
      setCompareWithPeriod(getLast12Months());
      setCompareWithReviewers([]);
      setCompareWithAttributes([]);
      onCreatedForDisabledPresetMessage(createdForParams);
      onCompareWithDisabledPresetMessage(null);
      checkValueInPresets(createdForParams, null);
    };

    const handleRemoveAttribute = useCallback(
      (isCreatedFor = false) =>
        attribute => {
          if (isCreatedFor) {
            setCreatedForRestore(true);
            return setCreatedForAttributes(
              removeObjectFromArray(createdForAttributes, attribute)
            );
          }

          setCompareWithRestore(true);
          return setCompareWithAttributes(
            removeObjectFromArray(compareWithAttributes, attribute)
          );
        },
      [compareWithAttributes, createdForAttributes]
    );

    const handleGetCreatedForAttributes = useCallback(async () => {
      const attributes = await onGetAttributeScores(
        createdForParams,
        isCreatedForSelf
      );

      setCreatedForAttributes(attributes);
      setCreatedForRestore(false);
      setIsCreatedForAttributesLoading(false);
    }, [createdForParams, isCreatedForSelf, onGetAttributeScores]);

    const handleGetCompareWithAttributes = useCallback(async () => {
      const attributes = await onGetAttributeScores(
        compareWithParams,
        isCompareWithSelf
      );

      setCompareWithAttributes(attributes);
      setCompareWithRestore(false);
    }, [compareWithParams, isCompareWithSelf, onGetAttributeScores]);

    const handleCreatedForRestore = () => handleGetCreatedForAttributes();

    const handleCompareWithRestore = () => handleGetCompareWithAttributes();

    const handleReorderCreatedForAttributes = attributes =>
      setCreatedForAttributes(attributes);

    const handleReorderCompareWithAttributes = attributes =>
      setCompareWithAttributes(attributes);

    useEffect(() => {
      handleGetCreatedForAttributes();
    }, [handleGetCreatedForAttributes]);

    useEffect(() => {
      handleGetCompareWithAttributes();
    }, [handleGetCompareWithAttributes]);

    return (
      <div>
        <div
          className={classNames(classes.contentRoot, {
            [classes.contentRootFullWidth]: !!compareWithUser,
          })}
        >
          <CompareFilters
            translations={translations}
            user={createdForUser}
            reviewersFilters={userFilters}
            surveyTypes={surveyTypes}
            allUsers={allUsers}
            surveyType={createdForSurveyType}
            period={createdForPeriod}
            reviewers={createdForReviewers}
            compareWithUser={compareWithUser}
            isSelfSurvey={isCreatedForSelf}
            onOpenAddCompare={toggleAddCompareDialog}
            onSurveyTypeChange={handleCreatedForSurveyTypeChange}
            onPeriodChange={handleCreatedForPeriodChange}
            onChangeReviewers={handleCreatedForReviewersChange}
            onGoToProfilePage={onGoToProfilePage}
            onGetReviewers={onGetReviewers}
            hasCompareWith
          />
          {compareWithUser && (
            <Fade in={!!compareWithUser} unmountOnExit>
              <div>
                <CompareFilters
                  translations={translations}
                  user={compareWithUser}
                  reviewersFilters={userFilters}
                  surveyTypes={surveyTypes}
                  allUsers={allUsers}
                  surveyType={compareWithSurveyType}
                  period={compareWithPeriod}
                  reviewers={compareWithReviewers}
                  attributes={compareWithAttributes}
                  isSelfSurvey={isCompareWithSelf}
                  onRemoveUser={handleRemoveCompareWithUser}
                  onSurveyTypeChange={handleCompareWithSurveyTypeChange}
                  onPeriodChange={handleCompareWithPeriodChange}
                  onChangeReviewers={handleCompareWithReviewersChange}
                  onSyncFilters={handleSyncFilters}
                  onGoToProfilePage={onGoToProfilePage}
                  onGetReviewers={onGetReviewers}
                />
              </div>
            </Fade>
          )}
          {!isCreatedForAttributesLoading && (
            <CompareScores
              translations={translations}
              attributes={createdForAttributes}
              hasDivider={!!compareWithUser}
              hasRestore={hasCreatedForRestore}
              onRemoveAttribute={handleRemoveAttribute(true)}
              onRestore={handleCreatedForRestore}
              onReorder={handleReorderCreatedForAttributes}
              isRemovable
              isDraggable
            />
          )}
          {compareWithUser && (
            <Fade in={!!compareWithUser} unmountOnExit>
              <div>
                <CompareScores
                  translations={translations}
                  attributes={compareWithAttributes}
                  hasRestore={hasCompareWithRestore}
                  onRemoveAttribute={handleRemoveAttribute()}
                  onRestore={handleCompareWithRestore}
                  onReorder={handleReorderCompareWithAttributes}
                  isRemovable
                  isDraggable
                />
              </div>
            </Fade>
          )}
        </div>
        <AssignUsersDialog
          translations={translations.addCompareWithDialog}
          isOpened={isAddCompareOpened}
          initialValue={compareWithUser}
          allUsers={allEmployees}
          onCancel={toggleAddCompareDialog}
          onSave={handleSelectCompareWith}
          shouldReturnOption
        />
      </div>
    );
  }
);

UserCompare.defaultProps = {
  initialReviewers: [],
  initialSurveyType: null,
  initialPreset: {},
};

UserCompare.propTypes = {
  translations: PropTypes.object.isRequired,
  allUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
  allEmployees: PropTypes.arrayOf(PropTypes.object).isRequired,
  initialReviewers: PropTypes.arrayOf(PropTypes.object),
  initialSurveyType: PropTypes.object,
  createdForUser: PropTypes.object.isRequired,
  userFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
  onGetAttributeScores: PropTypes.func.isRequired,
  onGoToProfilePage: PropTypes.func.isRequired,
  onGetReviewers: PropTypes.func.isRequired,
  onCompareWithDisabledPresetMessage: PropTypes.func.isRequired,
  onCreatedForDisabledPresetMessage: PropTypes.func.isRequired,
  checkValueInPresets: PropTypes.func.isRequired,
  initialPreset: PropTypes.object,
};

UserCompare.displayName = 'UserCompare';

export default memo(UserCompare);
