import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
} from '@material-ui/core';
import StatusIndicator from '../statusIndicator';
import RedirectLink from '../redirectLink';
import AttributeChip from '../attributeChip';
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down-dark.svg';
import { getPersonFullName } from '../../../utility/uiUtils';
import { getItemById, isArrayEmpty } from '../../../utility/helpers';
import { formatDate } from '../../../utility/dateUtils';
import { SURVEY_STATUS_TYPES } from '../../../constants/statuses';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    display: 'grid',
    gridColumnGap: 8,
    gridTemplateColumns: '75px 1fr 120px',
    padding: spacing(4, 2),
  },
  timeline: {
    gridTemplateColumns: '75px 1fr 135px',
  },
  rootNoExpansion: {
    gridTemplateColumns: '75px 1fr 90px',
  },
  lightColored: {
    color: primary.bluish4,
  },
  feedback: {
    width: '100%',
    wordBreak: 'break-word',
  },
  reviewer: {
    fontFamily: 'Proximanova-Bold',
  },
  expansionPanelRoot: {
    borderBottom: `1px solid ${primary.bluish7}`,
    boxShadow: 'none',
    width: '100%',
    pointerEvents: 'none',
  },
  expansionSummaryContent: {
    flexGrow: 1,
    alignItems: 'center',
    '&$expanded': {
      marginBottom: 0,
    },
  },
  expanded: {},
  expansionDetails: {
    flexDirection: 'column',
    pointerEvents: 'auto',
  },
  expansionDetailsItem: {
    gridTemplateColumns: '83px 1fr',
    gridColumnGap: 0,
    justifyContent: 'flex-end',
    padding: spacing(0, 2, 4, 2),
    '& $attributeWrapper': {
      borderTop: `1px dashed ${primary.bluish7}`,
    },
  },
  expandIcon: {
    width: 11,
    height: 7,
    pointerEvents: 'auto',
    '&.MuiIconButton-root': {
      position: 'absolute',
      right: 30,
    },
  },
  attributeWrapper: {
    display: 'flex',
    paddingTop: spacing(4),
    minWidth: 0,
  },
  placeholder: {
    marginRight: spacing(2),
  },
}));

const MONTH_DAY_FORMAT = 'MMM d';
const YEAR_FORMAT = 'yyyy';

const DetailItem = ({
  feedbackData,
  timelineItem,
  statuses,
  isTimeline,
  hasRedirectLink,
  getRedirectLinkPath,
}) => {
  const classes = useStyles();

  const renderCommentItem = () => {
    const { sent_at, comment, creator } = feedbackData;

    return (
      <div className={classes.root}>
        <div>
          <Typography variant="subtitle2">
            {formatDate(sent_at, MONTH_DAY_FORMAT)}
          </Typography>
          <Typography variant="subtitle2">
            {formatDate(sent_at, YEAR_FORMAT)}
          </Typography>
        </div>
        <div>
          <Typography variant="body2" className={classes.feedback}>
            {comment}
          </Typography>
        </div>
        <div>
          <Typography variant="body2" className={classes.reviewer}>
            {getPersonFullName(creator)}
          </Typography>
        </div>
      </div>
    );
  };

  const renderTimelineItem = () => {
    const { sent_at, title, id, status, attributes } = timelineItem;

    const hasAttributes = attributes && !isArrayEmpty(attributes);
    const { COMPLETED } = SURVEY_STATUS_TYPES;

    return (
      <div key={id}>
        <Accordion
          classes={{
            root: classes.expansionPanelRoot,
          }}
        >
          <AccordionSummary
            expandIcon={
              hasAttributes ? (
                <ArrowDownIcon className={classes.expandIcon} />
              ) : null
            }
            classes={{
              content: classNames(
                classes.root,
                classes.timeline,
                classes.expansionSummaryContent,
                { [classes.rootNoExpansion]: !hasAttributes }
              ),
              expanded: classes.expanded,
              expandIcon: classes.expandIcon,
            }}
          >
            <div>
              <Typography
                variant="subtitle2"
                className={classNames({
                  [classes.lightColored]: status === COMPLETED,
                })}
              >
                {formatDate(sent_at, MONTH_DAY_FORMAT)}
              </Typography>
              <Typography
                variant="subtitle2"
                className={classNames({
                  [classes.lightColored]: status === COMPLETED,
                })}
              >
                {formatDate(sent_at, YEAR_FORMAT)}
              </Typography>
            </div>
            <div>
              <RedirectLink
                label={title}
                isAccessible={hasRedirectLink}
                path={getRedirectLinkPath(timelineItem, hasRedirectLink)}
              />
            </div>
            <div>
              <StatusIndicator
                statuses={statuses}
                status={getItemById(statuses, status)}
              />
            </div>
          </AccordionSummary>
          {hasAttributes && (
            <AccordionDetails className={classes.expansionDetails}>
              {attributes.map(attribute => {
                return (
                  <div
                    key={attribute.name}
                    className={classNames(
                      classes.root,
                      classes.timeline,
                      classes.expansionDetailsItem
                    )}
                  >
                    <div />
                    <div className={classes.attributeWrapper}>
                      <AttributeChip
                        name={attribute.name}
                        color={attribute.color}
                        attribute={attribute}
                        isSelected
                      />
                    </div>
                  </div>
                );
              })}
            </AccordionDetails>
          )}
        </Accordion>
      </div>
    );
  };

  return isTimeline ? renderTimelineItem() : renderCommentItem();
};

DetailItem.defaultProps = {
  feedbackData: {},
  isTimeline: false,
  statuses: [],
  hasRedirectLink: false,
  timelineItem: {},
  getRedirectLinkPath: () => {},
};

DetailItem.propTypes = {
  feedbackData: PropTypes.object,
  isTimeline: PropTypes.bool,
  statuses: PropTypes.arrayOf(PropTypes.shape({})),
  hasRedirectLink: PropTypes.bool,
  timelineItem: PropTypes.object,
  getRedirectLinkPath: PropTypes.func,
};

export default memo(DetailItem);
