import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import fields from './fields';
import BackLink from '../backLink';
import AuthFormLayout from '../authFormLayout';
import { AUTOMATION_ID } from '../../../constants/automationId';
import { ReactComponent as TonalityIcon } from '../../../assets/icons/tonality.svg';

class LoginFormLayout extends PureComponent {
  render() {
    const { translations, history } = this.props;

    return (
      <AuthFormLayout
        {...this.props}
        subtitleVariant="body1"
        icon={TonalityIcon}
        fields={fields}
      >
        <BackLink
          id={AUTOMATION_ID.FORGOTTEN_PASSWORD_BUTTON}
          title={translations.backLink}
          history={history}
          to="/forgotten-password"
        />
      </AuthFormLayout>
    );
  }
}

LoginFormLayout.propTypes = {
  translations: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default LoginFormLayout;
