import { createAction, handleActions } from 'redux-actions';
import { parseDuplicateParameters } from 'utility/uiUtils';
import http from '../../utility/http';
import { getFormattedUsers, isObjectEmpty } from '../../utility/helpers';
import { setSectionLoader } from './loading';
import {
  API_COMPANY,
  API_COMPANY_METRICS,
  API_COMPANY_ACTION_PLANS_STATS,
  API_COMPANY_COURSES_STATS,
  API_COMPANY_ONE_ON_ONE_STATS,
  API_USERS_BASIC,
} from '../../constants/apiRoutes';
import { SECTION_LOADERS } from '../../constants/sectionLoaders';
import { PEOPLE_DEFAULT_ORDERING } from '../../constants/people';
// ------------------------------------
// Constants
// ------------------------------------

const SET_ACTION_PLANS_OVERVIEW = 'SET_ACTION_PLANS_OVERVIEW';
const SET_ONE_ON_ONE_OVERVIEW = 'SET_ONE_ON_ONE_OVERVIEW';
const SET_COURSES_OVERVIEW = 'SET_COURSES_OVERVIEW';
const SET_TEAMS_OVERVIEW = 'SET_TEAMS_OVERVIEW';
const LOAD_MORE_TEAMS_OVERVIEW = 'LOAD_MORE_TEAMS_OVERVIEW';
const SET_TEAMS_METRICS = 'SET_TEAMS_METRICS';
const LOAD_MORE_TEAMS_METRICS = 'LOAD_MORE_TEAMS_METRICS';
const SET_MODERATORS_WITH_TEAMS = 'SET_MODERATORS_WITH_TEAMS';

const initialState = {
  actionPlansOverview: null,
  coursesOverview: null,
  oneOnOneOverview: null,
  teamsOverview: {},
  teamsMetrics: {},
  moderatorsWithTeams: [],
  formattedModeratorsWithTeams: {},
};

// ------------------------------------
// Actions
// ------------------------------------

const setActionPlansOverview = createAction(SET_ACTION_PLANS_OVERVIEW);
const setOneOnOneOverview = createAction(SET_ONE_ON_ONE_OVERVIEW);
const setCoursesOverview = createAction(SET_COURSES_OVERVIEW);
const setTeamsOverview = createAction(SET_TEAMS_OVERVIEW);
const setTeamsOverviewLoadMore = createAction(LOAD_MORE_TEAMS_OVERVIEW);
const setTeamsMetrics = createAction(SET_TEAMS_METRICS);
const setTeamsMetricsLoadMore = createAction(LOAD_MORE_TEAMS_METRICS);
const setModeratorsWithTeams = createAction(SET_MODERATORS_WITH_TEAMS);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_ACTION_PLANS_OVERVIEW]: (state, { payload }) => {
    return { ...state, actionPlansOverview: payload };
  },
  [SET_ONE_ON_ONE_OVERVIEW]: (state, { payload }) => {
    return { ...state, oneOnOneOverview: payload };
  },
  [SET_COURSES_OVERVIEW]: (state, { payload }) => {
    return { ...state, coursesOverview: payload };
  },
  [SET_TEAMS_OVERVIEW]: (state, { payload }) => {
    const { isCleanup, ...rest } = payload;

    if (isCleanup) {
      return { ...state, teamsOverview: rest };
    }

    return {
      ...state,
      teamsOverview: payload,
    };
  },
  [LOAD_MORE_TEAMS_OVERVIEW]: (state, { payload }) => {
    return isObjectEmpty(payload)
      ? { ...state, teamsOverview: payload }
      : {
          ...state,
          teamsOverview: {
            ...payload,
            results: state.teamsOverview.results
              ? [...state.teamsOverview.results, ...payload.results]
              : [...payload.results],
          },
        };
  },
  [SET_TEAMS_METRICS]: (state, { payload }) => {
    const { isCleanup, ...rest } = payload;

    if (isCleanup) {
      return { ...state, teamsMetrics: rest };
    }

    return {
      ...state,
      teamsMetrics: payload,
    };
  },
  [LOAD_MORE_TEAMS_METRICS]: (state, { payload }) => {
    return isObjectEmpty(payload)
      ? { ...state, teamsMetrics: payload }
      : {
          ...state,
          teamsMetrics: {
            ...payload,
            results: state.teamsMetrics.results
              ? [...state.teamsMetrics.results, ...payload.results]
              : [...payload.results],
          },
        };
  },
  [SET_MODERATORS_WITH_TEAMS]: (state, { payload }) => {
    const allUsers = [
      {
        id: -1,
        first_name: 'All managers',
        last_name: '',
        shouldRemoveUserAvatar: true,
      },
    ];
    const formattedUsers = getFormattedUsers(payload);
    const managerIds = Object.keys(formattedUsers);
    const filteredManagers = payload.filter(user =>
      managerIds.includes(user.id.toString())
    );

    return {
      ...state,
      moderatorsWithTeams: allUsers.concat(filteredManagers),
      formattedModeratorsWithTeams: formattedUsers,
    };
  },
};

// ------------------------------------
// API calls
// ------------------------------------

export const getActionPlansOverview = (dispatch, params) => {
  dispatch(setSectionLoader(SECTION_LOADERS.ACTION_PLANS_OVERVIEW, true));

  http
    .get(API_COMPANY_ACTION_PLANS_STATS, {
      params,
      paramsSerializer: data => parseDuplicateParameters(data),
    })
    .then(({ data }) => {
      dispatch(setActionPlansOverview(data));
    })
    .finally(() => {
      dispatch(setSectionLoader(SECTION_LOADERS.ACTION_PLANS_OVERVIEW));
    });
};

export const getCoursesOverview = (dispatch, params) => {
  dispatch(setSectionLoader(SECTION_LOADERS.COURSES_OVERVIEW, true));
  http
    .get(API_COMPANY_COURSES_STATS, {
      params,
      paramsSerializer: data => parseDuplicateParameters(data),
    })
    .then(({ data }) => {
      dispatch(setCoursesOverview(data));
    })
    .finally(() => {
      dispatch(setSectionLoader(SECTION_LOADERS.COURSES_OVERVIEW));
    });
};

export const getOneOnOneOverview = (dispatch, params) => {
  dispatch(setSectionLoader(SECTION_LOADERS.ONE_ON_ONE_OVERVIEW, true));

  http
    .get(API_COMPANY_ONE_ON_ONE_STATS, {
      params,
      paramsSerializer: data => parseDuplicateParameters(data),
    })
    .then(({ data }) => {
      dispatch(setOneOnOneOverview(data));
    })
    .finally(() => {
      dispatch(setSectionLoader(SECTION_LOADERS.ONE_ON_ONE_OVERVIEW));
    });
};

export const getModeratorsWithTeams = dispatch => {
  return http
    .get(API_USERS_BASIC, {
      params: {
        ...PEOPLE_DEFAULT_ORDERING,
        who_reports: true,
      },
    })
    .then(({ data }) => {
      return dispatch(setModeratorsWithTeams(data));
    });
};

export const getTeamsOverview = (dispatch, params, isLoadMore = false) => {
  return http
    .get(API_COMPANY, {
      params,
      paramsSerializer: data => parseDuplicateParameters(data),
    })
    .then(({ data }) => {
      return dispatch(
        isLoadMore ? setTeamsOverviewLoadMore(data) : setTeamsOverview(data)
      );
    });
};

export const getTeamsMetrics = (dispatch, params, isLoadMore = false) => {
  return http
    .get(API_COMPANY_METRICS, {
      params,
      paramsSerializer: data => parseDuplicateParameters(data),
    })
    .then(({ data }) => {
      return dispatch(
        isLoadMore ? setTeamsMetricsLoadMore(data) : setTeamsMetrics(data)
      );
    });
};

export default handleActions(reducers, initialState);

// ------------------------------------
// "plain" action functions
// ------------------------------------

export const clearActionPlansOverview = () =>
  setActionPlansOverview(initialState.actionPlansOverview);
export const clearOneOnOneOverview = () =>
  setOneOnOneOverview(initialState.oneOnOneOverview);
export const clearCoursesOverview = () =>
  setCoursesOverview(initialState.coursesOverview);
export const clearTeamsOverview = () =>
  setTeamsOverview({ ...initialState.teamsOverview, isCleanup: true });
export const clearTeamsMetrics = () =>
  setTeamsMetrics({ ...initialState.teamsMetrics, isCleanup: true });
