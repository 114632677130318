import { memo } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup } from 'react-transition-group';
import { Fade, makeStyles } from '@material-ui/core';
import InsightsDetailsHeader from '../insightsDetailsHeader';
import DetailItem from '../detailItem';
import NotificationCard from '../notificationCard';
import { isArrayEmpty } from '../../../utility/helpers';

const useStyles = makeStyles(({ spacing }) => ({
  noData: {
    marginTop: spacing(4),
    padding: spacing(10, 14, 10),
  },
}));

const ReportDetailsTable = ({
  className,
  translations,
  columns,
  details,
  isTimeline,
  hasRedirectLinkCheck,
  getRedirectLinkPath,
  hasExpansionPanel,
  ...rest
}) => {
  const classes = useStyles();
  const hasDetails = details && !isArrayEmpty(details);

  return (
    <div className={className}>
      <InsightsDetailsHeader
        title={translations.title}
        description={translations.description}
        columns={columns}
        isTimeline={isTimeline}
        hasExpansionPanel={hasExpansionPanel}
      />
      {hasDetails ? (
        <TransitionGroup>
          {details.map((detailsItem, index) => {
            return (
              <Fade key={`details_item_${index}`} in unmountOnExit>
                <DetailItem
                  key={`detail_item_${index}`}
                  timelineItem={detailsItem}
                  hasRedirectLink={hasRedirectLinkCheck(detailsItem)}
                  isTimeline={isTimeline}
                  getRedirectLinkPath={getRedirectLinkPath}
                  {...rest}
                />
              </Fade>
            );
          })}
        </TransitionGroup>
      ) : (
        <NotificationCard
          className={classes.noData}
          content={translations.noData}
          shouldFade={!hasDetails}
        />
      )}
    </div>
  );
};

ReportDetailsTable.defaultProps = {
  className: undefined,
  details: [],
  isTimeline: false,
  hasExpansionPanel: false,
  hasRedirectLinkCheck: () => true,
  getRedirectLinkPath: () => {},
};

ReportDetailsTable.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  isTimeline: PropTypes.bool,
  hasExpansionPanel: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  details: PropTypes.arrayOf(PropTypes.object),
  hasRedirectLinkCheck: PropTypes.func,
  getRedirectLinkPath: PropTypes.func,
};

export default memo(ReportDetailsTable);
