import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import TextBoxWithTooltip from '../textBoxWithTooltip';

const useStyles = makeStyles(({ palette: { primary } }) => ({
  root: {
    display: 'block',
    textDecoration: 'none',
    maxWidth: '100%',
  },
  text: {
    color: primary.blue1,
    cursor: 'pointer',
  },
}));

const TextLink = ({ className, textClass, to, text, variant, ...rest }) => {
  const classes = useStyles();

  return (
    <Link className={classNames(classes.root, className)} to={to}>
      <TextBoxWithTooltip
        className={classNames(classes.text, textClass)}
        variant={variant}
        text={text}
        isClickable
        {...rest}
      />
    </Link>
  );
};

TextLink.defaultProps = {
  className: undefined,
  textClass: undefined,
  variant: 'body1',
};

TextLink.propTypes = {
  className: PropTypes.string,
  textClass: PropTypes.string,
  to: PropTypes.string.isRequired,
  variant: PropTypes.string,
  text: PropTypes.string.isRequired,
};

export default TextLink;
