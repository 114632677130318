import { memo, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup } from 'react-transition-group';
import { FormHelperText, Fade, makeStyles } from '@material-ui/core';
import CustomButton from '../customButton';
import TextBoxWithTooltip from '../textBoxWithTooltip';
import EnrollCourseDialog from '../enrollCourseDialog';
import ActionButton from '../actionButton';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';
import {
  getCourseLevels,
  prepareCoursesToEnroll,
} from '../../../utility/courseUtils';

const useStyles = makeStyles(({ spacing }) => ({
  selectedCourse: {
    display: 'flex',
    alignItems: 'center',
    height: 40,
  },
  deleteIconButton: {
    flexShrink: 0,
    marginLeft: spacing(2),
  },
}));

const AddCoursesField = ({
  className,
  translations,
  selectedCourses,
  courses,
  getCourses,
  getEnrolledUsers,
  onCoursesSearch,
  onChange,
}) => {
  const classes = useStyles();
  const [isEnrollCourseDialogOpened, setIsEnrollCourseDialogOpened] =
    useState(false);
  const courseLevels = useMemo(
    () => getCourseLevels(translations.levels).slice(1),
    [translations]
  );
  const coursesToEnroll = prepareCoursesToEnroll(courses);

  const handleCloseCourseDialog = () => setIsEnrollCourseDialogOpened(false);

  const handleOpenCourseDialog = () => setIsEnrollCourseDialogOpened(true);

  const handleCourseChange = course => {
    const isSelected = selectedCourses.some(
      selectedCourse => selectedCourse.id === course.id
    );
    if (!isSelected) {
      onChange(course);
    }

    if (isEnrollCourseDialogOpened) {
      handleCloseCourseDialog();
    }

    return Promise.resolve();
  };

  const handleRemoveCourse = course => () => onChange(course);

  const onOpenCourseDialog = () => {
    return getCourses().then(handleOpenCourseDialog);
  };

  return (
    <div className={className}>
      <div>
        <FormHelperText>{translations.label}</FormHelperText>
        <div>
          <CustomButton
            type="addDarkRoundedOutlined"
            onClick={onOpenCourseDialog}
          >
            {translations.addButton}
          </CustomButton>
        </div>
        <TransitionGroup>
          {selectedCourses.map(selectedCourse => (
            <Fade key={`selected_course_${selectedCourse.id}`} in unmountOnExit>
              <div className={classes.selectedCourse}>
                <TextBoxWithTooltip variant="h5" text={selectedCourse.title} />
                <ActionButton
                  className={classes.deleteIconButton}
                  type={ACTION_BUTTON_TYPES.DELETE}
                  onClickHandler={handleRemoveCourse(selectedCourse)}
                />
              </div>
            </Fade>
          ))}
        </TransitionGroup>
      </div>

      <EnrollCourseDialog
        translations={translations.enrollCourseDialog}
        isOpened={isEnrollCourseDialogOpened}
        levels={courseLevels}
        courses={coursesToEnroll}
        getCourseEnrolledUsers={getEnrolledUsers}
        onSearch={onCoursesSearch}
        onCancel={handleCloseCourseDialog}
        onEnroll={handleCourseChange}
      />
    </div>
  );
};

AddCoursesField.defaultProps = {
  className: undefined,
  selectedCourses: [],
  courses: [],
};

AddCoursesField.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  selectedCourses: PropTypes.arrayOf(PropTypes.object),
  courses: PropTypes.arrayOf(PropTypes.object),
  getCourses: PropTypes.func.isRequired,
  onCoursesSearch: PropTypes.func.isRequired,
  getEnrolledUsers: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default memo(AddCoursesField);
