import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Typography, IconButton, makeStyles } from '@material-ui/core';
import ArrowTooltip from '../arrowTooltip';
import { ReactComponent as NewTooltipIcon } from '../../../assets/icons/info.svg';

const useStyles = makeStyles(({ palette: { primary } }) => ({
  icon: { cursor: 'pointer' },
  tooltipText: {
    lineHeight: '20px',
    color: primary.bluish2,
  },
  link: {
    display: 'flex',
  },
}));

const Tooltip = ({
  customIconClass,
  text,
  position,
  isLink,
  linkProps,
  icon: Icon,
  isClickable,
  onIconClick,
  ...rest
}) => {
  const classes = useStyles();
  const iconClasses = classNames(classes.icon, customIconClass);

  const renderLabel = () => {
    return (
      <Typography variant="body2" className={classes.tooltipText}>
        {text}
      </Typography>
    );
  };

  const renderIcon = () => {
    if (Icon) {
      if (isLink) {
        return (
          <Link className={classes.link} {...linkProps}>
            <Icon className={iconClasses} />
          </Link>
        );
      }
      if (isClickable) {
        return (
          <IconButton className={iconClasses} onClick={onIconClick}>
            <Icon />
          </IconButton>
        );
      }

      return <Icon className={iconClasses} />;
    }

    return <NewTooltipIcon className={iconClasses} />;
  };

  return (
    <ArrowTooltip tooltipLabel={renderLabel()} position={position} {...rest}>
      {renderIcon()}
    </ArrowTooltip>
  );
};

Tooltip.defaultProps = {
  customIconClass: null,
  position: 'top',
  icon: null,
  linkProps: {},
  isClickable: false,
  isLink: false,
  onIconClick: () => {},
};

Tooltip.propTypes = {
  customIconClass: PropTypes.string,
  position: PropTypes.string,
  text: PropTypes.string.isRequired,
  icon: PropTypes.object,
  linkProps: PropTypes.object,
  isClickable: PropTypes.bool,
  isLink: PropTypes.bool,
  onIconClick: PropTypes.func,
};

export default memo(Tooltip);
