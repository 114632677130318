import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import ActionButton from '../../actionButton';
import { ReactComponent as RequestIcon } from '../../../../assets/icons/person-search.svg';
import { ACTION_BUTTON_TYPES } from '../../actionButton/config';
import { getEmployeeAgendaLabel } from '../config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    boxSizing: 'border-box',
    borderRadius: 4,
    border: `1px solid ${primary.blue5}`,
    transition: 'border .2s ease',
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr) 230px',
    width: '100%',
    '&:hover': {
      border: `1px solid ${primary.blue1}`,
    },
  },
  content: {
    boxSizing: 'border-box',
    borderRight: `1px dashed ${primary.bluish6}`,
    padding: spacing(4, 6, 4, 4),
  },
  actionsBar: {
    backgroundColor: primary.bluish8,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(2, 3),
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionButton: {
    marginLeft: spacing(1.5),
  },
  agenda: {
    marginTop: spacing(6),
  },
  agendaLabel: {
    color: primary.bluish3,
    marginBottom: spacing(1),
  },
  topic: {
    color: primary.bluish3,
  },
  sidebar: {
    boxSizing: 'border-box',
    padding: spacing(6, 5, 4, 5),
  },
  requestStatus: {
    border: `1px solid ${primary.bluish1}`,
    display: 'flex',
    alignItems: 'center',
    padding: spacing(1, 2),
    width: 'fit-content',
  },
  requestIcon: {
    marginRight: spacing(1),
  },
  statusText: {
    fontSize: 10,
    lineHeight: '12px',
    textTransform: 'uppercase',
  },
}));

const ModeratorCard = ({
  className,
  translations,
  request,
  onAcceptRequest,
  onDismissRequest,
}) => {
  const classes = useStyles();
  const { ACCEPT, CLOSE } = ACTION_BUTTON_TYPES;
  const [agenda] = request.agenda;
  const agendaLabel = getEmployeeAgendaLabel(
    translations.agenda,
    request.created_at
  );

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.content}>
        <div className={classes.actionsBar}>
          <Typography variant="subtitle2">
            {translations.scheduleMeeting}
          </Typography>
          <div className={classes.actionButtons}>
            <ActionButton
              className={classes.actionButton}
              type={ACCEPT}
              tooltipText={translations.actionButtons[ACCEPT.toLowerCase()]}
              onClickHandler={onAcceptRequest}
              isSquared
            />
            <ActionButton
              className={classes.actionButton}
              type={CLOSE}
              tooltipText={translations.actionButtons.dismiss}
              onClickHandler={() => onDismissRequest(request)}
              isSquared
            />
          </div>
        </div>
        <div className={classes.agenda}>
          <Typography className={classes.agendaLabel} variant="body2">
            {agendaLabel}
          </Typography>
          <Typography className={classes.topic} variant="subtitle2">
            {agenda.name}
          </Typography>
        </div>
      </div>
      <div className={classes.sidebar}>
        <div className={classes.requestStatus}>
          <RequestIcon className={classes.requestIcon} />
          <Typography className={classes.statusText} variant="overline">
            {translations.requested}
          </Typography>
        </div>
      </div>
    </div>
  );
};

ModeratorCard.defaultProps = {
  className: undefined,
};

ModeratorCard.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.shape({}).isRequired,
  request: PropTypes.shape({}).isRequired,
  onAcceptRequest: PropTypes.func.isRequired,
  onDismissRequest: PropTypes.func.isRequired,
};

export default ModeratorCard;
