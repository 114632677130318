import { isPermissionGranted } from '../../../utility/helpers';
import { PERMISSIONS } from '../../../constants/rolesAndPermissionList';

export const getUserMenuItems = (
  labels,
  pathname,
  permissions,
  organizationData,
  isPremiumUser,
  onRedirect,
  onLogOut,
  onManageSubscription,
  onInstructionGuideDrawerClick
) => {
  const isCustomer = organizationData?.stripe_customer !== null;

  return [
    ...(isPermissionGranted(
      PERMISSIONS.canManageOrganizationSettings,
      permissions
    ) && isCustomer
      ? [
          {
            id: 1,
            name: labels.manageSubscription,
            action: onManageSubscription,
          },
        ]
      : []),
    ...(isPermissionGranted(
      PERMISSIONS.canManageOrganizationSettings,
      permissions
    )
      ? [
          {
            id: 2,
            name: labels.organizationSettings,
            action: onRedirect('/organization-settings'),
            isDisabled: pathname.includes('organization-settings'),
          },
        ]
      : []),

    ...(isPermissionGranted(
      PERMISSIONS.canManageSlackIntegration,
      permissions
    ) && isPremiumUser
      ? [
          {
            id: 7,
            name: labels.slackIntegration,
            action: onRedirect('/slack-integration'),
            isDisabled: pathname.includes('slack-integration'),
          },
        ]
      : []),
    {
      id: 3,
      name: labels.releaseNotes,
      action: onRedirect('/release-notes'),
      isDisabled: pathname.includes('release-notes'),
    },
    {
      id: 4,
      name: labels.privacyPolicy,
      action: onRedirect('/privacy-policy'),
    },
    {
      id: 5,
      name: labels.termsOfService,
      action: onRedirect('/terms-of-service'),
    },
    {
      id: 6,
      name: labels.helpCenter,
      action: () => onInstructionGuideDrawerClick(true),
    },
    {
      id: 7,
      name: labels.logOut,
      action: onLogOut,
    },
  ];
};
