import { memo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { Fade, makeStyles } from '@material-ui/core';
import PageContainer from '../../shared/pageContainer';
import UsersTagCard from '../../shared/usersTagCard';
import ColorBox from '../../shared/colorBox';
import TextBoxWithTooltip from '../../shared/textBoxWithTooltip';
import NotificationCard from '../../shared/notificationCard';
import { useTranslations } from '../../../utility/useTranslations';
import {
  isArrayEmpty,
  isUserProfileAccessible,
} from '../../../utility/helpers';
import http from '../../../utility/http';
import {
  api_tag_category,
  api_tagged_users,
} from '../../../constants/apiRoutes';
import { APP_PAGES } from '../../../constants/pages';

const useStyles = makeStyles(({ spacing }) => ({
  tags: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(0,1fr))',
    gridGap: spacing(2),
  },
  categoryWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: spacing(8, 0, 4, 0),
    width: '100%',
  },
  colorBox: {
    flexShrink: 0,
    marginRight: spacing(3),
  },
}));

const TagCategoryPage = ({ navigate, auth, organizationSettings, ...rest }) => {
  const translations = useTranslations(APP_PAGES.TAG_CATEGORY);
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [category, setCategory] = useState(null);
  const [tagsWithUsers, setTagsWithUsers] = useState(null);
  const { categoryId } = useParams();

  const goToProfilePage = useCallback(
    user => navigate(`/people/${user.id}`),
    [navigate]
  );

  const getCategory = useCallback(async () => {
    try {
      const currentCategory = await http.get(api_tag_category(categoryId));

      setCategory(currentCategory.data);
      if (!isArrayEmpty(currentCategory?.data?.tags)) {
        const categoryTagsWithUsers = await http.get(
          api_tagged_users(categoryId)
        );
        setTagsWithUsers(categoryTagsWithUsers.data);
      } else {
        setTagsWithUsers(null);
      }
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  }, [categoryId]);

  useEffect(() => {
    getCategory();
  }, [getCategory, categoryId]);

  return (
    <PageContainer
      {...rest}
      translations={translations}
      navigate={navigate}
      auth={auth}
      organizationSettings={organizationSettings}
      shouldPassProps={false}
    >
      <Fade in appear>
        <div>
          {!isLoading && (
            <div className={classes.categoryWrapper}>
              <ColorBox className={classes.colorBox} bgColor={category.color} />
              <TextBoxWithTooltip variant="h5" text={category.name} />
            </div>
          )}
          {!isLoading && tagsWithUsers && (
            <div className={classes.tags}>
              {tagsWithUsers.map(tag => (
                <UsersTagCard
                  key={`tag_${tag.id}`}
                  translations={translations}
                  tag={tag}
                  color={category.color}
                  isUserAccesible={isUserProfileAccessible(
                    auth,
                    organizationSettings.global_see_himself
                  )}
                  onGoToUserProfile={goToProfilePage}
                />
              ))}
            </div>
          )}
          {!isLoading && !tagsWithUsers && (
            <NotificationCard content={translations.noData} />
          )}
        </div>
      </Fade>
    </PageContainer>
  );
};

TagCategoryPage.propTypes = {
  auth: PropTypes.object.isRequired,
  organizationSettings: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
};

export default memo(TagCategoryPage);
