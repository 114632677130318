import { validateLength, isEmpty } from './validation';
import { validateTasks, prepareTasksForSave } from './tasks';
import { FIELD_TYPES } from '../constants/fieldTypes';
import { CREATABLE_TAG_FIELD } from '../constants/tags';
import { NOTE_FIELD_NAMES } from '../constants/notes';
import { getObjectToNumberArray } from './helpers';

export const getManageNoteInitialData = (note, isEdit = false) => {
  const { TEXT, TAGS, TASKS, IS_PRIVATE } = NOTE_FIELD_NAMES;
  if (isEdit) {
    return note
      ? {
          ...note,
          [IS_PRIVATE]: note[IS_PRIVATE],
          [TAGS]: getObjectToNumberArray(note?.[TAGS]),
        }
      : {};
  }

  return {
    [IS_PRIVATE]: false,
    [TEXT]: '',
    [TASKS]: [],
    [TAGS]: [],
  };
};

export const getManageNoteFields = (
  classes,
  onCreateTag,
  isEdit,
  hasVisibilityField = false
) => {
  return [
    ...(hasVisibilityField
      ? [
          {
            name: NOTE_FIELD_NAMES.IS_PRIVATE,
            translationKey: 'isPrivate',
            type: FIELD_TYPES.CHECKBOX,
            fieldWrapperClass: classes.visibility,
            className: classes.checkbox,
            isControlled: true,
            smallText: true,
            isDisabled: isEdit,
            dependants: [
              {
                name: 'tasks',
                resetFnc: (
                  prevIsPrivate,
                  currentIsPrivate,
                  currentDependantValue
                ) => (currentIsPrivate ? [] : currentDependantValue),
              },
            ],
          },
        ]
      : []),
    {
      name: NOTE_FIELD_NAMES.TEXT,
      translationKey: 'note',
      type: FIELD_TYPES.RICH_TEXT_EDITOR,
      required: true,
      fieldWrapperClass: classes.note,
      validators: [
        { type: 'required', validator: isEmpty },
        {
          type: 'noteMinMaxLength',
          validator: fieldValue => validateLength(fieldValue, 0, 20000),
        },
      ],
    },
    {
      name: NOTE_FIELD_NAMES.TAGS,
      type: FIELD_TYPES.SELECT,
      fieldWrapperClass: classes.tags,
      optionsKey: 'categories',
      creatableOptionField: CREATABLE_TAG_FIELD,
      isCategorized: true,
      hasCategoryColorBox: true,
      isMulti: true,
      hasHash: true,
      isCreatable: true,
      isTag: true,
      parser: {
        value: 'id',
        label: 'name',
        categoryLabel: 'name',
        categoryValue: 'tags',
      },
      onCreateOption: onCreateTag,
    },
    {
      name: NOTE_FIELD_NAMES.TASKS,
      translationKey: 'tasks',
      type: FIELD_TYPES.TASKS,
      fieldWrapperClass: classes.tasks,
      optionsKey: 'allUsers',
      shouldRender: values => !values[NOTE_FIELD_NAMES.IS_PRIVATE],
      validators: [
        {
          validator: (tasks, values) => validateTasks(tasks, values),
          isMultiple: true,
        },
      ],
    },
  ];
};

export const prepareNoteForSave = (note, isEdit, user) => {
  const { ID, TEXT, TAGS, TASKS, IS_PRIVATE, USER } = NOTE_FIELD_NAMES;

  return {
    ...(isEdit ? { [ID]: note[ID] } : {}),
    ...(!note[IS_PRIVATE]
      ? {
          [TASKS]: prepareTasksForSave(note[TASKS]),
        }
      : {}),
    [IS_PRIVATE]: note[IS_PRIVATE],
    [TEXT]: note[TEXT],
    [TAGS]: note[TAGS],
    [USER]: user[ID],
  };
};

export const getNoteFormTitle = (translations, note, isEdit = false) => {
  const isPrivate = note.is_private;

  if (isPrivate) {
    return isEdit
      ? translations.title.editPrivateNote
      : translations.title.addPrivateNote;
  }

  return isEdit ? translations.title.edit : translations.title.add;
};
