import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import CustomModal from '../customModal';
import AttributeQuestion from '../attributeQuestion';
import NoDataText from '../noDataText';
import CustomScrollBar from '../customScrollBar';
import { isObjectEmpty, isArrayEmpty } from '../../../utility/helpers';
import { ATTRIBUTES } from '../../../constants/attributes';

const styles = ({ palette: { primary }, spacing }) => ({
  paper: {
    boxSizing: 'border-box',
    width: 850,
    height: 472,
  },
  contentWrapper: {
    position: 'relative',
    borderTop: `1px solid ${primary.bluish5}`,
    height: '100%',

    '&::before': {
      backgroundColor: primary.bluish5,
      content: '""',
      position: 'absolute',
      top: -1,
      left: -32,
      width: 32,
      height: 1,
    },
    '&::after': {
      backgroundColor: primary.bluish5,
      content: '""',
      position: 'absolute',
      top: -1,
      right: -32,
      width: 32,
      height: 1,
    },
  },
  content: {
    width: 640,
    margin: spacing(9, 'auto', 0, 'auto'),
  },
  question: {
    marginBottom: spacing(6),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  noData: {
    marginTop: '5%',
    marginRight: '18.5%',
    textAlign: 'center',
  },
  scrollY: {
    backgroundColor: primary.bluish9,
    top: 12,
    right: -20,
    height: 'calc(100% - 12px)',
    width: 8,
  },
  scroll: {
    backgroundColor: primary.bluish7,
  },
});

class PreviewAttributeDialog extends PureComponent {
  renderContent = () => {
    const { classes, translations, attribute } = this.props;
    const isOpenEnded = attribute?.text_reply_questions;

    return !isObjectEmpty(attribute) && !isArrayEmpty(attribute.questions) ? (
      attribute.questions.map(question => (
        <AttributeQuestion
          key={`question_${question.id}`}
          className={classes.question}
          translations={translations}
          isVertical={attribute.layout === ATTRIBUTES.verticalLayout}
          question={question}
          withTextReplyQuestions={isOpenEnded}
          isPreview
          isAttributePreview
          isSingleReview
        />
      ))
    ) : (
      <NoDataText className={classes.noData} text={translations.noData} />
    );
  };

  render() {
    const { classes, translations, isOpened, onClose } = this.props;

    return (
      <CustomModal
        paperClass={classes.paper}
        isOpened={isOpened}
        title={translations.preview}
        onClose={onClose}
        shouldRemoveFooter
      >
        <div className={classes.contentWrapper}>
          <CustomScrollBar
            customScrollBarYClass={classes.scrollY}
            customScrollClass={classes.scroll}
            verticalScroll
            removeScrollX
          >
            <div className={classes.content}>{this.renderContent()}</div>
          </CustomScrollBar>
        </div>
      </CustomModal>
    );
  }
}

PreviewAttributeDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  isOpened: PropTypes.bool.isRequired,
  attribute: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(PreviewAttributeDialog);
