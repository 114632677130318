import { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup } from 'react-transition-group';
import { Fade, makeStyles } from '@material-ui/core';
import Task from './task';
import AlertDialog from '../alertDialog';
import { showSuccessMessage } from '../../../utility/uiUtils';

const useStyles = makeStyles(({ spacing }) => ({
  taskWrapper: {
    marginBottom: spacing(3),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));

const TasksList = ({
  translations,
  tasks,
  checkCanManageTask,
  canAccessUserProfile,
  shouldHideAssignedUser,
  onStatusChange,
}) => {
  const classes = useStyles();

  const [selectedTask, setSelectedTask] = useState(null);

  const onCloseTaskStatus = () => setSelectedTask(null);

  const handleChangeStatus = task => setSelectedTask(task);

  const onSaveStatus = () => {
    onStatusChange(selectedTask);
    onCloseTaskStatus();
    showSuccessMessage(translations.taskStatusDialog.success);
  };

  return (
    <div>
      <TransitionGroup>
        {tasks.map(task => {
          return (
            <Fade
              key={`task${task.id}`}
              className={classes.taskWrapper}
              in
              unmountOnExit
            >
              <div>
                <Task
                  task={task}
                  checkCanManageTask={checkCanManageTask}
                  canAccessUserProfile={canAccessUserProfile}
                  shouldHideAssignedUser={shouldHideAssignedUser}
                  onChange={handleChangeStatus}
                />
              </div>
            </Fade>
          );
        })}
      </TransitionGroup>
      <AlertDialog
        translations={
          selectedTask
            ? {
                ...translations.taskStatusDialog,
                contentMessage: selectedTask?.is_finished
                  ? translations.taskStatusDialog.contentMessageUncheck
                  : translations.taskStatusDialog.contentMessageCheck,
              }
            : {}
        }
        isOpened={!!selectedTask}
        onClose={onCloseTaskStatus}
        onConfirm={onSaveStatus}
      />
    </div>
  );
};

TasksList.defaultProps = {
  canAccessUserProfile: () => false,
  checkCanManageTask: () => true,
  shouldHideAssignedUser: false,
};

TasksList.propTypes = {
  translations: PropTypes.object.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.object).isRequired,
  checkCanManageTask: PropTypes.func,
  canAccessUserProfile: PropTypes.func,
  shouldHideAssignedUser: PropTypes.bool,
  onStatusChange: PropTypes.func.isRequired,
};

export default memo(TasksList);
