import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import { Switch, Case } from '../switchComponent';
import FillSurveyCard from './fillSurveyCard';
import PlanProposalCard from './planProposalCard';
import OneOnOneRequestCard from './oneOnOneRequestCard';
import { TO_DO_ENTITIES } from '../../../constants/dashboard';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  cardWrapper: {
    backgroundColor: primary.white,
    boxSizing: 'border-box',
    borderRadius: 16,
    cursor: 'default',
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(4),
    width: 270,
    minHeight: 170,
    userSelect: 'none',
  },
}));

const ToDoCard = ({
  className,
  translations,
  data,
  onFillSurvey,
  onAcceptProposal,
  onDismissProposal,
  onAcceptRequest,
  onDismissRequest,
}) => {
  const classes = useStyles();
  const { entity } = data;
  const { PLAN, ONE_ON_ONE, SURVEY } = TO_DO_ENTITIES;

  return (
    <Switch condition={entity}>
      <Case when={SURVEY}>
        <div className={classNames(classes.cardWrapper, className)}>
          <FillSurveyCard
            translations={translations.survey}
            survey={data}
            onFillSurvey={onFillSurvey}
          />
        </div>
      </Case>
      <Case when={ONE_ON_ONE}>
        <div className={classNames(classes.cardWrapper, className)}>
          <OneOnOneRequestCard
            translations={translations.oneOnOneRequest}
            request={data}
            onAccept={onAcceptRequest}
            onDismiss={onDismissRequest}
          />
        </div>
      </Case>
      <Case when={PLAN}>
        <div className={classNames(classes.cardWrapper, className)}>
          <PlanProposalCard
            translations={translations.planProposal}
            proposal={data}
            onAccept={onAcceptProposal}
            onDismiss={onDismissProposal}
          />
        </div>
      </Case>
    </Switch>
  );
};

ToDoCard.defaultProps = {
  className: undefined,
  data: {},
  onFillSurvey: () => {},
  onAcceptProposal: () => {},
  onDismissProposal: () => {},
  onAcceptRequest: () => {},
  onDismissRequest: () => {},
};

ToDoCard.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  data: PropTypes.object,
  onFillSurvey: PropTypes.func,
  onAcceptProposal: PropTypes.func,
  onDismissProposal: PropTypes.func,
  onAcceptRequest: PropTypes.func,
  onDismissRequest: PropTypes.func,
};

export default ToDoCard;
