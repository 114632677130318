import { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router';
import { Popover, makeStyles } from '@material-ui/core';
import CustomButton from '../customButton';
import UpgradePlan from './upgradePlan';
import http from '../../../utility/http';
import { API_SUBSCRIPTION } from '../../../constants/apiRoutes';
import { SUBSCRIPTION_EXCLUDED_URLS } from '../../../constants/appConfig';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  paper: {
    width: 300,
    height: 'auto',
    padding: spacing(5, 7, 6, 7),
    border: `1px solid ${primary.bluish7}`,
    borderRadius: 16,
    boxShadow: 'none',
    marginTop: spacing(2),
  },
}));

const UpgradePopover = ({ translations, grantedPermissions }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [limits, setLimits] = useState({});

  const shouldRender = SUBSCRIPTION_EXCLUDED_URLS.every(
    route => !location.pathname.includes(route)
  );

  const handleClick = async event => {
    const anchor = event.currentTarget;
    const { data } = await http.get(API_SUBSCRIPTION);
    setAnchorEl(anchor);
    setLimits(data);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onGoToPricingPlan = () => {
    navigate('/pricing-plans');
    setAnchorEl(null);
  };

  return (
    shouldRender && (
      <div>
        <CustomButton type="withTextLightRounded" onClick={handleClick}>
          {translations.free}
        </CustomButton>
        <Popover
          id={anchorEl ? 'simple-popover' : undefined}
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={handleClose}
          classes={{
            paper: classes.paper,
          }}
          anchorOrigin={{
            vertical: -24,
            horizontal: 0,
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <UpgradePlan
            translations={translations}
            grantedPermissions={grantedPermissions}
            limits={limits}
            onGoToPricingPlan={onGoToPricingPlan}
          />
        </Popover>
      </div>
    )
  );
};

UpgradePopover.propTypes = {
  translations: PropTypes.object.isRequired,
  grantedPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default UpgradePopover;
