import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import CustomModal from '../customModal';
import InputField from '../inputField';
import { asyncDebounce, trimString } from '../../../utility/helpers';
import { validateField } from '../../../utility/validation';

const DELAY = 300;
const nameID = 'name';
const shortNameID = 'shortName';

const styles = ({ spacing }) => ({
  description: {
    marginBottom: spacing(4),
  },
  input: {
    marginBottom: spacing(6),
  },
  inputError: {
    marginBottom: spacing(1),
  },
});

class ChooseNameDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      inputted: props.initialValue || '',
      error: '',
      inputtedShortName: '',
      errorShortName: '',
    };
    this.validateSingleField = asyncDebounce(this.validateSingleField, DELAY);
    this.validateShortNameField = asyncDebounce(
      this.validateShortNameField,
      DELAY
    );
  }

  componentDidUpdate(prevProps) {
    const { initialValue, initialShortNameValue, isOpened } = this.props;

    if (
      prevProps.initialValue !== initialValue ||
      prevProps.initialShortNameValue !== initialShortNameValue ||
      (isOpened && isOpened !== prevProps.isOpened)
    ) {
      this.handleInitialValue(initialValue, initialShortNameValue);
    }
  }

  resetState = () => {
    this.setState({
      inputted: '',
      error: '',
      inputtedShortName: '',
      errorShortName: '',
    });
  };

  handleInitialValue = (value, valueShortName) => {
    this.setState({ inputted: value, inputtedShortName: valueShortName });
  };

  isFormDirty = () => {
    const { initialValue, initialShortNameValue, withShortNameInput } =
      this.props;
    const { inputted, inputtedShortName } = this.state;
    const titleIsDirty = inputted && inputted !== initialValue;

    if (withShortNameInput) {
      const shortNameIsDirty =
        inputtedShortName && inputtedShortName !== initialShortNameValue;
      return titleIsDirty && shortNameIsDirty;
    }
    return titleIsDirty;
  };

  validateSingleField = value => {
    const { field } = this.props;
    return validateField(field, value).then(errorType => {
      this.setState({ error: errorType });
      return errorType;
    });
  };

  validateShortNameField = value => {
    const { shortNameField } = this.props;
    return validateField(shortNameField, value).then(errorType => {
      this.setState({ errorShortName: errorType });
      return errorType;
    });
  };

  handleChange = event => {
    const {
      target: { value, id },
    } = event;
    const trimmedValue = trimString(value);

    if (id === nameID) {
      this.setState({ inputted: value }, () =>
        this.validateSingleField(trimmedValue)
      );
    } else {
      const styledValue = value.toUpperCase();
      this.setState({ inputtedShortName: styledValue }, () =>
        this.validateShortNameField(trimmedValue)
      );
    }
  };

  handleSave = async () => {
    const { onSave, withShortNameInput } = this.props;
    const { inputted, inputtedShortName } = this.state;

    const trimmedValue = trimString(inputted);
    const hasNameError = await this.validateSingleField(trimmedValue);
    let hasShortNameError = false;

    if (withShortNameInput) {
      const trimmedValueShortName = trimString(inputtedShortName);
      hasShortNameError = await this.validateShortNameField(
        trimmedValueShortName
      );
    }

    if (!hasNameError && !hasShortNameError) {
      if (this.isFormDirty()) {
        if (withShortNameInput) {
          const trimmedValueShortName = trimString(inputtedShortName);
          return onSave(trimmedValue, trimmedValueShortName).then(
            this.resetState
          );
        }
        return onSave(trimmedValue).then(this.resetState);
      }

      this.handleClose();
    }
  };

  handleClose = () => {
    const { onCancel } = this.props;

    onCancel();
    this.resetState();
  };

  render() {
    const {
      classes,
      translations,
      title,
      field,
      isOpened,
      description,
      withShortNameInput,
      shortNameField,
    } = this.props;
    const { inputted, error, inputtedShortName, errorShortName } = this.state;

    const hasError = Boolean(error);
    const hasErrorSN = Boolean(errorShortName);

    const inputClasses = classNames(classes.input, {
      [classes.inputError]: hasError,
    });
    const inputClassesSN = classNames(classes.input, {
      [classes.inputError]: hasErrorSN,
    });

    return (
      <CustomModal
        title={title}
        confirmButtonLabel={translations.save}
        closeButtonLabel={translations.cancel}
        isOpened={isOpened}
        onClose={this.handleClose}
        onConfirm={this.handleSave}
        isMedium
      >
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography className={classes.description}>
              {description}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div className={inputClasses}>
              <div>
                <InputField
                  id={nameID}
                  name={field.name}
                  type={field.type}
                  placeholder={translations[field.translationKey].placeholder}
                  label={translations[field.translationKey].label}
                  value={inputted}
                  error={hasError}
                  errorMessage={translations.errorMessages[error] || ''}
                  onChange={this.handleChange}
                  fullWidth
                />
              </div>
            </div>
          </Grid>
          {withShortNameInput ? (
            <Grid item xs={12}>
              <div className={inputClassesSN}>
                <div>
                  <InputField
                    id={shortNameID}
                    name={shortNameField.name}
                    type={shortNameField.type}
                    placeholder={
                      translations[shortNameField.translationKey].placeholder
                    }
                    label={translations[shortNameField.translationKey].label}
                    value={inputtedShortName}
                    error={hasErrorSN}
                    errorMessage={
                      translations.errorShortNameMessages[errorShortName] || ''
                    }
                    onChange={this.handleChange}
                    fullWidth
                  />
                </div>
              </div>
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      </CustomModal>
    );
  }
}

ChooseNameDialog.defaultProps = {
  initialValue: '',
  withShortNameInput: false,
  shortNameField: {},
  initialShortNameValue: '',
};

ChooseNameDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpened: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  field: PropTypes.object.isRequired,
  withShortNameInput: PropTypes.bool,
  shortNameField: PropTypes.object,
  initialShortNameValue: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default withStyles(styles)(ChooseNameDialog);
