import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import InputField from '../../inputField';
import CustomCheckbox from '../../customCheckbox';
import UserAvatar from '../../userAvatar';
import CustomButton from '../../customButton';
import { validateField } from '../../../../utility/validation';
import { trimString } from '../../../../utility/helpers';
import { REPLY_FIELD } from './config';

const styles = ({ palette: { primary }, spacing }) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: spacing(6),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  reply: {
    marginBottom: spacing(6),
  },
  replyError: {
    marginBottom: spacing(1),
  },
  replyInput: {
    borderRadius: 8,
    '& textarea': {
      height: 174,
    },
  },
  subject: {
    marginBottom: spacing(4),
  },
  userCaption: {
    maxWidth: 'calc(100% - 40px)',
  },
  userLabel: {
    fontFamily: 'ProximaNova-Bold',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
  },
  save: {
    marginLeft: 'auto',
    '&:disabled': {
      border: `1px solid ${primary.bluish7}`,
    },
  },
  neutralAnswerCheckbox: {
    flexGrow: 1,
  },
});

class FreeTextAnswer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      answer: this.props.initialAnswer,
    };
  }

  componentDidUpdate(prevProps) {
    const { questionId } = this.props;

    if (prevProps.questionId !== questionId) {
      this.updateInitialAnswer();
    }
  }

  updateInitialAnswer = () => {
    const { initialAnswer, subject, onSetError } = this.props;

    this.setState({ answer: initialAnswer }, () => onSetError(null, subject));
  };

  handleOnChange = async event => {
    event.persist();
    const { subject, onSetError } = this.props;
    const { value } = event.target;
    const caretStart = event.target.selectionStart;
    const caretEnd = event.target.selectionEnd;
    const error = await validateField(REPLY_FIELD, value);

    this.setState(
      prevState => ({
        answer: {
          ...prevState.answer,
          reply: value,
          not_applicable: !!prevState.answer?.not_applicable,
        },
      }),
      () => onSetError(error, subject)
    );
    event.target.setSelectionRange(caretStart, caretEnd);
  };

  handleSaveReply = () => {
    const { questionId, subject, initialAnswer, error, onChange } = this.props;
    const { answer } = this.state;

    if (
      !error &&
      trimString(initialAnswer.reply || '') !== trimString(answer.reply || '')
    ) {
      onChange(questionId, subject, answer);
    }
  };

  handleNeutralAnswerChange = isNeutral => {
    const { translations, subject, onSetError } = this.props;

    this.setState(
      prevState => ({
        answer: {
          ...prevState.answer,
          reply: isNeutral ? translations.neutralAnswer.neutralValue : '',
          not_applicable: isNeutral,
        },
      }),
      () => {
        onSetError(null, subject);
      }
    );
  };

  render() {
    const {
      classes,
      translations,
      initialAnswer,
      isSingleReview,
      subject,
      hasNeutralAnswer,
      isDisabled,
      error,
    } = this.props;
    const { answer } = this.state;
    return (
      <div className={classes.main}>
        {!isSingleReview && (
          <UserAvatar
            className={classes.subject}
            labelClass={classes.userLabel}
            user={subject}
            caption
          />
        )}
        <div
          className={classNames(classes.reply, {
            [classes.replyError]: !!error,
          })}
        >
          <InputField
            name={REPLY_FIELD.name}
            className={classes.replyInput}
            placeholder={
              answer.not_applicable
                ? translations.neutralAnswer.neutralValue
                : translations[REPLY_FIELD.name].placeholder
            }
            value={answer.reply || ''}
            rows={REPLY_FIELD.rows}
            multiline={REPLY_FIELD.isMultiline}
            error={!!error}
            errorMessage={
              translations[REPLY_FIELD.name].validationMessages?.[error]
            }
            onChange={this.handleOnChange}
            disabled={isDisabled || answer.not_applicable}
            fullWidth
          />
        </div>
        <div className={classes.footer}>
          {hasNeutralAnswer && (
            <CustomCheckbox
              className={classes.neutralAnswerCheckbox}
              isChecked={!!answer.not_applicable}
              labelText={translations.neutralAnswer.text}
              disabled={isDisabled}
              onChange={this.handleNeutralAnswerChange}
              isControlled
              smallText
            />
          )}
          <CustomButton
            className={classes.save}
            type="addDarkRoundedOutlined"
            showIcon={false}
            disabled={
              !!error ||
              !trimString(answer.reply || '') ||
              trimString(initialAnswer.reply || '') ===
                trimString(answer.reply || '')
            }
            onClick={this.handleSaveReply}
          >
            {translations.save}
          </CustomButton>
        </div>
      </div>
    );
  }
}

FreeTextAnswer.defaultProps = {
  initialAnswer: {},
  subject: {},
  error: null,
  isSingleReview: false,
  hasNeutralAnswer: false,
  isDisabled: false,
};

FreeTextAnswer.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.shape({}).isRequired,
  subject: PropTypes.shape({}),
  questionId: PropTypes.number.isRequired,
  initialAnswer: PropTypes.shape({}),
  isSingleReview: PropTypes.bool,
  isDisabled: PropTypes.bool,
  error: PropTypes.string,
  hasNeutralAnswer: PropTypes.bool,
  onSetError: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(FreeTextAnswer);
