import { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import download from 'downloadjs';
import { getPeoplePickerParams } from 'components/pages/surveySettingsPage/config';
import SurveySettingsPage from '../../components/pages/surveySettingsPage';
import PageContainer from '../../components/shared/pageContainer';
import TextBoxWithTooltip from '../../components/shared/textBoxWithTooltip';
import SurveyIconInfoTooltip from '../../components/shared/surveyIconInfoTooltip';
import CustomButton from '../../components/shared/customButton';
import ActionButton from '../../components/shared/actionButton';
import { surveySelector } from '../../store/selectors/surveySelector';
import { surveysSelector } from '../../store/selectors/surveysSelector';
import {
  getSurvey,
  editSurvey,
  clearSurvey,
  getReviewers,
  clearReviewers,
} from '../../store/modules/survey';
import {
  getAllUsers,
  clearAllUsers,
  getAllSurveyGroups,
  clearAllSurveyGroups,
} from '../../store/modules/surveys';
import { useTranslations } from '../../utility/useTranslations';
import { checkUserRole, getObjectToNumberArray } from '../../utility/helpers';
import { showSuccessMessage } from '../../utility/uiUtils';
import { ACTION_BUTTON_TYPES } from '../../components/shared/actionButton/config';
import { APP_PAGES, PARAMS } from '../../constants/pages';
import http from '../../utility/http';
import {
  API_ORG_CHART,
  api_survey,
  api_survey_export,
} from '../../constants/apiRoutes';
import { ROLES } from '../../constants/rolesAndPermissionList';
import { SURVEY_STATUS_TYPES, UserStatuses } from '../../constants/statuses';
import {
  CREATE_SURVEY_FIELD_NAMES,
  SURVEY_TYPES,
} from '../../constants/survey';

const mapStateToProps = state => ({
  ...surveySelector(state),
  allUsers: [...surveysSelector(state).allUsers],
  allSurveyGroups: [...surveysSelector(state).allSurveyGroups],
});

const mapDispatchToProps = dispatch => ({
  getSurvey: surveyId => getSurvey(dispatch, surveyId),
  editSurvey: (surveyId, data) => editSurvey(dispatch, surveyId, data),
  getAllUsers: () => getAllUsers(dispatch),
  getReviewers: (surveyId, isLoadMore, data) =>
    getReviewers(dispatch, surveyId, isLoadMore, data),
  getAllSurveyGroups: () => getAllSurveyGroups(dispatch),
  clearSurvey: () => dispatch(clearSurvey()),
  clearReviewers: () => dispatch(clearReviewers()),
  clearAllUsers: () => dispatch(clearAllUsers()),
  clearAllSurveyGroups: () => dispatch(clearAllSurveyGroups()),
});

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'calc(100% - 260px)',
    maxWidth: 900,
  },
  surveyTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 'calc(100% - 162px)',
  },
  headerActions: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: spacing(4),
    flexShrink: 0,
  },
  actionButton: {
    marginLeft: spacing(2),
    '&:first-of-type': {
      marginLeft: 0,
    },
  },
  linkButton: {
    marginLeft: spacing(2),
  },
  button: {
    borderRadius: 44,
    fontFamily: 'ProximaNova-Bold',
    fontSize: 16,
    lineHeight: '24px',
    padding: spacing(2, 4),
    maxHeight: 40,
  },
  seeReportButton: {
    boxSizing: 'border-box',
    backgroundColor: primary.blue1,
    border: 'none',
    color: primary.white,
    '&:hover': {
      backgroundColor: primary.blue1,
    },
  },
  anonymous: {
    display: 'block',
  },
  infoIcon: {
    flexShrink: 0,
    '& svg': {
      marginRight: 0,
    },
  },
}));

const SurveySettingsPageView = props => {
  const [isLoading, setIsLoading] = useState(true);
  const [isEditTitleOpened, setIsEditTitleOpened] = useState(false);
  const [organizationMentor, setOrganizationMentor] = useState({});
  const { surveyId } = useParams();
  const classes = useStyles();
  const translations = useTranslations(APP_PAGES.SURVEY_SETTINGS);
  const isAdmin = checkUserRole(props.auth.role, ROLES.ADMIN);

  const getOrganizationMentor = () => {
    return http.get(API_ORG_CHART).then(({ data }) => ({
      ...data,
      last_name: translations.organization,
      isCeo: true,
    }));
  };

  const getSurveyData = async () => {
    const [, , , SUSPENDED] = UserStatuses;
    const [surveyData, orgMentor] = await Promise.all([
      props.getSurvey(surveyId),
      getOrganizationMentor(),
      ...(isAdmin ? [props.getAllSurveyGroups()] : []),
    ]);

    const isPeerReview = surveyData?.type === SURVEY_TYPES.PEER;
    const isSelfReview = surveyData?.type === SURVEY_TYPES.SELF;

    setOrganizationMentor(orgMentor);

    if (!surveyData?.isAnonymous) {
      const isDraft = surveyData?.status === SURVEY_STATUS_TYPES.DRAFT;

      return Promise.all([
        props.getReviewers(surveyId, false, {
          ...(isDraft ? { [PARAMS.EXCLUDE_USER_STATUS]: SUSPENDED.id } : {}),
        }),
        props.getAllUsers(getPeoplePickerParams(isAdmin, isSelfReview), {
          [PARAMS.EXCLUDE]: getObjectToNumberArray(
            isPeerReview
              ? [...surveyData.reviewers, ...surveyData.createdFor]
              : surveyData.reviewers
          ),
        }),
      ]).then(() => setIsLoading(false));
    }

    setIsLoading(false);
  };

  const toggleSurveyTitleDialog = () =>
    setIsEditTitleOpened(!isEditTitleOpened);

  const exportToCsv = () => {
    http
      .get(api_survey_export(props.survey.id), {
        responseType: 'arraybuffer',
      })
      .then(({ data }) => {
        download(
          new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          }),
          `${props.survey.title}.xlsx`
        );
      });
  };

  const onChangeSurveyTitle = surveyTitle => {
    return http
      .patch(api_survey(props.survey.id), { title: surveyTitle })
      .then(() => {
        showSuccessMessage(translations.renameSurveyDialog.success);
        toggleSurveyTitleDialog();
        getSurveyData();
      });
  };

  useEffect(() => {
    try {
      getSurveyData();
    } catch (e) {
      setIsLoading(false);
    }

    return () => {
      props.clearSurvey();
      props.clearReviewers();
      props.clearAllUsers();
      props.clearAllSurveyGroups();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderSettingsHeader = () => {
    const { survey } = props;
    const { title, isAnonymous, creator, isOneTime } = survey;
    const { COMPLETED, DRAFT } = SURVEY_STATUS_TYPES;
    const isDraft = survey[CREATE_SURVEY_FIELD_NAMES.STATUS] === DRAFT;

    return (
      <div className={classes.header}>
        {title && (
          <>
            <div className={classes.surveyTitleWrapper}>
              <TextBoxWithTooltip variant="h1" text={title} />
              {isOneTime && (
                <SurveyIconInfoTooltip
                  className={classes.infoIcon}
                  tooltipText={translations.oneTimeSurvey}
                  isOneTimeSurvey
                />
              )}
              {isAnonymous && (
                <SurveyIconInfoTooltip
                  className={classNames(classes.anonymous, classes.infoIcon)}
                  tooltipText={translations.anonymousSurvey}
                />
              )}
            </div>
            <div className={classes.headerActions}>
              {(isAdmin || props.auth.id === creator.id) &&
                survey.status === COMPLETED && (
                  <ActionButton
                    type={ACTION_BUTTON_TYPES.DOWNLOAD}
                    onClickHandler={exportToCsv}
                  />
                )}

              {(isAdmin || props.auth.id === creator.id) && (
                <ActionButton
                  type={ACTION_BUTTON_TYPES.EDIT}
                  onClickHandler={toggleSurveyTitleDialog}
                />
              )}
              {!isDraft && (
                <Link
                  className={classes.linkButton}
                  to={`/surveys/${survey.id}/report`}
                >
                  <CustomButton
                    className={classNames(
                      classes.button,
                      classes.seeReportButton
                    )}
                    type="withTextDarkRounded"
                  >
                    {translations.seeReportButton}
                  </CustomButton>
                </Link>
              )}
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <PageContainer
      {...props}
      translations={translations}
      headerProps={{
        renderTitle: renderSettingsHeader,
      }}
      organizationMentor={organizationMentor}
    >
      {!isLoading ? (
        <SurveySettingsPage
          isEditTitleOpened={isEditTitleOpened}
          onToggleTitleDialog={toggleSurveyTitleDialog}
          onChangeSurveyTitle={onChangeSurveyTitle}
        />
      ) : (
        <Fragment />
      )}
    </PageContainer>
  );
};

SurveySettingsPageView.propTypes = {
  history: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  survey: PropTypes.object.isRequired,
  getSurvey: PropTypes.func.isRequired,
  getReviewers: PropTypes.func.isRequired,
  getAllUsers: PropTypes.func.isRequired,
  clearSurvey: PropTypes.func.isRequired,
  clearReviewers: PropTypes.func.isRequired,
  clearAllUsers: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveySettingsPageView);
