import {
  isEmpty,
  validateEmail,
  validateLength,
} from '../../../utility/validation';
import { AUTOMATION_ID } from '../../../constants/automationId';

const fields = [
  {
    id: AUTOMATION_ID.USER_EMAIL,
    name: 'email',
    type: 'email',
    label: 'email',
    required: true,
    shouldShowError: true,
    validators: [
      {
        type: 'required',
        validator: isEmpty,
      },
      {
        type: 'invalid',
        validator: validateEmail,
      },
      {
        type: 'exceededLength',
        validator: validateLength,
      },
    ],
  },
];

export default fields;
