import { trimString, isArrayEmpty } from './helpers';
import { customSearch } from './uiUtils';
import http from './http';
import { API_TAGS_MULTIPLE } from '../constants/apiRoutes';
import {
  CATEGORY_PLACEMENTS,
  GENERAL_CATEGORY_ID,
  PLACEMENTS,
} from '../constants/tags';

export const prepareMultipleTagsForSave = tags =>
  tags.reduce(
    (acc, tag) => {
      if (tag.id && tag.isUpdated) {
        return { ...acc, update: [...acc.update, { ...tag }] };
      }

      if (tag.id && tag.isDeleted) {
        return { ...acc, delete: [...acc.delete, tag.id] };
      }

      if (!tag.id) {
        return { ...acc, create: [...acc.create, { ...tag }] };
      }

      return acc;
    },
    { create: [], update: [], delete: [] }
  );

const formatPlacementData = category => {
  return category.placements.reduce((tempAcc, curr) => {
    return [
      ...tempAcc,
      { ...curr, label: CATEGORY_PLACEMENTS[curr.name].label },
    ];
  }, []);
};

export const filterTags = (categories, searchTerm = '', excludedTags = []) => {
  const value = trimString(searchTerm);

  return categories.reduce((acc, category) => {
    const tags = customSearch(category.tags, value).filter(
      tag => !excludedTags.some(eTag => eTag.id === tag.id)
    );

    if (!value) {
      return [
        ...acc,
        {
          ...category,
          placements: [...(formatPlacementData(category) || [])],
          tags,
        },
      ];
    }

    if (!isArrayEmpty(tags)) {
      return [
        ...acc,
        {
          ...category,
          placements: [...(formatPlacementData(category) || [])],
          tags,
        },
      ];
    }

    return acc;
  }, []);
};

export const getCategoriesWithTags = categories =>
  categories.filter(category => !isArrayEmpty(category.tags));

export const prepareUserTagCategories = (categories, userTags) =>
  categories.reduce((acc, category) => {
    const tags = category.tags.filter(tag =>
      userTags.some(uTag => uTag.id === tag.id)
    );

    if (!isArrayEmpty(tags)) {
      return [
        ...acc,
        {
          ...category,
          tags,
          placements: [...(formatPlacementData(category) || [])],
        },
      ];
    }

    return acc;
  }, []);

const prepareCreatableTagRequestData = tagName => {
  const sanitizedName = trimString(tagName);

  return {
    create: [{ category: GENERAL_CATEGORY_ID, name: sanitizedName }],
  };
};

export const onSaveCreatableTag = tagName =>
  http
    .post(API_TAGS_MULTIPLE, prepareCreatableTagRequestData(tagName))
    .then(({ data }) => ({ ...data, delete: [] }));

export const isIncludedInPlacements = categoryData => {
  return categoryData.placements.includes(PLACEMENTS.ORGANIZATION_MENU.id);
};
