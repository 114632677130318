import { getItemById, canSeeEmployeeProfile, isArrayEmpty } from './helpers';
import { formatDate } from './dateUtils';
import { PERIOD_FILTER } from '../constants/filters';
import {
  TO_DO_ENTITIES,
  MAX_MOOD_LENGTH,
  MOOD_PLACEHOLDER,
} from '../constants/dashboard';

export const getDasboardPeriodOptions = labels =>
  PERIOD_FILTER.map(period => ({
    ...period,
    label: labels[period.key],
  }));

export const prepareToDoItems = (items, surveyTypes, currentUser) => {
  const { SURVEY, PLAN } = TO_DO_ENTITIES;
  const timeFormat = 'EEEE, MMM d, yyyy';

  return items.map(item => {
    if (item.entity === SURVEY) {
      return {
        ...item,
        type: getItemById(surveyTypes, item.type),
        expiryDate: formatDate(item.expire_at, timeFormat),
      };
    }

    if (item.entity === PLAN) {
      return {
        ...item,
        isAccessible: canSeeEmployeeProfile(currentUser, item.user.id),
        proposedOn: formatDate(item.created_on, timeFormat),
      };
    }

    return {
      ...item,
      isAccessible: canSeeEmployeeProfile(currentUser, item.user.id),
      requestedOn: formatDate(item.created_at, timeFormat),
    };
  });
};

export const prepareUserMoodData = moodResults => {
  const name = 'Mood';

  if (isArrayEmpty(moodResults)) {
    return [...Array(MAX_MOOD_LENGTH)].map((_, index) => ({
      name: `${name} ${index + 1}`,
      ...MOOD_PLACEHOLDER,
    }));
  }

  if (moodResults.length < MAX_MOOD_LENGTH) {
    const difference = MAX_MOOD_LENGTH - moodResults.length;
    const lastValueIndex = moodResults.length - 1;
    const lastValue = moodResults[lastValueIndex];

    return [...moodResults, ...Array(difference)].map((mood, index) => {
      if (!mood) {
        return {
          name: `${name} ${index + 1}`,
          placeholderValue: lastValue,
        };
      }

      return {
        name: `${name} ${index + 1}`,
        value: mood,
        placeholderValue: index === lastValueIndex ? mood : undefined,
      };
    });
  }

  return moodResults.map((mood, index) => ({
    name: `${name} ${index + 1}`,
    value: mood,
  }));
};
