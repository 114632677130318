import PropTypes from 'prop-types';
import { withStyles, alpha } from '@material-ui/core/styles';
import Dropzone from 'react-dropzone';

import classNames from 'classnames';
import ArrowTooltip from '../arrowTooltip';
import UserAvatar from '../userAvatar';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload-icon-gray.svg';
import { showErrorMessage } from '../../../utility/uiUtils';
import { validateFileType } from '../../../utility/validation';
import { PHOTO_UPLOAD_CONFIG } from '../../../constants/uploadPhotoConfig';

const styles = ({ palette: { primary }, spacing }) => ({
  dropZone: {
    width: 48,
    height: 48,
    borderRadius: '50%',
    outline: 'none',
  },
  clickable: {
    cursor: 'pointer',
  },
  tooltipPopper: {
    backgroundColor: alpha(primary.black, 0.5),
    opacity: 0.8,
    overflow: 'hidden',
    width: 48,
    height: 48,
    borderRadius: '50%',
    marginTop: spacing(-12),
  },
  tooltipBox: {
    backgroundColor: alpha(primary.black, 0.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    padding: 0,
    height: '100%',
    width: '100%',
  },
});

const AvatarUploadZone = props => {
  const { classes, onSuccessHandler, user, translations, disabled } = props;
  const { mimeTypes, maxSize, multiple } = PHOTO_UPLOAD_CONFIG;

  const rejectedUpload = file => {
    if (
      file?.[0]?.file?.type &&
      !validateFileType(file[0].file, Object.keys(mimeTypes))
    ) {
      showErrorMessage(translations.choosePhoto);
    } else if (file?.[0]?.file && file[0].file.size > maxSize) {
      showErrorMessage(translations.photoSizeMessage);
    }
  };

  const renderTooltipLabel = () => {
    return <UploadIcon />;
  };

  return (
    <Dropzone
      onDropAccepted={onSuccessHandler}
      onDropRejected={rejectedUpload}
      accept={mimeTypes}
      maxSize={maxSize}
      multiple={multiple}
      disabled={disabled}
    >
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps({
            className: classNames(classes.dropZone, {
              [classes.clickable]: !disabled,
            }),
          })}
        >
          <input {...getInputProps()} />
          {!disabled ? (
            <ArrowTooltip
              tooltipClassName={classes.tooltipBox}
              tooltipPopperClassName={classes.tooltipPopper}
              tooltipLabel={renderTooltipLabel()}
              isArrowHidden
              position="bottom"
            >
              <div>
                <UserAvatar user={user} medium />
              </div>
            </ArrowTooltip>
          ) : (
            <UserAvatar user={user} medium />
          )}
        </div>
      )}
    </Dropzone>
  );
};

AvatarUploadZone.defaultProps = {
  disabled: false,
};

AvatarUploadZone.propTypes = {
  translations: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onSuccessHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default withStyles(styles)(AvatarUploadZone);
