import { useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, Collapse, makeStyles } from '@material-ui/core';
import TextBoxWithTooltip from '../../textBoxWithTooltip';
import ActionButton from '../../actionButton';
import Survey from './survey';
import CustomButton from '../../customButton';
import ReorderItems from '../../reorderItems';
import { isArrayEmpty, reorder } from '../../../../utility/helpers';
import { ACTION_BUTTON_TYPES } from '../../actionButton/config';

const useStyles = makeStyles(
  ({ breakpoints, palette: { primary }, spacing }) => ({
    header: {
      boxSizing: 'border-box',
      borderBottom: `1px solid ${primary.bluish7}`,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: spacing(4, 2),
      transition: 'border-color .3s ease',
    },
    disabledDrag: {
      padding: spacing(4, 2, 4, 4),
    },
    headerBackground: {
      transition: 'background-color .25s ease',
      '&:hover': {
        backgroundColor: primary.bluish9,
      },
    },
    headerExpanded: {
      borderBottom: '1px solid transparent',
    },
    actionsWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    actionButton: {
      marginLeft: spacing(1),
      '&:first-of-type': {
        marginLeft: spacing(2),
      },
    },
    duplicateIcon: {
      width: 14,
      height: 18,
    },
    expandIcon: {
      transform: 'rotate(0deg)',
      transition: 'transform .3s cubic-bezier(0.77, 0.2, 0.05, 1)',
    },
    expandIconOpened: {
      transform: 'rotate(180deg)',
    },
    button: {
      backgroundColor: primary.white,
      borderLeft: 'none',
      borderRight: 'none',
      borderBottom: `1px solid ${primary.bluish4}`,
      width: '100%',
      '&:only-child': {
        borderTop: 'none',
      },
    },
    surveyHeader: {
      backgroundColor: `${primary.bluish8}`,
      display: 'grid',
      alignItems: 'center',
      gridTemplateColumns:
        'minmax(80px, 80px) minmax(132px, 1.5fr) minmax(auto, 1fr) minmax(90px, 1fr) minmax(90px, 1fr) minmax(160px, 190px) minmax(60px, 80px)',
    },
    headerCell: {
      boxSizing: 'border-box',
      padding: spacing(1.5, 2),
      textAlign: 'left',
      lineHeight: '24px',
      userSelect: 'none',
      [breakpoints.up('xLg')]: {
        padding: spacing(1.5, 4),
      },
    },
    titleHeaderCell: {
      padding: spacing(1.5, 4, 1.5, 9),
    },
    actionCell: {
      textAlign: 'right',
    },
    dragButton: {
      position: 'relative',
      width: 22,
      height: 22,
      flexShrink: 0,
      marginRight: spacing(1.5),
      zIndex: 11,
      '& button': {
        width: 22,
        height: 22,
      },
    },
    dragIcon: {
      width: 8,
      height: 14,
    },
    dragTooltipLabel: {
      fontFamily: 'ProximaNova-Regular',
    },
  })
);

const SurveyGroup = ({
  className,
  translations,
  group,
  surveyColumns,
  dragRef,
  dragProps,
  isDragDisabled,
  onEditTitle,
  onDuplicate,
  onDelete,
  onSurveyClick,
  onCreateSurvey,
  onReorder,
}) => {
  const classes = useStyles();
  const { title, surveys } = group;
  const hasSurveys = !isArrayEmpty(surveys);

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => setIsExpanded(prevExpanded => !prevExpanded);

  const handleCreateSurvey = useCallback(
    e => onCreateSurvey(e, group),
    [group, onCreateSurvey]
  );

  const handleEditTitle = useCallback(
    e => {
      e.stopPropagation();
      onEditTitle(e, group);
    },
    [group, onEditTitle]
  );

  const handleDelete = useCallback(
    e => {
      e.stopPropagation();
      onDelete(group);
    },
    [group, onDelete]
  );

  const handleDuplicate = useCallback(
    e => {
      e.stopPropagation();
      onDuplicate(group);
    },
    [group, onDuplicate]
  );

  const handleReorder = useCallback(
    result => {
      if (
        !result.destination ||
        result.source.index === result.destination.index
      )
        return;

      const updatedOrder = reorder(
        surveys,
        result.source.index,
        result.destination.index
      );

      return onReorder(group.id, updatedOrder);
    },
    [surveys, group, onReorder]
  );

  return (
    <div
      ref={dragRef}
      className={className}
      {...(dragProps ? { ...dragProps.draggableProps } : {})}
    >
      <div
        className={classNames(classes.header, {
          [classes.headerExpanded]: isExpanded && hasSurveys,
          [classes.headerBackground]: !isExpanded,
          [classes.disabledDrag]: isDragDisabled,
        })}
        onClick={toggleExpand}
      >
        {!isDragDisabled && (
          <ActionButton
            className={classes.dragButton}
            iconClass={classes.dragIcon}
            tooltipLabelClass={classes.dragTooltipLabel}
            type={ACTION_BUTTON_TYPES.DRAG}
            tooltipText={translations.dragTooltip}
            {...(dragProps ? { ...dragProps.dragHandleProps } : {})}
          />
        )}
        <TextBoxWithTooltip variant="h5" text={title} />
        <div className={classes.actionsWrapper}>
          <ActionButton
            className={classes.actionButton}
            type={ACTION_BUTTON_TYPES.EDIT}
            tooltipText={translations.actionTooltips.editTitle}
            onClickHandler={handleEditTitle}
          />
          <ActionButton
            className={classes.actionButton}
            iconClass={classes.duplicateIcon}
            type={ACTION_BUTTON_TYPES.COPY}
            tooltipText={translations.actionTooltips.duplicateGroup}
            onClickHandler={handleDuplicate}
          />
          <ActionButton
            className={classes.actionButton}
            type={ACTION_BUTTON_TYPES.DELETE}
            tooltipText={translations.actionTooltips.deleteSection}
            onClickHandler={handleDelete}
          />
          <ActionButton
            className={classes.actionButton}
            type={ACTION_BUTTON_TYPES.ARROW}
            iconClass={classNames(classes.expandIcon, {
              [classes.expandIconOpened]: isExpanded,
            })}
          />
        </div>
      </div>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <div>
          {hasSurveys && (
            <div className={classes.surveyHeader}>
              {surveyColumns.map((column, index) => (
                <Typography
                  className={classNames(classes.headerCell, {
                    [classes.titleHeaderCell]: index === 0,
                    [classes.actionCell]: column.isActionCell,
                  })}
                  component="div"
                  variant="overline"
                  key={`header_cell_${column.id}`}
                >
                  {column.title}
                </Typography>
              ))}
            </div>
          )}
          {hasSurveys && (
            <ReorderItems onSave={handleReorder}>
              {surveys.map(survey => (
                <Survey
                  key={`survey_item_${survey.id}`}
                  id={survey.id}
                  translations={translations}
                  survey={survey}
                  onSurveyClick={onSurveyClick}
                />
              ))}
            </ReorderItems>
          )}
          <CustomButton
            className={classes.button}
            type="addWithTextDarkNew"
            onClick={handleCreateSurvey}
          >
            {translations.createSurveyButton}
          </CustomButton>
        </div>
      </Collapse>
    </div>
  );
};

SurveyGroup.defaultProps = {
  className: undefined,
  isDragDisabled: false,
  dragRef: () => {},
  dragProps: null,
};

SurveyGroup.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
  surveyColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
  dragProps: PropTypes.object,
  dragRef: PropTypes.func,
  isDragDisabled: PropTypes.bool,
  onEditTitle: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSurveyClick: PropTypes.func.isRequired,
  onCreateSurvey: PropTypes.func.isRequired,
  onReorder: PropTypes.func.isRequired,
};

export default memo(SurveyGroup);
