import { createAction, handleActions } from 'redux-actions';
import http from '../../utility/http';
import { parseDuplicateParameters } from '../../utility/uiUtils';
import { PRIVATE_NOTES_DEFAULT_PARAMS } from '../../constants/pages';
import { API_MY_PRIVATE_NOTES } from '../../constants/apiRoutes';
import { isObjectEmpty } from '../../utility/helpers';

// ------------------------------------
// Constants
// ------------------------------------

const SET_PRIVATE_NOTES = 'SET_PRIVATE_NOTES';
const APPEND_PRIVATE_NOTES = 'APPEND_PRIVATE_NOTES';

const initState = {
  privateNotes: {},
};

// ------------------------------------
// Actions
// ------------------------------------

const setPrivateNotes = createAction(SET_PRIVATE_NOTES);
const appendPrivateNotes = createAction(APPEND_PRIVATE_NOTES);

// ------------------------------------
// Reducers
// ------------------------------------

const reducer = {
  [SET_PRIVATE_NOTES]: (state, { payload }) => {
    return { ...state, privateNotes: { ...payload } };
  },
  [APPEND_PRIVATE_NOTES]: (state, { payload }) => {
    return isObjectEmpty(payload)
      ? { ...state, privateNotes: payload }
      : {
          ...state,
          privateNotes: {
            ...payload,
            results: state.privateNotes.results
              ? [...state.privateNotes.results, ...payload.results]
              : [...payload.results],
          },
        };
  },
};

// ------------------------------------
// API calls
// ------------------------------------

export const getPrivateNotes = async (
  dispatch,
  params = {},
  isLoadMore = false
) => {
  return http
    .get(API_MY_PRIVATE_NOTES, {
      params: {
        ...PRIVATE_NOTES_DEFAULT_PARAMS,
        ...params,
      },
      paramsSerializer: p => parseDuplicateParameters(p),
    })
    .then(({ data }) =>
      dispatch(isLoadMore ? appendPrivateNotes(data) : setPrivateNotes(data))
    );
};

// ------------------------------------
// "plain" action functions
// ------------------------------------

export const clearPrivateNotes = () => setPrivateNotes(initState.notes);

export default handleActions(reducer, initState);
