import { addDays, differenceInMinutes, parseJSON } from 'date-fns';
import { RECURRING_TYPES } from 'constants/oneOnOne';
import { ReactComponent as PrivateNoteIcon } from '../../../assets/icons/anonymous-user.svg';
import {
  isArrayEmpty,
  isObjectEmpty,
  isUserAppAccessForbidden,
  trimString,
  getObjectToNumberArray,
} from '../../../utility/helpers';
import {
  forbiddenCharacters,
  isEmpty,
  validateLength,
} from '../../../utility/validation';
import { prepareTasksForSave } from '../../../utility/tasks';
import { isDateInPast, isDateInFuture } from '../../../utility/dateUtils';
import { FIELD_TYPES } from '../../../constants/fieldTypes';
import { CREATABLE_TAG_FIELD } from '../../../constants/tags';

import { getPersonFullName } from '../../../utility/uiUtils';

export const getNotifyAll = user => ({
  name: 'notify',
  isDisabled: values =>
    (user && isUserAppAccessForbidden(user)) ||
    isDateInPast(values.meeting_time),
});

export const getDialogTitle = (
  labels,
  recordId,
  isOneOnOneRequest = false,
  isReschedule = false
) => {
  if (isOneOnOneRequest) {
    return labels.request;
  } else if (isReschedule) {
    return labels.reschedule;
  } else if (recordId) {
    return labels.edit;
  }
  return labels.add;
};

export const getFields = (
  classes,
  labels,
  record,
  topics,
  user,
  onCreateTag,
  getUserPlans,
  isReschedule = false,
  newRecurring = false
) => [
  ...(user && !isObjectEmpty(user)
    ? [
        {
          name: 'userFullName',
          type: FIELD_TYPES.TEXT,
          translationKey: 'with',
          isDisabled: true,
          fieldWrapperClass: classes.user,
        },
      ]
    : [
        {
          name: 'user',
          type: FIELD_TYPES.SELECT,
          translationKey: 'with',
          required: true,
          optionsKey: 'allUsers',
          isUser: true,
          shouldReturnOption: true,
          parser: {
            value: 'id',
            label: 'first_name',
          },
          fieldWrapperClass: classes.user,
          dependants: [
            {
              name: 'plans',
              value: [],
            },
            {
              name: 'plans',
              callbackFnc: (prevUser, currentUser) => {
                if (prevUser?.id !== currentUser?.id) {
                  return getUserPlans(currentUser?.id);
                }
              },
            },
          ],
          validators: [
            { type: 'required', validator: value => !isObjectEmpty(value) },
          ],
        },
      ]),
  {
    name: 'meeting_time',
    translationKey: 'meetingStatus',
    type: FIELD_TYPES.MEETING_STATUS,
    fieldWrapperClass: classes.status,
    parent: 'meeting_impression',
  },
  {
    name: 'meeting_time',
    type: FIELD_TYPES.DATE_PICKER,
    fieldWrapperClass: classes.date,
    translationKey: 'meetingDate',
    dependants: [
      {
        name: 'recurring',
        resetFnc: (prevValue, currentValue, recurringValue) => {
          return isDateInPast(currentValue)
            ? RECURRING_TYPES[0].id
            : recurringValue;
        },
      },
    ],
    isToolbarDisabled: true,
    isDisabled:
      !isReschedule &&
      !newRecurring &&
      record?.id &&
      record?.meeting_time &&
      isDateInPast(record?.meeting_time),
  },
  {
    name: 'meeting_time',
    type: FIELD_TYPES.TIME_PICKER,
    fieldWrapperClass: classes.time,
    translationKey: 'meetingTime',
    dependants: [
      {
        name: 'recurring',
        resetFnc: (prevValue, currentValue, recurringValue) => {
          return isDateInPast(currentValue)
            ? RECURRING_TYPES[0].id
            : recurringValue;
        },
      },
    ],
    isDisabled:
      !isReschedule &&
      !newRecurring &&
      record?.id &&
      record?.meeting_time &&
      isDateInPast(record?.meeting_time),
  },
  {
    name: 'recurring',
    type: FIELD_TYPES.SELECT,
    translationKey: 'recurring',
    optionsKey: 'recurring',
    shouldReturnOption: false,
    isDisabledCheck: values => {
      return values?.meeting_time && isDateInPast(values?.meeting_time);
    },
    parser: {
      value: 'id',
      label: 'name',
    },
    fieldWrapperClass: classes.recurring,
  },
  {
    name: 'agenda',
    type: FIELD_TYPES.MEETING_AGENDA,
    translationKey: 'meetingAgenda',
    fieldWrapperClass: classes.agenda,
    topics,
    required: true,
    validators: [
      {
        validator: values => {
          const { selectedTopics, hasCustomTopic } = values;

          return !isArrayEmpty(selectedTopics) || hasCustomTopic;
        },
        type: 'minTopicsLength',
      },
      {
        validator: values => {
          const { selectedTopics, hasCustomTopic } = values;

          return (
            (selectedTopics.length <= 4 && hasCustomTopic) ||
            (selectedTopics.length <= 5 && !hasCustomTopic)
          );
        },
        type: 'maxTopicsLength',
      },
      {
        type: 'customTopicRequired',
        validator: values =>
          !values.hasCustomTopic ||
          isEmpty(trimString(values.customTopic?.name)),
      },
      {
        type: 'forbiddenCharacters',
        validator: values => forbiddenCharacters(values.customTopic?.name),
      },
      {
        type: 'customTopicMinMaxLength',
        validator: values =>
          !values.hasCustomTopic ||
          validateLength(trimString(values.customTopic?.name), 2, 50),
      },
    ],
  },
  {
    name: 'tags',
    type: FIELD_TYPES.SELECT,
    optionsKey: 'categories',
    fieldWrapperClass: classes.tags,
    creatableOptionField: CREATABLE_TAG_FIELD,
    isCategorized: true,
    hasCategoryColorBox: true,
    isMulti: true,
    hasHash: true,
    isCreatable: true,
    isTag: true,
    parser: {
      value: 'id',
      label: 'name',
      categoryLabel: 'name',
      categoryValue: 'tags',
    },
    onCreateOption: onCreateTag,
  },
  {
    name: 'sharedNote',
    type: FIELD_TYPES.RICH_TEXT_EDITOR,
    fieldWrapperClass: classes.sharedNote,
    labelClass: classes.editorLabel,
    labelTooltip: {
      text: labels.sharedNote.tooltipText,
    },
    validators: [
      {
        type: 'noteMinMaxLength',
        validator: fieldValue => validateLength(fieldValue, 0, 20000),
      },
    ],
  },
  {
    name: 'note',
    type: FIELD_TYPES.RICH_TEXT_EDITOR,
    fieldWrapperClass: classes.privateNote,
    labelWrapperClass: classes.editorLabelWrapper,
    labelClass: classes.editorLabel,
    labelTooltip: {
      text: labels.note.tooltipText,
      icon: PrivateNoteIcon,
    },
    validators: [
      {
        type: 'noteMinMaxLength',
        validator: fieldValue => validateLength(fieldValue, 0, 20000),
      },
    ],
  },
  {
    name: 'meeting_impression',
    type: FIELD_TYPES.MEETING_IMPRESSION,
    fieldWrapperClass: classes.meetingImpression,
    translationKey: 'meetingImpression',
    isFutureMeeting: values =>
      values.isOneOnOneRequest ||
      isDateInFuture(values.meeting_time) ||
      !!(record?.id && isDateInFuture(record.meeting_time)),
  },
  {
    name: 'plans',
    type: FIELD_TYPES.SELECT,
    optionsKey: 'userPlans',
    fieldWrapperClass: classes.plans,
    labelClass: classes.editorLabel,
    isMulti: true,
    shouldDisableSort: true,
    parser: {
      value: 'id',
      label: 'title',
    },
  },
];

export const prepareRecordData = (
  currentRecord,
  currentEmployee,
  currentUser,
  isOneOnOneRequest = false,
  isReschedule = false,
  newRecurring = false
) => {
  const today = new Date();

  if (currentRecord && currentRecord?.id) {
    const customTopic = currentRecord?.agenda?.find(
      item => !item.is_recommended && item.creator === currentUser.id
    );
    const managerTopics = currentRecord?.agenda?.filter(
      item => item.is_recommended && item.creator === currentUser.id
    );

    if (isReschedule || newRecurring) {
      return {
        user: currentEmployee || {},
        userFullName: currentEmployee ? getPersonFullName(currentEmployee) : '',
        meeting_impression: null,
        meeting_time: addDays(
          new Date(today.getFullYear(), today.getMonth(), today.getDate(), 12),
          7
        ),
        notify: false,
        agenda: {
          selectedTopics: [...(managerTopics || [])],
          customTopic: customTopic || null,
          hasCustomTopic: !!customTopic,
        },
        tags: getObjectToNumberArray(currentRecord.tags),
        plans: getObjectToNumberArray(currentRecord.plans),
        sharedNote: currentRecord?.shared_note || '',
        note: currentRecord?.note?.text || '',
        recurring: currentRecord.recurring,
        isReschedule,
        ...(isReschedule ? { tasks: currentRecord.tasks } : {}),
      };
    }
    return {
      id: currentRecord.id,
      user: currentEmployee || {},
      userFullName: currentEmployee ? getPersonFullName(currentEmployee) : '',
      meeting_impression: currentRecord.meeting_impression || null,
      meeting_time: isOneOnOneRequest
        ? addDays(
            new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate(),
              12
            ),
            7
          )
        : parseJSON(currentRecord.meeting_time),
      notify: false,
      agenda: {
        selectedTopics: isOneOnOneRequest ? [] : [...(managerTopics || [])],
        customTopic: customTopic || null,
        hasCustomTopic: !!customTopic,
      },
      tags: getObjectToNumberArray(currentRecord.tags),
      plans: getObjectToNumberArray(currentRecord.plans),
      note: currentRecord?.note?.text || '',
      sharedNote: currentRecord?.shared_note || '',
      recurring: currentRecord.recurring,
      tasks: currentRecord.tasks || [],
      isReschedule,
      isOneOnOneRequest,
    };
  }

  return {
    user: currentEmployee || {},
    userFullName: currentEmployee ? getPersonFullName(currentEmployee) : '',
    meeting_impression: null,
    meeting_time: addDays(
      new Date(today.getFullYear(), today.getMonth(), today.getDate(), 12),
      7
    ),
    recurring: RECURRING_TYPES[0].id,
    tags: [],
    tasks: [],
    plans: [],
    note: '',
    agenda: {
      selectedTopics: [],
      customTopic: null,
      hasCustomTopic: false,
    },
    notify: false,
    sharedNote: '',
    isReschedule,
  };
};

export const prepareDataForSave = (values, record, newRecurring) => {
  const {
    agenda,
    meeting_impression,
    meeting_time,
    note,
    sharedNote,
    tags,
    isOneOnOneRequest,
    isReschedule,
    recurring,
    notify,
    user,
    tasks,
    plans,
  } = values;

  const data = {
    user,
    isOneOnOneRequest,
    isReschedule,
    agenda: isArrayEmpty(agenda.selectedTopics)
      ? []
      : getObjectToNumberArray(agenda.selectedTopics),
    tags,
    plans,
    hasCustomTopic: !!agenda?.hasCustomTopic,
    customTopic: agenda?.customTopic,
    shared_note: sharedNote || undefined,
    note: note || undefined,
    tasks: prepareTasksForSave(tasks || [], !isReschedule, isReschedule),
    recurring,
  };

  if (
    !record?.id ||
    isOneOnOneRequest ||
    differenceInMinutes(
      new Date(meeting_time),
      new Date(record?.meeting_time)
    ) !== 0 ||
    newRecurring ||
    isReschedule
  ) {
    data.meeting_time = meeting_time;
  }

  if (meeting_impression && meeting_impression !== record?.meeting_impression) {
    data.meeting_impression = meeting_impression;
  }

  if (record?.id && !isReschedule) {
    data.shared_note = sharedNote || '';
    data.note = note || '';
  }

  if (notify) {
    data.notify = !isDateInPast(meeting_time);
  }

  return data;
};
