import { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Tree from 'react-d3-tree';
import ChartItem from './chartItem';
import http from '../../../utility/http';
import { api_user_organization } from '../../../constants/apiRoutes';
import { formatUserForOrgChart } from '../../../utility/uiUtils';
import { canSeeEmployeeProfile, checkUserRole } from '../../../utility/helpers';
import { ROLES } from '../../../constants/rolesAndPermissionList';

const styles = ({ palette: { primary } }) => ({
  chartContainer: {
    minHeight: 'calc(100vh - 255px)',
    width: 'calc(100% - 5px)',
    border: `1px solid ${primary.bluish7}`,
    borderRadius: 8,

    '& .rd3t-link': {
      stroke: primary.blue2,
    },
    '& .rd3t-tree-container svg': {
      minHeight: 'calc(100vh - 255px)',
    },
  },
  chartContainerUser: {
    minHeight: 'calc(100vh - 305px)',
    '& .rd3t-tree-container svg': {
      minHeight: 'calc(100vh - 310px)',
    },
  },
});

class OrganizationChart extends PureComponent {
  constructor(props) {
    super(props);

    this.treeContainerRef = createRef();

    this.state = {
      translate: {
        x: 0,
        y: 100,
      },
    };
  }

  componentDidMount() {
    const dimensions = this.treeContainerRef.current.getBoundingClientRect();

    this.setState(prevState => ({
      translate: {
        ...prevState.translate,
        x: dimensions.width / 2,
      },
    }));
  }

  handleGoToProfile = id => () => {
    const { onGoToProfile } = this.props;

    onGoToProfile(id);
  };

  getChild = async node => {
    const { currentUser, organizationSettings, setEmployeeRespondents } =
      this.props;

    const response = await http.get(api_user_organization(node.id));
    const responseData = response.data.map(user =>
      formatUserForOrgChart(
        user,
        canSeeEmployeeProfile(
          currentUser,
          user.id,
          organizationSettings.global_see_himself
        )
      )
    );

    setEmployeeRespondents(node.id, responseData);
  };

  renderUser = user => {
    return (
      <ChartItem
        node={user.nodeDatum}
        triggerNodeToggle={user.toggleNode}
        onGoToProfile={this.handleGoToProfile(user.nodeDatum.person.id)}
        getChild={this.getChild}
      />
    );
  };

  render() {
    const { classes, currentUser, hierarchyTree } = this.props;
    const { translate } = this.state;
    const isUser = checkUserRole(currentUser.role, ROLES.USER);

    return (
      <div
        ref={this.treeContainerRef}
        className={classNames(classes.chartContainer, {
          [classes.chartContainerUser]: isUser,
        })}
      >
        <Tree
          data={hierarchyTree}
          renderCustomNodeElement={this.renderUser}
          orientation="vertical"
          translate={translate}
          pathFunc="step"
          nodeSize={{ x: 186, y: 200 }}
          separation={{ siblings: 1.1, nonSiblings: 2 }}
        />
      </div>
    );
  }
}

OrganizationChart.propTypes = {
  classes: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  organizationSettings: PropTypes.object.isRequired,
  hierarchyTree: PropTypes.object.isRequired,
  setEmployeeRespondents: PropTypes.func.isRequired,
  onGoToProfile: PropTypes.func.isRequired,
};

export default withStyles(styles)(OrganizationChart);
