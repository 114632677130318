import PropTypes from 'prop-types';
import UserAvatar from '../userAvatar';

const UserLabel = ({ user, isDisabled }) => (
  <UserAvatar
    variant="subtitle1"
    user={user}
    clickableCaption={!isDisabled}
    small
    caption
  />
);

UserLabel.defaultProps = {
  isDisabled: false,
};

UserLabel.propTypes = {
  user: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    id: PropTypes.number,
  }).isRequired,
  isDisabled: PropTypes.bool,
};

export default UserLabel;
