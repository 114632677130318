import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { PLACEMENTS } from 'constants/tags';
import PeoplePageContainer from '../../components/shared/peoplePageContainer';
import PeopleActionPlansPage from '../../components/pages/peopleActionPlansPage';
import { actionPlansSelector } from '../../store/selectors/actionPlansSelector';
import { peopleSelector } from '../../store/selectors/peopleSelector';
import { tagsSelector } from '../../store/selectors/tagsSelector';
import { coursesSelector } from '../../store/selectors/coursesSelector';
import {
  getUserActionPlans,
  clearAllActionPlans,
} from '../../store/modules/actionPlans';
import {
  getTagsCategories,
  clearTagsCategories,
  addMultipleTags,
  getPageQuickFilters,
  clearPageQuickFilters,
} from '../../store/modules/tags';
import {
  getCourseEnrolledUsers,
  clearCourseEnrolledUsers,
} from '../../store/modules/courseDetails';
import { getCourses, clearCourses } from '../../store/modules/courses';
import { getAllUsers, clearAllUsers } from '../../store/modules/people';
import { useTranslations } from '../../utility/useTranslations';
import { APP_PAGES } from '../../constants/pages';

const mapStateToProps = state => ({
  ...actionPlansSelector(state),
  user: state.person,
  ...tagsSelector(state),
  ...coursesSelector(state),
  allUsers: [...peopleSelector(state).allUsers],
});

const mapDispatchToProps = dispatch => ({
  getUserActionPlans: (userId, data, isLoadMore) =>
    getUserActionPlans(dispatch, userId, data, isLoadMore),
  clearAllActionPlans: () => clearAllActionPlans(dispatch),
  getTagsCategories: () => getTagsCategories(dispatch),
  getPageQuickFilters: () =>
    getPageQuickFilters(dispatch, PLACEMENTS.ACTION_POINTS.id),
  clearTagsCategories: () => dispatch(clearTagsCategories()),
  clearPageQuickFilters: () => dispatch(clearPageQuickFilters()),
  addMultipleTags: data => dispatch(addMultipleTags(data)),
  getAllUsers: () => getAllUsers(dispatch),
  clearAllUsers: () => dispatch(clearAllUsers()),
  getCourses: params => getCourses(dispatch, params),
  clearCourses: () => dispatch(clearCourses()),
  getCourseEnrolledUsers: (courseId, params) =>
    getCourseEnrolledUsers(dispatch, courseId, params),
  clearCourseEnrolledUsers: () => dispatch(clearCourseEnrolledUsers()),
});

const PeopleActionPlansPageView = props => {
  const { id } = useParams();
  const userId = (!Number.isNaN(Number(id)) && +id) || -1;
  const translations = useTranslations(APP_PAGES.PEOPLE_ACTION_PLANS);

  return (
    <PeoplePageContainer {...props} userId={userId} translations={translations}>
      <PeopleActionPlansPage />
    </PeoplePageContainer>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PeopleActionPlansPageView);
