import {
  forbiddenCharacters,
  validateLength,
} from '../../../../utility/validation';
import { FIELD_TYPES } from '../../../../constants/fieldTypes';

export const FIELD = {
  name: 'comment',
  type: FIELD_TYPES.TEXT,
  multiline: true,
  rows: 9,
  validators: [
    {
      type: 'forbiddenCharacters',
      validator: forbiddenCharacters,
    },
    {
      type: 'maxLength',
      validator: fieldValue => validateLength(fieldValue, 0, 2500),
    },
  ],
};
