import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import AttributesList from '../attributesList';

const useStyles = makeStyles(({ spacing }) => ({
  title: {
    marginBottom: spacing(6),
  },
}));

const SurveySummaryReviews = ({ className, translations, reviews }) => {
  const classes = useStyles();

  return (
    <div className={className}>
      <Typography className={classes.title} variant="h5">
        {translations.title}
      </Typography>
      <AttributesList attributes={reviews} isHorizontalList isActive />
    </div>
  );
};

SurveySummaryReviews.defaultProps = {
  className: '',
};

SurveySummaryReviews.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  reviews: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SurveySummaryReviews;
