import { createAction, handleActions } from 'redux-actions';

// ------------------------------------
// Constants
// ------------------------------------

const SET_LOADING = 'SET_LOADING';
const SET_SECTION_LOADER = 'SET_SECTION_LOADER';
const CLEAR_SECTION_LOADERS = 'CLEAR_SECTION_LOADERS';
const INCREMENT = 1;
const DECREMENT = -1;

export const constants = {
  SET_LOADING,
  INCREMENT,
  DECREMENT,
};

const initialState = {
  loading: 0,
  sectionLoaders: {},
};

// ------------------------------------
// Actions
// ------------------------------------

export const setLoading = createAction(SET_LOADING);
export const setSectionLoader = createAction(
  SET_SECTION_LOADER,
  (sectionName, increment = false) => {
    return {
      section: sectionName,
      value: increment ? constants.INCREMENT : constants.DECREMENT,
    };
  }
);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_LOADING]: (state, { payload }) => {
    return { ...state, loading: state.loading + payload };
  },
  [SET_SECTION_LOADER]: (state, { payload }) => {
    const { section, value } = payload;

    return {
      ...state,
      sectionLoaders: {
        ...state.sectionLoaders,
        [section]:
          state.sectionLoaders[section] !== undefined
            ? state.sectionLoaders[section] + value
            : value,
      },
    };
  },
  [CLEAR_SECTION_LOADERS]: state => {
    return { ...state, sectionLoaders: initialState.sectionLoaders };
  },
};

export default handleActions(reducers, initialState);
