import { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import { primaryPalette } from 'styles/theme';

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: ({ isSmall }) => (isSmall ? 4 : 8),
    flexShrink: 0,
    height: ({ isSmall, isLarge }) => {
      if (isLarge) return 32;
      if (isSmall) return 16;
      return 24;
    },
    width: ({ isSmall, isLarge }) => {
      if (isLarge) return 32;
      if (isSmall) return 16;
      return 24;
    },
    transition: 'background-color .3s ease',
  },
}));

const ColorBox = ({ className, bgColor, isSmall, isLarge, onClickHandler }) => {
  const classes = useStyles({ isSmall, isLarge });

  const handleClick = useCallback(
    () => onClickHandler(bgColor),
    [bgColor, onClickHandler]
  );

  return (
    <div
      className={classNames(classes.root, className)}
      onClick={handleClick}
      style={{ backgroundColor: bgColor || primaryPalette.bluish1 }}
    />
  );
};

ColorBox.defaultProps = {
  className: null,
  isLarge: false,
  isSmall: false,
  bgColor: '',
  onClickHandler: () => {},
};

ColorBox.propTypes = {
  className: PropTypes.string,
  isSmall: PropTypes.bool,
  isLarge: PropTypes.bool,
  bgColor: PropTypes.string,
  onClickHandler: PropTypes.func,
};

export default memo(ColorBox);
