import { connect } from 'react-redux';
import { useParams } from 'react-router';
import PeopleTeamPage from '../../components/pages/peopleTeamPage';
import PeoplePageContainer from '../../components/shared/peoplePageContainer';
import { updatePersonRole, getPerson } from '../../store/modules/person';
import { useTranslations } from '../../utility/useTranslations';
import { APP_PAGES } from '../../constants/pages';

const mapDispatchToProps = dispatch => ({
  getPerson: id => getPerson(dispatch, id),
  updatePersonRole: (id, data) => updatePersonRole(dispatch, id, data),
});

const PeopleTeamPageView = props => {
  const { id } = useParams();
  const userId = (!Number.isNaN(Number(id)) && +id) || -1;
  const translations = useTranslations(APP_PAGES.PEOPLE_TEAM);

  return (
    <PeoplePageContainer {...props} userId={userId} translations={translations}>
      <PeopleTeamPage />
    </PeoplePageContainer>
  );
};

export default connect(null, mapDispatchToProps)(PeopleTeamPageView);
