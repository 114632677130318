import { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import AttributeChip from '../attributeChip';
import ActionButton from '../actionButton';
import { getProgressWidth } from '../../../utility/uiUtils';
import { PLACEHOLDER_ATTRIBUTE } from '../../../constants/attributes';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    minWidth: 0,
    width: '100%',
    margin: spacing(0, 1, 1, 0),
  },
  content: {
    backgroundColor: primary.bluish8,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    zIndex: 9,
    flexGrow: 1,
    maxWidth: 300,
    minWidth: 0,
  },
  progress: {
    '&:before': {
      content: '""',
      backgroundColor: ({ attribute }) => attribute.color,
      position: 'absolute',
      left: 0,
      top: 0,
      height: 28,
      width: ({ attribute }) => getProgressWidth(attribute.score).width,
      zIndex: 8,
    },
  },
  attribute: {
    backgroundColor: 'transparent',
    position: 'relative',
    zIndex: 11,
    minWidth: 0,
  },
  attributeDraggable: {
    paddingLeft: 0,
  },
  score: {
    display: 'flex',
    alignItems: 'center',
  },
  scoreValue: {
    color: primary.bluish2,
    marginRight: spacing(1),
  },
  scoreMaxValue: {
    color: primary.bluish2,
    marginLeft: spacing(1),
  },
  removeButton: {
    flexShrink: 0,
    width: 24,
    height: 24,
    marginLeft: spacing(1),
    marginRight: 'auto',
  },
  removeButtonIcon: {
    width: 16,
    height: 16,
  },
  dragButton: {
    position: 'relative',
    width: 22,
    height: 22,
    flexShrink: 0,
    margin: spacing(0, 1),
    zIndex: 11,
    '& button': {
      width: 22,
      height: 22,
    },
  },
  dragIcon: {
    width: 8,
    height: 14,
  },
  dragTooltipLabel: {
    fontFamily: 'ProximaNova-Regular',
  },
}));

const AttributeChipWithScore = ({
  translations,
  attribute,
  dragRef,
  dragProps,
  isDraggable,
  hasRemove,
  onRemove,
  ...rest
}) => {
  const classes = useStyles({ attribute });

  const handleRemove = useCallback(
    () => onRemove(attribute),
    [attribute, onRemove]
  );

  return (
    <div
      ref={dragRef}
      className={classes.root}
      id={attribute.id}
      {...(dragProps ? { ...dragProps.draggableProps } : {})}
    >
      <div className={classNames(classes.content, classes.progress)}>
        {isDraggable && (
          <ActionButton
            className={classes.dragButton}
            iconClass={classes.dragIcon}
            tooltipLabelClass={classes.dragTooltipLabel}
            type={ACTION_BUTTON_TYPES.DRAG}
            tooltipText={translations.dragTooltip}
            {...(dragProps ? { ...dragProps.dragHandleProps } : {})}
          />
        )}
        <AttributeChip
          customClass={classNames(classes.attribute, {
            [classes.attributeDraggable]: isDraggable,
          })}
          attribute={attribute}
          name={attribute.name}
          isSelected
          {...rest}
        />
      </div>
      {hasRemove && (
        <ActionButton
          className={classes.removeButton}
          iconClass={classes.removeButtonIcon88}
          type={ACTION_BUTTON_TYPES.CLOSE}
          size="small"
          onClickHandler={handleRemove}
        />
      )}
      <div className={classes.score}>
        <Typography variant="subtitle2" className={classes.scoreValue}>
          {attribute?.score}
        </Typography>
        /
        <Typography variant="body2" className={classes.scoreMaxValue}>
          {PLACEHOLDER_ATTRIBUTE.max_score}
        </Typography>
      </div>
    </div>
  );
};

AttributeChipWithScore.defaultProps = {
  translations: {},
  dragRef: () => {},
  dragProps: null,
  hasRemove: false,
  isDraggable: false,
  onRemove: () => {},
};

AttributeChipWithScore.propTypes = {
  translations: PropTypes.object,
  dragProps: PropTypes.object,
  attribute: PropTypes.object.isRequired,
  hasRemove: PropTypes.bool,
  isDraggable: PropTypes.bool,
  dragRef: PropTypes.func,
  onRemove: PropTypes.func,
};

export default memo(AttributeChipWithScore);
