import { combineReducers } from 'redux';
import { createRouterReducer } from '@lagunovsky/redux-react-router';
import auth from './modules/auth';
import dashboard from './modules/dashboard';
import loading from './modules/loading';
import people from './modules/people';
import person from './modules/person';
import attribute from './modules/attribute';
import dialogs from './modules/dialogs';
import attributes from './modules/attributes';
import surveys from './modules/surveys';
import survey from './modules/survey';
import surveySummaries from './modules/surveySummaries';
import surveySummary from './modules/surveySummary';
import fillSurvey from './modules/fillSurvey';
import tags from './modules/tags';
import actionPlans from './modules/actionPlans';
import timeline from './modules/timeline';
import employeesHierarchy from './modules/employeesHierarchy';
import competenceMap from './modules/competenceMap';
import personReports from './modules/personReports';
import courses from './modules/courses';
import courseDetails from './modules/courseDetails';
import notes from './modules/notes';
import userCourses from './modules/userCourses';
import privateNotes from './modules/privateNotes';
import actionPlanDetails from './modules/actionPlanDetails';
import oneOnOne from './modules/oneOnOne';
import oneOnOneReport from './modules/oneOnOneReport';
import personOneOnOne from './modules/personOneOnOne';
import pendingSurveys from './modules/pendingSurveys';
import oneOnOneTopics from './modules/oneOnOneTopics';
import organizationSettings from './modules/organizationSettings';
import companyOverview from './modules/companyOverview';
import jobTitles from './modules/jobTitles';
import levelsAndTracks from './modules/levelsAndTracks';
import personCareer from './modules/personCareer';
import signUp from './modules/signUp';
import instructionGuide from './modules/instructionGuide';

const createRootReducer = history =>
  combineReducers({
    router: createRouterReducer(history),
    actionPlans,
    actionPlanDetails,
    attribute,
    attributes,
    auth,
    courses,
    courseDetails,
    companyOverview,
    competenceMap,
    dialogs,
    dashboard,
    employeesHierarchy,
    fillSurvey,
    instructionGuide,
    loading,
    people,
    person,
    personReports,
    surveys,
    survey,
    surveySummaries,
    surveySummary,
    tags,
    timeline,
    notes,
    userCourses,
    signUp,
    privateNotes,
    oneOnOne,
    oneOnOneReport,
    oneOnOneTopics,
    personOneOnOne,
    pendingSurveys,
    organizationSettings,
    jobTitles,
    levelsAndTracks,
    personCareer,
  });

export default createRootReducer;
