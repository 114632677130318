import { alpha } from '@material-ui/core';
import { primaryPalette } from '../../../../styles/theme';
import { isArrayEmpty } from '../../../../utility/helpers';

const OPACITY = 0.4;

const MIN_CHART_HEIGHT = 300;

const DEFAULT_ITEM_HEIGHT = 30;

export const CHART_COLORS = {
  MAIN: primaryPalette.bluish1,
  AXES: primaryPalette.bluish4,
  GRID: primaryPalette.bluish7,
  LINE_COLORS: [
    alpha('#FFE600', OPACITY),
    alpha('#3E60B7', OPACITY),
    alpha('#EF9CC2', OPACITY),
    alpha('#EDB77E', OPACITY),
    alpha('#3EC8F3', OPACITY),
    alpha('#4FB73E', OPACITY),
  ],
};

export const getOptions = (
  translations,
  totalYAxisItems,
  hasNeutralAnswer,
  isAverageView
) => ({
  animation: {
    delay: 0,
  },
  maintainAspectRatio: false,
  responsive: true,
  interaction: {
    intersect: false,
  },
  layout: {
    autoPadding: false,
  },
  elements: {
    point: {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      borderWidth: 2,
      radius: 4,
      hoverRadius: 4,
      hoverBorderWidth: 2,
    },
  },
  plugins: {
    filler: {
      propagate: true,
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      display: false,
    },
  },
  clip: false,
  scales: {
    x: {
      grid: {
        borderDash: [4, 4],
        drawTicks: false,
        borderColor: CHART_COLORS.AXES,
        color: CHART_COLORS.GRID,
      },
      ticks: {
        font: {
          size: 14,
          family: 'ProximaNova-Bold',
        },
        padding: 16,
        color: CHART_COLORS.MAIN,
        z: 10,
      },
      afterFit: axis => {
        axis.paddingLeft = 8;
        axis.paddingRight = 0;
      },
    },
    y: {
      type: 'linear',
      display: true,
      position: 'right',
      min: 0,
      max: hasNeutralAnswer ? totalYAxisItems - 1 : totalYAxisItems,
      grid: {
        borderDash: [4, 4],
        drawTicks: false,
        borderColor: CHART_COLORS.AXES,
        color: CHART_COLORS.GRID,
      },
      ticks: {
        crossAlign: 'center',
        font: {
          size: 14,
          family: 'ProximaNova-Bold',
        },
        padding: 8,
        color: CHART_COLORS.MAIN,
        stepSize: 1,
        callback: (value, index) => {
          if (isAverageView) return index;
          if (index === 0)
            return hasNeutralAnswer ? translations.neutral.shorthand : '';
          return `${translations.answerShorthand}${index}`;
        },
        z: 10,
      },
      afterFit: axis => {
        axis.paddingTop = 8;
      },
    },
  },
  hover: {
    mode: 'dataset',
    intersect: false,
  },
});

export const getChartHeight = ({ yMax }) => {
  const height = yMax * DEFAULT_ITEM_HEIGHT;

  if (height > MIN_CHART_HEIGHT) return height;

  return MIN_CHART_HEIGHT;
};

export const getXAxisLabels = (translations, totalXAxisItems) =>
  Array.from(
    { length: totalXAxisItems },
    (_, i) => `${translations.questionShorthand}${i + 1}`
  );

export const prepareChartResults = (results, isAverageView) => {
  if (isArrayEmpty(results)) return [{ data: [] }];

  return results.reduce(
    (acc, dataset, index) => [
      ...acc,
      {
        fill: true,
        data: [...dataset],
        borderColor: isAverageView ? CHART_COLORS.MAIN : 'transparent',
        borderWidth: 2,
        backgroundColor: CHART_COLORS.LINE_COLORS[index],
        hoverBorderColor: CHART_COLORS.MAIN,
      },
    ],
    []
  );
};
