import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router';
import { authSelector } from '../../store/selectors/authSelector';
import PageNotFound from '../../components/pages/pageNotFound';
import PageContainer from '../../components/shared/pageContainer';
import { useTranslations } from '../../utility/useTranslations';
import { APP_PAGES } from '../../constants/pages';
import { grantedPermissionsSelector } from '../../store/selectors/grantedPermissionsSelector';
import { organizationSettingsSelector } from '../../store/selectors/organizationSettingsSelector';
import { setAuthInfo } from '../../store/modules/auth';

const mapStateToProps = state => ({
  ...authSelector(state),
  ...grantedPermissionsSelector(state),
  ...organizationSettingsSelector(state),
});

const mapDispatchToProps = dispatch => ({
  setAuthInfo: data => dispatch(setAuthInfo(data)),
});

const PageNotFoundView = props => {
  const location = useLocation();
  const navigate = useNavigate();
  const translations = useTranslations(APP_PAGES.NOT_FOUND);

  if (props.auth && props.auth.access) {
    return (
      <PageContainer
        {...props}
        translations={translations}
        location={location}
        navigate={navigate}
        isFullWidthContent
        shouldRemoveSpacing
      >
        <PageNotFound isAuthorized />
      </PageContainer>
    );
  }

  return <PageNotFound translations={translations} navigate={navigate} />;
};

PageNotFoundView.propTypes = {
  auth: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageNotFoundView);
