import { useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import UserLink from '../userLink';
import OneOnOneRequestCard from '../oneOnOneRequestCard';
import OneOnOneTimeIndicator from '../oneOnOneTimeIndicator';
import RequestedIconTooltip from '../requestedIconTooltip';
import PlansIconTooltip from '../plansIconTooltip';
import TasksIconTooltip from '../tasksIconTooltip';
import RecurringIconTooltip from '../recuringIconTooltip';
import AsyncListBadge from '../asyncListBadge';
import OneOnOneCalendar from '../oneOnOneCalendar';
import Tag from '../tag';
import { ReactComponent as TagsIcon } from '../../../assets/icons/tag-dark.svg';
import { formatDate, isDateInFuture } from '../../../utility/dateUtils';
import {
  getRequestCardLabels,
  checkIsManagerChanged,
} from '../../../utility/oneOnOne';
import { isArrayEmpty } from '../../../utility/helpers';
import {
  DATE_TIME_FORMAT,
  RECURRING_TYPES_MAP,
} from '../../../constants/oneOnOne';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    backgroundColor: primary.white,
    boxSizing: 'border-box',
    borderRadius: 16,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(4),
    width: 270,
    minHeight: 170,
    userSelect: 'none',
  },
  request: {
    backgroundColor: primary.white,
    boxSizing: 'border-box',
    borderRadius: 16,
    border: 'none',
    cursor: 'default',
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(4),
    width: 270,
    minHeight: 170,
    userSelect: 'none',
    '&:hover': {
      border: 'none',
    },
  },
  requestContent: {
    paddingLeft: 0,
  },
  user: {
    marginBottom: spacing(2),
  },
  meetingDate: {
    color: primary.bluish4,
    marginTop: spacing(1.5),
  },
  infoWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(0,max-content))',
    gridColumnGap: 8,
    alignItems: 'center',
    marginTop: 'auto',
  },
  noSpacing: {
    marginLeft: 0,
  },
  badgeIcon: {
    marginTop: spacing(-0.5),
  },
}));

const OneOnOneMiniCard = ({
  className,
  translations,
  oneOnOne,
  myManager,
  onCardClick,
  onEditRequest,
  onDeleteRequest,
}) => {
  const {
    moderator,
    meeting_time,
    has_impression,
    requested,
    isPendingRequest,
    tags,
    tasks,
    plans,
    recurring,
    canAccessModeratorProfile,
  } = oneOnOne;
  const classes = useStyles({ isPendingRequest });
  const hasPlans = !isArrayEmpty(plans);
  const hasTasks = !isArrayEmpty(tasks);
  const hasTags = !isArrayEmpty(tags);
  const isRecurring = recurring !== RECURRING_TYPES_MAP.DOES_NOT_REPEAT.id;
  const isFuture = isDateInFuture(meeting_time);

  const handleCardClick = useCallback(() => {
    if (!oneOnOne.isPendingRequest) {
      onCardClick(oneOnOne.id);
    }
  }, [oneOnOne, onCardClick]);

  const renderTag = tag => (
    <Tag tag={tag} color={tag?.category?.color} isSelected />
  );

  return isPendingRequest ? (
    <OneOnOneRequestCard
      className={classNames(classes.request, className)}
      translations={getRequestCardLabels(
        translations.requestCard,
        oneOnOne.moderator,
        myManager
      )}
      request={oneOnOne}
      isExpired={checkIsManagerChanged(oneOnOne.moderator, myManager)}
      onChangeRequest={onEditRequest}
      onDeleteRequest={onDeleteRequest}
      isUserCard
      isMiniCard
    />
  ) : (
    <div
      className={classNames(classes.root, className)}
      onClick={handleCardClick}
    >
      <UserLink
        className={classes.user}
        user={moderator}
        isUserAccessible={canAccessModeratorProfile}
      />
      <OneOnOneTimeIndicator
        labels={translations.statuses}
        meetingTime={meeting_time}
        hasImpression={has_impression}
      />
      <Typography className={classes.meetingDate} variant="caption">
        {`${translations.meetingDate}: ${formatDate(
          meeting_time,
          DATE_TIME_FORMAT
        )}`}
      </Typography>
      {isFuture ? (
        <OneOnOneCalendar
          className={classes.calendar}
          translations={translations.calendar}
          record={oneOnOne}
        />
      ) : null}
      <div className={classes.infoWrapper}>
        {requested && (
          <RequestedIconTooltip
            className={classes.noSpacing}
            labels={translations.requested}
            isRequestedByCurrentUser
          />
        )}
        <RecurringIconTooltip
          iconClass={classes.noSpacing}
          isVisible={isRecurring}
          text={translations.recurring}
        />
        <PlansIconTooltip
          text={translations.plansIconTooltip}
          isVisible={hasPlans}
        />
        <TasksIconTooltip
          className={classes.iconSpacing}
          text={translations.tasksIconTooltip}
          isVisible={hasTasks}
        />
        {hasTags && (
          <AsyncListBadge
            badgeIconClass={classes.badgeIcon}
            tooltipTitle={translations.tags}
            count={tags?.length}
            items={tags}
            icon={TagsIcon}
            renderItem={renderTag}
          />
        )}
      </div>
    </div>
  );
};

OneOnOneMiniCard.defaultProps = {
  className: undefined,
  oneOnOne: {},
  myManager: {},
  onCardClick: () => {},
  onEditRequest: () => {},
  onDeleteRequest: () => {},
};

OneOnOneMiniCard.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  oneOnOne: PropTypes.object,
  myManager: PropTypes.object,
  onCardClick: PropTypes.func,
  onEditRequest: PropTypes.func,
  onDeleteRequest: PropTypes.func,
};

export default OneOnOneMiniCard;
