import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import { ReactComponent as PeopleIcon } from '../../../assets/icons/people-icon.svg';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    backgroundColor: primary.bluish8,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'baseline',
    flexShrink: 0,
    padding: spacing(1, 3),
    borderRadius: 40,
  },
  icon: {
    marginLeft: spacing(1),
  },
}));

const UserCountBadge = ({ className, userCount }) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.root, className)}>
      <Typography variant="subtitle1">{userCount}</Typography>
      <PeopleIcon className={classes.icon} />
    </div>
  );
};

UserCountBadge.defaultProps = {
  className: null,
  userCount: 0,
};

UserCountBadge.propTypes = {
  className: PropTypes.string,
  userCount: PropTypes.number,
};

export default memo(UserCountBadge);
