import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import SignUpLayout from './layout';

const SignUpForm = withFormik({
  mapPropsToValues: props => {
    const { initialData } = props;

    return {
      ...initialData,
    };
  },
  enableReinitialize: true,
  validateOnBlur: false,
  validateOnChange: false,
  validateOnMount: true,
})(SignUpLayout);

SignUpForm.defaultProps = {
  initialData: {},
};

SignUpForm.propTypes = {
  translations: PropTypes.object.isRequired,
  initialData: PropTypes.object,
  setSignUp: PropTypes.func,
  clearSignUp: PropTypes.func,
  validateOrganizationName: PropTypes.func,
};

export default SignUpForm;
