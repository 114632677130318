import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, withStyles } from '@material-ui/core';
import NotificationCard from '../../shared/notificationCard';
import CustomButton from '../../shared/customButton';
import AlertDialog from '../../shared/alertDialog';
import CustomFormDrawer from '../../shared/customFormDrawer';
import NotesList from '../../shared/notesList';
import { showSuccessMessage } from '../../../utility/uiUtils';
import {
  isUserSuspended,
  isArrayEmpty,
  isUserProfileAccessible,
  canManagePerson,
  checkUserRole,
  removeObjectFromArray,
} from '../../../utility/helpers';
import { onSaveCreatableTag } from '../../../utility/tagUtils';
import {
  getManageNoteFields,
  getManageNoteInitialData,
  prepareNoteForSave,
  getNoteFormTitle,
} from '../../../utility/notes';
import { sticky } from '../../../constants/helperCssRules';
import { ROLES } from '../../../constants/rolesAndPermissionList';
import { GENERAL_CATEGORY_ID } from '../../../constants/tags';

const styles = ({ palette: { primary }, spacing }) => ({
  header: {
    paddingBottom: spacing(4),
    ...sticky(primary.white, 169),
  },
  descriptionWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  description: {
    marginBottom: spacing(4),
  },
  form: {
    display: 'grid',
    gridColumnGap: 24,
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    gridTemplateAreas: `
      "visibility visibility"
      "note note"
      "tags ."
      "tasks tasks"
    `,
  },
  visibility: {
    gridArea: 'visibility',
  },
  checkbox: {
    width: '100%',
  },
  note: {
    gridArea: 'note',
    '& .ql-container': {
      height: 376,
    },
    '& > div': {
      height: 445,
    },
  },
  tags: {
    gridArea: 'tags',
  },
  tasks: {
    gridArea: 'tasks',
  },
});

class PeopleNotesPage extends PureComponent {
  state = {
    isLoading: true,
    isManageNoteOpened: false,
    isDeleteDialogOpened: false,
    note: null,
  };

  componentDidMount() {
    const { user, getNotes, getTagsCategories, getAllUsers } = this.props;

    return Promise.all([
      getNotes(user.id),
      getTagsCategories(),
      getAllUsers(),
    ]).finally(() => this.setState({ isLoading: false }));
  }

  componentWillUnmount() {
    const { clearNotes, clearTagsCategories, clearAllUsers } = this.props;

    clearNotes();
    clearTagsCategories();
    clearAllUsers();
  }

  onOpenManageNote = () => this.setState({ isManageNoteOpened: true });

  onCloseManageNote = () => {
    this.setState({ isManageNoteOpened: false, note: null });
  };

  handleSave = values => {
    const { createNote, editNote, translations, user } = this.props;
    const { note } = this.state;
    const isEdit = !!note;

    const noteData = prepareNoteForSave(values, isEdit, user);

    if (isEdit) {
      return editNote(noteData).then(() => {
        showSuccessMessage(translations.manageNotesDialog.successMessages.edit);
      });
    }

    return createNote(noteData).then(() => {
      showSuccessMessage(translations.manageNotesDialog.successMessages.add);
    });
  };

  handleEditNote = selectedNote =>
    this.setState({ isManageNoteOpened: true, note: selectedNote });

  handleOpenDelete = selectedNote =>
    this.setState({ isDeleteDialogOpened: true, note: selectedNote });

  handleDelete = () => this.setState({ isDeleteDialogOpened: true });

  handleCloseDelete = () => this.setState({ isDeleteDialogOpened: false });

  onDelete = () => {
    const { deleteNote, translations } = this.props;
    const { note } = this.state;

    return deleteNote(note.id)
      .then(() => {
        showSuccessMessage(translations.noteDeleteDialog.deleteSuccess);
      })
      .finally(() =>
        this.setState({
          isManageNoteOpened: false,
          isDeleteDialogOpened: false,
          note: null,
        })
      );
  };

  onCreateTag = async tagName => {
    const { addMultipleTags } = this.props;
    const tags = await onSaveCreatableTag(tagName);
    const [tag] = tags.create;

    addMultipleTags({ categoryId: GENERAL_CATEGORY_ID, tags });

    return tag;
  };

  handleTaskStatusChange = (note, task) => {
    const { editTaskStatus } = this.props;

    return editTaskStatus(note, task);
  };

  checkCanManageNote = isAdmin => note => {
    const { auth } = this.props;

    return isAdmin || note.creator?.id === auth.id;
  };

  checkCanManageTask = isAdmin => task => {
    const { auth } = this.props;

    return isAdmin || task.creator === auth.id || task.user.id === auth.id;
  };

  render() {
    const {
      classes,
      auth,
      organizationSettings,
      notes,
      translations,
      user,
      categories,
      allUsers,
    } = this.props;
    const { isLoading, isManageNoteOpened, isDeleteDialogOpened, note } =
      this.state;

    const isAdmin = checkUserRole(auth.role, ROLES.ADMIN);
    const isEdit = !!note;
    const isUserDeactivated = isUserSuspended(user);
    const canAddNote = canManagePerson(auth, user?.id);
    const availableTaskUsers = removeObjectFromArray(allUsers, user);

    return (
      <div>
        {!isLoading && (
          <div className={classes.header}>
            <Typography
              className={classNames({ [classes.description]: canAddNote })}
              variant="body2"
            >
              {translations.description}
            </Typography>
            {canAddNote && (
              <div className={classes.descriptionWrap}>
                <CustomButton
                  type="addRoundedNew"
                  disabled={isUserDeactivated}
                  onClick={this.onOpenManageNote}
                >
                  {translations.addNoteButton}
                </CustomButton>
              </div>
            )}
          </div>
        )}
        <NotesList
          translations={translations.note}
          notes={notes}
          currentUser={auth}
          checkCanManageNote={this.checkCanManageNote(isAdmin)}
          checkIsDisabledEdit={isUserSuspended}
          checkCanManageTask={this.checkCanManageTask(isAdmin)}
          canAccessUserProfile={isUserProfileAccessible(
            auth,
            organizationSettings.global_see_himself
          )}
          onTaskStatusChange={this.handleTaskStatusChange}
          onEdit={this.handleEditNote}
          onDelete={this.handleOpenDelete}
        />
        <NotificationCard
          shouldFade={!isLoading && isArrayEmpty(notes)}
          title={translations.noResults.title}
          content={translations.noResults.message}
        />
        <AlertDialog
          translations={translations.noteDeleteDialog}
          isOpened={isDeleteDialogOpened}
          onClose={this.handleCloseDelete}
          onConfirm={this.onDelete}
          isWarning
        />
        <CustomFormDrawer
          customFormClass={classes.form}
          translations={translations.manageNotesDialog}
          getTitle={getNoteFormTitle}
          initialData={getManageNoteInitialData(note, isEdit)}
          isOpened={isManageNoteOpened}
          isInitialValid={isEdit}
          fields={getManageNoteFields(classes, this.onCreateTag, isEdit, true)}
          categories={categories}
          allUsers={availableTaskUsers}
          onClose={this.onCloseManageNote}
          onSave={this.handleSave}
          onDelete={this.handleDelete}
          hasCancelButton
          isMiddleWidth
        />
      </div>
    );
  }
}

PeopleNotesPage.defaultProps = {
  notes: [],
};

PeopleNotesPage.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  allUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
  notes: PropTypes.arrayOf(PropTypes.object),
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  addMultipleTags: PropTypes.func.isRequired,
  getTagsCategories: PropTypes.func.isRequired,
  editNote: PropTypes.func.isRequired,
  createNote: PropTypes.func.isRequired,
  deleteNote: PropTypes.func.isRequired,
  clearTagsCategories: PropTypes.func.isRequired,
  editTaskStatus: PropTypes.func.isRequired,
  getNotes: PropTypes.func.isRequired,
  clearNotes: PropTypes.func.isRequired,
  getAllUsers: PropTypes.func.isRequired,
  clearAllUsers: PropTypes.func.isRequired,
};

export default withStyles(styles)(PeopleNotesPage);
