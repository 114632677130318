import { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, Collapse, withStyles } from '@material-ui/core';
import ActionButton from '../actionButton';
import CustomButton from '../customButton';
import CustomScrollBar from '../customScrollBar';
import RichTextPreview from '../richTextPreview';
import { isArrayEmpty } from '../../../utility/helpers';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';
import { getColumnStyles, NO_VALUE } from './config';

const styles = ({ palette: { primary }, spacing }) => ({
  main: {
    display: 'grid',
    gridRowGap: 8,
    width: 'fit-content',
  },
  grid: {
    display: 'grid',
    gridColumnGap: 8,
  },
  cell: {
    backgroundColor: primary.bluish3,
    boxSizing: 'border-box',
    borderRadius: 4,
    padding: spacing(4, 3),
    minHeight: 50,
  },
  levelCell: {
    backgroundColor: primary.blue2,
  },
  trackCell: {
    backgroundColor: primary.bluish8,
    padding: spacing(4),
  },
  trackCellExpandable: {
    padding: spacing(2.5, 4),
  },
  trackCellDescription: {
    fontSize: 16,
    lineHeight: '20px',
    padding: spacing(4, 0, 5.5),
    userSelect: 'none',
  },
  addTrackPlaceholderCell: {
    backgroundColor: primary.bluish9,
    borderRadius: 'unset',
  },
  cellContent: {
    color: primary.white,
    lineHeight: '18px',
    userSelect: 'none',
    wordBreak: 'break-word',
  },
  trackContentWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  collapseButton: {
    marginLeft: spacing(1.5),
    alignSelf: 'flex-start',
  },
  trackCellContent: {
    color: primary.bluish1,
  },
  addLevelButton: {
    backgroundColor: primary.bluish8,
    border: `1px solid ${primary.bluish5}`,
    minHeight: 38,
    padding: 7,

    '&:hover': {
      backgroundColor: primary.bluish8,
    },
  },
  expandIcon: {
    transform: 'rotate(0deg)',
    transition: 'transform .3s cubic-bezier(0.77, 0.2, 0.05, 1)',
  },
  expandIconOpened: {
    transform: 'rotate(180deg)',
  },
  scrollX: {
    left: 0,
    bottom: -18,
    width: '100%',
    height: 8,
  },
  scrollY: {
    top: 0,
    right: -18,
    height: '100%',
    width: 8,
  },
});

class LevelingTable extends PureComponent {
  constructor(props) {
    super(props);
    this.scrollBarRef = createRef();
    this.state = {
      isExpanded: {},
      scrollLeft: 0,
    };
  }

  handleIsExpand = cellIndex => () => {
    const { isExpanded } = this.state;
    const scrollPosition =
      this.scrollBarRef.current.scrollbarRef.current.scrollLeft;

    return this.setState(prevState => {
      return {
        isExpanded: isExpanded[cellIndex] ? {} : { [cellIndex]: true },
        scrollLeft:
          prevState.scrollLeft !== scrollPosition
            ? scrollPosition
            : prevState.scrollLeft,
      };
    });
  };

  renderHeader = () => {
    const { classes, tracks, labels, isReadOnly, addLevelAndTrack } =
      this.props;

    return (
      <div className={classes.grid} style={getColumnStyles(tracks.length)}>
        <div className={classes.cell}>
          <Typography variant="subtitle1" className={classes.cellContent}>
            {labels.level}
          </Typography>
        </div>
        {tracks.map((track, index) => (
          <div key={`header_column_${index}`} className={classes.cell}>
            <Typography variant="subtitle1" className={classes.cellContent}>
              {track.name}
            </Typography>
          </div>
        ))}
        {!isReadOnly && (
          <CustomButton type="addWithTextDarkNew" onClick={addLevelAndTrack}>
            {labels.addTrackButtonLabel}
          </CustomButton>
        )}
      </div>
    );
  };

  renderBody = () => {
    const { classes, tracks, levels, isReadOnly } = this.props;
    const { isExpanded } = this.state;

    return levels.map((level, index) => {
      return (
        <div
          key={`row_${index}`}
          className={classes.grid}
          style={getColumnStyles(tracks.length)}
        >
          <div className={classNames(classes.cell, classes.levelCell)}>
            <Typography variant="subtitle1" className={classes.cellContent}>
              {level.name}
            </Typography>
          </div>
          {tracks.map((track, i) => {
            const hasDescription = !!track.track_levels[index]?.description;

            return (
              <div
                key={`track_cell_${i}`}
                className={classNames(classes.cell, classes.trackCell, {
                  [classes.trackCellExpandable]: hasDescription,
                })}
              >
                <div className={classes.trackContentWrapper}>
                  <Typography
                    variant="subtitle1"
                    className={classNames(
                      classes.cellContent,
                      classes.trackCellContent
                    )}
                  >
                    {track.track_levels[index]?.name || NO_VALUE}
                  </Typography>
                  {hasDescription && (
                    <ActionButton
                      className={classes.collapseButton}
                      type={ACTION_BUTTON_TYPES.ARROW}
                      iconClass={classNames(classes.expandIcon, {
                        [classes.expandIconOpened]: !!isExpanded?.[index],
                      })}
                      size="small"
                      edge="end"
                      onClickHandler={this.handleIsExpand(index)}
                    />
                  )}
                </div>
                {hasDescription && (
                  <Collapse
                    in={!!isExpanded?.[index]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <RichTextPreview
                      className={classNames(classes.trackCellDescription)}
                      text={track.track_levels[index]?.description}
                    />
                  </Collapse>
                )}
              </div>
            );
          })}
          {!isReadOnly && (
            <Typography
              className={classNames(
                classes.cell,
                classes.addTrackPlaceholderCell
              )}
            />
          )}
        </div>
      );
    });
  };

  render() {
    const { classes, isReadOnly, labels, levels, addLevelAndTrack } =
      this.props;
    const { scrollLeft } = this.state;

    return (
      !isArrayEmpty(levels) && (
        <CustomScrollBar
          ref={this.scrollBarRef}
          customScrollBarXClass={classes.scrollX}
          customScrollBarYClass={classes.scrollY}
          scrollLeft={scrollLeft}
          verticalScroll
        >
          <div className={classes.main}>
            {this.renderHeader()}
            {this.renderBody()}
            {!isReadOnly && (
              <CustomButton
                type="addWithTextDarkNew"
                className={classes.addLevelButton}
                onClick={addLevelAndTrack}
              >
                {labels.addLevelButtonLabel}
              </CustomButton>
            )}
          </div>
        </CustomScrollBar>
      )
    );
  }
}

LevelingTable.defaultProps = {
  tracks: [],
  levels: [],
};

LevelingTable.propTypes = {
  classes: PropTypes.object.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  labels: PropTypes.object.isRequired,
  tracks: PropTypes.arrayOf(PropTypes.shape({})),
  levels: PropTypes.arrayOf(PropTypes.shape({})),
  addLevelAndTrack: PropTypes.func.isRequired,
};

export default withStyles(styles)(LevelingTable);
