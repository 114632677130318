import { createAction, handleActions } from 'redux-actions';
import http from '../../utility/http';
import {
  api_fill_survey,
  api_fill_review,
  api_answer_on_question,
  api_answer_on_text_reply_question,
  api_finish_review,
} from '../../constants/apiRoutes';
import {
  prepareSurveyData,
  prepareReviewData,
  resumeReview,
  prepareAnswerTextResult,
} from '../../utility/fillSurveyUtils';

// ------------------------------------
// Constants
// ------------------------------------

const SET_FILL_SURVEY = 'SET_FILL_SURVEY';
const SET_SURVEY_REVIEW = 'SET_SURVEY_REVIEW';
const SET_REVIEW_STEP = 'SET_REVIEW_STEP';
const SET_ANSWER = 'SET_ANSWER';
const SET_COMMENTS = 'SET_COMMENTS';
const SET_IS_INLINE_SURVEY = 'SET_IS_INLINE_SURVEY';
const RESET_FILL_SURVEY = 'RESET_FILL_SURVEY';

const initialState = {
  fillSurvey: {},
  review: {},
  surveyStep: 0,
  reviewStep: 0,
  isSurveyLoaded: false,
  isInlineSurvey: false,
};

// ------------------------------------
// Actions
// ------------------------------------

const setFillSurvey = createAction(SET_FILL_SURVEY);
const setSurveyReview = createAction(SET_SURVEY_REVIEW);
const setAnswer = createAction(SET_ANSWER);
const resetFillSurvey = createAction(RESET_FILL_SURVEY);
export const setReviewStep = createAction(SET_REVIEW_STEP);
export const setComments = createAction(SET_COMMENTS);
export const setIsInlineSurvey = createAction(SET_IS_INLINE_SURVEY);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_FILL_SURVEY]: (state, { payload }) => {
    return {
      ...state,
      isSurveyLoaded: true,
      fillSurvey: prepareSurveyData(payload),
    };
  },
  [SET_SURVEY_REVIEW]: (state, { payload }) => {
    return {
      ...state,
      review: prepareReviewData(payload),
      ...resumeReview(state.fillSurvey.attributes, payload),
    };
  },
  [SET_REVIEW_STEP]: (state, { payload }) => {
    return {
      ...state,
      reviewStep: payload,
    };
  },
  [SET_ANSWER]: (state, { payload }) => {
    return {
      ...state,
      review: {
        ...state.review,
        results: {
          ...state.review.results,
          [payload.review_tracking]: {
            ...state.review.results[payload.review_tracking],
            [payload.question]: payload.answer,
          },
        },
      },
    };
  },
  [SET_COMMENTS]: (state, { payload }) => {
    return {
      ...state,
      review: {
        ...state.review,
        comments: payload,
      },
    };
  },
  [SET_IS_INLINE_SURVEY]: (state, { payload }) => ({
    ...state,
    isInlineSurvey: payload,
  }),
  [RESET_FILL_SURVEY]: (state, { payload }) => {
    return payload;
  },
};

// ------------------------------------
// API calls
// ------------------------------------

export const getFillSurvey = (dispatch, token) => {
  return http.get(api_fill_survey(token)).then(
    ({ data }) => dispatch(setFillSurvey(data)),
    () => dispatch(resetFillSurvey({ ...initialState, isSurveyLoaded: true }))
  );
};

export const getSurveyReview = (dispatch, token, reviewId) => {
  return http.get(api_fill_review(token, reviewId)).then(
    ({ data }) => dispatch(setSurveyReview(data)),
    () => dispatch(resetFillSurvey({ ...initialState, isSurveyLoaded: true }))
  );
};

export const setAnswerResult = (dispatch, token, answer) => {
  return http.post(api_answer_on_question(token), answer).then(
    () => dispatch(setAnswer(answer)),
    () => dispatch(resetFillSurvey({ ...initialState, isSurveyLoaded: true }))
  );
};

export const setAnswerTextResult = (dispatch, token, payload) => {
  return http
    .post(
      api_answer_on_text_reply_question(token),
      prepareAnswerTextResult(payload)
    )
    .then(
      () => dispatch(setAnswer(payload)),
      () => dispatch(resetFillSurvey({ ...initialState, isSurveyLoaded: true }))
    );
};

export const submitReview = (dispatch, token, comments) =>
  http.post(api_finish_review(token), comments);

export default handleActions(reducers, initialState);

// ------------------------------------
// "plain" action functions
// ------------------------------------

export const clearFillSurvey = isInlineSurvey =>
  resetFillSurvey({ ...initialState, isInlineSurvey });
