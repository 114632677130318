import { memo } from 'react';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as ClearIcon } from '../../../../assets/icons/close-dark.svg';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    cursor: 'pointer',
    display: 'flex',
    padding: spacing(1.5),
    marginRight: spacing(1.5),
  },
}));

const ClearIndicator = props => {
  const classes = useStyles();

  return (
    <div {...props.innerProps} className={classes.root}>
      <ClearIcon />
    </div>
  );
};

export default memo(ClearIndicator);
