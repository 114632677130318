import { ReactComponent as EditIcon } from '../../../assets/icons/edit-dark.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-outlined.svg';
import {
  forbiddenCharacters,
  isEmpty,
  validateFileSize,
  validateFileType,
  validateLength,
  validateUrl,
} from '../../../utility/validation';
import { PHOTO_UPLOAD_CONFIG } from '../../../constants/uploadPhotoConfig';
import { FIELD_TYPES } from '../../../constants/fieldTypes';
import { CREATABLE_TAG_FIELD } from '../../../constants/tags';

const { ADD_IMAGE, SELECT, TEXT } = FIELD_TYPES;

export const getEditCourseFields = onCreateTag => [
  {
    name: 'title',
    type: TEXT,
    required: true,
    validators: [
      {
        type: 'required',
        validator: isEmpty,
      },
      {
        type: 'forbiddenCharacters',
        validator: forbiddenCharacters,
      },
      {
        type: 'titleMaxLength',
        validator: fieldValue => validateLength(fieldValue, 0, 500),
      },
    ],
  },
  {
    name: 'level',
    type: SELECT,
    optionsKey: 'levels',
    parser: {
      value: 'id',
      label: 'name',
    },
  },
  {
    name: 'url',
    type: TEXT,
    required: true,
    validators: [
      {
        type: 'required',
        validator: isEmpty,
      },
      { type: 'invalidUrl', validator: validateUrl },
    ],
  },
  {
    name: 'description',
    type: TEXT,
    multiline: true,
    rows: 5,
    validators: [
      {
        type: 'forbiddenCharacters',
        validator: forbiddenCharacters,
      },
      {
        type: 'descriptionMaxLength',
        validator: fieldValue => validateLength(fieldValue, 0, 2500),
      },
    ],
  },
  {
    name: 'attribute',
    type: SELECT,
    optionsKey: 'allAttributes',
    isClearable: true,
    hasColorBox: true,
    parser: {
      value: 'id',
      label: 'name',
    },
  },
  {
    name: 'tags',
    type: SELECT,
    optionsKey: 'categories',
    creatableOptionField: CREATABLE_TAG_FIELD,
    isCategorized: true,
    hasCategoryColorBox: true,
    isMulti: true,
    hasHash: true,
    isCreatable: true,
    isTag: true,
    parser: {
      value: 'id',
      label: 'name',
      categoryLabel: 'name',
      categoryValue: 'tags',
    },
    onCreateOption: onCreateTag,
  },
  {
    name: 'cover_image',
    type: ADD_IMAGE,
    translationKey: 'courseImage',
    validators: [
      {
        type: 'fileType',
        validator: value =>
          validateFileType(value, Object.keys(PHOTO_UPLOAD_CONFIG.mimeTypes)),
      },
      {
        type: 'fileSize',
        validator: value =>
          validateFileSize(value, PHOTO_UPLOAD_CONFIG.maxSize),
      },
    ],
  },
];

export const getCourseActions = (labels, onEdit, onDelete) => [
  {
    action: onEdit,
    label: labels.edit,
    shouldItemRender: () => true,
    type: 'Enroll',
    icon: EditIcon,
  },
  {
    action: onDelete,
    label: labels.deleteAction,
    shouldItemRender: () => true,
    type: 'Delete',
    icon: DeleteIcon,
  },
];
