import { primaryPalette } from '../../../styles/theme';
import { MAX_SELECTED_SCORES } from '../../pages/competenceMapPage/config';

const MIN = 0;
const MAX = 10;

export const SCORE_LABELS = Array.from({ length: MAX + 1 }, (_, i) => i);

export const getLabelBoxPosition = (event, chart, data) => {
  const {
    scales: {
      x: { top, left, bottom, ticks, maxWidth },
    },
    canvas,
  } = chart;

  const numberOfTicks = ticks.length;
  const labelBox = 28;
  const scalePaddingTop = 8;
  const scalePaddingBottom = 10;
  const right = maxWidth / numberOfTicks;
  const rect = canvas.getBoundingClientRect();
  const xPosition = event.clientX - rect.left - left;
  const yPosition = event.clientY - rect.top;

  for (let i = 0; i < numberOfTicks; i += 1) {
    if (
      xPosition >= labelBox + right * i &&
      xPosition <= right - labelBox + right * i &&
      yPosition >= top + scalePaddingTop &&
      yPosition <= bottom - scalePaddingBottom
    ) {
      const hasScore = data.datasets.some(dSet => dSet.data[i] !== 0);
      if (hasScore) {
        return i;
      }
    }
  }
};

export const xAxisLabelButton = {
  id: 'xAxisLabelButton',
  beforeDraw: (chart, args, options) => {
    const {
      ctx,
      data,
      scales: { x },
    } = chart;
    const squareSize = 30;
    const padding = 8;
    ctx.lineWidth = 1;
    ctx.fillStyle = primaryPalette.white;
    ctx.strokeStyle = primaryPalette.bluish5;
    ctx.save();

    data.labels.forEach((label, i) => {
      const isActive = options.selectedScores.includes(i);
      ctx.beginPath();
      ctx.fillStyle = isActive ? primaryPalette.bluish2 : primaryPalette.white;
      ctx.strokeStyle = isActive
        ? primaryPalette.bluish2
        : primaryPalette.bluish5;
      ctx.roundRect(
        x.getPixelForValue(i) - squareSize / 2,
        x.top + padding,
        squareSize,
        squareSize,
        padding
      );
      ctx.closePath();
      ctx.fill();
      ctx.stroke();
      ctx.restore();
    });
  },
};

export const chartXAreaBorder = {
  id: 'chartAreaBorder',
  beforeDraw: (chart, args, options) => {
    const {
      ctx,
      chartArea: { left, top, right, bottom },
    } = chart;

    ctx.save();
    ctx.strokeStyle = options.borderColor;
    ctx.lineWidth = options.borderWidth;
    ctx.setLineDash(options.borderDash || []);
    ctx.lineDashOffset = options.borderDashOffset;
    ctx.beginPath();
    ctx.moveTo(left, top);
    ctx.lineTo(right, top);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(left, bottom);
    ctx.lineTo(right, bottom);
    ctx.stroke();
    ctx.restore();
  },
};

export const customLabelXHover = {
  id: 'customLabelXHover',
  afterEvent: (chart, event, options) => {
    const evt = event.event;

    if (evt.type !== 'mousemove' || options.isDisabled) return;

    const labelBoxIndex = getLabelBoxPosition(evt.native, chart, options.data);
    const isSelected =
      labelBoxIndex !== undefined &&
      options.selectedScores.includes(labelBoxIndex + 1);

    evt.native.target.style.cursor =
      labelBoxIndex !== undefined &&
      (isSelected || options.selectedScores.length !== MAX_SELECTED_SCORES)
        ? 'pointer'
        : 'default';
  },
};

export const getOptions = (yAxisLabel, selectedScores, data) => ({
  skipNull: true,
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    delay: 300,
  },
  showTooltips: true,
  layout: {
    autoPadding: false,
    padding: {
      top: 1,
      right: 1,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      backgroundColor: primaryPalette.bluish2,
      bodyAlign: 'center',
      callbacks: {
        title: () => '',
        label: () => '',
        footer: ([tooltipItem]) => {
          return tooltipItem?.raw ? `+${tooltipItem.raw - MAX}` : '';
        },
      },
      caretPadding: 0,
      caretSize: 0,
      cornerRadius: {
        topLeft: 8,
        topRight: 8,
        bottomLeft: 0,
        bottomRight: 8,
      },
      filter: tooltipItem => tooltipItem.raw > MAX,
      footerFont: {
        family: 'ProximaNova-Bold',
        size: 14,
        lineHeight: '20px',
      },
      padding: {
        x: 6,
        y: 0,
      },
      xAlign: 'left',
      yAlign: 'center',
    },
    chartAreaBorder: {
      borderColor: primaryPalette.bluish2,
      borderWidth: 1,
      borderDash: [2, 2],
      borderDashOffset: 0,
    },
    xAxisLabelButton: {
      selectedScores,
    },
    customLabelXHover: {
      data,
      selectedScores,
    },
  },
  scales: {
    x: {
      beginAtZero: false,
      drawBorder: false,
      grid: {
        drawBorder: false,
        drawTicks: false,
        color: primaryPalette.bluish2,
        borderDash: [2, 2],
      },
      ticks: {
        font: {
          size: 18,
          family: 'ProximaNova-Regular',
          weight: 400,
          lineHeight: '20px',
        },
        padding: 14,
        color: ({ index }) =>
          selectedScores.includes(index)
            ? primaryPalette.white
            : primaryPalette.bluish1,
      },
      min: MIN,
      max: MAX,
    },
    y: {
      title: {
        display: true,
        text: yAxisLabel.toUpperCase(),
        color: primaryPalette.bluish2,
        font: {
          family: 'ProximaNova-Regular',
          size: 14,
          weight: 400,
          lineHeight: '20px',
        },
        padding: 0,
      },
      ticks: {
        display: false,
        callback: value => {
          if (value === 0 || value === MAX) return null;

          return '';
        },
      },
      grid: {
        drawTicks: false,
        drawBorder: false,
        color: primaryPalette.white,
        z: 10,
      },
      min: MIN,
      max: MAX,
    },
  },
});
