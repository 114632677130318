import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import 'react-indiana-drag-scroll/dist/style.css';
import ScrollContainer from 'react-indiana-drag-scroll';

const useStyles = makeStyles(({ breakpoints, palette: { primary } }) => ({
  main: {
    overflow: 'auto',
  },
  showDesktopScrollbars: {
    [breakpoints.up('sm')]: {
      paddingRight: 6,
      'scrollbar-width': 'auto !important',
      '&::-webkit-scrollbar': {
        display: 'block !important',
        width: '8px !important',
        height: 'calc(100% - 6px)',
        borderRadius: 4,
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: primary.bluish9,
        borderRadius: 4,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: primary.bluish7,
        borderRadius: 4,
      },
    },
  },
  showHorizontalScrollbar: {
    paddingBottom: 6,
    [breakpoints.up('sm')]: {
      'scrollbar-width': 'auto !important',
      '&::-webkit-scrollbar': {
        display: 'block !important',
        height: '8px !important',
        width: 'calc(100% - 6px)',
        borderRadius: 4,
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: primary.bluish9,
        borderRadius: 4,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: primary.bluish7,
        borderRadius: 4,
      },
    },
  },
}));

const DraggableScroll = forwardRef(
  (
    {
      className,
      children,
      showDesktopScrollbars,
      showHorizontalScrollbar,
      onEndScroll,
    },
    ref
  ) => {
    const classes = useStyles();

    return (
      <ScrollContainer
        ref={ref}
        className={classNames(
          classes.main,
          {
            [classes.showDesktopScrollbars]: showDesktopScrollbars,
            [classes.showHorizontalScrollbar]: showHorizontalScrollbar,
          },
          className
        )}
        hideScrollbars={!showHorizontalScrollbar}
        mouseScroll={{ ignoreElements: 'textarea, nav' }}
        onEndScroll={onEndScroll}
      >
        {children}
      </ScrollContainer>
    );
  }
);

DraggableScroll.displayName = 'DraggableScroll';

DraggableScroll.defaultProps = {
  className: undefined,
  showDesktopScrollbars: false,
  showHorizontalScrollbar: false,
  onEndScroll: () => {},
};

DraggableScroll.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element.isRequired,
  showDesktopScrollbars: PropTypes.bool,
  showHorizontalScrollbar: PropTypes.bool,
  onEndScroll: PropTypes.func,
};

export default DraggableScroll;
