import { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AttributeChip from '../../../attributeChip';
import ActionButton from '../../../actionButton';
import { ReactComponent as TextReplyIcon } from '../../../../../assets/icons/text_snippet.svg';
import { getProgressWidth } from '../../../../../utility/uiUtils';
import { PLACEHOLDER_ATTRIBUTE } from '../../../../../constants/attributes';
import { ACTION_BUTTON_TYPES } from '../../../actionButton/config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  itemBottomSpacing: {
    marginBottom: spacing(2),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  label: {
    color: primary.bluish4,
    lineHeight: '20px',
    marginBottom: spacing(2),
  },
  attributeWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  attribute: {
    backgroundColor: 'transparent',
    position: 'relative',
    zIndex: 11,
    minWidth: 0,
    width: '100%',
  },
  progress: {
    backgroundColor: primary.bluish8,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    zIndex: 9,
    flexGrow: 1,
    maxWidth: 'calc(100% - 58px)',
    minWidth: 0,
    height: 28,
  },
  progressColor: {
    boxSizing: 'border-box',
    position: 'absolute',
    left: 0,
    top: 0,
    height: 28,
    zIndex: 8,
  },
  attributeScore: {
    color: primary.bluish2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 50,
  },
  attributeScoreValue: {
    color: primary.bluish2,
    marginRight: spacing(1),
  },
  attributeScoreMaxValue: {
    color: primary.bluish2,
    marginLeft: spacing(1),
  },
  noScore: {
    color: primary.bluish2,
  },
  sendIcon: {
    width: 16,
    height: 16,
  },
}));

const AttributesPerformanceSubgroup = ({
  translations,
  title,
  attributes,
  hasSendSurveyButton,
  onSendSurvey,
  isSendSurveyDisabled,
}) => {
  const classes = useStyles();

  const renderScore = attribute => {
    const { id, text_reply_questions, score } = attribute;
    const hasScore = score || score === 0;

    if (text_reply_questions) {
      return (
        <div className={classes.attributeScore}>
          <TextReplyIcon />
        </div>
      );
    }

    if (!hasSendSurveyButton || !id || hasScore) {
      return (
        <div className={classes.attributeScore}>
          {hasScore && (
            <Typography
              variant="subtitle2"
              className={classes.attributeScoreValue}
            >
              {score}
            </Typography>
          )}
          /
          {hasScore && (
            <Typography
              variant="body2"
              className={classes.attributeScoreMaxValue}
            >
              {PLACEHOLDER_ATTRIBUTE.max_score}
            </Typography>
          )}
        </div>
      );
    }
    return (
      <div className={classes.attributeScore}>
        <ActionButton
          iconClass={classes.sendIcon}
          type={ACTION_BUTTON_TYPES.SEND}
          tooltipText={translations.sendSurvey}
          isDisabled={isSendSurveyDisabled}
          onClickHandler={onSendSurvey(attribute.id)}
          hasBorder
        />
      </div>
    );
  };

  const renderAttribute = attribute => {
    const isTextReply = attribute.text_reply_questions;

    return (
      <div className={classes.attributeWrapper}>
        <div className={classes.progress}>
          <div
            className={classes.progressColor}
            style={{
              backgroundColor: attribute.score ? attribute.color : '',
              width: isTextReply
                ? '100%'
                : getProgressWidth(attribute.score).width,
              border: isTextReply ? `4px solid ${attribute.color}` : 'none',
            }}
          />
          {attribute.id && (
            <AttributeChip
              customClass={classes.attribute}
              name={attribute.name}
              attribute={attribute}
              isTextReply={isTextReply}
              isSelected
            />
          )}
        </div>
        {renderScore(attribute)}
      </div>
    );
  };

  return (
    <div>
      <Typography variant="body2" component="h4" className={classes.label}>
        {title}
      </Typography>
      {attributes.map((attribute, index) => (
        <div
          key={`attribute_basic_item_${index}`}
          className={classes.itemBottomSpacing}
        >
          {renderAttribute(attribute)}
        </div>
      ))}
    </div>
  );
};

AttributesPerformanceSubgroup.defaultProps = {
  isSendSurveyDisabled: false,
};

AttributesPerformanceSubgroup.propTypes = {
  translations: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  attributes: PropTypes.array.isRequired,
  hasSendSurveyButton: PropTypes.bool.isRequired,
  isSendSurveyDisabled: PropTypes.bool,
  onSendSurvey: PropTypes.func.isRequired,
};

export default memo(AttributesPerformanceSubgroup);
