import { Link, useParams } from 'react-router-dom';
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  withStyles,
} from '@material-ui/core';
import Breadcrumbs from '../../shared/breadcrumbs';
import { gridColumn } from '../../../constants/gridColumn';

const styles = ({ spacing }) => ({
  wrapper: {
    height: '100vh',
    overflowY: 'auto',
  },
  main: {
    padding: spacing(8, 8, 17),
  },
  breadcrumbs: {
    marginTop: 15,
  },
  title: {
    width: '100%',
    textAlign: 'center',
    margin: spacing(16, 0),
  },
  summary: {
    margin: spacing(2, 0),
    padding: spacing(5),
  },
  details: {
    marginBottom: spacing(6),
    padding: spacing(0, 5),
  },
  text: {
    margin: spacing(4, 0),
  },
  listItem: {
    marginBottom: spacing(2),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
});

const translations = {
  back: 'back',
};

const routes = [];
const appUrl = '/privacy-policy';

const PrivacyPolicyPage = props => {
  const { classes, history } = props;
  const { url } = useParams();
  const isApp = url === appUrl;

  return (
    <Grid container spacing={0} className={classes.wrapper}>
      <Grid item sm={gridColumn.one} lg={gridColumn.three} />
      <Grid item xs={gridColumn.twelve} sm={gridColumn.ten} lg={gridColumn.six}>
        <div className={classes.main}>
          <Breadcrumbs
            className={classes.breadcrumbs}
            translations={translations}
            routes={routes}
            onHandleBack={() => history.back()}
          />
          <Typography variant="h1" className={classes.title}>
            Privacy policy
          </Typography>
          <Typography component="div" className={classes.details}>
            We are committed to protecting and respecting your privacy. The
            privacy policy is intended to inform you what information we collect
            about you, how we use that information, and to provide you with
            other relevant information for exercising your rights regarding the
            protection of personal data. This policy sets out the basis, under
            applicable data protection law (including the General Data
            Protection Regulation (EU 2016/679), on which we will process any
            personal data we collect from you, or that you or your employer
            provides to us through your use of the Kadar&apos;s online software
            platform.
          </Typography>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h4" className={classes.summary}>
                Purpose of processing
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="div" className={classes.details}>
                In the normal course of our business we collect and process data
                in respect of:
                <ul>
                  <li className={classes.listItem}>
                    survey recipients who are typically officers, employees,
                    contractors or consultants who have been granted access to
                    our platform by their employer (where their employer is an
                    existing client) and who are invited to complete engagement
                    surveys;
                  </li>
                  <li className={classes.listItem}>
                    administrative users of the platform dashboard who are
                    acting on behalf of their relevant employer (where their
                    employer is an existing client);
                  </li>
                  <li className={classes.listItem}>
                    trial users or potential new users of the platform,
                    including those who we have identified as possible future
                    clients and to whom we are marketing or promoting the
                    platform and our services.
                  </li>
                </ul>
                Please read this policy carefully to understand our views and
                practices regarding your personal data and how we will treat it.
                By visiting <Link to="/">https://kadar.app</Link> or using the
                platform you are accepting and consenting to the activities and
                data usage as described in this policy.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h4" className={classes.summary}>
                Information we collect
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="div" className={classes.details}>
                We will collect and process the following data about you:
                <ol>
                  <li className={classes.listItem}>
                    You may give us information about you when you are using the
                    platform&apos;s functions, including by filling in forms or
                    completing surveys on the platform. This information may
                    include your name, company email address, job title, level
                    of seniority, department, work start date, as well as any
                    views you provide to us using the Platform. If you contact
                    or correspond with us (for example, using any support
                    function made available by us) and we may keep a record of
                    that correspondence (either directly or through our service
                    providers).
                  </li>
                  <li className={classes.listItem}>
                    Your employer may give us information about you when he is
                    creating a user profile for you in order to enable you to
                    access the Platform. This information may include your name,
                    company email address, job title, level of seniority,
                    department, work start date.
                  </li>
                  <li className={classes.listItem}>
                    Each time you complete an engagement survey or otherwise use
                    the Platform we may automatically collect information such
                    as: date, time and location of platform usage, technical
                    information about your computer or mobile device for system
                    administration and analysis (such as your IP address, unique
                    device identifiers, operating system, and network and
                    browser type).
                  </li>
                  <li className={classes.listItem}>
                    When you wish to purchase a service, you will be required to
                    provide certain information to our third-party payment
                    processor, Stripe. Such information may include a debit card
                    number, credit card number, expiration date, billing address
                    and similar information. Such information is collectively
                    called the “Billing Information”. We do not collect, process
                    or store your Billing Information in Kadar or on our
                    servers.{' '}
                    <Typography className={classes.text}>
                      You authorize Stripe to collect, process, and store your
                      Billing Information in accordance with its terms of
                      service and privacy policy. You can learn more about
                      Stripe and read its privacy policy at{' '}
                      <a
                        href="https://stripe.com/privacy"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        www.stripe.com/privacy
                      </a>
                      .
                    </Typography>{' '}
                    Our Stripe integration allows you to give us permission to
                    initiate a series of payments on your behalf. The amount you
                    set will be taken on the same day of the month that you made
                    the first payment (recurring payments). You should receive
                    email receipts from Stripe to confirm these payments.
                  </li>
                </ol>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h4" className={classes.summary}>
                Your rights
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="div" className={classes.details}>
                Visitors to our platform whose information we possess are
                entitled to contact us by email and request access to the data,
                updating or deleting of personal information.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary>
              <Typography variant="h4" className={classes.summary}>
                Cookie policy
              </Typography>
            </AccordionSummary>
            {isApp ? (
              <AccordionDetails>
                <Typography component="div" className={classes.details}>
                  Our website does not use cookies.
                </Typography>
              </AccordionDetails>
            ) : (
              <AccordionDetails>
                <Typography component="div" className={classes.details}>
                  <Typography className={classes.text}>
                    Cookies are small text records (files) that are sent to your
                    computer or mobile device when you visit a website and can
                    be accessed later. Thanks to cookies, you can see what
                    interests certain visitors, which helps to improve the use
                    for all users. We provide this notice to you to comply with
                    our legal obligation to let you know what cookies we use
                    when you use our online service and how we protect your
                    privacy.
                  </Typography>
                  <Typography className={classes.text}>
                    By using our online service, you agree that we may use
                    cookies in accordance with this Policy. For more information
                    about our use of your information, see our Privacy Policy.
                  </Typography>
                  <Typography className={classes.text}>
                    Cookies set by Kadar are refereed to as first-party cookies.
                    We do not use this type of cookies.
                  </Typography>
                  <Typography className={classes.text}>
                    There are also cookies set by third parties who are not part
                    of Kadar, but whose content is integrated within our
                    website. This includes content such as analytical tools that
                    help us to improve this online service.
                  </Typography>
                  <Typography className={classes.text}>
                    Our online service uses Google Analytics, a web analytics
                    service provided by Google. Google Analytics uses cookies in
                    order to more easily analyze how users use the online
                    service. The data generated by the cookie on the use of the
                    service is transmitted and stored by Google on servers in
                    the United States of America. The IP address that your
                    browser transmits within Google Analytics is not linked to
                    other data owned by Google. In case of activating the hiding
                    of the IP address, Google shortens / hides your IP address
                    for the member states of the European Union as well as for
                    the signatory countries of the Agreement on the European
                    Economic Area. Only in exceptional cases, the full IP
                    address is sent to Google servers in the USA and shortened
                    there. IP anonymization is active on this site and IT CANNOT
                    identify site visitors. The purpose of collecting this data
                    is to evaluate the use of the service, on the basis of which
                    Google compiles a report on activities. The cookies are used
                    to calculate visitor sessions and on-site behaviour data for
                    analytics reports. The information collected in this way is
                    used exclusively for statistical purposes.
                  </Typography>
                  <Typography className={classes.text}>
                    You can also decline consent for tracking by Google
                    Analytics by downloading and installing the Google Analytics
                    opt-out plugin for your current browser. If you need more
                    information about Google Analytics cookies, see the Google
                    Help and Privacy Policy pages (
                    <a
                      href="https://policies.google.com/technologies/partner-sites?hl=en"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      https://policies.google.com/technologies/partner-sites?hl=en,
                      https://policies.google.com/privacy?hl=en
                    </a>
                    ,{' '}
                    <a
                      href="https://policies.google.com/privacy?hl=en"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      https://policies.google.com/privacy?hl=en
                    </a>
                    ).
                  </Typography>
                  <Typography variant="subtitle2">Data processor</Typography>
                  <Typography className={classes.text}>
                    Kadar Technologies Inc., 651 N Broad St, Suite 206,
                    Middletown, DE 19709,USA
                  </Typography>
                  <Typography variant="subtitle2">
                    Data protection officer
                  </Typography>
                  <Typography className={classes.text}>
                    To communicate with our Data Protection Officer, please
                    email privacy@kadar.app.
                  </Typography>
                </Typography>
              </AccordionDetails>
            )}
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography variant="h4" className={classes.summary}>
                Full text of privacy policy
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="div" className={classes.details}>
                <Typography className={classes.text}>
                  Kadar Technologies Inc., 651 N Broad St, Suite 206,
                  Middletown, DE 19709, USA as detailed further below as
                  (&quot;Kadar&quot; / &quot;we&quot; / &quot;our&quot;/
                  &quot;us&quot;) are committed to protecting and respecting
                  your privacy. This policy sets out the basis, under applicable
                  data protection law (including the General Data Protection
                  Regulation (EU 2016/679), on which we will process any
                  personal data we collect from you, or that you or your
                  employer provides to us through your use of the Kadar&apos;s
                  online software platform.
                </Typography>
                <Typography className={classes.text}>
                  Kadar appointed Data Protection Officer. You can contact the
                  Data Protection Officer at privacy@kadar.app.
                </Typography>
                <Typography variant="subtitle2">
                  OUR PROCESSING OF PERSONAL DATA
                </Typography>
                <Typography className={classes.text}>
                  In the normal course of our business we collect and process
                  data in respect of:
                </Typography>
                <ul>
                  <li className={classes.listItem}>
                    survey recipients who are typically officers, employees,
                    contractors or consultants who have been granted access to
                    our platform by their employer (where their employer is an
                    existing client) and who are invited to complete engagement
                    surveys (&quot;Survey Recipients&quot;);
                  </li>
                  <li className={classes.listItem}>
                    administrative users of the platform dashboard who are
                    acting on behalf of their relevant employer (where their
                    employer is an existing client) (&quot;Client Users&quot;);
                    and
                  </li>
                  <li className={classes.listItem}>
                    trial users or potential new users of the platform,
                    including those who we have identified as possible future
                    clients and to whom we are marketing or promoting the
                    platform and our services (&quot;Marketing Contacts&quot;).
                  </li>
                </ul>
                <Typography className={classes.text}>
                  When we refer to &quot;personal data&quot; in this policy, we
                  mean any information relating to you from or in relation to
                  which you may be identified (directly or indirectly). This
                  might include, for instance, your contact details, your
                  biographical details, online identifiers, and factors specific
                  to your physical, mental, economic, cultural or social
                  identity. Your personal data may also include any comments or
                  opinions made by you or about you.
                </Typography>
                <Typography className={classes.text}>
                  Please read this policy carefully to understand our views and
                  practices regarding your personal data and how we will treat
                  it. By visiting <Link to="/">https://kadar.app</Link> or using
                  the platform you are accepting and consenting to the
                  activities and data usage as described in this policy.
                </Typography>
                <Typography variant="subtitle2">
                  OUR STATUS AND RESPONSIBILITIES
                </Typography>
                <Typography className={classes.text}>
                  In the case of Survey Recipients and Client Users, Kadar has
                  entered into an agreement with your employer to grant you
                  access to the platform and / or to engagement surveys
                  completed on the platform (the &quot;Agreement&quot;), and
                  your employer is the data controller of your personal data. We
                  will process your personal data on behalf of your employer and
                  in accordance with its lawful instructions. The information
                  you provide to us and/or upload to the platform (whether or
                  not it constitutes personal data) will also be governed by the
                  Agreement.
                </Typography>
                <Typography className={classes.text}>
                  In the case of Marketing Contacts, we are the data controller
                  in respect of your personal data.
                </Typography>
                <Typography className={classes.text}>
                  References in this policy to &quot;your employer&quot; shall
                  refer to the entity who has entered into the Agreement with
                  us, whether or not as a matter of law you are an employee,
                  consultant or contractor of that entity, and such references
                  are not intended to characterize or prejudice your status
                  vis-à-vis that entity.
                </Typography>
                <Typography variant="subtitle2">
                  INFORMATION WE COLLECT ABOUT YOU
                </Typography>
                <Typography className={classes.text}>
                  For Survey Recipients and Client Users: we will collect and
                  process the following data about you as follows.
                </Typography>
                <Typography className={classes.text}>
                  Information you give us. You may give us information about you
                  when you are using the platform&apos;s functions, including by
                  filling in forms or completing surveys on the platform. The
                  extent of the information collected in engagement surveys is
                  set by your employer. This information may include your name,
                  company email address, job title, level of seniority,
                  department, work start date, as well as any views you provide
                  to us about you or your performance in your role, or
                  information regarding your employer, your colleagues or other
                  third parties during the course of completing engagement
                  surveys or otherwise using the Platform. If you contact or
                  correspond with us (for example, using any support function
                  made available by us) and we may keep a record of that
                  correspondence (either directly or through our service
                  providers).
                </Typography>
                <Typography className={classes.text}>
                  Information your employer gives us. Your employer may give us
                  information about you when he is creating a user profile for
                  you in order to enable you to access the Platform under the
                  Agreement (whether on a trial or full basis) or to enable us
                  to send engagement surveys and similar communications to you.
                  This information may include your name, company email address,
                  job title, level of seniority, department, work start date.
                  Otherwise, your employer may give us information in the course
                  of your employer&apos;s use of the Platform.
                </Typography>
                Information we collect about you. Each time you complete an
                engagement survey or otherwise use the Platform we may
                automatically collect the following information:
                <ul>
                  <li className={classes.listItem}>
                    when you use the Platform, we will keep a record of the
                    details of that usage, including the date, time, location,
                    frequency and duration of the usage;
                  </li>
                  <li className={classes.listItem}>
                    if you are a Client User (using the Platform as a
                    representative of your employer), we may obtain further
                    information about you from your employer, for example to
                    verify your eligibility to access and use the Platform;
                  </li>
                  <li className={classes.listItem}>
                    any comments, opinions and/or feedback you provide to us
                    regarding the Platform, for example during any trial period
                    that you may participate in or thereafter;
                  </li>
                  <li className={classes.listItem}>
                    technical information about your computer or mobile device
                    for system administration and analysis, including your IP
                    address, unique device identifiers, operating system, and
                    network and browser type;
                  </li>
                </ul>
                <Typography className={classes.text}>
                  For Marketing Contacts, we will collect and process personal
                  data which you provide us when you complete an inquiry via a
                  website or register for a trial or otherwise contact us to
                  request information about our products and services. We will
                  typically obtain contact information such as your name,
                  employer, work email address. We may also receive further
                  personal data about you which is publicly available, such as
                  your seniority, years of experience and employment history and
                  similar work-related background. We shall also store and
                  process data relating to your communications with us and your
                  responses to our marketing emails and attendance at our
                  events.
                </Typography>
                <Typography className={classes.text}>
                  Billing information. When you wish to purchase a service, you
                  will be required to provide certain information to our
                  third-party payment processor, Stripe. Such information may
                  include a debit card number, credit card number, expiration
                  date, billing address and similar information. We do not
                  collect, process or store your Billing Information in Kadar or
                  on our servers. You authorize Stripe to collect, process, and
                  store your Billing Information in accordance with its terms of
                  service and privacy policy. You can learn more about Stripe
                  and read its privacy policy at&nbsp;
                  <a
                    href="https://stripe.com/privacy"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    www.stripe.com/privacy
                  </a>
                  . Our Stripe integration allows you to give us permission to
                  initiate a series of payments on your behalf.
                </Typography>
                <Typography variant="subtitle2">
                  HOW WE USE YOUR PERSONAL DATA AND OUR LEGAL BASIS FOR DOING SO
                </Typography>
                <Typography className={classes.text}>
                  Please note that we are permitted to collect, use, disclose
                  and/or otherwise process any information other than personal
                  data, including data sets you upload to the Platform or
                  otherwise provide to us, to the fullest extent permitted by
                  the Agreement.
                </Typography>
                <Typography className={classes.text}>
                  Where we have collected, received or generated personal data
                  from or about you, we may use this for the purposes, and on
                  the legal bases, as set out below.
                </Typography>
                <Typography className={classes.text}>
                  For Survey Recipients and Client Users
                </Typography>
                <Typography className={classes.text}>
                  Information you give to us. We will use this information to:
                </Typography>
                <ul>
                  <li className={classes.listItem}>
                    if you are a Client User, process your application to become
                    a registered user of the Platform and verify your
                    eligibility to use the Platform;
                  </li>
                  <li className={classes.listItem}>
                    carry out our obligations arising from the Agreement. This
                    includes providing your employer with reports and analysis
                    summarizing information provided during your use of the
                    Platform, including in any engagement surveys, aggregated
                    with information from other employees and personnel, and
                    (where relevant) comparing this data with past or future
                    employee responses and/or industry benchmarks. All survey
                    responses are entirely optional: individual questions (or
                    the entire survey) may be skipped and there is no
                    requirement for any surveys to be completed;
                  </li>
                  <li className={classes.listItem}>
                    provide you with information, products and services you
                    request from us, or
                  </li>
                  <li className={classes.listItem}>
                    deal with any inquiries, correspondence, concerns or
                    complaints you have raised, or that have been raised by or
                    concerning third parties (such as your employer) involving
                    you and any issues caused by your use of the Platform.
                  </li>
                </ul>
                <Typography className={classes.text}>
                  Information we collect about you. We will use this
                  information:
                </Typography>
                <ul>
                  <li className={classes.listItem}>
                    to administer and improve the Platform and other services,
                    including ensuring that content is presented in the most
                    effective manner for you and for your computer;
                  </li>
                  <li className={classes.listItem}>
                    to report to your employer relating to the use of the
                    Platform, as may be required under the Agreement;
                  </li>
                  <li className={classes.listItem}>
                    for internal operations, including troubleshooting, data
                    analysis, testing, research, statistical and survey
                    purposes;
                  </li>
                  <li className={classes.listItem}>
                    to allow you to participate in interactive features of the
                    Platform, when you choose to do so; as part of our efforts
                    to keep the Platform safe and secure; or to compile reports
                    (which do not personally identify you) of usage of the
                    Platform.
                  </li>
                </ul>
                <Typography className={classes.text}>
                  Information we receive from other sources. We may combine
                  information from other sources with the information you give
                  to us or we collect about you and use this information as
                  specified above. In relation to the above uses, we shall
                  process your personal data on the legal basis that it is
                  necessary for the purposes of our legitimate interests or of
                  your employer&apos;s legitimate interests, including: to
                  enable us to perform our contractual obligations under the
                  Agreement, to improve or optimize our services, to maintain
                  the security of our computer systems, to understand how the
                  Platform is used and to improve the user experience of the
                  Platform, to protect and defend our legal rights, for
                  troubleshooting, and for data analysis, testing and research
                  purposes. Please also note:
                </Typography>
                <Typography className={classes.text}>
                  We will not undertake any surveys or analysis via the Platform
                  by specific reference to any special categories of personal
                  data (including racial or ethnic origin, political opinions,
                  religious or philosophical beliefs, trade union membership,
                  genetic or biometric data, health data, sex life or sexual
                  orientation), unless this has been expressly requested or
                  configured by your employer. Where this is the case, it is
                  your employer&apos;s responsibility to ensure they have
                  obtained your explicit consent to such processing or that they
                  have another lawful basis for such processing (please contact
                  your employer if you have any questions). However, you might,
                  in the course of providing comments or feedback in a survey,
                  provide personal data within one of the above categories where
                  this has not been requested. By providing this data to us, you
                  will be deemed to have consented to our processing such data
                  as part of the survey results and disclosing such data to your
                  employer. We will not reveal your identity to your employer
                  other than in exceptional circumstances, as explained further
                  below in the section entitled “Disclosure Of Your
                  Information”. Data collected from you and other employees or
                  personnel may be used by us in an aggregated and anonymized
                  form for statistical and benchmarking purposes including
                  enabling comparisons to other organizations within the same
                  industry.
                </Typography>
                <Typography className={classes.text}>
                  For Marketing Contacts, we will collect and use data to
                  contact you about our news, updates, events, developments,
                  products and services from time to time and for the purposes
                  of entering into discussions with you in connection with your
                  purchase of licenses from us to use or have access to the
                  Platform. This data is processed by us on the basis that it is
                  necessary for the purposes of our legitimate interests, namely
                  undertaking targeted marketing and business development
                  activities in connection with our business.
                </Typography>
                <Typography variant="subtitle2">
                  DISCLOSURE OF YOUR INFORMATION
                </Typography>
                <Typography className={classes.text}>
                  We may also share your personal data with selected third
                  parties in accordance with this policy, including: service
                  providers, for example of IT services, sub-contractors, for
                  the performance of any contract that we enter into with your
                  employer (such as the Agreement) including the following:
                </Typography>
                <ul>
                  <li className={classes.listItem}>
                    Amazon Web Services, Inc who provide cloud hosted
                    infrastructure and services used by us to operate the
                    Platform as a hosted solution;
                  </li>
                  <li className={classes.listItem}>
                    government or other law enforcement agencies, in connection
                    with the investigation of unlawful activities or for other
                    legal reasons (this may include your location information).
                  </li>
                </ul>
                <Typography className={classes.text}>
                  We require all our third party service providers to take
                  appropriate and stringent security measures to protect your
                  personal data in line with our policies. We do not allow our
                  third party service providers to use your personal data for
                  their own purposes and only permit them to process your
                  personal data for specified purposes in accordance with our
                  instructions.
                </Typography>
                We may also disclose your personal data to other third parties
                in the following circumstances:
                <ul>
                  <li className={classes.listItem}>
                    if we sell or buy any business assets or receive investment
                    into our business, we may disclose your personal data to the
                    prospective or actual buyer, seller or investee of such
                    business or assets;
                  </li>
                  <li className={classes.listItem}>
                    if Kadar or substantially all of its assets are acquired by
                    a third party, in which case personal data held by us,
                    including your data and data about our customers, suppliers
                    and correspondents will be one of the transferred assets;
                  </li>
                  <li className={classes.listItem}>
                    we may disclose your personal data to our legal advisers if
                    they need to have access to this information in order to
                    advise us on our legal rights and obligations; and
                  </li>
                  <li className={classes.listItem}>
                    if we are under a duty to disclose or share your personal
                    data in order to comply with any legal obligation, or in
                    order to enforce or apply our Terms of Use or other
                    contracts between us and you or your employer; or to protect
                    the rights, property or safety of us, our customers or
                    others.
                  </li>
                </ul>
                If you are a Survey Recipient please note that:
                <ul>
                  <li className={classes.listItem}>
                    feedback and comments provided by you during engagement
                    surveys are collated and conveyed to your employer in an
                    aggregated or anonymized/not anonymized form. Your anonymity
                    as a Survey Recipient is configured by your employer. Please
                    also note that we merely report the feedback and comments
                    and do not undertake any investigation or assessment into
                    their veracity or legality;
                  </li>
                  <li className={classes.listItem}>
                    from time to time we may receive a request from your
                    employer to disclose your identity or we may consider it
                    appropriate to disclose your identity in the absence of such
                    a request. We will consider this in accordance with our
                    internal policy on revealing anonymity. Your identity will
                    in general only be disclosed to your employer where it is
                    necessary to do so for reasons of substantial public
                    interest. This is only likely to occur in exceptional
                    circumstances. For example, we may consider (based on your
                    or others&apos; comments) that you or another person
                    identified is experiencing or at risk of physical, mental or
                    emotional harm (including self-harm) and requires support,
                    and that there is a need to protect the well-being of you or
                    another person, and furthermore that we cannot reasonably
                    obtain your consent (for instance if you are away from your
                    workplace and therefore not contactable for a period of
                    time) or where seeking your consent would prejudice the
                    purpose of the disclosure. By way of further example, we may
                    consider (based on your or others&apos; comments) that
                    disclosure of your identity to your employer is necessary to
                    prevent or detect an unlawful act (such as fraud or other
                    criminal act), and that we cannot request your consent since
                    this would prejudice the purpose of the disclosure.
                  </li>
                </ul>
                <Typography className={classes.text}>
                  Except as explained above, we will not disclose your personal
                  data to any third parties for any other purpose unless we have
                  a legal right or obligation to do so.
                </Typography>
                <Typography variant="subtitle2">
                  INTERNATIONAL DATA TRANSFERS
                </Typography>
                <Typography className={classes.text}>
                  Hosting servers are located in the the European Economic Area
                  (&quot;EEA&quot;), so your personal data are not transferred
                  outside European Economic Area (&quot;EEA&quot;). We may
                  transfer your personal data to those services providers in
                  countries outside the EEA only in order to undertake marketing
                  activities.
                </Typography>
                <Typography className={classes.text}>
                  We have put in place appropriate measures to ensure that your
                  personal data are treated by those third parties in a way that
                  is consistent with and in respects with EU laws on data
                  protection. If you require further information about these
                  protective measures, please contact us at privacy@kadar.app.
                </Typography>
                <Typography variant="subtitle2">
                  SECURITY OF INFORMATION
                </Typography>
                <Typography className={classes.text}>
                  You will not ordinarily require a username or password to gain
                  access to or complete a survey or otherwise provide us with
                  information via the Platform. Where we have given you a
                  username, password and/or security information which enables
                  you to access particular features of the Platform (for
                  example, as an administrator or manager of your employer), you
                  are responsible for keeping these access credentials
                  confidential. You must not share these details with anyone, or
                  store them in a way that may allow a third party to access
                  them. Please see the Terms of Use for more details on not
                  sharing your access credential with third parties and how
                  doing so may affect your ability to use the Platform:&nbsp;
                  <Link to="/terms-of-service">
                    https://kadar.app/terms-of-service
                  </Link>
                  .
                </Typography>
                <Typography className={classes.text}>
                  We maintain appropriate technical and organizational measures
                  to ensure that an appropriate level of security in respect of
                  all personal data we process. Unfortunately, the transmission
                  of information via the internet is not completely secure.
                  Although we will do our best to protect your personal data, we
                  cannot guarantee the security of your data transmitted to the
                  Platform and you acknowledge that any transmission is at your
                  own risk. Once we have received your information, we will use
                  strict procedures and security features which are appropriate
                  to the type of personal data you have provided to try to
                  prevent unauthorized access or inadvertent disclosure, which
                  may include two factor authentication and end-to-end
                  encryption.
                </Typography>
                <Typography variant="subtitle2">YOUR CONSENT</Typography>
                <Typography className={classes.text}>
                  Where consent is required for our use of your personal data,
                  by ticking the appropriate consent box or otherwise
                  communicating your consent to us (whether by phone, email or
                  other means), you consent to our use of that personal data as
                  set out in this policy. If you disclose someone else&apos;s
                  personal data to us, you confirm that you have their consent
                  to disclose this to us and for us to use and disclose it in
                  accordance with this policy.
                </Typography>
                <Typography variant="subtitle2">
                  RETAINING YOUR INFORMATION
                </Typography>
                <Typography className={classes.text}>
                  We will not store your personal data for longer than is
                  reasonably necessary to use it in accordance with this policy
                  or with our legal rights and obligations. For the avoidance of
                  doubt, aggregated and anonymized data and any information
                  other than personal data can be stored indefinitely.
                </Typography>
                <Typography className={classes.text}>In particular:</Typography>
                <Typography className={classes.text}>
                  For Survey Recipients and Client Users: we will retain your
                  personal data for a period of 5 years or until six months
                  after our relationship with your employer has ended (whichever
                  is sooner). After this period, your personal data will be
                  anonymized or deleted. For Marketing Contacts: we will retain
                  your personal data for a period for so long as necessary to
                  continue to provide you with updates or other marketing emails
                  or other communications in circumstances in which you have
                  consented (where necessary) or else not unsubscribed to
                  receiving such communications and in which we have a continued
                  legitimate interest in undertaking that marketing.
                </Typography>
                <Typography variant="subtitle2">YOUR RIGHTS</Typography>
                <Typography className={classes.text}>
                  You have the following rights in regards to your personal
                  information:
                </Typography>
                <Typography className={classes.text}>
                  Access. You have the right to access information about the
                  personal data we hold about you. We reserve the right to
                  charge a reasonable fee in response to unreasonable or
                  repetitive requests, or requests for further copies of the
                  same information.
                </Typography>
                <Typography className={classes.text}>
                  Right to object to processing. You have the right to object to
                  processing of your personal data where that processing is
                  being undertaken by us on the basis of our (or a third
                  party&apos;s) legitimate interest. In such a case we are
                  required to cease processing your data unless we can
                  demonstrate compelling grounds which override your objection.
                  You also have the right to object at any time to the
                  processing by us of your personal data for direct marketing
                  purposes.
                </Typography>
                <Typography className={classes.text}>
                  Correction. You have the right to request that we correct any
                  inaccurate personal data that we hold about you.
                </Typography>
                <Typography className={classes.text}>
                  Erasure. You have the right to request that we erase any
                  personal data that we hold about you, based on one of a number
                  of grounds, including the withdrawal of your consent (where
                  our processing of that data is undertaken on the basis of your
                  consent), or if your object to our continued processing (as
                  mentioned above). This right does not extend to information
                  which is not personal data. Please also note that it is likely
                  to be necessary for us to retain your personal data for the
                  purposes of assessing and verifying data that is submitted
                  and/or held on the Platform, and your rights under applicable
                  law to request erasure may be limited accordingly. We also
                  reserve the right to retain your personal data in an
                  anonymized form for statistical and benchmarking purposes.
                </Typography>
                <Typography className={classes.text}>
                  Request to restriction of processing. This enables you to ask
                  us to restrict the processing of your personal data in certain
                  circumstances, for example if you want us to establish its
                  accuracy or the reason for processing it.
                </Typography>
                <Typography className={classes.text}>
                  Portability. You have the right to obtain copies of your
                  personal data to enable you to reuse your personal data across
                  different services and with different companies. You may also
                  request that your personal data is transmitted directly to
                  another organization where this is technically feasible using
                  our data processing systems.
                </Typography>
                <Typography className={classes.text}>
                  Change of preferences. You can change your data processing
                  preferences at any time. For example, if you have given your
                  consent to direct marketing, but have changed your mind, you
                  have the ability to opt out of receiving marketing
                  communications by emailing us at privacy@kadar.app or clicking
                  the relevant link in any communication you receive.
                </Typography>
                <Typography className={classes.text}>
                  You will not have to pay a fee to access your personal data
                  (or to exercise any of the other rights). However, we may
                  charge a reasonable fee if your request for access is
                  manifestly unfounded or excessive. Alternatively, we may
                  refuse to comply with the request in such circumstances.
                </Typography>
                <Typography className={classes.text}>
                  We may need to request specific information from you to help
                  us confirm your identity and ensure your right to access your
                  personal data (or to exercise any of your other rights). This
                  is another appropriate security measure to ensure that
                  personal data is not disclosed to any person who has no right
                  to receive it.
                </Typography>
                <Typography className={classes.text}>
                  Please note that if you exercise any of the above rights to
                  require us to restrict or cease processing or to delete
                  personal data, and this type of processing is required in
                  order to facilitate your use of the Platform, you will no
                  longer be able to use the Platform following the date on which
                  we action your request. This does not include your right to
                  object to direct marketing which can be exercised at any time
                  without restriction. Please allow at least 5 working days for
                  your request to be processed.
                </Typography>
                <Typography className={classes.text}>
                  Save as set out above, your rights detailed above can be
                  exercised free of charge in accordance with applicable data
                  protection laws. Please contact your employer directly if you
                  would like to exercise any of these rights (other than a
                  change to your marketing preferences, which should be notified
                  directly to us as described above).
                </Typography>
                <Typography className={classes.text}>
                  If for any reason you are not happy with the way that we have
                  handled your personal data, you also have the right to make a
                  complaint to the relevant supervisory authority in your
                  country.
                </Typography>
                <Typography variant="subtitle2">COOKIES</Typography>
                <Typography className={classes.text}>
                  Our website does not use cookies.
                </Typography>
                <Typography variant="subtitle2">
                  CHANGES TO OUR PRIVACY POLICY
                </Typography>
                <Typography className={classes.text}>
                  Any changes we may make to our privacy policy will be posted
                  on this page and where appropriate, may be notified to you by
                  e-mail or advised to you on next login to the Platform.
                  Continued use of the Platform will signify that you agree to
                  any such changes.
                </Typography>
                <Typography variant="subtitle2">CONTACT</Typography>
                <Typography className={classes.text}>
                  If you have any questions about this Privacy Policy, or if you
                  wish to exercise any of your rights, please contact the Data
                  Protection Officer at privacy@kadar.app.
                </Typography>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </Grid>
      <Grid item sm={gridColumn.one} lg={gridColumn.three} />
    </Grid>
  );
};

export default withStyles(styles)(PrivacyPolicyPage);
