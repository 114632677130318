export const getDialogTranslations = (
  translations,
  key,
  numberOfAnswersToDelete
) => {
  if (numberOfAnswersToDelete && numberOfAnswersToDelete !== 1) {
    return {
      ...translations[key],
      contentMessage: translations[key].contentMessageMultiple.replace(
        '[X]',
        numberOfAnswersToDelete
      ),
    };
  }

  return translations[key] || {};
};
