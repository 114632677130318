import { primaryPalette } from '../styles/theme';

export const DATE_FORMAT = 'MMM d, yyyy';

export const TIME_FORMAT = 'hh:mmaaa';

export const DATE_TIME_FORMAT = 'hh:mmaa, MMM d, yyyy';

export const FILTER_DATE_FORMAT = 'yyyy-LL-dd';

export const PERIOD_DATE_FORMAT = 'MMM yyyy';

export const MONTH_DATE_FORMAT = 'MMM';

export const ONE_ON_ONE_COMMENT_FIELDS = {
  CREATED_AT: 'created_at',
  CREATOR: 'creator',
  MODERATOR: 'moderator',
  USER: 'user',
};

export const RECURRING_TYPES_MAP = {
  DOES_NOT_REPEAT: {
    id: 'DOES_NOT_REPEAT',
    key: 'doesNotRepeat',
    name: 'Does not repeat',
  },
  WEEKLY: {
    id: 'WEEKLY',
    key: 'weekly',
    name: 'Weekly',
  },
  BIWEEKLY: {
    id: 'BIWEEKLY',
    key: 'biweekly',
    name: 'Biweekly',
  },
  MONTHLY: {
    id: 'MONTHLY',
    key: 'monthly',
    name: 'Monthly',
  },
};

export const RECURRING_TYPES = [
  RECURRING_TYPES_MAP.DOES_NOT_REPEAT,
  RECURRING_TYPES_MAP.WEEKLY,
  RECURRING_TYPES_MAP.BIWEEKLY,
  RECURRING_TYPES_MAP.MONTHLY,
];

export const DEFAULT_PARAMS = {
  page: 1,
  page_size: 10,
};

export const IMPRESSION_VALUES = [
  {
    value: 1,
    color: primaryPalette.green4,
  },
  {
    value: 2,
    color: primaryPalette.green5,
  },
  {
    value: 3,
    color: primaryPalette.green6,
  },
  {
    value: 4,
    color: primaryPalette.green7,
  },
  {
    value: 5,
    color: primaryPalette.green8,
  },
  {
    value: 6,
    color: primaryPalette.green9,
  },
  {
    value: 7,
    color: primaryPalette.green10,
  },
  {
    value: 8,
    color: primaryPalette.green11,
  },
  {
    value: 9,
    color: primaryPalette.green12,
  },
  {
    value: 10,
    color: primaryPalette.green13,
  },
];

export const INITIAL_TOPIC_DATA = {
  id: null,
  meetingId: null,
  name: '',
  moderator: '',
};
