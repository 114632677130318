import XRegExp from 'xregexp';
import { trimString, isOfType, isArrayEmpty, isObjectEmpty } from './helpers';
import http from './http';
import { API_SUBSCRIPTION } from '../constants/apiRoutes';
import { ROLES } from '../constants/rolesAndPermissionList';
import { FREEMIUM_LIMIT_TYPES } from '../constants/appConfig';

const WWW = 'www';

export const isEmpty = value =>
  value !== undefined && value !== null && value !== '';

export function validateLength(value, min = 0, max = 100, maxOff = false) {
  const pattern = !maxOff ? `^[\\s\\S]{${min},${max}}$` : `^[\\s\\S]{${min},}$`;
  return new RegExp(pattern).test(value);
}

export function validateLowercase(value) {
  return /[a-z]/.test(value);
}

export function validateUppercase(value) {
  return /[A-Z]/.test(value);
}

export function validateWhitespace(value) {
  return /^\S*$/.test(value);
}

export function validateTypeOfCharacters(value) {
  return /^.*[\d~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/].*/.test(value);
}

export function validateEmail(value) {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value);
}

export function validateName(value) {
  return XRegExp('^[\\pL ]*$').test(value);
}

export function forbiddenCharacters(value) {
  return /^[^<>[\]{}\\|]*$/.test(value);
}

export function validatePassword(value) {
  // Minimum six characters, at least one letter and one number:
  return /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/]{6,}$/.test(
    value
  );
}

export function validateOrganizationUrl(value) {
  const pattern = '^[a-z]+[a-z\\d]*((?:-(?=[a-z\\d])|[a-z\\d])*)$';

  return new RegExp(pattern).test(value) && value !== WWW;
}

export function validateUrl(value) {
  return /^((ftp|http|https):\/\/)?([A-z 0-9-]+)\.([A-z]{2,})/.test(value);
}

export const validateFileType = (value, fileTypes) => {
  if (value instanceof File) {
    return fileTypes.includes(value.type);
  }

  return true;
};

export const validateFileSize = (value, maxSize) => {
  if (value instanceof File) {
    return value.size <= maxSize;
  }

  return true;
};

export const validateFreemiumManagingRoles = async value => {
  if (value === ROLES.ADMIN || value === ROLES.MODERATOR) {
    const { data } = await http.get(API_SUBSCRIPTION);

    if (data.is_active) {
      return true;
    }

    if (
      value === ROLES.ADMIN &&
      data.usage[FREEMIUM_LIMIT_TYPES.ADMINS] <
        data.limit[FREEMIUM_LIMIT_TYPES.ADMINS]
    ) {
      return true;
    }

    if (
      value === ROLES.MODERATOR &&
      data.usage[FREEMIUM_LIMIT_TYPES.MODERATORS] <
        data.limit[FREEMIUM_LIMIT_TYPES.MODERATORS]
    ) {
      return true;
    }

    return false;
  }

  return true;
};

export const validateReviews = reviews => {
  if (isArrayEmpty(reviews)) return null;

  const errors = reviews.reduce((acc, review) => {
    return {
      ...acc,
      ...((review.reviewers && isArrayEmpty(review.reviewers)) ||
      (review.subjects && isArrayEmpty(review.subjects))
        ? { [review.attribute]: 'reviews' }
        : {}),
    };
  }, {});

  return isObjectEmpty(errors) ? null : errors;
};

export const validateField = async (
  field,
  value,
  initialValue,
  values,
  canSkipValidation
) => {
  let error = null;
  const asyncValidations = [];
  const errorTypes = [];
  const fieldValue =
    isOfType(value, 'string') && !field.forbidTrim ? trimString(value) : value;

  if (field.validators && field.validators.length > 0) {
    if (
      initialValue &&
      isOfType(fieldValue, 'string') &&
      fieldValue === initialValue
    ) {
      return error;
    }

    for (let i = 0; i < field.validators.length; i += 1) {
      if (canSkipValidation && field.validators[i].shouldSkipValidation) {
        return error;
      }
      if (field.validators[i].async) {
        errorTypes.push(field.validators[i].type);
        asyncValidations.push(
          field.validators[i].validator(
            fieldValue,
            field.validators[i].shouldPassFormValues ? values : undefined
          )
        );
        break;
      } else if (field.validators[i].isMultiple) {
        const errors = field.validators[i].validator(fieldValue);
        if (errors) {
          error = errors;
          break;
        }
      } else {
        const isValid = field.validators[i].validator(
          fieldValue,
          field.validators[i].shouldPassFormValues ? values : undefined
        );
        if (
          !isValid &&
          (field.required ||
            (field.parent && values?.[field.parent.name]) ||
            fieldValue)
        ) {
          error = field.validators[i].type;
          break;
        }
      }
    }
  }

  if (!error && asyncValidations.length > 0) {
    await Promise.all(asyncValidations).then(results => {
      const errorIndex = results.findIndex(item => !item);

      if (errorIndex !== -1) {
        error = errorTypes[errorIndex];
      }
    });
  }

  return error;
};

export const validateFields = async (
  fields,
  values,
  initialValues = {},
  canSkipValidation = false
) => {
  const errors = {};
  const validations = [];

  fields.forEach(field => {
    if (!field.isDisabled) {
      validations.push(
        validateField(
          field,
          values[field.name],
          initialValues[field.name],
          values,
          canSkipValidation
        )
      );
    } else {
      validations.push(null);
    }
  });

  await Promise.all(validations).then(results => {
    results.forEach((result, index) => {
      if (result) {
        errors[fields[index].name] = result;
      }
    });
  });

  return errors;
};

export const validateAll = (fields, values, translations) => {
  const errors = {};

  fields.forEach(field => {
    if (
      Object.keys(errors).length === 0 &&
      field.validators &&
      (field.required || values[field.name])
    ) {
      field.validators.forEach(property => {
        if (!errors[field.name] && !property.validator(values[field.name])) {
          errors[field.name] = translations[property.translationKey];
        }
      });
    }
  });

  return errors;
};

export const validateFieldSync = (field, value, values) => {
  let error = null;
  const fieldValue = isOfType(value, 'string') ? trimString(value) : value;

  if (field.validators && field.validators.length > 0) {
    for (let i = 0; i < field.validators.length; i += 1) {
      const isValid = field.validators[i].validator(
        fieldValue,
        field.validators[i].shouldPassFormValues ? values : undefined
      );
      if (
        !isValid &&
        (field.required ||
          (field.parent && values?.[field.parent.name]) ||
          fieldValue)
      ) {
        error = field.validators[i].type;
        break;
      }
    }
  }

  return error;
};
