import { createAction, handleActions } from 'redux-actions';
import { API_ATTRIBUTES } from '../../constants/apiRoutes';
import http from '../../utility/http';
import { parseDuplicateParameters } from '../../utility/uiUtils';
import { isObjectEmpty } from '../../utility/helpers';
import { ATTRIBUTES_DEFAULT_PARAMS } from '../../constants/pages';
// ------------------------------------
// Constants
// ------------------------------------

const SET_ATTRIBUTES = 'SET_ATTRIBUTES';
const APPEND_ATTRIBUTES = 'APPEND_ATTRIBUTES';

const initialState = {
  attributes: {},
};

// ------------------------------------
// Actions
// ------------------------------------

export const setAttributes = createAction(SET_ATTRIBUTES);
const appendAttributes = createAction(APPEND_ATTRIBUTES);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_ATTRIBUTES]: (state, { payload }) => ({ ...state, attributes: payload }),
  [APPEND_ATTRIBUTES]: (state, { payload }) => {
    return isObjectEmpty(payload)
      ? { ...state, attributes: payload }
      : {
          ...state,
          attributes: {
            ...payload,
            results: state.attributes.results
              ? [...state.attributes.results, ...payload.results]
              : [...payload.results],
          },
        };
  },
};

// ------------------------------------
// API calls
// ------------------------------------

export const getAttributes = (dispatch, isLoadMore = false, params = {}) => {
  return http
    .get(API_ATTRIBUTES, {
      params: { ...ATTRIBUTES_DEFAULT_PARAMS, ...params },
      paramsSerializer: data => parseDuplicateParameters(data),
    })
    .then(({ data }) => {
      dispatch(isLoadMore ? appendAttributes(data) : setAttributes(data));
    });
};

export default handleActions(reducers, initialState);

// ------------------------------------
// "plain" action functions
// ------------------------------------

export const clearAttributes = () => setAttributes(initialState.attributes);
