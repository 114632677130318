import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import CustomModal from '../customModal';
import CustomButton from '../customButton';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning-dark.svg';
import { setDialogVisibility } from '../../../store/modules/dialogs';
import { dialogSelector } from '../../../store/selectors/dialogSelector';
import { useTranslations } from '../../../utility/useTranslations';
import { checkUserRole } from '../../../utility/helpers';
import { ROLES } from '../../../constants/rolesAndPermissionList';
import { APP_PAGES } from '../../../constants/pages';
import { SUBSCRIPTION_EXCLUDED_URLS } from '../../../constants/appConfig';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    display: 'flex',
    height: 298,
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: 40,
    height: 'auto',
    marginBottom: spacing(7),
  },
  title: {
    marginBottom: spacing(2),
  },
  content: {
    marginBottom: spacing(6),
    textAlign: 'center',
  },
  button: {
    boxSizing: 'border-box',
    borderRadius: 44,
    fontFamily: 'ProximaNova-Bold',
    fontSize: 16,
    lineHeight: '24px',
    padding: spacing(2, 4),
    maxHeight: 40,
  },
  upgradePlanButton: {
    backgroundColor: primary.blue1,
    border: 'none',
    color: primary.white,
    '&:hover': {
      backgroundColor: primary.blue1,
    },
  },
  closeButton: {
    backgroundColor: primary.white,
    border: `1px solid ${primary.bluish1}`,
    color: primary.bluish1,
    marginTop: spacing(3),
  },
}));

const UpgradeRequiredDialog = ({ currentUser }) => {
  const classes = useStyles();
  const translations = useTranslations(APP_PAGES.UPGRADE_REQUIRED);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { dialogs } = useSelector(dialogSelector);
  const isAdmin = checkUserRole(currentUser.role, ROLES.ADMIN);

  const shouldRender = SUBSCRIPTION_EXCLUDED_URLS.every(
    route => !location.pathname.includes(route)
  );

  const onClose = () =>
    dispatch(
      setDialogVisibility({
        dialogName: 'upgradeRequiredDialog',
        opened: false,
      })
    );

  const onGoToPricingPlans = () => {
    onClose();
    navigate('/pricing-plans');
  };

  return (
    shouldRender && (
      <CustomModal
        paperClass={classes.root}
        isOpened={dialogs.upgradeRequiredDialogOpened}
        onClose={onClose}
        shouldRemoveFooter
        isSmall
      >
        <WarningIcon className={classes.icon} />
        <Typography className={classes.title} variant="h3" component="div">
          {translations.peopleLimit}
        </Typography>
        <Typography className={classes.content}>
          {isAdmin
            ? translations.upgradeRequiredMessage
            : translations.contactAdminMessage}
        </Typography>
        {isAdmin && (
          <CustomButton
            className={classNames(classes.button, classes.upgradePlanButton)}
            type="withTextDarkRounded"
            onClick={onGoToPricingPlans}
          >
            {translations.upgradeButtonText}
          </CustomButton>
        )}
        {!isAdmin && (
          <CustomButton
            className={classNames(classes.button, classes.closeButton)}
            type="withTextLightRounded"
            onClick={onClose}
          >
            {translations.close}
          </CustomButton>
        )}
      </CustomModal>
    )
  );
};

UpgradeRequiredDialog.propTypes = {
  currentUser: PropTypes.object.isRequired,
};

export default UpgradeRequiredDialog;
