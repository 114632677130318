import { isEmpty } from '../../../utility/validation';
import { FIELD_TYPES } from '../../../constants/fieldTypes';

export const getTrackLevels = (currentLevelId, track = []) => {
  const currentLevelIndex = track?.track_levels?.findIndex(
    trLvl => trLvl.id === currentLevelId
  );
  const totalLevelsToShow = 3;
  const numberOfLevels = track?.track_levels?.length;
  let start = 0;
  let visibleLevels;

  if (isEmpty(currentLevelIndex) && isEmpty(numberOfLevels)) {
    if (currentLevelIndex === numberOfLevels - 1) {
      start = numberOfLevels - totalLevelsToShow;
      visibleLevels = track.track_levels.slice(start >= 0 ? start : 0);
    } else if (currentLevelIndex === 0) {
      visibleLevels = track.track_levels.slice(start, totalLevelsToShow);
    } else {
      visibleLevels = track.track_levels.slice(
        currentLevelIndex - 1,
        currentLevelIndex + 2
      );
    }

    return visibleLevels.map(level => {
      const isCurrentLevel = level.id === currentLevelId;

      return {
        ...level,
        isCurrentLevel,
      };
    });
  }

  return [];
};

export const getCareerInfoFields = (shouldAddJobTitle, shouldAddLevel) => {
  const { SELECT } = FIELD_TYPES;

  return [
    ...(shouldAddJobTitle
      ? [
          {
            name: 'jobTitle',
            type: SELECT,
            required: true,
            optionsKey: 'jobTitles',
            parser: {
              value: 'id',
              label: 'name',
            },
            validators: [
              {
                validator: isEmpty,
                type: 'required',
              },
            ],
          },
        ]
      : []),
    ...(shouldAddLevel
      ? [
          {
            name: 'track',
            required: true,
            type: SELECT,
            optionsKey: 'tracks',
            dependants: [{ name: 'level', value: null }],
            parser: {
              value: 'id',
              label: 'name',
            },
            validators: [
              {
                validator: isEmpty,
                type: 'required',
              },
            ],
          },
          {
            name: 'level',
            type: SELECT,
            optionsKey: 'track_levels',
            required: true,
            parent: {
              name: 'track',
              options: 'tracks',
            },
            parser: {
              value: 'id',
              label: 'name',
            },
            validators: [
              {
                validator: isEmpty,
                type: 'required',
              },
            ],
          },
        ]
      : []),
  ];
};
