import { GROUPED_SURVEY_REPORTING_TYPES } from '../../../constants/survey';
import { getLast12Months } from '../../shared/customDateRange/config';

export const GROUPING_PERIODS = [
  { id: 'surveys', name: 'All surveys' },
  { id: 'month', name: 'Monthly' },
  { id: 'quarter', name: '3 months' },
  { id: 'half', name: '6 months' },
  { id: 'year', name: 'Yearly' },
];

export const DefaultFilterValues = {
  ranges: getLast12Months(),
  groupingPeriodId: GROUPING_PERIODS[0],
  attributesSelection: [],
  selectedSurveyType: GROUPED_SURVEY_REPORTING_TYPES[0].key,
};

export const formatChartData = (attributesSelection, chartData) => {
  return {
    labels: chartData?.map(data => data.name),
    datasets: attributesSelection.map(selectedAttribute => {
      return {
        data: chartData.map(instance =>
          instance[selectedAttribute.id] !== undefined
            ? instance[selectedAttribute.id]
            : null
        ),
        backgroundColor: selectedAttribute.color,
        barThickness: 28,
        borderWidth: {
          top: 5,
          bottom: 5,
        },
        borderColor: 'transparent',
        name: selectedAttribute.name,
      };
    }),
  };
};

export const generateLabelsMap = (attributesSelection, chartData) => {
  const selectedAttributeIds = attributesSelection.map(a => a.id.toString());

  return chartData.reduce((acc, current, index) => {
    const { name, ...rest } = current;
    const measuredAttributeIds = Object.keys(rest);
    const filteredMeasuredAttributeIds = measuredAttributeIds.filter(id =>
      selectedAttributeIds.includes(id)
    );

    return {
      ...acc,
      [index]: filteredMeasuredAttributeIds.length,
    };
  }, {});
};

export const PRESELECTED_ATTRIBUTES_COUNT = 3;
