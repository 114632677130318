import PropTypes from 'prop-types';

const SharedIcon = ({
  icon: Icon,
  showIcon,
  customIconWrapperClass,
  iconConfig,
}) => {
  return showIcon ? (
    <span className={customIconWrapperClass}>
      <Icon {...iconConfig} />
    </span>
  ) : null;
};

SharedIcon.defaultProps = {
  iconConfig: {},
  showIcon: false,
};

SharedIcon.propTypes = {
  icon: PropTypes.object.isRequired,
  showIcon: PropTypes.bool,
  iconConfig: PropTypes.object,
};

export default SharedIcon;
