import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import ConditionalTooltip from '../conditionalTooltip';
import { ReactComponent as RequestIcon } from '../../../assets/icons/person-search-gray.svg';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    boxSizing: 'border-box',
    border: `1px solid ${primary.bluish4}`,
    display: 'flex',
    alignItems: 'center',
    padding: spacing(1, 2),
    marginLeft: spacing(1.5),
    height: 20,
  },
}));

const RequestedIconTooltip = ({
  className,
  labels,
  isRequestedByCurrentUser,
}) => {
  const classes = useStyles();

  return (
    <ConditionalTooltip
      message={
        isRequestedByCurrentUser ? labels.requestedByYou : labels.default
      }
      addTooltip
    >
      <div className={classNames(classes.root, className)}>
        <RequestIcon />
      </div>
    </ConditionalTooltip>
  );
};

RequestedIconTooltip.defaultProps = {
  className: undefined,
  isRequestedByCurrentUser: false,
};

RequestedIconTooltip.propTypes = {
  className: PropTypes.string,
  labels: PropTypes.object.isRequired,
  isRequestedByCurrentUser: PropTypes.bool,
};

export default RequestedIconTooltip;
