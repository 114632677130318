import { createAction, handleActions } from 'redux-actions';
import http from '../../utility/http';
import { parseDuplicateParameters } from '../../utility/uiUtils';
import { api_user_surveys_timeline } from '../../constants/apiRoutes';

// ------------------------------------
// Constants
// ------------------------------------

const SET_SURVEYS_TIMELINE = 'SET_SURVEYS_TIMELINE';

const initialState = {
  surveysTimeline: [],
};

// ------------------------------------
// Actions
// ------------------------------------

const setSurveysTimeline = createAction(SET_SURVEYS_TIMELINE);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_SURVEYS_TIMELINE]: (state, { payload }) => {
    return { ...state, surveysTimeline: payload };
  },
};

// ------------------------------------
// API calls
// ------------------------------------

export const getSurveysTimeline = (dispatch, id, params) => {
  return http
    .get(api_user_surveys_timeline(id), {
      params,
      paramsSerializer: data => parseDuplicateParameters(data),
    })
    .then(({ data }) => {
      dispatch(setSurveysTimeline(data));
    });
};

export default handleActions(reducers, initialState);

// ------------------------------------
// "plain" action functions
// ------------------------------------

export const clearSurveysTimeline = () =>
  setSurveysTimeline(initialState.surveysTimeline);
