import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette: { primary } }) => ({
  root: {
    color: ({ color }) => color || primary.bluish1,
    transition: 'color .3s ease',
  },
}));

const TagHash = ({ className, bgColor, isSmall }) => {
  const classes = useStyles({ color: bgColor });
  return (
    <Typography
      variant={isSmall ? 'body2' : 'body1'}
      component="span"
      className={classNames(classes.root, className)}
    >
      #
    </Typography>
  );
};

TagHash.defaultProps = {
  className: null,
  bgColor: '',
  isSmall: false,
};

TagHash.propTypes = {
  className: PropTypes.string,
  bgColor: PropTypes.string,
  isSmall: PropTypes.bool,
};

export default memo(TagHash);
