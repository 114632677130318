import { isArrayEmpty } from '../../../utility/helpers';

const EMPLOYEE_NUMBER = '[EMPLOYEE_NUMBER]';

export const FIELDS = {
  LEVEL_NAME: 'LEVEL_NAME',
  TRACK_NAME: 'TRACK_NAME',
  TRACK_LEVEL_NAME: 'TRACK_LEVEL_NAME',
  TRACK_LEVEL_DESCRIPTION: 'TRACK_LEVEL_DESCRIPTION',
};

export const NO_VALUE_PLACEHOLDER = '-';

export const EMPTY_FIELD = {
  name: '',
};

export const EMPTY_TRACK_LEVEL = {
  name: '',
  description: '',
};

export const MINIMUM_NUMBER_OF_LEVELS = 1;

export const getColumnStyles = (numberOfColumns, isEditable) => {
  return {
    gridTemplateColumns: `${isEditable ? '101px' : '72px'} ${
      numberOfColumns !== 0 ? `repeat(${numberOfColumns}, 375px)` : ''
    } ${isEditable ? '167px' : ''} ${isEditable ? '40px' : ''}`,
  };
};

export const getDeleteDialogTranslations = (translations, numberOfUsers) => {
  return {
    ...translations,
    contentDescription:
      numberOfUsers !== 0
        ? translations.contentDescription.replace(
            EMPLOYEE_NUMBER,
            numberOfUsers
          )
        : undefined,
  };
};

export const getTrackLevelsForDelete = (tracks, levelIndex) => {
  const levelIds = tracks.reduce((acc, track) => {
    if (track?.track_levels?.[levelIndex]?.id) {
      return [...acc, track.track_levels[levelIndex].id];
    }

    return acc;
  }, []);

  return !isArrayEmpty(levelIds) ? levelIds : null;
};
