import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import UserAvatar from '../../userAvatar';
import HorizontalAnswersGroup from './horizontalAnswersGroup';
import { getPersonShortName } from '../../../../utility/helpers';

const styles = ({ breakpoints, palette: { primary }, spacing }) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
  },
  reviewAnswers: {
    display: 'flex',
    width: '100%',
  },
  groupReviewAnswers: {
    height: '100%',
    [breakpoints.up('sm')]: {
      height: 'auto',
      maxHeight: '100%',
    },
  },
  groupReviewAnswer: {
    boxSizing: 'border-box',
    border: `1px solid ${primary.bluish6}`,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: spacing(2),
    userSelect: 'none',
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  subject: {
    margin: spacing(4),
  },
  userLabel: {
    fontFamily: 'ProximaNova-Bold',
    lineHeight: '18px',
  },
  answersScroll: {
    display: 'flex',
  },
  horizontalWeightLabel: {
    fontSize: 12,
    fontFamily: 'ProximaNova-Bold',
    marginLeft: spacing(2),
    marginTop: spacing(13),
    marginRight: spacing(2),
  },
});

class HorizontalAnswers extends PureComponent {
  renderGroupReviewAnswers = () => {
    const {
      classes,
      subjects,
      answers,
      selection,
      questionId,
      isSingleReview,
      neutralAnswerLabel,
      weightLabel,
      getSelectedAnswer,
      onSelect,
      isAttributePreview,
    } = this.props;

    return (
      <div className={classes.groupReviewAnswers}>
        {subjects.map(subject => {
          return (
            <div
              key={`review_subject_${subject.id}`}
              className={classes.groupReviewAnswer}
            >
              <UserAvatar
                className={classes.subject}
                customAvatarClass={classes.avatar}
                labelClass={classes.userLabel}
                getCustomCaption={getPersonShortName}
                user={subject}
                caption
              />
              <div
                className={classNames({
                  [classes.reviewAnswers]: isAttributePreview,
                })}
              >
                <HorizontalAnswersGroup
                  questionId={questionId}
                  subject={subject}
                  isSingleReview={isSingleReview}
                  isAttributePreview={isAttributePreview}
                  neutralAnswerLabel={neutralAnswerLabel}
                  answers={answers}
                  selection={selection}
                  getSelectedAnswer={getSelectedAnswer}
                  onSelect={onSelect}
                />
                {isAttributePreview && (
                  <div className={classes.horizontalWeightLabel}>
                    {weightLabel}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    const {
      classes,
      questionId,
      subjects,
      neutralAnswerLabel,
      isSingleReview,
      isDisabled,
      isAttributePreview,
      answers,
      selection,
      getSelectedAnswer,
      onSelect,
      weightLabel,
    } = this.props;
    const [subject] = subjects;

    return (
      <div className={classes.main}>
        {isSingleReview ? (
          <div className={classes.reviewAnswers}>
            <HorizontalAnswersGroup
              questionId={questionId}
              subject={subject}
              isSingleReview={isSingleReview}
              isDisabled={isDisabled}
              isAttributePreview={isAttributePreview}
              neutralAnswerLabel={neutralAnswerLabel}
              answers={answers}
              selection={selection}
              getSelectedAnswer={getSelectedAnswer}
              onSelect={onSelect}
            />
            {isAttributePreview && (
              <div className={classes.horizontalWeightLabel}>{weightLabel}</div>
            )}
          </div>
        ) : (
          this.renderGroupReviewAnswers()
        )}
      </div>
    );
  }
}

HorizontalAnswers.defaultProps = {
  isSingleReview: false,
  isDisabled: false,
  questionId: 0,
  subjects: [],
  selection: {},
  getSelectedAnswer: () => {},
  onSelect: () => {},
  weightLabel: '',
  isAttributePreview: false,
};

HorizontalAnswers.propTypes = {
  classes: PropTypes.object.isRequired,
  weightLabel: PropTypes.string,
  answers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  questionId: PropTypes.number,
  subjects: PropTypes.arrayOf(PropTypes.shape({})),
  isSingleReview: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isAttributePreview: PropTypes.bool,
  neutralAnswerLabel: PropTypes.string.isRequired,
  selection: PropTypes.shape({}),
  getSelectedAnswer: PropTypes.func,
  onSelect: PropTypes.func,
};

export default withStyles(styles)(HorizontalAnswers);
