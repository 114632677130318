import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import CustomFormDrawerLayout from './layout';
import { validateFields } from '../../../utility/validation';

const CustomFormDrawer = withFormik({
  mapPropsToValues: props => {
    const { initialData } = props;

    return { ...initialData };
  },
  enableReinitialize: true,
  validateOnBlur: false,
  validateOnChange: false,
  validate: async (values, props) => {
    if (props.columns) {
      const validateAll = props.columns.map(column => {
        return validateFields(column.fields, values, props.initialData);
      });
      const errors = await Promise.all(validateAll);
      throw errors.reduce((acc, error) => ({ ...acc, ...error }), {});
    } else {
      throw await validateFields(props.fields, values, props.initialData);
    }
  },
  handleSubmit: (values, { props, resetForm, setSubmitting }) => {
    const resetFormData = (shouldResetForm = true) => {
      if (shouldResetForm) {
        setSubmitting(false);
        props.onClose();
        resetForm();
      } else {
        setSubmitting(false);
      }
    };

    return props
      .onSave(values)
      .then(
        ({ shouldResetForm = true } = {}) => resetFormData(shouldResetForm),
        resetFormData
      );
  },
})(CustomFormDrawerLayout);

CustomFormDrawer.defaultProps = {
  initialData: {},
};

CustomFormDrawer.propTypes = {
  translations: PropTypes.object.isRequired,
  initialData: PropTypes.object,
};

export default CustomFormDrawer;
