import { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import RichTextPreview from 'components/shared/richTextPreview';
import CustomButton from '../../customButton';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  main: {
    boxSizing: 'border-box',
    padding: spacing(6, 6, 8),
    position: 'relative',
    width: '100%',
  },
  label: {
    color: primary.bluish4,
    lineHeight: '20px',
    marginBottom: spacing(2),
  },
  descriptionLabel: {
    marginTop: spacing(5),
    marginBottom: spacing(1),
  },
  jobTitleName: {
    wordBreak: 'break-word',
  },
  jobTitleDescription: {
    color: primary.bluish3,
    wordBreak: 'break-word',
  },
}));

const JobTitle = ({
  className,
  translations,
  jobTitle,
  hasAssignJobTitleButton,
  hasAddJobTitleButton,
  isAssignDisabled,
  onAddJobTitle,
  onAssignJobTitle,
}) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.main, className)}>
      <Typography variant="body2" component="h4" className={classes.label}>
        {translations.jobTitle}
      </Typography>
      {jobTitle.name ? (
        <div>
          <Typography variant="h3" className={classes.jobTitleName}>
            {jobTitle.name}
          </Typography>
          {jobTitle.description && (
            <div>
              <Typography
                variant="body2"
                component="h4"
                className={classNames(classes.label, classes.descriptionLabel)}
              >
                {translations.description}
              </Typography>
              <RichTextPreview
                className={classes.jobTitleDescription}
                text={jobTitle.description}
              />
            </div>
          )}
        </div>
      ) : (
        <div>
          {hasAssignJobTitleButton && (
            <CustomButton
              type="addRoundedNew"
              disabled={isAssignDisabled}
              onClick={onAssignJobTitle}
            >
              {translations.assignJobTitle}
            </CustomButton>
          )}
          {hasAddJobTitleButton && (
            <CustomButton type="addRoundedNew" onClick={onAddJobTitle}>
              {translations.addJobTitle}
            </CustomButton>
          )}
          {!hasAssignJobTitleButton && !hasAddJobTitleButton && (
            <Typography className={classes.jobTitleDescription}>
              {translations.noJobTitle}
            </Typography>
          )}
        </div>
      )}
    </div>
  );
};

JobTitle.defaultProps = {
  className: undefined,
  isAssignDisabled: false,
};

JobTitle.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  jobTitle: PropTypes.shape({}).isRequired,
  hasAssignJobTitleButton: PropTypes.bool.isRequired,
  hasAddJobTitleButton: PropTypes.bool.isRequired,
  isAssignDisabled: PropTypes.bool,
  onAddJobTitle: PropTypes.func.isRequired,
  onAssignJobTitle: PropTypes.func.isRequired,
};

export default memo(JobTitle);
