import { createAction, handleActions } from 'redux-actions';
import http from '../../utility/http';
import { replaceObjectInList } from '../../utility/helpers';
import {
  API_USER_NOTES,
  api_user_notes,
  api_task_completion,
} from '../../constants/apiRoutes';
// ------------------------------------
// Constants
// ------------------------------------

const SET_NOTES = 'SET_NOTES';
const ADD_NOTE = 'ADD_NOTE';
const DELETE_NOTE = 'DELETE_NOTE';
const EDIT_NOTE = 'EDIT_NOTE';
const SET_TASK_STATUS = 'SET_TASK_STATUS';

const initialState = {
  notes: [],
};

// ------------------------------------
// Actions
// ------------------------------------

const setNotes = createAction(SET_NOTES);
const addNote = createAction(ADD_NOTE);
const deleteNoteAction = createAction(DELETE_NOTE);
const editNoteAction = createAction(EDIT_NOTE);
const setTaskStatus = createAction(SET_TASK_STATUS);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_NOTES]: (state, { payload }) => {
    return { ...state, notes: payload };
  },
  [ADD_NOTE]: (state, { payload }) => {
    return { ...state, notes: [payload, ...state.notes] };
  },
  [DELETE_NOTE]: (state, { payload }) => {
    return { ...state, notes: [...state.notes.filter(n => n.id !== payload)] };
  },
  [EDIT_NOTE]: (state, { payload }) => {
    const noteIndex = state.notes.findIndex(n => n.id === payload.id);

    return {
      ...state,
      notes: replaceObjectInList(state.notes, noteIndex, payload),
    };
  },
  [SET_TASK_STATUS]: (state, { payload }) => {
    const { note, task } = payload;

    const noteIndex = state.notes.findIndex(
      currentNote => currentNote.id === note.id
    );
    const taskIndex = note.tasks.findIndex(
      currentTask => currentTask.id === task.id
    );
    const updatedNote = {
      ...note,
      tasks: replaceObjectInList(note.tasks, taskIndex, task),
    };

    return {
      ...state,
      notes: replaceObjectInList(state.notes, noteIndex, updatedNote),
    };
  },
};

// ------------------------------------
// API calls
// ------------------------------------

export const getNotes = (dispatch, userId) => {
  return http.get(api_user_notes(userId)).then(({ data }) => {
    dispatch(setNotes(data));
  });
};

export const createNote = (dispatch, note) => {
  return http.post(API_USER_NOTES, note).then(({ data }) => {
    dispatch(addNote(data));

    return data;
  });
};

export const deleteNote = (dispatch, noteId) => {
  return http.delete(`${API_USER_NOTES}${noteId}/`).then(() => {
    dispatch(deleteNoteAction(noteId));
  });
};

export const editNote = (dispatch, note) => {
  const { id, ...params } = note;
  return http.patch(`${API_USER_NOTES}${id}/`, params).then(({ data }) => {
    dispatch(editNoteAction(data));

    return data;
  });
};

export const editTaskStatus = (dispatch, note, task) => {
  return http
    .patch(api_task_completion(task.id))
    .then(({ data }) => dispatch(setTaskStatus({ note, task: data })));
};

export default handleActions(reducers, initialState);

// ------------------------------------
// "plain" action functions
// ------------------------------------

export const clearNotes = () => setNotes(initialState.notes);
