import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OrganizationPage from '../../components/pages/organizationPage';
import PageContainer from '../../components/shared/pageContainer';
import {
  getEmployeesHierarchy,
  clearEmployeesHierarchy,
  setEmployeeRespondents,
} from '../../store/modules/employeesHierarchy';
import { employeesHierarchySelector } from '../../store/selectors/employeesHierarchySelector';
import { useTranslations } from '../../utility/useTranslations';
import { APP_PAGES } from '../../constants/pages';

const mapStateToProps = state => ({
  ...employeesHierarchySelector(state),
});

const mapDispatchToProps = dispatch => ({
  getEmployeesHierarchy: auth => getEmployeesHierarchy(dispatch, auth),
  clearEmployeesHierarchy: () => clearEmployeesHierarchy(dispatch),
  setEmployeeRespondents: (id, data) =>
    dispatch(setEmployeeRespondents({ id, data })),
});

const OrganizationPageView = props => {
  const translations = useTranslations(APP_PAGES.ORGANIZATION);

  return (
    <PageContainer {...props} translations={translations} isFullWidthContent>
      <OrganizationPage />
    </PageContainer>
  );
};

OrganizationPageView.propTypes = {
  getEmployeesHierarchy: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationPageView);
