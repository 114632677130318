import {
  CREATE_SURVEY_FIELD_NAMES,
  SURVEY_TYPES,
} from '../../../constants/survey';
import { isItemInList } from '../../../utility/helpers';

export const isPlural = surveyData => {
  const { SURVEY_TYPE, CREATED_FOR, REVIEWERS } = CREATE_SURVEY_FIELD_NAMES;

  return (
    (surveyData[SURVEY_TYPE] === SURVEY_TYPES.SELF &&
      surveyData[CREATED_FOR].length > 1) ||
    (surveyData[SURVEY_TYPE] !== SURVEY_TYPES.SELF &&
      surveyData[REVIEWERS].length > 1)
  );
};

export const hasInlineSurvey = (surveyData, currentUser) => {
  const { SURVEY_TYPE, IS_SCHEDULE, CREATED_FOR, REVIEWERS } =
    CREATE_SURVEY_FIELD_NAMES;

  if (surveyData[IS_SCHEDULE]) return false;

  if (surveyData[SURVEY_TYPE] === SURVEY_TYPES.SELF) {
    return isItemInList(surveyData[CREATED_FOR], currentUser);
  }

  return isItemInList(surveyData[REVIEWERS], currentUser);
};

export const getDescription = (translations, surveyData, isScheduled) => {
  const { description, reviewer, reviewers, send, schedule } = translations;

  return description
    .replace(
      '[SEND]',
      isScheduled ? schedule.toLowerCase() : send.toLowerCase()
    )
    .replace(
      '[REVIEWER]',
      isPlural(surveyData) ? reviewers.toLowerCase() : reviewer.toLowerCase()
    );
};
