import { memo } from 'react';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette: { primary } }) => ({
  root: {
    color: primary.bluish4,
  },
}));

const Placeholder = props => {
  const classes = useStyles();

  return (
    <Typography className={classes.root} {...props.innerProps}>
      {props.children}
    </Typography>
  );
};

export default memo(Placeholder);
