import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { authSelector } from '../../store/selectors/authSelector';
import { setAuthInfo } from '../../store/modules/auth';
import UnauthorizedAccessPage from '../../components/pages/unauthorizedAccessPage';
import PageContainer from '../../components/shared/pageContainer';
import { grantedPermissionsSelector } from '../../store/selectors/grantedPermissionsSelector';
import { organizationSettingsSelector } from '../../store/selectors/organizationSettingsSelector';
import { useTranslations } from '../../utility/useTranslations';
import { APP_PAGES } from '../../constants/pages';

const mapStateToProps = state => ({
  ...authSelector(state),
  ...grantedPermissionsSelector(state),
  ...organizationSettingsSelector(state),
});

const mapDispatchToProps = dispatch => ({
  setAuthInfo: data => dispatch(setAuthInfo(data)),
});

const UnauthorizedAccessPageView = props => {
  const location = useLocation();
  const navigate = useNavigate();
  const translations = useTranslations(APP_PAGES.UNAUTHORIZED_ACCESS);

  if (props.auth && props.auth.access) {
    return (
      <PageContainer
        {...props}
        translations={translations}
        location={location}
        navigate={navigate}
        isFullWidthContent
      >
        <UnauthorizedAccessPage isAuthorized />
      </PageContainer>
    );
  }

  return (
    <UnauthorizedAccessPage
      {...props}
      translations={translations}
      navigate={navigate}
    />
  );
};

UnauthorizedAccessPageView.propTypes = {
  auth: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnauthorizedAccessPageView);
