const getWidth = width => {
  if (width) {
    return typeof width === 'number' ? `${width}px` : width;
  }

  return 'auto';
};

export const getColumnWidth = columns => {
  const widths = columns
    .map(
      column =>
        `minmax(${getWidth(column.minWidth)}, ${getWidth(column.maxWidth)})`
    )
    .join(' ');

  return { gridTemplateColumns: widths };
};

export const getAlignItem = column => {
  const alignment = column.align || 'left';

  return { textAlign: alignment };
};
