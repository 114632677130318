import { connect } from 'react-redux';
import { PLACEMENTS } from 'constants/tags';
import CoursesPage from '../../components/pages/coursesPage';
import PageContainer from '../../components/shared/pageContainer';
import {
  getCourses,
  clearCourses,
  setCourses,
} from '../../store/modules/courses';
import {
  getAttributesWithQuestions,
  clearAttributesWithQuestions,
} from '../../store/modules/surveys';
import {
  getCourseEnrolledUsers,
  clearCourseEnrolledUsers,
} from '../../store/modules/courseDetails';
import {
  getTagsCategories,
  clearTagsCategories,
  addMultipleTags,
  getPageQuickFilters,
  clearPageQuickFilters,
} from '../../store/modules/tags';
import { coursesSelector } from '../../store/selectors/coursesSelector';
import { dialogSelector } from '../../store/selectors/dialogSelector';
import { surveysSelector } from '../../store/selectors/surveysSelector';
import { tagsSelector } from '../../store/selectors/tagsSelector';
import { useTranslations } from '../../utility/useTranslations';
import { APP_PAGES } from '../../constants/pages';

const mapStateToProps = state => ({
  ...coursesSelector(state),
  ...dialogSelector(state),
  ...tagsSelector(state),
  allAttributes: [...(surveysSelector(state)?.attributesWithQuestions || [])],
});

const mapDispatchToProps = dispatch => ({
  getCourses: data => getCourses(dispatch, data),
  clearCourses: () => dispatch(clearCourses()),
  getAttributesWithQuestions: params =>
    getAttributesWithQuestions(dispatch, params),
  clearAttributesWithQuestions: () => dispatch(clearAttributesWithQuestions()),
  getCourseEnrolledUsers: (courseId, params) =>
    getCourseEnrolledUsers(dispatch, courseId, params),
  clearCourseEnrolledUsers: () => dispatch(clearCourseEnrolledUsers()),
  addMultipleTags: data => dispatch(addMultipleTags(data)),
  getTagsCategories: () => getTagsCategories(dispatch),
  getPageQuickFilters: () =>
    getPageQuickFilters(dispatch, PLACEMENTS.LEARNING.id),
  clearTagsCategories: () => dispatch(clearTagsCategories()),
  clearPageQuickFilters: () => dispatch(clearPageQuickFilters()),
  setCourses: data => dispatch(setCourses(data)),
});

const CoursesPageView = props => {
  const translations = useTranslations(APP_PAGES.COURSES);

  return (
    <PageContainer {...props} translations={translations}>
      <CoursesPage />
    </PageContainer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CoursesPageView);
