import { createAction, handleActions } from 'redux-actions';
import http from '../../utility/http';
import { isObjectEmpty } from '../../utility/helpers';
import {
  API_ACTION_PLANS,
  api_user_action_plans,
} from '../../constants/apiRoutes';
import {
  ACTION_PLANS_DEFAULT_PARAMS,
  PEOPLE_ACTION_PLANS_DEFAULT_PARAMS,
} from '../../constants/pages';
import { parseDuplicateParameters } from '../../utility/uiUtils';

// ------------------------------------
// Constants
// ------------------------------------

const SET_ALL_ACTION_PLANS = 'SET_ALL_ACTION_PLANS';
const LOAD_MORE_ACTION_PLANS = 'LOAD_MORE_ACTION_PLANS';

const initialState = {
  actionPlans: {},
};

// ------------------------------------
// Actions
// ------------------------------------

const setAllActionPlans = createAction(SET_ALL_ACTION_PLANS);
const loadMoreActionPlans = createAction(LOAD_MORE_ACTION_PLANS);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_ALL_ACTION_PLANS]: (state, { payload }) => {
    return { ...state, actionPlans: payload };
  },
  [LOAD_MORE_ACTION_PLANS]: (state, { payload }) => {
    return isObjectEmpty(payload)
      ? { ...state, actionPlans: payload }
      : {
          ...state,
          actionPlans: {
            ...payload,
            results: state.actionPlans.results
              ? [...state.actionPlans.results, ...payload.results]
              : [...payload.results],
          },
        };
  },
};

export const getUserActionPlans = (
  dispatch,
  userId,
  params = {},
  isLoadMore = false
) => {
  return http
    .get(api_user_action_plans(userId), {
      params: {
        ...PEOPLE_ACTION_PLANS_DEFAULT_PARAMS,
        ...params,
      },
      paramsSerializer: data => parseDuplicateParameters(data),
    })
    .then(({ data }) =>
      dispatch(isLoadMore ? loadMoreActionPlans(data) : setAllActionPlans(data))
    );
};

export const getAllActionPlans = (dispatch, isLoadMore, params = {}) => {
  return http
    .get(API_ACTION_PLANS, {
      params: { ...ACTION_PLANS_DEFAULT_PARAMS, ...params },
      paramsSerializer: data => parseDuplicateParameters(data),
    })
    .then(({ data }) => {
      dispatch(
        isLoadMore ? loadMoreActionPlans(data) : setAllActionPlans(data)
      );
    });
};

export const clearAllActionPlans = dispatch => {
  dispatch(setAllActionPlans(initialState.actionPlans));
};

export default handleActions(reducers, initialState);
